import {CwSpacePlanModel, CwSpacePlanVisibleModel} from '../CollaborateSpaceWebTools/components/space/plan/cw-space-plan.model';
import {CwInput} from '../CollaborateSpaceWebTools/components/input/cw-input-model';
import {CwFunction} from '../CollaborateSpaceWebTools/model/model';
import {CwFormatTool} from '@tool/format/tool-format';
import {CW_SPACE_SUBSCRIPTION_TOOL} from '@tool/space/subscription/tool-space-subscription-model';

export interface PlansViewPlanRouterModel {
  name: string;
  router: string;
}

export interface PlansViewPlanIntervalModel {
  name: string;
  amount: number;
}

export interface PlansViewPlanModel {
  name: string;
  Routers: PlansViewPlanRouterModel[];
  ApiPlanList: any[];
  Visible: CwSpacePlanVisibleModel;
}

export interface PlansViewModel {
  Plans: PlansViewPlanModel[];
  definePlans: any;
  definePlan: any;
  definePlanFree: any;
}

export interface PlansBuyAgreeModel {
  privacy: boolean;
  terms: boolean;
}

export interface PlansBuyRegistrationModel {
  email: string;
  firstName: string;
  lastName: string;
  password: string;

  InputsSet: CwInput[];
}

export interface PlansBuyPaymentModel {
  Input: CwInput;
}

export interface PlansBuyDoneModel {
  success?: string;
  error?: string;
}

export interface PlansBuySignInModel {
  email: string;
  password: string;
}

export interface PlansBuySelectModel {
  ExtraNews: CwSpacePlanModel[];
  On: {
    changed: CwFunction;
  }
  PlanNew: CwSpacePlanModel;
  Plans: CwSpacePlanModel[];
}

export interface PlanBuyModel {
  Select: PlansBuySelectModel;
  Registration: PlansBuyRegistrationModel;
  Payment: PlansBuyPaymentModel;
  Agree: PlansBuyAgreeModel;
  do: CwFunction;
  Done: PlansBuyDoneModel;
  SignIn: PlansBuySignInModel;
}

export interface PlansToolModel {
  Buy: PlanBuyModel;
  View: PlansViewModel;
}


export const APP_PLAN_EXTRA = {
  CLOUD: <CwSpacePlanModel>{
    Date: null,
    dateFormat: CwFormatTool.Date.standard,
    id: CW_SPACE_SUBSCRIPTION_TOOL.PACKAGE.CLOUD,
    id_collaborate_package: CW_SPACE_SUBSCRIPTION_TOOL.PACKAGE.CLOUD,
    interval: CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.API,
    interval_count: 1,
    name: 'Cloud Space 1GB',
    quantity: 0,
    type: CW_SPACE_SUBSCRIPTION_TOOL.TYPE.EXTRA,
  },
  GATEWAY: <CwSpacePlanModel>{
    Date: null,
    dateFormat: CwFormatTool.Date.standard,
    id: CW_SPACE_SUBSCRIPTION_TOOL.PACKAGE.GATEWAY,
    id_collaborate_package: CW_SPACE_SUBSCRIPTION_TOOL.PACKAGE.GATEWAY,
    interval: CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.API,
    interval_count: 1,
    name: 'Gateway Port',
    quantity: 0,
    type: CW_SPACE_SUBSCRIPTION_TOOL.TYPE.EXTRA,
  }
}

export const FREE = {
  amount: 0,
  amountReplace: 'Free',
  ApiPlanList: [],
  ExtraList: [],
  hosts: '1 host',
  interval: 'month',
  // 2007291000
  name: 'Free', // Do not change
  quantity: 1,
  Routers: [
    {
      router: '/plans/free',
      name: 'Sign Up',
    }
  ],
  top: 'For individuals',
  Visible: {
    description: true,
    router: true,
  }
}
