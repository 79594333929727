import {Component} from '@angular/core';
import {CwInputComponent} from '@cw/components/input/cw-input.component';
import {CwInputI} from '@cw/components/input/cw-input-model';

@Component({
  selector: 'cw-input-number-positive',
  templateUrl: './cw-input-number-positive.component.html',
  styleUrls: ['./cw-input-number-positive.component.scss']
})
export class CwInputNumberPositiveComponent extends CwInputComponent {

  settingChange(Item: CwInputI) {
    if (Item.value < 0) {
      Item.value = 0;
    }
    super.settingChange(Item);
  }
}
