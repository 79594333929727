import {Result} from '../common/result';
import {ActivatedRoute} from '@angular/router';
import {NgForm} from '@angular/forms';
import {AppSettings} from '../common/app-settings';
import {CoWeb} from '../co/tools/web';
import {CoValidationsComponent} from '../co/validations/component';
import {CwTrialBus} from '@cw/entity/trial/trial-bus';
import {CwEntityTrialDoI} from '@cw/entity/trial/trial-model';
import {AppDataSignBus} from './data-sign-up-bus';
import {CwWeb} from '@tool/web/tool-web';
import {CwUserResetBus} from '@cw/user/reset/reset-bus';
import {CwUserResetDoI} from '@cw/user/reset/reset-model';
import {CwUserLogin} from '../CollaborateSpaceWebTools/user/login/login-bus';
import {CwUserLoginDoI} from '../CollaborateSpaceWebTools/user/login/login-model';
import {CwTokenGet} from '../CollaborateSpaceWebTools/token/get/get-bus';
import {CwRestRequestParametersI, PayLoadCustomI} from '@tool/rest/model';
import {App} from '../app';
import {CwSelectComponentI} from '@cw/components/select/select-model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {AppTool} from '../app-tool';
import {APP_DATA_SIGN_UP, DataSignUpCouponModel, DataSignUpTypeModel} from './data-sign-up.model';
import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {CwCountryStateBus} from '@rest/literal/country-min/country-min-bus';
import {CwStateBus} from '@rest/literal/state/state-bus';
import {AppWebTool} from '../app-web.tool';
import {AppFeedbackTool} from '../feedback/feedback.tool';
import {AppPlansTool} from '../plans/plans.tool';
import {PageScrollService} from 'ngx-page-scroll';
import {DOCUMENT} from '@angular/common';
import {CwScrollTool} from '@tool/scroll/tool';
import {AppDataSignUpCouponTool} from './data-sign-up-coupon.tool';
import {AppDataSignUpToolClass} from './data-sign-up.tool';

@Component({
  selector: 'app-data-sign-up',
  templateUrl: './data-sign-up.component.html',
})
export class DataSignUpComponent extends CoValidationsComponent
  implements OnInit {

  @Input() couponExternal: string;
  @ViewChild('formCtrl') public formCtrl: NgForm;


  Agree = {
    Checked: {
      term: false,
      privacy: false,
    },
  };
  AppDataSignBus = AppDataSignBus;
  AppDataSignUpTool = new AppDataSignUpToolClass();

  companyName: string;
  confirmEmail = '';
  ConfirmEmailValidationList: any[] = [];
  confirmPassword: string;
  ConfirmValidationsList: any[] = [];
  Country: any;
// 2005060000
  Coupon: DataSignUpCouponModel = AppDataSignUpCouponTool;

  errorMessage: String;

  On = {
    Country: {
      // 2005261100
      change: (ValueNew): void => {
        this.StateSetup = {
          OptionList: []
        };

        if (this.Country && this.Country.api) {
          this.State = undefined;
          if (this.Country.StateList) {
            this.StateSetup.OptionList.push(
                ...this.Country.StateList
            );
          }
          // 2006101639
          if (this.StateSetup.OptionList.length === 0) {
            this.StateSetup.OptionList.push({
              coGridSelected: false,
              coId: undefined,
              country_id: -1,
              cwKey: -1,
              id: -1,
              name: this.Country.api,
              web: this.Country.web,
            });
          }
          this.State = this.StateSetup.OptionList[0];
        }
      },
    },

    State: {
      change: (valueNew): void => {
      },
    }
  }


  passwordPattern = AppSettings.PASSWORD_PATTERN;
  phoneNumber: string;


  result = new Result();

  State: any;
  StateSetup: any;
  state: string;

  // // 2007291000
  TITLE = 'COLLABORATE Space Free Account';
  // TITLE = 'COLLABORATE Space Personal';
  // TITLE = 'COLLABORATE Space TRIAL 90-Day';
  // TITLE = 'COLLABORATE Space Free';
  token: string;
  TRIAL = true;
  type: DataSignUpTypeModel;


  /**
   * @version 2006110844
   * @param Data
   */
  error = (Data) => {
    CwWeb.loaderHide();
    AppFeedbackTool.error(
      (Data && Data.error)
        ? Data.error
        : (Data)
        ? Data
        : ''
    )
    // 2006110844
    CwScrollTool.goTo({
      id: '#ScrollFeedback',
    })

  };
  privacyChanged = (value: boolean) => {
    this.Agree.Checked.privacy = value;
  }

  termsChanged = (value: boolean) => {
    this.Agree.Checked.term = value;
  }


  // 2005060000
  get getCouponInputDisabled(): boolean {
    return false;
  }

  get getDisabled(): boolean {
    let disabled = true;
    // 2004030942
    if ('PASSWORD' === AppDataSignBus.action) {
      if (
        this.formCtrl &&
        this.formCtrl.valid &&
        this.formCtrl.form &&
        this.formCtrl.form.controls &&
        this.formCtrl.form.controls.password &&
        this.formCtrl.form.controls.confirmPassword &&
        this.formCtrl.form.controls.password.value === this.formCtrl.form.controls.confirmPassword.value &&
        this.Agree.Checked.privacy &&
        this.Agree.Checked.term &&
        true
      ) {
        disabled = false;
      }
    } else {
      if (
        this.formCtrl &&
        this.formCtrl.valid &&
        this.formCtrl.form &&
        this.formCtrl.form.controls &&
        this.formCtrl.form.controls.email &&
        this.formCtrl.form.controls.confirmEmail &&
        this.formCtrl.form.controls.email.value === this.formCtrl.form.controls.confirmEmail.value &&
        this.formCtrl.form.controls.password &&
        this.formCtrl.form.controls.confirmPassword &&
        this.formCtrl.form.controls.password.value === this.formCtrl.form.controls.confirmPassword.value &&
        this.Country &&
        this.State &&
        this.Agree.Checked.privacy &&
        this.Agree.Checked.term &&
        true
      ) {
        disabled = false;
      }

    }
    return disabled;
  }

  get getDisplay(): boolean {
    return (
      // this.Coupon.display &&
      true
    );
  }


  get getStateSetup(): CwSelectComponentI {
    return {
      OptionList: (
        this.Country &&
        this.Country.StateList &&
        true
      )
        ? this.Country.StateList
        : []
    }
  }


  constructor(
    private _ActivatedRoute: ActivatedRoute,
    // 2006110844
    // tslint:disable-next-line:no-shadowed-variable
    private PageScrollService: PageScrollService,
    @Inject(DOCUMENT) private Document: any,
  ) {
    super();
    CwStateBus.Load.do();
    CwCountryStateBus.Load.do();
    CwScrollTool.setup({PageScrollService, Document});
  }


  ngOnInit() {
// 2005060000
    this.Coupon.setup();

    AppTool.googleLog();
    this.token = CwWeb.getToken({
      ActivatedRoute: this._ActivatedRoute,
    })

    // 2005121138
    if (
      this.token !== null &&
      this.token !== undefined &&
      true
    ) {
      AppDataSignBus.action = 'INVITATION';
    } else {
      this.token = CoWeb.getParam(this._ActivatedRoute, 'tokenp');
      // this.token = this.route.snapshot.paramMap.get('tokenp');
      if (this.token !== null) {
        AppDataSignBus.action = 'PASSWORD';
      }
    }

    // 1904251312 - Title
    if (
      undefined === AppDataSignBus.action ||
      '' === AppDataSignBus.action ||
      'INVITATION' === AppDataSignBus.action ||
      false
    ) {
      AppWebTool.Title = {
        main: this.TITLE,
        secondary: 'Registration',
      }
    } else if (
      'PASSWORD' === AppDataSignBus.action
    ) {
      AppWebTool.Title = {
        main: 'Welcome to Collaborate',
      }
    }

    if (AppDataSignBus.action !== '' && this.token) {
      // 1904101050
      CwTokenGet.do(<CwRestRequestParametersI>{
        Bus: CwTokenGet,
        Parameters: {
          action: AppDataSignBus.action,
          token: this.token
        },
        successActionCustom: ((Data, That) => {
          if (
            Data &&
            Data.result &&
            true
          ) {
            //      2003180836
            const NameList = Data.result.name.split(' ');
            AppDataSignBus.firstName = NameList.shift();
            AppDataSignBus.lastName = NameList.join(' ');

            AppDataSignBus.email = Data.result.email;
            if (AppDataSignBus.action === 'INVITATION') {
              AppDataSignBus.jid = Data.result.jid;
              AppDataSignBus.applicant = Data.result.applicant;
            } else {
              AppDataSignBus.companyName = Data.result.entity;
            }
          } else {
            App.Feedback.contactAdminVisible = true;
            App.Body.visible = false;
          }
        }),
        errorActionCustom: ((Data, That) => {
          CwWeb.goTo({path: '/datasignin'});
        }),
        errorRestActionCustom: this.error,

      })
    }
  }

  signup(): void {

    if (this.formCtrl.form.valid) {
      CwWeb.loaderShow();
      this.formCtrl.value.email = AppDataSignBus.email;

      if (AppDataSignBus.action === 'PASSWORD') {
        CwUserResetBus.do(<CwUserResetDoI>{
          Bus: CwUserResetBus,
          Parameters: {
            password: AppDataSignBus.password,
            token: this.token,
          },
          errorActionCustom: this.error,
          errorRestActionCustom: this.error,
          successActionCustom: ((Data, That) => {
            CwWeb.loaderHide();
            App.Body.visible = false;
            App.Feedback = Data;
            CwUserLogin.do(<CwUserLoginDoI>{
              Bus: CwUserLogin,
              Parameters: AppDataSignBus,
            })
          })
        });
      } else {
        this.formCtrl.value.contact = this.formCtrl.value.firstName + this.formCtrl.value.lastName;
        this.formCtrl.value.jid = AppDataSignBus.jid;
        this.formCtrl.value.applicant = AppDataSignBus.applicant;
        this.formCtrl.value.trial = this.TRIAL;
        CwTrialBus.do(<CwEntityTrialDoI>{
          PayLoadCustom: <PayLoadCustomI>{
            ...this.formCtrl.value,
            // 2003111232
            country: this.Country.api,
            // 2005060000
            coupon: this.Coupon.api,
            // 2003111232
            state: (
              this.State &&
              this.State.web &&
              true
            )
              ? this.State.web
              : ''
            ,
            TransactionId: CwUtil.uuid(),
          },
          Bus: CwTrialBus,
          successActionCustom: ((Data, That) => {
            CwWeb.loaderHide();
            App.Body.visible = false;
            App.Feedback = Data;
            if (this.type === APP_DATA_SIGN_UP.TYPE.INVITATION) {
              AppPlansTool.Buy.SignIn.email = AppDataSignBus.email;
              AppPlansTool.Buy.SignIn.password = this.formCtrl.form.controls.password;
              CwWeb.goTo({path: 'datasignin'});
            } else {
              if (
                AppDataSignBus &&
                AppDataSignBus.jid === '' &&
                AppDataSignBus.email &&
                true
              ) {
                CwWeb.goTo({path: '/verify/' + AppDataSignBus.email});
              } else if (
                AppDataSignBus &&
                AppDataSignBus.jid &&
                true
              ) {
                CwWeb.goTo({path: '/datasignin'})
              } else {
                AppWebTool.Title = {
                  main: 'Check Your Inbox',
                }
              }
            }
          }),
          errorActionCustom: this.error,
          errorRestActionCustom: this.error,
          Parameters: undefined,
        })
      }
    }

  }


}


/**
 * @version 2004150000-11
 */
@Component({
  selector: 'app-data-free-up',
  templateUrl: './data-sign-up.component.html',
})
export class FreeSingUpComponent extends DataSignUpComponent {
  // 2007291000
  TITLE = 'COLLABORATE Space Free Account';
  // TITLE = 'COLLABORATE Space Personal';
  // TITLE = 'COLLABORATE Space Free Trial';
  // TITLE = 'COLLABORATE Space Free';
  TRIAL = false;
}
