<div
  class="row"
  xxx="2007231543"
  [ngClass]="{'d-none':ready}"
>
  <div class="col text-center m-5 p-5">
    <i class="pi pi-spin pi-spinner cw-loader"
    ></i>
  </div>
</div>
<div [ngClass]="{'d-none': !ready}">
  <ng-content></ng-content>
</div>
