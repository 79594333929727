import {Component, OnDestroy, OnInit} from '@angular/core';
import {App} from '../app';
import {AppTool} from '../app-tool';
import {AppWebTool} from '../app-web.tool';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
})
/**
 * @version 1905301259
 */
export class HomeComponent implements OnInit, OnDestroy {

  PlanList = [
    {
      src: 'assets/images/icon-meetings.png',
      titleLiteral: 'Meetings',
      descriptionLiteral: 'Meet now or...'
    },
    {
      src: 'assets/images/icon-calls.png',
      titleLiteral: 'Calls',
      descriptionLiteral: 'Connect with...'
    },
    {
      src: 'assets/images/icon-messages.png',
      titleLiteral: 'Messages',
      descriptionLiteral: 'One-to-one...'
    },
    {
      src: 'assets/images/icon-contacts.png',
      titleLiteral: 'Channels',
      descriptionLiteral: 'Channels...'
    },
  ];

  ngOnDestroy(): void {
    App.Navigation.home = false;
  }

  ngOnInit(): void {
    AppTool.googleLog();
    App.Navigation.home = true;
    AppWebTool.Title = {
      main: 'Collaborate Anytime, Anywhere',
      secondary: 'Host a meeting',
      startHereVisible: true,
    }
  }


}
