import {CwLiteral} from '../../../literal/literal';
import {CW_SPACE_SUBSCRIPTION_TOOL} from './tool-space-subscription-model';
import {CwBusClass} from '../../../model/bus';
import {CwDateTool} from '../../date/date.tool';
import {DateAddModel} from '../../date/date.model';
import {CwIs} from '@tool/tool-is';
import {CwUtil} from '@tool/util/cw-tool-util';

/**
 * @version 2002281031
 */
export const CwSpaceSubscriptionTool = {


  // 2006020000.15.4
  Define: {

    available: ((Params: {
      CompleteList: any[],
      ExcludedList: any[],
      equalFunction?: any,
    }): any[] => {
      const _List = [];
      if (Params && Params.CompleteList && Params.ExcludedList) {
        if (!Params.equalFunction) {
          Params.equalFunction = CwSpaceSubscriptionTool.Define.equal;
        }
        Params.CompleteList.forEach(Item => {
          if (
            Item &&
            Params.ExcludedList.filter(
              Filter => Params.equalFunction(Item, Filter)
            ).length === 0
          ) {
            _List.push({
              ...CwUtil.clone(Item),
              quantity: 0,
            });
          }
        });
      }
      return _List;
    }),

    equal: ((ItemA, ItemB): boolean => {
      let _equal = false;
      if (ItemA && ItemB) {
        _equal = (
          ItemA.interval === ItemB.interval &&
          ItemA.id_collaborate_package === ItemB.id_collaborate_package
        );
      }
      return _equal;
    }),

    current: ((Params: {
      AmountList: any[],
      CurrentList: any[],
      equalFunction?: any,
      ExcludedList?: any[],
    }): any[] => {
      const _List = [];
      if (Params) {
        if (!Params.equalFunction) {
          Params.equalFunction = CwSpaceSubscriptionTool.Define.equal;
        }
        if (!Params.ExcludedList) {
          Params.ExcludedList = [];
        }
        if (
          Params.AmountList &&
          Params.CurrentList &&
          Params.equalFunction &&
          Params.ExcludedList
        ) {
          Params.CurrentList.forEach(CurrentItem =>
            Params.AmountList.forEach(AmountItem => {
              if (
                Params.equalFunction(CurrentItem, AmountItem) &&
                Params.ExcludedList.filter(FilterItem => (
                  Params.equalFunction(CurrentItem, FilterItem)
                )).length === 0
              ) {
                _List.push({
                  ...CwUtil.clone(CurrentItem),
                  amount: AmountItem.amount,
                });
              }
            }));
        }
      }
      return _List;
    })
  },
  // 2002281031-4
  defineEnd: (_Subscription: any): Date => {
    const Params: DateAddModel = {
      Source: new Date(),
      months: (
        _Subscription.interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.API
      )
        ? _Subscription.interval_count
        : null
      ,
      years: (
        _Subscription.interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API
      )
        ? _Subscription.interval_count
        : null
    };
    // interval
    // interval_count
    // if (_Subscription)
    return CwDateTool.add(Params);
  },

  Gift: {
    defineTrialEnd: (_Plan: any): Date => {
      let TrialEnd = null;
      if (
        _Plan &&
        CwSpaceSubscriptionTool.Gift.is(_Plan) &&
        _Plan.interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.GIFT.API &&
        (
          _Plan.trialDuration > 0 ||
          _Plan.interval_count > 0 ||
          false
        ) &&
        true
      ) {
        TrialEnd = CwDateTool.add({
          Source: new Date(),
          days: (_Plan.trialDuration)
            ? _Plan.trialDuration
            : _Plan.interval_count
        });
      }
      return TrialEnd;
    },

    is: (_Plan: any): boolean => {
      let _is = false;
      if (
        _Plan &&
        _Plan.type === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.GIFT &&
        true
      ) {
        _is = true;
      } else if (
        _Plan &&
        _Plan.trialDuration === 30 &&
        _Plan.trial === 1 &&
        _Plan.type === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.MAIN &&
        true
      ) {
        _is = true;
      } else if (
        // Sign up for trial - 2002281031-10
        _Plan &&
        _Plan.status === CW_SPACE_SUBSCRIPTION_TOOL.STATUS.TRIAL.API &&
        true
      ) {
        _is = true;
      }
      return _is;
    },
  },

  Web: {
    examplePlanItem: {
      'complete_description': [
        'Host up to 50 participants',
        '6GB of OnCloud Space',
        'Channels Management',
        'Classroom and Webinar Support'
      ],
      'currency': 'usd',
      'id_Plan': 'plan_EK32Nd1jUYjjv6',
      'id_collaborate_package': 'Pro_1',
      'interval': 'day',
      'interval_count': 90,
      'min_Subscription': 1,
      'name': 'Pro 90 Days Free Trial',
      'total_license_plans': 0,
      'trial': 0,
      'trialDuration': 0,
      'type': 'Gift',
      'cwKey': '289db8cb-5150-4a3b-88b8-415c27cdef6e',
      'coGridSelected': false
    },

    // 2002281031

    name: (PlanItem): string => {
      let _name = '';
      if (PlanItem && PlanItem.name) {
        _name = CwLiteral.translate(PlanItem.name);
        if (
          PlanItem &&
          PlanItem.interval &&
          PlanItem.interval !== 'day' &&
          true
        ) {
          // 2002271317
          if (PlanItem.interval.toLowerCase() === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.API) {
            _name += ' - ' + CwLiteral.translate(CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.LITERAL);
          } else if (PlanItem.interval.toLowerCase() === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API) {
            _name += ' - ' + CwLiteral.translate(CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.LITERAL);
          }
        }
      }
      return _name;
    }
  },


  Property: {
    _EndDate: (Subscription: any): Date => (
      Subscription &&
      Subscription.current_period_end &&
      true
    ) ? CwDateTool.utcToDate(Subscription.current_period_end, null)
      : null
    ,
    _cancelAt: (Subscription: any): Date => (
      Subscription &&
      Subscription.cancel_at &&
      true
    ) ? CwDateTool.utcToDate(Subscription.cancel_at, null)
      : null
    ,
    interval: (Params: {
      Subscription?: any,
      SubscriptionItem: any,
    }): string => {
      let _interval = (Params.SubscriptionItem.plan.interval)
        ? Params.SubscriptionItem.plan.interval
        : ''
      ;
      if (
        // Sign up for trial - 2002281031-10
        Params.Subscription &&
        Params.Subscription.status === CW_SPACE_SUBSCRIPTION_TOOL.STATUS.TRIAL.API
      ) {
        _interval = CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.GIFT.API;
      }
      return _interval;
    },

    intervalWeb: (Params: {
      Subscription?: any,
      SubscriptionItem: any,
    }): string => {
      if (Params.SubscriptionItem.plan.interval) {
        const intervalKey = Object.keys(CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL).find(
          key => CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL[key].API === Params.SubscriptionItem.plan.interval);
        return intervalKey ? CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL[intervalKey].LITERAL : '';
      }
    },


    renewedWeb: (Subscription: any): Date => (
      Subscription &&
      Subscription.current_period_end &&
      !Subscription.cancel_at_period_end &&
      true
    )
      ? CwDateTool.utcToDate(Subscription.current_period_end, null)
      : null
    ,
    canceledWeb: (Subscription: any): Date => (
      Subscription &&
      Subscription.current_period_end &&
      Subscription.cancel_at_period_end &&
      true
    ) ? CwDateTool.utcToDate(Subscription.current_period_end, null)
      : null
    ,
    type: (Params: {
      Subscription?: any,
      SubscriptionItem: any,
    }): string => {
      let _type = null;
      if (
        // Sign up for trial - 2002281031-10
        Params.Subscription &&
        Params.Subscription.status === CW_SPACE_SUBSCRIPTION_TOOL.STATUS.TRIAL.API
      ) {
        _type = CW_SPACE_SUBSCRIPTION_TOOL.TYPE.GIFT;
      } else if (
        Params.SubscriptionItem &&
        Params.SubscriptionItem.plan &&
        Params.SubscriptionItem.plan.type &&
        true
      ) {
        _type = Params.SubscriptionItem.plan.type;
      }
      return _type;
    },
  },
  // 2002281031
  Api: {
    defineList(Data: any, Bus: CwBusClass) {

      Bus.Api = [];

      for (const DataItem of Data) {
        let trial = false;
        if (DataItem.status === 'trialing') {
          trial = true;
        }
        Bus.Api.push(...DataItem.subsciptionsItems);
        for (const SubscriptionItem of DataItem.subsciptionsItems) {
          const Params = {
            Subscription: DataItem,
            SubscriptionItem,
          };
          const NewSubscriptionItem: any = {
            // 2002281031.3
            _EndDate: CwSpaceSubscriptionTool.Property._EndDate(DataItem),
            _cancelAt: CwSpaceSubscriptionTool.Property._cancelAt(DataItem),
            canceledWeb: CwSpaceSubscriptionTool.Property.canceledWeb(DataItem),
            cwKey: SubscriptionItem.id,
            id: DataItem.id,
            id_collaborate_package: SubscriptionItem.plan.id_collaborate_package,
            interval: CwSpaceSubscriptionTool.Property.interval(Params),
            intervalWeb: CwSpaceSubscriptionTool.Property.intervalWeb(Params),
            name: (trial)
              ? SubscriptionItem.plan.name + ' Trial'
              : SubscriptionItem.plan.name
            ,
            planId: SubscriptionItem.plan.id_Plan,
            quantity: SubscriptionItem.quantity,
            renewedWeb: CwSpaceSubscriptionTool.Property.renewedWeb(DataItem),
            type: CwSpaceSubscriptionTool.Property.type(Params),
            scheduled : SubscriptionItem.subscriptionItemSchedule,
          };

          // 2006020000
          NewSubscriptionItem.cancelAtWeb = CwSpaceSubscriptionTool.defineCancelAt(
            DataItem
          );
          // 2006020000
          NewSubscriptionItem.subscriptionWeb = CwSpaceSubscriptionTool.defineSubscription(
            NewSubscriptionItem.canceledWeb,
            NewSubscriptionItem.renewedWeb
          );
          // 2006020000
          NewSubscriptionItem.descriptionList = SubscriptionItem.plan.complete_description;
          NewSubscriptionItem.isDisabledRow = !SubscriptionItem.plan.public_plan;
          Bus.ItemsSet(NewSubscriptionItem);
        }
      }

    },


    // // 2002111109-5
    // defineSubscriptions: (ModalComponentData: any[]):any => {
    //   return Subscription;
    // },

  },

  // 2006020000
  defineCancelAt(Subscription: any): Date {
    return (
      Subscription &&
      Subscription.cancel_at &&
      true
    ) ? CwDateTool.utcToDate(Subscription.cancel_at, null)
      : null
      ;
  },

  defineSubscription(canceledWeb: Date, renewedWeb: Date): String {
    let subscription = '';
    if (CwIs.defined(canceledWeb)) {
      subscription = CwLiteral.translate('Expires') + ' ' + CwDateTool.localeWithTimeZone(canceledWeb, true);
    } else if (CwIs.defined(renewedWeb)) {
      subscription = CwLiteral.translate('Renews') + ' ' + CwDateTool.localeWithTimeZone(renewedWeb, true);
    }
    return subscription;
  }

};
