<div class="container-fluid px-0">


  <div
    class="h4 font-bold text-center m-0"
  >{{getName}}</div>

  <div
    class="h-6 m-0 text-center"
    style="height: 50px !important;"
  >
    <cw-text-middle>
      <div
        [translate]="PlanNew.top"
      ></div>
    </cw-text-middle>
  </div>


  <div
    class="text-center"
    style="height: 100px !important;"
  >
    <cw-text-middle>

      <ng-container *ngIf="PlanNew.amountReplace">
        <ng-container *ngIf="isFree">
          <div class="h3 font-bold m-0 2007291000"
               [translate]="PlanNew.amountReplace"
          ></div>
          <div
            class="d-flex justify-content-center invisible"
          >
            <div class="icon icon-calendar"></div>
            <div class="px-2" translate="host/month"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isFree">
          <div class="2007291000"
               [translate]="PlanNew.amountReplace"
          ></div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!PlanNew.amountReplace">


        <ng-container *ngIf="getTotalHostMonth">
          <div class="h3 font-bold m-0">{{ getTotalHostMonth | cwCurrency:CwToolCurrency.getSelectedCode}}</div>
          <div
            class="d-flex justify-content-center"
          >
            <div class="icon icon-calendar"></div>
            <div class="px-2" translate="host/month"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!getTotal">
          <div class="h3 font-bold m-0">${{ getTotal }}</div>
        </ng-container>


      </ng-container>

    </cw-text-middle>
  </div>


  <div class="row">
    <div class="col-12 text-center h6 small mt-2"
         [translate]="PlanNew.hosts"
    ></div>
  </div>


  <div class="mt-3 mb-2">
    <cw-space-plan-button [PlanNew]="PlanNew"></cw-space-plan-button>
  </div>

  <div class="row justify-content-center">
    <div class="col-11">
      <hr>
    </div>
  </div>

  <div class="font-bold d-flex mb-2">
    <div>{{getName}}</div>
    <div class="px-1" translate="Features"></div>
  </div>
  <cw-space-plan-description
    [DescriptionList]="PlanNew.complete_description"
  ></cw-space-plan-description>

</div>
