import {LiteralBusClass} from '../literal-bus';
import {CwDefineUrl, CwFunction, CwMap} from '../../../model/model';
import {CwStateBus} from '../state/state-bus';
import {CwRestRequestParametersI} from '../../../tool/rest/model';
import {CwCountryStateBus} from '../country-min/country-min-bus';

/**
 * @version 1905281612
 */
export class CwCountryBus extends LiteralBusClass {

  CurrencyMap: CwMap<any> = new Map<string, any>();

  ItemsKey = 'alpha2Code';


  DefineProperties: CwFunction[] = [
    ( // CurrencyMap
      (Properties): void => {
        if (
          Properties &&
          Properties.currencies &&
          Properties.currencies.length > 0
        ) {
          Properties.currencies.forEach(
            (Item: any): void => {
              try {
                this.CurrencyMap.set(Item.code.toUpperCase(), Item);
              } catch (e) {
              }
            }
          )
        }
      }
    ), // CurrencyMap
    // api
    ((Properties) => {
        if (
          Properties &&
          Properties.name &&
          Properties.name.common &&
          true
        ) {
          Properties.api = Properties.name.common
        } else if (
          Properties &&
          Properties.name
        ) {
          Properties.api = Properties.name
        } else {
          Properties.api = '';
        }
      }
    ),
    // web
    ((Properties) =>
        // 1906131130
        Properties.web = Properties.api
    ),
    // countryCode
    ((Properties) =>
        Properties.countryCode = Properties.alpha2Code
    )
  ];

  defineUrl: CwDefineUrl = (() => {
    return this.path + 'data/country-2020.json';
  });

  do: CwFunction = ((Param: CwRestRequestParametersI) => {
    const CountryB = this;
    this.restGet({
      Bus: this,
      successActionCustom: ((CountryData) => {
        CwCountryStateBus.Load.do(<CwRestRequestParametersI>{
            successActionCustom: (() => {
              CwStateBus.Load.do(<CwRestRequestParametersI>{
                Bus: CwStateBus,
                successActionCustom: ((StateData) => {

                  CwCountryStateBus.ItemsList.forEach(CountryStateItem => {
                    const StateList = CwStateBus.ItemsList.filter((StateItem) => StateItem.country_id === CountryStateItem.id);
                    const Country = CountryB.getById(CountryStateItem.sortname);
                    if (Country) {
                      Country.StateList = StateList;
                      Country.phoneCode = CountryStateItem.phonecode
                      if (Country.languages) {
                        Country.mainLanguageCode = Country.languages[0].iso639_1;
                      }
                    }
                  });

                  if (Param && Param.successActionCustom) {
                    Param.successActionCustom();
                  }

                }),
                errorActionCustom: ((Data) => {
                  console.log('Error', '1905291841', Data);
                }),
                errorRestActionCustom: ((Data) => {
                  console.log('Error', '1905291840', Data);
                }),
              });
            }),
            errorActionCustom: ((Data) => {
              console.log('Error', '1905291842', Data);
            }),
            errorRestActionCustom: ((Data) => {
              console.log('Error', '1905291843', Data);
            }),

          }
        )


        // })
      }),
      errorActionCustom: ((Data) => {
        console.log('Error', '1905291844', Data);
      }),
      errorRestActionCustom: ((Data) => {
        console.log('Error', '1905291845', Data);
      }),
    })
  })

  // // 2005210000
  // definePropertyId(Properties: any): void {
  //   if (Properties && Properties.cca2) {
  //     Properties.cwKey = Properties.cca2
  //   }
  // }

  constructor() {
    super();
    this.do();
  }

  clear() {
    super.clear();
    this.CurrencyMap.clear();
  }

}

export const CwCountry = new CwCountryBus();
