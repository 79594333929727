import {Component, OnDestroy, OnInit} from '@angular/core';
import {App} from '../app';
import {MeetingIdComponent} from '../meeting-id/meeting-id.component';
import {AppWebTool} from '../app-web.tool';

@Component({
  selector: 'app-host',
  templateUrl: './host.component.html',
})
/**
 * @version R2.0 - Host a Meeting and Join a Meeting
 */
export class HostComponent extends MeetingIdComponent implements OnInit, OnDestroy {


  ngOnInit() {
    App.Navigation.host = true;
    App.Navigation.home = false;

    AppWebTool.Title = {
      main: 'Host a Meeting',
      secondary: '',
      startHereVisible: false,
    };

    // 2001101133
    // App.Protocol.SelectConnection.flag = false;
  }


  ngOnDestroy(): void {
    App.Navigation.host = false;
  }

}
