<ng-container *ngIf="Item.focus">
  <input
    [(ngModel)]="Item.value"

    [disabled]="getDisabled"
    [id]="Item.id"
    [maxLength]="maxLength(Item)"
    [name]="Item.id"
    [ngClass]="getClass"

    (blur)="settingChange(Item)"
    (keydown.enter)="keyDownEnter(Item, $event)"
    (keyup.enter)="settingChange(Item)"

    appCw
    appCwFocus
    class="form-control form-control-sm"
    min="0"
    type="number"
  >
</ng-container>

<ng-container *ngIf="!Item.focus">
  <input
    [(ngModel)]="Item.value"

    [disabled]="getDisabled"
    [id]="Item.id"
    [maxLength]="maxLength(Item)"
    [name]="Item.id"
    [ngClass]="getClass"

    (blur)="settingChange(Item)"
    (keydown.enter)="keyDownEnter(Item, $event)"
    (keyup.enter)="settingChange(Item)"

    class="form-control form-control-sm"
    min="0"
    type="number"
  >
</ng-container>
