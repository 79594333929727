import {CwWeb} from '@tool/web/tool-web';
import {AppWebTool} from './app-web.tool';
import {isBoolean} from 'util';
import {OnDestroy, OnInit} from '@angular/core';
import {App} from './app';
import {CwOnP} from '@tool/on-premise/tool-on-premise';

export class AppCw implements OnDestroy, OnInit {

  CwOnP = CwOnP;
  App = App;
  ngOnDestroy(): void {
    this.resetAll();
    this.OnDestroy();
  }

  OnDestroy(): void {
    // 1907030844
    this.App.downVisible = false;
  }

  ngOnInit(): void {
    this.resetAll();
    this.OnInit();
    CwWeb.fitty();
  }

  OnInit(): void {
  }

  resetAll(): void {
    if (
      App &&
      App.resetFeedBack &&
      true
    ) {
      App.resetFeedBack();
    }
    if (
      AppWebTool &&
      AppWebTool.resetTitle &&
      true
    ) {
      AppWebTool.resetTitle()
    }

    if (
      App &&
      App.Navigation &&
      true
    ) {
      for (const navigationKey in App.Navigation) {
        if (
          navigationKey &&
          isBoolean(App.Navigation[navigationKey]) &&
          true
        ) {
          App.Navigation[navigationKey] = false;
        }
      }
    }

    // 1905281429
    if (
      App &&
      App.Body &&
      true
    ) {
      App.Body.visible = true;
    }
  }

}
