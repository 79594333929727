import {EntityBus} from '../entity-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwEntityTrialDoI, CwEntityTrialParametersI} from './trial-model';

/**
 * @version 1904101050
 */
class TrialBus extends EntityBus {
    DefineProperties: CwFunction[];


    defineUrl: CwDefineUrl = (() =>
            CwTrialBus.path + 'trial'
    );


    do: CwFunction = ((Parameters: CwEntityTrialDoI) => {
        CwTrialBus.restPost(Parameters);
    });

    Parameters: CwEntityTrialParametersI;
}

/**
 * @version 1904101050
 */
export const CwTrialBus = new TrialBus();


// @Injectable()
// export class SignUpService extends BasicService {
//
//     url = AppSettings.API_ENDPOINT + 'entity/trial';
//
//     constructor(private http: Http, private router: Router) {
//         super();
//     }
//
//     signup(json: string): Observable<Result> {
//
//         let headers = new Headers({'Content-type': 'application/json'});
//         let options = new RequestOptions({headers: headers});
//
//         return this.http.post(this.url, json, options)
//             .map(this.extractData.bind(this))
//             .catch(this.handleError);
//     }
//
//     extractData(res: Response) {
//         let body = res.json();
//         return body;
//     }
//
// }
