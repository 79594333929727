import {CwRestRequestParametersI} from '../../tool/rest/model';
import {CwBusParametersI} from '../../model/model';

export interface CwVersionCheckDoI extends CwRestRequestParametersI {
  Parameters: CwVersionCheckDoParametersI;
}

export interface CwVersionCheckDoParametersI extends CwBusParametersI {
  entityId: number;
  platform?: CwVersionCheckPlatformT;
  token?: string;
}


// export interface CwVersionCheckDoTokenI {
//   token: string
//   platform?: CwVersionCheckPlatformT;
//   entityId?: number;
// }
//
// export type CwVersionCheckDoT: CwVersionCheckDoEntityI & CwVersionCheckDoTokenI;


export enum CwVersionCheckPlatform {
  windows = 1000,
  mac = 1001,
  android = 1011,
  ios = 1010,
  other = 9999,
}


export type CwVersionCheckPlatformT =
  CwVersionCheckPlatform.android |
  CwVersionCheckPlatform.ios |
  CwVersionCheckPlatform.mac |
  CwVersionCheckPlatform.windows |
  null
  ;
