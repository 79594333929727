<ng-container *ngIf="Coupon.visible">
<app-data-sign-up-passive-trial
  [couponExternal]="this.Coupon.value"
  ></app-data-sign-up-passive-trial>
</ng-container>

<!--<ng-container *ngIf="Coupon.visible">-->
<!--  <app-data-sign-up-->
<!--    [couponExternal]="Coupon.value"-->
<!--  ></app-data-sign-up>-->
<!--</ng-container>-->
