import {UserBus} from '../user-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwToolKeepC} from '../../tool/keep/tool-keep';
import {CwWeb} from '../../tool/web/tool-web';
import {CwUserPwdDoI} from './pwd-model';

/**
 *  @version 1904251312
 */
class PwdBus extends UserBus {
  DefineProperties: CwFunction[];
  Keep: CwToolKeepC<any>;


  SuccessActionList = [
    (Data, That) => {
      CwWeb.goTo({path: '/verify/' + CwUserPwd.Setup.email});
    },
  ];

  parameterRuleValidation = (() => true);

  defineUrl: CwDefineUrl = (() => {
    return CwUserPwd.path + 'pwd/?email=' + CwUserPwd.Setup.email;
  });


  do: CwFunction = ((DoParameters: CwUserPwdDoI) => {
    if (
      DoParameters &&
      DoParameters.Parameters &&
      DoParameters.Parameters.email &&
      true
    ) {
      CwUserPwd.Setup.email = DoParameters.Parameters.email;
      CwUserPwd.restGet(DoParameters)
    }
  });
}

export const CwUserPwd = new PwdBus();
