<div class="div-middle-6 mb-3">
  <div style="width: 80%; padding-left: 0; padding-right: 0; margin-right: auto; margin-left: auto">
    <ng-container *ngFor="let Literal of Literals">
      <ng-container
        xxx-2003111334
        *ngIf="Literal !== (Literal|translate) && installationBusReady"
      >
        <ng-container *ngTemplateOutlet="CheckTemplate;context:{text:Literal}"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #CheckTemplate let-text="text">
  <ng-container *ngIf="text.indexOf('###H1') > -1">
    <ng-container *ngTemplateOutlet="H1Template;context:{text:text}"></ng-container>
  </ng-container>
  <ng-container *ngIf="text.indexOf('###H2') > -1">
    <ng-container *ngTemplateOutlet="H2Template;context:{text:text}"></ng-container>
  </ng-container>
  <ng-container *ngIf="!(text.indexOf('###H1') > -1) && !(text.indexOf('###H2') > -1) ">
    <ng-container *ngTemplateOutlet="ParagraphTemplate;context:{text:text}"></ng-container>
  </ng-container>

</ng-template>


<ng-template #H1Template let-text="text">
  <div class="main pt-2">
    <ng-container *ngTemplateOutlet="SectionTemplate;context:{text:text}"></ng-container>
  </div>
</ng-template>

<ng-template #H2Template let-text="text">
  <div class="sub-main pt-2">
    <ng-container *ngTemplateOutlet="SectionTemplate;context:{text:text}"></ng-container>
  </div>
</ng-template>


<ng-template #SectionTemplate let-text="text">
  <div class="row pt-2">
    <div
      class="col-sm-12 text-justify"
      [translate]="text"
    ></div>
  </div>
</ng-template>


<ng-template #ParagraphTemplate let-text="text">
  <div class="row pt-2">
    <div class="col-sm-12 text-justify">
      <ng-container *ngIf="(text | translate).split('###').length > 1">
        <ul *ngFor="let item of (text | translate).split('###')">
          <li>{{item}}</li>
        </ul>
      </ng-container>
      <ng-container *ngIf="(text | translate).split('###').length === 1">
        {{text| translate}}
      </ng-container>
    </div>
  </div>
</ng-template>
