import {InfoBus} from '@rest/info/info.bus';
import {CwFunction} from '@cw/model/model';
import {CwRestRequestParametersI} from '@tool/rest/model';

class InfoContactSalesBus extends InfoBus {
  DefineProperties: CwFunction[];

  defineUrl = (() => this.path + 'contactSales')

  do: CwFunction = ((Params: CwRestRequestParametersI) => {
    if (Params) {
      this.restPost({
        ...Params,
        Bus: this,
      })
    }
  })

}

/**
 * @version 2005210000
  */
export const CwInfoContactSalesBus: InfoContactSalesBus = new InfoContactSalesBus();
