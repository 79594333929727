import {TokenBusClass} from '../token-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwTokenGetDoIDo, CwTokenGetParametersI} from './get-model';

/**
 * @version 1906261620
 * token/get
 */
class TokenGetBus extends TokenBusClass {
    // 1906261620
    ACTION = {
        REGISTRATION: 'PREREGISTRATION',
        RECOVER_PASSWORD: 'RECOVER_PASSWORD',
        ACTION_REQUEST_ADD_EMAIL: 'ACTION_REQUEST_ADD_EMAIL',
    };

    DefineProperties: CwFunction[];

    defineUrl: CwDefineUrl = (() =>
            CwTokenGet.path + 'get' +
            '?token=' + CwTokenGet.Parameters.token +
            '&action=' + CwTokenGet.Parameters.action +
            ''
    );


    do: CwFunction = ((Parameters: CwTokenGetDoIDo) =>
            CwTokenGet.restGet(Parameters)
    );

    Parameters: CwTokenGetParametersI;

}

export const CwTokenGet = new TokenGetBus();


// url2 = AppSettings.API_ENDPOINT + 'token/get';
//
// constructor(
//   private http: Http,
//   private router: Router
// ) {
//   super();
// }
//
// getData(token: string, action: string): Observable<any> {
//   let headers = new Headers({'Content-type': 'application/json'});
//   let options = new RequestOptions({headers: headers});
//   return this.http.get(this.url2 + '?token=' + token + '&action=' + action, options)
//     .map(this.extractData.bind(this))
//     .catch(this.handleError);
// }
