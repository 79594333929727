import {CW_PROTOCOL_TOOL} from './model';
import {CW_PROTOCOL_DEFAULT_DEFAULT, CW_PROTOCOL_FREE_WINDOWS} from './tool-protocol-device';


export const CW_PROTOCOL_DEFAULT = new Map()
  .set(CW_PROTOCOL_TOOL.DEVICE.DEFAULT, CW_PROTOCOL_DEFAULT_DEFAULT)
;

export const CW_PROTOCOL_FREE = new Map()
  // .set(CW_PROTOCOL_TOOL.DEVICE.DEFAULT, CW_PROTOCOL_DEFAULT_DEFAULT)
  .set(CW_PROTOCOL_TOOL.DEVICE.WINDOWS, CW_PROTOCOL_FREE_WINDOWS)
;

