import {EntityBus} from '../entity-bus';
import {CwBusParametersI, CwDefineUrl, CwFunction} from '../../model/model';
import {CwRestRequestParametersI} from '../../tool/rest/model';

class EntitySubdomainBus extends EntityBus {
    DefineProperties: CwFunction[];
    defineUrl: CwDefineUrl = (() =>
            CwEntitySubdomainBus.path +
            CwEntitySubdomainBus.Parameters.subdomain +
            '/entity'
    );
    do: CwFunction = ((DoParam: CwEntitySubdomainDoI) =>
            CwEntitySubdomainBus.restGet(DoParam)
    );

    Parameters: CwEntitySubdomainParametersI;
}

export const CwEntitySubdomainBus = new EntitySubdomainBus();

// // 1904101050
// getEntity(subdomain: string): Observable<any> {
//
//     const headers = new Headers({'Content-type': 'application/json', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache'});
// const options = new RequestOptions({headers: headers});
// url2 = AppSettings.API_ENDPOINT + 'entity/';
// return this.http.get(this.url2 + subdomain + '/entity', options)
//     .map(this.extractData.bind(this))
//     .catch(this.handleError);
// }

export interface CwEntitySubdomainParametersI extends CwBusParametersI{
    subdomain: string;
}

export interface CwEntitySubdomainDoI extends CwRestRequestParametersI, CwBusParametersI {
    Parameters: CwEntitySubdomainParametersI;
}
