import {BillingBus} from '../../../billing/billing-bus';
import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwToolKeepC} from '@tool/keep/tool-keep';
import {CwRestRequestMinParametersI} from '@tool/rest/model';
import {CwTaxDoI} from '@rest/billing/tax/tax-model';

class TaxBus extends BillingBus {

  tax: number;

  DefineProperties: CwFunction[] = [];
  Keep: CwToolKeepC<any>;
  Setup: any;
  defineUrl: CwDefineUrl = (() =>
          CwTaxBus.path + 'tax'
  );
  do: CwFunction = ((Param: CwTaxDoI) => {
    CwTaxBus.restPost({
      ...<CwRestRequestMinParametersI>Param,
      PayLoadCustom: {
        country : Param.country,
        business_vat_country_code: Param.countryCode,
        business_type_vat_id: Param.vatType,
        business_vat_id : Param.vatId
      },
      Bus: CwTaxBus,
      token: null,
    });
  });

}

export const CwTaxBus = new TaxBus();
