import {CwDefineUrl} from '../../../model/model';
import {CwRestRequestMinParametersI} from '../../../tool/rest/model';
import {CwTrust} from '../../../trust/trust';
import {WebinarBus} from '@rest/webinar/webinar-bus';
import {CwAnswerWebinarI} from '@rest/webinar/answer-questions-by-user/answer-questions-by-user-model';

export class AnswerQuestionsByUserBus extends WebinarBus {

  defineUrl: CwDefineUrl = (() => {
    return CwAnswerQuestionsByUserBus.path + 'answerQuestionsByUser';
  });

  do = ((Params: CwAnswerWebinarI) => {
    if (Params) {
      CwAnswerQuestionsByUserBus.bearer = Params.token;
      CwAnswerQuestionsByUserBus.restPost({
        ...<CwRestRequestMinParametersI>Params,
        PayLoadCustom: <CwAnswerWebinarI> Params,
        Bus: this
      });
    }
  });

}


export const CwAnswerQuestionsByUserBus = new AnswerQuestionsByUserBus();
