import {CwDefineUrl, CwFunction} from '../../model/model';
import {UserBus} from '../user-bus';
import {CwToolKeepC} from '../../tool/keep/tool-keep';
import {CwUserLoginDoI, CwUserLoginParametersI} from './login-model';

/**
 * @version 1904101050
 */
class UserLoginBus extends UserBus {
    Parameters: CwUserLoginParametersI;
    DefineProperties: CwFunction[];
    Keep: CwToolKeepC<any>;
    defineUrl: CwDefineUrl = (() =>
            // TODO-1904101050 Subdomain
            CwUserLogin.path +
            'login?email=' +
            CwUserLogin.Parameters.email +
            '&password=' +
            encodeURIComponent(CwUserLogin.Parameters.password) +
            (
                (CwUserLogin.Parameters.domain)
                    ? '&domain=' + CwUserLogin.Parameters.email
                    : ''
            ) +
            (
              (CwUserLogin.Parameters.reCaptchaV3Token)
                ? '&reCaptchaToken=' + CwUserLogin.Parameters.reCaptchaV3Token
                : ''
            ) +
            '&web=true' +
            ''
    );
    parameterRuleValidation = (() => (
        CwUserLogin &&
        CwUserLogin.Parameters &&
        CwUserLogin.Parameters.email &&
        CwUserLogin.Parameters.password &&
        true
    ));


    do: CwFunction = ((Parameters: CwUserLoginDoI) => {
        CwUserLogin.restGet(Parameters);
    });


}

/**
 * @version 1904101050
 */
export const CwUserLogin = new UserLoginBus();


// signin(email: string, password: string): Observable<Result> {
//
//   let headers = new Headers({'Content-type': 'application/json'});
//   let options = new RequestOptions({headers: headers});
//
//
//   console.log('subdomain ' + this.user.domain);
//
//   let loginUrl = this.url + '?email=' + email + '&password=' + encodeURIComponent(password) + '&web=true';
//
//   if (this.user.domain && this.user.domain.length > 0) {
//     loginUrl = loginUrl + '&domain=' + this.user.domain;
//   }
//
//   return this.http.get(loginUrl, options)
//     .map(this.extractData.bind(this))
//     .catch(this.handleError);
// }

// extractData(res: Response) {
//     const body = res.json();
//
//     if (body.errorCode === 0) {
//         let found = false;
//
//         body.roles.forEach(eachObj => {
//             if (
//                 eachObj.componentName === 'OWNER' ||
//                 eachObj.componentName === 'ADMINISTRATOR' ||
//                 eachObj.componentName === 'ADMINISTRATOR_BILLING'
//             ) {
//                 found = true;
//             }
//         });
//
//         if (found) {
//             this.router.ngOnDestroy();
//             if (
//                 body &&
//                 body.token &&
//                 true
//             ) {
//                 if (App.KeepToken) {
//                     App.KeepToken.Keep = body.token;
//                 }
//                 CwWeb.goToAdmin(body.token);
//             } else {
//                 App.KeepToken.remove();
//             }
//         } else {
//             this.user.name = body.name;
//             this.router.navigate(['/home']);
//         }
//     } else {
//         return body;
//     }
// }
