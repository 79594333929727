import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-summary-rates',
  templateUrl: './summary-rates.component.html',
  styleUrls: ['./summary-rates.component.css']
})
export class SummaryRatesComponent implements OnInit {

  errorMessage;

  constructor() {
  }

  ngOnInit() {

    // // 1904101050
    // if (this.ratesService.globalCountriesRates.length == 0) {
    //   console.log('entra');
    //   this.ratesService.getPhoneRates().subscribe(
    //     countriesRates => {
    //       console.log('entra ' + countriesRates);
    //
    //     },
    //     error => this.errorMessage = <any>error);
    //
    //
    //   this.ratesService.getPhoneRates().subscribe();
    // } else {
    //   console.log('no entra ' + this.ratesService.globalCountriesRates);
    // }
  }

}
