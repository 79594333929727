import {CwToolKeepC} from '../keep/tool-keep';
import {CwSessionKeepModel, CwSessionModel} from './session.model';

/**
 * @version 1910280940
 */
export class SessionTool implements CwSessionModel {
  private _email: string;
  private Keep: CwToolKeepC<CwSessionKeepModel> = new CwToolKeepC({
    // TODO-1907110924
    key: 'CS-Session',
  });

  get email(): string {
    if (this._email) {
      return this._email;
    } else {
      const Session: CwSessionKeepModel = this.Keep.Keep;
      if (Session) {
        return Session.email;
      } else {
        return null;
      }
    }
  }

  set = ((Params: CwSessionModel) => {
    this._email = Params.email;
    this.Keep.Keep = {
      email: Params.email,
    }
  })
}


export const CwSessionTool = new SessionTool();
