import {CoAppBusClass} from '../app/bus';
import {CoAppFeedbackItemClass} from './model';
import {CoTools} from '../tools/tools';

/**
 * @version R4.0 - Mobile - 1810040937
 */
class CoFeedbackBusClass extends CoAppBusClass {
  add(_message: string): void {
    const key = CoTools.uuid();
    this.ItemsMap.set(key, new CoAppFeedbackItemClass(key, _message));
  }
}

export const CoFeedbackBus = new CoFeedbackBusClass();

