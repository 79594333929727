import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CwGoogleRecaptchaComponent} from '@cw/components/google/recaptcha/cw-google-recaptcha.component';
import {RecaptchaModule} from 'ng-recaptcha';

@NgModule({
  imports: [
    CommonModule,
    RecaptchaModule
  ],
  exports: [
    CwGoogleRecaptchaComponent
  ],
  declarations: [CwGoogleRecaptchaComponent]
})
export class CwGoogleRecaptchaModule {
}
