import * as _ from 'lodash';
import {CoIs} from './is';
import {CoLog} from './log';

/**
 * @since 4.0.0.0.R9.0 - Admin Project
 * @version R36.5 - Resources
 */
export class CoUtils {

  // R52.5 - The chips are dynamically adjusted
  public static CONFIG = {
    // FIX_EXPRESSION_CHANGED_ERROR_TIME_SPAN: 25
    // FIX_EXPRESSION_CHANGED_ERROR_TIME_SPAN: 50
    FIX_EXPRESSION_CHANGED_ERROR_TIME_SPAN: 100
    // FIX_EXPRESSION_CHANGED_ERROR_TIME_SPAN: 200
  };

  public static configure(config: any): void {
    CoUtils.CONFIG = config;
  }


  /**
   * @param data
   * @returns {any}
   * TODO: remove lodash to other method 15/09/2017
   * [CoPlansBus].getFilter(item => true)[0]
   * @version R29.0 - Billing - Migration to work in production mode.
   */
  public static cloneDeep(data: any): any {
    // return Object.assign({}, data);
    return _.cloneDeep(data);
  }

  /**
   * @since 4.0.0.0.R10.0 - Resources
   * @param {number} min
   * @param {number} max
   */
  public static random(min: number, max: number): number {
    let _random: number;
    if (min < max) {
      _random = Math.round(Math.random() * (max - min)) + min - 1;
    }
    return _random;
  }

  /**
   * @since 4.0.0.0.R9.6 - Hotfix - Teams update error
   * @param data
   * @returns {any}
   */
  public static cloneData(data: any): any {
    let clone: any = null;
    if (CoIs.defined(data)) {
      clone = Object.assign({}, data);
    }
    return clone;
  }

  /**
   *
   * @param _source
   * @param target
   * @param {string[]} _mapList [ 'sourceKey,targetKey' ...]
   * @returns {boolean}
   * @version R34.0 - New Design
   */
  public static setPropertiesMap(_source: any,
                                 target: any,
                                 _mapList: string[] = []): boolean {
    let _util = false;
    try {
      if (
        CoIs.defined(_source, target, _mapList) &&
        CoIs.arrayNotEmpty(_mapList)
      ) {

        _mapList.forEach(_pairItem => {
          const _key = _pairItem.split(',');
          target[_key[1]] = _source[_key[0]];
        });
        _util = true;
      }

    } catch (error) {
      _util = false;
    }
    return _util;
  }

  /**
   * @param _source
   * @param target
   * @since 4.0.0.0.R10.0 - Resources
   * @version 4.0.0.0.R16.2 - Account - LDAP
   */
  public static setProperties(_source: any,
                              target: any,
                              _propertiesList: string[] = []): boolean {
    if (CoIs.defined(_source, target)) {
      if (CoIs.arrayEmpty(_propertiesList)) {
        _propertiesList = Object.keys(_source);
      }
      _propertiesList.forEach(keyItem => {
        try {
          if (target[keyItem] !== _source[keyItem]) {
            target[keyItem] = _source[keyItem];
          }
        } catch (error) {
          CoLog.error('201802201746', 'CoUtils.setProperties', error, keyItem, _source[keyItem]);
        }
      });
    }
    return true;
  }

  /**
   * @param value1
   * @param value2
   * @returns {number}
   * @version R24.0 - Users - Migration to work in production mode.
   */
  public static sortAsc(value1: any, value2: any): number {
    if (typeof value1 === 'string') {
      value1 = value1.toUpperCase();
    }
    if (typeof value2 === 'string') {
      value2 = value2.toUpperCase();
    }
    if (undefined === value1 || undefined === value2) {
      return 0;
    }
    if (value1 > value2) {
      return 1;
    }
    if (value1 < value2) {
      return -1;
    }
    return 0;
  }

  /**
   *
   * @param value1
   * @param value2
   * @returns {number}
   * @version R24.0 - Users - Migration to work in production mode.
   */
  public static sortDesc(value1: any, value2: any): number {
    return (-1) * CoUtils.sortAsc(value1, value2);
  }

  /**
   * @param {string} prefix
   * @returns {string}
   * @version R29.0 - Billing - Migration to work in production mode.
   */
  // public static uuid(_prefix = ''): string {
  //   let _uuid = '';
  //
  //   try {
  //     _uuid = UUID.UUID();
  //   } catch (error) {
  //
  //   }
  //
  //   return _prefix + _uuid;
  // }

  public static intervalStop(interval: any): any {
    if (CoIs.defined(interval)) {
      if (CoIs.defined(interval.cancelFn)) {
        interval.cancelFn(interval);
      }
      clearInterval(interval);
      interval = undefined;
    }
    return interval;
  }

  public static crc(data: any): number {
    let _crc = 0;
    try {
      // _crc = CRC.default('CRC16_CCIT_ZERO').compute(Buffer.from(JSON.stringify(data)));
    } catch (e) {

    }
    return _crc;
  }
}
