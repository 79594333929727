import {BillingBus} from '../../../billing/billing-bus';
import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwToolKeepC} from '@tool/keep/tool-keep';
import {CwDoParametersI, CwRestRequestMinParametersI} from '@tool/rest/model';

class FiscalIdsBus extends BillingBus {

  DefineProperties: CwFunction[] = [
    (Properties: any) => {
      if (Properties.type && Properties.countryCode) {
        const vatType = Properties.type.split('_')[1].toUpperCase();
        if (vatType) {
          Properties.vatTypeWeb = Properties.countryCode + ' ' + vatType;
        }
      }
    }
  ];
  Keep: CwToolKeepC<any>;
  Setup: any;
  defineUrl: CwDefineUrl = (() =>
      CwFiscalIdsBus.path + 'fiscalIds'
  );
  do: CwFunction = ((Param: CwDoParametersI) => {
    CwFiscalIdsBus.restGet({
      ...<CwRestRequestMinParametersI>Param,
      Bus: CwFiscalIdsBus,
      token: null,
    });
  });

}

export const CwFiscalIdsBus = new FiscalIdsBus();
