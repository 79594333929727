import {AppWebTitleModel} from './app-web.model';
import {CoFixed} from './co/utils/fixed';

class AppWebToolClass {
  resetTitle = (() => {
    this._Title = {
      main: '',
      secondary: '',
      startHereVisible: false
    };
  });

  // 1904251312
  _Title: AppWebTitleModel = {
    main: '',
    secondary: '',
    startHereVisible: false
  };

  get Title(): AppWebTitleModel {
    return this._Title;
  }

  set Title(Value: AppWebTitleModel) {
    this.resetTitle();
    this._Title.main = Value.main;
    CoFixed.expressionChangedError(
      {},
      ((Data) => {
        this.Title.secondary = (Value.secondary)
          ? Value.secondary
          : undefined
        ;
        this.Title.startHereVisible =
          (Value.startHereVisible)
            ? Value.startHereVisible
            : false
        ;
      })
    )
  }

}

/**
 * @version 2005270000-1
 */
export const AppWebTool: AppWebToolClass = new AppWebToolClass();
