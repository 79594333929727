<div class="d-flex flex-row justify-content-center py-5 cw-bg-2">
  <div class="d-flex flex-row align-items-center">
    <div color="pink"
         class="cw-div-meeting-host cw-div-115 pr-2"
    ></div>
    <div class="d-flex flex-column">
      <div class="h2"
      >
        <span translate appTextFit>Host a Meeting</span>
      </div>
      <button appCw appCwForm
              routerLink="/hostmeeting"
              type="button"
      >
        {{'Host a Meeting' | translate | uppercase}}
      </button>
    </div>
  </div>
  <div class="d-flex flex-row">
    <div class="cw-w-50 border-dark border-right"></div>
    <div class="cw-w-50 border-0"></div>
  </div>
  <div class="d-flex flex-row align-items-center">
    <div color="pink"
         class="cw-div-meeting-join cw-div-115 pr-2"></div>
    <div class="d-flex flex-column">
      <div class="h2"
      >
        <span translate appTextFit>Join a Meeting</span>
      </div>
      <button appCw appCwForm
              routerLink="/meeting"
              type="button"
      >
        {{'Join a Meeting' | translate | uppercase}}
      </button>
    </div>
  </div>
  <!--    <div class="w-25 d-flex flex-row align-items-center">-->
  <!--        <div>-->
  <!--            <img class="cw-div-meeting-join  pr-3">-->
  <!--        </div>-->
  <!--        <div class="">-->
  <!--            <div translate-->
  <!--                 appTextFit-->
  <!--                 class="h2"-->
  <!--            >Join a Meeting-->
  <!--            </div>-->
  <!--            <div>-->
  <!--                <button routerLink="/hostmeeting"-->
  <!--                        type="button"-->
  <!--                        class="btn btn-important btn-300 font-secondary mr-0"-->
  <!--                >-->
  <!--                    {{'Join a Meeting' | translate | uppercase}}-->
  <!--                </button>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
</div>

<!--&lt;!&ndash;1904251312&ndash;&gt;-->
<!--<div class="background2">-->
<!--    <div style="width: 80%; padding-top: 20px; padding-bottom: 20px; padding-left: 0; padding-right: 0; margin-right: auto; margin-left: auto">-->
<!--        <div class="bg"></div>-->
<!--        <div class="row">-->
<!--            <div class="col-sm-5">-->
<!--                <div class="row">-->
<!--                    <div class="col-sm-2">-->
<!--                    </div>-->
<!--                    <div class="col-sm-2">-->
<!--                        <img src="assets/images/icon-hostAMeeting.png">-->
<!--                    </div>-->
<!--                    <div class="col-sm-8">-->
<!--                        <div style="padding-bottom: 20px"><h2 class="font-bold">{{'Host a Meeting' | translate}}</h2>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <p class="text-left">-->
<!--                                <button routerLink="/hostmeeting" type="button"-->
<!--                                        class="btn btn-important btn-300 font-secondary">-->
<!--                                    {{'Host a Meeting' | translate | uppercase}}-->
<!--                                </button>-->
<!--                            </p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-sm-1 co-vline" style="width:5px; height: 100px">-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--                <div class="row">-->
<!--                    <div class="col-sm-2">-->
<!--                    </div>-->
<!--                    <div class="col-sm-2">-->
<!--                        <img src="assets/images/icon-joinAMeeting.png">-->
<!--                    </div>-->
<!--                    <div class="col-sm-8">-->
<!--                        <div style="padding-bottom: 20px">-->
<!--                            <h2 class="font-bold">{{'Join a Meeting' | translate}}</h2>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <p class="text-left">-->
<!--                                <button routerLink="/hostmeeting" type="button"-->
<!--                                        class="btn btn-important btn-300 font-secondary">-->
<!--                                    {{'Join a Meeting' | translate | uppercase}}-->
<!--                                </button>-->
<!--                            </p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
