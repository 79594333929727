import {TokenBusClass} from '../token-bus';
import {CwDefineUrl, CwFunction, CwMap} from '../../../model/model';
import {CW_WEB_BUS, CwTokenGetWebI} from './get-web-model';
import {ACTIONS_API, ACTIONS_MAP} from '../../../../../environments/environment.define';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwVoIpBus} from '../../entity/modify-voip-service/bus';
import {CwUserGetVCardUsersBus} from '../../../user/get-v-cards-user/get-v-cards-user-bus';
import {CwUsersBus} from '../../entity/get-users/bus';
import {CwSocket} from '../../../socket/socket-management';
import {CwSocketActionModel, CwSocketActionPriorityModel, SOCKET_ACTION} from '../../../socket/socket-model';
import {CwErrorTool} from '../../../tool/error/error.tool';
import {CW_ERROR_TOOL} from '../../../tool/error/error.model';
import {AppDevelopTool} from '../../../../tool/develop/develop.tool';
import {CwTrust} from '@cw/trust/trust';

/**
 * @version 1909301204
 */
class TokenGetWeb extends TokenBusClass {

  /**
   * @version 1909061153
   */
  AdminActions = {
    Settings: {
      Branding: {
        enabled: ((): boolean => {
          return this.subOptionEnabled(ACTIONS_API.branding);
        }),
      },
      Ldap: {
        enabled: ((): boolean => {
          return this.subOptionEnabled(ACTIONS_API.ldap);
        }),
      },
      Searchable: {
        enabled: ((): boolean => {
          return this.subOptionEnabled(ACTIONS_API.searchable);
        }),
      },
      Recording: {
        enabled: ((): boolean => {
          return this.subOptionEnabled(ACTIONS_API.recording);
        }),
      },
    }
  };

  email = '';
  jid: string;

  Is = {
    admin: false,
    monitor: false,
    disabledVoIP: false,
    // 2008061200
    invoicesEnable: false,
    billingEnable: false,
    onPremise: false,
    owner: false,
    purchaseEnable: false,
    searchable: false,
    recordingOn: false,
  };

  Menu = {
    User: [],
    Admin: [],
  };


  // FUNCTIONs /////////////////////////////////////////////////////////////////
  entityDeleted = (() => {
    CwErrorTool.set({
      message: 'The entity has been deleted.',
      type: CW_ERROR_TOOL.TYPE.WARNING,
      code: CW_ERROR_TOOL.CODE.CONTACT_DEALER,
      signInShow: false,
    });
  });

  entityDisabled = (() => {
    CwErrorTool.set({
      message: 'The entity has been disabled.',
      type: CW_ERROR_TOOL.TYPE.WARNING,
      code: CW_ERROR_TOOL.CODE.CONTACT_DEALER,
      signInShow: false,
    });
  });

  definePropertyIs = ((Properties) => {
    // 2002111109-5
    this.Is.purchaseEnable = !!Properties.billingId && AppDevelopTool.Enabled.purchase;
    this.Is.onPremise = (
      Properties &&
      Properties.isOnPremise &&
      true
    );
  });

  DefineProperties: CwFunction[] = [
    // jid
    (Properties) => {
      if (Properties && Properties.jid) {
        this.jid = Properties.jid;
      }
    },
    this.definePropertyIs,
    // Avatar - 1910011410
    ((Properties) => {
      CwUsersBus.MAP_AVATAR.set(
        Properties.jid,
        {
          isImage: (Properties.binPhoto),
          photoUrl: (Properties.binPhoto)
            ? 'url(' + CwUserGetVCardUsersBus.imageUriByType(Properties.typePhoto, Properties.binPhoto) + ')'
            : undefined
          ,
          color: Properties.color,
          initial: Properties.initials
        }
      );
    }),

    ((Properties) => {
      CwVoIpBus.defineProperties(Properties);
    }),
    // Owner - 1909301204
    // Is.owner
    ((Properties) => {
      this.Is.owner = (
        Properties &&
        Properties.roles &&
        Properties.roles.filter(
          Item =>
            Item &&
            Item.componentName === 'OWNER' &&
            Item.quantity === 1 &&
            true
        ).length === 1 &&
        true
      );
    }),
    // Admin
    // Is.admin
    ((Properties) => {
      this.Is.admin = (
        Properties &&
        Properties.roles &&
        Properties.roles.filter(
          Item =>
            Item &&
            Item.componentName.startsWith('ADMINISTRATOR') &&
            true
        ).length > 0 &&
        true
      );
    }),
    // Monitor
    // Is.monitor
    ((Properties) => {
      this.Is.monitor = (
        Properties &&
        Properties.roles &&
        Properties.roles.filter(
          Item =>
            Item &&
            Item.componentName.startsWith('MONITOR') &&
            true
        ).length > 0 &&
        true
      );
      if (this.Is.monitor) {
        CwTrust.viewOnly = true;
      }
    }),
    // Is.disabledVoIP
    ((Properties) => {
      this.Is.disabledVoIP = Properties.disabledVoIP;
    }),
    // Is.searchable
    ((Properties) => {
      this.Is.searchable = Properties.customSearchable;
    }),
    // Is.recordingOn
    ((Properties) => {
      this.Is.recordingOn = Properties.meetingScreenRecording;
    }),


    // Advanced Menu definition
    ((Properties) => {
      if (
        Properties &&
        Properties.adminActions &&
        Properties.adminActions.length > 0 &&
        true
      ) {
        if (
          this.Is &&
          (
            this.Is.admin ||
            this.Is.owner ||
            this.Is.monitor ||
            false
          ) &&
          true
        ) {

          // ////////////////////////////////////////////////////////////////////////
          // // TODO-MEETINGS: remove
          // CwUtil.clear(Properties.userActions);
          // Properties.adminActions.push(CW_WEB_BUS.MENU.MEETINGS.DEVELOP);
          // ////////////////////////////////////////////////////////////////////////

          this.defineMenu({
            Actions: Properties.adminActions,
            Menu: this.Menu.Admin,
          });
        }
      }
    }),
    // User Menu definition
    ((Properties) => {
      if (
        Properties &&
        Properties.userActions &&
        Properties.userActions.length > 0 &&
        true
      ) {
        // ////////////////////////////////////////////////////////////////////////
        // // TODO-1912101603: remove
        // CwUtil.clear(Properties.userActions);
        // Properties.userActions.push(CW_WEB_BUS.MENU.OAUTH2.DEVELOP);
        // ////////////////////////////////////////////////////////////////////////

        // 2009290000
        this.defineMenu({
          Actions: Properties.userActions,
          Menu: this.Menu.User,
        });
      }
    }),

    // iFrameAdminOptions => ActionIframeList
    ((Properties) => {
      if (
        Properties &&
        Properties.iFrameAdminOptions &&
        true
      ) {
        Properties.ActionIframeList = [];
        Properties.iFrameAdminOptions.forEach(Item => {
          if (Item) {
            if (Item.subOptions) {
              Properties.ActionIframeList.push(...Item.subOptions);
            } else {
              Properties.ActionIframeList.push(Item);
            }
          }
        });
      }
    }),

    // email
    ((Properties) => {
      if (Properties && Properties.email) {
        this.email = Properties.email;
      }
    }),

    // language
    /*    ((Properties) => {
          if (
            Properties &&
            Properties.language &&
            true
          ) {
            CwEntityLanguageB.setLanguage(Properties);
          }
        }),*/


    ( // Show invoices
      (Properties) => {
        try {
          this.Is.invoicesEnable = Properties.adminActions.filter(
            (Filter): boolean => {
              return Filter.name === ACTIONS_API.onCloud;
            }
          )[0].subOptions.filter(
            (Filter): boolean => {
              return Filter.name === ACTIONS_API.invoices;
            }
          )[0];
        } catch (e) {
          this.Is.invoicesEnable = false;
        }
      }
    ), // Show Invoices

    ( // Show billing
      (Properties) => {
        try {
          this.Is.billingEnable = Properties.adminActions.filter(
            (Filter): boolean => {
              return Filter.name === ACTIONS_API.onCloud;
            }
          )[0].subOptions.filter(
            (Filter): boolean => {
              return Filter.name === ACTIONS_API.billingInformation;
            }
          )[0];
        } catch (e) {
          this.Is.billingEnable = false;
        }
      }
    ), // Show billing

  ]; // DefineProperties

  defineMenu = ((Params: {
    Actions: any[],
    Menu: any[],
  }) => {
    CwUtil.clear(Params.Menu);
    Params.Actions.forEach((ActionItem) => {
      if (
        ActionItem &&
        ActionItem.name &&
        ACTIONS_MAP.has(ActionItem.name) &&
        true
      ) {
        Params.Menu.push(ACTIONS_MAP.get(ActionItem.name));
      }
    });
  });

  defineUrl: CwDefineUrl = (() => this.path + 'getWeb');

  do: CwFunction = ((Params: CwTokenGetWebI) => {
    if (
      Params &&
      Params.token &&
      true
    ) {
      this.restGet({
        Bus: this,
        Parameters: {},
        ...Params,
      });
    }
  });


  get isOnPremise(): boolean {
    return this.Item.isOnPremise;
  }

  get isOwner(): boolean {
    return this.Is.owner;
  }

  get isAdmin(): boolean {
    return this.Is.admin;
  }

  get isMonitor(): boolean {
    return this.Is.monitor;
  }

  get isSearchable(): boolean {
    return this.Is.searchable;
  }

  get isRecordingOn(): boolean {
    return this.Is.recordingOn;
  }


  SocketActionMap: CwMap<CwSocketActionModel[]> = new Map()
    .set(
      SOCKET_ACTION.ENTITY.DELETED,
      [<CwSocketActionModel>{
        fn: this.entityDeleted,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.ENTITY.DISABLED,
      [<CwSocketActionModel>{
        fn: this.entityDisabled,
        priority: CwSocketActionPriorityModel.medium,
      }]
    );


  constructor(Parameters?: any) {
    super(Parameters);
  }


  subOptionEnabled(subOption: string) {
    let enabled = false;
    if (
      this.Item &&
      this.Item.adminActions &&
      this.Item.adminActions.length > 0 &&
      true
    ) {
      this.Item.adminActions.forEach(Item => {
        if (
          Item &&
          Item.name === ACTIONS_API.settings &&
          Item.subOptions &&
          Item.subOptions.length > 0 &&
          Item.subOptions.filter(
            Filter => Filter.name === subOption
          ).length > 0 &&
          true
        ) {
          enabled = true;
        }
      });
    }
    return enabled;
  }

}

export const CwWebBus: TokenGetWeb = new TokenGetWeb({
  Socket: CwSocket
});

