import {CoExpirationEnum} from '../enums/co-expiration.enum';
import {CoUtils} from './CoUtils';
import {CoIs} from './is';
import {CoArray} from './array';

/**
 * @version R66.0 - Trial - Standard - Pro - Licenses
 */
export class CoFixed {

  /**
   * @version R66.0 - Trial - Standard - Pro - Licenses
   */
  public static largeMeetingVisible(): boolean {
    return false;
  }

  /**
   * @version R59.11 - Users - Add LDAP - Not continue
   * @param {any[]} userList
   * @returns {any[]}
   */
  public static cleanLdapUsersInvalidToSpace(userList: any[]): any[] {
    const userValidateList = [];
    if (userList) {
      userList.forEach(userItem => {
        if (userItem) {
          if (
            CoIs.stringNotEmpty(userItem.email) &&
            CoIs.stringNotEmpty(userItem.name) &&
            CoIs.stringNotEmpty(userItem.state)
          ) {
            CoArray.pushOnly(userValidateList, userItem, 'email');
          }
        }
      });
    }
    return userValidateList;
  }

  /**
   * @version R57.0 - Remove team actions
   * @returns {string}
   */
  public static teamDefault(): string {
    return 'DEFAULT';
  }

  /**
   * @version R59.18 - Ticket 1788 - Sometimes Away group is duplicated
   * @param {string} statusSocket
   * @returns {string}
   */
  public static userStatus(userData: any): void {
    if (userData && userData.status) {
      // Ticket 1213 - Users status in statistics...
      if (userData.status === 'Disconnected') {
        userData.status = 'offline';
      } else if (userData.status === 'Online') {
        // Ticket 1548 - There are online and available status that are the same.
        userData.status = 'available';
      } else if (userData.status === 'away') {
        // Ticket 1548 - There are online and available status that are the same.
        // Ticket 1788 - Sometimes Away group is duplicated
        userData.status = 'Away';
      } else if (userData.status === 'xa') {
        userData.status = 'sptInvisible';
      } else if (userData.status === 'Do Not Disturb') {
        // from API 'Do Not Disturb', from IM 'dnd'
        // userData.status = CoResourcesConfig59.RESOURCE_STATUS_DND;
      }
    }
  }

  /**
   * @param {() => void} callback
   * @version R52.5 - The chips are dynamically adjusted
   */
  public static appliedChange(_in: any, callback: ((_in: any) => void)): void {
    // FIX: Wait for the changes to apply
    setTimeout(() => {
      try {
        callback(_in);
      } catch (error) {
      }
    }, CoExpirationEnum.ms001);
  }

  /**
   * First try to static functions remove, else use this function
   * @param {() => void} callback
   * @version R71.0 - Resources - Settings - Required
   */
  public static expressionChangedError(_in: any, callback: ((_in: any) => void)): void {
    // FIX: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: ''. ...
    setTimeout(() => {
      try {
        callback(_in);
      } catch (error) {
      }
    }, CoUtils.CONFIG.FIX_EXPRESSION_CHANGED_ERROR_TIME_SPAN);
  }


}
