import {Component, Input} from '@angular/core';
import {CW_SPACE_PLAN, CwSpacePlanModel} from '../plan/cw-space-plan.model';
import {CwFunction} from '../../../model/model';
import {CW_SPACE_SUBSCRIPTION_TOOL} from '../../../tool/space/subscription/tool-space-subscription-model';
import {CwFormatTool} from '@tool/format/tool-format';
import {CwTaxBus} from '@rest/billing/tax/tax-bus';
import {CwDateTool} from '@cw/tool/date/date.tool';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-space-summary',
  templateUrl: './cw-space-summary.component.html',
})
export class CwSpaceSummaryComponent {

  @Input() amountFunction: CwFunction;
  @Input() SelectedList: CwSpacePlanModel[];
  @Input() registration: boolean;

  CW_SPACE_PLAN = CW_SPACE_PLAN;
  CwDateTool = CwDateTool;
  CwToolCurrency = CwToolCurrency;

  amountBy = (interval?: string): number => {
    let _amountNow = null;
    if (this.getAvailable && this.amountFunction) {
      _amountNow = 0;
      this.SelectedList.forEach(Item => {
        if (
          Item &&
          (
            (
              interval &&
              Item.interval === interval
            ) ||
            !interval
          ) &&
          Item.quantity > 0 &&
          this.amountFunction &&
          this.amountFunction(Item)
        ) {
          _amountNow += (this.amountFunction(Item) * Item.quantity);
        }
      });
    }
    return _amountNow;
  };

  dateBy = (interval?: string): Date => {
    let _Date = null;
    if (this.getAvailable) {
      const _DateList = this.SelectedList.filter(Filter =>
        Filter &&
        interval &&
        Filter.interval === interval &&
        true
      );
      if (_DateList && _DateList[0]) {
        _Date = _DateList[0].Date;
      }
    }
    return _Date;
  };


  // constructor ///////////////////////////////////////////////////////////////
  constructor() {
    CwToolCurrency.subscribe(() => {
      if (this.SelectedList) {
        this.SelectedList.forEach((Item) => {
          if (Item) {
            Item.currency = CwToolCurrency.getSelectedCode;
          }
        });
      }
    });
  }

  // constructor ///////////////////////////////////////////////////////////////


  // GETs //////////////////////////////////////////////////////////////////////

  get getAvailable(): boolean {
    return (
      this.SelectedList &&
      this.amountFunction &&
      true
    );
  }

  get getAmountMonthly(): number {
    const beforeTaxesAmount = this.amountBy(CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.API) / 100;
    return this.taxesApplied() ? beforeTaxesAmount + (beforeTaxesAmount * CwTaxBus.tax / 100) : beforeTaxesAmount;
  }

  get getAmountToday(): number {
    const beforeTaxesAmount = this.amountBy() / 100;
    return this.taxesApplied()
      ? beforeTaxesAmount + (beforeTaxesAmount * CwTaxBus.tax / 100)
      : beforeTaxesAmount
      ;
  }

  get getAmountYearly(): number {
    const beforeTaxesAmount = this.amountBy(CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API) / 100;
    return this.taxesApplied() ? beforeTaxesAmount + (beforeTaxesAmount * CwTaxBus.tax / 100) : beforeTaxesAmount;
  }

  get getDateFormat(): string {
    let _dateFormat = CwFormatTool.Date.standard;
    if (
      this.SelectedList &&
      this.SelectedList[0] &&
      true
    ) {
      _dateFormat = this.SelectedList[0].dateFormat;
    }
    return _dateFormat;
  }

  get getDateMonthly(): Date {
    return this.dateBy(CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.API);
  }

  get getDateToday(): Date {
    return new Date();
  }

  get getDateYearly(): Date {
    return this.dateBy(CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API);
  }

  get getTaxesText(): string {
    return this.registration ? 'After taxes' : 'Before taxes';
  }

  get getTaxes(): string {
    return this.taxesApplied() ? CwTaxBus.tax.toString() + '%' : '';
  }

  // GETs //////////////////////////////////////////////////////////////////////


  taxesApplied(): boolean {
    return this.registration && CwTaxBus.tax && CwTaxBus.tax > 0;
  }
}
