import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-only',
  templateUrl: './download-only.component.html',
  styles: []
})
export class DownloadOnlyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
