import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalComponent} from './modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {InformModalComponent} from './inform-modal/inform-modal.component';
import { CwDeleteModalComponent } from './delete/cw-delete-modal.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [ModalComponent,
    InformModalComponent,
    CwDeleteModalComponent],
  exports: [ModalComponent,
    InformModalComponent,
    CwDeleteModalComponent],
  entryComponents: [
    InformModalComponent,
  ],
})
export class CwModalModule {
}
