import {CsIs} from './is';
import {CsToolClass} from './tool';
import {CsSetup} from '../model/model';

class CsToolStringClass  extends CsToolClass<CsSetup> {

  Setup;

  contains(
    _searchedItem: string,
    _possibleValues: any,
    _onlyPossibleValues = false
  ): boolean {
    // R2.1 - Password checking
    // 2
    let _stringContains = false;
    if (_onlyPossibleValues) {
      _stringContains = CsString._containsOnly(_searchedItem.split(''), _possibleValues);
    } else {
      _stringContains = CsString._contains(_searchedItem, _possibleValues);
    }
    return _stringContains;
    // 1
    //   // 1
    //   let _stringContains = false;
    //   if (_searchedItem) {
    //     if (CsIs.array(_possibleValues)) {
    //     } else {
    //       if (_chartToChart) {
    //         const _searchedItemArray = _searchedItem.split('');
    //         if (CsIs.arraySingle(_searchedItemArray)) {
    //           _stringContains = _searchedItem.includes(_possibleValues)
    //         } else {}
    //       }
    //     }
    //   }
    //   return _stringContains;
    // // 0
    // let _stringContains = false;
    // if (_onlyPossibleValues) {
    //   _stringContains = CoString._containsOnly(_searchedItem.split(''), _possibleValues);
    // } else {
    //   _stringContains = CoString._contains(_searchedItem, _possibleValues);
    // }
    // return _stringContains;
  }

  _containsOnly(_searchedItem: string[],
                _possibleValues: string[]): boolean {
    let _only = true;
    _searchedItem.forEach(_charItem => {
      _only = _only && CsString._contains(_charItem, _possibleValues);
    });
    return _only;
  }

  _contains(_searchedItem: any, _possibleValues: any): boolean {
    let _stringContains = false;
    if (CsIs.stringNotEmpty(_possibleValues)) {
      _stringContains = CsString._stringContains(_searchedItem, String(_possibleValues));
    } else if (CsIs.arrayNotEmpty(_possibleValues)) {
      for (const item of _possibleValues) {
        _stringContains = CsString._stringContains(_searchedItem, String(item));
        if (_stringContains) {
          break;
        }
      }
    }
    return _stringContains;
  }

  _stringContains(data: any, textContent: string): boolean {
    let _stringContains = false;
    try {
      _stringContains = (
        CsIs.stringNotEmpty(data) &&
        CsIs.stringNotEmpty(textContent) &&
        0 <= (<string>data).indexOf(textContent)
      );
    } catch (error) {
      _stringContains = false;
    }
    return _stringContains;
  }

  setup(_setupParam: any) {
  }

}


/**
 * @version R2.1 - Password checking
 */
export const CsString = new CsToolStringClass();
export const CsToolString = CsString;
