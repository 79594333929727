import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataSignUpPassiveTrialComponent} from './passive/data-sign-up-passive-trial.component';
import {DataSignUpPassiveComponent} from './passive/data-sign-up-passive.component';
import {CwModule} from '@cw/cw.module';
import {CwInputModule} from '@cw/components/input/cw-input.module';
import {CwComponentsModule} from '@cw/components/component.module';
import {FormsModule} from '@angular/forms';
import {AppCw} from '../app.class';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    CwModule,
    CwInputModule,
    CwComponentsModule,
    FormsModule,
    RouterModule,
  ],
  declarations: [
    DataSignUpPassiveComponent,
    DataSignUpPassiveTrialComponent,
  ],
  exports: [
    DataSignUpPassiveComponent,
    DataSignUpPassiveTrialComponent,
  ]
})
export class DataSingUpModuleModule {
}
