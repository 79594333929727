<!--<cw-space-buy-->
<!--[Setup]="SpaceBuySetup"-->
<!--&gt;</cw-space-buy>-->
<cw-ready
  [ready]="_visible">


  <div class="container p-3 app-purchase"
  >

    <div class="row h5 font-weight-bold mb-4 text-truncate">
      <div
        class="col text-truncate"
        translate="Select a plan..."
      ></div>
      <div
        class="col text-truncate"
        translate="Buy..."
      ></div>
      <div
        class="col text-truncate"
        translate="Successful purchase"
      ></div>
      <div class="col-12">
        <ngb-progressbar
          [value]="(plansBuyType/3)*1000"
          max="1000"
          striped="true"
          animated="true"
          type="primary"
          height="5px"
        ></ngb-progressbar>
      </div>
    </div>

    <div class="row">

      <div
        xxx-2005270000-2
        class="col-xs-12 col-md-8 p-3"
      >
        <ng-container *ngIf="!doing">
          <ng-container *ngIf="getPlansBuySelect">
            <ng-container *ngTemplateOutlet="SelectTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="getPlansBuyRegistration">
            <ng-container *ngTemplateOutlet="RegistrationTemplate"></ng-container>
          </ng-container>
          <!--        2007170000-->
          <!--        <ng-container *ngIf="getPlansBuyPayment">-->
          <!--          <ng-container *ngTemplateOutlet="PaymentTemplate"></ng-container>-->
          <!--        </ng-container>-->
          <ng-container *ngIf="getPlansBuyDone">
            <ng-container *ngTemplateOutlet="DoneTemplate"></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="doing">
        </ng-container>
      </div>

      <div class="col-md-4">

        <ng-container *ngTemplateOutlet="ElementCurrency"></ng-container>

        <ng-container *ngIf="getPlansBuySummary">
          <app-plans-buy-summary
            [registration]="getPlansBuyRegistration"
          ></app-plans-buy-summary>
        </ng-container>
      </div>

    </div>

  </div>


</cw-ready>


<ng-template #ContinueTemplate
             let-back="back"
             let-continue="continue"
>
  <div class="row mt-3 mb-2">
    <div class="col">
      <ng-container *ngIf="back">
        <a
          class="col btn btn-block btn-outline-secondary font-bold"
          translate="Back"
          (click)="plansBuyContinueClick(back)"
        ></a>
      </ng-container>
    </div>
    <div class="col">
      <button
        class="col btn btn-block btn-important btn-outline-primary font-bold"
        [translate]="getContinueLabel"
        [disabled]="getContinueDisabled"
        (click)="plansBuyContinueClick(continue)"
      ></button>
    </div>
  </div>
</ng-template>

<ng-template #DoneTemplate>
  <app-plans-buy-done></app-plans-buy-done>
</ng-template>


<ng-template #RegistrationTemplate>
  <app-plans-buy-registration></app-plans-buy-registration>
  <ng-container
    *ngTemplateOutlet="ContinueTemplate;context:CONTINUE_TEMPLATE_CONTEXT.REGISTRATION"
  ></ng-container>
</ng-template>


<ng-template #SelectTemplate>
  <app-plans-buy-select
    [planType]="PLAN_TYPE"
  ></app-plans-buy-select>
  <ng-container
    *ngTemplateOutlet="ContinueTemplate;context:CONTINUE_TEMPLATE_CONTEXT.SELECT"
  ></ng-container>
</ng-template>


<!-- ELEMENTS ------------------------------------------------------------------>
<ng-container>

  <!-- ElementCurrency ----------------------------------------------------------->
  <ng-template #ElementCurrency>
    <ng-container *ngIf="isElementCurrency">
      <div class="row my-3">
        <div class="col-12">
          <cw-currency-select></cw-currency-select>
        </div>
      </div>
    </ng-container>
  </ng-template>

</ng-container>
<!-- ELEMENTS ------------------------------------------------------------------>
