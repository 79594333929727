import {CwInputI} from '@cw/components/input/cw-input-model';

class CwApiToolClass {
  extractFromInputSet = (
    (InputSetList: CwInputI[]): any => {
      const ApiObject = {};
      if (InputSetList) {
        InputSetList.forEach(
          (Item: CwInputI) => {
            if (Item && Item.api) {
              ApiObject[Item.api] = Item.value;
            }
          }
        )
      }
      return ApiObject;
    }

  )

}

/**
 * @version 2007170000
 */
export const CwApiTool: CwApiToolClass = new CwApiToolClass();
