<div class="form-group"
>
  <label *ngIf="getLabel"
         [for]="Item.id"
         [translate]="Item.name"
  ></label>
  <span class="ml-1 h5 align-middle"
        *ngIf="Item.labelRequired"
  >*</span>
  <span
    *ngIf="Item.maycauserestart"
    class="pl-2 text-warning icon icon-warning"
    translate="Changes may cause restart of the device."
  >
    </span>

  <div class="input-group w-100">

    <ng-container *ngTemplateOutlet="getTemplate"></ng-container>

    <ng-container *ngIf="getCardElement">

      <ng-container *ngIf="Item.editable">
        <ng-container *ngTemplateOutlet="CardNumberTemplate"></ng-container>
      </ng-container>
      <ng-container *ngIf="!Item.editable">
        <ng-container *ngTemplateOutlet="CardNumberViewTemplate"></ng-container>
      </ng-container>

    </ng-container>


    <ng-container [ngSwitch]="Item.type">

      <ng-container *ngSwitchCase="TYPE.CARD">
        <ng-container *ngTemplateOutlet="CardTemplate;context:{Item:Item}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.CHECK">
        <ng-container *ngTemplateOutlet="CheckTemplate;context:{Item:Item}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.COMMENTS">
        <ng-container *ngTemplateOutlet="CommentTemplate;context:{Item:Item}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.DOMAIN">
        <ng-container *ngTemplateOutlet="DomainTemplate;context:{Item:Item}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.FREE">
        <ng-content></ng-content>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.NUMBER">
        <ng-container *ngTemplateOutlet="NumberTemplate;context:{Item:Item}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.PASSWORD">
        <ng-container *ngTemplateOutlet="PasswordTemplate;context:{Item:Item}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.TEXT">
        <ng-container *ngTemplateOutlet="TextTemplate;context:{Item:Item}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.CHECK">
        <ng-container *ngTemplateOutlet="CheckTemplate;context:{Item:Item}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TYPE.VALIDATION">
        <ng-container *ngTemplateOutlet="ValidationTemplate"></ng-container>
      </ng-container>

    </ng-container>

    <!--    <div-->
    <!--      *ngIf="Item && Item.ValidationList && Item.ValidationList.length > 0"-->
    <!--      class="error-list alert alert-danger mt-3 mb-0"-->
    <!--      role="alert"-->
    <!--    >-->
    <!--      <div *ngFor="let message of Item.ValidationList">-->
    <!--        <span-->
    <!--          class="error-item"-->
    <!--        >{{message | translate}}</span>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="invalid-feedback"
         *ngIf="Item && Item.ValidationList && Item.ValidationList.length > 0"
    >
      <div *ngFor="let message of Item.ValidationList">
        <span
          class=""
        >{{message | translate}}</span>
      </div>

    </div><!--      invalid-feedback-->

  </div><!--    input-group-->
  <div class="alert alert-warning"
       role="alert"
       *ngIf="Item && Item.FeedbackList && Item.FeedbackList.length > 0"
  >
    <div *ngFor="let message of Item.FeedbackList">
        <span
          class=""
        >{{message | translate}}</span>
    </div>
  </div>
</div><!--  form-group-->


<ng-template #DomainTemplate let-Item="Item">

  <div
    *ngIf="Item.prepend"
    class="input-group-prepend"
  >
    <span class="input-group-text">{{Item.prepend}}</span>
  </div>

  <ng-container *ngIf="Item.focus">
    <input
      appCw
      appCwFocus
      type="text"
      class="form-control form-control-sm"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [minLength]="minLength(Item)"
      [maxLength]="maxLength(Item)"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>

  <ng-container *ngIf="!Item.focus">
    <input
      type="text"
      class="form-control form-control-sm"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [minLength]="minLength(Item)"
      [maxLength]="maxLength(Item)"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>
  <div
    *ngIf="Item.append"
    class="input-group-append"
  >
    <span class="input-group-text">{{Item.append}}</span>
  </div>

</ng-template>

<ng-template #NumberTemplate let-Item="Item">
  <!--    (keyup.enter)="new($event)"-->
  <!--    (keyup.tab)="new($event)"-->
  <!--    (blur)="new($event)"-->
  <ng-container *ngIf="Item.focus">
    <input
      appCw
      appCwFocus
      type="number"
      class="form-control form-control-sm"
      [ngClass]="getClass"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [maxLength]="maxLength(Item)"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>

  <ng-container *ngIf="!Item.focus">
    <input
      xxx-2002111109-5
      type="number"
      class="form-control form-control-sm"
      xxx-2002111109-5
      [ngClass]="getClass"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [maxLength]="maxLength(Item)"
      (keydown.enter)="keyDownEnter(Item, $event)"

    >
  </ng-container>

</ng-template>

<ng-template #TextTemplate let-Item="Item">


  <ng-container *ngIf="Item.focus">
    <input
      [attr.appCw]
      appCw
      appCwFocus
      type="text"
      class="form-control form-control-sm"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [maxLength]="maxLength(Item)"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>

  <ng-container *ngIf="!Item.focus">
    <input
      type="text"
      class="form-control form-control-sm"
      [placeholder] = "getPlaceholder"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [maxLength]="maxLength(Item)"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>

</ng-template>

<ng-template #PasswordTemplate let-Item="Item">
  <!--    (keyup.enter)="new($event)"-->
  <!--    (keyup.tab)="new($event)"-->
  <!--    (blur)="new($event)"-->
  <ng-container *ngIf="Item.focus">
    <input
      appCw
      appCwFocus
      [type]="passwordDisplay?'text':'password'"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [maxLength]="maxLength(Item)"
      autocomplete="off"
      class="form-control form-control-sm"
      data-toggle="password"
      aria-label="Password"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>

  <ng-container *ngIf="!Item.focus">
    <input
      [type]="passwordDisplay?'text':'password'"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [maxLength]="maxLength(Item)"
      autocomplete="off"
      class="form-control form-control-sm"
      data-toggle="password"
      aria-label="Password"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>
  <div class="input-group-append">
    <a
      (click)="passwordDisplay = !passwordDisplay"
      class="input-group-text password-reveal-icon"
    >
      <i
        class="icon"
        ngClass="{{passwordDisplay ? 'icon-eye-with-line' : 'icon-eye'}}"
      ></i>
    </a>
  </div>


</ng-template>


<ng-template
  #CardNumberTemplate
  xxx-2005270000-1
>
  <div
    id="CardNumberDiv"
    #CardNumberDiv
    class="form-control form-control-sm bg-light"
    [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
  ></div>
</ng-template>

<ng-template
  #CardNumberViewTemplate
  xxx-2004150000-24
>
</ng-template>

<ng-template #CardTemplate let-Item="Item">
  <ng-container *ngIf="!Card.isEditable">
    <ng-container *ngIf="Item.CardToken.card.brand">
      {{Item.CardToken.card.brand}}
    </ng-container>
    <ng-container *ngIf="Item.CardToken.card.last4">
      {{Item.CardToken.card.last4}}
    </ng-container>
    <button (click)="Card.remove()">-</button>
  </ng-container>

  <ng-container *ngIf="Card.isEditable">
    <div
      xxx-2004150000-5
      id="CardElement"
      #CardElement
      class="form-control form-control-sm bg-light"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
    ></div>
  </ng-container>
</ng-template>

<ng-template #CheckTemplate let-Item="Item">

  <div
    *ngIf="Item.prepend"
    class="input-group-prepend"
  >
    <span class="input-group-text">{{Item.prepend}}</span>
  </div>

  <div class="form-check mb-3">
    <ng-container *ngIf="Item.focus">
      <input
        appCw
        appCwFocus
        type="checkbox"
        class="form-check-input"
        [name]="Item.id"
        [id]="Item.id"
        [(ngModel)]="Item.value"
        [disabled]="getDisabled"
        (change)="settingChange(Item)"
        (keydown.enter)="keyDownEnter(Item, $event)"
      >
    </ng-container>

    <ng-container *ngIf="!Item.focus">
      <input
        type="checkbox"
        class="form-check-input"
        [name]="Item.id"
        [id]="Item.id"
        [(ngModel)]="Item.value"
        [disabled]="getDisabled"
        (change)="settingChange(Item)"
        (keydown.enter)="keyDownEnter(Item, $event)"
      >
    </ng-container>
    <label class="form-check-label" [for]="Item.id">
      <span>{{Item.name | translate}}</span>
    </label>
  </div>

  <div
    *ngIf="Item.append"
    class="input-group-append"
  >
    <span class="input-group-text">{{Item.append}}</span>
  </div>

</ng-template>

<ng-template
  #CommentTemplate
  xxx-2005210000
>
  <textarea
    class="form-control form-control-sm"
    rows="3"

    [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
    [name]="Item.id"
    [id]="Item.id"
    [(ngModel)]="Item.value"
    [disabled]="getDisabled"
    (blur)="settingChange(Item)"
    (keyup.enter)="settingChange(Item)"
    [maxLength]="maxLength(Item)"
    (keydown.enter)="keyDownEnter(Item, $event)"

  ></textarea>
</ng-template>

<ng-template
  #ValidationTemplate
  xxx-2005210000
>
  <input
    [attr.appCw]
    appCw
    appCwFocus
    type="hidden"
    class="form-control form-control-sm"
    [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
    [name]="Item.id"
    [id]="Item.id"
    [(ngModel)]="Item.value"
    [disabled]="getDisabled"
    (blur)="settingChange(Item)"
    (keyup.enter)="settingChange(Item)"
  >
</ng-template>


<ng-template #TemplatePasswordMinimal>
  <!--    (keyup.enter)="new($event)"-->
  <!--    (keyup.tab)="new($event)"-->
  <!--    (blur)="new($event)"-->
  <ng-container *ngIf="Item.focus">
    <input
      appCw
      appCwFocus
      [type]="passwordDisplay?'text':'password'"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [maxLength]="maxLength(Item)"
      autocomplete="off"
      class="form-control form-control-sm"
      data-toggle="password"
      aria-label="Password"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>

  <ng-container *ngIf="!Item.focus">
    <input
      [type]="passwordDisplay?'text':'password'"
      [ngClass]="{'is-invalid': Item.ValidationList.length>0}"
      [name]="Item.id"
      [id]="Item.id"
      [(ngModel)]="Item.value"
      [disabled]="getDisabled"
      (blur)="settingChange(Item)"
      (keyup.enter)="settingChange(Item)"
      [maxLength]="maxLength(Item)"
      autocomplete="off"
      class="form-control form-control-sm"
      data-toggle="password"
      aria-label="Password"
      (keydown.enter)="keyDownEnter(Item, $event)"
    >
  </ng-container>
  <div class="input-group-append">
    <a
      (click)="passwordDisplay = !passwordDisplay"
      class="input-group-text password-reveal-icon p-0"
    >
      <i
        class="icon"
        ngClass="{{passwordDisplay ? 'icon-eye-with-line' : 'icon-eye'}}"
      ></i>
    </a>
  </div>


</ng-template>

<ng-template #TemplateCardNumberView>
  <input
    type="text"
    class="form-control"
    [name]="Item.id"
    [id]="Item.id"
    [ngModel]="getCardNumberViewValue"
    [size]="12"
    disabled="true"
  >
  <button
    type="button"
    class="btn btn-sm btn-outline-primary"
    ngbTooltip="{{'Delete'  | translate}}"
    [disableTooltip]="isMobile"
    placement="bottom"
    openDelay="500"
    closeDelay="0"
    (click)="CardElements.On.Delete.clicked()"
  >
    <span class="icon icon-trash">
    </span>
  </button>
</ng-template>

<ng-template #TemplateNumberPositive>
  <cw-input-number-positive
      [Item]="Item"
  ></cw-input-number-positive>
</ng-template>
