import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Cw} from '@tool/trace/tool-trace';
import {CwVersionCheck} from '@cw/version/check/check-bus';
import {App} from '../app';
import {CwVersionCheckDoI, CwVersionCheckDoParametersI} from '@cw/version/check/check-model';
import {CwProtocol} from '@tool/protocol/tool-protocol';
import {CwWeb} from '@tool/web/tool-web';
import {ActivatedRoute} from '@angular/router';
import {AppWebTool} from '../app-web.tool';

// import {saveAs as SaveAs} from 'file-saver';

@Component({
  selector: 'app-download',
  templateUrl: 'download.component.html',
})
/**
 * @version R4.1 - Ticket 2268 - If you click on download...
 */
export class DownloadComponent implements OnInit, OnDestroy {

  // 1906251012
  @Input() protocolCheck = true;
  // 11901301328
  os: string;

  App = App;
  Cw = Cw;
  client: string;

  constructor(
    private _ActivatedRoute: ActivatedRoute
  ) {
    // Firefox mandatory - 2001200856
    const DownloadComponentPointer = this;
    // 1904101050
    this.client = App.client;
    CwVersionCheck.do2(<CwVersionCheckDoI>{
      Bus: CwVersionCheck,
      successActionCustom: ((Data, That) => {
        if (
          Data &&
          Data.result &&
          Data.result.download_url &&
          true
        ) {
          // 1906201334
          const token = CwWeb.getToken({
            ActivatedRoute: _ActivatedRoute
          });
          // 2001171318
          if (CwWeb.isTablet && CwWeb.isMac) {
            window.location.href = Data.result.download_url;
          } else {
            if (
              DownloadComponentPointer.protocolCheck
            ) {
              if (token) {
                CwProtocol.check({token});
              } else {
                CwProtocol.check(Data.result.download_url);
              }
            }
          }
        }
      }),
      errorActionCustom: ((Data) => {
      }),
      errorRestActionCustom: ((Data) => {
      }),
      Parameters: <CwVersionCheckDoParametersI>{
        entityId: 0
      },
    });

    // // 1904251312
    // // 1904101050
    // CwVersionCheck.do(0);
    // // TODO-1904101050
    // this.informationService.getClient(0).subscribe(
    //   result => {
    //     if (
    //       result &&
    //       result.result &&
    //       result.result.download_url &&
    //       true
    //     ) {
    //       CoProtocolBus.check(result.result.download_url);
    //     }
    //   });

  }


  ngOnInit() {
    this.os = CwWeb.getOS();
    // 1906201334
    const token = CwWeb.getToken({
      ActivatedRoute: this._ActivatedRoute
    });
    if (!token) {
      App.Navigation.download = true;
      AppWebTool.Title = {
        main: 'Thank you for downloading',
        secondary: '',
        startHereVisible: false,
      }
    }

  }

  ngOnDestroy(): void {
    App.Navigation.download = false;
    App.Navigation.join = false;
  }

}
