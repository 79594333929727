<form #formCtrl="ngForm" *ngIf="App.Body.visible"
      class="py-3"
>
  <app-cw-row>
    <app-cw-form R literal="Email">
      <input appCw
             appCwForm
             appCwFocus
             name="email"
             type="mail"
             ngModel #emailInput="ngModel"
             [(ngModel)]="email"
             required
             onpaste="return false;"
             onCopy="return false"
             onCut="return false"
             onDrag="return false"
             onDrop="return false"
      >
    </app-cw-form>
  </app-cw-row>

  <app-cw-row>
    <app-cw-form R literal="Password">
      <input appCw
             appCwForm
             name="password"
             type="password"
             (keyup.enter)="signin()"
             ngModel
             #passwordInput="ngModel"
             [(ngModel)]="password"
             required
             onpaste="return false;"
             onCopy="return false"
             onCut="return false"
             onDrag="return false"
             onDrop="return false"
      >
    </app-cw-form>
  </app-cw-row>

  <app-cw-row>

    <div RF class="d-flex align-items-center px-2">
      <input
        name="keepMeInput"
        type="checkbox"
        [(ngModel)]="App.KeepLoggedIn.Keep"
      >

      <span class="ml-2 font-bold text-truncate"
            appCw
            appCwText
            appCwTextMax="15"
            appCwTextMin="8"
      >{{'Keep me logged in' | translate}}</span>
    </div>

    <div RF class="d-flex justify-content-end align-items-center px-2">
      <a class="font-bold text-truncate"
         routerLink="/forgot"
         appCw
         appCwText
         appCwTextMax="15"
         appCwTextMin="8"
      >{{ 'Forgot Password?' | translate }}</a>
    </div>

  </app-cw-row>

  <div class="my-3"></div>

  <app-cw-row>

    <button R
            appCw
            appCwForm
            (click)="executeImportantAction()"
            type="button"
            [disabled]="!formCtrl.form.valid"
            translate="Sign-In-Button"
    ></button>

  </app-cw-row>

  <div class="row justify-content-center">
      <div
        class="col-12 col-md-6 text-danger text-center my-3 2010141200.2"
        [translate]="errorMessage"
      ></div>
  </div>


  <app-cw-row>
    <hr R2>
  </app-cw-row>


  <ng-container *ngIf="!CwOnP.is" xxx-2004161558>
    <div class="w-100 text-center">
      <span class="font-bold">{{ 'New to Space?' | translate}}</span>
      <a routerLink="/plans/free"
         class="font-bold"
      >&#160;
<!--        2007291000-->
<!--        {{'Sign Up for Personal' | translate}}-->
<!--        {{'Sign Up for trial' | translate}}-->
        {{'Sign Up for Free' | translate}}
      </a>
    </div>
  </ng-container>

</form>
