import {NgModule} from '@angular/core';
import {DoughnutComponent} from './components/charts/doughnut/doughnut.component';
import {ChartModule} from 'primeng/chart';
import {TooltipModule} from 'primeng/tooltip';
import {CwDirective} from './directives/cw.directive';
import {CwDateTimeZonePipe} from './pipes/time-zone.pipe';
import {CwLiteralModuleExports, CwLiteralModuleImports, CwLiteralModuleProviders} from '@cw/literal/model';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {CwTextFitDirective} from '@cw/directives/text/fit.directive';
import {CommonModule} from '@angular/common';


@NgModule({
  imports: [
    ...CwLiteralModuleImports,
    ChartModule,
    TooltipModule, // 1906051110
    // 1907021609
    DeviceDetectorModule.forRoot(),
    CommonModule,
  ],
  declarations: [
    DoughnutComponent,
    CwDirective,
    CwDateTimeZonePipe,
    CwTextFitDirective,
  ],
  // declarations: [...CW_DECLARATIONS],
  exports: [
    ...CwLiteralModuleExports,
    DoughnutComponent,
    CwDirective,
    CwDateTimeZonePipe,
    CwTextFitDirective,
  ],
  providers: [
    ...CwLiteralModuleProviders,
  ],
})
export class CwModule {
}
