import {Subplan} from "./subplan";

export class Plan {

  id: string;
  subplans: Subplan[] = [];
  selected = false;

  constructor(id: string, subplans: Subplan[]) {

    this.id = id;
    this.subplans = subplans;
  }

  addSubplan(subplan: Subplan)
  {
    this.subplans.push(subplan);
  }

}
