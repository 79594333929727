import {CwLogicSpacePlanDefineModel, CwLogicSpacePlanModel} from '@cw/logic/space/plan/cw-logic-space-plan.model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CW_SPACE_SUBSCRIPTION_TYPE_MODEL} from '@space/subscription/tool-space-subscription-model';

export class CwLogicSpacePlanTool extends CwLogicSpacePlanModel {

  add = ((PlanList: any[], ...Plan) => {
    // Push anr sort by order field
    if (PlanList && Plan) {
      PlanList.push(...Plan);
      PlanList.sort(
        (PlanA, PlanB) => {
          return CwUtil.sortAsc(PlanA._order, PlanB._order);
        })
    }
  });

  addExtra = ((...Plan): void => {
    this.add(this.Buy.Data.ExtraList, ...Plan);
  });

  addMain = (
    (...Plan): void => {
      this.add(this.Buy.Data.MainList, ...Plan);
    }
  );

  // 2007241150.1
  addBasicAdd = (
    () => {
      this.rmMain(this.isBasicAdd);
      // Debe de ser unico
      // El número BasicWeb es menor que 3 (Numero de planes Basic)
      if (
        this.Buy.Data.MainList.filter(this.isBasicType).length < 3
      ) {
        const ExpansionList = [];
        if (!this.existInMainList(this.isBasic1)) {
          ExpansionList.push(this.Plan.Basic1);
        }
        if (!this.existInMainList(this.isBasic5)) {
          ExpansionList.push(this.Plan.Basic5);
        }
        if (!this.existInMainList(this.isBasic10)) {
          ExpansionList.push(this.Plan.Basic10);
        }
        this.addMain({
          _order: this.orderMainNext(this.isBasicType),
          ExpansionList,
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.BASIC_ADD,
          name: 'Basic',
        });
      }
    }
  );

  // 2007241150.1
  addProAdd = (
    () => {
      this.rmMain(this.isProAdd);
      // Debe de ser unico
      // El número Pro  es menor que 4 (Numero de planes Pro)
      if (
        this.Buy.Data.MainList.filter(this.isProType).length < 4
      ) {
        const ExpansionList = [];
        if (!this.existInMainList(this.isPro5)) {
          ExpansionList.push(this.Plan.Pro5);
        }
        if (!this.existInMainList(this.isPro10)) {
          ExpansionList.push(this.Plan.Pro10);
        }
        if (!this.existInMainList(this.isPro50)) {
          ExpansionList.push(this.Plan.Pro50);
        }
        if (!this.existInMainList(this.isPro100)) {
          ExpansionList.push(this.Plan.Pro100);
        }
        this.addMain({
          _order: this.orderMainNext(this.isProType),
          ExpansionList,
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO_ADD,
          name: 'Pro',
        });
      }
    }
  );
  // 2009081500
  addCloudAdd = (() => {
    this.rmExtra(this.isCloudAdd);
    if (!this.existInExtraList(this.isCloud500)) {
      const ExpansionList = [this.Plan.Cloud500];
      this.addExtra({
        _order: this.orderExtraNext(this.isCloudType),
        ExpansionList,
        id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLOUD_ADD,
        name: 'Cloud Space',
      });
    }
  });

  // // 2007241150.1
  addWebinarAdd = (
    () => {
      this.rmExtra(this.isWebinarAdd);
      // Debe de ser unico
      // El número Webinars es menor que 2 (Numero de planes Webinar)
      // Existe un pro
      if (
        this.existInMainList((
          (Plan: any): boolean => {
            return (
              this.isProType(Plan) &&
              Plan.quantity > 0
            )
          }
        ))

      ) {
        const countWebinar = this.Buy.Data.ExtraList.filter(this.isWebinarType).length;
        if (countWebinar === 0) {
          this.addExtra({
            ...CwUtil.clone(this.Plan.Webinar),
            quantity: 0
          })
        }
        if (countWebinar < 2) {
          const ExpansionList = [];
          if (!this.existInExtraList(this.isWebinar)) {
            ExpansionList.push(this.Plan.Webinar);
          }
          if (!this.existInExtraList(this.isWebinarExpansion)) {
            ExpansionList.push(this.Plan.WebinarExpansion);
          }
          this.addExtra({
            _order: this.orderExtraNext(this.isWebinarType),
            ExpansionList,
            id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.WEBINAR_ADD,
            name: 'Webinar',
          });
        }
      }
    }
  );

  clear = (
    (): void => {
      CwUtil.clearData(this.Plan)
      this.IO.MainList = undefined;
      this.IO.ExtraList = undefined;
      this.IO.NewList = undefined;
    }
  )

  define = (
    (IO: CwLogicSpacePlanDefineModel) => {
      try {
        this.clear();
        CwUtil.exec(this.Define, IO);
        // this.BuyBasic.define(IO);
      } catch (e) {
        console.log('2007181937', 'CwLogic2SpacePlan.define', e);
      }
    }
  );

  existInExtraList = (
    (condition: any): boolean => {
      return CwUtil.exist({
        Array: this.Buy.Data.ExtraList,
        condition,
      })
    }
  )

  existInMainList = (
    (condition: any): boolean => {
      return CwUtil.exist({
        Array: this.Buy.Data.MainList,
        condition,
      })
    }
  )

  orderMainNext = (
    (condition): number => {
      let order = -1;
      try {
        if (this.existInMainList(condition)) {
          const PlanList = this.Buy.Data.MainList.filter(condition);
          order = PlanList[PlanList.length - 1]._order + 10;
        } else {
          order = this.Buy.Data.MainList[this.Buy.Data.MainList.length - 1]._order + 100;
        }
      } catch (e) {
      }
      return order;
    }
  )

  orderExtraNext = (
    (condition): number => {
      let order = -1;
      try {
        if (this.existInExtraList(condition)) {
          const PlanList = this.Buy.Data.ExtraList.filter(condition);
          order = PlanList[PlanList.length - 1]._order + 10;
        } else {
          order = this.Buy.Data.ExtraList[this.Buy.Data.ExtraList.length - 1]._order + 100;
        }
      } catch (e) {
      }
      return order;
    }
  )

  rmExtra = (
    (condition) => {

      CwUtil.remove({
        List: this.Buy.Data.ExtraList,
        condition,
      });

    }
  )

  rmMain = (
    (condition) => {

      CwUtil.remove({
        List: this.Buy.Data.MainList,
        condition,
      });

    }
  )


}
