import {CwInfoInstallationBus} from '@rest/info/installation/cw-info-installation-bus.class';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {CwFunction} from '../../model/model';

class WebRtcTool {

  available = true;
  ready = false;

  private SetupSubject$ = new Subject();

  get Configured$(): Observable<any> {
    return this.SetupSubject$.asObservable();
  }

  setup = () => {
    this.available = CwInfoInstallationBus.webRtcAvailable;
    this.ready = true;
    this.SetupSubject$.next();
  }

  subscribe = (callback: CwFunction) => {
    if (this.ready) {
      callback();
    } else {
      this.Configured$.subscribe(callback);
    }
  }

  constructor() {
    if (CwInfoInstallationBus.ready) {
      this.setup();
    } else {
      CwInfoInstallationBus.Bus$.subscribe(this.setup)
    }
  }
}

export const CwWebRtcTool: WebRtcTool = new WebRtcTool();
