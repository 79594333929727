import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'co-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.css']
})
export class CoPreloaderComponent implements OnInit {

  @Input() hidden: boolean = true;
  @Input() textMain: string = "";
  @Input() textSecondary: string = "";

  constructor() { }

  ngOnInit() {
  }

}
