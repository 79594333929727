import {CwIs} from '../tool-is';
import {CW_WEB_TOOL, CwWebKeepI, CwWebLoadJsI, CwWebSetupI, CwWebToolGoToModel} from './web.model';
import {CwSafe} from '../tool-safe';
import {CwBusClass} from '../../model/bus';
import {CsFunction} from '../../../cs/model/model';
import {CwToolKeepC} from '../keep/tool-keep';
import {CwDefineUrl, CwFunction, CwTokenBusI} from '../../model/model';
import {CwUtil} from '../util/cw-tool-util';
import {environment} from '../../../../environments/environment';
import {CwVersionCheckPlatform} from '../../version/check/check-model';
import fitty from 'fitty';
import {ToolProtocol} from '../protocol/tool-protocol';

/**
 * @version 1.8.0
 */
export class CwToolWebClass extends CwBusClass {

  DefineProperties: CsFunction[];
  Keep: CwToolKeepC<any>;
  Setup: CwWebSetupI;
  SuccessActionList: any[];
  SuccessActionOnceList: any[];
  TokenBus: CwTokenBusI;
  defineUrl: CwDefineUrl;
  do: CwFunction;

  macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  loaderVisible = false;

  isMobile = false;
  isIpad = false;
  isPortrait = false;
  isDesktop = false;
  isTablet = false;
  isInternetExplorer = false;
  isMac = false;

  DEVICE = {
    check: () => {
      CwUtil.checking(this.DEVICE);
    },
    Desktop: {
      is: false,
      check: () => {
        this.DEVICE.Desktop.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          this.Setup.DeviceDetectorService.isDesktop() &&
          true
        );
      }
    },
    Mobile: {
      is: false,
      check: () => {
        this.DEVICE.Mobile.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          this.Setup.DeviceDetectorService.isMobile() &&
          true
        );
      }
    },
    Tablet: {
      is: false,
      check: () => {
        this.DEVICE.Tablet.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          this.Setup.DeviceDetectorService.isTablet() &&
          true
        );
      }
    },
  };

  OS = {
    api: null,
    check: () => {
      CwUtil.checking(this.OS);
      if (this.OS.Windows.is) {
        this.OS.api = CwVersionCheckPlatform.windows;
      } else if (this.OS.Mac.is) {
        this.OS.api = CwVersionCheckPlatform.mac;
      } else if (this.OS.Android.is) {
        this.OS.api = CwVersionCheckPlatform.android;
      } else if (this.OS.Ios.is) {
        this.OS.api = CwVersionCheckPlatform.ios;
      }
    },
    Android: {
      is: false,
      check: () => {
        this.OS.Android.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          CW_WEB_TOOL.OS.ANDROID === this.Setup.DeviceDetectorService.os.toLowerCase() &&
          true
        );
      }
    },
    Ios: {
      is: false,
      check: () => {
        const os = (
            this.Setup &&
            this.Setup.DeviceDetectorService &&
            this.Setup.DeviceDetectorService.os &&
            true
          )
          ? this.Setup.DeviceDetectorService.os.toLowerCase()
          : ''
        ;
        this.OS.Ios.is = (
          (
            this.Setup &&
            this.Setup.DeviceDetectorService &&
            CW_WEB_TOOL.OS.IOS === os &&
            true
          ) ||
          /**
           * iPad Pro by default is Mac and Tablet
           * Settings -> Safari -> request Desktop Website -> All websites.
           * 22001201051
           */
          (
            this.Setup &&
            this.Setup.DeviceDetectorService &&
            CW_WEB_TOOL.OS.MAC === os &&
            this.Setup.DeviceDetectorService.isTablet() &&
            true
          ) ||
          false
        );
      }
    },
    Mac: {
      is: false,
      check: () => {
        this.OS.Mac.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          CW_WEB_TOOL.OS.MAC === this.Setup.DeviceDetectorService.os.toLowerCase() &&
          this.Setup.DeviceDetectorService.isDesktop() &&
          true
        );
      }
    },
    Windows: {
      is: false,
      check: () => {
        this.OS.Windows.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          CW_WEB_TOOL.OS.WINDOWS === this.Setup.DeviceDetectorService.os.toLowerCase() &&
          true
        );
      }
    },
  };

  Browser = {
    check: () => {
      CwUtil.checking(this.Browser);
    },
    Chrome: {
      is: false,
      version: null,
      check: () => {
        this.Browser.Chrome.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          (
            CW_WEB_TOOL.BROWSER.CHROME === this.Setup.DeviceDetectorService.browser.toLowerCase() ||
            CW_WEB_TOOL.BROWSER.EDGE79 === this.Setup.DeviceDetectorService.browser.toLowerCase() ||
            false
          ) &&
          true
        );
        this.Browser.Chrome.version = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          true
        )
          ? this.Setup.DeviceDetectorService.browser_version
          : null
        ;
      }
    },
    Edge: {
      is: false,
      check: () => {
        this.Browser.Edge.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          CW_WEB_TOOL.BROWSER.EDGE === this.Setup.DeviceDetectorService.browser.toLowerCase() &&
          true
        );
      }
    },
    Firefox: {
      is: false,
      check: () => {
        this.Browser.Firefox.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          CW_WEB_TOOL.BROWSER.FIREFOX === this.Setup.DeviceDetectorService.browser.toLowerCase() &&
          true
        );
      }
    },
    InternetExplorer: {
      is: false,
      check: () => {
        this.Browser.InternetExplorer.is = (
          this.Setup &&
          this.Setup.DeviceDetectorService &&
          CW_WEB_TOOL.BROWSER.IE === this.Setup.DeviceDetectorService.browser.toLowerCase() &&
          true
        );
      }
    },
  };

  Event = {
    stop: ((Event) => {
      if (Event && Event.preventDefault) {
        Event.preventDefault();
      }
      if (Event && Event.stopImmediatePropagation) {
        Event.stopImmediatePropagation();
      }
      if (Event && Event.stopPropagation) {
        Event.stopPropagation();
      }
    })
  };

  Route = {
    is: '',
    check: () => {
      this.DEVICE.check();
      this.OS.check();
      this.Browser.check();
      this.Route.is = '/';
      if (this.OS.Android.is) {
        this.Route.is += CW_WEB_TOOL.OS.ANDROID;
      } else if (this.OS.Ios.is) {
        this.Route.is += CW_WEB_TOOL.OS.IOS;
      } else if (this.OS.Mac.is) {
        this.Route.is += CW_WEB_TOOL.OS.MAC;
      } else if (this.OS.Windows.is) {
        this.Route.is += CW_WEB_TOOL.OS.WINDOWS;
      }

      if (this.Browser.Chrome.is) {
        this.Route.is += '/' + CW_WEB_TOOL.BROWSER.CHROME;
      } else if (this.Browser.Firefox.is) {
        this.Route.is += '/' + CW_WEB_TOOL.BROWSER.FIREFOX;
      } else if (this.Browser.Edge.is) {
        this.Route.is += '/' + CW_WEB_TOOL.BROWSER.EDGE;
      }

    }
  };

  CheckList = [
    () => {
      this.isMac = (
        this.Setup &&
        this.Setup.DeviceDetectorService &&
        CW_WEB_TOOL.OS.MAC === this.Setup.DeviceDetectorService.os.toLowerCase() &&
        true
      );
    },
  ];

  checkIsInternetExplorer = (() => {
    this.isInternetExplorer = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      CW_WEB_TOOL.BROWSER.IE === this.Setup.DeviceDetectorService.browser.toLowerCase() &&
      true
    );
  });

  checkIsMobile = (() => {
    this.isMobile = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      this.Setup.DeviceDetectorService.isMobile() &&
      true
    );
  });

  checkIsIpad = (() => {
    this.isIpad = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      CW_WEB_TOOL.DEVICE.IPAD === this.Setup.DeviceDetectorService.device.toLowerCase() &&
      true
    );
  });

  checkIsTablet = (() => {
    this.isTablet = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      this.Setup.DeviceDetectorService.isTablet() &&
      true
    );
  });

  checkIsDesktop = (() => {
    this.isDesktop = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      this.Setup.DeviceDetectorService.isDesktop() &&
      true
    );
    if (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      this.Setup.DeviceDetectorService['browserVersion'] === '13.0.1' &&
      this.Setup.DeviceDetectorService.os_version === 'mac-os-x-15' &&
      true
    ) {
      this.isDesktop = false;
    }
  });


  get getVersion(): string {
    return environment.App.name +
      '-' +
      environment.App.version +
      ''
      ;
  }

  getToken(Param: CwWebSetupI): any {
    return CwWeb.getParam({
      ...Param,
      paramName: 'token',
    });
  }

  getParam(Param: CwWebSetupI): any {
    let value: any;
    if (
      Param &&
      Param.paramName &&
      Param.ActivatedRoute &&
      Param.ActivatedRoute.snapshot &&
      Param.ActivatedRoute.snapshot.paramMap &&
      true
    ) {
      value = Param.ActivatedRoute.snapshot.paramMap.get(Param.paramName);
    }
    return value;
  }


  goTo(Params: CwWebToolGoToModel): void {
    if (Params && Params.path) {
      if (
        Params.external &&
        window &&
        window.location &&
        true
      ) {
        window.location.href = CwSafe.get(Params.path);
      } else if (
        CwWeb &&
        CwWeb.Setup &&
        CwWeb.Setup.Router &&
        !Params.byUrl &&
        true
      ) {
        if (!Params.queryParams) {
          Params.queryParams = {};
        }
        CwWeb.Setup.Router.navigate(
          [CwSafe.get(Params.path)],
          {queryParams: Params.queryParams}
        )
          .then((res) => {
          })
          .catch((error) => {
            console.log(error);
            throw new Error('1902041245');
          })
        ;
      } else if (
        CwWeb &&
        CwWeb.Setup &&
        CwWeb.Setup.Router &&
        Params.byUrl &&
        true
      ) {
        if (!Params.queryParams) {
          Params.queryParams = {};
        }
        CwWeb.Setup.Router.navigateByUrl(
          CwSafe.get(Params.path),
          {queryParams: Params.queryParams}
        );
      }
    }
  }

  goToAdmin(token: string): void {
    if (token) {
      const adminPath = (environment.App.adminPath)
        ? environment.App.adminPath
        : 'admin'
      ;
      CwWeb.goTo({
          external: true,
          path: '/' +
            adminPath +
            '/#/' +
            CwUtil.uuid() +
            '/' +
            token +
            '',
        }
      );
    }
  }

  /**
   * @version 0.3.2 - The literal project json files unify - 1810151342
   * @description
   * https://github.com/ngx-translate/http-loader/issues/25
   */
  public notCache(value: string = null): string {
    const _notCache = '?cacheBusting=' + new Date().getTime();
    return (value)
      ? value + _notCache
      : _notCache;
  }

  public keepGet(Param: CwWebKeepI<any>): boolean {
    let _get = false;
    try {
      if (Param &&
        Param.key &&
        CwIs.defined(typeof (Storage)) &&
        true
      ) {
        // R24.1 - Users - Set Admins - It does not opened the permissions.
        const _data = localStorage.getItem(CwSafe.get(Param.key));
        if (CwIs.defined(_data)) {
          Param.Data = JSON.parse(CwSafe.get(_data));
          _get = true;
        }
      }
    } catch (error) {
      _get = false;
    }
    return _get;
  }

  // 1901181612
  public keepSet(Param: CwWebKeepI<any>): boolean {
    let _set = false;
    try {
      if (
        Param &&
        Param.key &&
        CwIs.defined(typeof (Storage)) &&
        true
      ) {
        if (CwIs.defined(Param.Data)) {
          Param.DataSafe = CwSafe.set(JSON.stringify(Param.Data));
        }
      }
      localStorage.setItem(CwSafe.get(Param.key), Param.DataSafe);
    } catch (error) {
      _set = false;
      // throw new TypeError();
    }
    return _set;
  }

  public keepRemove(Param: CwWebKeepI<any>): void {
    try {

      if (Param &&
        Param.key &&
        CwIs.defined(typeof (Storage)) &&
        true
      ) {
        localStorage.removeItem(
          CwSafe.get(Param.key)
        );
      }
    } catch (error) {
      // throw new TypeError();
    }
  }

  /**
   * @version 1.12.0 - RPM - Js and Css - 1902051520
   * @param Param
   */
  public loadJs(Param: CwWebLoadJsI): void {
    if (
      Param &&
      Param.UrlList &&
      Param.UrlList[0] &&
      Param.condition &&
      true
    ) {
      Param.UrlList.forEach(urlItem => {
        const node = document.createElement('script');
        node.src = urlItem;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        node['crossOrigin'.toLowerCase()] = 'anonymous';
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }
  }

  public loadCss(callback: CwFunction, ...urls) {
    if (urls) {
      let count = 0;
      urls.forEach((urlItem) => {
        const element = document.createElement('link');
        element.rel = 'stylesheet';
        element.href = urlItem;
        if (callback) {
          element.addEventListener('load', function () {
            count++;
            if (count === urls.length) {
              callback(urls);
            }
          });
        }
        document.getElementsByTagName('head')[0].appendChild(element);
      });
    }
  }

  /**
   * @deprecated Use CwWeb.OS.api instead - 22001201051
   */
  getPlatform(): CwVersionCheckPlatform {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    let _platform: CwVersionCheckPlatform = CwVersionCheckPlatform.other;

    if (CwWeb.macosPlatforms.indexOf(platform) !== -1) {
      _platform = CwVersionCheckPlatform.mac;
    } else if (CwWeb.iosPlatforms.indexOf(platform) !== -1) {
      _platform = CwVersionCheckPlatform.ios;
    } else if (CwWeb.windowsPlatforms.indexOf(platform) !== -1) {
      _platform = CwVersionCheckPlatform.windows;
    } else if (/Android/.test(userAgent)) {
      _platform = CwVersionCheckPlatform.android;
    }
    if (CwWeb.isTablet && CwWeb.isMac) {
      _platform = CwVersionCheckPlatform.ios;
    }

    return _platform;
  }

  getOS(): string {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    let os = null;

    if (CwWeb.macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (CwWeb.iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (CwWeb.windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;

  }

  // 1905171600
  fitty(): void {
    if (
      fitty &&
      fitty['fitAll'] &&
      true
    ) {
      fitty['fitAll']();
    }
  }

  loaderShow(): void {
    this.loaderVisible = true;
  }

  loaderHide(): void {
    this.loaderVisible = false;
  }

  /**
   * @version 1906051731
   * @param ClassList
   * @param Item
   */
  htmlClassAdd(Param: CwWebHtmlClassAddI) {
    if (
      Param &&
      Param.ClassList &&
      Param.HtmlElement &&
      true
    ) {
      if (!Param.HtmlElement.classList) {
        // DOMTokenList
        Param.HtmlElement.classList = new DOMTokenList();
        // Param.HtmlElement.classList = [];
      }
      // IE not support ...
      Param.ClassList.forEach(
        classItem => {
          if (
            classItem &&
            // Not exists
            !Param.HtmlElement.classList.contains(classItem) &&
            true
          ) {
            Param.HtmlElement.classList.add(classItem);
          }
        }
      );
    }
  }


  htmlHas(Param: CwWebHasI): boolean {
    return (
      // Edge
      (
        Param &&
        Param.name &&
        Param.HtmlElement &&
        Param.HtmlElement.hasAttribute &&
        Param.HtmlElement.hasAttribute(Param.name.toLowerCase()) &&
        true
      ) ||
      false
    );
  }

  /**
   * @version 1906261144
   * @param Param
   * TODO: sleep depends of OS
   */
  checkAndSignIn(Param: CwWebCheckSignIn): void {
    if (
      Param &&
      !Param.admin &&
      Param.Protocol &&
      Param.token &&
      true
    ) {
      Param.Protocol.check({
        token: Param.token
      });
      // 1907030844
      if (Param.App.downVisible) {

      } else {
        CwWeb.goTo({path: 'download-only'});
      }
      // // 1907030844
      //   CwWeb.goTo('download-only');
    }

    if (
      Param &&
      !Param.admin &&
      Param.userDo &&
      true
    ) {
      Param.userDo();
    }

    if (
      Param &&
      Param.token &&
      Param.KeepToken &&
      true
    ) {
      // // 2005210000
      // Param.KeepToken.Keep = Param.token;
    }

  }

  // // 1907021609
  // checkAndSignIn(Param: CwWebCheckSignIn): void {
  //   if (Param && Param.Protocol) {
  //     if (Param.token) {
  //       Param.Protocol.check({
  //         token: Param.token
  //       });
  //       const timeout = setTimeout(() => {
  //         if (Param.admin) {
  //           if (
  //               !CwFix.protocolIOsSafari({
  //                 platform: CwWeb.getPlatform(),
  //                 goTo: CwWeb.goTo
  //               })
  //           ) {
  //             CwWeb.goToAdmin(Param.token)
  //           }
  //         } else if (Param.userDo) {
  //           Param.userDo();
  //         }
  //         if (Param.KeepToken) {
  //           Param.KeepToken.Keep = Param.token
  //         }
  //
  //       }, 2000);
  //     }
  //   }
  //
  // }

  getBrowser(): CwWebBrowserI {
    const isChrome = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'CHROME' === this.Setup.DeviceDetectorService.browser.toUpperCase() &&
      true
    );
    const isFirefox = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'FIREFOX' === this.Setup.DeviceDetectorService.browser.toUpperCase() &&
      true
    );
    const isEdge = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'MS-EDGE' === this.Setup.DeviceDetectorService.browser.toUpperCase() &&
      true
    );
    const isSafari = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'SAFARI' === this.Setup.DeviceDetectorService.browser.toUpperCase() &&
      true
    );
    const isInternetExplorer = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'IE' === this.Setup.DeviceDetectorService.browser.toUpperCase() &&
      true
    );
    return {
      isChrome,
      isFirefox,
      isEdge,
      isSafari,
      isInternetExplorer,
    };
  }


  // 1907030844
  getOperativeSystem(): CwWebOSI {
    const isAndroid = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'ANDROID' === this.Setup.DeviceDetectorService.os.toUpperCase() &&
      true
    );
    const isIOS = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'IOS' === this.Setup.DeviceDetectorService.os.toUpperCase() &&
      true
    );
    const isMac = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'MAC' === this.Setup.DeviceDetectorService.os.toUpperCase() &&
      true
    );
    const isWindows = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'WINDOWS' === this.Setup.DeviceDetectorService.os.toUpperCase() &&
      true
    );
    return {
      isAndroid,
      isIOS,
      isMac,
      isWindows,
    };
  }

  /**
   * @override 1911200945
   * @param _Setup
   */
  setup(_Setup: CwWebSetupI) {
    this.Setup = _Setup;
    this.checkIsMobile();
    this.checkIsIpad();
    this.checkIsDesktop();
    this.checkIsTablet();
    this.checkIsInternetExplorer();
    this.CheckList.forEach(checkItem => checkItem());
    this.OS.check();
    this.Browser.check();
    this.Route.check();
  }

}

export const CwWeb = new CwToolWebClass();

export interface CwWebCheckSignIn {
  token: string;
  admin: boolean;
  KeepToken?: CwToolKeepC<string>;
  Protocol: ToolProtocol;
  userDo?: CwFunction;
  App: any;

}

export interface CwWebHtmlI {
  // HtmlElement: HTMLElement;
  HtmlElement: any;
}

export interface CwWebHtmlClassAddI extends CwWebHtmlI {
  ClassList: string[];
}

export interface CwWebHasI extends CwWebHtmlI {
  name: string;
}

export interface CwWebBrowserI {
  isChrome: boolean;
  isFirefox: boolean;
  isEdge: boolean;
  isSafari: boolean;
  isInternetExplorer: boolean;
}

export interface CwWebOSI {
  isMac: boolean;
  isWindows: boolean;
  isAndroid: boolean;
  isIOS: boolean;
}
