import {CwInput, CwInputType} from '@cw/components/input/cw-input-model';
import {CwLiteral} from '@cw/literal/literal';

/**
 * @version 2005210000
 */
class AppPlansSalesClass {
  AllInputSet = [];
  InputsSet = [
    new CwInput({
      api: 'firstName',
      label: 'First Name',
      onChange: (Item: CwInput) => {
        // AppPlansTool.Buy.Registration.firstName = Item.value;
      },
      type: CwInputType.TEXT,
      Validate: {
        cannotBeEmpty: true,
      },
    }),
    new CwInput({
      api: 'lastName',
      label: 'Last Name',
      type: CwInputType.TEXT,
      onChange: (Item: CwInput) => {
        // AppPlansTool.Buy.Registration.lastName = Item.value;
      },
      Validate: {
        cannotBeEmpty: true,
      }
    }),
    // 2006290000.3
    new CwInput({
      api: 'email',
      label: 'Email',
      class: 'col-12 col-12 col-sm-12 col-md-12',
      onChange: (Item: CwInput) => {

      },
      type: CwInputType.TEXT,
      Validate: {
        email: true,
        cannotBeEmpty: true,
      },
    }),
    new CwInput({
      api: 'companyName',
      label: 'Company',
      onChange: (Item: CwInput) => {
        // AppPlansTool.Buy.Registration.firstName = Item.value;
      },
      type: CwInputType.TEXT,
      Validate: {
        // cannotBeEmpty: true,
      },
    }),
    new CwInput({
      api: 'phoneNumber',
      label: 'Phone',
      type: CwInputType.TEXT,
      onChange: (Item: CwInput) => {
      },
      Validate: {}
    }),
  ];
  Comments = new CwInput({
    api: 'comments',
    label: 'Comments',
    type: CwInputType.COMMENTS,
    Validate: {
      cannotBeEmpty: true,
    },
    value: CwLiteral.translate('I have questions. Have a sales person contact me.')
  });
  Country = new CwInput(
    {
      api: 'country',
      label: 'Country',
      notLabel: true,
      type: CwInputType.VALIDATION,
      Validate: {
        cannotBeEmpty: true,
      }
    },
  );
  State = new CwInput(
    {
      api: 'state',
      label: 'State',
      notLabel: true,
      type: CwInputType.VALIDATION,
      Validate: {
        cannotBeEmpty: true,
      }
    },
  )

  _reset = (() => {
    this.InputsSet.forEach(Item => Item._reset());
    this.Comments._reset();
    this.Country._reset();
    this.State._reset();
  });

  constructor() {
    // 2006290000.3
    this.AllInputSet.push(
      ...this.InputsSet,
      this.Country,
      this.State,
      this.Comments,
    )
  }

}

/**
 * @version 2005210000 - 2006290000.3
 */
export const AppPlansSalesTool: AppPlansSalesClass = new AppPlansSalesClass();
