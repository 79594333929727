import {CwConditionModel, CwFunction, CwMap} from '../../model/model';
import {CwIs} from '../tool-is';
import * as _ from 'lodash';
import * as moment from 'moment';
import {CwToolUtilRemoveModel} from '@tool/util/cw-tool-util.model';

/**
 * @version 22001201051, 2007000000
 */
class ToolUtilClass {

  clear = (
    (...IO): void => {
      if (IO) {
        IO.forEach(
          (Item: any): void => {
            this._clear(Item);
          }
        );
      }
    }
  );
  clearChild = ((IO) => {
    if (IO) {
      Object.getOwnPropertyNames(IO).forEach(Item => {
        this.clear(IO[Item]);
      });
    }
  });
  clearData = (
    (IO: any): void => {
      // tslint:disable-next-line:forin
      for (const key in IO) {
        CwUtil.clear(IO[key]);
      }
    }
  );
  clearIO = ((IO: any): void => {
    if (IO) {
      if (
        IO.clear
      ) {
        // Is Map
        IO.clear();
      } else if (CwIs.array(IO)) {
        IO.splice(0, IO.length);
      } else {
      }
    }
  });
  exec = (
    (
      IO: any,
      Params?: any,
    ) => {
      if (typeof IO === 'object') {
        this.execObject(IO, Params);
      }
    }
  );
  execList = ((
    IO: any[],
    Params?: any,
  ) => {
    IO.forEach(
      (itemFunction) => {
        itemFunction(Params);
      }
    );
  });

  execObject = (
    (
      IO: object,
      Params?: any,
    ) => {
      Object.keys(IO).forEach(
        (key: string) => {
          if (IO[key] && typeof IO[key] === 'function') {
            IO[key](Params);
          }
        }
      );
    });

  getBy = (
    (Params: {
      property: string,
      value: any,
      ItemsList: any[],
    }) => {
      let Item = null;
      if (Params && Params.property) {
        const _ItemsFilteredList = Params.ItemsList.filter(
          Filter => Filter[Params.property] === Params.value
        );
        if (_ItemsFilteredList && _ItemsFilteredList[0]) {
          Item = _ItemsFilteredList[0];
        }
      }
      return Item;
    }
  );

  has = (
    (Array: any[], condition: CwConditionModel): boolean => {
      return this.exist({
        Array,
        condition,
      });
    }
  );

  // 2009111050
  isEqual = ((ItemA: object, ItemB: object): boolean => {
      try {
        return _.isEqual(ItemA, ItemB);
      } catch (e) {
        console.error('CW2009111136', e);
      }
    }
  );

  rm = (
    (List: any[], condition: CwFunction) => {
      this.remove({
        List,
        condition,
      });
    }
  );

  public checking(Target: any): void {
    if (Target) {
      const _Target = Target;
      Object.getOwnPropertyNames(_Target).forEach(
        _TargetItem =>
          _Target &&
          _Target[_TargetItem] &&
          _Target[_TargetItem].check &&
          _Target[_TargetItem].check()
      );
    }
  }

  uuid(prefix = ''): string {
    let d = new Date().getTime();
    const uuid =
      'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
          // tslint:disable-next-line:no-bitwise
          const r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          // tslint:disable-next-line:no-bitwise
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    return prefix + uuid;
  }


  public _clear(IO: any): void {
    if (IO) {
      if (
        IO.clear &&
        true
      ) {
        // Is Map
        IO.clear();
      } else if (CwIs.array(IO)) {
        IO.splice(0, IO.length);
      } else {
      }
    }
  }

  public mapToArray(_Map: CwMap<any>, ArrayIO?: any[]): any[] {
    if (ArrayIO) {
      CwUtil.clear(ArrayIO);
      ArrayIO.push(...Array.from(_Map.values()));
    } else {
      return Array.from(_Map.values());
    }
  }

  doList(List: CwFunction[]) {
    if (List) {
      List.forEach(FunctionItem => {
        if (FunctionItem) {
          FunctionItem();
        }
      });
    }
  }

  utcToDate(_utc: string, defaultReturn = new Date()): Date {
    let _Date = defaultReturn;
    if (_utc) {
      const _DateArray = _utc.toLowerCase().split('z');
      if (_DateArray) {
        const _DateString = _DateArray[0];
        if (_DateString) {
          _Date = moment(_DateString.replace('t', ' ')).toDate();
        }
      }
    }
    return _Date;
  }

  clone(data: any): any {
    return _.cloneDeep(data);
  }

  /**
   * @version 2007172200
   * @param Param
   */
  exist(Param: {
    Array: any[],
    // Option I
    Item?: any,
    fieldId?: string,
    // Option II
    condition?: CwConditionModel,
    // Añadir si no existe
    added?: boolean
  }): boolean {
    let exist = false;
    try {
      if (Param.Item && Param.Array) { // Option I
        if (!Param.fieldId) {
          Param.fieldId = '_id';
        }
        const ExistList = Param.Array.filter(
          (Item) =>
            Param.Item[Param.fieldId] === Item[Param.fieldId]
        );
        exist = ExistList && ExistList.length > 0;
      } else if (Param.condition && Param.Array) { // Option II
        exist = Param.Array.filter(Param.condition).length > 0;
      }
      // 2010290000.D11.3
      if (!exist && Param.added) {
        Param.Array.push(Param.Item);
      }
    } catch (e) {
      console.log('2007180024', 'ToolUtilClass.exist()', e);
    }
    return exist;
  }


  extractPropertyToList(Param: {
    ItemsList: any[],
    property?: string,
  }): any[] {
    const OutList: any[] = [];
    if (
      Param &&
      Param.ItemsList &&
      true
    ) {
      if (!Param.property) {
        Param.property = '_id';
      }
      Param.ItemsList.forEach(Item => {
        OutList.push(Item[Param.property]);
      });
    }
    return _.uniq(OutList);
  }

  // 1903291017
  pushOnly(outArray: any[], _item: any, _objectKey?: string): number {
    let _pushOnly = -1;
    try {
      if (
        !CwIs.array(outArray) ||
        _item
      ) {
        // throw new CoError('201802151539', 'pushOnly', 'Params undefined');
      }
      let _ItemNews = _item;
      if (!CwIs.array(_ItemNews)) {
        _ItemNews = [_item];
      }
      _ItemNews.forEach(_itemNew => {
        let _equal = false;
        outArray.forEach(_listItem => {
          _equal = _equal || _listItem[_objectKey] === _itemNew[_objectKey];
        });
        if (!_equal) {
          outArray.push(_itemNew);
        }
      });
      _pushOnly = outArray.length;
    } catch (error) {
      _pushOnly = -1;
      // const x = new CoError('201802151544', 'pushOnly', error);
    }
    return _pushOnly;
  }

// 1907251253


  remove(Param: CwToolUtilRemoveModel) {
    _.remove(Param.List, Param.condition);
  }

  // // 1907251253
  // // 1903291017
  // remove(arrayIO: any[], _removeItem: any, _removeKey?: string): number {
  //   try {
  //     arrayIO.forEach((_item, _index) => {
  //       if (CwIs.defined(_removeKey)) {
  //         if (_item[_removeKey] === _removeItem[_removeKey]) {
  //           arrayIO.splice(_index, 1);
  //         }
  //       } else if (_item === _removeItem) {
  //         arrayIO.splice(_index, 1);
  //       }
  //     });
  //   } catch (error) {
  //     // CoLog.error('CoArray.remove', arrayIO, _removeItem, _removeKey);
  //   }
  //
  //   return (CwIs.array(arrayIO))
  //     ? arrayIO.length
  //     : undefined
  //     ;
  //
  // }


  // 1904031655
  sortAsc(value1: any, value2: any, trim?: boolean): number {
    if (typeof value1 === 'string') {
      // 2011231615
      if (trim) {
        value1 = value1.toUpperCase().trim();
      } else {
        value1 = value1.toUpperCase();
      }
    }
    if (typeof value2 === 'string') {
      // 2011231615
      if (trim) {
        value2 = value2.toUpperCase().trim();
      } else {
        value2 = value2.toUpperCase();
      }
    }
    if (undefined === value1 || undefined === value2) {
      return 0;
    }
    if (value1 > value2) {
      return 1;
    }
    if (value1 < value2) {
      return -1;
    }
    return 0;
  }

  sortAscByDate(ItemA, ItemB): number {
    let sort = 0;
    if (ItemA && ItemB && ItemA.Date && ItemB.Date) {
      if (ItemA.Date > ItemB.Date) {
        sort = 1;
      } else if (ItemA.Date < ItemB.Date) {
        sort = -1;
      }
    }
    return sort;
  }


  // 1904031655
  sortDesc(value1: any, value2: any, trim?: boolean): number {
    return (-1) * CwUtil.sortAsc(value1, value2, trim);
  }


  /**
   * @version 1906251012
   * @description https://expertcodeblog.wordpress.com/2018/07/05/typescript-sleep-a-thread/
   */
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // 1907251253
  setProperties(_source: any,
                target: any,
                _propertiesList: string[] = [],
                // 1910081139
                _onlyIfExist = false,
                _removeIfNotExist = false
  ): boolean {
    if (CwIs.defined(_source, target)) {
      if (CwIs.arrayEmpty(_propertiesList)) {
        _propertiesList = Object.keys(_source);
      }
      _propertiesList.forEach(keyItem => {
        try {
          // 1910081139
          if (
            _onlyIfExist &&
            target.hasOwnProperty(keyItem) &&
            true
          ) {
            target[keyItem] = _source[keyItem];
          } else {
            if (target[keyItem] !== _source[keyItem]) {
              target[keyItem] = _source[keyItem];
            }

          }
        } catch (error) {
          console.error('201802201746', 'CwUtil.setProperties', error, keyItem, _source[keyItem]);
        }
      });
    }
    return true;
  }

  removeProperties(_source: any,
                   target: any
  ): boolean {
    let _propertiesListTarget: string[] = [];

    if (CwIs.defined(_source, target)) {
      _propertiesListTarget = Object.keys(target);
      _propertiesListTarget.forEach(keyItem => {
        try {
          if (
            !(_source.hasOwnProperty(keyItem)) &&
            true
          ) {
            target[keyItem] = undefined;
          }
        } catch (error) {
          console.error('201802201746', 'CwUtil.removeProperties', error, keyItem, _source[keyItem]);
        }
      });
    }
    return true;
  }

  // 1908011133
  contains(Param: {
    target: string,
    PossibleValuesList: string[],
    onlyPossibleValues: boolean,
  }): boolean {
    let contains = Param.onlyPossibleValues;
    if (Param.target) {
      for (const targetChar of Param.target.split('')) {
        let found = false;
        for (const possibleChar of Param.PossibleValuesList) {
          found = (
            found ||
            (
              targetChar &&
              targetChar.toUpperCase &&
              possibleChar &&
              possibleChar.toUpperCase &&
              targetChar.toUpperCase() === possibleChar.toUpperCase()
            ) ||
            false
          );
        }
        if (Param.onlyPossibleValues) {
          contains = contains && found;
        } else {
          contains = contains || found;
        }
      }
    }

    return contains;
  }


  difference(Params: {
    BaseList: any[],
    EliminatorList: any[],
    property?: string,
    properties?: string[],
  }): any[] {
    if (Params.property) {
      return _.differenceBy(Params.BaseList, Params.EliminatorList, Params.property);
    } else if (Params.properties) {
      const comparator = (ItemA, ItemB): boolean => {
        let _comparator = true;
        Params.properties.forEach(property => {
          _comparator = _comparator && ItemA[property] === ItemB[property];
        });
        return _comparator;
      };
      return _.differenceWith(Params.BaseList, Params.EliminatorList, comparator);
    } else {
      return null;
    }
  }
}

export const CwUtil = new ToolUtilClass();
