import {CW_GOOGLE_LOG_TOOL} from './CollaborateSpaceWebTools/tool/google/log/google-log-tool';
import {CwOnP} from './CollaborateSpaceWebTools/tool/on-premise/tool-on-premise';
import {CwInfoInstallationBus} from '@rest/info/installation/cw-info-installation-bus.class';

/**
 * @version 2003251014
 */
export const AppTool = {
  googleLog: () => {
    if (!CwInfoInstallationBus.domain) {
      CwInfoInstallationBus.Bus$.subscribe(
        () => {
          CW_GOOGLE_LOG_TOOL.record({
            isOnPremise: CwOnP.is,
            domain: CwInfoInstallationBus.domain
          });
        }
      )
    } else {
      CW_GOOGLE_LOG_TOOL.record({
        isOnPremise: CwOnP.is,
        domain: CwInfoInstallationBus.domain
      });
    }

  }
}
