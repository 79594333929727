<ng-container
  *ngIf="Setup && Setup.Bus && Setup.Bus.ItemsList && Setup.Bus.ItemsList.length > 0"
>

  <div
    class="form-check form-check-inline"
  >
    <div class="m-2 pt-1 pr-1">
      {{Setup.titleLabel|translate}}
    </div>
    <div
      *ngFor="let Item of Setup.Bus.ItemsList; index as index"
      xxx-2005270000-1
      class="custom-control custom-radio"
      (click)="select(Item)"
    >
      <input
        type="radio"
        [id]="id + index"
        class="custom-control-input"
        [name]="id"
        [checked]="isChecked({Item:Item, index:index})"
        [disabled]="Item.disabled"
      >
      <label
        class="custom-control-label px-4"
        xxx-2005270000-2
        [ngClass]="{'text-primary font-weight-bolder font-bold': isChecked({Item:Item, index:index})}"
        [for]="id + index"
        translate
      >{{Item[Setup.Bus.ItemsRadio.web]}}</label>
    </div>

  </div>

</ng-container>
