import {isArray, isBoolean, isNumber, isString} from 'util';
import {CsToolClass} from './tool';
import {CsSetup} from '../model/model';

class CsToolIsClass extends CsToolClass<CsSetup> {

  Setup;

  array(data: any): boolean {
    return (
      data &&
      isArray(data) &&
      // data.isArray() &&
      true
    );
  }

  arrayNotEmpty(data: any): boolean {
    return (
      CsIs.array(data) &&
      0 < data.length &&
      true
    );
  }


  arrayEmpty(data: any): boolean {
    return (
      CsIs.array(data) &&
      0 === data.length &&
      true
    );
  }

  arraySingle(data: any): boolean {
    return CsIs.arrayWithLength(data, 1);
  }

  arrayWithLength(data: any, length: number): boolean {
    return (
      CsIs.array(data) &&
      length === data.length &&
      true
    );
  }

  boolean(data: any): boolean {
    let _valid = false;
    if (CsIs.defined(data)) {
      try {
        _valid = isBoolean(Boolean(data));
      } catch (error) {
        _valid = false;
      }
    }
    return _valid;
  }

  defined(...dataList: any[]): boolean {
    let _isDefined = false;
    try {
      _isDefined = (
        undefined !== dataList &&
        null !== dataList &&
        // isNaN(data) &&
        // NaN !== data &&
        true
      );
      if (
        _isDefined &&
        isArray(dataList) &&
        true
      ) {
        for (const item of dataList) {
          _isDefined = (
            _isDefined &&
            undefined !== item &&
            // isNaN(item) &&
            // NaN !== item &&
            null !== item
          );
        }
      }
    } catch (error) {
      _isDefined = false;
    }
    return _isDefined;
  }

  number(data: any): boolean {
    let _valid = false;
    if (CsIs.stringNotEmpty(String(data))) {
      try {
        _valid = isNumber(data);
      } catch (error) {
        _valid = false;
      }
    }
    return _valid;
  }

  string(data: any): boolean {
    return (
      CsIs.defined(data) &&
      isString(data) &&
      true
    );
  }

  stringEmpty(data: any): boolean {
    return (
      CsIs.string(data) &&
      String('') === String(data) &&
      true
    );
  }

  stringEqual(data: any, than: string): boolean {
    let _isStringEqual = false;
    if (
      (
        CsIs.stringNotEmpty(data) ||
        CsIs.number(data)
      ) &&
      String(data) === than
    ) {
      _isStringEqual = true;
    }
    return _isStringEqual;
  }

  stringNotEmpty(data: any): boolean {
    return (
      CsIs.string(data) &&
      String('') !== String(data) &&
      true
    );
  }

  true(data: any | any[], breakFalse = false): boolean {
    let _valid = false;
    if (CsIs.array(data)) {
      _valid = CsIs.trueAll(data, true);
    } else if (CsIs.stringEqual(typeof data, 'string')) {
      _valid = (
        CsIs.stringEqual(data, '1') ||
        CsIs.stringEqual(data.toLowerCase(), 'true')
      );
    } else {
      _valid = (
        CsIs.boolean(data) &&
        Boolean(data) &&
        true
      );
    }
    return _valid;
  }

  protected trueAll(dataList: any[], breakFalse = false): boolean {
    let _isTrueAll = true;
    if (CsIs.arrayNotEmpty(dataList)) {
      for (const itemBoolean of dataList) {
        _isTrueAll = CsIs.true(itemBoolean) && _isTrueAll;
        if (breakFalse && !_isTrueAll) {
          break;
        }
      }
    } else {
      _isTrueAll = false;
    }
    return _isTrueAll;
  }

  setup(_setupParam: any) {
  }


}

/**
 * @version R2.1 - Password checking
 */
export const CsIs = new CsToolIsClass();
export const CsToolIs = CsIs;
