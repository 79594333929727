import {CwToolModel} from '@cw/6.0/tools/tool.model';
import {CwTrust} from '@cw/trust/trust';
import {CwStripe} from '@tool/stripe/tool';
import {CwUtil} from '@tool/util/cw-tool-util';
import {Subject} from 'rxjs';

/**
 * @version 2010290000.D11.3
 */
export class CwToolScaClass extends CwToolModel {

  private _ClientSecretList: string[] = [];

  // 2010290000.D11.3 //////////////////////////////////////////////////////////
  Error = {
    Subject$: new Subject(),

    next: (message) => {
      this.Error.Subject$.next(message);
    },

    subscribe: (callback) => {
      this.Error.Subject$.asObservable().subscribe(callback);
    }
  };
  Success = {
    Subject$: new Subject(),

    next: () => {
      this.Success.Subject$.next();
    },

    subscribe: (callback) => {
      this.Success.Subject$.asObservable().subscribe(callback);
    }
  };
  //////////////////////////////////////////////////////////////////////////////

  // 2010290000.D12 ////////////////////////////////////////////////////////////
  NotNecessary = {
    Subject$: new Subject(),

    next: () => {
      this.NotNecessary.Subject$.next();
    },

    subscribe: (callback) => {
      this.NotNecessary.Subject$.asObservable().subscribe(callback);
    }
  };

  //////////////////////////////////////////////////////////////////////////////

  add = (clientSecret: string) => {
    CwUtil.exist({
      Array: this._ClientSecretList,
      condition: (item: string) => {
        return item === clientSecret;
      },
      added: true,
    });
    this._ClientSecretList.push(clientSecret);
  };

  accept = (_clientSecret: string) => {
    CwUtil.remove({
      condition: (_item) => {
        return _item === _clientSecret;
      },
      List: this._ClientSecretList
    });
  };

  isPending = (): boolean => {
    return this._ClientSecretList.length > 0;
  };

  check = (Data?) => {
    // 2010290000.D11.3
    if (
      Data &&
      Data.result &&
      Data.result.sca &&
      Data.result.sca.clients &&
      Data.result.sca.clients.length > 0
    ) {
      Data.result.sca.clients.forEach(_item => {
        this.open(_item);
      });
    } else if (      !Data && this.isPending()    ) {
      this._ClientSecretList.forEach(_item => {
        this.open(_item);
      });
    } else {
      // 2010290000.D12
      this.NotNecessary.next();
    }
  };

  // 2010290000.D11.3
  open = (clientSecret: string) => {


    if (clientSecret) {
      this.add(clientSecret);

      CwStripe.Sca.confirm({
        clientSecret,
        errorCallback: (message) => {
          this.Error.next(message);
        },
        successCallback: () => {
          this.accept(clientSecret);
          this.Success.next();
        }
      });
    } else {
      console.error('2011201021');
    }


  };

  successActionCustomCheck = (Param): any => {
    if (Param && Param.successActionCustom) {
      const successActionCustom = Param.successActionCustom;
      Param.successActionCustom = (Data) => {
        CwToolSca.check(Data);
        successActionCustom(Data);
      };
    } else if (Param && !Param.successActionCustom) {
      Param.successActionCustom = (Data) => {
        CwToolSca.check(Data);
      };
    }
  };


  constructor() {
    super();
    CwTrust.Sca = this;
  }

}

export const CwToolSca = new CwToolScaClass();
