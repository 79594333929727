import {Component, ViewChild} from '@angular/core';
import {Result} from '../common/result';
import {NgForm} from '@angular/forms';
import {CoWeb} from '../co/tools/web';
import {ActivatedRoute} from '@angular/router';
import {CoValidationsComponent} from '../co/validations/component';
import {CwUserRegistrationBus} from '../CollaborateSpaceWebTools/user/registration/registration-bus';
import {CwUserRegistrationDoI} from '../CollaborateSpaceWebTools/user/registration/registration-model';
import {App} from '../app';
import {errorActionCustomD, tokenIsValid} from '../app-default';
import {CwTokenGet} from '../CollaborateSpaceWebTools/token/get/get-bus';
import {CwWeb} from '../CollaborateSpaceWebTools/tool/web/tool-web';
import {CwProtocol} from '../CollaborateSpaceWebTools/tool/protocol/tool-protocol';
import {AppWebTool} from '../app-web.tool';

declare var protocolObject: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
})
/**
 * @version 1904101050
 * @version R8.2 - Enter your new password
 */
export class RegistrationComponent extends CoValidationsComponent {

  password: string;
  message: String;

  creating = false;

  result: Result;

  @ViewChild('formCtrl') public formCtrl: NgForm;

  constructor(
    private _ActivatedRoute: ActivatedRoute,
  ) {
    super();
    // 1906270835
    tokenIsValid(
      CwTokenGet.ACTION.REGISTRATION,
      CwWeb.getToken({ActivatedRoute: this._ActivatedRoute}),
    );
  }

  OnInit(): void {
    AppWebTool.Title.main = 'Welcome to Collaborate';
  }

  /**
   * @version R4.6 - Ticket 837 - ...
   */
  accept() {
    if (
      this.CsValidation.passwordOk(
        this.password,
        this.ValidationList,
        true) &&
      this.formCtrl.form.valid
    ) {
      this.creating = true;
      // 1904101050
      CwUserRegistrationBus.do(<CwUserRegistrationDoI>{
        Bus: CwUserRegistrationBus,
        Parameters: {
          token: CoWeb.getToken(this._ActivatedRoute),
          password: this.password,
        },
        errorActionCustom: errorActionCustomD,
        errorRestActionCustom: errorActionCustomD,
        successActionCustom: ((Data) => {
          App.Body.visible = false;
          AppWebTool.Title.main = 'Congratulations, You are registered in the system';
          if (
            Data &&
            Data.result &&
            Data.result.token &&
            true
          ) {
            CwWeb.checkAndSignIn({
              token: Data.result.token,
              // 1907021609
              admin: false,
              // admin: Data.result.admin,
              Protocol: CwProtocol,
              App,
            })
          }
          App.Protocol.check({})
        }),
      })
      // // 1904101050
      // this.registrationService.registerUser(
      //     CoWeb.getToken(this._ActivatedRoute),
      //     this.password
      // )
      //     .subscribe(
      //         result => {
      //             this.result = result;
      //             if (this.result.errorCode === 0) {
      //                 this.message = 'Congratulations, You are registered in the system';
      //                 CoProtocolBus.check(this.informationService.client);
      //             } else {
      //                 this.message = this.result.error;
      //             }
      //         },
      //         error => this.message = <any>error);
    }
  }
}
