import {UserBus} from '../user-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwUserRegistrationDoI, CwUserRegistrationParametersI} from './registration-model';

class UserRegistrationBus extends UserBus {


    defineUrl: CwDefineUrl = (() =>
            CwUserRegistrationBus.path + 'registration' +
            '?token=' +
            CwUserRegistrationBus.Parameters.token +
            '&password=' +
            encodeURIComponent(CwUserRegistrationBus.Parameters.password) +
            ''
    );


    do: CwFunction = ((Parameters: CwUserRegistrationDoI) =>
            CwUserRegistrationBus.restPost(Parameters)
    );


    Parameters: CwUserRegistrationParametersI;


}

export const CwUserRegistrationBus = new UserRegistrationBus();

// // 1904101050
// @Injectable()
// export class RegistrationService extends BasicService {
//
//     url = AppSettings.API_ENDPOINT + 'user/registration';
//
//     constructor(private http: Http) {
//         super();
//     }
//
//
//     registerUser(token: string, password: string): Observable<Result> {
//
//         const headers = new Headers({'Content-type': 'application/json'});
//         const options = new RequestOptions({headers: headers});
//         return this.http.post(this.url +
//         '?token=' + token
//         + '&password=' + encodeURIComponent(password)
//         , options)
//             .map(this.extractData.bind(this))
//             .catch(this.handleError);
//     }
//
// }
