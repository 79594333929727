import {EntityBus} from '@rest/entity/entity-bus';
import {CwFunction} from '../../../model/model';
import {CwEntityCheckEmailFromPortalDoModel} from '@rest/entity/check-email-from-portal/entity-check-email-from-portal.model';

class EntityCheckEmailFromPortalBus extends EntityBus {
  DefineProperties: CwFunction[];

  defineUrl = () => this.path + 'checkEmailFromPortal';

  do = (Params: CwEntityCheckEmailFromPortalDoModel) => {
    Params.Bus = this;
    Params.PayLoadCustom = {email: Params.email};
    this.restPut(Params);
  }

}

export const CwEntityCheckEmailFromPortalBus: EntityCheckEmailFromPortalBus = new EntityCheckEmailFromPortalBus();
