import {CwInput, CwInputType} from '@cw/components/input/cw-input-model';
import {CwVal} from '@tool/validation/tool-validation';
import {CwWeb} from '@tool/web/tool-web';
import {AppDataSignBus} from './data-sign-up-bus';
import {DataSignUpCouponModel} from './data-sign-up.model';
import {AppDataSignUpCouponTool} from './data-sign-up-coupon.tool';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwTrialBus} from '@cw/entity/trial/trial-bus';
import {CwEntityTrialDoI} from '@cw/entity/trial/trial-model';
import {CwUserResetBus} from '@cw/user/reset/reset-bus';
import {CwUserResetDoI} from '@cw/user/reset/reset-model';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';
import {CwCountry} from '@rest/literal/country/country-bus';
import {App} from '../app';


export class AppDataSignUpToolClass {

  Country: any;
  Coupon: DataSignUpCouponModel = AppDataSignUpCouponTool;


  InputCountry = new CwInput(
    {
      api: 'country',
      label: 'Country',
      notLabel: true,
      type: CwInputType.VALIDATION,
      Validate: {
        passive: true,
        cannotBeEmpty: true,
      }
    },
  );
  InputEmail = new CwInput({
    api: 'email',
    label: 'Email',
    type: CwInputType.TEXT,
    Validate: {
      email: true,
      passive: true,
      cannotBeEmpty: true,
    },
  });
  InputEmailRepeat = new CwInput({
    api: 'emailRepeat',
    label: 'Confirm Email',
    type: CwInputType.TEXT,
    Validate: {
      ConfirmInput: this.InputEmail,
      passive: true,
    }
  });
  InputFirstName = new CwInput({
    api: 'firstName',
    label: 'First Name',
    type: CwInputType.TEXT,
    Validate: {
      passive: true,
      cannotBeEmpty: true,
    },
  });
  InputLastName = new CwInput({
    api: 'lastName',
    label: 'Last Name',
    type: CwInputType.TEXT,
    Validate: {
      passive: true,
      cannotBeEmpty: true,
    },
  });
  InputPassword = new CwInput({
    api: 'password',
    label: 'Password',
    onChange: (Item: CwInput) => {
      // AppPlansTool.Buy.Registration.password = Item.value;
    },
    type: CwInputType.PASSWORD_MINIMAL,
    Validate: {
      cannotBeEmpty: true,
      passive: true,
      passwordKetan: true,
    },
    ValidationRelationList: [
      () => {
        // CwVal.check(AppPlansBuyRegistrationPasswordConfirmInput);
      }
    ]
  });
  InputPasswordRepeat = new CwInput({
    api: 'confirmPassword',
    label: 'Confirm Password',
    type: CwInputType.PASSWORD_MINIMAL,
    Validate: {
      ConfirmInput: this.InputPassword,
      passive: true,
    }
  });
  // 2010151600
  InputReasonOfUse = new CwInput(
    {
      api: 'reasonOfUse',
      label: '',
      notLabel: true,
      type: CwInputType.VALIDATION,
      Validate: {
        // // 2007291000
        // passive: true,
        // cannotBeEmpty: true,
      },
    },
  );

  InputState = new CwInput(
    {
      api: 'state',
      label: 'State',
      notLabel: true,
      type: CwInputType.VALIDATION,
      Validate: {
        cannotBeEmpty: true,
      }
    },
  );
  State: any;
  StateSetup: any;


  Activation = {


    check: ((): boolean => {
      this.Registration.valid = true;
      for (const Item of this.Registration.InputSet) {
        if (CwVal.check(Item)) {
        } else {
          this.Registration.valid = false;
          break;
        }
      } // for

      return this.Registration.valid;
    }),

    On: {
      clicked: ((token) => {
        if (this.Activation.check()) {
          CwWeb.loaderShow();
          CwUserResetBus.do(<CwUserResetDoI>{
            Bus: CwUserResetBus,
            Parameters: {
              password: this.InputPassword.value,
              token,
            },
            successActionCustom: this.Registration.On.successActionCustom,
            errorActionCustom: this.Registration.On.errorActionCustom,
            errorRestActionCustom: this.Registration.On.errorActionCustom,
          });
        }
      })
    }
  };

  Invitation = {
    jid: undefined,
    applicant: undefined,

    check: ((): boolean => {
      this.Registration.valid = true;
      for (const Item of this.Registration.InputSet) {
        if (CwVal.check(Item)) {
        } else {
          this.Registration.valid = false;
          break;
        }
      } // for
      return this.Registration.valid;
    }),

    On: {
      clicked: (() => {
        if (this.Invitation.check()) {
          // 2011160000.D1
          CwWeb.loaderShow();
          const PayLoadCustom: any = {};
          PayLoadCustom.email = this.InputEmail.value;
          PayLoadCustom.password = this.InputPassword.value;
          PayLoadCustom.contact = this.InputFirstName.value +
            ' ' + this.InputLastName.value;
          PayLoadCustom.jid = this.Invitation.jid;
          PayLoadCustom.applicant = this.Invitation.applicant;
          PayLoadCustom.trial = false;
          PayLoadCustom.coupon = this.Coupon.api;
          PayLoadCustom.TransactionId = CwUtil.uuid();
          PayLoadCustom.reasonOfUse = this.InputReasonOfUse.value;
          PayLoadCustom.country = this.InputCountry.value;
          PayLoadCustom.state = this.InputState.value;

          CwTrialBus.do(<CwEntityTrialDoI>{
            PayLoadCustom,
            Bus: CwTrialBus,
            successActionCustom: this.Registration.On.successActionCustom,
            errorActionCustom: this.Registration.On.errorActionCustom,
            errorRestActionCustom: this.Registration.On.errorActionCustom,
            Parameters: undefined,
          });
        }
      }),
    },
  };

  // 2010151600
  ReasonOfUse: any;

  Registration = {
    valid: false,


    check: ((): boolean => {
      this.Registration.valid = true;
      for (const Item of [...this.Registration.InputSet, this.InputCountry, this.InputState, this.InputReasonOfUse]) {
        if (!CwVal.check(Item)) {
          this.Registration.valid = false;
          break;
        }
      } // for
      return this.Registration.valid;
    }),

    InputSet: [
      this.InputFirstName,
      new CwInput({
        api: 'lastName',
        label: 'Last Name',
        type: CwInputType.TEXT,
        Validate: {
          passive: true,
          cannotBeEmpty: true,
        }
      }),
      this.InputEmail,
      this.InputEmailRepeat,
      this.InputPassword,
      this.InputPasswordRepeat,
    ],


    On: {

      Country: {
        changed: (ValueNew): void => {
          this.StateSetup = {
            OptionList: []
          };

          if (this.Country && this.Country.api) {
            this.State = undefined;

            if (this.Country.StateList) {
              this.StateSetup.OptionList.push(
                ...this.Country.StateList
              );
            }

            // 2006101639
            if (this.StateSetup.OptionList.length === 0) {
              this.StateSetup.OptionList.push({
                coGridSelected: false,
                coId: undefined,
                country_id: -1,
                cwKey: -1,
                id: -1,
                name: this.Country.api,
                web: this.Country.web,
              });
            }
            this.State = this.StateSetup.OptionList[0];

            this.Registration.On.State.changed();

            this.InputCountry.set(this.Country.api);
            this.InputCountry.changed = true;
          }
        },
      },

      errorActionCustom: undefined,

      SignUp: {

        clicked: (() => {
          if (this.Registration.check()) {
            CwWeb.loaderShow();
            const PayLoadCustom: any = {};
            [
              ...this.Registration.InputSet,
              this.InputCountry,
              this.InputState,
            ].forEach(
              Item => PayLoadCustom[Item.api] = Item.value
            )

            if (!PayLoadCustom.reasonOfUse) {
              PayLoadCustom.reasonOfUse = 'Personal';
            }

            PayLoadCustom.contact = PayLoadCustom.firstName +
              ' ' +
              PayLoadCustom.lastName;
            PayLoadCustom.jid = AppDataSignBus.jid;
            PayLoadCustom.applicant = AppDataSignBus.applicant;
            PayLoadCustom.trial = false;
            PayLoadCustom.coupon = this.Coupon.api;
            PayLoadCustom.TransactionId = CwUtil.uuid();

            // 2009210000
            if (App.referred) {
              PayLoadCustom.referred = App.referred;
            }

            const Country = CwCountry.ItemsList.find((item) =>
              item.api === this.InputCountry.value);
            if (Country) {
              // 2011191236
              if (!Country.mainLanguageCode) {
                if (Country.languages && Country.languages[0]) {
                  Country.mainLanguageCode = Country.languages[0].iso639_1;
                }
              }

              PayLoadCustom.currency = CwToolCurrency.getCountryCurrencyCode(Country[CwCountry.ItemsKey]).toLowerCase();
              PayLoadCustom.invoiceLanguage = Country.mainLanguageCode + '_' + Country.countryCode;
            }

            CwTrialBus.do(<CwEntityTrialDoI>{
              PayLoadCustom,
              Bus: CwTrialBus,
              successActionCustom: this.Registration.On.successActionCustom,
              errorActionCustom: this.Registration.On.errorActionCustom,
              errorRestActionCustom: this.Registration.On.errorActionCustom,
              Parameters: undefined,
            });
          }
        }),
      },
      State: {
        changed: (() => {
          if (this.State && this.State.name) {
            this.InputState.set(this.State.name);
            this.InputState.changed = true;
          }
        })
      },
      successActionCustom: (Data, That): void => {
        CwWeb.loaderHide();
        if (Data && Data.result) {
          CwWeb.goToAdmin(Data.result);
        } else {
          CwWeb.goTo({
            path: '/#/datasignin',
          })
        }
      },
    },

  }

  constructor(Params?: { inputSetReset?: boolean }) {
    if (Params && Params.inputSetReset) {
      CwUtil.clear(this.Registration.InputSet)
    }
  }
}

// export const AppDataSignUpTool: AppDataSignUpToolClass = new AppDataSignUpToolClass();
