import {CwBusClass} from '../../model/bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwToolKeepC} from '../../tool/keep/tool-keep';
import {CwWeb} from '../../tool/web/web.tool';
import {CwTrust} from '../../trust/trust';
import {CwNavigateI, CwPageOpenI} from './cw-page-model';
import {ACTIONS_API, ACTIONS_MAP} from '../../../../environments/environment.define';
import {NavigationExtras} from '@angular/router';

class PageBusClass extends CwBusClass {
  DefineProperties: CwFunction[];
  Keep: CwToolKeepC<any>;
  Setup: any;
  defineUrl: CwDefineUrl;
  do: CwFunction;

  open = ((Param: CwPageOpenI) => {
    if (CwPageBus.Items.has(Param.pageKey)) {
      CwPageBus.Items.delete(Param.pageKey);
    }
    if (Param.ItemList) {
      CwPageBus.Items.set(Param.pageKey, Param.ItemList);
    } else {
      CwPageBus.Items.set(Param.pageKey, Param.Item);
    }
    if (CwTrust.adminBehaviour) {
      CwWeb.goTo({
        path: ACTIONS_MAP.get(Param.pageKey).routerLink[0],
        byUrl: CwTrust.adminBehaviour,
      });
    } else {
      CwWeb.goTo({path: ACTIONS_MAP.get(Param.pageKey).iframeLink[0] + '/' + CwTrust.token});
    }
  });

  get = ((pageKey): any => CwPageBus.Items.get(pageKey));

  close = ((pageKey) => {
    CwPageBus.Items.delete(pageKey);
  });

  navigate = ((Param: CwNavigateI) => {
    if (Param.ActiveTab) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          'activeTab': Param.ActiveTab,
        }
      };
      if (CwTrust.adminBehaviour) {
        Param.Router.navigate([Param.Action.routerLink[0]], navigationExtras);
      } else {
        CwWeb.goTo({path: ACTIONS_MAP.get(Param.Action).iframeLink[0] + '/' + CwTrust.token});
      }
    } else if (CwTrust.adminBehaviour) {
      Param.Router.navigate([Param.Action.routerLink[0]]);
    } else {
      CwWeb.goTo({path: ACTIONS_MAP.get(Param.Action).iframeLink[0] + '/' + CwTrust.token});
    }
  });
}



export const CwPageBus = new PageBusClass();
