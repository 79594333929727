import {CwSpacePlanToolDefinePlanModel, CwSpacePlanToolGetByModel} from '@space/plan/space-plan.model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwSpacePlanModel} from '@cw/components/space/plan/cw-space-plan.model';
import {CW_SPACE_SUBSCRIPTION_TYPE_MODEL} from '@space/subscription/tool-space-subscription-model';

export const CwSpacePlanTool = {

  TYPE: CW_SPACE_SUBSCRIPTION_TYPE_MODEL,

  getBy: (Params: CwSpacePlanToolGetByModel) => {
    if (Params && Params.PlanList) {
      let _Return = undefined;
      const _PlanList = [];
      if (
        Params.id_collaborate_package &&
        Params.interval &&
        Params.type &&
        true
      ) {
        _PlanList.push(...Params.PlanList.filter(Filter =>
          Filter.id_collaborate_package === Params.id_collaborate_package &&
          Filter.interval === Params.interval &&
          Filter.type === Params.type &&
          true
        ));
      } else if (
        Params.id_collaborate_package &&
        Params.interval === undefined &&
        Params.type === undefined &&
        true
      ) {
        _PlanList.push(...Params.PlanList.filter(Filter =>
          Filter.id_collaborate_package === Params.id_collaborate_package &&
          true
        ));
      } else if (
        Params.id_collaborate_package &&
        Params.interval === undefined &&
        Params.type &&
        true
      ) {
        _PlanList.push(...Params.PlanList.filter(Filter =>
          Filter.id_collaborate_package === Params.id_collaborate_package &&
          Filter.type === Params.type &&
          true
        ));
      }
      if (_PlanList && _PlanList[0]) {
        if (Params.returnAll) {
          _Return = [..._PlanList]
        } else {
          _Return = _PlanList[0];
        }
      }
      return _Return;
    }
  },

  definePlan: (Params: CwSpacePlanToolDefinePlanModel) => {
    const Source = CwSpacePlanTool.getBy(Params);
    if (
      Params &&
      Params.Target &&
      Source &&
      true
    ) {
      if (
        Source.id_Plan &&
        true
      ) {
        Params.Target.planId = Source.id_Plan;
        Params.Target.id_Plan = Source.id_Plan;
      }
      if (
        Source.amount &&
        true
      ) {
        Params.Target.amount = Source.amount;
      }
    }
  },

  // 2004150000-12
  selectChanged: (Params: {
    PlanChanged: CwSpacePlanModel,
    SelectedList: CwSpacePlanModel[],
    PlanList: any[]
  }) => {
    if (
      Params &&
      Params.PlanChanged &&
      true
    ) {
      CwUtil.remove({
        List: Params.SelectedList,
        condition: ((Item) => {
          return (
            Item.id_collaborate_package === Params.PlanChanged.id_collaborate_package
          );
        })
      })
      if (Params.PlanChanged.quantity > 0) {
        CwSpacePlanTool.definePlan(<CwSpacePlanToolDefinePlanModel>{
            ...Params.PlanChanged,
            PlanList: Params.PlanList,
            Target: Params.PlanChanged,
          }
        )
        Params.SelectedList.push(Params.PlanChanged);
      }
    }
  }
}
