<app-cw-row class="d-block d-md-none d-lg-none d-xl-block">

  <div R>
    <app-rates-call-group
      [CallGroup]="CwInfoPhoneRatesBus.getItemListAmericaTop"
      nameGroup="NA"
      showAllVisible="true"
    ></app-rates-call-group>
  </div>

  <div R>
    <app-rates-call-group
      [CallGroup]="CwInfoPhoneRatesBus.getItemListEuropeTop"
      nameGroup="EU"
      showAllVisible="true"
    ></app-rates-call-group>
  </div>

  <div R>
    <app-rates-call-group
      [CallGroup]="CwInfoPhoneRatesBus.getItemListAfricaTop"
      nameGroup="AF"
      showAllVisible="true"
    ></app-rates-call-group>
  </div>

  <div R>
    <app-rates-call-group
      [CallGroup]="CwInfoPhoneRatesBus.getItemListAsiaTop"
      nameGroup="AS"
      showAllVisible="true"
    ></app-rates-call-group>
  </div>

</app-cw-row>

<app-cw-row class="d-none d-md-block d-lg-block d-xl-none">

  <div R>
    <app-rates-call-group
      [CallGroup]="CwInfoPhoneRatesBus.getItemListAmericaTop"
      nameGroup="NA"
      showAllVisible="true"
    ></app-rates-call-group>
  </div>

  <div R>
    <app-rates-call-group
      [CallGroup]="CwInfoPhoneRatesBus.getItemListEuropeTop"
      nameGroup="EU"
      showAllVisible="true"
    ></app-rates-call-group>
  </div>

</app-cw-row>

<app-cw-row class="d-none d-md-block d-lg-block d-xl-none">

  <div R>
    <app-rates-call-group
      [CallGroup]="CwInfoPhoneRatesBus.getItemListAfricaTop"
      nameGroup="AF"
      showAllVisible="true"
    ></app-rates-call-group>
  </div>

  <div R>
    <app-rates-call-group
      [CallGroup]="CwInfoPhoneRatesBus.getItemListAsiaTop"
      nameGroup="AS"
      showAllVisible="true"
    ></app-rates-call-group>
  </div>

</app-cw-row>


<div class="d-flex pt-4 h5" translate>Rate may vary...</div>
