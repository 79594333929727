import {Component} from '@angular/core';
import {CwWeb} from '../../tool/web/tool-web';

@Component({
  selector: 'app-cw-loader',
  templateUrl: './loader.component.html',
  styles: []
})
export class LoaderComponent {

  get display(): boolean {
    return (CwWeb && CwWeb.loaderVisible);
  }

}
