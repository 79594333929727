import {ActivatedRoute, Params, Router} from '@angular/router';
import {CoAppSubscribe} from '../app/subscribe';
import {CoTools} from './tools';
import {CoGetParamSuccessCallBackFunction} from '../model/model';

/**
 * @version R1.0 - Portal
 */
export class CoToolsWebClass {
  private _Router: Router;
  private _ActivatedRoute: ActivatedRoute;

  /*equal that cs.css root: --cs-mobile-width-max*/
  MOBILE_WIDTH_MAX = 1080;

  get getIsMobile(): boolean {
    return innerWidth < this.MOBILE_WIDTH_MAX;
  }

  get getIsDesktop(): boolean {
    return innerWidth >= this.MOBILE_WIDTH_MAX;
  }


  public init(_Router: Router, _ActivatedRoute: ActivatedRoute): void {
    this._Router = _Router;
    this._ActivatedRoute = _ActivatedRoute;
  }

  goTo(path = ''): void {
    CoWeb._Router.navigate([path])
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        throw new Error('1809270949');
      })
    ;

    // T&& CoAppSubscribe.unsubscribe(key)ODO: CoAppSessionBus.lastNavigation
    // if (path === APP_AUTH_GUARD_ROUTE_LOGOUT) {
    //   this.exit();
    // } else {
    //   this._router.navigate([
    //     '/' + path
    //   ]);
    // }
  }

  getToken(
    _ActivatedRoute: ActivatedRoute,
    getParamSuccessCallBack: CoGetParamSuccessCallBackFunction = null
  ): any {
    return CoWeb.getParam(_ActivatedRoute, 'token', getParamSuccessCallBack);
  }

  /**
   * @description https://angular.io/guide/router
   * @param _ActivatedRoute
   * @param name
   * @param getParamSuccessCallBack
   */
  getParam(
    _ActivatedRoute: ActivatedRoute,
    name: string,
    getParamSuccessCallBack: CoGetParamSuccessCallBackFunction = null
  ): any {
    CoWeb._ActivatedRoute = _ActivatedRoute;
    const param = CoWeb._ActivatedRoute.snapshot.paramMap.get(name);
    if (param) {
      if (getParamSuccessCallBack) {
        getParamSuccessCallBack(param);
      }
      return param;
    } else {
      // @deprecated
      const key = CoTools.uuid();
      CoAppSubscribe.subscribe(
        key,
        CoWeb._ActivatedRoute.params.subscribe(
          (params: Params) => {
            if (params && params[name]) {
              getParamSuccessCallBack(params[name]);
              CoAppSubscribe.unsubscribe(key);
              return params[name];
            } else {
              CoAppSubscribe.unsubscribe(key);
              return null;
            }
          }
        )
      );
      return null;
    }
  }
}

export const CoWeb = new CoToolsWebClass();
