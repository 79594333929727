import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CwInputComponent} from './cw-input.component';
import {CwModule} from '../../cw.module';
import {FormsModule} from '@angular/forms';
import {CustomFormsModule} from 'ng2-validation';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CwInputNumberPositiveComponent} from '@cw/components/input/number-positive/cw-input-number-positive.component';

@NgModule({
  imports: [
    CommonModule,
    CwModule,
    FormsModule,
    CustomFormsModule,
    NgbTooltipModule
  ],
  declarations: [
    CwInputComponent,
    CwInputNumberPositiveComponent,
  ],
  exports: [
    CwInputComponent,
    CwInputNumberPositiveComponent,
  ]
})
export class CwInputModule {
}
