<ng-container *ngFor="let Item of Logic.Buy.Data.MainList">
  <cw-space-plan-minimal
    [amountFunction]="CwBillingPlans.Purchase.amount"
    [PlanNew]="Item"
    [Setup]="SetupMain"
    (changed)="On.Main.changed(Item)"
  ></cw-space-plan-minimal>
</ng-container>

<ng-container *ngIf="isAvailableExtras">

  <div class="mx-5 my-3">
    <hr>
  </div>
  <div
    class="h3 font-bold mt-4 mb-3"
    translate="Available Extras:"
  ></div>

  <ng-container *ngFor="let Item of Logic.Buy.Data.ExtraList">
    <cw-space-plan-minimal
      xxx="2007000000"
      [amountFunction]="CwBillingPlans.Purchase.amount"
      [PlanNew]="Item"
      [Setup]="SetupExtra"
      (changed)="On.Extra.changed(Item)"
    ></cw-space-plan-minimal>

  </ng-container>
</ng-container>



