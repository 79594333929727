import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {COLLABORATE_PROTOCOL} from '@tool/protocol/model';
import {AppWebTool} from '../app-web.tool';
import {AppCw} from '../app.class';

@Component({
  selector: 'app-dialin',
  templateUrl: './dialin.component.html',
})
export class DialinComponent extends AppCw {


  OnInit(): void {
    super.OnInit();
    AppWebTool.Title.main = 'Meeting Bridge Phone Numbers';
    // 1905101356
    this.App.Literal.LanguageSelected = {
      language: 'en',
      country: 'US',
      rtl: false,
    };
  }


  OnDestroy(): void {
    super.OnDestroy();
    this.App.Head.selectLanguageVisible = true;
  }

  // 1904251312
  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  // 1904251312
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(
      COLLABORATE_PROTOCOL +
      url
    );
  }
}

