export interface CwSpacePlanRouterModel {
  name: string;
  router: string;
}

export interface CwSpacePlanVisibleModel {
  amount?: boolean;
  collapsed?: boolean;
  description?: boolean;
  interval?: boolean;
  quantity?: boolean;
  router?: boolean;
}

export interface CwSpacePlanModel {
  amount: number;
  complete_description?: string[];
  collapse?: boolean; // 2006101345
  currency?: string;
  Date: Date;
  dateFormat: string;
  ExpansionList?: string[];
  id: string;
  id_collaborate_package: string;
  id_Plan?: string;
  interval: string;
  interval_count: number;
  keep?: boolean;
  min?: number;
  minimized?: boolean;
  name: string;
  planId?: string;
  quantity: number;
  // 2007240008
  quantityPrevious?: number;
  // 2004150000-22
  Routers?: CwSpacePlanRouterModel[];
  type: string;
  Visible?: CwSpacePlanVisibleModel;
}

export enum CwSpacePlanStyleModel {
  _INIT,
  MINIMAL,
  MINIMAL_COLLAPSE,
  STANDARD,
  SUMMARY,
  // 2007010000
  ZOOM,
}

/**
 * @version 2004150000-23
 */
export const CW_SPACE_PLAN = {
  STYLE: CwSpacePlanStyleModel,
};
