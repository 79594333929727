import {CwBusClass} from '../../model/bus';
import {CwToolKeepC} from '../../tool/keep/tool-keep';

/**
 * @version 1908191308
 */
export abstract class EntityBus
    extends CwBusClass {
    Keep: CwToolKeepC<any>;
    path = 'entity/';
    Setup: any;
}
