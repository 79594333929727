import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {CwInputI, CwInputType} from './cw-input-model';
import {CwVal} from '@tool/validation/tool-validation';
import {CwWeb} from '../../tool/web/tool-web';
import {CwStripe} from '@tool/stripe/tool';
import {CwUtil} from '@tool/util/cw-tool-util';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-input',
  templateUrl: './cw-input.component.html',
  styleUrls: ['./cw-input.component.scss']
})
/**
 * @version 1908191308
 */
export class CwInputComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() Item: CwInputI;
  @Input() Set: CwInputI[];
  @Input() review: boolean;
  @Output() delete = new EventEmitter();
  @Output() enter = new EventEmitter();
  @Output() error = new EventEmitter();
  @Output() success = new EventEmitter();
  @ViewChild('CardElement') CardElement: any;
  @ViewChild('CardNumberDiv') CardNumberDiv: any;
  @ViewChild('TemplateCardNumberView') TemplateCardNumberView: TemplateRef<any>;
  @ViewChild('TemplateNumberPositive') TemplateNumberPositive: TemplateRef<any>;
  @ViewChild('TemplatePasswordMinimal') TemplatePasswordMinimal: TemplateRef<any>;

  TYPE = CwInputType;
  passwordDisplay = false;
  isMobile = CwWeb.isMobile;

  keyDownEnter = ((Item, Event) => {
    if (Item && Item.enterEnabled) {
      this.enter.emit();
    }
    CwWeb.Event.stop(Event);
  });


  get getClass(): any {
    const _class = {};
    _class['is-invalid'] = !this.Item.valid;
    if (this.Item.class) {
      _class[this.Item.class] = true;
    }
    return _class;
  }

  get getDisabled(): boolean {
    return this.review
      ? true
      : !this.Item.enabled
      ;
  }

  get getPlaceholder(): string {
    return this.Item.placeholder
      ? this.Item.placeholder
      : ''
      ;
  }

  get getLabel(): boolean {
    let _hasLabel = true;
    if (this.Item && this.Item.type === CwInputType.CHECK) {
      _hasLabel = false;
    } else if (this.Item && this.Item.notLabel) {
      _hasLabel = false;
    }
    return _hasLabel;
  }

  Card = {
    isEditable: true,
    is: (): boolean => {
      return (this.Item.type === this.TYPE.CARD);
    },
    defineElement: () => {
      if (this.Card.is()) {
        this.Card.destroy();
        if (this.CardElement) {
          this.Card.isEditable = (!this.Item.CardToken);
          CwStripe.Card.create({
            Input: this.Item,
            NativeElement: this.CardElement.nativeElement,
            successCallback: (() => {
              if (this.Item.ValidationList) {
                CwUtil.clear(this.Item.ValidationList);
              } else {
                this.Item.ValidationList = [];
              }
              this.Item.changed = true;
              this.Item.valid = true;
              CwVal.validate({
                Item: this.Item,
                messageInvalid: '',
                valid: true,
              });
            }),
            errorCallback: ((messageInvalid: string) => {
              if (this.Item.ValidationList) {
                CwUtil.clear(this.Item.ValidationList);
              } else {
                this.Item.ValidationList = [];
              }
              CwVal.validate({
                Item: this.Item,
                messageInvalid,
                valid: false,
              });
            })
          });
        } else {
          setTimeout(
            this.Card.defineElement,
            500
          );
        }
      }
    },
    destroy: () => {
      if (this.Card.is()) {
        CwStripe.Card.destroy();
      }
    },
    remove: () => {
      if (this.Card.is()) {
        this.Item.CardToken = null;
        this.Card.isEditable = true;
        this.Card.defineElement();
      }
    }
  };

  // 2004150000-24
  CardElements = {

    define: () => {
      if (this.CardElements.is()) {
        this.CardElements.destroy();
        if (this.CardNumberDiv) {
          CwStripe.Element.create({
            DomElement: this.CardNumberDiv.nativeElement,
            Input: this.Item,
            InputSet: this.Set, // 2005270000-1
            successCallback: (token) => {
              this.success.emit(token);
            },
            errorCallback: (message) => {
              this.error.emit(message);
            },
          });
        } else {
          setTimeout(
            this.CardElements.define,
            500
          );
        }
      }
    },
    destroy: () => {
    },
    is: (): boolean => {
      return this.getCardElement;
    },
    On: {
      Delete: {
        clicked: () => {
          this.delete.emit();
        }
      }
    }
  };

  ngAfterViewInit(): void {
    this.Card.defineElement();
    this.CardElements.define();
  }

  ngOnDestroy(): void {
    this.Card.destroy();
  }

  ngOnInit() {
    if (this.Item) {
      this.Item.valueOriginal = this.Item.value;
      this.Item.reset = (() => {
        this.Item.value = this.Item.valueOriginal;
        this.Item.changed = false;
      });

    }
  }

  settingChange(Item: CwInputI): void {
    if (Item && Item.Validate && Item.Validate.passive) {
    } else {
      this.settingChangeActive(Item);
    }
  }

  settingChangeActive(Item: CwInputI): void {
    if (
      Item &&
      CwVal.check(this.Item) &&
      Item.value !== Item.valueOriginal &&
      this.Item &&
      true
    ) {
      this.Item.changed = true;
      if (
        this.Item.onChange &&
        true
      ) {
        this.Item.onChange(Item);
      }
      if (Item.ValidationRelationList) {
        Item.ValidationRelationList.forEach(
          Validation => {
            if (Validation) {
              Validation();
            }
          }
        );
      }
    }
  }

  maxLength(Item: CwInputI): number {
    return (
      Item &&
      Item.maxlength &&
      true
    )
      ? Item.maxlength
      : (Item.maxLength)
        ? Item.maxLength
        : 1000
      ;
  }

  minLength(Item: CwInputI): number {
    return (
      Item &&
      Item.minlength &&
      true
    )
      ? Item.minlength
      : (Item.minLength)
        ? Item.minLength
        : 1000
      ;
  }

  get getCardElement(): boolean {
    return (
      this.Item.type === this.TYPE.CARD_CVC ||
      this.Item.type === this.TYPE.CARD_EXPIRY ||
      this.Item.type === this.TYPE.CARD_NUMBER ||
      false
    );
  }

  get getCardNumberViewValue(): string {
    return '**** **** **** ' + this.Item.value;
  }

  get getTemplate(): TemplateRef<any> {
    return (this.Item.type === this.TYPE.PASSWORD_MINIMAL)
      ? this.TemplatePasswordMinimal
      : (this.Item.type === this.TYPE.CARD_NUMBER_VIEW)
        ? this.TemplateCardNumberView
        : (this.Item.type === this.TYPE.NUMBER_POSITIVE)
          ? this.TemplateNumberPositive
          : null
      ;
  }
}
