<ng-container [ngTemplateOutlet]="getTheme"></ng-container>

<ng-template #ThemeStandard>
  <div class="statcard text-center">
    <div class="mb-3">
      <div
        [translate]="nameGroup"
        class="h4 px-2 pt-3 font-bold"></div>
    </div>

    <app-rates-call-header></app-rates-call-header>

    <div *ngFor="let CallItem of CallGroup">
      <app-rates-call [Call]="CallItem"></app-rates-call>
    </div>

    <div *ngIf="showAllVisible"
         class="button p-2 py-2 text-center"
    >
      <button appCw
              appCwForm
              appCwText
              appCwTextMax="14"
              (click)="showAll(nameGroup)"
              data-toggle="modal"
              href="#continentModal"
              class="cw-plan-button"
      >{{'Show all' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #ThemeDefault>

  <div class="mx-0 mx-md-2">
    <div translate appTextFit
         class="d-flex font-weight-bold h4 px-2 pt-3"
    >{{nameGroup}}</div>

    <app-rates-call-header></app-rates-call-header>

    <div *ngFor="let CallItem of CallGroup">
      <app-rates-call [Call]="CallItem"></app-rates-call>
    </div>

    <div *ngIf="showAllVisible"
         class="button p-2 py-2 border border-top-0 text-center"
    >
      <button appCw
              appCwForm
              appCwText
              appCwTextMax="14"
              (click)="showAll(nameGroup)"
              data-toggle="modal"
              href="#continentModal"
      >{{'Show all' | translate}}</button>
    </div>
  </div>

</ng-template>

<ng-container *ngIf="ModalContext">
  <app-rates-show-all-modal
    [CallGroup]="ModalContext.CallGroup"
    [nameGroup]="ModalContext.nameGroup"
  ></app-rates-show-all-modal>
</ng-container>
