import {EntityBus} from '../entity-bus';
import {CwDefineUrl, CwFunction, CwMap} from '@cw/model/model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwRestRequestMinParametersI} from '@tool/rest/model';

class EntityGetConfig extends EntityBus {

  ItemsKey = 'nombre';

  ParametersMap: CwMap<any> = new Map();

  DefineProperties: CwFunction[] = [
    // Parameters Default
    ((Properties) => {
      if (
        Properties &&
        Properties.parametersDefault &&
        true
      ) {
        this.ParametersMap.clear();
        Properties.parametersDefault.forEach(Item => {
          if (
            Item &&
            Item[this.ItemsKey] &&
            true
          ) {
            const Parameter = this.ParametersMap.get(Item[this.ItemsKey]);
            if (Parameter) {
              CwUtil.setProperties(Item, Parameter);
            } else {
              this.ParametersMap.set(Item[this.ItemsKey], Item);
            }
          }
        })
      }
    }),
    // Parameters Entity
    ((Properties) => {
      if (
        Properties &&
        Properties.parametersEntity &&
        true
      ) {
        Properties.parametersEntity.forEach(Item => {
          if (
            Item &&
            Item[this.ItemsKey] &&
            true
          ) {
            const Parameter = this.ParametersMap.get(Item[this.ItemsKey]);
            if (Parameter) {
              CwUtil.setProperties(Item, Parameter);
            } else {
              this.ParametersMap.set(Item[this.ItemsKey], Item);
            }
          }
        })
      }
    })
  ];

  defineUrl: CwDefineUrl = (() => this.path + 'getConfig');

  do: CwFunction = ((Param: CwRestRequestMinParametersI) => {
    // // 2005210000
    // this.restGet({
    //   ...Param,
    //   Bus: this,
    //   token: CwTrust.token,
    // });
  });

}

export const CwConfigBus = new EntityGetConfig();
