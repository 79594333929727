<app-cw-row class="d-block d-md-none d-lg-none d-xl-block">

  <div R
       *ngFor="let PlanItem of PlanList"
  >
    <ng-container *ngTemplateOutlet="PlanTemplate;context:{Plan:PlanItem}"></ng-container>
  </div>

</app-cw-row>

<app-cw-row class="d-none d-md-block d-lg-block d-xl-none">

  <div R
       *ngFor="let PlanItem of PlanList|slice:0:2"
  >
    <ng-container *ngTemplateOutlet="PlanTemplate;context:{Plan:PlanItem}"></ng-container>
  </div>

</app-cw-row>

<app-cw-row class="d-none d-md-block d-lg-block d-xl-none">

  <div R
       *ngFor="let PlanItem of PlanList|slice:2:4"
  >
    <ng-container *ngTemplateOutlet="PlanTemplate;context:{Plan:PlanItem}"></ng-container>
  </div>

</app-cw-row>


<app-cw-row>

  <div R4 class="p-3 p-lg-5 mx-lg-5 bg-light">

    <p class="font-medium font-bold font-spacing-2"
       translate="Persistent space"
    ></p>

    <p class="text-justify" translate="Persistent space...">
    </p>

    <div class="d-flex flex-row justify-content-end">
      <button routerLink="/download"
              type="button"
              class="btn btn-important font-bold"
              translate="Download Now"
              appTextFit
      >
      </button>
    </div>

  </div>

</app-cw-row>


<ng-template #PlanTemplate let-Plan="Plan">

  <div class="cw-highlight p-3 mx-0 mx-xl-2">
    <div class="text-center">
      <img [src]="Plan.src">
    </div>
    <h3 class="font-bold text-uppercase text-center py-2 py-lg-4" [translate]="Plan.titleLiteral"></h3>
    <p class="p  text-center" [translate]="Plan.descriptionLiteral"></p>
  </div>

</ng-template>

