<div class="row">
  <ng-container *ngFor="let Plan of AppPlansTool.View.Plans">
    <div
      class="app-view-standard"
    >
      <cw-space-plan-zoom
        [amountFunction]="CwBillingPlans.Purchase.amount"
        [perHost]="Plan.perHost"
        [PlanNew]="Plan"
      ></cw-space-plan-zoom>
    </div>
  </ng-container>
</div>
