import {CwModalOpenI, CwModalSetupI} from './model';
import {CwClass} from '../component';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

/**
 * @version 1907230828
 */
class ModalClass extends CwClass {

  Setup: CwModalSetupI;

  open(Param: CwModalOpenI): void {
    if (
      Param &&
      Param.ModalComponent &&
      true
    ) {
      const options: NgbModalOptions = {
        backdrop: 'static',
        centered: true,
      };

      if (Param.large) {
        options.size = 'lg';
      }

      const NewModal = this.Setup.NgbModal.open(Param.ModalComponent, options);

      if (Param.Data) {
        NewModal.componentInstance.ModalComponent.Data = Param.Data;
      }
      if (Param.GridComponent) {
        NewModal.componentInstance.ModalComponent.GridComponent = Param.GridComponent;
      }
      if (Param.ExtraParams) {
        NewModal.componentInstance.ModalComponent.ExtraParams = Param.ExtraParams;
      }
      if (Param.callback) {
        NewModal.componentInstance.ModalComponent.callback = Param.callback;
      }

      if (Param.closeAction) {
        NewModal.componentInstance.ModalComponent.closeAction = Param.closeAction;
      }

      if (Param.messageSuccess) {
        NewModal.componentInstance.ModalComponent.Success.message = Param.messageSuccess;
      }

      if (Param.messageError) {
        NewModal.componentInstance.ModalComponent.Error.message = Param.messageError;
      }

      if (Param.title) {
        NewModal.componentInstance.ModalComponent.title = Param.title;
      }

      // Blur button that triggered the modal
      if (Param.Event) {
        if (Param.Event.target.localName === 'span') {
          Param.Event.target.parentElement.blur();
        } else if (Param.Event.target.localName === 'button') {
          Param.Event.target.blur();
        }
      }

      // 1912131221
      if (Param.SetupGrid) {
        NewModal.componentInstance.ModalComponent.SetupGrid = Param.SetupGrid;
      }
    }
  }

  // close(): void {
  //   this.Setup.NgbActiveModal.dismiss();
  // }

}

export const CwModal = new ModalClass();

