<ng-container [ngTemplateOutlet]="getTemplateOutlet"></ng-container>


<!-- TemplateMainDefault ------------------------------------------------------->
<ng-template #TemplateMainDefault>
  <div class="statcard text-xs-center">
    <ng-container *ngIf="!PlanNew?.Visible?.collapsed">
      <div class="hr-divider mb-3">
        <div
          [translate]="PlanNew?.name"
          class="hr-divider-content hr-divider-heading text-secondary mb-0 h5 font-bold"
        ></div>
      </div>
    </ng-container>

    <ng-container *ngIf="PlanNew.Visible.collapsed">
      <div class="hr-divider mb-3">
        <h5
          class="hr-divider-content hr-divider-heading text-primary mb-0"
          role="button"
          (click)="On.TemplateMainStandard.clicked( PlanNew)"
        >
          <small
            class="delta-indicator"
            [ngClass]="{'delta-negative': !PlanNew.Visible.description, 'delta-positive': PlanNew.Visible.description}"
          ></small>
          {{PlanNew?.name | translate}}
          <small
            class="delta-indicator"
            [ngClass]="{'delta-negative': !PlanNew.Visible.description, 'delta-positive': PlanNew.Visible.description}"
          ></small>
        </h5>
      </div>
    </ng-container>

    <ng-container *ngIf="PlanNew.Visible.description">
      <ng-container *ngIf="PlanNew.Visible.amount">
        <div
          class="statcard-number h4 mb-3"
        >
          <ng-container
            *ngIf="PlanNew.amount > 0"
          >
            {{ getTotal | cwCurrency:CwToolCurrency.getSelectedCode}}<span class="h6">
            <small class="delta-indicator p-0 m-0"
            ><span class="icon icon-calendar mx-1"></span>{{PlanNew.interval | translate}}
            </small></span>
          </ng-container>
          <ng-container *ngIf="PlanNew.amount === 0">
            {{ 'Free' | translate }}
          </ng-container>
          <ng-container *ngIf="PlanNew.amount<0">
            {{ 'Custom...' | translate }}
          </ng-container>

        </div>
      </ng-container>
      <ng-container *ngIf="PlanNew.Visible && (PlanNew.Visible.interval || PlanNew.Visible.quantity)">
        <div class="row">
          <ng-container *ngIf="PlanNew.Visible && PlanNew.Visible.interval">
            <div class="col">
              <div
                class="mb-3"
                [ngClass]="{'invisible':(PlanNew.amount <= 0)}"
              >
                <div class="cw-plan-data pl-0">
                  <cw-subscription-interval
                    [default]="PlanNew.interval"
                    (change)="intervalChange($event)"
                  ></cw-subscription-interval>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="PlanNew.Visible && PlanNew.Visible.quantity">
            <div class="col">
              <ng-container
                *ngTemplateOutlet="QuantityTemplate;context:{editable:true}"
              ></ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="PlanNew.Visible && PlanNew.Visible.router">
        <ng-container *ngIf="PlanNew.Routers">
          <div class="mb-3 row justify-content-center">
            <ng-container *ngFor="let Router of PlanNew.Routers">
              <button
                type="button"
                class="btn btn-sm btn-primary col-5 mx-1 text-truncate btn-gradient-primary"
                [routerLink]="Router.router"
                [translate]="Router.name"
              ></button>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="PlanNew.complete_description">
        <cw-space-plan-description
          [DescriptionList]="PlanNew.complete_description"
        ></cw-space-plan-description>
      </ng-container>

    </ng-container>

  </div> <!--statcard-->
</ng-template>


<!-- TemplateMainMinimal ------------------------------------------------------->
<ng-template #TemplateMainMinimal>
  <cw-space-plan-minimal
    [amountFunction]="amountFunction"
    [PlanNew]="PlanNew"
    [Setup]="Setup"
    (changed)="changed.emit($event)"
  ></cw-space-plan-minimal>
</ng-template>


<!-- TemplateMainStandard ------------------------------------------------------>
<ng-template #TemplateMainStandard>
  <div class="statcard text-xs-center">
    <ng-container *ngIf="!PlanNew?.Visible?.collapsed">
      <div class="mb-3">
        <div
          class="h4 px-2 pt-3 font-bold"
        >{{PlanNew?.name}}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="PlanNew.Visible.collapsed">
      <div class="mb-3">
        <div
          class="h4 px-2 font-bold"
          role="button"
          (click)="On.TemplateMainStandard.clicked(PlanNew)"
        >
          <small
            class="delta-indicator"
            [ngClass]="{'delta-negative': !PlanNew.Visible.description, 'delta-positive': PlanNew.Visible.description}"
          ></small>
          {{PlanNew?.name | translate}}
          <small
            class="delta-indicator"
            [ngClass]="{'delta-negative': !PlanNew.Visible.description, 'delta-positive': PlanNew.Visible.description}"
          ></small>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="PlanNew.Visible.description">
      <ng-container *ngIf="PlanNew.Visible.amount">
        <div
          class="statcard-number h4 mb-3"
        >
          <ng-container
            *ngIf="PlanNew.amount > 0"
          >
            {{ getTotal | cwCurrency:CwToolCurrency.getSelectedCode}}<span class="h6">
            <small class="delta-indicator p-0 m-0"
            ><span class="icon icon-calendar mx-1"></span>{{PlanNew.interval | translate}}
            </small></span>
          </ng-container>
          <ng-container *ngIf="PlanNew.amount === 0">
            {{ 'Free' | translate }}
          </ng-container>
          <ng-container *ngIf="PlanNew.amount<0">
            {{ 'Custom...' | translate }}
          </ng-container>

        </div>
      </ng-container>
      <ng-container *ngIf="PlanNew.Visible && (PlanNew.Visible.interval || PlanNew.Visible.quantity)">
        <div class="row">
          <ng-container *ngIf="PlanNew.Visible && PlanNew.Visible.interval">
            <div class="col">
              <div
                class="mb-3"
                [ngClass]="{'invisible':(PlanNew.amount <= 0)}"
              >
                <div class="cw-plan-data">
                  <cw-subscription-interval
                    [default]="PlanNew.interval"
                    (change)="intervalChange($event)"
                  ></cw-subscription-interval>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="PlanNew.Visible && PlanNew.Visible.quantity">
            <div class="col">
              <ng-container
                *ngTemplateOutlet="QuantityTemplate;context:{editable:true}"
              ></ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <cw-space-plan-button [PlanNew]="PlanNew"></cw-space-plan-button>

      <ng-container *ngIf="PlanNew.complete_description">
        <cw-space-plan-description
          [DescriptionList]="PlanNew.complete_description"
        ></cw-space-plan-description>
      </ng-container>

    </ng-container>

  </div> <!--statcard-->
</ng-template>


<!-- TemplateMainSummary ------------------------------------------------------->
<ng-template #TemplateMainSummary>
  <ng-container *ngIf="PlanNew">
    <div class="d-flex">
      <div
        class="flex-fill text-left text-primary font-bold"
        [translate]="PlanNew.name"
      ></div>
      <div class="flex-fill font-bold text-right">
        {{ getTotal | cwCurrency:CwToolCurrency.getSelectedCode}}
      </div>
    </div>
    <div class="d-flex">
      <div
        class="flex-fill text-left small"
      >
        {{getAmount | cwCurrency:CwToolCurrency.getSelectedCode}}
        <span class="icon icon-cross text-primary"></span>
        {{PlanNew.quantity}}
      </div>
      <div
        class="flex-fill text-right small"
        [translate]="PlanNew.interval"
      ></div>
    </div>
  </ng-container>
</ng-template>


<ng-template #HeadTemplate let-title="title">
  <div class="cw-plan-head" [translate]="title"></div>
</ng-template>

<ng-template #NameTemplate let-name="name">
  <div class="cw-plan-label" translate="Name:"></div>
  <div class="cw-plan-data" [translate]="name"></div>
</ng-template>

<ng-template #IntervalTemplate let-editable="editable">
  <ng-container *ngIf="PlanNew">
    <div class="cw-plan-label" translate="Interval:"></div>
    <ng-container *ngIf="editable">
      <div class="cw-plan-data">
        <cw-subscription-interval
          [default]="PlanNew.interval"
          (change)="intervalChange($event)"
        ></cw-subscription-interval>
      </div>
    </ng-container>
    <ng-container *ngIf="!editable">
      <div class="cw-plan-data" [translate]="Plan.interval"></div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #QuantityTemplate let-editable="editable">
  <div class="form-group row">
    <label
      class="col col-form-label text-right"
      translate="Quantity:"
    ></label>
    <div class="col-4">
      <cw-input
        [Item]="QuantityInput"
        (change)="quantityChange()"
      >
      </cw-input>
    </div>
  </div>
</ng-template>

<ng-template #DateTemplate let-editable="editable">
  <ng-container *ngIf="PlanNew">
    <div class="cw-plan-label" translate="End at:"></div>
    <ng-container *ngIf="editable">
      <div
        class="cw-plan-data"
      >{{PlanNew.Date | cwDateTimeZone |date:PlanNew.dateFormat}}
      </div>
    </ng-container>
    <ng-container *ngIf="!editable">
      <div
        class="cw-plan-data"
      >{{Plan.Date | cwDateTimeZone |date:Plan.dateFormat}}
      </div>
    </ng-container>
  </ng-container>
</ng-template>


