import {Component} from '@angular/core';
import {PlansComponentClass} from '../../plans.class';

@Component({
  selector: 'app-plans-buy-done',
  templateUrl: './plans-buy-done.component.html',
  styleUrls: ['./plans-buy-done.component.css']
})
export class PlansBuyDoneComponent extends PlansComponentClass {

  get getAvailable(): boolean {
    return true;
  }

  get getMessage(): string {
    return this.getSuccess
      ? this.AppPlansTool.Buy.Done.success
      : this.AppPlansTool.Buy.Done.error
      ;
  }

  get getSuccess(): boolean {
    return !!this.AppPlansTool.Buy.Done.success;
  }


}
