/**
 * @version 1910311256
 */
import {CW_FILE_TOOL, CwFileToolCsvExportListModel, CwFileToolCsvExportModel} from './file.model';
import {saveAs as SaveAs} from 'file-saver';
import {CwLiteral} from '@cw/literal/literal';
import {isArray, isString} from 'util';

class FileTool {

  ZIP_DATE_FORMAT = 'YYYYMMDD-HHmmss';

  Csv = {
    DATE_FORMAT: 'YYYYMMDDHHmm',
    SEPARATOR: {
      COLUMN: ', ',
      ROW: '\n',
    },
    export: ((Params: CwFileToolCsvExportModel) => {

      if (Params && Params.filename) {
        if (Params.Items) {
          this.Csv.exportList({
            ...<CwFileToolCsvExportListModel>Params,
          });
        } else if (Params.selection) {
          this.Csv.exportTableSelection(Params.filename);
        } else {
          this.Csv.exportTable(Params.filename);
        }
      }
    }),

    _exportListError: ((Params: CwFileToolCsvExportListModel) => {
      if (!Params) {
        throw new Error('1910311619');
      }
      if (Params && !Params.filename) {
        throw new TypeError('1910311620');
      }
      if (Params && !Params.Items) {
        throw new Error('1910311621');
      }
      if (
        Params &&
        Params.Items &&
        (
          !Params.Items.DataList ||
          !Params.Items.FieldList ||
          !Params.Items.HeaderList ||
          false
        ) &&
        true
      ) {
        throw new Error('1910311623');
      }
      if (
        Params &&
        Params.Items &&
        Params.Items.DataList &&
        Params.Items.FieldList &&
        Params.Items.HeaderList &&
        (
          Params.Items.FieldList.length !== Params.Items.HeaderList.length ||
          0 === Params.Items.FieldList.length ||
          false
        ) &&
        true
      ) {
        // // 2009290000
        // throw new Error('1910311626')
      }
    }),

    exportList: ((Params: CwFileToolCsvExportListModel) => {
      this.Csv._exportListError(Params);

      if (CwLiteral.isRtl) {
        Params.Items.HeaderList.reverse();
        Params.Items.FieldList.reverse();
      }

      const RowsList = [];
      // Headline
      RowsList.push(Params.Items.HeaderList.join(this.Csv.SEPARATOR.COLUMN));
      // Rows
      Params.Items.DataList.forEach(DataItem => {
        const _RowList = [];
        Params.Items.FieldList.forEach(fieldItem => {
          // 2009290000.1 //////////////////////////////////////////////////////
          if (
            isArray(DataItem[fieldItem]) &&
            DataItem[fieldItem][0] &&
            isString(DataItem[fieldItem][0]) &&
            true
          ) {
            DataItem[fieldItem].forEach(AuxItem => {
              AuxItem = this.Csv.defineField(AuxItem);
            });
            _RowList.push(DataItem[fieldItem].join(CW_FILE_TOOL.SEPARATOR_LIST));
          } else {
            _RowList.push(this.Csv.defineField(DataItem[fieldItem]));
          }
          //////////////////////////////////////////////////////////////////////
        });
        RowsList.push(_RowList.join(this.Csv.SEPARATOR.COLUMN));
      });
      // Download CSV file
      this.downloadCSV(RowsList.join(this.Csv.SEPARATOR.ROW), Params.filename);

    }),

    defineField: (Value): string => {
      try {
        let _data: string = <string>Value;
        try {
          if (_data && _data.indexOf && _data.indexOf(',') > -1) {
            if (_data.indexOf('"')) {
              _data = _data.replace('"', '""');
            }
            _data = '"' + _data + '"';
          }
        } catch (e) {
          console.log('2010080930', e);
        }
        return _data;
      } catch (e) {
        console.error('2010080928', e);
      }
    },

    exportTableSelection: (filename) => {
      const csv = [];
      const rows = document.querySelectorAll<HTMLElement>('table tr');
      for (let i = 0; i < rows.length; i++) {
        const row = [];
        const ColumnList = rows[i].querySelectorAll<HTMLElement>('td, th');
        if (
          ColumnList &&
          ColumnList.length > 0 &&
          true
        ) {
          let selected = false;
          for (let j = 0; j < ColumnList.length; j++) {
            if ((
                ColumnList[j] &&
                'TH' === ColumnList[j].tagName &&
                true
              ) ||
              (
                ColumnList[j] &&
                ColumnList[j].firstElementChild &&
                ColumnList[j].firstElementChild['checked'] &&
                true
              ) ||
              false
            ) {
              selected = true;
            }
            row.push(ColumnList[j].innerText);
          }
          if (selected) {
            csv.push(row.join(','));

          }
        }
      }

      // Download CSV file
      this.downloadCSV(csv.join('\n'), filename);

    },

    exportTable: (
      (filename) => {
        const csv = [];
        const rows = document.querySelectorAll<HTMLElement>('table tr');
        for (let i = 0; i < rows.length; i++) {
          const row = [];
          const cols = rows[i].querySelectorAll<HTMLElement>('td, th');
          for (let j = 0; j < cols.length; j++) {
            row.push(cols[j].innerText);
          }
          csv.push(row.join(CW_FILE_TOOL.SEPARATOR));
          // csv.push(row.join('\r\n'));
        }

        // Download CSV file
        this.downloadCSV(csv.join('\n'), filename);
      }
    )
  };


  downloadCSV = ((CsvDataHtmlList, filename) => {
    const CsvBlob = new Blob([CsvDataHtmlList], {type: 'text/csv'});
    if (!CsvBlob) {
      throw new Error('Not file - 1911061057');
    }
    if (
      navigator &&
      navigator.msSaveOrOpenBlob &&
      true
    ) {
      // To IE
      navigator.msSaveOrOpenBlob(CsvBlob, filename);
    } else {
      const downloadLink = document.createElement('a');

      // File name
      downloadLink.download = filename;

      // Create a link to the file
      downloadLink.href = window.URL.createObjectURL(CsvBlob);

      // Hide download link
      downloadLink.style.display = 'none';

      // Add the link to DOM
      document.body.appendChild(downloadLink);

      // Click download link
      if (!downloadLink.click) {
        throw new Error('Export File does not create - 1911061056');
      }
      downloadLink.click();
      downloadLink.remove();
    }

  });

  downloadZIP = ((url, filename) => {
    SaveAs(url, filename);
  });

}

/**
 * @version 1910311256
 */
export const CwFileTool = new FileTool();
