import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {RatesComponent} from '../rates.component';
import {APP_THEME} from '../../app-model';
import {CwWeb} from '@tool/web/tool-web';
import {CwInfoPhoneRatesBus} from '@cw/info/phone-rates/phone-rates-bus';

@Component({
  selector: 'app-rates-call-group',
  templateUrl: './rates-call-group.component.html',
  styleUrls: ['./rates-call-group.component.css']
})
export class RatesCallGroupComponent extends RatesComponent {
  @Input() CallGroup: any;
  @Input() nameGroup: string;
  @Input() showAllVisible: boolean;
  @ViewChild('ThemeDefault') ThemeDefault: TemplateRef<any>;
  @ViewChild('ThemeStandard') ThemeStandard: TemplateRef<any>;

  get getTheme(): TemplateRef<any> {
    return this.App.theme === APP_THEME.STANDARD
      ? this.ThemeStandard
      : this.ThemeDefault
      ;
  }

  showAll(continentCode: string) {
    // 1904251312
    if ('NA' === continentCode) {
      this.ModalContext = {
        CallGroup: CwInfoPhoneRatesBus.getItemListAmerica,
        nameGroup: continentCode,
        showAllHidden: true
      }

    } else if ('EU' === continentCode) {
      this.ModalContext = {
        CallGroup: CwInfoPhoneRatesBus.getItemListEurope,
        nameGroup: continentCode,
        showAllHidden: true
      }

    } else if ('AF' === continentCode) {
      this.ModalContext = {
        CallGroup: CwInfoPhoneRatesBus.getItemListAfrica,
        nameGroup: continentCode,
        showAllHidden: true
      }

    } else if ('AS' === continentCode) {
      this.ModalContext = {
        CallGroup: CwInfoPhoneRatesBus.getItemListAsia,
        nameGroup: continentCode,
        showAllHidden: true
      }

    }

    CwWeb.fitty();
  }

}
