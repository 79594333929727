import {CwBusClass} from '../model/bus';
import {CwToolKeepC} from '../tool/keep/tool-keep';

/**
 * @version
 */
export abstract class TokenBusClass extends CwBusClass {
    Keep: CwToolKeepC<any>;
    Setup: any;
    path = 'token/'
}
