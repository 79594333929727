import {CwIs} from '../../CollaborateSpaceWebTools/tool/tool-is';

/**
 * @since 4.0.0.0.R18.0 - ERROR - obfuscated against services
 * @version R60.1 - Dashboard - Usage
 */
export class CwString {

  public static bytesToHuman(_dataIn: any,
                             dataOut: any,
                             _propertyOutName: string,
                             _config: any): boolean {
    let _bytesToHuman = false;
    try {
      if (
        CwIs.number(_dataIn) &&
        CwIs.defined(dataOut) &&
        CwIs.stringNotEmpty(_propertyOutName)
      ) {
        let _unit = 0;
        let _precision = _config.PRECISION;

        while (_dataIn >= _config.SI) {
          _dataIn /= _config.SI;
          _unit++;
        }

        if (_unit === 0) {
          _precision = 0;
        }

        dataOut[_propertyOutName] = _dataIn.toFixed(+_precision)
          + ' ' + _config.UNITS[_unit];
        _bytesToHuman = true;
      }

    } catch (error) {
      _bytesToHuman = false;
    }

    return _bytesToHuman;
  }
}
