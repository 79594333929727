<ng-container
  *ngIf="isIosWithToken"
>
  <app-connection-click-here-or-download></app-connection-click-here-or-download>
</ng-container>

<ng-container
  *ngIf="!isIosWithToken"
>

  <form #formCtrl="ngForm" appCw appCwForm
        *ngIf="!App.Protocol.SelectConnection.flag"
  >

    <app-cw-row>
      <app-cw-form R
                   literal="Personal ID or Meeting ID"
      >
        <input appCw
               appCwFocus
               appCwForm
               name="personalId"
               type="text"
               (keyup.enter)="accept()"
               ngModel
               #personalIdInput="ngModel"
               [(ngModel)]="personalId"
               required
        >
      </app-cw-form>

    </app-cw-row>

    <app-cw-row>
      <app-cw-form R>
        <div class="text-center">

          <button
            appCw
            appCwForm
            class="2102111557"
            id="acceptButton"
            type="button"

            [disabled]="!formCtrl.form.valid"

            (click)="accept()"
          >
            {{'Join' | translate}}
          </button>
        </div>

      </app-cw-form>

    </app-cw-row>

  </form>
</ng-container>
