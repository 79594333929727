import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CwWeb} from '../../CollaborateSpaceWebTools/tool/web/tool-web';
import {CwProtocol, CwProtocolTool} from '../../CollaborateSpaceWebTools/tool/protocol/tool-protocol';
import {CW_PROTOCOL_TOOL, CwProtocolCheckI} from '../../CollaborateSpaceWebTools/tool/protocol/model';
import {ActivatedRoute} from '@angular/router';
import {AppWebTool} from '../../app-web.tool';
import {App} from '../../app';

@Component({
  selector: 'app-check-available',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.css']
})
/**
 * @version 22001201051
 */
export class ConnectionCheckComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('TemplateInvite') TemplateInvite: TemplateRef<any>;
  Template: TemplateRef<any>;
  // 2009210000.D2
  couponExternal;
  On = {
    Start : {
      clicked: () => {
        // 2010200942
        CwProtocol.App.start();
      }
    }
  }
  constructor(private _ActivatedRoute: ActivatedRoute) {
    console.log('2002101131', 'ConnectionCheckComponent', 'constructor');
    CwProtocol.token = CwWeb.getToken({
      ActivatedRoute: _ActivatedRoute
    });
  }

  // GETs //////////////////////////////////////////////////////////////////////
  get getCreateLink(): string {
    return '/plans/pro/trial';
  }

  get getFrom(): string {
    return (App.referredName)? App.referredName : App.referred;
  }

  get getTemplate(): TemplateRef<any> {
    try {
      return (CwProtocolTool.isSourceInvite())
        ? this.TemplateInvite
        : null
        ;
    } catch (e) {
      return null;
    }
  }

  //////////////////////////////////////////////////////////////////////////////


  // NGs ///////////////////////////////////////////////////////////////////////
  ngAfterViewInit(): void {
    if (CwProtocol.Status.isStillOpen()) {
      console.log('2002141110', 'ConnectionAppWindowsComponent', 'isStillOpen');
      // 2002181213
      CwProtocol.Status.close();
      CwWeb.goTo({path: '/download/app/is-still-open'});
    } else {
      CwProtocolTool.action = CW_PROTOCOL_TOOL.ACTION.CHECK;
      // 2010200942
      if (CwProtocolTool.source !== CW_PROTOCOL_TOOL.SOURCE.INVITE) {
        if (!CwProtocolTool.do()) {
          this.check();
        }
      }
    }
  }

  ngOnInit() {
    if (CwProtocolTool.isSourceInvite()) {
      AppWebTool.Title = {
        main: 'COLLABORATE Space',
        secondary: 'Invitation',
      };
    } else {
      AppWebTool.Title = {
        main: 'Launching...',
        secondary: '',
        startHereVisible: false,
      };
    }
  }

  // 2103021025
  ngOnDestroy() {
    CwProtocolTool.source = CW_PROTOCOL_TOOL.SOURCE.DEFAULT;
  }

  //////////////////////////////////////////////////////////////////////////////


  check = () => {
    const appRoute = '/download/app' + CwWeb.Route.is;
    // 2002240902
    if (CwWeb.Browser.InternetExplorer.is) {
      CwProtocol.isAutoDownload = false;
      CwWeb.goTo({path: appRoute});
    } else if (CwProtocol.Browser.Mobile.Chrome.check()) {

    } else {
      const Param: CwProtocolCheckI = {
        notFound: function () {
          if (!CwProtocolTool.isSourceInvite()) {
            if (CwProtocol.token) {
              const _uri = (CwProtocol.SelectConnection.available)
                ? '/download/select'
                : '/download/app' + CwWeb.Route.is
              ;
              CwWeb.goTo({path: _uri});
            } else {
              CwProtocol.isAutoDownload = true;
              CwWeb.goTo({path: appRoute});
            }
          }
        },
        found: function () {
          // 2011060918
          if (!CwProtocolTool.isSourceInvite()) {
            CwProtocol.isAutoDownload = false;
            if (CwProtocol.token) {
              const _uri = (CwProtocol.SelectConnection.available)
                ? '/download/select'
                : '/download/app' + CwWeb.Route.is
              ;
              CwWeb.goTo({path: _uri});
            } else {
              CwWeb.goTo({path: appRoute});
            }
          }

        },
        token: CwProtocol.token,
      };
      CwProtocol.check(Param);
    }
  };


}
