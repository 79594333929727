import {CwBusClass} from '../model/bus';
import {CsFunction} from '../../cs/model/model';
import {CwToolKeepC} from '../tool/keep/tool-keep';
import {CwDefineUrl, CwFunction, CwTokenBusI} from '../model/model';
import {CwFeedI, CwFeedMapItemI, CwFeedSetupI, CwFeedTypeE} from './feed-model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {environment} from '../../../environments/environment';

const INTERVAL_TIME_DEFAULT = 1000;

/**
 * @version 1.13.0 - RPM - Partners - 1902071227
 * @description https://www.primefaces.org/primeng-6.1.6/#/toast
 */
export class FeedBusC extends CwBusClass {
  DefineProperties: CsFunction[];
  Keep: CwToolKeepC<any> = new CwToolKeepC({
    key: environment.Literal.keepKey,
  });
  Setup: CwFeedSetupI = {
    hiddenTime: environment.Feed.hiddenTime,
  };
  SuccessActionList: any[];
  SuccessActionOnceList: any[];
  TokenBus: CwTokenBusI;
  defineUrl: CwDefineUrl;
  do: CwFunction;

  private add(Feed: CwFeedI): void {
    const FeedItem: CwFeedMapItemI = {
      ...Feed,
      id: CwUtil.uuid(),
      timestamp: Date.now(),
      visible: true,
      life: CwFeed.Setup.hiddenTime,
    };
    this.ItemsSet(FeedItem);
    if (
      CwFeed &&
      CwFeed.Setup &&
      CwFeed.Setup.Service &&
      CwFeed.Setup.Service.add &&
      true
    ) {
      CwFeed.Setup.Service.add(FeedItem);
    }
  }

  public error(message: string, title = 'Error Message'): void {
    CwFeed.add(<CwFeedI>{
      detail: message,
      severity: CwFeedTypeE.error,
      summary: title,
    });
  }

  public system(Data: any, title = 'System Error Message'): void {
    if (
      Data &&
      Data.statusText &&
      true
    ) {
      CwFeed.add(<CwFeedI>{
        detail: (Data.statusText === 'OK')
          ? Data.message
          : Data.statusText
        ,
        severity: CwFeedTypeE.warn,
        summary: title,
        Data: Data,
      });
      CwFeed.Keep.Keep = CwFeed.ItemsList.filter(FeedItem => (
        FeedItem &&
        FeedItem.severity === CwFeedTypeE.system &&
        true
      )).reverse().slice(0, 10);
    }
  }

  public info(message: string, title = 'Info Message'): void {
    CwFeed.add(<CwFeedI>{
      detail: message,
      severity: CwFeedTypeE.info,
      summary: title,
    });
  }

  public success(message: string, title = 'Success Message'): void {
    CwFeed.add(<CwFeedI>{
      detail: message,
      severity: CwFeedTypeE.success,
      summary: title,
    });
  }

  setup(_Setup: any) {
    super.setup(_Setup);
    if (
      CwFeed &&
      CwFeed.Setup &&
      CwFeed.Setup.visibleCondition &&
      true
    ) {
      this.Setup.intervalId = setInterval(() => {
          CwFeed.Items.forEach(Item => {
            CwFeed.Setup.visibleCondition(Item);
          });
        }, (this.Setup.intervalTime)
        ? this.Setup.intervalTime
        : INTERVAL_TIME_DEFAULT
      );
    }
  }
}

export const CwFeed = new FeedBusC();
