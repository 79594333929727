import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwToolKeepC} from '@tool/keep/tool-keep';
import {CwValidateVatDoI, CwValidateVatParametersI} from '@rest/billing/validate-vat-id/validate-vat-id-model';
import {BillingBus} from '@rest/billing/billing-bus';

class ValidateVatIdBus extends BillingBus {

  DefineProperties: CwFunction[] = [];
  Keep: CwToolKeepC<any>;
  Setup: any;
  Parameters: CwValidateVatParametersI;

  defineUrl: CwDefineUrl = (() =>
          CwValidateVatIdBus.path +
         'validateVatID?' +
          'type=' +
          CwValidateVatIdBus.Parameters.type +
          '&value=' +
          CwValidateVatIdBus.Parameters.value +
          '&countryCode=' +
          CwValidateVatIdBus.Parameters.countryCode
  );
  do: CwFunction = ((Param: CwValidateVatDoI) => {
    CwValidateVatIdBus.restGet(Param);
  });

}

export const CwValidateVatIdBus = new ValidateVatIdBus();
