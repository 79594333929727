import {CwDataFunction} from '../keep/tool-keep-model';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {DeviceDetectorService} from 'ngx-device-detector';
import {CW_PROTOCOL_TOOL} from '@tool/protocol/model';

export interface CwWebKeepI<T> {
  key: string;
  Data?: T;
  DataSafe?: any;
  defineData?: CwDataFunction;
  DataDefault?: T;
}

export interface CwWebSetupI {
  Router?: Router;
  ActivatedRoute?: ActivatedRoute;
  paramName?: string;
  DeviceDetectorService?: DeviceDetectorService;
  DomSanitizer?: DomSanitizer;
}

export interface CwWebLoadJsI {
  UrlList: string[];
  condition: boolean;
}

export interface CwWebOSI {
  isMac: boolean;
  isWindows: boolean;
  isAndroid: boolean;
  isIOS: boolean;
}

export const CW_WEB_TOOL = {
  BROWSER: {
    CHROME: 'chrome',
    EDGE: 'ms-edge',
    EDGE79: 'ms-edge-chromium',
    FIREFOX: CW_PROTOCOL_TOOL.BROWSER.FIREFOX,
    IE: 'ie',
    SAFARI: 'safari', // 2009290000.B5
  },
  DEVICE: {
    IPAD: 'ipad'
  },
  KEEP : {
    KEY : {
      SPACE_KEEP_ME_LOGGED_IN : 'COLLABORATESpaceKeepLogged',
      SPACE: 'COLLABORATESpace',
      SPACE_TOKEN: 'COLLABORATESpaceToken',
    }
  },
  OS: {
    MAC: 'mac',
    WINDOWS: CW_PROTOCOL_TOOL.DEVICE.WINDOWS,
    IOS: 'ios',
    ANDROID: 'android',
  }
};

export interface CwWebToolGoToModel {
  path: string;

  byUrl?: boolean;
  external?: boolean;
  queryParams?: any;
}
