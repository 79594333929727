export interface CwSpacePlanToolGetByModel {
  PlanList: any[];
  id_collaborate_package?: string,
  interval?: string,
  returnAll?: boolean,
  type?: string,
}

export interface CwSpacePlanToolDefinePlanModel
  extends CwSpacePlanToolGetByModel {
  Target: any;
}


// 2004150000-12
export enum CW_SPACE_PLAN_TOOL_BUY_TYPE {
  _INIT,
  SELECT,
  REGISTRATION,
  // PAYMENT,
  DONE,
}

/**
 * @version 2007170000
 */
export const CW_SPACE_PLAN_TOOL = {
  // TYPE: CW_SPACE_PLAN_TOOL_BUY_TYPE,
  CONTINUE_TEMPLATE_CONTEXT: {
    // screen order
    SELECT: {
      continue: CW_SPACE_PLAN_TOOL_BUY_TYPE.REGISTRATION
    },
    REGISTRATION: {
      back: CW_SPACE_PLAN_TOOL_BUY_TYPE.SELECT,
      continue: CW_SPACE_PLAN_TOOL_BUY_TYPE.DONE,
      // continue: CW_SPACE_PLAN_TOOL_BUY_TYPE.PAYMENT
    },
    // PAYMENT: {
    //   continue: CW_SPACE_PLAN_TOOL_BUY_TYPE.DONE
    //   back: CW_SPACE_PLAN_TOOL_BUY_TYPE.REGISTRATION,
    // },
  }
}
