import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwToolKeepC} from '../../../tool/keep/tool-keep';
import {CwVoIPDoI, CwVoIPPayLoadDefault, CwVoIPPayLoadI} from './model';
import {CwRestRequestMinParametersI} from '../../../tool/rest/model';
import {CwTrust} from '../../../trust/trust';
import {EntityBus} from '../entity-bus';

class EntityModifyVoIpService extends EntityBus {
  Is: CwVoIPPayLoadI = CwVoIPPayLoadDefault;
  DefineProperties: CwFunction[] = [
      // Is.owner
    ((Properties) => {
      this.Is.owner = (
        Properties &&
        Properties.roles &&
        Properties.roles.filter(
          Item =>
            Item &&
            Item.componentName === 'OWNER' &&
            Item.quantity === 1 &&
            true
        ).length === 1 &&
        true
      );
    }),
    // Is.disabledVoIP
    ((Properties) => {
      this.Is.disabledVoIP = Properties.disabledVoIP;
    })
  ];
  Keep: CwToolKeepC<any>;
  Setup: any;
  defineUrl: CwDefineUrl = (() => this.path + 'modifyVoIPService');
  do: CwFunction = ((Params: CwVoIPDoI) => {

    this.restPut({
      ...<CwRestRequestMinParametersI>Params,
      PayLoadCustom: <CwVoIPPayLoadI>Params,
      Bus: this,
      token: CwTrust.token
    })
  });

}

export const CwVoIpBus = new EntityModifyVoIpService();
