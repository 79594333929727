import {EntityBus} from '@rest/entity/entity-bus';
import {CwFunction} from '../../../model/model';
import {CwEntityAccountDoModel} from '@rest/entity/account/enity-account.model';

class EntityAccountBus extends EntityBus {

  DefineProperties: CwFunction[];

  defineUrl = (() => this.path + 'account');

  do = ((Params: CwEntityAccountDoModel) => {
    Params.Parameters = undefined;
    this.restPost(Params);
  });


}

/**
 * @version 2004150000-7
 */
export const CwEntityAccountBus: EntityAccountBus = new EntityAccountBus();
