import {LiteralBusClass} from '../literal-bus';
import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwRestRequestParametersI} from '../../../tool/rest/model';

export class CwStateBusC extends LiteralBusClass {

  ItemsKey = 'country_id';

  DefineProperties: CwFunction[] = [
    ((Properties) => Properties.web = (
        Properties.name &&
        true
      )
        ? Properties.name
        : ''
    )
  ];

  defineUrl: CwDefineUrl = (() => {
    return this.path + 'data/state.json';
  });

  do: CwFunction = ((Parameters: CwRestRequestParametersI) => {
    if (Parameters && !Parameters.Bus) {
      Parameters.Bus = this;
    }
    this.restGet(Parameters);
  })

}

/**
 * @version 2005210000
 */
export const CwStateBus: CwStateBusC = new CwStateBusC();
