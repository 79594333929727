import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Result} from '../common/result';
import {NgForm} from '@angular/forms';
import {AppSettings} from '../common/app-settings';
import {CoWeb} from '../co/tools/web';
import {CoValidationsComponent} from '../co/validations/component';
import {CwUserResetBus} from '../CollaborateSpaceWebTools/user/reset/reset-bus';
import {CwUserResetDoI} from '../CollaborateSpaceWebTools/user/reset/reset-model';
import {App} from '../app';
import {CwWeb} from '../CollaborateSpaceWebTools/tool/web/tool-web';
import {CwProtocol} from '../CollaborateSpaceWebTools/tool/protocol/tool-protocol';
import {tokenIsValid} from '../app-default';
import {CwTokenGet} from '../CollaborateSpaceWebTools/token/get/get-bus';
import {PROTOCOL_ACTION} from '../CollaborateSpaceWebTools/tool/protocol/model';
import {AppWebTool} from '../app-web.tool';

@Component({
  selector: 'app-reset',
  templateUrl: 'reset.component.html',
})
export class ResetComponent extends CoValidationsComponent {

  token: string;
  newpassword: string;
  newPasswordConfirm: string;
  public ConfirmValidationList: any[] = [];

  passwordPattern = AppSettings.PASSWORD_PATTERN;

  @ViewChild('formCtrl') public formCtrl: NgForm;

  result = new Result();

  errorMessage: string;

  constructor(
    private router: Router,
    private _ActivatedRoute: ActivatedRoute,
  ) {
    super();
    tokenIsValid(
      CwTokenGet.ACTION.RECOVER_PASSWORD,
      CwWeb.getToken({ActivatedRoute: _ActivatedRoute}),
    );
  }


  reset() {
    if (this.formCtrl.form.valid) {
      // 1905231102
      CwUserResetBus.do(<CwUserResetDoI>{
        Bus: CwUserResetBus,
        Parameters: {
          token: CoWeb.getToken(this._ActivatedRoute),
          password: this.newpassword,
        },
        errorActionCustom: ((Data) => {
          App.Feedback = {
            ...Data,
            contactAdminVisible: true,
          };
        }),
        errorRestActionCustom: ((Data) => {
          App.Feedback = Data;
        }),
        successActionCustom: ((Data, That) => {
          // 1906250845
          App.Feedback = Data;
          if (
            Data &&
            Data.result &&
            Data.result.token &&
            true
          ) {
            // 1907030844
            App.Protocol.check6({
              action: PROTOCOL_ACTION.SIGN_IN,
              App,
            });
            CwWeb.checkAndSignIn({
              App,
              token: Data.result.token,
              // 1907021609
              admin: false,
              // admin: Data.result.admin,
              KeepToken: App.KeepToken,
              Protocol: CwProtocol,
              userDo: (() => {
                App.Body.visible = false;
              }),
            });
          }
        }),
      })
    }
  }

  OnInit(): void {
    super.OnInit();
    AppWebTool  .Title.main = 'Reset Your Password';
  }


}
