<div id="continentModal" class="modal fade" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="d-flex flex-column px-2 w-100">


          <div class="statcard text-center">
            <div class="mb-3">
              <div
                [translate]="nameGroup"
                class="h4 px-2 pt-3 font-bold"></div>
            </div>

            <app-rates-call-header></app-rates-call-header>

            <div *ngFor="let CallItem of CallGroup">
              <app-rates-call [Call]="CallItem"></app-rates-call>
            </div>


          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button appCw
                appCwForm
                type="button"
                data-dismiss="modal">{{'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
