/**
 * @version 1911261041
 */
import {CW_ERROR_TOOL, CwErrorToolSetModel, CwErrorToolTypeModel} from './error.model';
import {CwWeb} from '../web/web.tool';
import {CwUtil} from '../util/cw-tool-util';

class ErrorTool implements CwErrorToolSetModel {

  code: number;
  message: string;
  literal: string;
  type: CwErrorToolTypeModel;
  title: string;
  class: string;
  signInShow: boolean;

  set = ((Parameters: CwErrorToolSetModel | any) => {
    console.log('1911261041', 'Error', Parameters);
    if (Parameters) {
      this.clear();
      this.defineProperties(Parameters);
      this.logic();
      CwWeb.goTo({path: CW_ERROR_TOOL.GOTO.get(this.type)});
    }
    // CwWeb.goTo({path: 'error'});
  });

  defineProperties = ((Properties) => {
    if (Properties.errorCode) {
      this.code = Properties.errorCode;
    }
    if (Properties.error) {
      this.message = Properties.error;
    }

    CwUtil.setProperties(Properties, this);
    if (this.type) {
      this.title = CW_ERROR_TOOL.TITLE.get(this.type);
      this.class = CW_ERROR_TOOL.CLASS.get(this.type);
      if (this.code) {
        this.literal = CW_ERROR_TOOL.LITERAL.get(this.type) +
          '-' +
          Math.abs(this.code)
        ;
      }
    }
  });

  logic = (() => {
    if (this.code === CW_ERROR_TOOL.CODE.TOKEN_INVALID) {
      CwWeb.Keep.clear();
    } else if (this.code === CW_ERROR_TOOL.CODE.ENTITY_INACTIVED) {
      CwWeb.Keep.clear();
    } else if (this.code === CW_ERROR_TOOL.CODE.AUTH_ACCESS_DENIED) {
      window.close();
    }
  });

  clear = (() => {
    this.code = undefined;
    this.message = undefined;
    this.literal = undefined;
    this.type = CW_ERROR_TOOL.TYPE_DEFAULT;
    this.signInShow = CW_ERROR_TOOL.SIGN_IN_SHOW_DEFAULT;
  });

}

export const CwErrorTool = new ErrorTool();
