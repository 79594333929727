import {Observable, Subject} from 'rxjs';

export class CwToolModel {
  // 2010290000.D2 /////////////////////////////////////////////////////////////
  private Subject$ = new Subject();

  get Tool$(): Observable<any> {
    return this.Subject$.asObservable();
  };

  next = (() => {
    this.Subject$.next();
  });
  //////////////////////////////////////////////////////////////////////////////
}
