import {Component, OnInit} from '@angular/core';
import {CwEntityActivation, CwEntityActivationDoI} from '../CollaborateSpaceWebTools/entity/activation/activation-bus';
import {CwWeb} from '../CollaborateSpaceWebTools/tool/web/tool-web';
import {App} from '../app';
import {ActivatedRoute} from '@angular/router';
import {CwProtocol, CwProtocolTool} from '../CollaborateSpaceWebTools/tool/protocol/tool-protocol';
import {CW_PROTOCOL_TOOL, CwProtocolCheckI, PROTOCOL_ACTION} from '../CollaborateSpaceWebTools/tool/protocol/model';
import {CwFix} from '../CollaborateSpaceWebTools/tool/tool-fix';
import {AppWebTool} from '../app-web.tool';
import {AppCw} from '../app.class';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
})
/**
 * @version 1905281523
 */
export class ActivationComponent extends AppCw implements OnInit {

  result: any;
  errorMessage: string;

  constructor(
    private _ActivatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    AppWebTool.Title = {
      main: 'Activation'
    };

    CwProtocol.token = CwWeb.getToken({
      ActivatedRoute: this._ActivatedRoute
    });
    // 1904251312
    CwEntityActivation.do(<CwEntityActivationDoI>{
      Bus: CwEntityActivation,
      Parameters: {
        tokenUrl: CwProtocol.token
      },
      errorRestActionCustom: ((Data) => {
        App.Feedback = Data;
        // 2008061040
        AppWebTool.Title = {
          main: '',
          secondary: '',
        }
        CwWeb.goTo({path: '/datasignin'});
      }),
      errorActionCustom: ((Data) => {
        App.Feedback = Data;
        // 2008061040
        AppWebTool.Title = {
          main: '',
          secondary: '',
        }
        CwWeb.goTo({path: '/datasignin'});
        // // 2008061040
        // // 2007291445
        // AppWebTool.Title = {
        //   main: Data.error,
        //   secondary: 'Try the operation again ...',
        // }
      }),
      successActionCustom: ((Data, That) => {
        if (CwWeb.Browser.Firefox.is) {
          CwProtocolTool.source = CW_PROTOCOL_TOOL.SOURCE.FREE;
          CwWeb.goTo({path: '/download/check/' + CwProtocol.token});
        } else {
          App.Feedback = Data;
          // 1907030844
          App.Protocol.check6({
            action: PROTOCOL_ACTION.ACTIVATION,
            App,
          });
          // 1907030844
          CwWeb.checkAndSignIn({
            Protocol: CwProtocol,
            admin: CwFix.activationOpenAdmin({browser: CwWeb.getBrowser()}),
            token: Data.result,
            // 1907030844
            App,
          });
          CwProtocol.token = Data.result;
          this.check();

        }
      })
    });
  }

  check() {
    if (CwProtocol.token) {
      const appRoute = 'download/app' + CwWeb.Route.is;
      const Param: CwProtocolCheckI = {
        notFound: function () {
          CwWeb.goTo({
            path:
              (CwProtocol.SelectConnection.available)
                ? 'download/select'
                : appRoute
          });
        },
        found: function () {
          CwProtocol.isAutoDownload = false;
          CwWeb.goTo({path: appRoute});
        },
        token: CwProtocol.token,
      };
      CwProtocol.check(Param);
    }
  }
}
