import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Code} from '../common/code';
import {NgForm} from '@angular/forms';
import {App} from '../app';
import {CwWeb} from '../CollaborateSpaceWebTools/tool/web/tool-web';
import {CwProtocol} from '../CollaborateSpaceWebTools/tool/protocol/tool-protocol';
import {AppWebTool} from '../app-web.tool';

declare var protocolObject: any;

/**
 * @version R2.0 - Host a Meeting and Join a Meeting
 * @version 2002121212
 * @description COLLABORATE© Space did not install and You try Join a Meeting, "Select how to connect" page show.
 * If you select "Web App", "Personal ID" is sent automatically - 2002121212
 */
@Component({
  selector: 'app-meeting-id',
  templateUrl: './meeting-id.component.html',
})
export class MeetingIdComponent implements OnInit, OnDestroy {

  App = App;
  @Input() meetingId;

  code: Code;
  errorMessage: string;

  show: boolean;
  dmeetingId: any;
  personalId: string;

  protocolObj: any;

  isSupported: boolean;

  @ViewChild('formCtrl') public formCtrl: NgForm;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    // 2001101133
    App.Protocol.SelectConnection.flag = false;
    this.dmeetingId = this.route.snapshot.paramMap.get('meetingId');

    CwProtocol.token = CwWeb.getToken({
      ActivatedRoute: route
    });

    if (CwProtocol.token) {
      if (CwWeb.OS.Android.is && CwWeb.Browser.Chrome.is) {
        this.personalId = CwProtocol.token;
        this.show = true;
      } else if (!CwWeb.OS.Ios.is) {
        this.show = false;
        this._goTo(CwProtocol.token);
      }
    } else {
      this.show = true;
    }
  }

  private _goTo(token: string = null): void {
    let _uri = '/download/check';
    if (token) {
      _uri += '/' + token;
    }
    CwWeb.goTo({path: _uri});
  }

  // ISs ///////////////////////////////////////////////////////////////////////
  get isIosWithToken(): boolean {
    return CwWeb.OS.Ios.is && !!CwProtocol.token;
  }

  //////////////////////////////////////////////////////////////////////////////


  // NGs ///////////////////////////////////////////////////////////////////////

  ngOnInit() {
    App.Navigation.home = false;
    App.Navigation.join = true;

    // 1904251312
    AppWebTool.Title = {
      main: 'Join a Meeting',
      secondary: '',
      startHereVisible: false,
    };

  }

  ngOnDestroy(): void {
    App.Navigation.join = false;
  }

  //////////////////////////////////////////////////////////////////////////////


  accept() {
    if (this.formCtrl.form.valid) {
      this._goTo(this.personalId);
    }
  }


}
