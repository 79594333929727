<ng-container *ngIf="getAvailable">

  <div class="row">
    <ng-container
      *ngTemplateOutlet="ElementTitleSection;context:{title:'Billing Contact'}"
    ></ng-container>

    <ng-container *ngFor="let Input of getInputs">
      <div
        class="col-6"
        [ngClass]="Input?.class"
      >
        <cw-input
          [Item]="Input"
          [review]="review"
        ></cw-input>
      </div>
    </ng-container>

    <div class="col-12 col-sm-12 col-md-6 px-3">
      <div class="form-group"
      >
        <label class="font-weight-bold text-dark"
          translate="Country"
        ></label>
        <span class="ml-1 h5 align-middle"
        >*</span>
        <app-cw-select-country
            #countrySelect
          [(ngModel)]="Country"
          (change)="On.Country.changed()"
          ngDefaultControl
          required
        ></app-cw-select-country>
        <cw-input
          [Item]="AppPlansTool.Buy.Registration.InputCountry"
        ></cw-input>
      </div><!--      form-group-->
    </div>

    <div class="col-12 col-sm-12 col-md-6 px-3">
      <div class="form-group">
        <label class="font-weight-bold text-dark"
          translate="State"
        ></label>
        <span class="ml-1 h5 align-middle"
        >*</span>
        <app-cw-select2
          [Setup]="StateSetup"
          [(ngModel)]="State"
          (change)="On.State.changed()"
          required
          ngDefaultControl
        ></app-cw-select2>
        <cw-input
          [Item]="AppPlansTool.Buy.Registration.InputState"
        ></cw-input>
      </div><!--      form-group-->
    </div>


    <ng-container *ngTemplateOutlet="ElementSeparator"></ng-container>

    <ng-container
      *ngTemplateOutlet="ElementTitleSection;context:{title:'VAT Info (optional)'}"
    ></ng-container>


    <div class="col-6">
      <label
        class="font-weight-bold text-dark text-justify"
        translate="VAT Type"
      ></label>
      <select
        class="form-control form-control-sm bg-light"
        (change)="On.VatType.changed()"
        [(ngModel)]="vatTypeSelected">
        <option *ngFor="let Item of CwFiscalIdsBus.ItemsList"
                [ngValue]="Item"
                [selected]="getSelected(Item)">
          {{Item.vatTypeWeb}}
        </option>
      </select>
    </div>

    <div [ngClass]="AppPlansTool.Buy.Registration.Vat.Input.class">
      <cw-input [Item]="AppPlansTool.Buy.Registration.Vat.Input"></cw-input>
    </div>


    <ng-container *ngTemplateOutlet="ElementSeparator"></ng-container>

    <ng-container
      *ngTemplateOutlet="ElementTitleSection;context:{title:'Payment Method'}"
    ></ng-container>

    <div class="col-12">
      <app-plans-buy-payment></app-plans-buy-payment>
    </div>


  </div>

  <cw-space-agree-passive></cw-space-agree-passive>
</ng-container>


<ng-container #ELEMENTS>

  <ng-template #ElementTitleSection let-title="title">
    <div class="col-12 h4 font-bold"
         [translate]="title"
    ></div>
  </ng-template>

  <ng-template #ElementSeparator>
    <div class="col-12 font-bold">
      <hr>
    </div>
  </ng-template>

</ng-container>
