import {Component, OnInit} from '@angular/core';
import {AppSettings} from '../common/app-settings';
import {CoWeb} from '../co/tools/web';
import {CwComponent} from '../CollaborateSpaceWebTools/model/component';
import {CwIs} from '../CollaborateSpaceWebTools/tool/tool-is';
import {App} from '../app';
import {CwEntitySubdomainBus, CwEntitySubdomainDoI} from '../CollaborateSpaceWebTools/entity/subdomain/subdomain-bus';
import {User} from '../common/user';
import {CwRestRequestParametersI} from '../CollaborateSpaceWebTools/tool/rest/model';
import {CwOnP} from '../CollaborateSpaceWebTools/tool/on-premise/tool-on-premise';
import {CwInfoInstallationBus} from '@rest/info/installation/cw-info-installation-bus.class';
import {AppWebTool} from '../app-web.tool';
import {CwProtocolTool} from '@tool/protocol/tool-protocol';
import {CW_PROTOCOL_TOOL} from '@tool/protocol/model';


@Component({
  selector: 'app-navigation',
  styleUrls: ['navigation.component.scss'],
  templateUrl: 'navigation.component.html',
})
/**
 * @version R3.1 - Update links...
 */
export class NavigationComponent
  extends CwComponent
  implements OnInit {

// 2003041112
  CwOnPremise = CwOnP;
  // 1904251312
  App = App;
  AppWebTool = AppWebTool;
  // R5.0 - Mobile design
  CoWeb = CoWeb;
  // 2009210000.D2


  src: string[] = [
    'assets/images/icon-facebook.png',
    'assets/images/icon-twitter.png',
    'assets/images/icon-linkedin.png'
  ];

  // 1904101050
  // navigation: AppNavigationI;
  // // 1904101050
  // navigation: Navigation;

  user: User = new User();
  img: string;
  branding = false;
  imgUrl: string;

  errorMessage: string;

  public subdomain: string;

  constructor() {
    super();
    // // 1904101050
    // this.translateService.get('My Account').subscribe(
    //   translation => {
    //     this.user.name = translation;
    //   });

  }

  // ISs ///////////////////////////////////////////////////////////////////////
  get isInvite(): boolean {
    return (
      CwProtocolTool.source === CW_PROTOCOL_TOOL.SOURCE.INVITE ||
      CwProtocolTool.source === CW_PROTOCOL_TOOL.SOURCE.INVITE_BY_APP
    );
  }
  //////////////////////////////////////////////////////////////////////////////


  // NGs ///////////////////////////////////////////////////////////////////////
  ngOnInit() {
    // 1904101050
    CwEntitySubdomainBus.SuccessActionList = [((Data, That) => {
      const branding = Data.brandingOn;
      const banner = Data.banner;
      if (branding && banner) {
        const random = Math.random().toString(36).substring(2, 8);
        this.branding = true;
        this.img =
          document.location.protocol +
          '//' +
          (
            (document.location.hostname === '192.168.0.43')
              ? 'www.collaboratespace.us'
              : document.location.hostname
          ) +
          AppSettings.ENTITIES_PATH +
          Data.id +
          AppSettings.ENTITY_IMAGE +
          Data.extension +
          '?' +
          random
        ;
        this.imgUrl = Data.logoLink;
      } else {
        // 2101040000
        this.imgUrl = App.Logo.url;
        this.img = App.Logo.src;
      }

    })];
    CwInfoInstallationBus.do(<CwRestRequestParametersI>{
      Bus: CwInfoInstallationBus,
      successActionCustom: ((Data) => {
        const domain = Data.result.domain;
        // To develop
        const subdomain = document.location.hostname === '192.168.0.43'
          ? 'irene'
          : document.location.hostname.substring(
            0, document.location.hostname.indexOf(domain) - 1
          );
        if (subdomain.length > 0 && subdomain !== 'www') {
          this.user.domain = subdomain + '.' + domain;
        } else if (subdomain === '') {
          this.user.domain = null;
          this.img = App.Logo.src;
        } else if ( subdomain === 'www') {
          // 2101040000
          this.img = App.Logo.src;
          this.imgUrl = App.Logo.url;
        }
        // Obtenemos la entidad a partir del subdominio
        if (CwIs.stringNotEmpty(subdomain)) {
          CwEntitySubdomainBus.do(<CwEntitySubdomainDoI>{
            Parameters: {
              subdomain: subdomain
            },
            Bus: CwEntitySubdomainBus,
          })
        }
      })
    });
    // CwInfoInstallationBus.do();
    // // 1904101050
    // // $.smartbanner();
    // this.informationService.getInstallation().subscribe(
    //     res => {
    //
    //         const domain = res.result.domain;
    //         const subdomain = document.location.hostname.substring(0, document.location.hostname.indexOf(domain) - 1);
    //
    //         if (subdomain.length > 0 && subdomain !== 'www') {
    //             this.user.domain = subdomain + '.' + domain;
    //         } else if (subdomain === '') {
    //             this.user.domain = null;
    //         }
    //
    //         // Obtenemos la entidad a partir del subdominio
    //         if (CwIs.stringNotEmpty(subdomain)) {
    //             this.informationService.getEntity(subdomain).subscribe(
    //                 EntityData => {
    //                     const branding = EntityData.result.brandingOn;
    //                     const banner = EntityData.result.banner;
    //                     if (branding && banner) {
    //                         const random = Math.random().toString(36).substring(2, 8);
    //                         this.img =
    //                             document.location.protocol +
    //                             '//' +
    //                             document.location.hostname +
    //                             AppSettings.ENTITIES_PATH +
    //                             EntityData.result.id +
    //                             AppSettings.ENTITY_IMAGE +
    //                             EntityData.result.extension +
    //                             '?' +
    //                             random
    //                         ;
    //                         this.imgUrl = EntityData.result.logoLink;
    //                     }
    //                 },
    //                 error => this.errorMessage = <any>error
    //             );
    //         }
    //
    //
    //     },
    //     error => this.errorMessage = <any>error
    // );

  }
  //////////////////////////////////////////////////////////////////////////////


  over(application: string, index: number, over: boolean) {
    if (over) {
      this.src[index] = 'assets/images/icon-' + application + '-hover.png';
    } else {
      this.src[index] = 'assets/images/icon-' + application + '.png';
    }
  }

  onResize(event) {
    // 1904101050
    App.innerWidth = innerWidth;
    // // 1904101050
    // this.informationService.innerWidth = innerWidth;
  }

  onDeactivate() {
    document.body.scrollTop = 0;
  }


}
