import {CwDefineUrl, CwFunction} from '../../../model/model';
import {LiteralBusClass} from '../literal-bus';
import {CwRestRequestParametersI} from '../../../tool/rest/model';

/**
 * @version 1905281612
 */
export class CwCountryStateBusClass extends LiteralBusClass {

  ItemsKey = 'id';

  defineUrl: CwDefineUrl = (() => {
    return this.path + 'data/country-state.json';
  });

  do: CwFunction = ((Parameters: CwRestRequestParametersI) => {
    if (Parameters && !Parameters.Bus) {
      Parameters.Bus = this;
    }
    this.restGet(Parameters)
  })

}

export const CwCountryStateBus: CwCountryStateBusClass = new CwCountryStateBusClass();

