/**
 * @version 1.8.0
 */
import {
  CwActionFunctionT,
  CwBusAppI,
  CwBusCallbackI,
  CwBusInputsI,
  CwBusLoadI,
  CwBusModel,
  CwBusParametersI,
  CwBusStateType,
  CwDefineUrl,
  CwFunction,
  CwMap,
  CwSetupFeedI,
  CwTokenBusI
} from './model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwRest} from '../tool/rest/tool-rest';
import {CwIs} from '../tool/tool-is';
import {CwToolKeepC} from '../tool/keep/tool-keep';
import {CwRestRequestParametersI, CwRestRequestParametersT, CwToolRestParam} from '../tool/rest/model';
import {CwInput} from '../components/input/cw-input-model';
import {Observable, Subject} from 'rxjs';
import {SocketManagementClass} from '@cw/socket/socket-management';
import {CwSocketActionModel} from '@cw/socket/socket-model';
import {OnDestroy} from '@angular/core';

export abstract class CwBusClass implements OnDestroy {

  // 2010191252
  tokenSuffixDisabled = false;

  private Subject$ = new Subject();

  bearer: string;

  ready = false;
  Api: any;

  get Bus$(): Observable<any> {
    return this.Subject$.asObservable();
  }

  changed = (() => {
    this.Subject$.next();
  });

  definePropertyId = ((Properties: any): void => {
    if (Properties) {
      Properties.cwKey = (
        this.ItemsKey &&
        Properties[this.ItemsKey] &&
        true
      )
        ? Properties[this.ItemsKey]
        : CwUtil.uuid()
      ;
    }
  });

  repeatTimeInSeconds: number;

  addRepeater = ((Params) => {
    if (this.repeatTimeInSeconds && !Params.repeatEnabled) {
      Params.repeatEnabled = true;
      const successFn = Params.successActionCustom;
      Params.successActionCustom = ((Data) => {
        this.repeatDo(Params);
        if (successFn) {
          successFn(Data);
        }
      });

      const errorActionCustomFn = Params.errorActionCustom;
      Params.errorActionCustom = ((Data) => {
        this.repeatDo(Params);
        if (errorActionCustomFn) {
          errorActionCustomFn(Data);
        }
      });

      const errorRestActionCustomFn = Params.errorRestActionCustom;
      Params.errorRestActionCustom = ((Data) => {
        this.repeatDo(Params);
        if (errorRestActionCustomFn) {
          errorRestActionCustomFn(Data);
        }
      });
    }

  });

  repeatDo = ((Params) => {
    const timeout = setTimeout(() => {
      this.do(Params);
    }, this.repeatTimeInSeconds * 1000);
  });

  addObservable = ((Params) => {
    const successFn = Params.successActionCustom;
    Params.successActionCustom = ((Data) => {
      this.changed();
      if (successFn) {
        successFn(Data);
      }
    });

  });

  Load: CwBusLoadI = {
    ActionsToDoList: [],
    ParamsLast: undefined,
    state: <CwBusStateType>CwBusStateType.new,

    forced: ((Params): boolean => {
      // if (Params && Params.forced) {
      //   return true;
      // }
      let _force = (
        (this.Load.ParamsLast === undefined) ||
        (Params && Params.forced) ||
        false
      );
      if (!_force) {
        if (this.Load.ParamsLast !== Params) {
          if (Params) {
            for (const propertyName of Object.getOwnPropertyNames(Params)) {
              if (
                Params &&
                Params[propertyName] &&
                typeof Params[propertyName] !== 'function' &&
                true) {
                if (
                  this.Load.ParamsLast[propertyName] === undefined ||
                  false
                ) {
                  _force = true;
                  break;
                } else if (this.Load.ParamsLast[propertyName] !== Params[propertyName]) {
                  _force = true;
                  break;
                }
              }
            }
          } else {
            _force = true;
          }
        } else {
        }
      }
      return _force;
    }),

    do: ((Params: CwRestRequestParametersI) => {

      if (CwBusStateType.new === this.Load.state) {
        if (Params === undefined) {
          Params = {
            Bus: this,
          };
        }
        this.Load.ParamsLast = Params;
        if (this.do) {
          this.do(Params);
        }
      } else if (CwBusStateType.loading === this.Load.state) {
        if (this.Load.forced(Params)) {
          this.Load.ParamsLast = Params;
          this.do(Params);
        } else {
          // when end
          // if error do again
          // add succesCustom to loadedToDo
          if (Params) {
            this.Load.ActionsToDoList.push(Params);
          }
        }
      } else if (CwBusStateType.loaded === this.Load.state) {
        if (this.Load.forced(Params)) {
          this.Load.ParamsLast = Params;
          this.do(Params);
        } else {
          if (
            Params &&
            Params.successActionCustom &&
            true
          ) {
            Params.successActionCustom({});
          }
        }
      } else if (CwBusStateType.error === this.Load.state) {
        this.Load.ParamsLast = Params;
        this.do(Params);
      } else if (CwBusStateType.empty === this.Load.state) {
        if (this.Load.forced(Params)) {
          this.Load.ParamsLast = Params;
          this.do(Params);
        } else {
          // nothing
          if (Params) {
            this.Load.ActionsToDoList.push(Params);
          }
        }
      } else if (CwBusStateType.disabled === this.Load.state) {
        // nothing
      }
    }),

  };

  getByJid = ((value) => {
    return this.getBy({property: 'jid', value});
  });

  getByEmail = ((value) => {
    return this.getBy({property: 'email', value});
  });

  getBy = ((Params: {
    property: string,
    value: any,
  }) => {
    return CwUtil.getBy({
      ...Params,
      ItemsList: this.ItemsList,
    });

  });


  Socket: SocketManagementClass;
  SocketActionMap: CwMap<CwSocketActionModel[]>;
  socketActivation = (() => {
    if (this.Socket && this.SocketActionMap) {
      this.Socket.register(this.SocketActionMap);
    }
  });

  SocketAction = {
    callback: null,
    callbackDo: ((Params?) => {
      if (this.SocketAction.callback) {
        this.SocketAction.callback(Params);
      }
    }),
  };

  // 1910071306
  App: CwBusAppI = {
    inBackground: false,
    loaded: false,
    loading: false,
  };

  Inputs: CwBusInputsI = {
    MapByApi: new Map(),
    ModalComponent: null,
    List: [],

    get: ((Params: {
      byApi?: string
    }): CwInput => {
      let _get: CwInput;
      if (
        Params &&
        Params.byApi &&
        true
      ) {
        _get = <CwInput>this.Inputs.MapByApi.get(Params.byApi);
      }
      return _get;
    }),

    getValue: ((Params: {
      byApi?: string
    }): any => {
      let _value: any;
      const Input: CwInput = this.Inputs.get(Params);
      if (Input) {
        _value = Input.value;
      }
      return _value;
    }),

    set: ((...Items: CwInput[]) => {
      if (Items) {
        Items.forEach((Item: CwInput) => {
          this.Inputs.List.push(Item);
          this.Inputs.MapByApi.set(Item.api, Item);
        });
      }
    }),

    setValues: ((Values: any) => {
      Object.getOwnPropertyNames(Values).forEach(
        property => {
          const Input = this.Inputs.get({byApi: property});
          if (Input) {
            Input.set(Values[property]);
          }
        }
      );
    }),

    changed: ((): boolean => {
      return (
        this.Inputs.List.length > 0 &&
        this.Inputs.List.filter(
          Item => Item.changed
        ).length > 0 &&
        true
      );
    }),

    reset: (() => {
      this.Inputs.List.forEach((Input: CwInput) => {
        Input.value = Input.valueOriginal;
      });
    }),

    valid: ((): boolean => {
      return (
        this.Inputs.List.length > 0 &&
        this.Inputs.List.filter(
          Item => Item.valid
        ).length === this.Inputs.List.length &&
        true
      );
    }),

    defined: ((): boolean => {
      return (
        this.Inputs.List.length > 0 &&
        this.Inputs.List.filter(
          Item => (
            Item.Validate.required &&
            (
              Item.value === undefined ||
              Item.value === '' ||
              false
            ) &&
            true
          )
        ).length === 0 &&
        true
      );
    }),

    modalComponentEnabled: ((): boolean => {
      return (
        (
          this.Inputs.ModalComponent &&
          !this.Inputs.ModalComponent.isLoading &&
          true
        ) ||
        (
          !this.Inputs.ModalComponent
        ) ||
        false
      );
    }),

    actionEnabled: ((): boolean => {
      return (
        this.Inputs.changed() &&
        this.Inputs.valid() &&
        this.Inputs.defined() &&
        this.Inputs.modalComponentEnabled() &&
        true
      );
    }),

    actionDisabled: ((): boolean => {
      return !this.Inputs.actionEnabled();
    }),

    PayLoad: ((): any => {
      const Payload = {};
      this.Inputs.List.forEach(Input => {
        if (Input.payload) {
          Payload[Input.api] = Input.value;
        }
      });
      return Payload;
    }),

  }; // Inputs


  doParamLast: any;
  resyncronizedCallback: CwFunction;

  doAgain: CwFunction = ((Bus) => {
    if (
      Bus &&
      Bus.doParamLast &&
      true
    ) {
      if (Bus.resyncronizedCallback) {
        Bus.doParamLast.successActionCustom = Bus.resyncronizedCallback;
      }
      Bus.do(Bus.doParamLast);
    }
  });


  // 1906271359
  defineItemValidation: CwFunction;

  // 1904240944
  apiBus = true;

  // 1904101050
  Parameters: CwBusParametersI;
  // 1904101050
  parameterRuleValidation: CwFunction;
  // 1904101050
  parametersValid: CwFunction = (() => {
    const _parametersValid: boolean = (
      (this.Parameters) &&
      this.parameterRuleValidation &&
      this.parameterRuleValidation() &&
      true
    );
    if (
      !_parametersValid &&
      this.Setup &&
      this.Setup.Feed &&
      this.Setup.Feed.Bus &&
      this.Setup.Feed.Bus.info &&
      this.Setup.Feed.parametersInvalid &&
      true
    ) {
      this.Setup.Feed.Bus.info(
        this.Setup.Feed.parametersInvalid
      );
    }
    return _parametersValid;
  });

  Callback: CwBusCallbackI = {};
  Grid: any;

  Response: any = {
    code: 0,
    message: ''
  };
  abstract Keep: CwToolKeepC<any>;

  token: string;
  abstract do: CwFunction;
  abstract defineUrl: CwDefineUrl;
  TokenBus: CwTokenBusI;

  Feed: CwSetupFeedI;
  ItemsSelected: any[] = [];
  selected: CwFunction = ((...IdsList: string[]) => {
    CwUtil.clear(this.ItemsSelected);
    IdsList.forEach(idItem => {
      const Item = this.Items.get(idItem);
      if (Item) {
        this.ItemsSelected.push(Item);
      }
    });
  });

  Items: CwMap<any> = new Map();
  ItemsNotValidation: CwMap<any> = new Map();
  ItemsKey: any;
  ItemsRadio: {
    id: string,
    web: string,
  };

  ItemsList: any[] = [];
  Item: any;
  // 1908191308
  ItemOriginal: any;
  ItemsDelete = (
    (Data: any) => {
      // 1904240944
      if (
        Data &&
        Data.cwKey &&
        true
      ) {
        this.Items.delete(Data.cwKey);
        CwUtil.remove({
          List: this.ItemsList,
          condition: ((Item) => {
            return (Item.cwKey === Data.cwKey);
          })
        });
        this.changed();
      }
    }
  );

  ItemsUpdate = ((Data: any) => {
    if (
      Data &&
      Data.cwKey &&
      this.Items.has(Data.cwKey) &&
      true
    ) {
      CwUtil.setProperties(Data, this.Items.get(Data.cwKey));
    }
  });

  ItemsSet = (
    (Data: any) => {
      // 1904240944
      if (
        Data &&
        Data.cwKey &&
        true
      ) {
        this.Items.set(Data.cwKey, Data);
      }
      // // 1904240944
      // this.Items.set(Data.id, Data);
      this.ItemsList.push(Data);
      if (
        this.Grid &&
        this.Grid.ValuesList &&
        true
      ) {
        this.Grid.ValuesList.push(Data);
        if (
          this.Grid.Setup &&
          this.Grid.Setup.Enabled &&
          this.Grid.Setup.Enabled.paginatorSimulator &&
          true
        ) {
          this.Grid.PaginatorSimulatorValuesList.push(Data);
        }
      }
    }
  );

  Bus: CwBusClass;
  Component: any;
  ErrorActionList: CwActionFunctionT[];
  SystemActionList: CwActionFunctionT[];
  SuccessActionList: any[] = [];
  // SuccessActionList: CwActionFunctionT[];
  SuccessActionOnceList: CwActionFunctionT[];

  SuccessAction = {
    Define: (
      (Data) => {
        this.defineData(Data);
      }
    ),

  };

  /**
   * @description Rest setup
   */
  Param: CwToolRestParam = {
    url: '',
    Callback: {
      error: {
        action: (Data: any, That = this) => {
          if (
            Data &&
            Data.result &&
            true
          ) {
            if (this.defineData) {
              this.defineData(Data.result);
            }
            if (this.ErrorActionList) {
              this.ErrorActionList.forEach(actionItem => {
                if (actionItem) {
                  actionItem(Data.result, That);
                }
              });
            }
            if (Data.result.error) {
              this.Response.message = Data.result.error;
              if (
                this.Setup &&
                this.Setup.Feed &&
                this.Setup.Feed.Bus &&
                true
              ) {
                this.Setup.Feed.Bus.error(
                  this.Response.message,
                  this.Setup.Feed.error
                );
              }
            }
            if (Data.result.errorCode) {
              this.Response.code = Data.result.errorCode;
            }
            // // 1904101050
            // if (
            //     this.Parameters &&
            //     this.Parameters.ErrorAction &&
            //     true
            // ) {
            //     this.Parameters.ErrorAction(Data, That);
            // }

          }
        }
      },
      success: {
        action: (Data, That = this) => {
          if (this.defineData) {
            this.defineData(Data);
          }
          if (
            this.Setup &&
            this.Setup.Feed &&
            this.Setup.Feed.Bus &&
            this.Setup.Feed.success &&
            true
          ) {
            this.Setup.Feed.Bus.success(
              this.Setup.Feed.success
            );
          }
          if (this.SuccessActionList) {
            this.SuccessActionList.forEach(Item => {
              Item(Data, That);
            });
          }
          if (this.SuccessActionOnceList) {
            this.SuccessActionOnceList.forEach(Item => {
              Item(Data, That);
            });
            CwUtil.clear(this.SuccessActionOnceList);
          }
          // // 1904101050
          // if (
          //     this.Parameters &&
          //     this.Parameters.SuccesAction &&
          //     true
          // ) {
          //     this.Parameters.SuccesAction(Data, That);
          // }
        }
      },
      system: {
        action: (Data, That = this) => {
          if (
            this.Setup &&
            this.Setup.Feed &&
            this.Setup.Feed.Bus &&
            true
          ) {
            this.Setup.Feed.Bus.system(
              Data,
              this.Setup.Feed.system
            );
          }
          if (That.SystemActionList) {
            That.SystemActionList.forEach(Item => {
              Item(Data, That);
            });
          }
        }
      }
    }
  };

  // abstract Param: CsToolRestParam;

  restErrorDescription: string;

  abstract DefineProperties: CwFunction[];

  abstract Setup: any;

  /**
   * @description Must be call outside the bus ( Normally in app.ts )
   * @param _Setup
   * @version 1904101050
   */
  setup(_Setup: any) {
    this.Setup = _Setup;
    if (_Setup) {
      if (_Setup.TokenBus) {
        this.TokenBus = _Setup.TokenBus;
      }
      if (_Setup.SuccessActionList) {
        this.SuccessActionList = _Setup.SuccessActionList;
      }
      if (_Setup.ErrorActionList) {
        this.ErrorActionList = _Setup.ErrorActionList;
      }
      if (_Setup.SystemActionList) {
        this.SystemActionList = _Setup.SystemActionList;
      }
      if (_Setup.Grid) {
        this.Grid = _Setup.Grid;
      }
      // 1904101050
      if (_Setup.DefineProperties) {
        this.DefineProperties = _Setup.DefineProperties;
      }
      // 1904101050
      if (_Setup.parameterRuleValidation) {
        this.parameterRuleValidation = _Setup.parameterRuleValidation;
      }
    }
  }

  getById(id: string): any {
    return this.Items.get(id);
  }

  // 1904101050
  /**
   * @param That
   * @param ParametersRest
   * @version 1904101050
   */
  restPost(ParametersRest: CwRestRequestParametersI) {
    if (
      (
        ParametersRest &&
        ParametersRest.Bus &&
        ParametersRest.Bus.restValidation &&
        ParametersRest.Bus.restValidation(ParametersRest.Parameters) &&
        CwRest &&
        true
      ) ||
      (
        CwRest &&
        ParametersRest.Bus &&
        true
      ) ||
      false
    ) {
      CwRest.setPost();
      CwRest.doing(ParametersRest);
    }
  }

  // // 1904101050
  // /**
  //  * @param That
  //  * @param Parameters
  //  * @version 1904101050
  //  */
  // restPost(That: CwBusClass = this, Parameters: any = undefined) {
  //     if (
  //         (
  //             Parameters &&
  //             That &&
  //             That.restValidation &&
  //             That.restValidation(Parameters) &&
  //             CwRest &&
  //             true
  //         ) ||
  //         (
  //             CwRest &&
  //             That &&
  //             true
  //         ) ||
  //         false
  //     ) {
  //         CwRest.setPost();
  //         CwRest.doing(That);
  //     }
  // }

  /**
   * @version 1904101050
   * @param That
   * @param Parameters
   */
  restPut(ParametersRest: CwRestRequestParametersI) {
    if (
      (
        ParametersRest &&
        ParametersRest.Bus &&
        ParametersRest.Bus.restValidation &&
        ParametersRest.Bus.restValidation(ParametersRest.Parameters) &&
        CwRest &&
        true
      ) ||
      (
        CwRest &&
        ParametersRest &&
        ParametersRest.Bus &&
        true
      ) ||
      false
    ) {
      CwRest.setPut();
      CwRest.doing(ParametersRest);
    }
  }

  /**
   *
   * @param _Parameters
   */
  restValidation(_Parameters: any): boolean {
    let restValidation = false;
    if (
      CwRest &&
      _Parameters &&
      true
    ) {
      this.Parameters = {..._Parameters};
      restValidation = this.parametersValid();
    }
    return restValidation;
  }


  restDelete(ParametersRest: CwRestRequestParametersI) {
    if (ParametersRest) {
      CwRest.delete(<CwRestRequestParametersT>ParametersRest);
    }
  }

  /**
   * @description If Parameters is defined, Validation parameters
   * then Call rest with protocol GET
   * @version 1904101050
   */
  restGet(ParametersRest: CwRestRequestParametersI) {
    if (
      (
        ParametersRest &&
        ParametersRest.Parameters &&
        ParametersRest.Bus &&
        ParametersRest.Bus.restValidation &&
        ParametersRest.Bus.restValidation(ParametersRest.Parameters) &&
        CwRest &&
        true
      ) ||
      (
        CwRest &&
        ParametersRest &&
        ParametersRest.Bus &&
        true
      ) ||
      false
    ) {
      CwRest.setGet();
      CwRest.doing(ParametersRest);
    }
  }

  // 1908191308
  defineItem(Data: any) {
    this.Item = {...Data};
    this.ItemOriginal = CwUtil.clone(this.Item);
  }

  reset() {
    if (this.ItemOriginal) {
      this.Item = CwUtil.clone(this.ItemOriginal);
      this.Item.changed = false;
    }
  }


  defineData(Data: any) {

    if (Data) {
      if (CwIs.array(Data)) {
        this.defineList(Data);
      } else if (CwIs.object(Data)) {
        this.defineProperties(Data);
        // 1908191308
        this.defineItem(Data);
      }
      // // 1905281523
      // if (CwIs.array(Data)) {
      //     this.defineList(Data);
      // } else {
      //     this.defineProperties(Data);
      // }
    }
  }

  defineGrid(): void {
    if (this.Grid) {
      CwUtil.clear(this.Grid.ValuesList);
      this.Grid.ValuesList.push(...this.ItemsList);
    }
  }

  defineList(Data: any[]) {
    this.clear();
    for (const _Item of Data) {
      // 1906271359
      if (
        CwIs.object(_Item) &&
        this.defineProperties &&
        true
      ) {
        this.defineProperties(_Item);
      }
      if (
        (
          this.defineItemValidation &&
          this.defineItemValidation(_Item) &&
          true
        ) ||
        (
          !this.defineItemValidation
        ) ||
        false
      ) {
        this.ItemsSet(_Item);
      }
    }
  }

  clear(): void {
    this.Items.clear();
    this.ItemsNotValidation.clear();
    CwUtil.clear(this.ItemsList);
    if (
      this &&
      this.Grid &&
      true
    ) {
      CwUtil.clear(this.Grid.ValuesList);
      CwUtil.clear(this.Grid.PaginatorSimulatorValuesList);
      if (
        this.Grid.Setup &&
        this.Grid.Setup.Action &&
        this.Grid.Setup.Action.clear &&
        true
      ) {
        this.Grid.Setup.Action.clear();
      }
    }
  }

  definePropertiesRadio(Properties: any): void {
    if (
      Properties &&
      this.ItemsRadio &&
      this.ItemsRadio.id &&
      this.ItemsRadio.web &&
      true
    ) {
      Properties._id = Properties[this.ItemsRadio.id];
      Properties._web = Properties[this.ItemsRadio.web];
    }
  }


  /**
   * @description Necessary to work with co-app-grids-grid40
   * @param Properties
   */
  definePropertyGrid40(Properties: any) {
    Properties.coId = Properties.csId;
    Properties.coGridSelected = false;
  }

  defineProperties(Properties: any) {
    if (Properties) {
      this.definePropertyId(Properties);
      this.definePropertyGrid40(Properties);
      this.definePropertiesRadio(Properties);
      if (this.DefineProperties) {
        this.DefineProperties.forEach(DefineProperty => {
          DefineProperty(Properties);
        });
      }
    }
  }

  /**
   * @description Necessary to work with co-app-grids-grid40
   * @param Properties
   */
  get getGridSelection(): any[] {
    return this.ItemsList.filter(Item => Item.coGridSelected);
  }

  /**
   * @description Necessary to work with co-app-grids-grid40
   */
  public selectionPush(RowItem: any): void {
    if (RowItem && RowItem.csId) {
      RowItem.coGridSelected = true;
    }
  }

  /**
   * @description Necessary to work with co-app-grids-grid40
   */
  public selectionRemove(RowItem: any): void {
    if (RowItem && RowItem.csId) {
      RowItem.coGridSelected = false;
    }
  }

  /**
   * @description Necessary to work with co-app-grids-grid40
   */
  public selectionAll(): void {
    this.ItemsList.forEach(Item => {
      Item.coGridSelected = true;
    });
  }

  /**
   * @description Necessary to work with co-app-grids-grid40
   */
  selectionEmpty(): void {
    this.ItemsList.forEach(Item => {
      Item.coGridSelected = false;
    });
  }

  /**
   * @description Necessary to work with co-app-grids-grid40
   */
  get getSelectOne(): boolean {
    return CwIs.arrayWithLength(this.getGridSelection, 1);
  }

  /**
   * @description Necessary to work with co-app-grids-grid40
   */
  get getSelectNone(): boolean {
    return CwIs.arrayEmpty(this.getGridSelection);
  }

  /**
   * @description Necessary to work with co-app-grids-grid40
   */
  get getSelectSeveral(): boolean {
    return (
      !this.getSelectOne &&
      CwIs.arrayNotEmpty(this.getGridSelection)
    );
  }

  get getSelectAny(): boolean {
    return this.getSelectOne || this.getSelectSeveral;
  }


  /**
   * @version 1908271309
   * @param Params
   */
  constructor(Params?: CwBusModel) {
    if (Params) {
      if (Params.InputsSet) {
        this.Inputs.set(...Params.InputsSet);
      }
      // 1910081139
      CwUtil.setProperties(Params, this, [], true);
    }
  }

  ngOnDestroy(): void {
    // 2005210000
    // this.Subscribe.free();
  }
}
