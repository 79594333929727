import {BillingBus} from '../billing-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwToolKeepC} from '../../tool/keep/tool-keep';

class BillingProductsBus extends BillingBus {
    DefineProperties: CwFunction[];
    Keep: CwToolKeepC<any>;
    Setup: any;
    defineUrl: CwDefineUrl = (() =>
        CwBillingProductsBus.path + 'products'
    );
    do: CwFunction = (() => CwBillingProductsBus.restGet(CwBillingProductsBus));

}

export const CwBillingProductsBus = new BillingProductsBus();


// // 1904101050
// getProducts(): Observable<any> {
//     let headers = new Headers({'Content-type': 'application/json'});
// let options = new RequestOptions({headers: headers});
// url = AppSettings.API_ENDPOINT + "billing/";
//
// return this.http.get(this.url + "products", options)
//     .map(this.extractData2.bind(this))
//     .catch(this.handleError);
// }
//
//
// extractData2(res: Response) {
//     let body = res.json();
//
//     for(let product of body.result)
//     {
//         product.price = product.price/100;
//     }
//
//     return body;
// }
