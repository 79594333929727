import {Component, Input, ViewChild} from '@angular/core';
import {Plan} from '../common/plan';
import {AppSettings} from '../common/app-settings';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Subplan} from '../common/subplan';
import {ActivatedRoute, Router} from '@angular/router';
import {CoValidationsComponent} from '../co/validations/component';
import {App} from '../app';
import {CwBillingPlans} from '@rest/billing/plans/plans-bus';
import {CwBillingProductsBus} from '@cw/billing/products/products-bus';
import {CwEntityCreateBus} from '@cw/entity/create/create-bus';
import {CwUserLogin} from '@cw/user/login/login-bus';
import {CwUserLoginDoI} from '@cw/user/login/login-model';
import {CwLiteral} from '@cw/literal/literal';
import {CwWeb} from '@tool/web/tool-web';
import {AppTool} from '../app-tool';
import {AppWebTool} from '../app-web.tool';
import {CwOnPremiseBus} from '@rest/billing/on-premise/billing-on-premise-bus';
import {CwRestRequestParametersI} from '@tool/rest/model';
import {AppPlansTool} from './plans.tool';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';

// declare var Stripe: any;

@Component({
  selector: 'app-plans',
  styleUrls: ['./plans.component.scss'],
  templateUrl: 'plans.component.html',
})

export class PlansComponent
  extends CoValidationsComponent {

  @Input() activeSubscriptions: boolean;
  @Input() activeCallRates: boolean;

  // TODO: Pasar a base de datos
  productsProperties = new Map<string, boolean[]>();
  productsIcon = new Map<string, string>();

  ready = false;
  rectangleHover: boolean[] = [];
  activePlans: string;
  activeRates: string;

  main: string = AppSettings.MAIN_PLAN;
  planId: string;
  productQuantity = 1;

  structuredPlans: Plan[] = [];
  structuredExtras: Plan[] = [];
  structuredProducts: any[];
  planQuantity: number;
  extrasQuantity: number[] = [];
  extrasInterval: string[] = [];
  productsQuantity: number[] = [];
  productsActive: string[] = [];
  intervals = new Map<string, number>();

  // 1904251312 - ?
  lastName = '';

  creating = false;

  partnerId = 1;

  errorMessage: string;

  description: string;

  billing = false;
  rates = false;

  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  lastStep: string;

  selectedPlan: Plan;
  selectedSubplan: Subplan;

  selectedPlans: Subplan[] = [];


  nowSelectedExtras: Subplan[] = [];
  nowSelectedProducts: any[] = [];

  planElements: number[] = [];

  total = 0;

  // Formulario
  planForm: FormGroup;
  // Formulario

  // Cuenta
  firstName: string;
  email: string;
  password: string;
  // Cuenta


  public cardOwner: string;
  private cardNumber: any;
  private cardExpiry: any;
  private cardCvc: any;

  private cardOk = false;
  private cardCvcOk = false;

  stripeToken: string;

  @ViewChild('cardNumberElement') cardNumberElement: any;
  @ViewChild('cardExpiryElement') cardExpiryElement: any;
  @ViewChild('cardCvcElement') cardCvcElement: any;
  @ViewChild('cardErrorsElement') cardErrorsElement: any;
  // Payment

  @ViewChild('formCtrl4') formCtrl4: any;


  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute) {
    super();
    // 2007032000
    CwOnPremiseBus.Load.do({
      successActionCustom: () => {
        if (CwOnPremiseBus.isOnPremise) {
          CwWeb.goTo({path: '/home'});
        }
      },
    })
    const params = this.route.snapshot.paramMap.get('rates');

    if (params !== null && params === 'rates') {
      this.activePlans = '';
      this.activeRates = 'active';
      this.showRates();
    } else {
      this.activePlans = 'active';
      this.activeRates = '';
    }

    // // 1904101050
    // App.Navigation.home = false;
    // App.Navigation.download = false;
    // App.Navigation.plan = true;
    // App.Navigation.host = false;
    // App.Navigation.join = false;
    // // 1904101050
    // this.informationService.navigation.download = false;
    // this.informationService.navigation.plan = true;
    // this.informationService.navigation.host = false;
    // this.informationService.navigation.join = false;

    // Plus
    let properties: boolean[] = [true, true, true, true, false];
    this.productsProperties.set('Plus_1', properties);
    // this.productsIcon.set('Plus_1', "fas fa-plane rotate");
    this.productsIcon.set('Plus_1', 'assets/images/icon-plusplan.png');
    properties = [true, true, true, true, true];
    this.productsProperties.set('Premium_1', properties);
    // this.productsIcon.set('Premium_1', "fas fa-rocket");
    this.productsIcon.set('Premium_1', 'assets/images/icon-premiumplan.png');
  }


  // 1904101050
  definePlans() {
  }


  // 1904101050
  defineProducts() {
    // 1904101050
    this.structuredProducts = CwBillingProductsBus.ItemsList;

//         // 1904101050
    // // TODO::no se si funcionara bien con mas de un producto
//         this.billingService.getProducts().subscribe(
//             result => {
//                 this.structuredProducts = result.result;
//                 // this.addPlan();
//             }
//         );
  }

  OnInit(): void {
    super.OnInit();
    // 2005280951
    App.Navigation.planCallRates = false;
    App.Navigation.planSubscriptions = false;
    if (this.activeCallRates) {
      App.Navigation.planCallRates = true;
      this.showRates();
    } else {
      this.showPlans();
      App.Navigation.planSubscriptions = true;
    }
    AppTool.googleLog();
    App.Navigation.plan = true;
    App.Navigation.home = false;

    AppWebTool.Title.main = 'Plans & Pricing';

    this.planForm = this.formBuilder.group({
      plans: this.formBuilder.array([]),
      subplans: this.formBuilder.array([]),
      extras: this.formBuilder.array([]),
      subextras: this.formBuilder.array([]),
      products: this.formBuilder.array([]),
      subproducts: this.formBuilder.array([])
    });

    CwBillingPlans.Load.do(<CwRestRequestParametersI>{
      successActionCustom: () => {
        this.definePlans();
        this.ready = true;
      }
    });

    if (!CwBillingProductsBus.SuccessActionList) {
      CwBillingProductsBus.SuccessActionList = [];
    }
    // 1904101050
    CwBillingProductsBus.SuccessActionList.push(
      this.defineProducts
    );
    CwBillingProductsBus.do();

    CwToolCurrency.locationSelectedCurrencySelected();
  }

  get subplans(): FormArray {
    return this.planForm.get('subplans') as FormArray
  }

  get extras(): FormArray {
    return this.planForm.get('extras') as FormArray;
  }

  get subextras(): FormArray {
    return this.planForm.get('subextras') as FormArray;
  }

  get products(): FormArray {
    return this.planForm.get('products') as FormArray;
  }

  get subproducts(): FormArray {
    return this.planForm.get('subproducts') as FormArray;
  }

  showDetail(id: string) {
    // 1904101050
    this.description = CwLiteral.translate('.' + id + 'DESC');
    // // 1904101050
    // this.translateService.get('.' + id + 'DESC').subscribe(
    //     translation => {
    //         this.description = translation;
    //     });
  }

  addPlanEvent(event) {
    console.log(this.productQuantity);
    this.addPlan(event.planId);
  }

  addPlan(planId: string) {

    this.billing = true;
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.step4 = false;

    console.log(planId);
    console.log(this.planId);

    if (planId.length > 0) {
      this.selectedPlan = this.structuredPlans.find(
        x => x.id === planId
      );
      this.planId = planId;
    } else {
      this.selectedPlan = this.structuredPlans.find(
        x => x.id === this.planId
      );
    }


    const subplan: Subplan = this.selectedPlan.subplans[0];
    this.selectedSubplan = subplan;

    this.planQuantity = subplan.minSubscription;

    let length: number = this.subplans.length - 1;
    for (let planIndex = 0; planIndex <= length; planIndex++) {
      this.subplans.removeAt(0);
    }
    this.subplans.push(this.formBuilder.group({
      'planId': subplan.planId,
      'id': subplan.id,
      'interval': subplan.interval,
      'quantity': subplan.quantity
    }))

    this.selectedPlans.pop();
    this.selectedPlans.push(subplan);
    this.planElements = subplan.elements;

    const extras = this.structuredExtras;

    length = this.nowSelectedExtras.length - 1;
    for (let extraIndex = 0; extraIndex <= length; extraIndex++) {
      this.nowSelectedExtras.pop();
    }
    length = this.extras.length - 1;
    for (let extraIndex = 0; extraIndex <= length; extraIndex++) {
      this.extras.removeAt(0);
    }
    length = this.subextras.length - 1;
    for (let extraIndex = 0; extraIndex <= length; extraIndex++) {
      this.subextras.removeAt(0);
    }

    let index = 0;
    for (const extra of extras) {
      const subextra = extra.subplans[0];
      this.nowSelectedExtras.push(subextra);
      this.extras.push(this.formBuilder.group(new Plan(extra.id, [])));
      this.subextras.push(this.formBuilder.group({
        'planId': subextra.planId,
        'id': subextra.id,
        'interval': subextra.interval,
        'quantity': subextra.quantity
      }));
      this.extrasQuantity[index] = subextra.minSubscription;
      this.extrasInterval[index] = '';
      index++;
    }

    const products = this.structuredProducts;

    length = this.nowSelectedProducts.length - 1;

    for (let productIndex = 0; productIndex <= length; productIndex++) {
      this.nowSelectedProducts.pop();
    }
    length = this.products.length - 1;
    for (let productIndex = 0; productIndex <= length; productIndex++) {
      this.products.removeAt(0);
    }
    length = this.subproducts.length - 1;
    for (let productIndex = 0; productIndex <= length; productIndex++) {
      this.subproducts.removeAt(0);
    }

    index = 0;
    for (const product of products) {
      this.nowSelectedProducts.push(product);
      this.products.push(this.formBuilder.group(new Plan(product.id_collaboarate_package, [])));
      this.subproducts.push(this.formBuilder.group({'planId': product.id, 'id': product.id_collaboarate_package, 'quantity': 1}));
      this.productsQuantity[index] = 1;
      this.productsActive[index] = 'false';
      index++;
    }

    this.getTotal();

  }

  replaceExtra(subextra, index: number) {

    subextra.index = index;
    subextra.quantity = this.extrasQuantity[index];
    this.extrasInterval[index] = subextra.interval;


    let oldSubextra = this.selectedPlans.find(
      x => x.planId === subextra.planId
    );

    if (oldSubextra === undefined) {

      oldSubextra = this.selectedPlans.find(
        x => x.id === subextra.id
      );

      this.selectedPlans = this.selectedPlans.filter(item => item !== oldSubextra);
      this.selectedPlans.push(subextra);
    } else {
      oldSubextra.quantity = subextra.quantity;
    }


    this.getTotal();

  }

  changeProductQuantityEvent(event) {
    this.changeProductQuantity(event.product, event.index, event.quantity);
  }

  // changeProductQuantity(product, index: number) {
  changeProductQuantity(product, index: number, quantity: number) {
    // this.productsQuantity[index] = this.fProductQuantity.value;
    this.productsQuantity[index] = quantity;

    if (this.productsActive[index] === 'true') {
      const oldSubproduct = this.selectedPlans.find(
        x => x.planId === product.id_SKU
      );
      // oldSubproduct.quantity = this.fProductQuantity.value;
      oldSubproduct.quantity = quantity;

      if (oldSubproduct === undefined) {
        this.selectedPlans = this.selectedPlans.filter(item => item !== oldSubproduct);
        this.selectedPlans.push(oldSubproduct);
      } else {
        oldSubproduct.quantity = quantity;
      }
      this.getTotal();
    }
  }

  /*
    manageQuantityProduct(id: string, index: number)
    {
      if(this.products.controls[index].value.selected) {
        let subproduct = this.selectedPlans.find(x => x.id== id);
        subproduct.quantity = this.subproducts.controls[index].value.quantity;
        this.selectedPlans = this.selectedPlans.filter(x => x.planId !== subproduct.planId);
        this.selectedPlans.push(subproduct);
      }
      this.getTotal();
    }
  */
  remove(event) {
    this.selectedPlans = this.selectedPlans.filter(item => item !== event);
    if (event.type === 'Extra') {
      this.extrasInterval[event.index] = '';
    } else if (event.type === 'Order') {
      this.productsActive[0] = 'false';
    }

    this.getTotal();
  }


  getTotal() {
    this.total = 0;

    for (const interval of this.intervals.keys()) {
      this.intervals.set(interval, 0);
    }

    for (const subplan of this.selectedPlans) {
      this.total = this.total + (subplan.quantity * subplan.amount);

      const totalInterval =
        this.intervals.get(subplan.interval) +
        (subplan.quantity * subplan.amount)
      ;
      this.intervals.set(subplan.interval, totalInterval);

    }

  }

  continueAccount() {
    this.billing = true;
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
    this.step4 = false;
  }

  continueCreditCard() {
    this.billing = true;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = true;
  }

  purchase() {
    this.creating = true;
    this.formCtrl4.value.stripeToken = this.stripeToken;
    this.billing = true;
    this.step4 = false;
    // 1904101050
    CwEntityCreateBus.SuccessActionList.push(
      () => {
        CwUserLogin.do(<CwUserLoginDoI>{
          Parameters: {
            email: CwEntityCreateBus.Item.email,
            password: CwEntityCreateBus.Item.password,
          },
        })
      }
    );
    CwEntityCreateBus.do(this.formCtrl4.value);
    // 1904101050
    // const json = JSON.stringify(this.formCtrl4.value);
    // this.billingService.createAccount(json).subscribe(
    //     result => {
    //         this.creating = false;
    //         if (result.errorCode >= 0) {
    //             this.signInService.signin(this.email, this.password)
    //                 .subscribe();
    //         } else {
    //             this.errorMessage = result.error;
    //         }
    //     },
    //     error => this.errorMessage = <any>error);
  }

  onCreateTokenError(error: any) {
    if (error && error.error) {
      this.cardOk = false;
      this.cardErrorsElement.nativeElement.textContent = error.error.message;

    } else {
      this.cardErrorsElement.nativeElement.textContent = '';

    }
  }

  back(step: string) {
    if (step === 'step1') {
      this.billing = false;
      this.step1 = true;
      this.step2 = false;
    } else if (step === 'step2') {
      this.step2 = true;
      this.step3 = false;
    } else if (step === 'step3') {
      this.step3 = true;
      this.step4 = false;
    }
  }

  showRates() {
    // 1904251312
    // 1904251312
    App.Navigation.planCallRates = true;
    App.Navigation.planSubscriptions = false;


    this.billing = false;
    this.rates = true;

    if (this.step1) {
      this.lastStep = 'step1';
    } else if (this.step2) {
      this.lastStep = 'step2';
    } else if (this.step3) {
      this.lastStep = 'step3';
    } else if (this.step4) {
      this.lastStep = 'step4';
    }
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;

    CwWeb.fitty();
  }

  showPlans() {
    // 1904251312
    App.Navigation.planCallRates = false;
    App.Navigation.planSubscriptions = true;

    this.rates = false;
    if (this.lastStep === 'step1') {
      this.step1 = true;
    } else if (this.lastStep === 'step2') {
      this.billing = true;
      this.step2 = true;
    } else if (this.lastStep === 'step3') {
      this.billing = true;
      this.step3 = true;
    } else if (this.lastStep === 'step4') {
      this.billing = true;
      this.step4 = true;
    }

    CwWeb.fitty();
  }

  customRectangle(index: number, hover: boolean) {
    this.rectangleHover[index] = hover;
  }

}

