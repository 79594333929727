import {CwLogicSpacePlanBuyProClass} from '@cw/logic/space/plan/cw-logic-space-plan-buy-pro';

/**
 * #version 2007290000
 */
export class CwLogicSpacePlanBuyAdminClass extends CwLogicSpacePlanBuyProClass {
  constructor() {
    super();
    this.Buy.Admin = {

    }
  }
}
