import {EntityBus} from '../entity-bus';
import {CwToolKeepC} from '../../tool/keep/tool-keep';
import {CwDefineUrl, CwFunction, CwTokenBusI} from '../../model/model';
import {CreateDoModel} from './create-model';
import {CwRestRequestParametersI} from '../../tool/rest/model';

class EntityCreateBus extends EntityBus {
  Keep: CwToolKeepC<any>;
  DefineProperties: CwFunction[];
  Setup: any;
  SuccessActionList: any[];
  SuccessActionOnceList: any[];
  TokenBus: CwTokenBusI;

  defineUrl: CwDefineUrl = (
    () => {
      return CwEntityCreateBus.path + 'account/';
    }
  );

  /**
   * @version 2003231500
   */
  do: CwFunction = ((Params: CreateDoModel) => {
    CwEntityCreateBus.Param.Data = Params.Data;
    Params.Bus = CwEntityCreateBus;
    Params.Parameters = undefined;
    CwEntityCreateBus.restPost(Params);
  });

}

export const CwEntityCreateBus = new EntityCreateBus();

// // 1904101050
// export class BillingService extends BasicService {
//
// url2 = AppSettings.API_ENDPOINT + "entity/";
// createAccount(json: string): Observable<any> {
//   let headers = new Headers({ 'Content-type': 'application/json'});
// let options = new RequestOptions({headers: headers});
//
// return this.http.post(this.url2 + "account", json,options)
//     .map(this.extractData.bind(this))
//     .catch(this.handleError);
// }
