import {CwToolClass} from '../tool';

class ToolTheme extends CwToolClass<any> {
  CssUrl;
  Setup: any;
}

/**
 * @version 1911181024
 */
export const CwThemeTool = new ToolTheme();
