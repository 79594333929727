import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CwVersionCheck} from '../../../CollaborateSpaceWebTools/version/check/check-bus';
import {Subscription} from 'rxjs/internal/Subscription';
import {CwProtocol, CwProtocolTool} from '../../../CollaborateSpaceWebTools/tool/protocol/tool-protocol';
import {CW_PROTOCOL_TOOL} from '../../../CollaborateSpaceWebTools/tool/protocol/model';
import {AppWebTool} from '../../../app-web.tool';

@Component({
  selector: 'app-connection-app-windows-chrome',
  templateUrl: './connection-app-windows.component.html',
  styleUrls: ['./connection-app-windows.component.css']
})
/**
 * @version 2002141110
 */
export class ConnectionAppWindowsComponent implements OnInit, OnDestroy, AfterViewInit {

  private _autoDownloaded = false;
  private _download;
  private _Subscription: Subscription;

  download = CwProtocol.App.download;


  constructor() {
    CwProtocol.Status.open();
  }


  // ISs ///////////////////////////////////////////////////////////////////////

  get isAutoDownload(): boolean {
    return CwProtocol.isAutoDownload;
  }

  // ISs ///////////////////////////////////////////////////////////////////////


  // NGs ///////////////////////////////////////////////////////////////////////

  ngAfterViewInit(): void {
    CwProtocolTool.action = CW_PROTOCOL_TOOL.ACTION.DOWNLOAD;
    if (!CwProtocolTool.do()) {
      if (CwProtocol.exec) {
        CwProtocol.Browser.Firefox.exec();
      }
    }
  }

  ngOnDestroy(): void {
    CwProtocol.Status.close();
    if (this._Subscription) {
      this._Subscription.unsubscribe();
    }
  }

  ngOnInit() {
    AppWebTool.Title = {
      main: 'Thank you for downloading',
      secondary: '',
      startHereVisible: false,
    };
    // 2002240902
    CwProtocol.Browser.InternetExplorer.check();
    if (CwProtocol.isAutoDownload) {
      this.download();
      if (this._download) {
        this._autoDownloaded = true;
      } else {
        this._Subscription = CwVersionCheck.Bus$.subscribe(() => {
          this.download();
          this._autoDownloaded = true;
        })
      }
    }


  }

  // NGs ///////////////////////////////////////////////////////////////////////

}
