import {CwBusClass} from '../../../../model/bus';
import {CwFunction} from '../../../../model/model';
import {CwToolKeepC} from '../../../../tool/keep/tool-keep';


class SubscriptionIntervalBus extends CwBusClass {

  DefineProperties: CwFunction[];
  defineUrl: any;
  do: CwFunction;
  ItemsKey = 'name';
  ItemsRadio = {
    id: 'name',
    web: 'literal',
  };
  Keep: CwToolKeepC<any>;
  Setup: any;

  constructor() {
    super();
    this.defineList([
        {
          disabled: false,
          literal: 'Monthly',
          name: 'month',
        },
        {
          disabled: false,
          literal: 'Yearly',
          name: 'year',
        },
      ]
    )
  }

}

/**
 * @version 2002111109-5
 */
export const CwSubscriptionIntervalBus = new SubscriptionIntervalBus();
