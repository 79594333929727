import {CwToolKeepC} from '../tool/keep/tool-keep';
import {CwBusClass} from '../model/bus';
import {CwDefineUrl, CwFunction} from '../model/model';
import {CwTrustKeepDefault, CwTrustKeepI} from './model';
import {CwWeb} from '../tool/web/web.tool';

/**
 * @version 1910011410
 */
class Trust extends CwBusClass {

  Sca: any;


  // ISs ///////////////////////////////////////////////////////////////////////
  get isScaPending(): boolean {
    if (this.Sca) {
      return this.Sca.isPending();
    } else {
      return false;
    }
  }

  //////////////////////////////////////////////////////////////////////////////


  // 1912131221
  get partnerBehaviour(): boolean {
    return this.Keep.getProperty('partnerId');
  }
  set partnerBehaviour(value: boolean) {
    this.Keep.setProperty({name: 'partnerBehaviour', Value: value});
  }

  // 1912131221
  get partnerId(): string {
    return this.Keep.getProperty('partnerId');
  }
  set partnerId(value: string) {
    this.Keep.setProperty({name: 'partnerId', Value: value});
  }

  get adminBehaviour(): boolean {
    return !this.iframeBehaviour;
  }

  get iframeBehaviour(): boolean {
    return this.Keep.getProperty('iframeBehaviour');
  }

  set iframeBehaviour(value: boolean) {
    this.Keep.setProperty({name: 'iframeBehaviour', Value: value});
  }

  get trust(): boolean {
    return this.Keep.getProperty('trust');
  }

  set trust(Value: boolean) {
    this.Keep.setProperty({name: 'trust', Value});
  }

  get token(): string {
    return this.Keep.getProperty('token');
  }

  set token(Value: string) {
    this.Keep.setProperty({name: 'token', Value});
  }

  // 2009230000.D11
  get jid(): string {
    return this.Keep.getProperty('jid');
  }

  // 2009230000.D11
  set jid(Value: string) {
    this.Keep.setProperty({name: 'jid', Value});
  }

  get JWT(): string {
    return CwWeb.Keep.getData('CS-JWT');
  }

  // 1910011410
  Keep: CwToolKeepC<CwTrustKeepI> = new CwToolKeepC({
    key: 'COLLABORATESpace',
    DataDefault: <CwTrustKeepI>CwTrustKeepDefault
  });

  language: string;
  unAuthorizePath = '/unAuthorize';
  viewOnly = false;
  // private _iframeBehaviour = true;


  DefineProperties: CwFunction[];
  // private _trust = false;
  Setup: any;
  defineUrl: CwDefineUrl;
  do: CwFunction;

  constructor() {
    super();
    this.iframeBehaviour = true;
  }

}

export const CwTrust = new Trust();
