import {Component, Input} from '@angular/core';
import {CwComponent} from '../component';
import {CwOptionI, CwSelectComponentI} from './select-model';
import {CwCountryBus} from '../../rest/literal/country/country-bus';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwLiteral} from '../../literal/literal';

@Component({
  selector: 'app-cw-select2',
  templateUrl: './select.component.html',
  styles: []
})
export class SelectComponent extends CwComponent {

  @Input()disabled: boolean;

  get getOptionList(): CwOptionI[] {
    return (
      this.Setup &&
      this.Setup.OptionList &&
      true
    )
      ? this.Setup.OptionList
      : []
  }

  @Input() Setup: CwSelectComponentI;
  @Input() adminStyle: boolean;

  getSelected(Option: any): boolean {
    return this.ngModel ? Option.web === this.ngModel.web : false ;
  }

}

@Component({
  selector: 'app-cw-select-country',
  templateUrl: './select.component.html',
  styles: []
})
/**
 * @version 1906131130
 */
export class CountrySelectComponent extends SelectComponent {

  CountryB = new CwCountryBus();

  get getOptionList(): CwOptionI[] {
    return (
      this.CountryB &&
      this.CountryB.ItemsList &&
      true
    )
      ? this.CountryB.ItemsList.sort((ItemA, ItemB) => {
        return CwUtil.sortAsc(ItemA.web, ItemB.web);
      })
      : []
  }

}
