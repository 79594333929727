import {EntityBus} from '../../../entity/entity-bus';
import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwSocket} from '../../../socket/socket-management';
import {CwTrust} from '../../../trust/trust';
import {CwConvergenceStatus} from '../../../convergence/convergence-status';
import {CwSocketActionModel, CwSocketActionPriorityModel, SOCKET_ACTION} from '../../../socket/socket-model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwFirmwareBus} from '../../version/cproversion/cproversion-bus';
import {UiConfig} from '../../../../commons/config/ui.config';
import {CwIs} from '../../../tool/tool-is';
import {GetUserConfig} from '../../../user/get-user/get-user.config';
import {CwRolesBus} from '../get-all-roles/bus';
import {CwEntityConfig} from './model';
import {CwString} from '../../../../commons/utils/string';
import {CwDateTool} from '../../../tool/date/date.tool';
import {CwFileTool} from '../../../tool/file/file.tool';
import {CwGetLogFilesBus} from '../../../socket/send/get-log-files/get-log-files';
import {CwDeviceSettingsBus} from '../../../socket/send/admin_connected/admin_connected-bus';
import {CwAvatarInterface, CwMapAvatar} from '../../../components/avatar/avatar.interfaces';

export class EntityGetUsers extends EntityBus {

  get getItemsList(): any[] {
    if (this.Filter.convergenceStatus) {
      return this.ItemsList.filter(Item => Item.convergenceStatus.toUpperCase() === this.Filter.convergenceStatus.toUpperCase());
    } else {
      return this.ItemsList;
    }
  }

  constructor(Params?) {
    super(Params);
  }

  // 1911220849
  get getNoCache(): boolean {
    return (
      CwRolesBus &&
      CwRolesBus.totalLicenses &&
      CwRolesBus.totalLicenses > CwEntityConfig.LIMIT_CACHE &&
      true
    );
  }

  Filter = {
    convergenceStatusReset: (() => {
      this.Filter.convergenceStatus = null;
    }),
    convergenceStatusSet: ((value) => {
      this.Filter.convergenceStatus = value;
    }),

    convergenceStatus: null
  };

  MAP_AVATAR: CwMapAvatar = new Map();


  resource: boolean;
  ItemsKey: 'email';
  noCache = false;

  defineAvatarFromUser = ((userData: any): void => {
    const userId = userData.jid;
    if (!(this.MAP_AVATAR.has(userId))) {
      this.MAP_AVATAR.set(userId, {});
    }
    const avatar: CwAvatarInterface = this.MAP_AVATAR.get(userId);

    if (!avatar.color || CwIs.stringEmpty(avatar.color)) {
      avatar.color = UiConfig.AVATAR.COLOR_DEFAULT;
    }

    avatar.initial = (CwIs.stringNotEmpty(userData.initials))
      ? userData.initials.toUpperCase()
      : UiConfig.AVATAR.INITIAL_DEFAULT;
  });

  definePropertyLicense = ((Properties: any) => {
    if (
      CwIs.defined(Properties) &&
      CwIs.array(Properties.roles)
    ) {
      const _licenseList = Properties.roles.filter(rolItem =>
        (
          String(rolItem.componentName).includes(GetUserConfig.ROLES_HOSTS_POSTFIX) ||
          String(rolItem.componentName) === GetUserConfig.LICENSE_FREE ||
          String(rolItem.componentName) === GetUserConfig.LICENSE_MANAGEMENT
        )
      );
      if (CwIs.arrayNotEmpty(_licenseList)) {
        Properties.license = _licenseList[0].componentName.replace(GetUserConfig.ROLES_HOSTS_POSTFIX, '').toUpperCase();
      } else {
        Properties.license = GetUserConfig.LICENSE_EMPTY;
      }
    }
  });

  definePropertyStorage = ((Properties: any) => {
    if (
      CwIs.defined(Properties) &&
      CwIs.number(Properties.cloudSpaceUsed) &&
      Number(Properties.cloudSpaceUsed) >= 0
    ) {
      CwString.bytesToHuman(
        Properties.cloudSpaceUsed,
        Properties,
        'storageWeb',
        UiConfig.STORAGE
      );
    }
  });

  definePropertyFirmwareUpgradeable = ((Properties) => {
    Properties.firmwareUpgradeable = false;
    if (Properties.firmwareVersion) {
      const FirmwareVersionList = Properties.firmwareVersion.split('.');
      if (FirmwareVersionList && 4 === FirmwareVersionList.length) {
        if (FirmwareVersionList[0] < CwFirmwareBus.Version.major) {
          Properties.firmwareUpgradeable = true;
        } else if (
          FirmwareVersionList[0] === CwFirmwareBus.Version.major &&
          FirmwareVersionList[1] < CwFirmwareBus.Version.minor &&
          true
        ) {
          Properties.firmwareUpgradeable = true;
        } else if (
          FirmwareVersionList[0] === CwFirmwareBus.Version.major &&
          FirmwareVersionList[1] === CwFirmwareBus.Version.minor &&
          FirmwareVersionList[2] < CwFirmwareBus.Version.build &&
          true
        ) {
          Properties.firmwareUpgradeable = true;
        } else if (
          FirmwareVersionList[0] === CwFirmwareBus.Version.major &&
          FirmwareVersionList[1] === CwFirmwareBus.Version.minor &&
          FirmwareVersionList[2] === CwFirmwareBus.Version.build &&
          FirmwareVersionList[3] < CwFirmwareBus.Version.revision &&
          true
        ) {
          Properties.firmwareUpgradeable = true;
        }
      }
    }
  });

  definePropertyFirmwareUgradeableEnabled = ((Properties) => {
    Properties.firmwareUgradeableEnabled = (
      Properties.resource &&
      Properties.firmwareUpgradeable &&
      UiConfig.RESOURCES.STATUS.TYPES.filter(
        TypeFilter => (
          TypeFilter.API.toUpperCase() === Properties.status.toUpperCase() &&
          TypeFilter.connected &&
          true
        )
      ).length > 0 &&
      true
    );
  });

  defineRole = ((Properties: any) => {
    Properties.activeRole = '';
    if (CwIs.defined(Properties) && CwIs.arrayNotEmpty(Properties.roles)) {
      for (const role of Properties.roles) {
        if (role.componentName === GetUserConfig.API_ROLES_OWNER) {
          Properties.activeRole = GetUserConfig.API_ROLES_OWNER;
          break;
        } else if (role.componentName === GetUserConfig.API_ROLES_ADMINISTRATOR) {
          Properties.activeRole = GetUserConfig.API_ROLES_ADMINISTRATOR;
          break;
        } else if (role.componentName === GetUserConfig.API_ROLES_MONITOR) {
          Properties.activeRole = GetUserConfig.API_ROLES_MONITOR;
          break;
        } else {
          Properties.activeRole = GetUserConfig.ROL_DEFAULT;
        }
      }
    }
  });

  defineLastUpdate = ((Properties: any) => {
    if (CwIs.defined(Properties) &&
      CwIs.stringNotEmpty(Properties.statusLastUpdate) &&
      !Properties.resource
    ) {
      if (Properties.status === 'offline') {
        Properties.lastUpdateWeb = CwDateTool.utcToDateTimeZone(Properties.statusLastUpdate);
      } else {
        Properties.lastUpdateWeb = CwDateTool.utcToDateTimeZone(new Date().toISOString());
      }
    }
  });

  defineRegistrationText = ((Properties: any) => {
    if (CwIs.defined(Properties) &&
      !Properties.resource
    ) {
      Properties.registrationText = 'Registered';
    }
  });

  defineLDAP = ((Properties: any) => {
    if (CwIs.defined(Properties) &&
      !Properties.resource
    ) {
      Properties.LDAP = Properties.type && Properties.type === 6   ? 'Yes' : 'No';
    }
  });

  DefineProperties: CwFunction[] = [
    // status
    CwConvergenceStatus.status,
    this.definePropertyFirmwareUpgradeable,
    this.definePropertyFirmwareUgradeableEnabled,
    this.definePropertyLicense,
    this.definePropertyStorage,
    this.defineRole,
    this.defineAvatarFromUser,
    this.defineLastUpdate,
    this.defineRegistrationText,
    this.defineLDAP,
    // modelDescription
    (Properties: any) => {
      if (
        Properties.model &&
        true
      ) {
        const modelDescription = UiConfig.RESOURCES.MODEL.TYPES.find(modelType => modelType.API === Properties.model);
        if (modelDescription) {
          Properties.modelDescription = modelDescription.LITERAL;
        }
      }
    },
  ];


  avatar = ((itemVCard: any) => {
    this.defineAvatarFromVcard(itemVCard);
  });

  defineAvatarFromVcard = ((itemVCard: any): void => {
    const userId = itemVCard.jid;
    if (!(this.MAP_AVATAR.has(userId))) {
      this.MAP_AVATAR.set(userId, {});
    }
    const avatar: CwAvatarInterface = this.MAP_AVATAR.get(userId);

    if (CwIs.stringNotEmpty(itemVCard.photoUrl)) {
      avatar.isImage = true;
      avatar.photoUrl = itemVCard.photoUrl;
    } else {
      avatar.isImage = false;
      avatar.photoUrl = '';
    }

    avatar.color = (CwIs.stringNotEmpty(itemVCard.color))
      ? itemVCard.color
      : UiConfig.AVATAR.COLOR_DEFAULT
    ;
  });


  defineUrl: CwDefineUrl = (() => this.path + 'getUsers');

  do: CwFunction = ((Params: any = {token: CwTrust.token}) => {
    const _SuccessActionCustom = Params.successActionCustom;
    Params.successActionCustom = (() => {
      if (_SuccessActionCustom) {
        _SuccessActionCustom();
      }
    });

    if (Params.PayLoadCustom) {
      Params.PayLoadCustom.noCache = !!this.getNoCache;
    }

    this.restPut({
      ...Params,
      PayLoadCustom: (Params.PayLoadCustom)
        ? Params.PayLoadCustom
        : {
          resource: this.resource,
          noCache: !!this.getNoCache,
        },
      token: CwTrust.token,
      Bus: this,
    });
  });

  doWithoutCache = (() => {
    this.restPut({
      PayLoadCustom: {
        resource: this.resource,
        noCache: true,
      },
      token: CwTrust.token,
      Bus: this,
    });
  });


  SocketAction = {

    callback: null,
    callbackDo: ((Params?) => {
      if (this.SocketAction.callback) {
        this.SocketAction.callback(Params);
      }
    }),

    updateStatus: ((Params) => {
      if (
        Params &&
        Params.resources_jid &&
        Params.resources_jid.length > 0 && // resources updated
        Params.content &&
        Params.content.response &&
        true
      ) {
        const statusFromSocket = Params.content.response;
        Params.resources_jid.forEach(jidItem => {
          const _Item = this.getByJid(jidItem);
          if (
            _Item &&
            _Item.status &&
            _Item.status.toUpperCase() !== statusFromSocket.toUpperCase() &&
            true
          ) {
            if (UiConfig.getResourceConvergenceStatusFromDevice(_Item) !==
              UiConfig.getResourceConvergenceStatusFromApi(statusFromSocket)) {
              _Item.statusLastUpdate = new Date().toISOString();
            }
            _Item.status = statusFromSocket;
            CwConvergenceStatus.status(_Item);
            this.updateLastLogin(_Item.jid, _Item);
            this.definePropertyFirmwareUgradeableEnabled(_Item);

            // If we are on settings - connected to the device- and status goes offline,
            // default tab needs to be shown
            if (statusFromSocket === 'offline' && CwDeviceSettingsBus.jidConnected === _Item.jid) {
              CwDeviceSettingsBus.responseCallback(true);
            }
            this.SocketAction.callbackDo(true);
          }
        });
      }
    }),

    updateStatusDevice: ((Params) => {
      if (
        Params &&
        Params.resources_jid &&
        Params.resources_jid.length > 0 && // resources updated
        Params.content &&
        true
      ) {
        const statusFromSocket = Params.content.status;
        Params.resources_jid.forEach(jidItem => {
          const _Item = this.getByJid(jidItem);
          if (
            _Item &&
            _Item.CProStatus &&
            _Item.CProStatus.toUpperCase() !== statusFromSocket.toUpperCase() &&
            true
          ) {
            _Item.CProStatus = statusFromSocket;
            CwConvergenceStatus.status(_Item);
            this.SocketAction.callbackDo();
          }
        });
      }
    }),

    //    "action": "log_zip_file_uploaded",
    //    "content":
    //    {
    //      "zip_file_name": "COLLABORATE_Live_300_F44D3067973F_10012020-0143211.zip",
    //      "download_url": "https://spontaniasharing.s3.amazonaws.com/E87648...."
    //    }
    logs: ((Params) => {
      if (
        Params &&
        Params.content &&
        Params.content.download_url &&
        true
      ) {
        const File = CwGetLogFilesBus.FileList.find((item) => item.name === Params.content.zip_file_name);
        if (File &&
          File.status !== 'Error' &&
          File.message !== 'Timeout') {
          File.download_url = Params.content.download_url;
          File.status = 'Ready';
          File.message = 'Ready';
          if (File.timeout) {
            clearTimeout(File.timeout);
          }
        }
        if (CwGetLogFilesBus.download) {
          for (const file of CwGetLogFilesBus.FileList) {
            CwFileTool.downloadZIP(file.download_url, file.name);
          }
          CwGetLogFilesBus.FileList = [];
          CwGetLogFilesBus.download = false;
        }
      } else if (
        Params &&
        Params.content &&
        Params.content.error &&
        true
      ) {
        const File = CwGetLogFilesBus.FileList.find((item) => item.name === Params.content.zip_file_name);
        if (File) {
          File.status = 'Error';
          File.message = Params.content.error;
        }
      }
    }),


    // action: "vcard_updated"
    // content: {}
    // id: "e3fde1b3-9dfd-437d-85d9-259b41cf168c"
    // resources_jid: ["139194218@spontania"]
    updateVcard: ((Params) => {
      if (
        Params &&
        Params.resources_jid &&
        Params.resources_jid.length > 0 && // resources updated
        true
      ) {
        // 1908161444
        Params.resources_jid.forEach(resourceJid => {
          const Item = this.getBy({
            property: 'jid',
            value: resourceJid,
          });
          if (Item) {
            // Vcard name can be updated
            this.do();
            // callBackDo(true) will make reload Vcard call
            this.SocketAction.callbackDo(true);
          }
        });
      }
    }),

    // adminJid: "151116511@spontania"
    // parameters:
    //  emails: ["ddd@yopmail.com"]
    // result: 0
    // transactionId: "e543350a-8eb9-40c2-96b0-73bf710a2b13"
    // webserviceName: "user/deleteUsers"
    deleteUsers: ((Params) => {
      if (
        Params &&
        Params.parameters &&
        Params.parameters.emails &&
        Params.parameters.emails.length > 0 &&
        true
      ) {
        Params.parameters.emails.forEach(value => {
          const Item = this.getBy({property: 'email', value});
          if (Item) {
            Item.registrationDate = CwDateTool.dateToString();
            this.ItemsDelete(Item);
            this.SocketAction.callbackDo();
          }
        });
      }
    }),


    // adminJid: "151116511@spontania"
    // parameters: {email: "dd1910141546@yopmail.com", jid: "139194218@spontania"}
    // result: 0
    // transactionId: "29c0928e-2a35-44e2-8843-b51ae2c822d1"
    // webserviceName: "user/update"
    updateUser: ((Params) => {
      if (
        Params &&
        Params.parameters &&
        Params.parameters.jid &&
        true
      ) {
        // 1911180842
        CwDevicesAndUsersUpdateBus.do({
          successActionCustom: (() => {
            CwDevicesAndUsersUpdateBus.ItemsList.forEach(UpdatedItem => {
              if (this.resource === UpdatedItem.resource) {
                const _Item = this.getByJid(UpdatedItem.jid);
                if (_Item) {
                  // if a property on UpdatedItem does not exist on Item, remove it.
                  CwUtil.removeProperties(UpdatedItem, _Item);
                  CwUtil.setProperties(UpdatedItem, _Item);
                  if (this.DefineProperties) {
                    this.DefineProperties.forEach(DefineProperty => {
                      DefineProperty(_Item);
                    });
                  }
                }
              }
            });
          }),
          PayLoadCustom: {
            // resource: _Item.resource,
            jids: [Params.parameters.jid],
            noCache: true,
          },
        });

        // // 1911180842
        // const Item = this.getBy({
        //   property: 'jid',
        //   value: Params.parameters.jid,
        // });
        // if (Item) {
        //   CwUtil.setProperties(Params.parameters, Item);
        //   this.SocketAction.callbackDo();
        // }


        // Upload several jids
      } else if (
        Params &&
        Params.parameters &&
        Params.parameters.jids &&
        true
      ) {
        CwDevicesAndUsersUpdateBus.do({
          successActionCustom: (() => {
            CwDevicesAndUsersUpdateBus.ItemsList.forEach(UpdatedItem => {
              if (this.resource === UpdatedItem.resource) {
                const _Item = this.getByJid(UpdatedItem.jid);
                if (_Item) {
                  CwUtil.setProperties(UpdatedItem, _Item);
                  if (this.DefineProperties) {
                    this.DefineProperties.forEach(DefineProperty => {
                      DefineProperty(_Item);
                    });
                  }
                }
              }
            });
          }),
          PayLoadCustom: {
            // resource: _Item.resource,
            // jids: [Params.parameters.jids],
            noCache: true,
          },
        });
      }
    }),

    //   adminJid: "Not Admin"
    //   error:
    //    admin: false
    //    email: "y1910141546@yopmail.com"
    //    entityId: 1
    //    token: "cf8708f9-3c1e-48ef-a699-a484d6971f08"
    // parameters:
    //    admin: false
    //    email: "y1910141546@yopmail.com"
    //    entityId: 1
    //    token: "cf8708f9-3c1e-48ef-a699-a484d6971f08"
    // result: 0
    // transactionId: "88a01f94-8a28-4e16-8590-5b3e769aa7e0"
    // webserviceName: "user/registration"
    registrationUser: ((Params) => {
      if (
        Params &&
        Params.parameters &&
        Params.parameters.email &&
        true
      ) {
        this.SocketAction.reload();
      }
    }),


    // "jid":" re180903423@collaboratespace.net",
    // "entityID": 31,
    // {
    //  "id":"4234lkjsfasd3",
    //  "action": "registration_status",
    //  "content":
    //  {
    //    "protocol": "SIP",
    //    "status": "Not Enabled",
    //    "error_description": ""
    //  },
    //  "timestamp"="2020-01-14T09:30:06.643546Z"
    // }
    registrationStatusDevice: ((Params) => {
      if (
        Params &&
        Params.resources_jid &&
        Params.content &&
        Params.timestamp &&
        true
      ) {
        const statusFromSocket = Params.content.status;
        const protocol = Params.content.protocol;
        const errorFromSocket = Params.content.error_description;
        const timestamp = Params.timestamp;

        Params.resources_jid.forEach(jidItem => {
          const _Item = this.getByJid(jidItem);
          if (
            _Item &&
            true
          ) {
            if (_Item.listProtocolRegistration) {
              const itemToUpdate = _Item.listProtocolRegistration.find((item) => item.protocol === protocol);
              itemToUpdate.status = statusFromSocket;
              itemToUpdate.errorDescription = errorFromSocket;
              if (timestamp) {
                itemToUpdate.lastChange = timestamp;
              } else {
                itemToUpdate.lastChange = new Date().toISOString();
              }
            } else {
              const listProtocolRegistration = [
                {
                  errorDescription: errorFromSocket,
                  protocol: protocol,
                  status: statusFromSocket,
                  lastChange: timestamp
                }
              ];
              _Item['listProtocolRegistration'] = listProtocolRegistration;
            }

            // if device has gone Healthy and has no longer issues
            // we need to update also the status last update
            if (!UiConfig.hasIssues(_Item)) {
              _Item.statusLastUpdate = new Date().toISOString();
            }

            // Update convergenceStatus
            CwConvergenceStatus.status(_Item);

          }
        });

      }
    }),

    // id: "ND5DKLGO"
    // action: "screen_message"
    // resources_jid: Array(1)
    // 0: "re138769264@collaboratespace.us"
    // length: 1
    // content:
    // message_description: "Refreshing NAT Address..."
    screenMessage: ((Params) => {
      if (
        Params &&
        Params.resources_jid &&
        Params.resources_jid.length > 0 && // resources updated
        Params.content &&
        Params.content.message_description &&
        true
      ) {
        const message = Params.content.message_description;
        Params.resources_jid.forEach(jidItem => {
          const _Item = this.getByJid(jidItem);
          if (
            _Item &&
            CwDeviceSettingsBus.jidConnected === _Item.jid &&
            true
          ) {
            CwDeviceSettingsBus.addScreenMessageWithTimeout(message);
          }
        });
      }
    }),


    updateDeviceSettings: ((Data) => {
      if (
        Data &&
        Data.resources_jid &&
        Data.resources_jid.length === 1 &&
        Data.content &&
        (!Data.content.response ||
          'OK' === Data.content.response.toUpperCase()) &&
        Data.content.cpro_settings &&
        Data.content.cpro_settings.version &&
        true
      ) {
        const firmwareFromSocket = Data.content.cpro_settings.version;
        const _Item = this.getByJid(Data.resources_jid[0]);
        if (
          _Item &&
          _Item.firmwareVersion !== firmwareFromSocket &&
          true
        ) {
          _Item.firmwareVersion = firmwareFromSocket;
          this.definePropertyFirmwareUpgradeable(_Item);
          this.definePropertyFirmwareUgradeableEnabled(_Item);
          this.SocketAction.callbackDo();
        }
      } else {
        console.error('error Display Firmware', Data);
      }
    }),

    // adminJid: "146956042@spontania"
    // parameters:
    //   emails: ["1910141546@yopmail.com"]
    // roles: Array(1)
    // 0: {componentName: "PRO_HOST", quantity: 1}
    // length: 1
    // result: 0
    // transactionId: "bc1fe829-e6f0-4843-995f-ed5ff7add1b1"
    // webserviceName: "user/addRolesUsers"
    updateRoles: ((Params) => {
      if (
        Params &&
        Params.parameters &&
        Params.parameters.roles &&
        Params.parameters.roles.length > 0 &&
        Params.parameters.emails &&
        Params.parameters.emails.length > 0 &&
        true
      ) {
        this.SocketAction.reload();
        // Params.parameters.emails.forEach( email => {
        //   const Item = this.getBy({property: 'email', value:email});
        //   if (Item) {
        //     // roles: [{componentName: "OWNER", quantity: 1}, {componentName: "PRO_HOST", quantity: 1}]
        //     // 0: {componentName: "OWNER", quantity: 1}
        //     // 1: {componentName: "PRO_HOST", quantity: 1}
        //
        //   }
        // })
      }
    }),

    // adminJid: "112316145@collaboratespace.us"
    // parameters:
    //  emails: Array(1)
    //  0: "1908281116@yopmail.com"
    //  roles: Array(2)
    //  0: {componentName: "ADMINISTRATOR", quantity: 0}
    //  1: {componentName: "ADMINISTRATOR_BILLING", quantity: 0}
    // result: 0
    // transactionId: "9fe4026b-fe24-4770-9b13-5eb111a2ffba"
    // webserviceName: "user/delRolesUsers"
    deleteRoles: ((Params) => {
      if (
        Params &&
        Params.parameters &&
        Params.parameters.roles &&
        Params.parameters.roles.length > 0 &&
        Params.parameters.emails &&
        Params.parameters.emails.length > 0 &&
        true
      ) {
        Params.parameters.emails.forEach(email => {
          const Item = this.getBy({property: 'email', value: email});
          if (Item) {
            this.SocketAction.reload();
            //     // roles: [{componentName: "OWNER", quantity: 1}, {componentName: "PRO_HOST", quantity: 1}]
            //     // 0: {componentName: "OWNER", quantity: 1}
            //     // 1: {componentName: "PRO_HOST", quantity: 1}

          }
        });
      }
    }),


    //   adminJid: "112316145@collaboratespace.us"
    //   parameters:
    //      classroom: false
    //      email_inviting_user: "mesi1705@yopmail.com"
    //      emails: Array(1)
    //        0: "carlos88@clearone.con"
    //      roles: [{…}]
    //      team: "DEFAULT"
    //      webinar: false
    // result: 0
    // transactionId: "93b48bab-fe20-4547-bbf4-999dc4eb76ef"
    // webserviceName: "entity/importLDAPUsers"
    addUsers: ((Params) => {
      if (
        Params &&
        Params.parameters &&
        (
          // from LDAP
          (
            Params.parameters.emails &&
            Params.parameters.emails.length > 0 &&
            true
          ) ||
          // from CSV
          (
            Params.parameters.imported &&
            Params.parameters.imported.length > 0 &&
            Params.parameters.imported[0] &&
            Params.parameters.imported[0].password &&
            true
          ) ||
          false

        ) &&
        true
      ) {
        this.SocketAction.reload();
      }
    }),


    reload: (() => {
      this.Load.do({
        ...this.Load.ParamsLast,
        forced: true,
        successActionCustom: (() => {
          this.SocketAction.callbackDo();
        })
      });
    }),

    // adminJid: "112316145@collaboratespace.us"
    // parameters:
    //    email: "1910211120@yopmail.com"
    //    idEntity: 83
    //    name: "1910211120"
    //    password: "Clearone1!"
    //    resource: true
    //    roles: [{…}]
    //    teams: ["DEFAULT"]
    // result: 0
    // transactionId: "bd067f24-b67c-4e1b-9d26-15abf5cb7c6c"
    // webserviceName: "user/create"

    Device: {
      add: ((Params) => {
        if (
          this.resource &&
          Params &&
          Params.parameters &&
          Params.parameters.resource &&
          Params.parameters.email &&
          true) {
          this.SocketAction.reload();
        }

      })
    }
  }; // /SocketAction

  SocketActionMap = new Map()
    .set(
      SOCKET_ACTION.USER.ADD.FROM_CSV,
      [<CwSocketActionModel>{
        fn: this.SocketAction.addUsers,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.ADD.FROM_LDAP,
      [<CwSocketActionModel>{
        fn: this.SocketAction.addUsers,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.ROLES.DELETE,
      [<CwSocketActionModel>{
        fn: this.SocketAction.deleteRoles,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.DELETE,
      [<CwSocketActionModel>{
        fn: this.SocketAction.deleteUsers,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.ROLES.UPDATE,
      [<CwSocketActionModel>{
        fn: this.SocketAction.updateRoles,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.ROLES.ADD,
      [<CwSocketActionModel>{
        fn: this.SocketAction.updateRoles,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.VCARD,
      [<CwSocketActionModel>{
        fn: this.SocketAction.updateVcard,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.STATUS, // === SOCKET_ACTION.DEVICE.STATUS
      [<CwSocketActionModel>{
        fn: this.SocketAction.updateStatus,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.DEVICE.STATUS_DEVICE, // === SOCKET_ACTION.DEVICE.STATUS
      [<CwSocketActionModel>{
        fn: this.SocketAction.updateStatusDevice,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.DEVICE.REGISTRATION,
      [<CwSocketActionModel>{
        fn: this.SocketAction.registrationStatusDevice,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.DEVICE.SCREEN_MESSAGE,
      [<CwSocketActionModel>{
        fn: this.SocketAction.screenMessage,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.DEVICE.LOGS.READY,
      [<CwSocketActionModel>{
        fn: this.SocketAction.logs,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.DEVICE.SETTING.UPDATED,
      [<CwSocketActionModel>{
        fn: this.SocketAction.updateDeviceSettings,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.UPDATE,
      [<CwSocketActionModel>{
        fn: this.SocketAction.updateUser,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.REGISTRATION,
      [<CwSocketActionModel>{
        fn: this.SocketAction.registrationUser,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    // 1910211120
    .set(
      SOCKET_ACTION.DEVICE.CREATE,
      [<CwSocketActionModel>{
        fn: this.SocketAction.Device.add,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.ENTITY.SUBSCRIPTION_UPDATE,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
  ;


  public id(item: any): any {
    let _id = item;
    try {
      if (CwIs.defined(item[this.ItemsKey])) {
        _id = item[this.ItemsKey];
      }
    } catch (error) {
      _id = item;
    }
    return _id;
  }

  updateLastLogin(jid: any, user: any): void {
    if (CwIs.defined(user)) {
      if (
        !user.resource &&
        'offline' === user.status &&
        true
      ) {
        this.defineFromVcardPropertyLastUpdate(
          jid,
          new Date().toUTCString(),
          user
        );
      }
    }
  }

  defineFromVcardPropertyLastUpdate(data: any, lastUpdate = null, user: any): void {
    let _id;
    let _lastUpdate;

    if (
      'string' === typeof data &&
      null != lastUpdate &&
      true
    ) {
      _id = data;
    } else if (
      this &&
      this.ItemsKey &&
      data &&
      data[this.ItemsKey] &&
      true
    ) {
      _id = data[this.ItemsKey];
    }

    if (
      'string' === typeof lastUpdate &&
      null != lastUpdate &&
      true
    ) {
      _lastUpdate = lastUpdate;
    } else if (
      this &&
      this.ItemsKey &&
      data &&
      data.lastUpdate &&
      true
    ) {
      _lastUpdate = data.lastUpdate;
    }

    if (CwIs.defined(_lastUpdate, _id)) {
      const itemToUpdate = user;
      itemToUpdate.lastUpdate = _lastUpdate;
      itemToUpdate.lastUpdateDate = CwDateTool.utcToDateTimeZone(_lastUpdate);
      itemToUpdate.lastUpdateWeb = CwDateTool.dateToString(
        itemToUpdate.lastUpdateDate
      );
      itemToUpdate.lastUpdateShow = true;
    }
  }

}

export const CwUsersBus = new EntityGetUsers({
  Socket: CwSocket,
  resource: false,
  // ItemsKey: 'email'
});

// 1910210910
export const CwUsersNoCacheBus = new EntityGetUsers({
  // Socket: CwSocket,
  resource: false,
  noCache: true,
  // ItemsKey: 'email'
});

export const CwDevicesBus = new EntityGetUsers({
  Socket: CwSocket,
  resource: true,
  // ItemsKey: 'email'
});

export const CwDevicesNoCacheBus = new EntityGetUsers({
  resource: true,
  noCache: true,
  // ItemsKey: 'email'
});

/**
 * @version 1911180842
 */
export const CwDevicesAndUsersUpdateBus = new EntityGetUsers();
