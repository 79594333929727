import {Component, Input} from '@angular/core';
import {PlansComponentClass} from '../../plans.class';
import {CwSpacePlanModel} from '../../../CollaborateSpaceWebTools/components/space/plan/cw-space-plan.model';
import {CwBillingPlans} from '../../../CollaborateSpaceWebTools/rest/billing/plans/plans-bus';

@Component({
  selector: 'app-plans-buy-summary',
  templateUrl: './plans-buy-summary.component.html',
})
export class PlansBuySummaryComponent
  extends PlansComponentClass {

  @Input() SelectedList: CwSpacePlanModel
  @Input() registration: boolean

  CwBillingPlans = CwBillingPlans;


  get getAvailable() {
    return true;
  }

  get getSelectedList() {
    return [
      this.AppPlansTool.Buy.Select.PlanNew,
      ...this.AppPlansTool.Buy.Select.MainNewList,
      ...this.AppPlansTool.Buy.Select.ExtraNews,
    ]
  }
}
