import {CwLogicSpacePlanBuyAdminClass} from '@cw/logic/space/plan/cw-logic-space-plan-buy-admin';


export class CwLogicSpacePlanClass extends CwLogicSpacePlanBuyAdminClass {

}

/**
 * @version 2007000000
 */
export const CwLogicSpacePlan: CwLogicSpacePlanClass = new CwLogicSpacePlanClass();
