<ng-container *ngIf="PlanNew.Visible && PlanNew.Visible.router">
  <ng-container *ngIf="PlanNew.Routers">
    <div class="mb-3 row justify-content-center">
      <ng-container *ngFor="let Router of PlanNew.Routers">
        <button
          appCw
          appCwForm
          type="button"
          class="text-uppercase appCwFormButton btn btn-sm btn-primary  mx-1 cw-plan-button py-2  2005280951 2102091735 {{getRouterCol}}"
          [ngStyle]="{'font-size.px': getRouterButtonFontSize}"
          [routerLink]="Router.router"
          [translate]="Router.name"
        ></button>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
