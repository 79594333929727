import {CwFunction} from '../model/model';
import {MessageService} from 'primeng/api';

export enum CwFeedTypeE {
  error = 'error',
  info = 'info',
  success = 'success',
  system = 'warn',
  warn = 'warn',
}

export type CwFeedTypeT = CwFeedTypeE;

export interface CwFeedI {
  detail: string;
  severity: string;
  summary: string;
  Data?: any;
}

export interface CwFeedMapItemI extends CwFeedI {
  id: string;
  timestamp: number;
  visible: boolean;
  // Number of time in milliseconds to wait before closing the message.
  life: number;
}

export interface CwFeedSetupI {
  hiddenTime: number;
  intervalId?: any;
  intervalTime?: number;
  visibleCondition?: CwFunction;

  Service?: MessageService;
}
