export class AppSettings {

  public static API_ENDPOINT = "/com.clearone.collaborate.services/rest/";

  public static ENTITIES_PATH = "/webconference/entidades/";

  public static ENTITY_IMAGE = "/imagenes/banner_top.";

  public static STRIPE_CREDIT_CARD = "STRIPE_CREDIT_CARD";
  public static STRIPE_PARTNER = "STRIPE_PARTNER";

  public static MAIN_PLAN = "Main";
  public static EXTRA_PLAN = "Extra";
  public static ORDER_PLAN = "Order";

  public static PHONE_CREDIT_10 = "PhoneCredit_10USD";

  public static MONTHLY = "month";
  public static YEARLY = "year";

  public static INITIAL_PLAN = "Premium_1";

  // public static STRIPE_PUBLIC_KEY = 'pk_test_KgvkUcafQsmRjdUR5XtWfcmF';
  public static STRIPE_PUBLIC_KEY = 'pk_test_UPlhYSVxvpnPHptB9x0koiVg';
  public static STRIPE_CLASS_COMPLETE = 'StripeElement--complete';

  // // 1904251312
  // public static RATES_AM = ["US", "CA", "BR", "AR", "CO", "PE", "MX", "CL"];
  // public static RATES_EU = ["RU", "BE", "ES", "IT", "UK", "FR", "DE", "SE"];
  // public static RATES_AF = ["AO", "CM", "EG", "KE", "MA", "LY", "NE", "ZA"];
  // public static RATES_AS = ["IN", "JP", "SA", "TW", "AE", "KR", "ID", "CN"];

  public static RATES_GLOBAL = ["CN","UK","US"];

  //PLATFORMS
  public static WINDOWS = 1000;
  public static MAC = 1001;

  //VALIDATIONS
  public static PASSWORD_PATTERN = "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[ !#$%&\\()*+,-./:;<=>?@[\\]^_`{|~}])[A-Za-z\\d !#$%&\\()*+,-./:;<=>?@[\\]^_`{|~}]{8,}$";
}
