/**
 * @version R59.6 - Ticket 1198 - Sometimes the language change doesn't work correctly
 */
export abstract class CoConfigureClass {

    abstract configPrefix;
    abstract configSave;
    PREFIX_DEFAULT = 'APP';
    //
    // get getPropertyNameList(): any[] {
    //   const propertyNameList = Object.getOwnPropertyNames(this).filter(
    //     propertyFilter =>
    //       CoString.startsWith(propertyFilter, (this.configPrefix) ? this.configPrefix : this.PREFIX_DEFAULT)
    //   );
    //   return (propertyNameList)
    //     ? propertyNameList
    //     : []
    //     ;
    // }
    //
    // init(): void {
    //   CoConfigBus40.addConfigure(this);
    // }
    //
    // configure(ConfigBus: any): void {
    // }
}
