import {CwRestRequestMinParametersI} from '../../../tool/rest/model';


export interface CwVoIPPayLoadI {
  disabledVoIP: boolean;
  owner: boolean;
}


export const CwVoIPPayLoadDefault: CwVoIPPayLoadI = {
  disabledVoIP: false,
  owner: false,
};


export interface CwVoIPDoI
  extends CwRestRequestMinParametersI, CwVoIPPayLoadI {
}
