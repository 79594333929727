import {UserBus} from '../user-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwUserValidationEmailDoI, CwUserValidationEmailParametersI} from './validation-email-model';

class UserValidationEmailBus extends UserBus {


    defineUrl: CwDefineUrl = (() =>
            CwUserValidationEmailBus.path + 'validationEmail' +
            '?token=' +
            CwUserValidationEmailBus.Parameters.token +
            '&password=' +
            encodeURIComponent(CwUserValidationEmailBus.Parameters.password) +
            ''
    );


    do: CwFunction = ((Parameters: CwUserValidationEmailDoI) =>
            CwUserValidationEmailBus.restPost(Parameters)
    );


    Parameters: CwUserValidationEmailParametersI;


}

export const CwUserValidationEmailBus = new UserValidationEmailBus();

// // 1904101050
// @Injectable()
// export class RegistrationService extends BasicService {
//
//     url = AppSettings.API_ENDPOINT + 'user/registration';
//
//     constructor(private http: Http) {
//         super();
//     }
//
//
//     registerUser(token: string, password: string): Observable<Result> {
//
//         const headers = new Headers({'Content-type': 'application/json'});
//         const options = new RequestOptions({headers: headers});
//         return this.http.post(this.url +
//         '?token=' + token
//         + '&password=' + encodeURIComponent(password)
//         , options)
//             .map(this.extractData.bind(this))
//             .catch(this.handleError);
//     }
//
// }
