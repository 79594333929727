import {HttpClient} from '@angular/common/http';
import {CwFunction, CwSetupI} from '../../model/model';
import {CwBusClass} from '../../model/bus';

// 1912131221
export interface CwRestRequestActionModel {
  successActionCustom?: CwFunction;
  errorActionCustom?: CwFunction;
  errorRestActionCustom?: CwFunction;
  Simulation?: {
    success: boolean;
    error: boolean;
    errorRest: boolean;
  };
}

export interface CwRestRequestMinParametersI extends CwRestRequestActionModel {
  PayLoadCustom?: any;
  token?: string;
  jwt?: string;
  Simulation?: {
    success: boolean;
    error: boolean;
    errorRest: boolean;
  };
}

export interface CwToolRestParam {
  url: string;
  Callback: CwCallback;
  Data?: any;
}

export interface CwCallbackAction {
  action: CwFunction;
  param?: any;
  Component?: any;
}

export interface CwCallback {
  error?: CwCallbackAction;
  success: CwCallbackAction;
  system?: CwCallbackAction;
  closed?: CwCallbackAction;
}

export interface CwRestSetup extends CwSetupI {
  HttpClient?: HttpClient;
  url: string;
}

export enum CwRestCode {
  ok = 0,
  errorParameter = -2,
  errorTokenNotValid = -48,
  errorTokenNotExpired = -58,
}

export type CwRestCodeT = CwRestCode;

// 1907110924
export  interface CwDoParametersI extends CwRestRequestMinParametersI{
  token: string;
}

export interface CwRestRequestMinParametersI {
  successActionCustom?: CwFunction;
  errorActionCustom?: CwFunction;
  errorRestActionCustom?: CwFunction;
  // 1907110924
  PayLoadCustom?: any;
  // 1907110924
  token?: string;
}

// 1904101050
export interface CwRestRequestParametersI extends CwRestRequestMinParametersI {
  Bus: CwBusClass;
  Parameters?: any;
}

// 1904101050
export type CwRestRequestParametersT = CwRestRequestParametersI;

// 1906261144
export interface PayLoadCustomI {
  TransactionId: string;
}
