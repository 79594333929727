// 1911281603
export const CW_DATE_TOOL = {
  // 'MM/dd/yyyy HH:mm:ss'
  // https://momentjs.com/docs/#/displaying/format/
  FORMAT_MOMENT: {
    'dd': 'DD',
    'yyyy': 'YYYY',
  }
};


export enum DateDurationUnitEnum {
  second = 'seconds',
}

export type DateDurationUnit = DateDurationUnitEnum.second;

export enum DateDurationFormatEnum {
  humanize = 'h',
  tech = 'e',
  time = 't',
  web = 'w'
}

export type DateDurationFormat =
  DateDurationFormatEnum.humanize |
  DateDurationFormatEnum.tech | /* ToDo: DateDurationFormat.tech */
  DateDurationFormatEnum.time |
  DateDurationFormatEnum.web
  ;

export interface DateDurationI {
  duration: any;
  unit?: DateDurationUnit;
  format?: DateDurationFormat;
}

// 2002281031-4
export interface DateAddModel {
  Source: Date;
  days?: number;
  months?: number;
  years?: number;
}
