import {CwFunction, CwMap} from '../../../model/model';
import {CwSocket} from '../../socket-management';
import {CwIs} from '../../../tool/tool-is';
import {CwUtil} from '@tool/util/cw-tool-util';
import {SOCKET_ACTION} from '../../socket-model';
import {SocketSendBus} from '../send-bus';
import {CwSocketSendMessageI} from '../send-model';
import {CwInputType} from '../../../components/input/cw-input-model';

class DeviceSettingsBus extends SocketSendBus {

  // TYPE = {
  //   BOOL: 'Bool',
  //   DATE: 'Date',
  //   LIST: 'Generic',
  //   LIST_BY_KEY: 'Combo',
  //   NUMBER: 'Number',
  //   PASSWORD: 'Password',
  //   RANGE: 'Slider',
  //   READ_ONLY: 'ReadOnly',
  //   SAVE: 'Save',
  //   TEXT: 'Text',
  //   TIME: 'Time'
  // };

  Items: CwMap<any> = new Map();
  ItemsList: any[] = [];
  ItemsKey = 'group';
  jid: string;
  screenMessage: any[] = [];

  SettingLastNotDepanded: any;
  SettingsDepandedList: any[];

  DefineSetting: CwFunction[] = [
    // depanded => required
    ((Setting) => {
      Setting.valdidateRequired = Setting.required;
    }),
    // type = time
    ((Setting) => {
      if (
        Setting &&
        Setting.type === CwInputType.TIME &&
        true
      ) {
        Setting.time = {
          hour: 12,
          minute: 0
        };
        const newDate =
          new Date(
            '1970-01-01T' +
            Setting.value.replace(/[ap]m$/i, '')
          );

        if (newDate != null) {
          if (Setting.value.search(/pm$/i) >= 0) {
            newDate.setHours(newDate.getHours() + 12);
          }
          Setting.time.hour = newDate.getHours();
          Setting.time.minute = newDate.getMinutes();
        }
        Setting.meridian = true;
      }
    }),
    // feedback
    ((Setting) => {
      if (Setting) {
        Setting.FeedbackList = [];
      }
    }),
    // validation
    ((Setting) => {
      if (Setting) {
        Setting.valid = true;
        Setting.ValidationList = [];
      }
    }),
    ((Setting) => {
      if (Setting) {
        Setting.id = CwUtil.uuid();
      }
    }),
    ((Setting) => {
      if (Setting) {
        Setting.valuePrevious = Setting.value;
      }
    })
  ];

  DefineProperties: CwFunction[] = [
    ((Properties) => {
      if (Properties) {
        Properties.cwKey = (
          this.ItemsKey &&
          Properties[this.ItemsKey] &&
          true
        )
          ? Properties[this.ItemsKey]
          : CwUtil.uuid()
        ;
      }
    }),
    // To Menu
    ((Properties) => {
      if (Properties) {
        Properties.label = Properties.group;
        Properties.id = Properties.group;
        Properties.href = '#' + Properties.id;
      }
    }),
    // Settings
    ((Properties) => {
      if (Properties && Properties.settings) {
        this.defineSettingsList({
          SettingsList: Properties.settings,
          group: Properties.group,
          jid: Properties.jid,
        });
      }
    })
  ];

  ItemsSet = (
    (Data: any) => {
      // 1904240944
      if (
        Data &&
        Data.cwKey &&
        true
      ) {
        this.Items.set(Data.cwKey, Data);
      }
      this.ItemsList.push(Data);
    }
  );

  jidConnected: string;

  do: CwFunction = ((Param: { jid: string }) => {
    if (
      Param &&
      Param.jid &&
      CwSocket &&
      this.jidConnected !== Param.jid &&
      true
    ) {
      this.jidConnected = Param.jid;
      CwSocket.send({
        Message: <CwSocketSendMessageI>{
          id: CwUtil.uuid(),
          resources_jid: [Param.jid],
          action: SOCKET_ACTION.DEVICE.ADMIN.CONNECTED,
        }
      })
    }
  });

  /**
   * @version 1912170900
   */
  disconnected: CwFunction = (() => {
    if (
      this.jidConnected &&
      CwSocket &&
      true
    ) {
      this.clearScreenMessages();
      CwSocket.send({
        Message: <CwSocketSendMessageI>{
          id: CwUtil.uuid(),
          resources_jid: [this.jidConnected],
          action: SOCKET_ACTION.DEVICE.ADMIN.DISCONNECTED,
        }
      })
      this.jidConnected = undefined;
    }
  });

  response: CwFunction = ((Data) => {
    if (
      Data &&
      Data.resources_jid &&
      Data.resources_jid.length === 1 &&
      Data.content &&
      // If content.response is undefined but with valid settings mapping is valid.
      // This case happens when user is on video details view of an offline device and not admin_connected
      // and then it becomes available
      (!Data.content.response ||
        'OK' === Data.content.response.toUpperCase()) &&
      Data.content.cpro_settings &&
      Data.content.cpro_settings.fullsettings &&
      true
    ) {
      this.jid = Data.resources_jid[0];
      this.defineList({
        GroupsList: Data.content.cpro_settings.fullsettings,
        jid: Data.resources_jid[0]
      });
      if (this.responseCallback) {
        this.responseCallback();
      }
    } else {
      console.error('1908011133', Data)
    }
  });

  clear(): void {
    this.Items.clear();
    CwUtil.clear(this.ItemsList);
  }

  defineProperties(Properties: any) {
    if (Properties) {
      if (this.DefineProperties) {
        this.DefineProperties.forEach(DefineProperty => {
          DefineProperty(Properties);
        });
      }
    }
  }

  defineSetting(Setting: any) {
    if (Setting) {
      if (this.DefineSetting) {
        this.DefineSetting.forEach(defineSetting => {
          defineSetting(Setting);
        });
      }
    }
  }

  defineSettingsList(Param: {
    SettingsList: any[],
    group: string,
    jid: string,
  }) {
    let settingPreviousIsDepanded = false;
    let SettingsSetList = [];
    this.SettingsDepandedList = [];
    if (Param && Param.SettingsList) {
      // tslint:disable-next-line:forin
      for (const index in Param.SettingsList) {
        const Setting = Param.SettingsList[index];
        Setting.group = Param.group;
        Setting.jid = Param.jid;
        if (Setting.depanded) {
          SettingsSetList.push(Setting);
        }
        if (
          settingPreviousIsDepanded &&
          !Setting.depanded &&
          true
        ) {
          this.SettingsDepandedList.push({
            index,
            Setting: this.SettingLastNotDepanded,
            SettingsSetList,
          });
          SettingsSetList = [];
        }
        this.defineSetting(Setting);
        settingPreviousIsDepanded = Setting.depanded;

        if (!Setting.depanded) {
          this.SettingLastNotDepanded = Setting;
        }
      }
    }
    // end position
    if (settingPreviousIsDepanded) {
      this.SettingsDepandedList.push({
        index: Param.SettingsList.length,
        Setting: this.SettingLastNotDepanded,
        SettingsSetList,
      });
      SettingsSetList = [];
    }

    while (this.SettingsDepandedList.length > 0) {
      const SettingSave = this.SettingsDepandedList.pop();
      const SettingNext = Param.SettingsList[SettingSave.index];

      if (
        !SettingNext ||
        (
          SettingNext &&
          SettingNext.type !== CwInputType.SAVE &&
          true
        ) ||
        false
      ) {
        Param.SettingsList.splice(
          SettingSave.index,
          0,
          {
            type: CwInputType.SAVE,
            name: 'Save ' + SettingSave.Setting.name,
            SettingsSetList: SettingSave.SettingsSetList,
          }
        )
      }
    }
  }

  defineList(Param: {
    GroupsList: any[],
    jid: string,
  }) {
    this.clear();
    for (const Item of Param.GroupsList) {
      if (
        CwIs.object(Item) &&
        this.defineProperties &&
        true
      ) {
        Item.jid = Param.jid;
        this.defineProperties(Item);
      }
      this.ItemsSet(Item);
    }
  }

  clearScreenMessages(): void {
    CwUtil.clear(this.screenMessage);
  }

  addScreenMessageWithTimeout(message: string) {
    CwDeviceSettingsBus.screenMessage.push(message);
    setTimeout(() => {
      const indexOfItem = CwDeviceSettingsBus.screenMessage.indexOf(message, 0);
      if (indexOfItem > -1) {
        CwDeviceSettingsBus.screenMessage.splice(indexOfItem, 1);
      }
    }, 10 * 1000);
  }

}

export const CwDeviceSettingsBus = new DeviceSettingsBus();
