import {CwScrollGoToModel, CwScrollSetupModel} from '@tool/scroll/model';
import {PageScrollInstance, PageScrollService} from 'ngx-page-scroll';
import {CwUtil} from '@tool/util/cw-tool-util';

class CwScrollToolClass implements CwScrollSetupModel {

  Document: any;
  PageScrollService: PageScrollService;

  goTo = (Params: CwScrollGoToModel) => {
    if (Params) {
      if (Params.Setup) {
        this.setup(Params.Setup)
      }
      if (Params.id) {
        const pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(
          this.Document,
          Params.id
        );
        this.PageScrollService.start(pageScrollInstance);
      }
    }
  };
  setup = (Params: CwScrollSetupModel) => {
    if (Params) {
      CwUtil.setProperties(Params, this);
    }
  };
}

/**
 * @version 2006110844
 */
export const CwScrollTool: CwScrollToolClass = new CwScrollToolClass();
