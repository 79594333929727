import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CwMapItemI} from '@cw/model/model';
import {AppWebTool} from '../../app-web.tool';
import {AppCw} from '../../app.class';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
/**
 * @version 1905211103
 */
export class TermsComponent extends AppCw {

  constructor(private _ActivatedRoute: ActivatedRoute) {
    super();
  }

  OnInit() {

    AppWebTool.Title.main = 'END USER LICENSE AGREEMENT (“EULA”)';

    this.App.Literal.change(<CwMapItemI>{
      cwKey: this.App.Web.getParam({
        ActivatedRoute: this._ActivatedRoute,
        paramName: 'language'
      })
    })
  }

}
