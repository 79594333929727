<div class="d-flex flex-row align-items-center bg-light p-2">

  <div class="d-flex text-left w-50 h6"
  ><span translate
         appTextFit15
         class="text-truncate"
  >Country</span>
  </div>

  <div class="d-flex  w-25 h6"
  >
    <small
      class="text-truncate"
    >{{'Landline'|translate}}</small>
  </div>

  <div class="d-flex  w-25 h6"
  >
    <small
      class="text-truncate"
    >{{'Mobile'|translate}}</small>
  </div>

</div>
