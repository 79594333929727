import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwRestRequestMinParametersI} from '../../tool/rest/model';
import {UserBus} from '../user-bus';
import {CwGetVCardUsersI} from './get-v-cards-user-bus-model';
import {CwTrust} from '../../trust/trust';
import {CwIs} from '../../tool/tool-is';
import {CwUsersBus} from '../../rest/entity/get-users/bus';


class UserGetVCardUsersBus extends UserBus {

  ItemsKey = 'jid';

  DefineProperties: CwFunction[] = [
    // avatar
    (Properties: any) => {
      this.image(Properties, Properties);
      this.avatar(Properties);
    },
  ];

  defineUrl: CwDefineUrl = (() => {
    return CwUserGetVCardUsersBus.path + 'getVcardUsers';
  });

  do: CwFunction = ((Param: CwGetVCardUsersI) => {

    const jids = [];
    Param.UsersList.forEach(Item => {
      jids.push(Item[this.ItemsKey]);
    });

    CwUserGetVCardUsersBus.restPut({
      ...<CwRestRequestMinParametersI>Param,
      PayLoadCustom: {
        jids
      },
      Bus: CwUserGetVCardUsersBus,
      token: CwTrust.token,
    });
  });

  public id(item: any): any {
    let _id = item;
    try {
      if (CwIs.defined(item[this.ItemsKey])) {
        _id = item[this.ItemsKey];
      }
    } catch (error) {
      _id = item;
    }
    return _id;
  }

  public avatar(itemVCard: any) {
    CwUsersBus.defineAvatarFromVcard(itemVCard);
  }

  public image(item: any, toWeb: any) {
    if (CwIs.defined(item, toWeb)) {
      if (
        CwIs.stringNotEmpty(item.typePhoto) &&
        CwIs.stringNotEmpty(item.binPhoto)
      ) {
        // item.photoSrc = CoFileService.imageUriByType(item.typePhoto, item.binPhoto);
        toWeb.photoUrl = 'url(' + this.imageUriByType(item.typePhoto, item.binPhoto) + ')';
        toWeb.isAvatar = true;
      } else {
        toWeb.isAvatar = false;
      }
    }
  }

  public imageUriByType(type: string, data: string): string {
    return (
      undefined !== type &&
      '' !== type &&
      undefined !== data &&
      '' !== data &&
      true
    )
      ? 'data:' + type + ';base64,' + data
      : undefined
      ;
  }
}


/**
 * @deprecated Used instead CwGetVcardBus
 * @version 1907291105
 */
export const CwUserGetVCardUsersBus = new UserGetVCardUsersBus();

