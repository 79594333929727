<!--1907030844-->
<div
    *ngIf="App.downVisible"
    class="bg-light"
>

  <div class="text-center pb-4">
    <div class="h-auto">
      <h3 class="font-additional text-dark text-center">{{ 'The download process should start automatically.' | translate}}</h3>
    </div>
    <div class="h-auto">
      <h3
          class="font-additional text-dark text-center"
      >{{ 'If the download process doesn’t start,' | translate}}&nbsp;
        <a (click)="App.Protocol.downloadLinked()"
           class="text-dark font-weight-bold"
        >{{ 'click here' | translate}}
        </a>
      </h3>
    </div>
  </div>
  <!--    1904251312-->
  <div class="d-flex justify-content-center flex-row align-items-center bg-light">
    <figure class="figure p-3">
      <img src="assets/images/step-1.png">
      <figcaption
          class="figure-caption "
      >
        <h6>
          <br/>
          1. <span translate>Run the Installer</span>
        </h6>
      </figcaption>
    </figure>
    <figure class="figure p-3">
      <img src="assets/images/step-2.png">
      <figcaption
          class="figure-caption"
      >
        <h6>
          <br/>
          2. <span translate>Click Yes</span>
        </h6>
      </figcaption>
    </figure>
    <figure class="figure p-3">
      <img src="assets/images/step-3.png">
      <figcaption
          class="figure-caption"
      >
        <h6>
          <br/>
          3. <span translate>Follow the Instructions</span>
        </h6>
      </figcaption>
    </figure>
  </div>

  <div class="d-flex justify-content-center flex-row align-items-center bg-light pb-5">
    <a routerLink="/home">
      <span translate>Back Home</span>
    </a>
  </div>

</div>
