import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {App} from '../../app';
import {CwProtocol} from '../../CollaborateSpaceWebTools/tool/protocol/tool-protocol';
import {CwWebRtcTool} from '../../CollaborateSpaceWebTools/tool/web-rtc/web-rtc.tool';
import {AppWebTool} from '../../app-web.tool';

@Component({
  selector: 'app-connection-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
/**
 * @version 2002241005
 */
export class ConnectionSelectionComponent
  implements OnInit, OnDestroy, AfterViewInit {

  Actions = {
    join: () => {
      CwProtocol.SelectConnection.web();
    },
  };

  Visible = {
    webRtcAvailable: false,
    webRtcUnavailable: false,
  }

  setup = () => {
    if (CwWebRtcTool.available) {
      this.Visible = {
        webRtcAvailable: true,
        webRtcUnavailable: false,
      };
    } else {
      this.Visible = {
        webRtcAvailable: false,
        webRtcUnavailable: true,
      };
    }
    AppWebTool.Title.main = App.Protocol.SelectConnection.title;
    AppWebTool.Title.secondary = null;
    AppWebTool.Title.startHereVisible = false;
  }

  ngOnInit() {
    CwWebRtcTool.subscribe(this.setup);
  }

  ngOnDestroy(): void {
    this.Visible = {
      webRtcAvailable: false,
      webRtcUnavailable: false,
    }
  }

  ngAfterViewInit(): void {
    CwProtocol.Browser.Firefox.exec();
  }

}
