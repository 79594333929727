/**
 * @version R69.1 - Track 45488 - Admin - Resources - Settings - Menu highlight disappears. 1810021747
 */
class CoAppLoaderWidgetBus40Class {

  display = false;

  public show(): void {
    if (!this.display) {
      this.display = true;
    }
  }

  public hide(): void {
    if (this.display) {
      this.display = false;
    }
  }

}

export const CoAppLoaderWidgetBus40: CoAppLoaderWidgetBus40Class = new CoAppLoaderWidgetBus40Class();
