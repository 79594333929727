import {CwLogicSpacePlanBuyClass} from '@cw/logic/space/plan/cw-logic-space-plan-buy';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwLogicSpacePlanDefineModel} from '@cw/logic/space/plan/cw-logic-space-plan.model';
import {CW_SPACE_SUBSCRIPTION_TOOL} from '@space/subscription/tool-space-subscription-model';

export class CwLogicSpacePlanBuyBasicClass extends CwLogicSpacePlanBuyClass {
  constructor() {
    super();
    this.Buy.Basic = {
      clear: (
        () => {
          CwUtil.clear(this.Buy.Basic.Plan);
        }
      ),
      define: (
        (IO: CwLogicSpacePlanDefineModel) => {
          this.Buy.clear();
          this.Buy.Basic.clear();
          CwUtil.clear(IO.NewList);
          CwUtil.exec(this.Buy.Basic.Define, IO);
        }
      ),
      Define: {
        // First define plans
        // Finally PlanList to "Select" and "Summary"

        _100_basic1: (
          (IO: CwLogicSpacePlanDefineModel): void => {
            this.Buy.Data.Basic1 = {
              ...CwUtil.clone(this.Plan.Basic1),
              _order: 101,
              // 2007311046
              interval: CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API,
              minimized: false,
              quantity: 1,
            };
          }
        ),

        _300_pro5: (
          (IO: CwLogicSpacePlanDefineModel): void => {
            this.Buy.Data.Pro5 = {
              ...CwUtil.clone(this.Plan.Pro5),
              _order: 305,
              minimized: true,
              quantity: 0
            };
          }
        ),

        _500_mainList: (
          (IO: CwLogicSpacePlanDefineModel): void => {
            this.addMain(
              this.Buy.Data.Basic1,
              this.Buy.Data.Pro5,
            );
            this.addBasicAdd();
            this.addProAdd();
          }
        ),
        _600_extraList: (
          (IO: CwLogicSpacePlanDefineModel): void => {
            this.add(
              this.Buy.Data.ExtraList,
              this.Plan.Cloud100,
              this.Plan.Gateway,
              CwUtil.clone(this.Plan.ClassroomWeb),
            );
            this.addCloudAdd();
          }
        ),
        _700_new: (
          (IO: CwLogicSpacePlanDefineModel): void => {
            this.add(
              IO.NewList,
              this.Plan.Basic1,
              this.Plan.Basic5,
              this.Plan.Basic10,

              this.Plan.Pro5,
              this.Plan.Pro10,
              this.Plan.Pro50,
              this.Plan.Pro100,

              this.Plan.Cloud100,
              this.Plan.Cloud500,
              this.Plan.Gateway,
              this.Plan.Classroom,
              this.Plan.ClassroomExpansion,
              this.Plan.Webinar,
              this.Plan.WebinarExpansion,
            )
          }
        ),
      },
      Plan: {


        Classroom: {},
        ClassroomWeb: {},
        Cloud: {},
        Gateway: {},
        Webinar: {},
        WebinarWeb: {},

      },
    };

  }
}
