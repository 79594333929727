import {BillingBus} from '../billing-bus';
import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwRestRequestParametersI} from '../../../tool/rest/model';
import {CwOnP} from '../../../tool/on-premise/tool-on-premise';

/**
 * @version 2003041211
 */
class BillingOnPremiseBus extends BillingBus {

  isOnPremise = false;
  isProduction = false;

  DefineProperties: CwFunction[] = [
    (Properties: any) => {
      if (Properties) {
        this.isOnPremise = Properties.onPremise;
        CwOnP.is = Properties.onPremise;
        // // TODO-DEV
        // this.isOnPremise = true;
        // CwOnP.is = true;

      }
    },
    (Properties: any) => {
      if (Properties) {
        this.isProduction = Properties.productionEnviroment;
      }
    },

  ]


  defineUrl: CwDefineUrl = (() => this.path + 'onPremise');
  do: CwFunction = ((Params = {}) => {
    this.restGet(<CwRestRequestParametersI>{
      ...Params,
      Bus: this,
    })
  });


}

export const CwOnPremiseBus: BillingOnPremiseBus = new BillingOnPremiseBus();
