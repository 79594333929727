import {CwSpacePlanMinimalSetupModel} from '@cw/components/space/plan/minimal/cw-space-plan-minimal.model';
import {CwFunction} from '@cw/model/model';

export enum CwSpaceBuyTemplateModel {
  select,
  registration,
  done,
}

export interface CwSpaceBuyStepModel {
  action: CwFunction;
  continueLabel: string;
  disabled: CwFunction;
  error: CwFunction;
  label: string;
  next?: CwFunction;
  template: CwSpaceBuyTemplateModel;
}

export interface CwSpaceBuySetupModel {

  Progress: {
    stepIndex: number;
    StepList: CwSpaceBuyStepModel[];
  }


  Registration?: {
    InputCountry: any;
    InputState: any;
    InputSet: any[];
  }


  SetupExtra: CwSpacePlanMinimalSetupModel;
  SetupMain: CwSpacePlanMinimalSetupModel;
  Summary: {
    ItemList: any[];
  }
}
