<div class="d-flex flex-row align-items-center p-2">
  <div class="d-flex  w-50  mr-3 text-truncate"
  >
    <small
      xxx-2005280951
      class="d-inline-block text-truncate  delta-indicator"
    >
      {{Call.countryName|translate}}</small>
  </div>
  <small
    xxx-2005280951
    class="d-flex  w-25 text-truncate  delta-indicator"
  >
    {{Call.fix | currency : 'USD'}}
  </small>
  <small
    xxx-2005280951
    class="d-flex  w-25 text-truncate  delta-indicator"
  >
    {{Call.mobile | currency : 'USD'}}
  </small>
</div>
