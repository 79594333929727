import {Directive, ElementRef, HostListener, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CwWeb} from '../tool/web/tool-web';
import fitty from 'fitty';

@Directive({
  selector: '[appCw]'
})
/**
 * @version 1906111045
 */
export class CwDirective implements OnInit, OnChanges {

  AppCw = {
    blockCopy: 'appCwBlockCopy',
    blockPaste: 'appCwBlockPaste',
    focus: 'appCwFocus',
    form: 'appCwForm',
    text: 'appCwText',
    textMax: 'appCwTextMax',
    textMin: 'appCwTextMin',
  };

  HtmlElement: any;

  set setHtmlElement(_HtmlElement: any) {
    this.HtmlElement = _HtmlElement;
    if (this.HtmlElement) {
      this.HtmlElement.has = ((name: string): boolean => {
        return CwWeb.htmlHas({name, HtmlElement: this.HtmlElement});
      });
      // htmlClassAdd
      this.HtmlElement.classAdd = ((ClassList: string[]) => {
        CwWeb.htmlClassAdd({ClassList, HtmlElement: this.HtmlElement});
      });
    }
  }

  ClassCommon = [
    'form-control',
    'form-control-sm',
    'bg-light'
  ];

  Class = {
    button: [
      'btn',
      'btn-sm',
      'btn-primary',
      'text-uppercase',
      'appCwFormButton',
      'px-0',
      'px-sm-2',
      // 2005291520
      'app-btn'
    ],
    form: [
      'was-validated',
      'my-2'
    ],
    input: [...this.ClassCommon],
    select: [...this.ClassCommon],
  };

  // 1906131311
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (
      this.HtmlElement &&
      this.HtmlElement.has(this.AppCw.blockPaste) &&
      true
    ) {
      e.preventDefault();
    }
  }


  // 1906131311
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if (
      this.HtmlElement &&
      this.HtmlElement.has(this.AppCw.blockCopy) &&
      true
    ) {
      e.preventDefault();
    }
  }

  // 1906131311
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if (
      this.HtmlElement &&
      this.HtmlElement.has(this.AppCw.blockCopy) &&
      true
    ) {
      e.preventDefault();
    }
  }

  constructor(Element: ElementRef) {
    if (
      Element &&
      Element.nativeElement &&
      true) {
      this.setHtmlElement = Element.nativeElement;
      this.appCwText();
      this.appCwForm();
    }
  }

  ngOnInit() {
    this.appCwAutofocus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach(
      key => {
        this.appCwAutofocusOnChages(key);
      });
  }


  appCwAutofocusOnChages(key: string): void {
    if (
      key &&
      this.AppCw.focus.toLowerCase() === key.toLowerCase() &&
      true
    ) {
      this.appCwAutofocus();
    }
  }

  appCwAutofocus(): void {
    if (
      this.HtmlElement &&
      this.HtmlElement.has(this.AppCw.focus) &&
      this.HtmlElement.tagName &&
      true
    ) {
      setTimeout(() => {
        if ('input' === this.HtmlElement.tagName.toLowerCase()) {
          this.HtmlElement.focus();
        } else {
          const _Inputs = this.HtmlElement.getElementsByTagName('input');
          if (_Inputs) {
            for (const _InputItem of _Inputs) {
              if (
                _InputItem &&
                // !_InputItem.disabled &&
                _InputItem.className &&
                _InputItem.className !== 'd-none' &&
                true
              ) {
                _InputItem.focus();
                break;
              }
            }

          }
        }

      }, 500);
    }
  }


  appCwForm(): void {
    if (
      this.HtmlElement &&
      this.HtmlElement.has(this.AppCw.form) &&
      this.HtmlElement.tagName &&
      this.Class[this.HtmlElement.tagName.toLowerCase()] &&
      true
    ) {
      this.HtmlElement.classAdd(this.Class[this.HtmlElement.tagName.toLowerCase()]);
    }
  }

  appCwText() {
    if (
      this.HtmlElement &&
      this.HtmlElement.has(this.AppCw.text) &&
      true
    ) {
      const options = {
        ...TextFitDefault
      };
      if (this.HtmlElement.has(this.AppCw.textMax)) {
        options.maxSize = this.HtmlElement.getAttribute(this.AppCw.textMax.toLowerCase());
      }
      if (this.HtmlElement.has(this.AppCw.textMin)) {
        options.minSize = this.HtmlElement.getAttribute(this.AppCw.textMin.toLowerCase());
      }
      fitty(this.HtmlElement, options);
    }
  }

}

// @Directive({selector: '[appCwForm]'})
// export class CwFormDirective {
//
//   ClassCommon = [
//     'form-control',
//     'form-control-sm',
//     'bg-light'
//   ];
//
//   Class = {
//     button: [
//       'btn',
//       'btn-sm',
//       'btn-primary',
//       'text-uppercase',
//       'appCwFormButton',
//     ],
//     form: ['was-validated'],
//     input: [...this.ClassCommon],
//     select: [...this.ClassCommon],
//   };
//
//   constructor(Element: ElementRef) {
//     if (
//         Element &&
//         Element.nativeElement &&
//         Element.nativeElement.tagName &&
//         this.Class[Element.nativeElement.tagName.toLowerCase()] &&
//         true) {
//       CwWeb.classAdd(
//           this.Class[Element.nativeElement.tagName.toLowerCase()],
//           Element.nativeElement
//       );
//     } else {
//     }
//   }
//
// }


// @Directive({selector: '[appTextFit]'})
// /**
//  * @version 1904251312
//  * @description
//  * https://github.com/rikschennink/fitty
//  * https://v6.angular.io/guide/attribute-directives
//  */
// export class TextFitDirective {
//
//   constructor(Element: ElementRef) {
//     const options = {
//       ...TextFitDefault
//     };
//     if (Element.nativeElement.getAttribute('data-cw-max')        ) {
//       options.maxSize = Element.nativeElement.getAttribute('data-cw-max');
//     }
//     if (Element.nativeElement.getAttribute('data-cw-min')        ) {
//       options.minSize = Element.nativeElement.getAttribute('data-cw-min');
//     }
//     fitty(Element.nativeElement, options);
//   }
//
// }
//
// export interface TextFitI {
//   // Default: 512	The maximum font size in pixels
//   maxSize?: number;
//   // Default: 16	The minimum font size in pixels
//   minSize?: number;
//   // Default: true	Wrap lines when using minimum font size.
//   multiLine?: boolean;
// }
//
// export const TextFitDefault: TextFitI = {
//   maxSize: 512,
//   minSize: 8,
//   multiLine: true,
// }


// @Directive({selector: '[appTextFit15]'})
// /**
//  * @version 6.3.0
//  * @deprecated Use appTextFit (data-cw-max=15,data-cw-min) instead
//  * @description
//  * https://github.com/rikschennink/fitty
//  * https://v6.angular.io/guide/attribute-directives
//  */
// export class TextFit15Directive {
//
//   constructor(el: ElementRef) {
//     fitty(el.nativeElement, {
//           ...TextFitDefault,
//           maxSize: 15,
//         }
//     );
//   }
// }


export interface TextFitI {
  // Default: 512	The maximum font size in pixels
  maxSize?: number;
  // Default: 16	The minimum font size in pixels
  minSize?: number;
  // Default: true	Wrap lines when using minimum font size.
  multiLine?: boolean;
}

export const TextFitDefault: TextFitI = {
  maxSize: 512,
  minSize: 8,
  multiLine: true,
};
