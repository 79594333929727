class DataSignUpBus {
    jid: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    action: string;
    applicant: string;
    companyName: string;
    // 1904251312
    savingData = false;
    error = false;
    errorDescription: string;
    coupon = undefined;
}

export const AppDataSignBus = new DataSignUpBus();
