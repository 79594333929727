import {CwLogicSpaceModel} from '@cw/logic/space/cw-logic-space.model';
import {CW_SPACE_SUBSCRIPTION_TOOL, CW_SPACE_SUBSCRIPTION_TYPE_MODEL} from '@space/subscription/tool-space-subscription-model';
import {CwSpacePlanModel} from '@cw/components/space/plan/cw-space-plan.model';
import {CwFormatTool} from '@tool/format/tool-format';
import {CwUtil} from '@tool/util/cw-tool-util';

export interface CwLogicSpacePlanModel extends CwLogicSpaceModel {
  mainPlan: CW_SPACE_SUBSCRIPTION_TYPE_MODEL;
}

export interface CwLogicSpacePlanExpansionProModel {
  Plan: any;
  ExtraList: any[];
  SummaryExtraList: any[];
}


export interface CwLogicSpacePlanDefineModel {
  ApiList: any[],
  ExtraList: any[],
  MainList: any[],
  NewList: any[],
}

export const APP_PLAN_STANDARD: CwSpacePlanModel = {
  amount: 0,
  Date: null,
  dateFormat: CwFormatTool.Date.standard,
  id: CW_SPACE_SUBSCRIPTION_TOOL.PACKAGE.STANDARD,
  id_collaborate_package: CW_SPACE_SUBSCRIPTION_TOOL.PACKAGE.STANDARD,
  interval: CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.API,
  interval_count: 1,
  name: 'Standard',
  quantity: 1,
  type: CW_SPACE_SUBSCRIPTION_TOOL.TYPE.MAIN
}

export class CwLogicSpacePlanModel {

  Buy: any;

  IO: CwLogicSpacePlanDefineModel = {
    ApiList: undefined,
    NewList: undefined,
    ExtraList: undefined,
    MainList: undefined,
  }

  Plan = {
    Basic: <any>{},
    Basic1: <any>{},
    Basic5: <any>{},
    Basic10: <any>{},
    BasicWeb: <any>{},

    Pro: <any>{},
    Pro5: <any>{},
    Pro10: <any>{},
    Pro50: <any>{},
    Pro100: <any>{},
    ProExpansionAdd: <any>{},
    ProWeb: <any>{},

    Classroom: <any>{},
    ClassroomAdd: <any>{},
    ClassroomExpansion: <any>{},
    ClassroomWeb: <any>{},

    Cloud100: <any>{},
    Cloud500: <any>{},
    CloudAdd: <any>{},

    Gateway: <any>{},

    Webinar: <any>{},
    WebinarAdd: <any>{},
    WebinarExpansion: <any>{},
    WebinarWeb: <any>{},

    MainList: [],
    ExtraList: [],

  }


  Define = {
    _000_io: (
      (IO: CwLogicSpacePlanDefineModel) => {
        CwUtil.setProperties(IO, this.IO);
      }
    ),
    // 200722000000
    basic1: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Basic1 = CwUtil.clone(
          IO.MainList.filter(this.isBasic1)[0]
        );
        this.Plan.Basic1.minimized = true;
      }
    ),
    basic5: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Basic5 = CwUtil.clone(
          IO.MainList.filter(this.isBasic5)[0]
        );
        this.Plan.Basic5.minimized = true;
      }
    ),
    basic10: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Basic10 = CwUtil.clone(
          IO.MainList.filter(this.isBasic10)[0]
        );
        this.Plan.Basic10.minimized = true;
      }
    ),

    basicWeb: (
      (IO: CwLogicSpacePlanDefineModel) => {

        this.Plan.BasicWeb = {
          _order: 100,
          minimized: true,
          complete_description: this.Plan.Basic1.complete_description,
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.BASIC_WEB,
          name: 'Basic',
          type: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.EXPANSION_WEB
        }
      }
    ),

    // 200722000000
    pro5: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Pro5 = CwUtil.clone(
          IO.MainList.filter(this.isPro5)[0]
        );
        this.Plan.Pro5.minimized = true;
      }
    ),
    pro10: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Pro10 = CwUtil.clone(
          IO.MainList.filter(this.isPro10)[0]
        );
        this.Plan.Pro10.minimized = true;
      }
    ),
    pro50: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Pro50 = CwUtil.clone(
          IO.MainList.filter(this.isPro50)[0]
        );
        this.Plan.Pro50.minimized = true;
      }
    ),
    pro100: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Pro100 = CwUtil.clone(
          IO.MainList.filter(this.isPro100)[0]
        );
        this.Plan.Pro100.minimized = true;
      }
    ),
    proWeb: (
      (IO: CwLogicSpacePlanDefineModel) => {

        const ExpansionList = [
          this.Plan.Pro5,
          this.Plan.Pro10,
          this.Plan.Pro50,
          this.Plan.Pro100,
        ];

        this.Plan.ProWeb = {
          _order: 200,
          minimized: true,
          complete_description: this.Plan.Pro5.complete_description,
          ExpansionList,
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO_WEB,
          name: 'Pro',
          // quantity: 0,
          type: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.EXPANSION_WEB
        }
      }
    ),


    classroom: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Classroom = {
          ...CwUtil.clone(
            IO.ExtraList.filter(this.isClassroom)[0]
          ),
          _order: 2100,
          minimized: true,
          quantity: 0,
        }
      }
    ),
    classroomAdd: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.ClassroomAdd = {
          _order: 2150,
          name: 'Classroom Expansion',
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLASSROOM_ADD
        };
      }
    ),
    classroomExpansion: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.ClassroomExpansion = {
          ...CwUtil.clone(
            IO.ExtraList.filter(this.isClassroomExpansion)[0]
          ),
          _order: 2200,
          disabled: true,
          minimized: false,
          quantity: 0,
        }
      }
    ),

    classroomWeb: (
      (IO: CwLogicSpacePlanDefineModel) => {
        const ExpansionList = [
          this.Plan.Classroom,
          this.Plan.ClassroomExpansion,
        ];

        this.Plan.ClassroomWeb = {
          _order: 2000,
          minimized: true,
          complete_description: this.Plan.Classroom['complete_description'],
          ExpansionList,
          // 2007311046
          interval: CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API,
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLASSROOM_WEB,
          name: 'Classroom',
          // quantity: 0,
          type: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.EXPANSION_WEB,
        }
      }
    ),

    cloud100: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Cloud100 = {
          ...CwUtil.clone(
            IO.ExtraList.filter(this.isCloud100)[0]
          ),
          _order: 6000,
          minimized: true,
          quantity: 0,
        }
      }
    ),

    // 2009081500
    cloud500: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Cloud500 = {
          ...CwUtil.clone(
            IO.ExtraList.filter(this.isCloud500)[0]
          ),
          _order: 6010,
          minimized: true,
          quantity: 0,
        }
      }
    ),

    // 2009081500
    cloudAdd: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.CloudAdd = {
          _order: 6150,
          name: 'Cloud Space',
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLOUD_ADD
        };
      }
    ),

    gateway: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Gateway = {
          ...CwUtil.clone(
            IO.ExtraList.filter(this.isGateway)[0]
          ),
          _order: 5000,
          minimized: true,
          quantity: 0,
        }
      }
    ),

    webinar: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.Webinar = {
          ...CwUtil.clone(
            IO.ExtraList.filter(this.isWebinar)[0]
          ),
          _order: 7100,
          minimized: true,
          quantity: 0,
        }
      }
    ),

    webinarAdd: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.WebinarAdd = {
          _order: 7150,
          name: 'Webinar',
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.WEBINAR_ADD
        };
      }
    ),

    webinarExpansion: (
      (IO: CwLogicSpacePlanDefineModel) => {
        this.Plan.WebinarExpansion = {
          ...CwUtil.clone(
            IO.ExtraList.filter(this.isWebinarExpansion)[0]
          ),
          _order: 7200,
          minimized: false,
          quantity: 0,
        }
      }
    ),

    webinarWeb: (
      (IO: CwLogicSpacePlanDefineModel) => {

        this.Plan.WebinarWeb = {
          _order: 7000,
          minimized: true,
          complete_description: this.Plan.Webinar['complete_description'],
          ExpansionList: [
            this.Plan.Webinar,
            this.Plan.WebinarExpansion,
          ],
          id_collaborate_package: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.WEBINAR_WEB,
          name: 'Webinar',
          // quantity: 0,
          type: CW_SPACE_SUBSCRIPTION_TYPE_MODEL.EXPANSION_WEB
        }
      }
    ),

  }


  is = (
    (
      ItemA: any | CW_SPACE_SUBSCRIPTION_TYPE_MODEL,
      ItemB: any | CW_SPACE_SUBSCRIPTION_TYPE_MODEL
    ): boolean => {
      const typeItemA = (ItemA && ItemA.id_collaborate_package)
        ? ItemA.id_collaborate_package
        : ItemA
      ;
      const typeItemB = (ItemB && ItemB.id_collaborate_package)
        ? ItemB.id_collaborate_package
        : ItemB
      ;
      return typeItemA === typeItemB;
    }
  );
  isBasic = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.BASIC);
    }
  );
  isBasic1 = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.BASIC_1);
    }
  );
  isBasic5 = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.BASIC_5);
    }
  );
  isBasic10 = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.BASIC_10);
    }
  );
  isBasicAdd = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.BASIC_ADD);
    }
  );
  isBasicWeb = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.BASIC_WEB);
    }
  );
  isBasicType = (
    (Item: any): boolean => {
      return (
        this.isBasic(Item) ||
        this.isBasic1(Item) ||
        this.isBasic5(Item) ||
        this.isBasic10(Item) ||
        this.isBasicWeb(Item)
      )
    }
  )
  isClassroom = (
    (Item: any): boolean => {
      return (
        this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLASSROOM)
      )
    }
  );
  isClassroomExpansion = (
    (Item: any): boolean => {
      return (
        this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLASSROOM_EXPANSION)
      )
    }
  );
  isClassroomAdd = (
    (Item: any): boolean => {
      return (this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLASSROOM_ADD));
    }
  );
  isClassroomWeb = (
    (Item: any): boolean => {
      return (this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLASSROOM_WEB));
    }
  );
  isClassroomType = (
    (Item: any): boolean => {
      return (
        this.isClassroom(Item) ||
        this.isClassroomExpansion(Item) ||
        this.isClassroomWeb(Item)
      )
    }
  )
  isCloud100 = (
    (Item: any): boolean => {
      return (
        this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLOUD_100)
      )
    }
  );
  // 2009081500
  isCloud500 = (
    (Item: any): boolean => {
      return (
        this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLOUD_500)
      )
    }
  );
  // 2009081500
  isCloudAdd = (
    (Item: any): boolean => {
      return (
        this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.CLOUD_ADD)
      )
    }
  );
  // 2009081500
  isCloudType = (
    (Item: any): boolean => {
      return (
        this.isCloud100(Item) ||
        this.isCloud500(Item) ||
        this.isCloudAdd(Item)
      )
    }
  );
  isGateway = (
    (Item: any): boolean => {
      return (
        this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.GATEWAY)
      )
    }
  );

  isMain = (
    (Item: any): boolean => {
      return (
        Item.type === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.MAIN
      )
    }
  )

  isMonthly = (
    (Item: any): boolean => {
      return (
        Item.interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.MONTHLY.API
      )
    }
  )

  isPlanAdd = (
    (Item: any): boolean => {
      return (
        this.isClassroomAdd(Item) ||
        this.isCloudAdd(Item) ||
        this.isWebinarAdd(Item) ||
        this.isBasicAdd(Item) ||
        this.isProAdd(Item)
      )
    }
  );

  isProType = (
    (Item: any): boolean => {
      return (
        this.isPro(Item) ||
        this.isPro5(Item) ||
        this.isPro10(Item) ||
        this.isPro50(Item) ||
        this.isPro100(Item) ||
        this.isProWeb(Item)
      )
    }
  )
  isPro = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO);
    }
  );
  isPro5 = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO_5);
    }
  );
  isPro10 = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO_10);
    }
  );
  isPro50 = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO_50);
    }
  );
  isPro100 = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO_100);
    }
  );
  isProAdd = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO_ADD);
    }
  )
  isProWeb = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.PRO_WEB);
    }
  );
  isWebinar = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.WEBINAR_500);
    }
  );
  isWebinarAdd = (
    (Item: any): boolean => {
      return this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.WEBINAR_ADD)
    }
  );
  isWebinarExpansion = (
    (Item: any): boolean => {
      return (
        this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.WEBINAR_1000)
      );
    }
  );

  isWebinarWeb = (
    (Item: any): boolean => {
      return (
        this.is(Item, CW_SPACE_SUBSCRIPTION_TYPE_MODEL.WEBINAR_WEB)
      );
    }
  );
  isWebinarType = (
    (Item: any): boolean => {
      return (
        this.isWebinar(Item) ||
        this.isWebinarExpansion(Item) ||
        this.isWebinarWeb(Item)
      )
    }
  )
  isYearly = (
    (Item: any): boolean => {
      return (
        Item.interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API
      )
    }
  )

}
