import {CwSocketSetupI} from './socket-model';
import {CwIs} from '../tool/tool-is';

export class SocketClass {

  CONNECTION_OFF_COUNT_TIME_FIRST = 0;
  connectionOffCount = this.CONNECTION_OFF_COUNT_TIME_FIRST;

  Setup: CwSocketSetupI;

  Socket: WebSocket;

  SocketActionsAssociateList = [];

  get getOpen(): boolean {
    return (CwIs.defined(this.Socket))
      ? this.Socket.readyState === this.Socket.OPEN
      : false
      ;
  }

  // is closed?
  get isClosed(): boolean {
    return this.Socket.readyState === this.Socket.CLOSED;
  }

  setup(Setup: CwSocketSetupI): void {
    if (Setup) {
      this.Setup = Setup;
      this.create();
    }

  }

  create(): void {
    try {
      if (
        !this.getOpen &&
        this.Setup &&
        CwIs.defined(
          this.Setup.protocols,
          this.Setup.token,
          this.Setup.url
        ) &&
        true
      ) {
        this.Socket = new WebSocket(
          this.Setup.url + this.Setup.token,
          this.Setup.protocols
        );

        if (CwIs.defined(this.Socket)) {

          this.Socket.onopen = (event: any) => {
            if (
              this.Setup &&
              this.Setup.onOpen &&
              true
            ) {
              this.Setup.onOpen();
            }

            // this.connectionIntervalOff(this);
            this.onOpenToDo(this, event);
          };

          this.Socket.onmessage = (event: any) => {
            this.Setup.onMessage(event);
            // this.onMessageToDo(this, event);
          };

          this.Socket.onerror = (event: any) => {
            console.error('WebSocket - 1806191529 - readyState', this.Socket.readyState, 'onerror', event);
          };

          this.Socket.onclose = (event: any) => {
            // this.onLostConnectionToDo();
          };
        }
      } else {
        console.error('WebSocket - 1806191529 - readyState', this.Socket.readyState);
      }
    } catch (error) {
      // this.onLostConnectionToDo();
      console.error('WebSocket - Error - 1806191529', error);
    }
  }

  public onOpenToDo(that: SocketClass, _event?: any): void {
    // console.trace('WebSocket - Open Connection', that.connectionOffCount);
    that.connectionOffCount = that.CONNECTION_OFF_COUNT_TIME_FIRST;
    // that.connectionIntervalOff(that);
    // that.connectionResynchronized(that);
  }

  // connectionIntervalOff(that: SocketClass): void {
  //   that.busIntervalList.forEach(busItem => {
  //     if (busItem.intervalOff) {
  //       busItem.intervalOff();
  //     }
  //   });
  // }

  // connectionResynchronized(that: SocketClass): void {
  //   that.SocketActionsAssociateList.forEach(socketItem => {
  //     socketItem.resynchronized();
  //   });
  // }
}


