import {Component, Input, OnInit} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-space-plan-features',
  templateUrl: './cw-space-plan-features.component.html',
  styleUrls: ['./cw-space-plan-features.component.css']
})
export class CwSpacePlanFeaturesComponent {
  @Input() Plan: any;


}
