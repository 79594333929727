export enum CW_SPACE_SUBSCRIPTION_TYPE_MODEL {


  BASIC = 'Basic_1',
  BASIC_1 = 'Basic_1',
  BASIC_5 = 'Basic_5',
  BASIC_10 = 'Basic_10',
  BASIC_ADD = 'Basic_add',
  BASIC_WEB = 'Basic_Web',

  CLASSROOM = 'Classroom_150',
  CLASSROOM_ADD = 'Classroom_add',
  CLASSROOM_EXPANSION = 'Classroom_Expansion_100',
  CLASSROOM_WEB = 'Classroom_web',

  CLOUD_100 = 'Cloud_Space_100GB',
  CLOUD_500 = 'Cloud_Space_500GB',
  CLOUD_ADD = 'Cloud_Space_Add',
  CLOUD_WEB = 'Cloud_Space_Web',

  ENTERPRISE = 'Enterprise',

  EXPANSION_WEB = 'Expansion_web',

  EXTRA = 'Extra',

  FREE = 'Free_1',

  GATEWAY_GIFT = 'Gateway_Port_1',

  GIFT = 'Gift',

  MAIN = 'Main',

  STANDARD = 'Basic_1',

  GATEWAY = 'Gateway_Port_1',

  PRO = 'Pro_1',
  PRO_5 = 'Pro_5',
  PRO_10 = 'Pro_10',
  PRO_50 = 'Pro_50',
  PRO_100 = 'Pro_100',
  PRO_ADD = 'PRO_add',
  PRO_WEB = 'Pro_Web',

  TRIAL = 'Trial',

  WEBINAR_1000 = 'Webinar_1000',
  WEBINAR_500 = 'Webinar_500',
  WEBINAR_ADD = 'Webinar_add',
  WEBINAR_WEB = 'Webinar_web',
}


/**
 * @version 2002271317
 */
export const CW_SPACE_SUBSCRIPTION_TOOL = {
  INTERVAL: {
    YEARLY: {
      API: 'year',
      LITERAL: 'Yearly'
    },
    MONTHLY: {
      API: 'month',
      LITERAL: 'Monthly'
    },
    GIFT: {
      API: 'day',
      LITERAL: '',
    },
  },
  PACKAGE: {
    CLOUD: 'Cloud_Space_1GB',
    GATEWAY: 'Gateway_Port_1',
    STANDARD: 'Standard_1'
  },
  PLAN_ID: {
    TRIAL: {
      GATEWAY: 'plan_Gyv8EZSyz74olv',
      PRO: 'plan_EK32Nd1jUYjjv6',
      STANDARD: 'plan_EK32SfNRsuiXQf',
    }
  },
  STATUS: {
    TRIAL: {
      API: 'trialing'
    }
  },
  TYPE: CW_SPACE_SUBSCRIPTION_TYPE_MODEL
};

