import {Component} from '@angular/core';
import {CwPayTool} from '../../../CollaborateSpaceWebTools/tool/payment/tool-payment';
import {PlansComponentClass} from '../../plans.class';
import {AppPlansTool} from '../../plans.tool';
import {CwInput, CwInputI, CwInputType} from '@cw/components/input/cw-input-model';
import {CwLiteral} from '@cw/literal/literal';
import {CwUtil} from '@tool/util/cw-tool-util';

@Component({
  selector: 'app-plans-buy-payment',
  templateUrl: './plans-buy-payment.component.html',
  styleUrls: ['./plans-buy-payment.component.css']
})
export class PlansBuyPaymentComponent
  extends PlansComponentClass {

  CwPayTool = CwPayTool;

  On = {
    success: (token) => {
      AppPlansTool.Buy.Payment.token = token;
    },
  }

  get getAvailable(): boolean {
    return (
      this.AppPlansTool &&
      this.AppPlansTool.Buy &&
      this.AppPlansTool.Buy.Payment &&
      (
        (
          AppPlansTool.Buy.Payment.Input &&
          AppPlansTool.Buy.Payment.Input.CardToken &&
          AppPlansTool.Buy.Payment.Input.CardToken.id &&
          true
        ) ||
        AppPlansTool.Buy.Payment.InputElements
      )
      &&
      true
    );
  }

  constructor() {
    super();
    CwLiteral.onLangChange$.subscribe(() => {
      // 2011240953
      // Borrar los elementos creados y crearlos de nuevo
      CwUtil.clear(AppPlansTool.Buy.Payment.InputElements);

      AppPlansTool.Buy.Payment.InputElements.push(
        ...AppPlansTool.Buy.Payment.defineInputsElements()
      );
    })
  }

}
