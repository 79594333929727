import {CwActionI, CwMessageActionI, CwMessageI} from './model';

export class CwMessageActionClass {

  TARGET_ORIGIN = '*';

  ActionsMap: Map<string, CwActionI[]> = new Map();
  Iframe: any;


  send = ((Message: CwMessageI) => {

    if (Message) {
      try {
        // Function not able to send
        const MessageBasic = Object.keys(Message)
          .filter(key => typeof Message[key] !== 'function')
          .reduce((out, key) => {
            out[key] = Message[key];
            return out;
          }, {});
        if (
          this.Iframe &&
          this.Iframe.contentWindow &&
          true
        ) {
          this.Iframe.contentWindow.postMessage(
            MessageBasic,
            this.TARGET_ORIGIN
          );
        } else if (
          this.Iframe &&
          this.Iframe.nativeElement &&
          this.Iframe.nativeElement.contentWindow &&
          true
        ) {
          this.Iframe.nativeElement.contentWindow.postMessage(
            MessageBasic,
            this.TARGET_ORIGIN
          );
        } else {
          parent.postMessage(MessageBasic,
            this.TARGET_ORIGIN
          );
        }
        // sender(Message, this.TARGET_ORIGIN)
      } catch (e) {
        if (Message && Message.errorFn) {
          Message.errorFn(e);
        }
      }
    } else if (Message && Message.errorFn) {
      Message.errorFn();
    }
  });

  handle = ((Params) => {
    if (
      Params &&
      Params.data &&
      Params.data.action &&
      true
    ) {
      if (
        this.ActionsMap.has(Params.data.action) &&
        true
      ) {
        this.ActionsMap.get(Params.data.action).forEach(ActionItem => {
          if (ActionItem) {
            try {
              ActionItem.fn(Params.data.Data);
            } catch (e) {
              if (ActionItem.errorFn) {
                ActionItem.errorFn(e);
              }
            }
          }
        })
      }
    }
  });

  register = ((Params: { Actions: CwActionI[] }) => {
    if (Params && Params.Actions) {
      Params.Actions.forEach(ActionItem => {
        if (!this.ActionsMap.has(ActionItem.action)) {
          this.ActionsMap.set(ActionItem.action, [ActionItem]);
        } else {
          this.ActionsMap.get(ActionItem.action).push(ActionItem);
        }
      })
    }
  });

  remove = (() => {
    if (window.removeEventListener) {
      window.removeEventListener('message', this.handle);
    } else {
      window['removeEvent']('message', this.handle);
    }
  })

  constructor(Params?: CwMessageActionI) {
    if (Params && Params.Iframe) {
      this.Iframe = Params.Iframe;
    }

    if (window.addEventListener) {
      window.addEventListener('message', this.handle);
    } else {
      window['attachEvent']('message', this.handle);
    }
  }

}
