import {BillingBus} from '../billing-bus';
import {CwFunction} from '../../../model/model';
import {CwRestRequestParametersI} from '../../model';

class CwBillingCurrenciesClass extends BillingBus {
  DefineProperties: CwFunction[];

  defineUrl = (
    (): string => {
      return this.path + 'currencies';
    }
  );

  do: CwFunction = (
    (Params: CwRestRequestParametersI): void => {
      this.restGet({
        ...Params,
        Bus: this,
        Parameters: null,
      });
    }
  );

}

// 2008130000
export const CwBillingCurrenciesBus: CwBillingCurrenciesClass = new CwBillingCurrenciesClass();

