import {Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-space-plan-description',
  templateUrl: './cw-space-plan-description.component.html',
  styles: []
})
export class CwSpacePlanDescriptionComponent {
  @Input() DescriptionList: any[]
  @ViewChild('TemplateItem') TemplateItem: TemplateRef<any>;
  @ViewChild('TemplateSubitem') TemplateSubitem: TemplateRef<any>;

  templateByDescription = ((description: string): TemplateRef<any> => {
    let Template = this.TemplateItem;
    if (description.indexOf('-') === 1) {
      Template = this.TemplateSubitem;
    }
    return Template;
  })

}
