import {Component} from '@angular/core';
import {RatesComponent} from '../rates.component';

@Component({
  selector: 'app-rates-standard',
  templateUrl: './rates-standard.component.html',
  styleUrls: ['./rates-standard.component.css']
})
export class RatesStandardComponent extends RatesComponent {


}
