import {CwOnP} from '../tool/on-premise/tool-on-premise';
import {CwLicense} from '../license/license-bus';
import {OnDestroy, ViewChild} from '@angular/core';
import {GridComponent} from '../components/grid/grid.component';
import {CwFunction} from './model';
import {Observable, Subscription} from 'rxjs';
import {SetupGrid} from '../components/grid/grid';
import {CwModal} from '../components/modal/modal';
import {ExportCsvModalComponent} from '../../commons/components/export-csv-modal/export-csv-modal.component';
import {CwWeb} from '../tool/web/web.tool';
import {AppDevelopTool} from '../../tool/develop/develop.tool';

/**
 * @version 1911080840
 */
export abstract class CwComponent implements OnDestroy {
  // App = App;
  CwDevelopTool = AppDevelopTool;
  CwLicense = CwLicense;
  CwOnP = CwOnP;

  private _SubscribeList: Subscription[] = [];

  subscribe = ((Bus$: Observable<any>, nextFn: CwFunction) => {
    this._SubscribeList.push(Bus$.subscribe(nextFn));
  });


  ngOnDestroy(): void {
    this._SubscribeList.forEach((SubscribeItem: Subscription) => {
      if (SubscribeItem && SubscribeItem.unsubscribe) {
        SubscribeItem.unsubscribe();
      }
    });
  }


}

/**
 * @version 1911080840
 */
export abstract class CwGridComponent extends CwComponent {

  @ViewChild('GridComponent') public GridComponent: GridComponent;

  abstract Setup: SetupGrid;

  isMobile = CwWeb.isMobile;
  exportCsvFileNamePrefix: string = '';
  // Add extra columns data for export that are not in the grid table.
  exportCsvExtraData: any;

  exportCsv(Event?: any) {
    CwModal.open({
      ModalComponent: ExportCsvModalComponent,
      GridComponent: this.GridComponent,
      ExtraParams:
        {
          fileNamePrefix: this.exportCsvFileNamePrefix,
          extraData: this.exportCsvExtraData
        },
      Event: Event,
    });
  }

  resynchronized = (() => {
    if (
      this &&
      this.Setup &&
      this.Setup.GridData &&
      this.GridComponent &&
      true
    ) {
      this.GridComponent.busFinished = true;
      this.GridComponent.reload();
    }
  });

  openModalComponent(_ModalComponent: any) {
    CwModal.open({
      large: true,
      ModalComponent: _ModalComponent,
      Data: this.GridComponent.getSelected(),
      GridComponent: this.GridComponent,
    });
  }

}

