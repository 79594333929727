import {isArray, isBoolean, isDate, isNumber, isObject, isString} from 'util';

/**
 * @since 4.0.0.0.R20.1 - Hotfix - Set Admins
 * @version R40.42 - Users - Select One or Several - Widget with user selection
 */
export class CoIs {

  /**
   * @param _output
   * @returns {boolean}
   * @version R35.0 - New Design - Navigation
   */
  static outputActive(_output: any): boolean {
    return (
      CoIs.defined(_output) &&
      CoIs.arrayNotEmpty(_output.observers)
    );
  }

  /**
   * @since 4.0.0.0.R20.1 - Hotfix - Set Admins
   * @param {any[]} argumentsList
   * @returns {boolean}
   */
  public static definedAll(argumentsList: any[]): boolean {
    let _isDefinedAll = true;
    if (CoIs.arrayNotEmpty(argumentsList)) {
      for (const argumentItem of argumentsList) {
        if (CoIs.undefined(argumentItem)) {
          _isDefinedAll = false;
          break;
        }
      }
    } else {
      _isDefinedAll = false;
    }
    return _isDefinedAll;
  }

  /**
   * @param dataList
   * @returns {boolean}
   * @since 4.0.0.0.R20.1 - Hotfix - Set Admins
   */
  public static defined(...dataList: any[]): boolean {
    return CoIs._defined(dataList);
  }

  /**
   *
   * @param data
   * @returns {boolean}
   * @since 4.0.0.0.R20.1 - Hotfix - Set Admins
   */
  private static _defined(data: any): boolean {
    let _isDefined = false;
    try {
      _isDefined = (
        undefined !== data &&
        null !== data &&
        // isNaN(data) &&
        // NaN !== data &&
        true
      );
      if (
        _isDefined &&
        isArray(data) &&
        true
      ) {
        for (const item of data) {
          _isDefined = (
            _isDefined &&
            undefined !== item &&
            // isNaN(item) &&
            // NaN !== item &&
            null !== item
          );
        }
      }
    } catch (error) {
      _isDefined = false;
    }

    return _isDefined;

  }

  /**
   * @param data
   * @returns {boolean}
   * @since 4.0.0.0.R20.1 - Hotfix - Set Admins
   */
  public static undefined(data: any): boolean {
    return !CoIs.defined(data);
  }

  /**
   * @param data
   * @returns {boolean}
   * @since 4.0.0.0.R9.12 - Error - Firefox - InvalidPipeArgument: 'Invalid Date' for pipe 'DatePipe'
   */
  public static array(data: any): boolean {
    return (
      CoIs.defined(data) &&
      isArray(data) &&
      true
    );
  }

  public static arrayEmpty(data: any): boolean {
    return isArray(data) && !CoIs.arrayNotEmpty(data);
  }

  /**
   * @since 4.0.0.0.R10.0 - Resources
   * @param data
   * @returns {boolean}
   */
  public static arrayNotEmpty(data: any): boolean {
    return (
      CoIs.defined(data) &&
      isArray(data) &&
      0 < data.length &&
      true
    );
  }

  public static arraySingle(data: any): boolean {
    return CoIs.arrayWithLength(data, 1);
  }

  /**
   * @since 4.0.0.0.R9.12 - Error - Firefox - InvalidPipeArgument: 'Invalid Date' for pipe 'DatePipe'
   * @param data
   * @returns {boolean}
   */
  public static arrayWithLength(data: any, length: number): boolean {
    return (
      CoIs.array(data) &&
      length === data.length &&
      true
    );
  }

  // R40.5 - Users - Add
  public static arrayLargerThan(data: any, _larger: number): boolean {
    return (
      CoIs.array(data) &&
      _larger < data.length &&
      true
    );
  }

  /**
   * @since 4.0.0.0.R9.12 - Error - Firefox - InvalidPipeArgument: 'Invalid Date' for pipe 'DatePipe'
   * @version R60.1 - Dashboard - Usage
   * @param data
   * @returns {boolean}
   */
  public static number(data: any): boolean {
    let _valid = false;
    if (CoIs.stringNotEmpty(String(data))) {
      try {
        _valid = isNumber(data);
      } catch (error) {
        _valid = false;
      }
    }
    return _valid;
  }

  /**
   * @since 4.0.0.0.R13.0 - Check the number of available licenses.
   * @param data
   * @returns {boolean}
   */
  public static numberPositive(data: any): boolean {
    return (
      CoIs.number(data) &&
      0 < Number(data)
    );
  }

  /**
   * @since 4.0.0.0.R9.12 - Error - Firefox - InvalidPipeArgument: 'Invalid Date' for pipe 'DatePipe'
   * @param data
   * @returns {boolean}
   */
  public static numberEqual(data: any, num: number): boolean {
    return (
      CoIs.number(data) &&
      num === Number(data) &&
      true
    );
  }

  /**
   * @since 4.0.0.0.R9.12 - Error - Firefox - InvalidPipeArgument: 'Invalid Date' for pipe 'DatePipe'
   * @version 4.0.0.0.R13.1 - Export CSV - File name: (name_date.csv)
   * @param data
   * @returns {boolean}
   */
  public static date(data: any): boolean {
    let _valid = false;
    try {
      _valid = (
        CoIs.defined(data) &&
        isDate(data) &&
        true
      );
    } catch (error) {
      _valid = false;
    }
    return _valid;
  }

  /**
   * @since 4.0.0.0.R9.12 - Error - Firefox - InvalidPipeArgument: 'Invalid Date' for pipe 'DatePipe'
   * @param data
   * @returns {boolean}
   */
  public static string(data: any): boolean {
    return (
      CoIs.defined(data) &&
      isString(data) &&
      true
    );
  }

  /**
   * @param data
   * @param {string} than
   * @returns {boolean}
   * @since R36.0 - Execution flow
   */
  public static stringNotEqual(data: any, than: string): boolean {
    let _isStringNotEqual = false;
    if (
      (
        CoIs.stringNotEmpty(data) ||
        CoIs.number(data)
      ) &&
      String(data) !== than
    ) {
      _isStringNotEqual = true;
    }
    return _isStringNotEqual;
  }

  /**
   * @since 4.0.0.0.R10.0 - Resources
   * @param data
   * @param {string} than
   * @returns {boolean}
   */
  public static stringEqual(data: any, than: string): boolean {
    let _isStringEqual = false;
    if (
      (
        CoIs.stringNotEmpty(data) ||
        CoIs.number(data)
      ) &&
      String(data) === than
    ) {
      _isStringEqual = true;
    }
    return _isStringEqual;
  }

  /**
   * @since 4.0.0.0.R9.12 - Error - Firefox - InvalidPipeArgument: 'Invalid Date' for pipe 'DatePipe'
   * @version 4.0.0.0.R13.11 - Users - Add - Invite users with mailing lists separated by semicolons
   * @param data
   * @returns {boolean}
   */
  public static stringNotEmpty(data: any): boolean {
    return (
      CoIs.string(data) &&
      String('') !== String(data) &&
      true
    );
  }

  /**
   * @since 4.0.0.0.R10.5 - Resources - Change License
   * @param data
   * @returns {boolean}
   */
  public static stringEmpty(data: any): boolean {
    return (
      CoIs.string(data) &&
      String('') === String(data) &&
      true
    );
  }

  /**
   * @since 4.0.0.0.R9.14 - ERROR - Safari - TypeError: Attempted to assign to readonly property - GridComponent
   * @version 4.0.0.0.R11.4 - Ticket 326 - You can't change a billing subscription and modify the Payment Method
   * @param data
   * @returns {boolean}
   */
  public static boolean(data: any): boolean {
    let _valid = false;
    if (CoIs.defined(data)) {
      try {
        _valid = isBoolean(Boolean(data));
      } catch (error) {
        _valid = false;
      }
    }
    return _valid;
  }

  public static trueOrUndefined(data: any): boolean {
    return CoIs.undefined(data) || CoIs.true(data);
  }

  /**
   * @since 4.0.0.0.R9.14 - ERROR - Safari - TypeError: Attempted to assign to readonly property - GridComponent
   * @version 4.0.0.0.R11.4 - Ticket 326 - You can't change a billing subscription and modify the Payment Method
   * @param data
   * @returns {boolean}
   */
  public static true(data: any): boolean {
    let _valid = false;
    if (CoIs.array(data)) {
      _valid = CoIs.trueAll(data, true);
    } else if (CoIs.stringEqual(typeof data, 'string')) {
      _valid = (
        CoIs.stringEqual(data, '1') ||
        CoIs.stringEqual(data.toLowerCase(), 'true')
      );
    } else {
      _valid = (
        CoIs.boolean(data) &&
        Boolean(data) &&
        true
      );
    }
    return _valid;
  }

  /**
   * @since 4.0.0.0.R10.0 - Resources
   * @param data
   * @returns {boolean}
   */
  public static false(data: any): boolean {
    return (
      CoIs.boolean(data) &&
      false === Boolean(data) &&
      true
    );
  }

  /**
   * Executes all conditions
   * @since 4.0.0.0.R10.0 - Resources
   * @version 4.0.0.0.R11.4 - Ticket 326 - You can't change a billing subscription and modify the Payment Method
   * @param {any[]} dataList
   * @returns {boolean}
   */
  public static trueAll(dataList: any[], breakFalse: boolean = false): boolean {
    let _isTrueAll = true;
    if (CoIs.arrayNotEmpty(dataList)) {
      for (const itemBoolean of dataList) {
        _isTrueAll = CoIs.true(itemBoolean) && _isTrueAll;
        if (breakFalse && !_isTrueAll) {
          break;
        }
      }
    } else {
      _isTrueAll = false;
    }
    return _isTrueAll;
  }

  public static trueAny(_booleanList: boolean[]): boolean {
    let _boolean = false;
    try {
      if (CoIs.arrayNotEmpty(_booleanList)) {
        for (const itemBoolean of _booleanList) {
          _boolean = CoIs.true(itemBoolean);
          if (_boolean) {
            break;
          }
        }
      } else {
        _boolean = false;
      }
    } catch (error) {
      _boolean = false;
    }
    return _boolean;
  }

  /**
   * @since 4.0.0.0.R11.4 - Ticket 326 - You can't change a billing subscription and modify the Payment Method
   * @param sourceData
   * @param targetDate
   * @param {string[]} propertiesList
   * @returns {boolean}
   * @version R29.0 - Billing - Migration to work in production mode.
   */
  public static anyDataChanged(sourceData: any, targetDate: any, propertiesList: string[] = []): boolean {
    let _changed = false;
    if (CoIs.defined(sourceData, targetDate, propertiesList)) {
      if (CoIs.arrayEmpty(propertiesList)) {
        propertiesList = Object.getOwnPropertyNames(sourceData);
      }
      for (const propertyName of propertiesList) {
        _changed = !CoIs.equal(sourceData[propertyName], targetDate[propertyName]);
        if (_changed) {
          break;
        }
      }
    }
    return _changed;
  }

  /**
   * @param _inA
   * @param _inB
   * @param {string} _objectKey
   * @returns {boolean}
   * @since 4.0.0.0.R16.5 - Account - LDAP Users
   */
  public static equal(_inA: any, _inB: any, _objectKey?: string): boolean {
    let _equal = false;
    try {
      if (CoIs.defined(_inA, _inB)) {
        if (CoIs.defined(_objectKey, _inA[_objectKey], _inB[_objectKey])) {
          _equal = (_inA[_objectKey] === _inB[_objectKey]);
        } else {
          _equal = (_inA === _inB);
        }
      }
    } catch (error) {
      _equal = false;
    }

    return _equal;
  }

  /**
   * @param _in
   * @returns {boolean}
   * @since 4.0.0.0.R15.0 - Reload & Session
   */
  public static object(_in: any): boolean {
    return (
      CoIs.defined(_in) &&
      isObject(_in)
    );
  }

  /**
   * @param _in
   * @returns {boolean}
   * @since 4.0.0.0.R20.1 - Hotfix - Set Admins
   */
  public static objectNotEmpty(_in: any): boolean {
    return (
      CoIs.object(_in) &&
      0 < Object.getOwnPropertyNames(_in).length
    );
  }

  public static objectEmpty(_in: any): boolean {
    return !CoIs.objectNotEmpty(_in);
  }

}
