import {Pipe, PipeTransform} from '@angular/core';
import {CwDateTool} from '../tool/date/date.tool';

@Pipe({
  name: 'cwDateTimeZone'
})
/**
 * @version 1910310846
 */
export class CwDateTimeZonePipe implements PipeTransform {

  transform(Value: any, args?: any): any {
    return  CwDateTool.utcToDateTimeZone(Value, null);
  }

}
