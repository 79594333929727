import {CwVersionCheckPlatform} from '../version/check/check-model';
import {CwFunction} from '../model/model';

/**
 * @version 1906251012
 */
class ToolFix {


  /**
   *         - Ticket 2958 - Password recovery process.
   *         In case of iOS Collaborate Space APP is not installed,
   *         last step does not finish properly and
   *         password is not saved.
   *         - Ticket 2646 - If you have the application installed (Android and iOS)
   *         and launched when you click on an email invitation, join doesn't work.
   *         SOLUTION - 1906251012
   *         If IOs and Safari, go to Download always
   */
  protocolIOsSafari(Param: FixProtocolIOsSafariI): boolean {
    let _protocolIOsSafariBackground = false;
    if (
        Param &&
        CwVersionCheckPlatform.ios === Param.platform &&
        true
    ) {
      if (Param.goTo) {
        if (!Param.downloadUrl) {
          Param.downloadUrl = 'download-only';
        }
        Param.goTo(Param.downloadUrl);
      }
      _protocolIOsSafariBackground = true;
    }
    return _protocolIOsSafariBackground;
  }

  activationOpenAdmin(Param: FixActivationOpenAdminI): boolean {
    return !(
        Param &&
        Param.browser &&
        Param.browser.isFirefox &&
        true
    ) ;
  }

}

/**
 * @version 1906251012
 */
export const CwFix = new ToolFix();

export interface FixProtocolIOsSafariI {
  platform: number;
  downloadUrl?: string;
  goTo?: CwFunction;
}

export interface FixActivationOpenAdminI {
  browser: any
}
