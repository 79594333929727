<ng-container *ngIf="App.Body.visible">

  <div
    *ngIf="isInvitedByVisible"
    class="qContainer"
  >
    <div style="width: 80%; padding-left: 0; padding-right: 0; margin-right: auto; margin-left: auto">
      <h2 class="text-center">
        <span
          translate>You have been invited to connect to COLLABORATE Space, by</span>&nbsp;<strong>{{getInviteBy}}</strong>
      </h2>
    </div>
  </div>

  <div class="container app-purchase py-3 px-5">
    <div class="row">
      <ng-container *ngIf="isEmailOffsetVisible">
        <div
          class="offset-0 offset-sm-0 offset-md-3 col-12 col-sm-12 col-md-6 px-2 2010151600 "
        >
          <cw-input [Item]="AppDataSignUpTool.InputEmail"></cw-input>
        </div>
      </ng-container>
      <ng-container *ngFor="let Item of AppDataSignUpTool.Registration.InputSet">
        <div class="col-12 col-sm-12 col-md-6 px-2">
          <cw-input
            [Item]="Item"
          ></cw-input>
        </div>
      </ng-container>


      <ng-container *ngIf="getCountryAndStateVisible">

        <div class="col-12 col-sm-12 col-md-6 px-2">
          <div class="form-group"
          >
            <label
              translate="Country"
            ></label>
            <app-cw-select-country
              [(ngModel)]="AppDataSignUpTool.Country"
              (change)="AppDataSignUpTool.Registration.On.Country.changed($event)"
              ngDefaultControl
              required
            ></app-cw-select-country>
            <cw-input
              [Item]="AppDataSignUpTool.InputCountry"
            ></cw-input>
          </div><!--      form-group-->
        </div>
        <div class="col-12 col-sm-12 col-md-6 px-2">
          <div class="form-group">
            <label
              translate="State"
            ></label>
            <app-cw-select2
              [Setup]="AppDataSignUpTool.StateSetup"
              [(ngModel)]="AppDataSignUpTool.State"
              (change)="AppDataSignUpTool.Registration.On.State.changed()"
              required
              ngDefaultControl
            ></app-cw-select2>
            <cw-input
              [Item]="AppDataSignUpTool.InputState"
            ></cw-input>
          </div><!--      form-group-->
        </div>

      </ng-container>

      <div
        class="offset-0 offset-sm-0 offset-md-3 col-12 col-sm-12 col-md-6 px-2 2010151600 2007291000"
        [ngClass]="{'d-none': !AppDataSignUpTool.InputReasonOfUse.enabled || true}"
      >
        <div class="form-group"
        >
          <label
            translate="Reason of Use"
          ></label>

          <select
            class="form-control form-control-sm"
            required="true"
            style="background-color: #F6F7FC"

            [(ngModel)]="AppDataSignUpTool.InputReasonOfUse.value"
          >

            <option
              translate="Personal"
              value="Personal"
            ></option>

            <option
              translate="Business"
              value="Business"
            ></option>

          </select>

          <cw-input
            [Item]="AppDataSignUpTool.InputReasonOfUse"
          ></cw-input>
        </div><!--      form-group-->
      </div>

    </div><!--    row-->


    <div class="mb-3 row justify-content-center px-2">
      <div class="col text-center">
        <button
          appCw
          appCwForm
          type="button"
          class="btn-secondary"
          role="button"
          (click)="On.SignUp.clicked()"
        >{{ 'Sign Up' | translate | uppercase}}
        </button>
      </div>
    </div>


    <div class="mb-3 row justify-content-center px-2">
      <div class="col text-center">
        <cw-space-agree-passive></cw-space-agree-passive>
      </div>
    </div>

    <app-cw-row>
      <hr R2>
    </app-cw-row>

    <div class="pt-3 d-flex justify-content-center">
      <label translate="Already have an account?"></label>
      <a
        *ngIf="isSignInVisible"
        routerLink="/datasignin" class="font-bold"
      >&#160;{{'Sign In' | translate}}</a>
    </div>

    <div
      *ngIf="isStartVisible"
      class="pt-3 d-flex justify-content-center 2009210000.D2">
      <button appCw appCwForm
              (click)="On.Start.clicked()"
              type="button"
      >
        {{'Start' | translate | uppercase}}
      </button>

    </div>



  </div>
</ng-container>
