<ng-template #PlansTemplate>

  <div R class="d-none d-md-block text-center mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-5  ">
    <h4>
      <span translate>Plans & Pricing</span>
    </h4>
    <a routerLink="/datasignup">
      <!--      2007291000-->
      <span translate>Personal</span>
      <!--      <span xxx-2003270829 translate>Trial 90 - Day</span>-->
      <!--      <span xxx-2003270829 translate>Free</span>-->
    </a>
    <br>
    <a routerLink="/plans/rates">
      <span translate>Call Rates</span>
    </a>
  </div>

</ng-template>

<ng-template #DownloadsTemplate>

  <div R class="d-none d-md-block text-center  mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-5  ">
    <div>
      <h4>
        <span translate>Downloads</span>
      </h4>
    </div>
    <div>
      <a [href]="App.Protocol.getDownload(App.Platform.mac)"
      >
        <span translate>Mac OSX</span>
      </a>
    </div>
    <div>
      <a [href]="App.Protocol.getDownload(App.Platform.windows)">
        <span translate>Windows</span>
      </a>
    </div>
    <div
      *ngIf="!CwOnP.is"
    >
      <!--1902051520-->
      <a [href]="App.Protocol.getDownload(App.Platform.ios)"
         target="_blank"
      >
        <span translate>iOS</span>
      </a>
    </div>
    <div
      *ngIf="!CwOnP.is"
    >
      <!--1902051520-->
      <a [href]="App.Protocol.getDownload(App.Platform.android)"
         target="_blank"
      >
        <span translate>Android</span>
      </a>
    </div>
  </div>

</ng-template>

<ng-template #SupportTemplate>

  <div R class="text-center  mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-5  "
       *ngIf="!CwOnP.is"
  >
    <h4>
      <span translate>Support</span>
    </h4>
    <div>
      <span translate>Tel: 801.974.3760</span>
    </div>
    <div>
      <a href="mailto:{{'videotechsupport@clearone.com' | translate}}">
        <span translate>videotechsupport@clearone.com</span>
      </a>
    </div>
  </div>

</ng-template>

<ng-template #SalesTemplate>

  <div R class="text-center  mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-5 "
       *ngIf="!CwOnP.is"
  >
    <h4>
      <span translate>Sales</span>
    </h4>
    <div>
      <span translate>Tel: 801.975.7200</span>
    </div>
    <div>
      <a href="mailto:{{'sales@clearone.com' | translate}}">
        <span translate>sales@clearone.com</span>
      </a>
    </div>
  </div>


</ng-template>

<footer class="py-4 text-white">

  <ng-container #AppFooterContainer>
    <ng-container #AppFooterContainerMobile>
      <app-cw-row class="d-none d-md-block d-lg-none d-xl-none">

        <ng-container
          xxx-2003041112
          *ngIf="!CwOnP.is"
        >
          <ng-container R *ngTemplateOutlet="PlansTemplate"></ng-container>
        </ng-container>

        <ng-container R *ngTemplateOutlet="DownloadsTemplate"></ng-container>

      </app-cw-row>
      <app-cw-row class="d-none d-md-block d-lg-none d-xl-none">

        <ng-container R *ngTemplateOutlet="SupportTemplate"></ng-container>

        <ng-container R *ngTemplateOutlet="SalesTemplate"></ng-container>

      </app-cw-row>

    </ng-container><!--    #AppFooterContainerMobile-->
    <ng-container #AppFooterContainerDesktop>
      <app-cw-row class="d-block d-md-none d-lg-block d-xl-block">
        <ng-container
          xxx-2003041112
          *ngIf="!CwOnP.is"
        >
          <ng-container R *ngTemplateOutlet="PlansTemplate"></ng-container>
        </ng-container>

        <ng-container R *ngTemplateOutlet="DownloadsTemplate"></ng-container>

        <ng-container R *ngTemplateOutlet="SupportTemplate"></ng-container>

        <ng-container R *ngTemplateOutlet="SalesTemplate"></ng-container>

      </app-cw-row>
    </ng-container>

  </ng-container>

</footer>
<footer class="d-flex flex-row justify-content-center  align-items-center  bg-white  py-2">

  <div class="px-5 d-none d-xl-block 2007311010">
    <a [href]="App.Logo.url" class="2101040000-3">
      <img class="img-fluid"
           style="max-height: 40px"
           alt="ClearOne"
           src="/literal/assets/logos/clearone.jpg"
      >
    </a>
  </div>


  <div class="px-lg-5 d-flex justify-content-center">
        <span appCw
              appCwText
              appCwTextMax="12"
              appCwTextMin="6"
              class="text-truncate 2101040000-2"
              translate
              id="Copyright"
        >Copyright</span>
  </div>

  <div class="px-5 d-none d-xl-block">
    <a
      *ngIf="!CwOnP.is"
      href="https://www.petfinder.com/pet-adoption"
      target="_blank"
    >
      <div class="d-flex flex-row align-items-center">
        <div class="cw-div-pet cw-div-25 px-2"></div>
        <div translate>Adopt a Homeless pet</div>
      </div>
    </a>
  </div>

</footer>
