import {CoMap} from '../model/model';
import {Subscription} from 'rxjs';

export class CoAppSubscribeClass {

  private _subscribeMap: CoMap<Subscription> = new Map();

  public subscribe(key: string, subs: Subscription): void {
    if (key && subs) {
      CoAppSubscribe._subscribeMap.set(key, subs);
      // CoAppSubscribe._subscribeMap.set(key, subs && CoAppSubscribe.unsubscribe(key));
      // CoAppSubscribe._subscribeMap.set(key, subs);
    }
  }

  public unsubscribe(key: string): void {
    if (key && CoAppSubscribe._subscribeMap.has(key)) {
      CoAppSubscribe._subscribeMap.get(key).unsubscribe();
      CoAppSubscribe._subscribeMap.delete(key);
    }
  }

}

export const CoAppSubscribe = new CoAppSubscribeClass();
