import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwToolKeepC} from '@tool/keep/tool-keep';
import {CwRestRequestMinParametersI} from '@tool/rest/model';
import {InfoBus} from '@rest/info/info.bus';
import {CwLocationDoI} from '@rest/info/location/location-model';

class LocationBus extends InfoBus {

  countryCode: string;

  DefineProperties: CwFunction[] = [];
  Keep: CwToolKeepC<any>;
  Setup: any;
  defineUrl: CwDefineUrl = (() =>
          CwLocationBus.path + 'getLocation'
  );
  do: CwFunction = ((Param: CwLocationDoI) => {
    CwLocationBus.restPost({
      ...<CwRestRequestMinParametersI>Param,
      PayLoadCustom: {
        latitude : Param.latitude,
        longitude: Param.longitude,
      },
      Bus: CwLocationBus,
      token: null,
    });
  });

}

export const CwLocationBus = new LocationBus();
