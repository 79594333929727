import {Component, Input, OnInit} from '@angular/core';
import {PlansComponentClass} from '../../plans.class';
import {CwBillingPlans} from '@rest/billing/plans/plans-bus';
import {CW_SPACE_SUBSCRIPTION_TYPE_MODEL} from '@space/subscription/tool-space-subscription-model';
import {CwSpacePlanMinimalSetupModel} from '@cw/components/space/plan/minimal/cw-space-plan-minimal.model';
import {AppPlansTool} from '../../plans.tool';
import {CwLogicSpacePlan} from '@cw/logic/space/plan/cw-logic-space-plan';

@Component({
  selector: 'app-plans-buy-select',
  templateUrl: './plans-buy-select.component.html',
})
/**
 * @version 2004150000-12, 2007172200
 */
export class PlansBuySelectComponent
  extends PlansComponentClass
  implements OnInit {
  @Input() planType: CW_SPACE_SUBSCRIPTION_TYPE_MODEL;


  CwBillingPlans = CwBillingPlans;

  Logic = CwLogicSpacePlan;

  SetupExtra: CwSpacePlanMinimalSetupModel;
  SetupMain: CwSpacePlanMinimalSetupModel;

  // 2007172200
  On = {
    Extra: {
      changed: (
        (Plan: any): void => {
          this.Logic.Buy.On.Extra.changed(
            Plan,
            AppPlansTool.Buy.Select.MainNewList
          );
        }
      )
    },
    Main: {
      changed: (
        (Plan: any): void => {
          this.Logic.Buy.On.Main.changed(
            Plan,
            AppPlansTool.Buy.Select.MainNewList
          );
        }
      )
    }
  }

  // GETS //////////////////////////////////////////////////////////////////////
  get getAvailable(): boolean {
    return true;
  }


  // IS ////////////////////////////////////////////////////////////////////////
  get isAvailableExtras(): boolean {
    return this.Logic.Buy.Performance.Extra.is();
  }


  ngOnInit() {

    this.SetupExtra = {
      Buy: {
        Select: {
          ExtraNews: AppPlansTool.Buy.Select.ExtraNews,
        }
      },
      Description: {
        collapsable: true,
      },
      Expansion: {
        // 2007000000
        anotherVisibleFunction: this.Logic.Buy.Performance.Expansion.addAnotherVisible,
        selectDisabledFunction: this.Logic.Buy.Performance.Expansion.selectDisabled,
        On: {
          Another: {
            clicked: (
              (Plan) => {
                this.Logic.Buy.On.Expansion.AddAnother.clicked(Plan);
              }
            )
          }
        }

      }
    };
    this.SetupMain = {
      Description: {
        collapsable: true,
      },
      Expansion: {
        // 2007000000
        anotherVisibleFunction: this.Logic.Buy.Performance.Expansion.addAnotherVisible,
        selectDisabledFunction: this.Logic.Buy.Performance.Expansion.selectDisabled,
        On: {
          Another: {
            clicked: (
              (Plan) => {
                this.Logic.Buy.On.Expansion.AddAnother.clicked(Plan);
              }
            )
          }
        }

      }

    }
  }

}
