import {Component} from '@angular/core';
import {PlansBuyComponent} from './plans-buy.component';
import {CwLogicSpacePlan} from '@cw/logic/space/plan/cw-logic-space-plan';

@Component({
  selector: 'app-plans-buy',
  templateUrl: './plans-buy.component.html',
  styleUrls: ['./plans-buy.component.css']
})
/**
 * #version 2007130000.1
 */
export class PlansBuyProComponent
  extends PlansBuyComponent {

  defineSelect = (
    (IO) => {
      CwLogicSpacePlan.Buy.Pro.define(IO);
    }
  );

}
