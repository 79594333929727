import {Component, EventEmitter, OnInit, Output} from '@angular/core';

/**
 * @version 2005210000
 * @description https://github.com/DethAriel/ng-recaptcha#basic-usage-see-in-action
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-google-recaptcha',
  templateUrl: './cw-google-recaptcha.component.html',
  styleUrls: ['./cw-google-recaptcha.component.css']
})
export class CwGoogleRecaptchaComponent implements OnInit {

  @Output() error = new EventEmitter();
  @Output() success = new EventEmitter();

  On = {
    resolved: (captchaResponse: string) => {
      if (captchaResponse) {
        this.success.emit();
      } else {
        this.error.emit();
      }
    },
    test: (Event) => {
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

}
