import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {DownloadComponent} from './download/download.component';
import {NavigationComponent} from './navigation/navigation.component';
import {PlansComponent} from './plans/plans.component';
import {FootComponent} from './foot/foot.component';
import {HomeComponent} from './home/home.component';
import {HomeHostJoinComponent} from './home-host-join/home-host-join.component';
import {JoinComponent} from './join/join.component';
import {MeetingIdComponent} from './meeting-id/meeting-id.component';
import {DataSignUpComponent, FreeSingUpComponent} from './data-sign-up/data-sign-up.component';
import {VerifyComponent} from './verify/verify.component';
import {DataSignInComponent} from './data-sign-in/data-sign-in.component';
import {RatesComponent} from './rates/rates.component';
import {DialinComponent} from './dialin/dialin.component';
import {ActivationComponent} from './activation/activation.component';
import {ResetComponent} from './reset/reset.component';
import {HostComponent} from './host/host.component';
import {CustomFormsModule} from 'ng2-validation';
import {ForgotComponent} from './forgot/forgot.component'
import {DeviceDetectorModule} from 'ngx-device-detector';
import {RegistrationComponent} from './registration/registration.component';
import {QuestionComponent} from './marketing/question/question.component';
import {CustomerComponent} from './marketing/customer/customer.component';
import {SummaryComponent} from './plans/summary/summary.component';
import {SummaryRatesComponent} from './marketing/summary-rates/summary-rates.component';
import {TermsComponent} from './marketing/terms/terms.component';
import {CoPreloaderComponent} from './preloader/preloader.component';
import {CoFeedbackComponent} from './co/feedback/component';
import {CsModule} from './cs/cs.module';
import {CoAppLoaderWidget40Component} from './co/app/loader/widget/component';
import {CwComponentsModule} from '@cw/components/component.module';
import {CwModule} from '@cw/cw.module';
import {DownloadOnlyComponent} from './download-only/download-only.component';
import {DownComponent} from './components/down/down.component';
import {ConnectionModule} from './connection/connection.module';
import {MarketingPrivacyPolicyComponent} from './marketing/privacy-policy/marketing-privacy-policy.component';
import {PlansViewComponent} from './plans/view/plans-view.component';
import {PlansModule} from './plans/plans.module';
import {PlansSalesComponent} from './plans/sales/plans-sales.component';
import {PlansBuyComponent} from './plans/buy/plans-buy.component';
import {PlansBuySelectComponent} from './plans/buy/select/plans-buy-select.component';
import {PlansBuyRegistrationComponent} from './plans/buy/registration/plans-buy-registration.component';
import {PlansBuyPaymentComponent} from './plans/buy/payment/plans-buy-payment.component';
import {PlansBuySummaryComponent} from './plans/buy/summary/plans-buy-summary.component';
import {PlansBuyDoneComponent} from './plans/buy/done/plans-buy-done.component';
import {CwInputModule} from '@cw/components/input/cw-input.module';
import {CwLiteralModuleExports, CwLiteralModuleImports} from '@cw/literal/model';
import {NgbAccordionModule, NgbProgressbarModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CouponComponent} from './coupon/coupon.component';
import {CwGoogleRecaptchaModule} from '@cw/components/google/recaptcha/cw-google-recaptcha.module';
import {FeedbackComponent} from './feedback/feedback.component';
import {RatesStandardComponent} from './rates/standard/rates-standard.component';
import {RatesCallGroupComponent} from './rates/call-group/rates-call-group.component';
import {RatesCallHeaderComponent} from './rates/call-header/rates-call-header.component';
import {RatesCallComponent} from './rates/call/rates-call.component';
import {RatesShowAllModalComponent} from './rates/show-all-modal/rates-show-all-modal.component';
import {PricingComponent} from './pricing/pricing.component';
import {PricingCallRatesComponent} from './pricing/call-rates/pricing-call-rates.component';
import {DataSignUpInvitationComponent} from './data-sign-up/invitation/data-sign-up-invitation.component';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {PlansSalesPassiveComponent} from './plans/sales/plans-sales-passive.component';
import {DataSignUpPassiveTokenComponent} from './data-sign-up/passive/data-sign-up-passive-token.component';
import {PlansBuyProComponent} from './plans/buy/plans-buy-pro.component';
import {InviteComponent} from './invite/invite.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DataSingUpModuleModule} from './data-sign-up/data-sing-up-module.module';
import {CwComponentGrid2Module} from '@cw/6.0/components/grid2/module';
import {AngularWebStorageModule} from 'angular-web-storage';
import { WebinarComponent } from './webinar/webinar.component';
import {ValidationEmailComponent} from './validation-email/validation-email.component';
// reCAPTCHA v3
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

export const COLLABORATE_DECLARATIONS = [
  CoFeedbackComponent,
  CoAppLoaderWidget40Component,
];

// export function translateFactory(http: Http) {
//   return new TranslateStaticLoader(http, 'assets/i18n', '.json');
// }

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    DownloadComponent,
    NavigationComponent,
    PlansComponent,
    FootComponent,
    HomeComponent,
    HomeHostJoinComponent,
    JoinComponent,
    MeetingIdComponent,
    DataSignUpComponent,
    FreeSingUpComponent, // 2004150000-20
    VerifyComponent,
    DataSignInComponent,
    // InvitationComponent,
    RatesComponent,
    DialinComponent,
    ActivationComponent,
    ResetComponent,
    HostComponent,
    PlansComponent,
    ForgotComponent,
    RegistrationComponent,
    ValidationEmailComponent,
    QuestionComponent,
    CustomerComponent,
    SummaryComponent,
    SummaryRatesComponent,
    TermsComponent,
    CoPreloaderComponent,
    ...COLLABORATE_DECLARATIONS,
    DownloadOnlyComponent,
    DownComponent,
    MarketingPrivacyPolicyComponent,
    PlansViewComponent,
    PlansSalesComponent,
    PlansSalesPassiveComponent,
    PlansBuyComponent,
    PlansBuyProComponent,
    PlansBuySelectComponent,
    PlansBuyRegistrationComponent,
    PlansBuyPaymentComponent,
    PlansBuySummaryComponent,
    PlansBuyDoneComponent,
    CouponComponent,
    FeedbackComponent,
    RatesStandardComponent,
    RatesCallGroupComponent,
    RatesCallHeaderComponent,
    RatesCallComponent,
    RatesShowAllModalComponent,
    PricingComponent,
    PricingCallRatesComponent,
    DataSignUpInvitationComponent,
    DataSignUpPassiveTokenComponent,
    InviteComponent,
    DashboardComponent,
    WebinarComponent,
  ],
  exports: [
    // ...CW_DECLARATIONS_DIRECTIVES
    DownloadOnlyComponent,
    DownComponent,
    MarketingPrivacyPolicyComponent,
    PlansViewComponent,
    PlansSalesComponent,
    PlansSalesPassiveComponent,
    PlansBuyComponent,
    PlansBuyProComponent,
    PlansBuySelectComponent,
    PlansBuyRegistrationComponent,
    PlansBuyPaymentComponent,
    PlansBuySummaryComponent,
    PlansBuyDoneComponent,
    CouponComponent,
    InviteComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    ConnectionModule,
    PlansModule, // before AppRoutingModule
    AppRoutingModule,
    DeviceDetectorModule.forRoot(),
    ...CwLiteralModuleImports,
    CsModule,
    CwComponentsModule,
    CwModule,
    CwInputModule,
    NgbAccordionModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    CwGoogleRecaptchaModule,
    NgxPageScrollModule,
    DataSingUpModuleModule,
    // 2006110844
    CwComponentGrid2Module,
    AngularWebStorageModule,
    RecaptchaV3Module,
  ],
  providers: [
    // 1904170837
    ...CwLiteralModuleExports,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdnPCkcAAAAACUiTgyb3PciKAnNScXRal455UwI' },
  ]
})
export class AppModule {
}
