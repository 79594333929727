/**
 * @since R35.5 - New design - Users - Add.
 */
export class CoLogClass {

  public APP_LEVEL_ALL = 8;
  public APP_LEVEL_TRACE = this.APP_LEVEL_ALL - 1;
  public APP_LEVEL_DEBUG = this.APP_LEVEL_TRACE - 1;
  public APP_LEVEL_INFO = this.APP_LEVEL_DEBUG - 1;
  public APP_LEVEL_WARN = this.APP_LEVEL_INFO - 1;
  public APP_LEVEL_ERROR = this.APP_LEVEL_WARN - 1;
  public APP_LEVEL_FATAL = this.APP_LEVEL_ERROR - 1;
  public APP_LEVEL_OFF = this.APP_LEVEL_FATAL - 1;
  private _level: number = this.APP_LEVEL_ALL;

  get level(): number {
    return this._level;
  }

  set level(value: number) {
    this._level = value;
  }

  public trace(..._messages: any[]): void {
    this.console(this.APP_LEVEL_TRACE, ..._messages);
  }

  public debug(..._messages: any[]): void {
    this.console(this.APP_LEVEL_DEBUG, ..._messages);
  }

  public info(..._messages: any[]): void {
    this.console(this.APP_LEVEL_INFO, ..._messages);
  }

  public warn(..._messages: any[]): void {
    this.console(this.APP_LEVEL_WARN, ..._messages);
  }

  public error(..._messages: any[]): void {
    this.console(this.APP_LEVEL_ERROR, ..._messages);
  }

  public fatal(..._messages: any[]): void {
    this.console(this.APP_LEVEL_FATAL, ..._messages);
  }

  public console(_level: number, ..._messages: any[]): void {
    try {

      if (_level <= this.level) {
        switch (_level) {
          case this.APP_LEVEL_ALL:
          case this.APP_LEVEL_TRACE:
            console.log('CO - TRACE', Date.now(), ..._messages);
            // console.trace(_messages);
            break;
          case this.APP_LEVEL_DEBUG:
            console.log('CO - DEBUG', Date.now(), ..._messages);
            // console.log(_messages);
            break;
          case this.APP_LEVEL_INFO:
            console.log('CO - INFO', Date.now(), ..._messages);
            // console.info(_messages);
            break;
          case this.APP_LEVEL_WARN:
            console.log('CO - WARN', Date.now(), ..._messages);
            // console.warn(_messages);
            break;
          case this.APP_LEVEL_ERROR:
            console.log('CO - ERROR', Date.now(), ..._messages);
            break;
          case this.APP_LEVEL_FATAL:
            console.log('CO - FATAL', Date.now(), ..._messages);
            break;
        }
      }
    } catch {

    }
  }


//   OFF: este es el nivel de mínimo detalle, deshabilita todos los logs.
//   FATAL: se utiliza para mensajes críticos del sistema, generalmente después de guardar el mensaje el programa abortará.
//   ERROR: se utiliza en mensajes de error de la aplicación que se desea guardar,
//    estos eventos afectan al programa pero lo dejan seguir funcionando, como por ejemplo
//    que algún parámetro de configuración no es correcto y se carga el parámetro por defecto.
//   WARN: se utiliza para mensajes de alerta sobre eventos que se desea mantener constancia,
//    pero que no afectan al correcto funcionamiento del programa.
//   INFO: se utiliza para mensajes similares al modo "verbose" en otras aplicaciones.
//   DEBUG: se utiliza para escribir mensajes de depuración. Este nivel no debe estar
//    activado cuando la aplicación se encuentre en producción.
//   TRACE: se utiliza para mostrar mensajes con un mayor nivel de detalle que debug.
//   ALL: este es el nivel de máximo detalle, habilita todos los logs (en general equivale a TRACE).


}

export const CoLog: CoLogClass = new CoLogClass();

