import {Component, OnInit} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-accepted-cards',
  templateUrl: './cw-accepted-cards.component.html',
  styleUrls: ['./cw-accepted-cards.component.css']
})
export class CwAcceptedCardsComponent implements OnInit {

  Cards: any;

  constructor() {
    this.Cards = [
      {
        src: '/literal/assets/card/visa.svg',
        id: 'visa',
      },
      {
        src: '/literal/assets/card/master-card.svg',
        id: 'master-card',
      },
      {
        src: '/literal/assets/card/american-express.svg',
        id: 'american-express',
      },
    ]
  }

  ngOnInit() {
  }

}
