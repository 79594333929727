import {Component, Input, OnInit} from '@angular/core';
import {CwSpacePlanComponent} from '@cw/components/space/plan/cw-space-plan.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-space-plan-zoom',
  templateUrl: './cw-space-plan-zoom.component.html',
  styleUrls: ['./cw-space-plan-zoom.component.css']
})
export class CwSpacePlanZoomComponent
  extends CwSpacePlanComponent
  implements OnInit {

  @Input() PlanNew: any;

  get isFree(): boolean {
    return (this.PlanNew && this.PlanNew.name === 'Free');
  }

  // 2007291000
  get getName(): string {
    if (this.isFree) {
      return 'Personal';
    } else if (this.PlanNew) {
      return this.PlanNew.name;
    } else {
      return '';
    }
  }

  get getTotalHostMonth(): number {
    return this.getTotal / 12;
  }
}
