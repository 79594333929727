import {EntityBus} from '../entity-bus';
import {CwBusParametersI, CwDefineUrl, CwFunction} from '../../model/model';
import {CwToolKeepC} from '../../tool/keep/tool-keep';
import {CwRestRequestParametersI} from '../../tool/rest/model';

/**
 * @version 1904101050
 */
class EntityActivationClass extends EntityBus {
    DefineProperties: CwFunction[];
    Keep: CwToolKeepC<any>;

    // 1904101050
    Parameters: CwEntityActivationParametersI;

    // // 1904101050
    // Setup: any;


    defineUrl: CwDefineUrl = (() => {
        return CwEntityActivation.path +
            CwEntityActivation.Parameters.tokenUrl +
            '/activation'
            ;
    });


    do: CwFunction = ((Param: CwEntityActivationDoI) => {
        // CwEntityActivation.Parameters.tokenUrl = token;
        CwEntityActivation.restPut(<CwEntityActivationDoI>Param);
    });


    SuccessActionList = [
        (Data, That) => {
            // TODO-1904101050
            // CoProtocolBus.check(
            //     this.informationService.client,
            //     this.result.result
            // );
            // CwWeb.goToAdmin(this.result.result);
        },
    ];
}

/**
 * @version 1904101050
 */
export const CwEntityActivation = new EntityActivationClass();

export interface CwEntityActivationParametersI extends CwBusParametersI{
    tokenUrl: string;
}

export interface CwEntityActivationDoI extends CwRestRequestParametersI {
    Parameters: CwEntityActivationParametersI
}
