import {Component} from '@angular/core';
import {CwMapItemI} from '@cw/model/model';
import {AppWebTool} from '../../app-web.tool';
import {AppCw} from '../../app.class';
import {CwInfoInstallationBus} from '@rest/info/installation/cw-info-installation-bus.class';
import {CwRestRequestParametersI} from '@tool/rest/model';
import {CwIs} from '@tool/tool-is';
import {CwLiteral} from '@cw/literal/literal';
import {CwLiteralLanguageI} from '@cw/literal/model';

@Component({
  selector: 'app-marketing-privacy-policy',
  templateUrl: './marketing-privacy-policy.component.html',
  styleUrls: ['./marketing-privacy-policy.component.css']
})
/**
 * @version 2002130840
 */
export class MarketingPrivacyPolicyComponent extends AppCw {

  installationBusReady = false;
  LanguageDefaultCGIL = <CwLiteralLanguageI> {
    language: 'it',
    country: 'IT',
  };

  isNet = (() => window.location.hostname.indexOf('collaboratespace.net') > -1);

  // 2003030920
  Literals = [
    'Privacy Policy 1###H1',
    'Privacy Policy 2###H1',
    'Privacy Policy 3###H1',
    'Privacy Policy 4###H1',
    'Privacy Policy 4.1',
    'Privacy Policy 4.2',
    'Privacy Policy 4.3',
    'Privacy Policy 4.4',
    'Privacy Policy 5###H1',
    (this.isNet())
      ? 'Privacy Policy 5.1 NET'
      : 'Privacy Policy 5.1 US',
    'Privacy Policy 6###H1',
    'Privacy Policy 6.1',
    'Privacy Policy 6.2',
    'Privacy Policy 7###H1',
    'Privacy Policy 7.1',
    'Privacy Policy 7.2',
    'Privacy Policy 8###H1',
    'Privacy Policy 8.1',
    'Privacy Policy 8.2',
    'Privacy Policy 9###H1',
    'Privacy Policy 9.1',
    'Privacy Policy 10###H1',
    'Privacy Policy 10.1',
    'Privacy Policy 10.2',
    'Privacy Policy 10.3',
    'Privacy Policy 11###H1',
    'Privacy Policy 11.1',
    'Privacy Policy 11.2',
    'Privacy Policy 11.3',
    'Privacy Policy 11.4',
    'Privacy Policy 12###H1',
    'Privacy Policy 12.1',
    'Privacy Policy 12.2',
    'Privacy Policy 12.3',
    'Privacy Policy 13###H1',
    'Privacy Policy 13.1',
    'Privacy Policy 13.2',
    'Privacy Policy 13.3',
    'Privacy Policy 14###H1',
    'Privacy Policy 14.1',
    'Privacy Policy 14.2',
    'Privacy Policy 15###H1',
    'Privacy Policy 15.1###H2',
    'Privacy Policy 15.1.1',
    'Privacy Policy 15.2###H2',
    'Privacy Policy 15.2.1',
    'Privacy Policy 15.2.2',
    'Privacy Policy 15.2.3',
    'Privacy Policy 15.3###H2',
    'Privacy Policy 15.3.1',
    'Privacy Policy 16###H1',
    'Privacy Policy 16.1###H2',
    'Privacy Policy 16.1.1',
    'Privacy Policy 16.1.2',
    'Privacy Policy 16.2###H2',
    'Privacy Policy 16.2.1',
    'Privacy Policy 16.3###H2',
    'Privacy Policy 16.3.1',
    'Privacy Policy 16.4###H2',
    'Privacy Policy 16.4.1',
    'Privacy Policy 16.5###H2',
    'Privacy Policy 16.5.1',
    'Privacy Policy 16.5.2',
    'Privacy Policy 16.5.3',
    'Privacy Policy 17###H1',
    'Privacy Policy 17.1',
    'Privacy Policy 17.2',
    'Privacy Policy 17.3',
    'Privacy Policy 17.4',
    'Privacy Policy 17.5',
    'Privacy Policy 17.6',
    'Privacy Policy 17.7',
    'Privacy Policy 17.8',
  ];

  constructor() {
    super();
  }

  isCGILCustomer() {
    return CwInfoInstallationBus.customerName === 'CGIL';
  }

  changeTextByCustomerName() {
    if (this.isCGILCustomer()) {
      this.App.Literal.change({
        cwKey: this.LanguageDefaultCGIL.language
      })
    }
  }

  OnInit() {

    AppWebTool.Title.main = 'PRIVACY POLICY';

    this.installationBusReady = false;
    CwInfoInstallationBus.Load.do(<CwRestRequestParametersI>{
      Bus: CwInfoInstallationBus,
      successActionCustom: ((Data) => {
        if (CwIs.stringNotEmpty(CwInfoInstallationBus.customerName) &&
            this.isCGILCustomer()) {
          this.Literals.forEach(function(part, index) {
            this[index] = CwInfoInstallationBus.customerName + '-' + this[index];
          },  this.Literals);
        }
        this.changeTextByCustomerName();
        this.installationBusReady = true;
      }),
    });

    CwLiteral.onLangChange.subscribe(
        () => {
          this.changeTextByCustomerName();
        }
    );

    this.App.Literal.change(<CwMapItemI>{
      cwKey: 'en',
    });

    // // 2003101253
    // this.App.Literal.change(<CwMapItemI>{
    //   cwKey: this.App.Web.getParam({
    //     ActivatedRoute: this._ActivatedRoute,
    //     paramName: 'language'
    //   })
    // })
  }

}
