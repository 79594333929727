import {SocketSendBus} from '../send-bus';
import {CwFunction} from '../../../model/model';
import {CwSocket} from '../../socket-management';
import {CwSocketSendMessageI} from '../send-model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {SOCKET_ACTION} from '../../socket-model';
import {CwFirmwareBus} from '../../../rest/version/cproversion/cproversion-bus';
import {CwDevicesBus} from '../../../rest/entity/get-users/bus';
import {UiConfig} from '../../../../commons/config/ui.config';
import {TimeFormatHelper} from '../../../../commons/helpers/time-format.helper';
import {CwFileTool} from '../../../tool/file/file.tool';
import {ExpirationEnum} from '../../../../commons/enum/expiration.enum';

class GetLogFilesBus extends SocketSendBus {

  FileList: any = [];
  download = false;

  do: CwFunction = ((Param: { ItemsList: any[] }) => {
    if (
      Param &&
      Param.ItemsList &&
      true
    ) {

      for (const item of Param.ItemsList) {

        const zipFileName = this.getZipLogFileName(item);
        const timeout = setTimeout(() => {
          const FileTimeout = CwGetLogFilesBus.FileList.find((_item) => _item.name === zipFileName);
          if (FileTimeout && FileTimeout.status !== 'Ready') {
            FileTimeout.status = 'Error';
            FileTimeout.message = 'Timeout';
          }
        }, 2 * ExpirationEnum.M);

        const File = {
          name:  zipFileName,
          status : 'Processing' ,
          message: 'Processing',
          timeout: timeout
        };
        this.FileList.push(File);

        CwSocket.send({
          Message: <CwSocketSendMessageI>{
            resources_jid: [item.jid],
            id: CwUtil.uuid(),
            action: SOCKET_ACTION.DEVICE.LOGS.GET,
            content: {
              zip_file_name: zipFileName,
            }
          }
        });
      }

    } else {
      throw Error('Error -missing params- requesting log files of device');
    }
  });
  response: CwFunction;

  getZipLogFileName(item: any): string {
    const timestamp = TimeFormatHelper.dateWithFormat(new Date(), CwFileTool.ZIP_DATE_FORMAT);
    let zipFileName = item.name + '_' + timestamp + '.zip';

    if (item.model && item.MACAddress) {
      const modelDescription =
        UiConfig.RESOURCES.MODEL.TYPES.find(modelType => modelType.API === item.model)
          .LITERAL.replace(/ /g, '_');
      const MACAddress = item.MACAddress.replace(/[^a-zA-Z0-9]/g, '');
      zipFileName = modelDescription + '_' + MACAddress + '_' + timestamp + '.zip';
    }
    return zipFileName;
  }

  getGlobalZipLogFileName(company: string, name: string): string {
    const timestamp = TimeFormatHelper.dateWithFormat(new Date(), CwFileTool.ZIP_DATE_FORMAT);
    const zipFileName = 'logs-devices' + '_' + company + '_' + name + '_' + timestamp + '.zip';
    return zipFileName;
  }

}

export const CwGetLogFilesBus = new GetLogFilesBus();
