import {Component, Input} from '@angular/core';
import {CwSpacePlanComponent} from '@cw/components/space/plan/cw-space-plan.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: ' [cw-space-plan-button',
  templateUrl: './cw-space-plan-button.component.html',
  styles: []
})
export class CwSpacePlanButtonComponent
  extends CwSpacePlanComponent {
  @Input() PlanNew: any;
}
