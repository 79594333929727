import {CwBusClass} from '../model/bus';
import {CsFunction} from '../../cs/model/model';
import {CwToolKeepC} from '../tool/keep/tool-keep';
import {CwDefineUrl, CwFunction, CwTokenBusI} from '../model/model';
import {environment} from '../../../environments/environment';
import {CwSessionKeepI} from './session-model';

/**
 * @version 1.11.0 - Partners - AuthGuard and LastNavigation - 1902050959
 */
class SessionBus
  extends CwBusClass {
  DefineProperties: CsFunction[];
  Keep: CwToolKeepC<CwSessionKeepI> = new CwToolKeepC({
    key: environment.Session.keepKey,
    DataDefault: <CwSessionKeepI>{
      navigation: '',
      partnerId: null,
      token: '',
      updated: Date.now()
    }
  });

  Setup: any;
  SuccessActionList: any[];
  SuccessActionOnceList: any[];
  TokenBus: CwTokenBusI;
  defineUrl: CwDefineUrl;
  do: CwFunction;

  set navigation(value: string) {
    const LastSession: CwSessionKeepI = this.Keep.Keep;
    LastSession.navigation = value;
    LastSession.updated = Date.now();
    this.Keep.Keep = LastSession;
  }

  get navigation(): string {
    return (<CwSessionKeepI>this.Keep.Keep).navigation;
  }

  set token(value: string) {
    if (value) {
      const LastSession: CwSessionKeepI = this.Keep.Keep;
      if (LastSession) {
        LastSession.token = value;
        LastSession.updated = Date.now();
        this.Keep.Keep = LastSession;
      }
    }
  }

  get partnerId(): any {
    return (
      this.Keep &&
      this.Keep.Keep &&
      true
    )
      ? (<CwSessionKeepI>this.Keep.Keep).partnerId
      : null
      ;
  }
  set partnerId(value: any) {
    if (value) {
      const LastSession: CwSessionKeepI = this.Keep.Keep;
      if (LastSession) {
        LastSession.partnerId = value;
        LastSession.updated = Date.now();
        this.Keep.Keep = LastSession;
      }
    }
  }

  get token(): string {
    return (
      this.Keep &&
      this.Keep.Keep &&
      true
    )
      ? (<CwSessionKeepI>this.Keep.Keep).token
      : null
      ;
  }

}

export const CwSession = new SessionBus();
