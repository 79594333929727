import {Component, OnInit} from '@angular/core';
import {CwWeb} from '@tool/web/tool-web';
import {DataSignUpPassiveComponent} from './data-sign-up-passive.component';
import {ActivatedRoute} from '@angular/router';
import {CwTokenGet} from '@cw/token/get/get-bus';
import {CwRestRequestParametersI} from '@tool/rest/model';
import {AppDataSignUpToolClass} from '../data-sign-up.tool';
import {AppPlansTool} from '../../plans/plans.tool';
import {AppWebTool} from '../../app-web.tool';
import {CwProtocolTool} from '@tool/protocol/tool-protocol';
import {CW_PROTOCOL_TOOL} from '@tool/protocol/model';

@Component({
  selector: 'app-data-sign-up-passive',
  templateUrl: './data-sign-up-passive.component.html',
  styleUrls: ['./data-sign-up-passive.component.scss']
})
/**
 * @version 2007020000.1
 */
export class DataSignUpPassiveTokenComponent
  extends DataSignUpPassiveComponent
  implements OnInit {

  AppDataSignUpTool = new AppDataSignUpToolClass({inputSetReset: true});
  token: any;
  action: string;
  defineForm: any;



  // 2011160000.D1
  defineFormInvitation = ((Data) => {
    // "{"jid":"177863266@collaboratespace.us","name":"Mexico Lindo", "applicant":"101007000@collaboratespace.us"}"
    if (Data && Data.result) {
      this._invitedByVisible = true;
      this._invitedBy = Data.result.applicantName;

      this.AppDataSignUpTool.Invitation.applicant = Data.result.applicant;
      this.AppDataSignUpTool.Invitation.jid = Data.result.jid;

      this.AppDataSignUpTool.InputEmail.enabled = false;
      this.AppDataSignUpTool.InputEmail.value = Data.result.email;
      this._emailOffsetVisible = true;

      this.AppDataSignUpTool.InputFirstName.value = Data.result.firstName;
      this.AppDataSignUpTool.InputLastName.value = Data.result.lastName;

      this._countryAndStateVisible = true;

      this.AppDataSignUpTool.Registration.InputSet = [
        this.AppDataSignUpTool.InputFirstName,
        this.AppDataSignUpTool.InputLastName,
        this.AppDataSignUpTool.InputPassword,
        this.AppDataSignUpTool.InputPasswordRepeat,
      ]

    }
  })

  defineFormPassword = ((Data) => {
    if (Data && Data.result) {
      // 2010151600.1
      this.AppDataSignUpTool.InputReasonOfUse.enabled = false;
      this.AppDataSignUpTool.InputEmail.enabled = false;
      this.AppDataSignUpTool.InputEmail.value = Data.result.email;


      this.AppDataSignUpTool.InputFirstName.enabled = false;
      this.AppDataSignUpTool.InputFirstName.value = Data.result.name;


      this.AppDataSignUpTool.Registration.InputSet = [
        this.AppDataSignUpTool.InputFirstName,
        this.AppDataSignUpTool.InputEmail,
        this.AppDataSignUpTool.InputPassword,
        this.AppDataSignUpTool.InputPasswordRepeat,
      ]
    }
  });

  activationSuccessActionCustom = ((Data) => {
    CwWeb.loaderHide();

    AppPlansTool.Buy.SignIn = {
      email: this.AppDataSignUpTool.InputEmail.value,
      password: this.AppDataSignUpTool.InputPassword.value,
    }
    CwWeb.loaderVisible = false;
    CwWeb.goTo({path: '/datasignin'});

  });

  constructor(
    private _ActivatedRoute: ActivatedRoute,
  ) {
    super();
  }

  get getCountryAndStateVisible(): boolean {
    return this._countryAndStateVisible;
  }


  ngOnInit() {
    super.ngOnInit();
    const queryParamToken = CwWeb.getToken({
      ActivatedRoute: this._ActivatedRoute
    });
    const queryParamTokenP = CwWeb.getParam({
      ActivatedRoute: this._ActivatedRoute,
      paramName: 'tokenp'
    });

    if (queryParamToken) {

      // 2007290008
      AppWebTool.Title = {
        main: '',
        secondary: 'Invitation',
      };

      this.action = 'INVITATION';
      this.token = queryParamToken
      CwProtocolTool.source = CW_PROTOCOL_TOOL.SOURCE.INVITE;
      this.defineForm = this.defineFormInvitation;
      this.On.SignUp.clicked = (() => {
        this.AppDataSignUpTool.Invitation.On.clicked();
      })

    } else if (queryParamTokenP) {
      // 2007290008
      AppWebTool.Title = {
        main: 'COLLABORATE Space',
        secondary: 'Activation',
      };

      this.action = 'PASSWORD'
      this.defineForm = this.defineFormPassword;
      this.token = queryParamTokenP;
      this.On.SignUp.clicked = (() => {
        this.AppDataSignUpTool.Activation.On.clicked(this.token);
      })
      this.AppDataSignUpTool.Registration.On.successActionCustom = this.activationSuccessActionCustom;
    }

    if (this.token) {
      CwTokenGet.do(<CwRestRequestParametersI>{
        Bus: CwTokenGet,
        Parameters: {token: this.token, action: this.action},
        errorRestActionCustom: ((Data) => {
          // 2008061040
          AppWebTool.Title = {
            main: '',
            secondary: '',
          }
          CwWeb.goTo({path: '/datasignin'});
        }),
        errorActionCustom: ((Data) => {
          // 2008061040
          AppWebTool.Title = {
            main: '',
            secondary: '',
          }
          CwWeb.goTo({path: '/datasignin'});
        }),
        successActionCustom: this.defineForm,
      });
    }
  }

}
