import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CW_GRID, GridRow, SetupGrid} from './grid';
import {GridPaginationComponent} from './grid-pagination/grid-pagination.component';
import {ColumnEnum} from '../../enums';
import {CwIs} from '../../tool/tool-is';
import {ExpirationEnum} from '../../../commons/enum/expiration.enum';
import {TimeFormatHelper} from '../../../commons/helpers/time-format.helper';
import {CwModal} from '../modal/modal';
import {CwPageBus} from '../page/cw-page-bus';
import {CwPageOpenI} from '../page/cw-page-model';
import {GridToolbarComponent} from './grid-toolbar/grid-toolbar.component';
import {GridTableHeaderComponent} from './grid-table-header/grid-table-header.component';
import {HISTORY_MAP} from '../../../../environments/environment.define';
import {Router} from '@angular/router';
import {CwTrust} from '../../trust/trust';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwFileTool} from '../../tool/file/file.tool';
import {CwLiteral} from '../../literal/literal';
import {CwWeb} from '../../tool/web/web.tool';
import {CwMessageActionClass} from '../../tool/message-action/tool';
import {CwDateTool} from '../../tool/date/date.tool';
import {CW_FILE_TOOL} from '../../tool/file/file.model';
import {isString} from 'util';
import {isArray} from 'rxjs/internal-compatibility';
import {UiConfig} from '../../../commons/config/ui.config';
import {CwWebBus} from '@rest/token/get-web/get-web-bus';
import {CwVersionCheckPlatform} from '@cw/version/check/check-model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit, OnDestroy {


  @Input() Setup: SetupGrid;
  @Output() OnGridRendered = new EventEmitter();
  // 2006020000
  // // tslint:disable-next-line:no-output-on-prefix
  @Output() showAllClicked = new EventEmitter();
  @Output() dateFilterChanged = new EventEmitter();
  @Output() searchTextChanged = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() paginationChanged = new EventEmitter();
  @Output() statusFilteringChanged = new EventEmitter();

  @ViewChild('GridTable') public GridTable: any;
  @ViewChild(GridPaginationComponent) public GridPagination;
  @ViewChild(GridToolbarComponent) public GridToolbar;
  @ViewChild(GridTableHeaderComponent) public GridTableHeaderComponent;

  CwWeb = CwWeb;
  // 2009290000
  Web = {
    bold: (Item, Column): string => {
      if (
        Column &&
        Column.bold &&
        Item &&
        Item[Column.bold]
      ) {
        return 'font-weight-bold';
      } else if (
        Column &&
        Column.boldIfCallback &&
        Item &&
        this.Setup.boldIfCallback &&
        this.Setup.boldIfCallback(Item)
      ) {
        return 'font-weight-bold';
      } else {
        return '';
      }
    },

    // 2009290000.B2
    trStyle: (): any => {
      if (this.Web.hasAvatar()) {
        return {'height': '53px'};
      } else {
        return {'height': 'auto'};
      }
    },

    // 2009290000.B2
    hasAvatar: (): boolean => {
      return CwUtil.exist({
        Array: this.Setup.GridColumns,
        condition: (Item): boolean => {
          return Item.columnType === ColumnEnum.TYPE_AVATAR;
        }
      });
    },

    // 2009290000.14
    iconTooltip: (Item, Column): string => {
      if (Column.tooltipLiteral) {
        return Column.tooltipLiteral;
      } else if (Column.tooltipLiteralField) {
        return Item[Column.tooltipLiteralField];
      }
    },

    // 2009290000.14
    iconTooltipEnabled: (Item, Column): boolean => {
      return (
        Column.tooltipLiteral ||
        Column.tooltipLiteralField
      );
    },

    osIconLogo: (value): boolean => {
      try {
        if (
          value === CwVersionCheckPlatform.windows ||
          value.toUpperCase() === 'WINDOWS' ||
          value.toUpperCase() === 'WIN'
        ) {
          return true;
        } else if (
          value === CwVersionCheckPlatform.mac ||
          value.toUpperCase() === 'MAC'
        ) {
          return true;
        } else if (
          value.toUpperCase() === 'PHONE'
        ) {
          return false;
        } else if (
          value.toUpperCase() === 'H.323/SIP Endpoint'.toUpperCase()
        ) {
          return false;
        } else if (
          value.toUpperCase() === 'iPhone/iPad'.toUpperCase()
        ) {
          // TODO-2009290000
          return false;
        } else if (
          value === CwVersionCheckPlatform.android ||
          value.toUpperCase() === 'Android'.toUpperCase()
        ) {
          // TODO-2009290000
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    osIcon: (value): string => {
      /**
       * TODO-2009290000
       case 11:
       case 12:
       return "H.323/SIP Endpoint";
       case 13:
       case 14:
       case 20:
       return "iPhone/iPad";
       case 23:
       case 24:
       return "Android";
       default:
       return null;
       */
      return '';

    },

    tooltipDisabled: (Item, Column) => {
      if (this.isMobile) {
        return true;
      } else if (
        Column &&
        Column.tooltipLiteralIfField &&
        Column.tooltipLiteral &&
        Item &&
        Item[Column.tooltipLiteralIfField] &&
        true
      ) {
        return false;
      } else {
        return true;
      }
    }, // tooltipDisabled
  };
  //////////////////////////////////////////////////////////////////////////////


  // EVENTs ////////////////////////////////////////////////////////////////////
  On = {}; // On
  //////////////////////////////////////////////////////////////////////////////


  /**
   * @version 1911050852
   */
  exportToFile = ((Params: { fileName: string, extraData?: any }) => {
    CwFileTool.Csv.export({
      filename: Params.fileName,
      Items: {
        ...this.formatData(
          (this.getSelected() && this.getSelected().length > 0)
            ? this.getSelected()
            : (
              this.GridFilteredList &&
              this.GridFilteredList.length > 0 &&
              true
            )
            ? this.GridFilteredList
            : this.Setup.GridData,
          Params.extraData
        )
      }
    });
  });

  /**
   * @version 1911050925
   */
  formatData = ((OriginalDataList, extraData?): {
    FieldList: string[],
    DataList: any[],
    HeaderList: string[],
  } => {
    const FieldList: string[] = [];
    const HeaderList: string[] = [];
    const DataList: any[] = CwUtil.clone(OriginalDataList);
    const ColumnsToFormatList: any[] = [];
    this.Setup.GridColumns.forEach(
      ColumnItem => {
        if (ColumnItem.Export) {
          FieldList.push(ColumnItem.Export.field);
          HeaderList.push(CwLiteral.translate(ColumnItem.Export.header));
          ColumnsToFormatList.push(ColumnItem.Export);
        }
      });

    if (extraData) {
      extraData.forEach(
        extraDataItem => {
          FieldList.push(extraDataItem.field);
          HeaderList.push(CwLiteral.translate(extraDataItem.header));
          ColumnsToFormatList.push(extraDataItem);
        });
    }

    DataList.forEach(FormatItem => {
      ColumnsToFormatList.forEach(ColumnItem => {
        if (ColumnItem.processingExportFunction) {
          ColumnItem.processingExportFunction(FormatItem, ColumnItem.field);
        }
        if (ColumnItem.translate) {
          if (
            FormatItem[ColumnItem.field] &&
            isArray(FormatItem[ColumnItem.field]) &&
            FormatItem[ColumnItem.field][0] &&
            isString(FormatItem[ColumnItem.field][0]) &&
            true
          ) {
            for (let index = 0; index < FormatItem[ColumnItem.field].length; index++) {
              FormatItem[ColumnItem.field][index] = CwLiteral.translate(FormatItem[ColumnItem.field][index]);
            }
            FormatItem[ColumnItem.field] = FormatItem[ColumnItem.field].join(CW_FILE_TOOL.SEPARATOR_LIST);
          } else {
            FormatItem[ColumnItem.field] = CwLiteral.translate(FormatItem[ColumnItem.field]);
          }
        } else if (FormatItem[ColumnItem.field] instanceof Date) {
          // 1911281603
          FormatItem[ColumnItem.field] = CwDateTool.toString({
            format: TimeFormatHelper.DATE_FORMAT_FULL,
            Date: (ColumnItem.utc)
              ? FormatItem[ColumnItem.field]
              : CwDateTool.utcToDateTimeZone(FormatItem[ColumnItem.field])
            ,
          });
        }
      });
    });

    return {
      DataList,
      FieldList,
      HeaderList,
    };
  });

  dateFormat = TimeFormatHelper.DATE_FORMAT_STANDARD;
  dateFormatComplete = TimeFormatHelper.DATE_FORMAT_FULL;

  CwMessageAction: CwMessageActionClass;

  language = CwLiteral.Language;
  UiConfig = UiConfig;
  CwDateTool = CwDateTool;

  COLUMN = ColumnEnum;

  isMobile = CwWeb.isMobile;

  GridMobileStyle = (CwWeb.isMobile)
    ? {
      'overflow': 'auto',
      'white-space': 'nowrap',
    }
    : undefined
  ;

  public selectAllColumn = true;
  public showFilters = true;
  public initDateFilter = '';

  readonly ascendingSortingClass = 'headerSortUp';
  readonly descendingSortingClass = 'headerSortDown';

  GridCurrentPage: any = [];
  GridFilteredList: any = [];
  HeaderSetup: any;

  checkAll = false;
  searchedText = '';
  filteringInfo = {startTime: '', endTime: '', queryMode: false};
  sortedColumn: any;
  attempts = ExpirationEnum.NUMBER_OF_ATTEMPS;

  gridLoading: boolean;
  busFinished: boolean;
  currentItemsPerPage: number;
  columnFilterDate = '';
  tooMuchFiltered = false;
  private _selectedStatusFilteringOption = '';

  timeout: NodeJS.Timer;

  // 2002111112
  _Setup = {
    Visible: {
      cwGridPagination: true,
      selectAll: true,
      totalIncluded: false,
      trSelectedBackground: true,
    },
    Class: {
      th: '',
      grid: 'grid-basic',
      table: '',
      gridMobile: 'mt-3',
    },
    define: () => {
      this._Setup.defineTypeSelectionModal();
      this._Setup.defineTypeSubscription();
    },
    defineTypeSelectionModal: () => {
      if (this.Setup && this.Setup.type === CW_GRID.TYPE.SELECTION_MODAL) {
        this._Setup.Visible.cwGridPagination = false;
        this._Setup.Visible.selectAll = false;
        this._Setup.Visible.totalIncluded = true;
        this._Setup.Visible.trSelectedBackground = false;
        this._Setup.Class.th = 'text-black';
        this._Setup.Class.grid = '';
        this._Setup.Class.table = 'border';
        this._Setup.Class.gridMobile = '';
        // if (!this.Setup.Hidden) {
        //   this.Setup.Hidden = {};
        // }
        // this.Setup.Hidden.datepickerTool = true;
        // this.Setup.Hidden.searchTool = true;
      }
    },
    // 2002201042
    defineTypeSubscription: () => {
      if (this.Setup && this.Setup.type === CW_GRID.TYPE.SUBSCRIPTION) {
        this._Setup.Visible.cwGridPagination = false;
      }
    }

  };


  reload = ((Data?: any): void => {
    if (Data && this.Setup) {
      this.Setup.GridData = Data;
    }
    this.isAllSelected();
    this.afterGridDataIsFilled();
    this.reloadFilterData();
    if (this.sortedColumn) {
      this.sortItemsWithoutToggle(this.sortedColumn);
    }
  });


  // GETs //////////////////////////////////////////////////////////////////////

  get getGridPaginationHidden(): boolean {
    return !this._Setup.Visible.cwGridPagination;
  }

  get getGridMobileStyle(): string {
    return (this.Setup && this.Setup.viewOnlyWithScroll)
      ? 'table-wrapper-scroll-y custom-scrollbar ' + this._Setup.Class.gridMobile
      : this._Setup.Class.gridMobile
      ;
  }


  //////////////////////////////////////////////////////////////////////////////


  constructor(
    private router: Router,
    public Element: ElementRef<any>,
  ) {
  }

  ngOnInit(): void {
    // 1910071306
    if (
      this.Setup &&
      this.Setup.App &&
      this.Setup.App.inBackground &&
      this.Setup.App.loaded &&
      true
    ) {
      this.busFinished = true;
      // this.gridRender();
      this.afterRender();
      this.OnGridRendered.emit();
      this.gridLoading = false;
      return null;
    }

    if (CwTrust.iframeBehaviour) {
      this.initMessageAction();
    }

    this._Setup.define();

    console.log('start waiting for data');
    this.waitTillGridDataIsFilled();
  }

  ngOnDestroy(): void {
    // Deselect
    this.Setup.GridData.forEach(DataItem => {
      DataItem.isSelected = false;
    });
    this.Setup.GridColumns.forEach((header) => {
      header.sortingClass = '';
    });
    clearTimeout(this.timeout);

    if (CwTrust.iframeBehaviour && this.CwMessageAction) {
      this.CwMessageAction.remove();
    }
  }

  waitTillGridDataIsFilled(): void {
    this.gridLoading = true;
    // busFinished can be set to true after the successful action call from the bus
    // in total grid will wait up to 20 seconds = 40 attemps * 0.5 seg
    if (!this.busFinished && this.attempts > 0) {
      this.timeout = setTimeout(() => {
        console.log('waiting for data', this.attempts);
        this.attempts--;
        this.waitTillGridDataIsFilled();
      }, ExpirationEnum.XXXXS2);
    } else {
      clearTimeout(this.timeout);
      console.log('data complete');
      this.afterGridDataIsFilled();
      console.log('start rendering');
      this.gridRender();
      this.gridLoading = false;
    }
  }

  gridRender(): void {
    if (CwIs.stringNotEmpty(this.GridTable.nativeElement.lastChild.innerText)) {
      console.log('rendering complete');
      this.afterRender();
      this.OnGridRendered.emit();
    } else {
      setTimeout(() => {
        console.log('rendering on progress');
        this.gridRender();
      }, ExpirationEnum.XXXXS);
    }
  }

  afterGridDataIsFilled(): void {
    this.itemsPerPageChangedHandler(this.GridPagination.pagingInfo.perPage);
    if (
      this.Setup &&
      this.Setup.GridData &&
      true
    ) {
      for (const item of this.Setup.GridData) {
        item.GridRow = {};
        const values: string[] = [];
        for (const Column of this.Setup.GridColumns) {
          values.push(CwLiteral.translate(item[Column.field]));
          if (Column.extraFilterProperty) {
            values.push(CwLiteral.translate(item[Column.extraFilterProperty]));
          }
        }
        item.GridRow = new GridRow(this.Setup.GridData.indexOf(item), values);
      }
      for (const item of this.Setup.GridColumns) {
        if (item.dateFilter) {
          this.columnFilterDate = item.field;
        }
      }
    }
  }

  afterRender(): void {
    jQuery(function () {
      jQuery('[data-toggle="tooltip"]').tooltip({trigger: 'hover'});
      jQuery('[data-toggle="dropdown"]').tooltip({trigger: 'hover'});
      jQuery('[data-toggle="popover"]').popover();
      jQuery('.popover-dismiss').popover({
        trigger: 'focus',
      });
    });
  }

  reloadFilterData() {
    if (
      this.Setup &&
      this.Setup.GridData &&
      true
    ) {

      // In case Language has changed;
      if (this.language !== CwLiteral.Language) {
        this.language = CwLiteral.Language;
        this.afterGridDataIsFilled();
      }

      this.GridFilteredList = this.Setup.GridData.filter(this.filterByTextAndDate, this);

      this.GridFilteredList.length === 0 &&
      this.Setup.GridData.length > 0 &&
      !this.gridLoading &&
      ((this.filteringInfo.startTime && this.filteringInfo.endTime && !this.filteringInfo.queryMode) ||
        this.searchedText)
        ? this.tooMuchFiltered = true
        : this.tooMuchFiltered = false
      ;
      if (
        this.Setup &&
        this.Setup.GridData &&
        this.GridFilteredList.length > 0 &&
        this.GridFilteredList.length < this.Setup.GridData.length &&
        true
      ) {
        this.GridPagination.pagingInfo.current = 0;
      }

      if (this.sortedColumn) {
        this.sortItemsWithoutToggle(this.sortedColumn);
      } else {
        this.itemsPerPageChangedHandler(this.GridPagination.pagingInfo.perPage);
      }

    }
  }

  // SEARCH ACTIONS
  searchTextChangedHandler(text: string) {
    this.searchedText = text;
    this.reloadFilterData();
    this.searchTextChanged.emit(text);
  }

  isSearchTextEmpty() {
    return !this.searchedText;
  }

  // DATE FILTER ACTIONS
  filteringInfoChangedHandler(filteringInfo: any) {
    this.filteringInfo = filteringInfo;
    if (this.filteringInfo.queryMode) {
      this.GridPagination.resetCurrentPage();
    }
    this.reloadFilterData();
    this.dateFilterChanged.emit(this.filteringInfo);
  }

  filterByTextAndDate(item) {
    if (
      this.filteringInfo.startTime &&
      this.filteringInfo.endTime &&
      !this.filteringInfo.queryMode &&
      // 1910301146
      item &&
      item.GridRow &&
      item.GridRow.stringValues &&

      true
    ) {
      const startDate = TimeFormatHelper.toUtc(new Date(this.filteringInfo.startTime));
      const endDate = TimeFormatHelper.toUtc(new Date(this.filteringInfo.endTime));
      const itemDate = TimeFormatHelper.toUtc(new Date(item[this.columnFilterDate]));
      if (this.searchedText) {
        return item.GridRow.stringValues.includes(this.searchedText.toLowerCase()) && itemDate >= startDate && itemDate <= endDate;
      } else {
        return itemDate >= startDate && itemDate <= endDate;
      }
    }
    return (
      item &&
      item.GridRow &&
      item.GridRow.stringValues &&
      this.searchedText &&
      true
    )
      ? item.GridRow.stringValues.includes(this.searchedText.toLowerCase())
      : null;
  }

  // SELECTION
  toggleAll(Event: any) {
    if (this.GridFilteredList.length > 0) {
      for (const Item of this.GridFilteredList) {
        if (!Item.isDisabledRow) {
          Item.isSelected = this.checkAll;
        }
      }
    } else if (this.Setup.GridData) {
      for (const Item of this.Setup.GridData) {
        if (!Item.isDisabledRow) {
          Item.isSelected = this.checkAll;
        }
      }
    }
    this.selectionChanged.emit();
  }

  getSelectionTooltipText() {
    return !this.checkAll ? 'Select All' : 'Unselect All';
  }

  public isAllSelected() {
    if (
      this.Setup &&
      this.Setup.GridData &&
      this.Setup.GridData.length > 0 &&
      true
    ) {
      this.checkAll = this.Setup.GridData.every(function (_item: any) {
        return _item.isSelected && !_item.isDisabledRow;
      });
    } else {
      this.checkAll = false;
    }
    this.selectionChanged.emit();
  }

  public cleanSelection() {
    this.Setup.GridData.forEach(function (_item: any) {
      _item.isSelected = false;
    });
    if (this.GridFilteredList &&
      this.GridFilteredList.length > 0) {
      this.GridFilteredList.forEach(function (_item: any) {
        _item.isSelected = false;
      });
    }
  }

  get someItemSelected(): boolean {
    return this.getSelected().length > 0;
  }

  /**
   * @version 1907230828
   */
  public getSelected(): any[] {
    return (
      this.Setup &&
      this.Setup.GridData &&
      true
    )
      ? this.Setup.GridData.filter(Item => Item.isSelected && !Item.isDisabledRow)
      : [];
    // return this.Setup.GridData.filter(Item => Item.isSelected && !Item.isDisabledRow);
  }

  public setSelectedAll() {
    if (this.GridFilteredList.length > 0) {
      this.GridFilteredList.forEach(Item => Item.isSelected = true);
    } else {
      this.Setup.GridData.forEach(Item => Item.isSelected = true);
    }
  }

  public getColumnSelected(field: any): any[] {
    const columnItems = [];
    if (
      this.Setup &&
      this.Setup.GridData &&
      true
    ) {
      for (const item of this.Setup.GridData) {
        if (item.isSelected) {
          columnItems.push(item[field]);
        }
      }
    }
    return columnItems;
  }

  // SORTING
  onTableHeaderClicked(header: any): void {
    this.sortedColumn = header;
    this.clearAllSortingStatus(header);
    this.toggleSortingStatusClass(header);
    if (this.GridFilteredList.length > 0) {
      this.sortItems(this.GridFilteredList, header);
    } else {
      this.sortItems(this.Setup.GridData, header);
    }
    this.itemsPerPageChangedHandler(this.GridPagination.pagingInfo.perPage);
  }

  sortItemsWithoutToggle(header: any): void {
    this.sortedColumn = header;
    this.clearAllSortingStatus(header);
    if (this.GridFilteredList.length > 0) {
      this.sortItems(this.GridFilteredList, header);
    } else {
      this.sortItems(this.Setup.GridData, header);
    }
    this.itemsPerPageChangedHandler(this.GridPagination.pagingInfo.perPage);
  }

  sortItems(listObject, header): void {
    let field;
    header.sortingField ? field = header.sortingField : field = header.field;

    if (header.sortingFunction) {
      header.sortingFunction(listObject, header);
    } else {
      if (header.sortingClass === 'headerSortDown') {
        listObject.sort(function (obj1, obj2) {
          if (obj1[field] > obj2[field]) {
            return 1;
          }
          if (obj1[field] < obj2[field] || obj2[field] === undefined) {
            return -1;
          }
          return 0;
        });
      } else {
        listObject.sort(function (obj2, obj1) {
          if (obj1[field] > obj2[field]) {
            return 1;
          }
          if (obj1[field] < obj2[field] || obj2[field] === undefined) {
            return -1;
          }
          return 0;
        });
      }
    }

  }

  toggleSortingStatusClass(header): void {
    if (header.sortingClass === this.ascendingSortingClass) {
      header.sortingClass = this.descendingSortingClass;
    } else if (header.sortingClass === this.descendingSortingClass ||
      header.sortingClass === '' || !header.sortingClass) {
      header.sortingClass = this.ascendingSortingClass;
    }
  }

  clearAllSortingStatus(headerClicked): void {
    this.Setup.GridColumns.forEach((header) => {
      if (headerClicked.field !== header.field) {
        header.sortingClass = '';
      }
    });
  }

  // PAGINATION
  itemsPerPageChangedHandler(itemsPerPage: number, emit?: boolean) {
    this.currentItemsPerPage = itemsPerPage;
    this.GridCurrentPage = [];
    let totalPages = 0;

    const startingIndex = this.GridPagination.pagingInfo.current * itemsPerPage;
    const endingIndex = +startingIndex + +itemsPerPage;

    if (this.GridFilteredList.length > 0) {
      if (itemsPerPage === 0) {
        totalPages = 1;
        for (const item of this.GridFilteredList) {
          this.GridCurrentPage.push(item);
        }
      } else {
        totalPages = Math.ceil(this.GridFilteredList.length / itemsPerPage);
        for (let i = startingIndex; i < endingIndex && i < this.GridFilteredList.length; i++) {
          this.GridCurrentPage.push(this.GridFilteredList[i]);
        }
      }
    } else {
      if (itemsPerPage === 0) {
        totalPages = 1;
        if (
          this.Setup &&
          this.Setup.GridData &&
          true
        ) {
          for (const item of this.Setup.GridData) {
            this.GridCurrentPage.push(item);
          }
        }
      } else {
        totalPages = (
          this.Setup &&
          this.Setup.GridData &&
          this.Setup.GridData.length &&
          this.Setup.GridData.length > 0 &&
          true
        )
          ? Math.ceil(this.Setup.GridData.length / itemsPerPage)
          : 0
        ;
        // totalPages = Math.ceil(this.Setup.GridData.length / itemsPerPage);
        for (
          let i = startingIndex;
          i < endingIndex && i < ((
              this.Setup &&
              this.Setup.GridData &&
              true
            )
              ? this.Setup.GridData.length
              : 0
          )
          ;
          i++
        ) {
          this.GridCurrentPage.push(this.Setup.GridData[i]);
        }
      }
    }
    this.GridPagination.pagingInfo.total = totalPages;
    if (emit) {
      this.paginationChanged.emit();
    }
  }

  openPage(Param: CwPageOpenI): void {
    if (Param.ItemList) {
      Param.ItemList.forEach(item => item.detailsSelected = false);
      if (Param.paramId) {
        Param.ItemList.find((item) => item[Param.paramId] === Param.Item[Param.paramId]).detailsSelected = true;
      } else {
        Param.ItemList.find((item) => item.jid ? item.jid === Param.Item.jid : item.email === Param.Item.email).detailsSelected = true;
      }
    }
    CwPageBus.open(Param);
  }

  openModal(ModalParams): void {
    CwModal.open(ModalParams);
  }

  columnFirmwareOnClick(Item: any): void {
    if (
      Item &&
      this.Setup &&
      this.Setup.firmwareAction &&
      true) {
      this.Setup.firmwareAction(Item);
    }
  }

  // ROW SELECTION WITH EVENTS
  onTableRowClicked(event: any, item: any) {
    if (event.target.type === 'checkbox' || item.isDisabledRow) {
      return;
    }
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    if (event.ctrlKey || (isMac && event.metaKey)) {
      this.handleSingleClickWithControl(item);
    } else if (event.shiftKey) {
      this.handleSingleClickWithShift(item);
    } else {
      this.handleSingleClick(item);
    }
    this.isAllSelected();
  }

  private handleSingleClickWithControl(item: any) {
    if (item.isSelected) {
      item.isSelected = false;
      const indexOfItem = this.getSelected().indexOf(item);
      this.getSelected().splice(indexOfItem, 1);
    } else {
      item.isSelected = true;
      this.getSelected().push(item);
    }
  }

  private handleSingleClickWithShift(item: any) {
    const from = this.Setup.GridData.findIndex(Item => Item.isSelected);
    const to = this.Setup.GridData.findIndex(Item => Item === item);
    const reversed = from > to;
    this.cleanSelection();
    for (let i = from; reversed ? i >= to : i <= to; reversed ? --i : ++i) {
      if (!this.Setup.GridData[i].isDisabledRow) {
        this.Setup.GridData[i].isSelected = true;
      }
    }
  }

  private handleSingleClick(item: any) {
    this.cleanSelection();
    item.isSelected = true;
  }

  getSpecialClassCell(Column: any): boolean {
    if (Column.columnType === ColumnEnum.TYPE_AVATAR) {
      return true;
    }
    return false;
  }

  getLinkVisible(itemElement: any) {
    return (!this.Setup.viewOnly &&
      HISTORY_MAP.get(itemElement) !== undefined &&
      (HISTORY_MAP.get(itemElement).ownerOnly === undefined ||
        (HISTORY_MAP.get(itemElement).ownerOnly && CwWebBus.Is.owner))
    );
  }

  getActionPage(itemElement: any) {
    return HISTORY_MAP.get(itemElement);
  }

  navigateToPageWithParams(actionObject): void {
    if (CwTrust.adminBehaviour) {
      CwPageBus.navigate({Router: this.router, ActiveTab: actionObject.activeTab, Action: actionObject.action});
    } else {
      this.sendMessageAction(actionObject);
    }
  }

  get selectedStatusFilteringOption(): string {
    return this._selectedStatusFilteringOption;
  }

  set selectedStatusFilteringOption(value: string) {
    this._selectedStatusFilteringOption = value;
  }

  getFullDataNoPagination() {
    return this.GridFilteredList.length > 0 ? this.GridFilteredList : this.Setup.GridData;
  }

  private initMessageAction() {
    this.CwMessageAction = new CwMessageActionClass({});
  }

  private sendMessageAction(action: any) {
    this.CwMessageAction.send({
      Data: action,
      action: 'link-navigation-page',
      errorFn: ((Params) => {
        console.error('link-navigation-page', Params);
      })
    });
  }

  statusIcon(status: string) {
    if (status.toUpperCase() === 'DOWN') {
      return 'icon-arrow-with-circle-down down-status';
    } else if (status.toUpperCase() === 'HEALTHY') {
      return 'icon-arrow-with-circle-up healthy-status';
    } else {
      return 'icon-arrow-with-circle-right issue-status';
    }
  }

  getContentPopover(item: any): string {
    let htmlContent = '';
    if (item &&
      item.descriptionList) {
      htmlContent = '<ul>';
      for (const description of item.descriptionList) {
        htmlContent = htmlContent.concat('<li>').concat(CwLiteral.translate(description)).concat('</li>');
      }
      htmlContent = htmlContent.concat('</ul>');
    }
    return htmlContent;
  }

  getElapsedStatusTime(device: any): string {
    return UiConfig.getElapsedStatusTime(device);
  }

  getLastUpdateTime(user: any): string {
    return CwDateTool.localeWithTimeZone(UiConfig.getLastUpdateTime(user), true);
  }

  getExpiredDate(item: any): string {
    const expiredDate = CwDateTool.utcToDateTimeZone(item.expiredDate);
    const nowDate = CwDateTool.utcToDateTimeZone(new Date().toISOString());
    const expiredTime = TimeFormatHelper.getTimeExpiredDate(expiredDate.getTime() - nowDate.getTime());
    if (expiredTime.time < 0) {
      return CwLiteral.translate('Expired') + ' ' +
        CwDateTool.localeWithTimeZone(item.expiredDate, true);
    } else {
      return CwLiteral.translate('Expires in') + ' '
        + expiredTime.time + ' ' + CwLiteral.translate(expiredTime.type);
    }
  }

  isExpiredDate(item: any): boolean {
    const expiredDate = CwDateTool.utcToDateTimeZone(item.expiredDate);
    const nowDate = CwDateTool.utcToDateTimeZone(new Date().toISOString());
    const expiredTime = TimeFormatHelper.getTimeExpiredDate(expiredDate.getTime() - nowDate.getTime());
    return expiredTime.time < 0;
  }

  transformField(item: any, Column: any): string {
    return Column.transformFieldFunction(item[Column.idField]) ?
      Column.transformFieldFunction(item[Column.idField])[Column.field] :
      '';
  }

  has(name: string, Element = this.Element.nativeElement): boolean {
    return (
      (
        Element &&
        Element.hasAttribute &&
        Element.hasAttribute(name) &&
        true
      ) ||
      (
        Element &&
        Element.getAttributeNames &&
        -1 < Element.getAttributeNames().indexOf(name) &&
        true) ||
      // Edge
      false
    );
  }

  openWindow(url: string): void {
    if (url) {
      window.open(
        url,
        '_blank', // URL is loaded into a new window
        'location=0,scrollbars=yes,resizable=yes,top=200,left=200,width=800,height=600',
        true
      );
    }
  }
}
