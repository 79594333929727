import {CwDefineUrl, CwFunction, CwSetupI, CwTokenBusI} from '@cw/model/model';
import {CwToolKeepC} from '@tool/keep/tool-keep';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwBillingPlansAmountModel, CwBillingPlanType} from './plans-model';
import {CW_SPACE_SUBSCRIPTION_TOOL} from '@space/subscription/tool-space-subscription-model';
import {BillingBus} from '../billing-bus';
import {CwSpacePlanModel} from '@cw/components/space/plan/cw-space-plan.model';
import {CwSpaceSubscriptionTool} from '@space/subscription/tool-space-subscription';
import {CwRestRequestParametersI} from '@tool/rest/model';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';

/**
 * @version 2004150000-12
 */
class BillingPlansBus extends BillingBus {

  ExtraList: any [] = [];
  MainList: any [] = [];

  DefineProperties: CwFunction[];
  Keep: CwToolKeepC<any>;
  Setup: CwSetupI;
  SuccessActionList: any[];
  SuccessActionOnceList: any[];
  TokenBus: CwTokenBusI;
  defineUrl: CwDefineUrl = ((): string => {
    // /partner/uploadLicense/5f4f4650-dc93-46bf-bf5e-7f102f775a1a
    return this.path +
      'plans'
      ;
  });

  do: CwFunction = (
    (Params: CwRestRequestParametersI = {
      Bus: this,
    }) => {
      this.restGet({
        ...Params,
        Bus: this,
        Parameters: null
      });
    }
  );

// 2002201042
  Purchase = {
    // 2004150000-12
    Available: {
      ExtraList: [],
      MainList: [],
      OtherList: [],
    },

    // 2004150000
    amount: (Params: CwBillingPlansAmountModel): number => {
      // let _amount: number = undefined;
      if (
        Params &&
        Params.id_collaborate_package &&
        Params.interval &&
        this.ItemsList
      ) {
        try {
          // 2008130000
          const Plan = this.ItemsList.find((
            (Finder: any): boolean => {
                            return (
                (CwToolCurrency.isEnabled)
                  ? (
                    Finder &&
                    Finder.id_collaborate_package === Params.id_collaborate_package &&
                    Finder.interval === Params.interval &&
                    CwToolCurrency.isSelected(Finder) &&
                    // 2010190915
                    Finder.public_plan
                  )
                  : (
                    Finder &&
                    Finder.id_collaborate_package === Params.id_collaborate_package &&
                    Finder.interval === Params.interval &&
                    // 2010190915
                    Finder.public_plan
                  )
              )
            }
          ));
          if (Plan) {
            return Plan.amount;
          } else {
            return undefined;
          }
        } catch (e) {
          console.error('CW2008171633', e);
        }
      } else {
        return undefined;
      }
    },


    // 2002111109-5
    /**
     * @deprecated 2007130000.2
     * @param Params
     */
    optionsByCollaborateId: (Params: any): any[] => {
      let collaborateId: string;
      const OptionsList: any[] = [];
      if (Params) {
        if (Params && Params.id_collaborate_package) {
          collaborateId = Params.id_collaborate_package;
        } else {
          collaborateId = Params;
        }
        this.Items.forEach(PlanItem => {
          if (PlanItem.id_collaborate_package === collaborateId) {
            OptionsList.push(PlanItem);
          }
        });
      }
      return OptionsList;
    },

    clear: () => {
      CwUtil.clear(this.Purchase.Available.ExtraList);
      CwUtil.clear(this.Purchase.Available.MainList);
      CwUtil.clear(this.Purchase.Available.OtherList);
    },

    // 2004150000-12
    defineAvailableToPurchase: (Plan) => {
      if (Plan && Plan.public_plan) {
        const PlanList = (Plan.type === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.MAIN)
          ? this.Purchase.Available.MainList
          : (Plan.type === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.EXTRA)
            ? this.Purchase.Available.ExtraList
            : this.Purchase.Available.OtherList
        ;
        CwUtil.remove({
          List: PlanList,
          condition: ((RemoveItem) => {
            return (RemoveItem.id_collaborate_package === Plan.id_collaborate_package);
          })
        });
        PlanList.push(<CwSpacePlanModel>{
          ...Plan,
          // 2007000000.1
          // planId: Plan.id_Plan,
          // // 2007000000.1
          id_Plan: undefined,
          planId: undefined,
          quantity: 0,
          Date: CwSpaceSubscriptionTool.defineEnd(Plan),
        })
      }
    },
  };


  defineData(Data: any) {
    super.defineData(Data);
    this.defineLists();
  }

  // 2008130000
  defineList(Data: any[]) {
    if (CwToolCurrency.isDisabled) {
      Data = [...Data.filter(CwToolCurrency.isDefault)];
    }
    super.defineList(Data);
  }

  defineLists(): void {
    if (this.Items) {
      CwUtil.clear(this.ExtraList);
      CwUtil.clear(this.MainList);
      this.Purchase.clear();
      this.Items.forEach(PlanItem => {
        if (
          PlanItem &&
          PlanItem.type
        ) {
          // 2003271009-2
          PlanItem.collapse = true;
          if (PlanItem.type === CwBillingPlanType.main) {
            this.MainList.push(PlanItem);
          } else if (PlanItem.type === CwBillingPlanType.gift) {
            if (PlanItem.id_collaborate_package === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.GATEWAY_GIFT) {
              this.ExtraList.push(PlanItem);
            } else {
              this.MainList.push(PlanItem);
            }
          } else if (PlanItem.type === CwBillingPlanType.extra) {
            PlanItem.collapse = true; // 2006101345
            this.ExtraList.push(PlanItem);
          }
        }
        this.Purchase.defineAvailableToPurchase(PlanItem);
      }); // forEach(PlanItem
    }
  }
}

export const CwBillingPlans = new BillingPlansBus();
