<div
  [ngClass]="{'invisible': !getVisible}"
>
  <div class="row mb-3">

    <ng-container [ngTemplateOutlet]="getTemplateOutlet"></ng-container>

  </div>
</div>


<ng-template #TemplateMainExpansionMinimized
>
  <ng-container [ngTemplateOutlet]="ElementPlanName"></ng-container>
  <ng-container [ngTemplateOutlet]="ElementMinimized"></ng-container>

</ng-template>

<ng-template #TemplateExpansionAddSelect
>
  <ng-container [ngTemplateOutlet]="ElementExpansionSelect"></ng-container>
  <div class="col-6 text-clearone d-flex py-2 font-bold"
       role="button"
       (click)="Expansion.AddAnother.On.clicked(Expansion.Type.Value)"
  >
    <div>{{getAnotherText}}</div>
  </div>
</ng-template>

<ng-template #TemplateExpansionAdd
>
  <div class="col-6 text-clearone d-flex py-2 font-bold"
       role="button"
       (click)="Expansion.AddAnother.On.clicked()"
  >
    <div>{{getAnotherText}}</div>
  </div>
</ng-template>

<ng-template #TemplateMainExpansionMaximized
>
  <ng-container [ngTemplateOutlet]="ElementPlanName"></ng-container>

  <ng-container [ngTemplateOutlet]="ElementMinimized"></ng-container>
  <ng-container [ngTemplateOutlet]="ElementExpansionSelect"></ng-container>
  <div class="col-6">
    <ng-container [ngTemplateOutlet]="ElementQuantity"></ng-container>
  </div>
  <ng-container [ngTemplateOutlet]="ElementPlanInterval"></ng-container>

</ng-template>


<ng-template #TemplateMainDefaultMaximized>
  <ng-container [ngTemplateOutlet]="ElementPlanName"></ng-container>
  <ng-container [ngTemplateOutlet]="ElementMinimized"></ng-container>
  <!--  <div class="col-6"></div>-->
  <div class="col-12">
    <ng-container [ngTemplateOutlet]="ElementQuantity"></ng-container>
  </div>
  <ng-container [ngTemplateOutlet]="ElementPlanInterval"></ng-container>
</ng-template>

<ng-template #TemplateMainDefaultMinimized>

  <ng-container [ngTemplateOutlet]="ElementPlanName"></ng-container>
  <ng-container [ngTemplateOutlet]="ElementMinimized"></ng-container>

</ng-template>


<!-- ELMENTS ------------------------------------------------------------------->
<ng-container>

  <ng-template #ElementExpansionSelect>
    <div class="col-6">
      <app-cw-select2
        required
        ngDefaultControl

        [disabled]="getElementExpansionSelectDisabled"
        [Setup]="Expansion.Type.Setup"
        [(ngModel)]="Expansion.Type.Value"

        (change)="Expansion.Type.On.changed()"
      ></app-cw-select2>
    </div>
  </ng-template>

  <ng-template #ElementInterval
               let-Interval="Interval">
    <div class="border text-center p-3"
         [ngClass]="{'border-0 text-white bg-primary':elementIntervalSelected(Interval)}"
         (click)="On.Plan.clicked(Interval)"
         role="button"
    >
      <div
        class="text-uppercase small mb-2"
        [translate]="Interval.label"
      ></div>
      <div class="font-bold h2 mb-0"
      >{{ Interval.amount | cwCurrency:CwToolCurrency.getSelectedCode}}</div>
      <div
        class="font-bold small"
        translate="monthly cost"
      ></div>
      <div class="small mt-2"
      ><span>{{Interval.total | cwCurrency:CwToolCurrency.getSelectedCode}}</span>&nbsp;
        <span [translate]="Interval.description"></span>
      </div>
    </div>
  </ng-template>

  <!-- ElementMinimized -------------------------------------------------------->
  <ng-template #ElementMinimized>
    <div class="col-5"
         role="button"
         (click)="On.Minimized.clicked()"
    >
      <div
        class="w-100 text-clearone text-right h5 m-0"
      >
        <cw-text-middle>
          <ng-container *ngIf="isPlanDetailsMinimizedVisible">

            <span class="text-clearone  m-0 px-3">
              <i class="small icon icon-cross"></i>
              <span class="small">{{QuantityInput?.value}}</span>
              <span
                class="px-3"
              >
                <i class="small icon icon-calendar"></i>
                <span class="small"
                      [translate]="getPlan.interval"
                ></span>
              </span>
            </span>

          </ng-container>
          <i class="icon"
             [ngClass]="{'icon-chevron-with-circle-right': getMinimized, 'icon-chevron-with-circle-up': !getMinimized}"
          ></i>
        </cw-text-middle>
      </div>
    </div>
  </ng-template>


  <!-- ElementPlanDescription -------------------------------------------------->
  <ng-template #ElementPlanDescription>
    <ng-container *ngIf="getCollapseHidden">
      <div class="col-6">
        <ng-container *ngIf="PlanNew?.complete_description">
          <div class="text-left">
            <ng-container
              *ngFor="let description of PlanNew.complete_description"
            >
              <small
                xxx-2005280951
                class="delta-indicator mb-2"
                [translate]="description"
              ></small>
              <br/>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>


  <!-- ElementPlanInterval ----------------------------------------------------->
  <ng-template #ElementPlanInterval>
    <div class="col-6">
      <ng-container
        *ngTemplateOutlet="ElementInterval;context:{Interval:Config.Monthly}"></ng-container>
    </div>

    <div class="col-6">
      <ng-container *ngTemplateOutlet="ElementInterval;context:{Interval:Config.Annual}"></ng-container>
    </div>
  </ng-template>


  <ng-template #ElementPlanName>
    <div
      class="col-7"
      role="button"
      [ngbTooltip]="TooltipFeatures"
      tooltipClass="cw-space-plan-minimal"
      [placement]="getElementPlanNameTooltipPlacement"
      (click)="On.Minimized.clicked()"
    >
      <div
        class="my-0 h-100 2009241150"
      >
        <cw-text-middle>
          <span class="h4 font-bold">{{getPlan.name}}</span>
        </cw-text-middle>
      </div>
    </div>
  </ng-template>


  <ng-template #ElementQuantity>
    <div class="form-group row mb-0">
      <label
        class="col col-form-label text-right"
        translate="Quantity:"
      ></label>
      <div
        class="col-4"
      >
        <cw-input
          class="mb-0 pb-0"
          [Item]="QuantityInput"
          (change)="On.Quantity.changed()"
        ></cw-input>
      </div>
    </div>
  </ng-template>


</ng-container>


<ng-container #TOOLTIPS>

  <ng-template #TooltipFeatures>
    <cw-space-plan-features [Plan]="PlanNew"></cw-space-plan-features>
  </ng-template>

</ng-container>
