import {CwWebKeepI} from '../web/web.model';
import {CwWeb} from '../web/web.tool';

export class CwToolKeepC<T> {

  private _Keep: CwWebKeepI<T>;

  getProperty = ((name) => {
    return this.Keep[name];
  });

  setProperty = ((Params: { name, Value }) => {
    if (Params && Params.name) {
      const _Keep = (this.Keep)
        ? this.Keep
        : {}
      ;
      _Keep[Params.name] = Params.Value;
      _Keep.updated = Date.now();
      this.Keep = _Keep;
    }
  });

  get Keep(): any {
    if (
      this._Keep &&
      !this._Keep.Data &&
      true
    ) {
      CwWeb.Keep.get(this._Keep);
      if (!this._Keep.Data && this._Keep.DataDefault) {
        this._Keep.Data = this._Keep.DataDefault;
      }
    }
    return this._Keep.Data;
  }

  // 1901181612
  set Keep(Data: any) {
    this._Keep.Data = (this._Keep.defineData)
      ? this._Keep.defineData(Data)
      : Data
    ;
    CwWeb.Keep.set(this._Keep);
  }

  remove(): void {
    if (
      this._Keep &&
      true
    ) {
      CwWeb.Keep.remove(this._Keep);
    }
  }


  constructor(Setup: CwWebKeepI<T>) {
    this._Keep = Setup;
    const firstTime = this.Keep;
  }

}
