import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownModule} from 'primeng/primeng';
import {CwLanguageSelectComponent} from './select.component';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        DropdownModule,
        FormsModule,
    ],
    declarations: [
        CwLanguageSelectComponent,
    ],
    exports: [
        CwLanguageSelectComponent,
    ]

})
export class CwSelectModule {
}
