import {CoMap} from '../model/model';
// import {CoActionsBus} from './actions/bus';

export class CoAppBusClass {

  // R70.0 - Resources - Settings - Unsaved dialog add - 1810190959
  // public ACTION_CANCEL = CoActionsBus.key;
  // public ACTION_DOING = CoActionsBus.keyLoaderShow;
  // public ACTION_DO_ERROR = CoActionsBus.keyLoaderHide;
  // public ACTION_DO_SUCCESS = CoActionsBus.keyLoaderHide;
  // public ACTION_SHOW = CoActionsBus.keyLoaderHide;
  //
  // public ACTIONS = CoActionsBus;

  ItemsMap: CoMap<any> = new Map();


  toList(): any[] {
    return Array.from(this.ItemsMap.values());
  }
}
