/**
 * @version R4.0 - Mobile - 1810040937
 */
export class CoAppLiteralClass {
  literal: string;
  parameters: any;

  constructor(literal: string, parameters: any = null) {
    this.literal = literal;
    this.parameters = parameters;
  }

  get(): string {
    return this.literal;
  }
}
