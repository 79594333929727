import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwUserResetDoI, CwUserResetParametersI} from './reset-model';
import {UserBus} from '../user-bus';

/**
 * @version 1904101050
 */
class ResetBus extends UserBus {
    DefineProperties: CwFunction[];
    defineUrl: CwDefineUrl = (() =>
            CwUserResetBus.path +
            'reset?token=' +
            CwUserResetBus.Parameters.token +
            '&newpwd=' +
            encodeURIComponent(CwUserResetBus.Parameters.password) +
            ''
    );


    do: CwFunction = ((Parameters: CwUserResetDoI) =>
            CwUserResetBus.restPut(Parameters)
    );

    Parameters: CwUserResetParametersI;
}

/**
 * @version 1904101050
 */
export const CwUserResetBus = new ResetBus();


// @Injectable()
// export class ResetService extends BasicService {
//
//   url = AppSettings.API_ENDPOINT + 'user/reset';
//
//   constructor(
//     private http: Http,
//     private router: Router
//   ) {
//     super();
//   }
//
//   reset(token: string, password: string): Observable<Result> {
//
//     const headers = new Headers({'Content-type': 'application/json'});
//     const options = new RequestOptions({headers: headers});
//
//     return this.http.put(
//       this.url +
//       '?token=' +
//       token +
//       '&newpwd=' +
//       encodeURIComponent(password),
//       options)
//       .map(this.extractData.bind(this))
//       .catch(this.handleError);
//   }
//
// }
