import {CsClass} from '../cs';


export abstract class CsToolClass<T> extends CsClass {

  abstract Setup: T;

  setup(_Setup: T) {
    this.Setup = _Setup;
  }

}
