<select *ngIf="!adminStyle"
        appCw
        appCwForm

        [disabled]="disabled"
        [required]="required"
        [(ngModel)]="ngModel"

        (ngModelChange)="onNgModelChange($event)"
        (blur)="On.blur($event)"
>

  <option *ngFor="let Option of getOptionList"

          [disabled]="Option.disabled"
          [ngValue]="Option"
          [selected]="Option.selected"

  >{{Option.web | translate}}</option>

</select>

<select *ngIf="adminStyle"
        appCwForm
        class="form-control"

        [(ngModel)]="ngModel"
        (ngModelChange)="onNgModelChange($event)"
        [required]="required"
        (blur)="On.blur($event)"
>
  <option *ngFor="let Option of getOptionList"

          [ngValue]="Option"
          [selected]="getSelected(Option)"
  >{{Option.web | translate}}</option>
</select>
