import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'cw-inform-modal',
  templateUrl: 'inform-modal.component.html',
  styleUrls: ['./inform-modal.component.scss']
})
export class InformModalComponent implements OnInit, OnDestroy {

  @Input() data: any;

  @Output() emitConfirmAction = new EventEmitter(true);
  @Output() emitCancelAction = new EventEmitter(true);
  @Output() emitCrossClickedAction = new EventEmitter(true);
  cancelButton: string;
  okButton: string;
  private defaultCancelButton = false;

  constructor(public activeModal: NgbActiveModal,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.cancelButton = 'Cancel';
    this.okButton = 'OK';
    this.defaultCancelButton = this.data.defaultCancelButton;
  }

  ngOnDestroy(): void {
    if (this.data.doOnDestroyAsCross) {
      this.emitCrossClickedAction.next(true);
    }
  }

  closeModal(): void {
    if (this.data.emitConfirm) {
      this.emitConfirmAction.next(true);
    }
    this.activeModal.close(this.data);
  }

  crossClicked(): void {
    if (this.data.emitCrossClicked) {
      this.emitCrossClickedAction.next(true);
    }
    this.activeModal.dismiss('Cross click');
  }

  cancelClicked(): void {
    if (this.data.emitConfirm) {
      this.emitConfirmAction.next(false);
    }
    this.activeModal.dismiss();
  }
}
