import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CwWeb} from '../CollaborateSpaceWebTools/tool/web/tool-web';
import {CwOnP} from '../CollaborateSpaceWebTools/tool/on-premise/tool-on-premise';
import {CwOnPremiseBus} from '../CollaborateSpaceWebTools/rest/billing/on-premise/billing-on-premise-bus';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
/**
 * @version 2005060000
 */
export class CouponComponent {

  Coupon = {
    value: undefined,
    visible: false,
    do: () => {
      if (CwOnP.is) {
        CwWeb.goTo({path: '/home'});
      } else {
        // 2010141200.1 ////////////////////////////////////////////////////////
        this.Coupon.value = CwWeb.getToken({
          ActivatedRoute: this._ActivatedRoute,
        });
        this.Coupon.visible = true;
      }
    },
  }

  constructor(
    private _ActivatedRoute: ActivatedRoute,
  ) {
    CwOnPremiseBus.Bus$.subscribe(
      this.Coupon.do
    );
  }

}
