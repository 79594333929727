import {Component} from '@angular/core';
import {RadioComponent} from './radio.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-radio-b',
  templateUrl: './radio.component.html',
  styles: []
})
export class RadioBooleanComponent extends RadioComponent {

  select(Item: any): void {
    if (this.Setup) {
      if (
        Item &&
        !Item.disabled &&
        Item !== this.Setup.Value &&
        true
      ) {
        this.changed.emit(Item);
      }
      this.Setup.Value = Item;
    }
  }

  isChecked(Param: { Item: any, index: number }) {
    // if (
    //   Param.Item.disabled &&
    //   // this.Setup.Value === undefined &&
    //   true
    // ) {
    //   return false;
    // } else if (!this.someAlreadySelected) {
    //   this.someAlreadySelected = true;
    //   // this.Setup.Value = Param.Item;
    // }
    return (
      Param.Item[this.Setup.Bus.ItemsRadio.id] === this.Setup.Value[this.Setup.Bus.ItemsRadio.id]
    );
  }
}
