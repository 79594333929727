import {ToolDefineFlagI} from './tool-define-model';
import {environment} from '../../../../environments/environment';
import {CwSafe} from '../tool-safe';

class ToolDefineClass {

  flag = ((FlagP: ToolDefineFlagI) => {
    if (
        FlagP &&
        FlagP.countryCode &&
        environment &&
        environment.Literal &&
        environment.Literal.flagPath &&
        true
    ) {
      FlagP._flag = CwSafe.get(environment.Literal.flagPath) +
          FlagP.countryCode.toLowerCase() +
          '.svg';
      FlagP.country = (FlagP.pais) ? FlagP.pais : FlagP.country;
    } else if (
        FlagP &&
        FlagP.country &&
        environment &&
        environment.Literal &&
        environment.Literal.flagPath &&
        true
    ) {
      FlagP._flag = CwSafe.get(environment.Literal.flagPath) +
          FlagP.country.toLowerCase() +
          '.svg';
      FlagP.countryCode = FlagP.pais;
      FlagP.country = (FlagP.pais) ? FlagP.pais : FlagP.country;
    } else if (
        FlagP &&
        FlagP.pais &&
        environment &&
        environment.Literal &&
        environment.Literal.flagPath &&
        true
    ) {
      FlagP._flag = CwSafe.get(environment.Literal.flagPath) +
          FlagP.pais.toLowerCase() +
          '.svg';
      FlagP.country = (FlagP.pais) ? FlagP.pais : FlagP.country;
      FlagP.countryCode = FlagP.pais;
    }
  })

}

/**
 * @version 1904251312
 */
export const CwDefine = new ToolDefineClass();
