// // 1910081139
// export const CONVERGENCE = {
//   STATUS: {
//     DISCONNECTED: 'disconnected',
//     AWAY: 'away',
//     AVAILABLE:  'available',
//   }
// }

// export type ConvergenceStatusType = CONVERGENCE.STATUS.DISCONNECTED | ;
import {UiConfig} from '../../commons/config/ui.config';
import {TimeFormatHelper} from '../../commons/helpers/time-format.helper';
import {CwDateTool} from '../tool/date/date.tool';
import {CwIs} from '../tool/tool-is';
import {CwLiteral} from '@cw/literal/literal';

export type CwConvergenceStatusType = 'available' | 'disconnected' | 'away' | null;

class ConvergenceStatusClass {
  STATUS = {
    AVAILABLE: 'available',
    AWAY: 'away',
    BUSY: 'busy',
    DISCONNECTED: 'disconnected',
    DOWN: 'down',
    ISSUES: 'issues',
    HEALTHY: 'healthy',
    ALL: null,
    RECORDING: 'Recording',
    STREAMING: 'Streaming',
    WHITEBOARD: 'Whiteboard',
    SHARINGDATA: 'SharingData',
    IN_CALL: 'In Call',
    DO_NOT_DISTURB: 'Do Not Disturb',
    ONLINE: 'Online'
  };

  DASHBOARD_STATUS = {
    AVAILABLE: 'Available',
    AWAY: 'Away',
    DISCONNECTED: 'Disconnected',
    DOWN: 'Down',
    ISSUES: 'Issues',
    HEALTHY: 'Healthy',
    SHOW_ALL: 'Show All',
    COMMUNICATION_FAILED: 'Communication Service Failed'
  };


  // 1910081139
  status = ((Properties) => {
    if (
      Properties &&
      Properties.status &&
      true
    ) {
      if (Properties.resource) {
        if (Properties.CProStatus) {
          Properties.convergenceStatus = UiConfig.getResourceConvergenceStatusFromApi(Properties.CProStatus);
        } else {
          Properties.convergenceStatus = UiConfig.getResourceConvergenceStatusFromDevice(Properties);
        }
      } else {
        Properties.convergenceStatus = UiConfig.getUserConvergenceStatusFromApi(Properties.status);
      }
    }
  });

  sortByStatusAndTime = ((listObject, header) => {
    const order = [ this.DASHBOARD_STATUS.DOWN,  this.DASHBOARD_STATUS.ISSUES, this.DASHBOARD_STATUS.HEALTHY];
    if (header.sortingClass === 'headerSortDown') {
      listObject.sort(function(a, b) {
        if (a.convergenceStatus === b.convergenceStatus) {
          if (!CwIs.defined(b.statusLastUpdate) && CwIs.defined(a.statusLastUpdate)) {
            return -1;
          } else if (!CwIs.defined(a.statusLastUpdate) && CwIs.defined(b.statusLastUpdate)) {
            return 1;
          } else if (CwDateTool.utcToDateTimeZone(a.statusLastUpdate) < CwDateTool.utcToDateTimeZone(b.statusLastUpdate)) {
             return 1;
          } else if (CwDateTool.utcToDateTimeZone(a.statusLastUpdate) > CwDateTool.utcToDateTimeZone(b.statusLastUpdate)) {
            return -1;
          }
          return 0;
        }
        return order.indexOf(a.convergenceStatus) - order.indexOf(b.convergenceStatus);
      });
    } else {
      listObject.sort(function(b, a) {
        if (a.convergenceStatus === b.convergenceStatus) {
          if (!CwIs.defined(b.statusLastUpdate) && CwIs.defined(a.statusLastUpdate)) {
            return -1;
          } else if (!CwIs.defined(a.statusLastUpdate) && CwIs.defined(b.statusLastUpdate)) {
            return 1;
          } else if (CwDateTool.utcToDateTimeZone(a.statusLastUpdate) < CwDateTool.utcToDateTimeZone(b.statusLastUpdate)) {
            return 1;
          } else if (CwDateTool.utcToDateTimeZone(a.statusLastUpdate) > CwDateTool.utcToDateTimeZone(b.statusLastUpdate)) {
            return -1;
          }
          return 0;
        }
        return order.indexOf(a.convergenceStatus) - order.indexOf(b.convergenceStatus);
      });
    }
  });

  sortByStatusAndRegistrationDate = ((listObject, header) => {
    if (header.sortingClass === 'headerSortDown') {
      listObject.sort(function(a, b) {
        if (a.invited === b.invited) {
          if (CwDateTool.utcToDateTimeZone(a.registrationDate) < CwDateTool.utcToDateTimeZone(b.registrationDate)) {
            return -1;
          } else if (CwDateTool.utcToDateTimeZone(a.registrationDate) > CwDateTool.utcToDateTimeZone(b.registrationDate)) {
            return 1;
          }
          return 0;
        }
        return a.invited ? 1 : -1;
      });
    } else {
      listObject.sort(function(b, a) {
        if (a.invited === b.invited) {
          if (CwDateTool.utcToDateTimeZone(a.registrationDate) < CwDateTool.utcToDateTimeZone(b.registrationDate)) {
            return -1;
          } else if (CwDateTool.utcToDateTimeZone(a.registrationDate) > CwDateTool.utcToDateTimeZone(b.registrationDate)) {
            return 1;
          }
          return 0;
        }
        return a.invited ? 1 : -1;
      });
    }
  });

  sortByLastUpdateDate = ((listObject, header) => {
    if (header.sortingClass === 'headerSortDown') {
      listObject.sort(function(a, b) {
          if (!CwIs.defined(b.lastUpdateWeb) && CwIs.defined(a.lastUpdateWeb)) {
            return 1;
          } else if (!CwIs.defined(a.lastUpdateWeb) && CwIs.defined(b.lastUpdateWeb)) {
            return -1;
          } else if (CwDateTool.utcToDateTimeZone(a.lastUpdateWeb) < CwDateTool.utcToDateTimeZone(b.lastUpdateWeb)) {
            return -1;
          } else if (CwDateTool.utcToDateTimeZone(a.lastUpdateWeb) > CwDateTool.utcToDateTimeZone(b.lastUpdateWeb)) {
            return 1;
          }
          return 0;
      });
    } else {
      listObject.sort(function(b, a) {
          if (!CwIs.defined(b.lastUpdateWeb) && CwIs.defined(a.lastUpdateWeb)) {
            return 1;
          } else if (!CwIs.defined(a.lastUpdateWeb) && CwIs.defined(b.lastUpdateWeb)) {
            return -1;
          } else if (CwDateTool.utcToDateTimeZone(a.lastUpdateWeb) < CwDateTool.utcToDateTimeZone(b.lastUpdateWeb)) {
            return -1;
          } else if (CwDateTool.utcToDateTimeZone(a.lastUpdateWeb) > CwDateTool.utcToDateTimeZone(b.lastUpdateWeb)) {
            return 1;
          }
          return 0;
      });
    }
  });

  exportLastUpdateDate = ((listObject, field) => {
    if (!listObject[field]) {
      listObject[field] = CwLiteral.translate('Never');
    } else {
      listObject[field] = CwDateTool.localeWithTimeZone(listObject[field], true)
    }
  });

  exportStatusUsers = ((listObject, field) => {
    listObject[field] = CwLiteral.translate(listObject.registrationText) + ' '
      + CwDateTool.localeWithTimeZone(listObject[field], true);
  });

}


export const CwConvergenceStatus = new ConvergenceStatusClass();
