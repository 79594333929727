import {CwBusClass} from '../model/bus';
import {CwToolKeepC} from '../tool/keep/tool-keep';
import {CwFunction} from '../model/model';

export abstract class InfoBus    extends CwBusClass {
    // 1904101050
    Keep: CwToolKeepC<any>;
    // 1904101050
    Setup: any;

    DefineProperties: CwFunction[];

    // 1904101050
    path = 'info/';
}
