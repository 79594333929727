import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {CoWeb} from './co/tools/web';
import {DomSanitizer} from '@angular/platform-browser';
import {App} from './app';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {CwWeb} from './CollaborateSpaceWebTools/tool/web/tool-web';
import {environment} from '../environments/environment';
import {DeviceDetectorService} from 'ngx-device-detector';

const {version, context} = require('../../package.json');
declare var protocolObject: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  App = App;
  CwWeb = CwWeb;

  innerWidth: number;
  errorMessage: string;
  webinarView = false;

  constructor(
    // 1907021609
    public _DeviceDetectorService: DeviceDetectorService,
    public _Router: Router,
    public _ActivatedRoute: ActivatedRoute,
    public  _HttpClient: HttpClient,
    public _TranslateService: TranslateService,
    public _DomSanitizer: DomSanitizer
  ) {
    console.log('App version', version);
    console.log('', context);

    App.setup(this);
    CoWeb.init(this._Router, this._ActivatedRoute);
    this.innerWidth = innerWidth;
  }


  ngOnInit(): void {
    this._Router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.webinarView = (event as NavigationStart).url && (event as NavigationStart).url.startsWith('/webinar');
      }
    });
    if (
      environment &&
      environment.App &&
      environment.App.StylesList &&
      true
    ) {
      CwWeb.loadCss(
        null,
        ...environment.App.StylesList
      );
    }
  }


}
