import {Component} from '@angular/core';
import {AppWebTool} from '../app-web.tool';
import {AppCw} from '../app.class';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
})
export class VerifyComponent extends AppCw {

  OnInit(): void {
    super.OnInit();
    AppWebTool.Title.main = 'Check Your Inbox';
  }

}
