import {Component, OnInit} from '@angular/core';
import {PlansComponentClass} from '../plans.class';
import {CwSpacePlanTool} from '@space/plan/space-plan.tool';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CW_SPACE_PLAN} from '@cw/components/space/plan/cw-space-plan.model';

@Component({
  selector: 'app-plans-view',
  templateUrl: './plans-view.component.html',
  styleUrls: ['./plans-view.component.scss']
})
export class PlansViewComponent
  extends PlansComponentClass
  implements OnInit {

  CW_SPACE_PLAN = CW_SPACE_PLAN;

  intervalChange = (value: any, Plan: any): void => {
    if (value && Plan && Plan.ApiPlanList) {
      const SourcePlan = CwSpacePlanTool.getBy({
        id_collaborate_package: Plan.id_collaborate_package,
        PlanList: Plan.ApiPlanList,
        type: Plan.type,
        interval: value,
      });
      if (SourcePlan) {
        CwUtil.setProperties(
          SourcePlan,
          Plan,
        );
      }
    }
  }


  get getAvailable(): boolean {
    return true;
  }

  ngOnInit() {
  }

}
