import {Component, Input} from '@angular/core';
import {CwLiteral} from '../../literal/literal';
import {CwLanguageI} from '../../model/model';

@Component({
  selector: 'app-cw-select',
  templateUrl: './select.component.html',
  styles: []
})
/**
 * @version 1904251312
 */
export class CwLanguageSelectComponent {

  @Input() Bus: CwLanguageI = CwLiteral;

  onChange(Event: any): void {
    this.Bus.change(Event.value);
  }

}
