<div class="d-none d-xs-none d-sm-none d-md-block d-lg-block">
  <nav #TopLgNavbar
       class="navbar navbar-expand navbar-dark bg-dark text-white py-0 px-10"
  >
    <ng-container *ngTemplateOutlet="BrandingTemplate"></ng-container>
    <button class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#TopNavbarContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse"
         id="TopNavbarContent">
      <ng-container #TopNavbarSocialContainer
                    *ngIf="!CwOnP.is"
      >
        <div
          class="img-circle-social"
        >
          <a target="_blank" href="https://www.facebook.com/ClearOne/">
            <img [src]="src[0]"
                 (mouseover)="over('facebook',0,true);"
                 (mouseout)="over('facebook',0,false);"
            >
          </a>
        </div>
        <div
          class="img-circle-social"
        >
          <a target="_blank" href="https://twitter.com/clearone">
            <img [src]="src[1]" (mouseover)="over('twitter',1,true);" (mouseout)="over('twitter',1,false);">
          </a>
        </div>
        <div
          class="img-circle-social"
        >
          <a target="_blank" href="https://www.linkedin.com/company/clearone-">
            <img [src]="src[2]" (mouseover)="over('linkedin',2,true);"
                 (mouseout)="over('linkedin',2,false);">
          </a>
        </div>
      </ng-container><!--            TopNavbarSocialContainer            -->


    </div>
    <!--        1904251312-->
    <div
      class="nav navbar-nav d-flex flex-row align-items-center"
    >

      <div *ngIf="App?.Head?.selectLanguageVisible"
           class="pr-2"
      >
        <app-cw-select></app-cw-select>
      </div>

      <div *ngIf="user?.name"
      >
        <a
          routerLink="/datasignin"
        >
          <img
            src="assets/images/icon-account.png"
          >
          &#160;&#160;{{user?.name}}
        </a>
      </div>
      <div *ngIf="!user || !user.name">
        <a
          routerLink="/datasignin"
        >
          <img src="assets/images/icon-account.png"
          >
          &#160;&#160;<span translate>My Account</span>
        </a>
      </div>
    </div>

  </nav>
</div>

<div class="d-block d-xs-block d-sm-block d-md-none d-lg-none">

  <nav #TopNavbar
       class="navbar navbar-expand-lg navbar-dark bg-dark"
  >

    <ng-container *ngTemplateOutlet="BrandingTemplate"></ng-container>

    <ng-container #TopNavbarSocialContainer
                  *ngIf="!CwOnP.is"
    >
      <div class="img-circle-social d-none d-md-block"
      >
        <a target="_blank" href="https://www.facebook.com/ClearOne/">
          <img [src]="src[0]"
               (mouseover)="over('facebook',0,true);"
               (mouseout)="over('facebook',0,false);"
          >
        </a>
      </div>

      <div class="img-circle-social d-none d-md-block">
        <a target="_blank" href="https://twitter.com/clearone">
          <img [src]="src[1]" (mouseover)="over('twitter',1,true);" (mouseout)="over('twitter',1,false);">
        </a>
      </div>

      <div class="img-circle-social d-none d-md-block">
        <a target="_blank" href="https://www.linkedin.com/company/clearone-">
          <img [src]="src[2]" (mouseover)="over('linkedin',2,true);"
               (mouseout)="over('linkedin',2,false);">
        </a>
      </div>

    </ng-container><!--            TopNavbarSocialContainer            -->


    <app-cw-select></app-cw-select>


    <div *ngIf="user?.name"
         class="text-white"
    >
      <a
        routerLink="/datasignin"
      >
        <img
          src="assets/images/icon-account.png"
        >
        <span class="d-none d-md-inline"
        >&#160;&#160;{{user?.name}}</span>
      </a>
    </div>

    <div *ngIf="!user || !user.name">
      <a
        routerLink="/datasignin"
      >
        <img src="assets/images/icon-account.png"
        >
        <span class="d-none d-lg-inline"
        >&#160;&#160;<span translate>My Account</span>
          </span>
      </a>
    </div>

    <button #MenuToggleButton
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#MenuNavbar"
            aria-controls="MenuNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div id="MenuNavbar"
         class="collapse navbar-collapse text-white"
    >

      <ul class="navbar-nav mr-auto">

        <li class="nav-item cw-clickable"
            routerLink="/home"
            (click)="MenuToggleButton.click()"
            translate="Home"
        ></li>

        <ng-container
            *ngIf="!CwOnPremise.is"
            xxx-2003041112
        >
        <li class="nav-item cw-clickable"
            routerLink="/rates"
            (click)="MenuToggleButton.click()"
            translate="Call Rates"
        ></li>

        <li class="nav-item cw-clickable"
            xxx-2005280951
            routerLink="/plans-pricing"
            translate="Plans & Pricing"
            (click)="MenuToggleButton.click()"
        ></li>
        </ng-container>


        <li class="nav-item cw-clickable"
            routerLink="/hostmeeting"
            translate="Host a Meeting"
            (click)="MenuToggleButton.click()"
        ></li>

        <li class="nav-item cw-clickable"
            routerLink="/meeting"
            translate="Join a Meeting"
            (click)="MenuToggleButton.click()"
        ></li>

      </ul>

    </div>
  </nav>
</div>


<div class="">
  <div #BodyContainer class="background1">

    <app-cw-row class="d-none d-md-inline">
      <div R4 class="d-flex flex-row justify-content-end text-white">

        <div class="p-md-2 p-lg-3"
             [ngClass]="{'menu-active':App.Navigation.home}"
        >
          <a routerLink="/home"
             translate="Home"
          ></a>
        </div>

        <div class="p-md-2 p-lg-3"
             [ngClass]="{'menu-active':App.Navigation.download}"
        >
          <a routerLink="/download"
             translate="Download"
          ></a>
        </div>

        <ng-container
          *ngIf="!CwOnPremise.is"
          xxx-2003041112
        >
          <div
            class="p-md-2 p-lg-3"
            [ngClass]="{'menu-active':App.Navigation.plan}"
          >
            <a routerLink="/plans-pricing"
               translate="Plans & Pricing"
            ></a>
          </div>
        </ng-container>

        <div class="p-md-2 p-lg-3"
             [ngClass]="{'menu-active':App.Navigation.host}"
        >
          <a routerLink="/hostmeeting"
             translate="Host a Meeting"
          ></a>
        </div>

        <div class="p-md-2 p-lg-3"
             [ngClass]="{'menu-active':App.Navigation.join}"
        >
          <a routerLink="/meeting"
             translate="Join a Meeting"
          ></a>
        </div>

      </div>
    </app-cw-row>

    <div id="ScrollFeedback"></div>
    <app-cw-row class="d-block d-sm-none">
      <div R2
           appCw
           appCwText
           appCwTextMax="36"
           appCwTextMax="16"
           class="font-secondary font-large font-spacing-2 my-2"
      >
        {{AppWebTool?.Title?.main | translate}}
      </div>
    </app-cw-row>

    <app-cw-row class="d-none d-sm-block">
      <div R2
           appCw
           appCwText
           appCwTextMax="36"
           class="font-secondary font-large font-spacing-2"
      >
        {{AppWebTool?.Title?.main | translate}}
      </div>
    </app-cw-row>


    <div class="d-inline">

      <div class="text-center pt-2 pb-3">

        <div *ngIf="AppWebTool?.Title && AppWebTool?.Title?.secondary"
             class="d-none d-sm-block"
        >
          <h2 class="font-secondary font-small font-bold font-spacing-15">
            {{AppWebTool?.Title?.secondary | translate | uppercase}}
          </h2>
        </div>

        <div *ngIf="AppWebTool?.Title?.startHereVisible"
             class="d-none d-sm-block"
        >
          <button
            routerLink="/download"
            type="button"
            class="btn btn-normal btn-300 font-small font-bold text-uppercase bg-transparent text-white border"
          >
            <span translate>Start here</span>
            <span translate>&nbsp;&nbsp;</span>
            <span class="btn-label"><i class="pi pi-arrow-right"></i></span>
          </button>
        </div>

        <ng-container *ngIf="App?.Preloader?.visible">
          <co-preloader
            [hidden]="false"
            [textMain]="App.Preloader.textMain | translate"
            [textSecondary]="App.Preloader.textSecondary | translate"
          ></co-preloader>
        </ng-container>

      </div>

    </div>

    <!--    1905301259-->
    <div class=" bg-white"
         [ngClass]="{'py-lg-3': !isInvite, 'pb-lg-3':isInvite}"
    >
      <router-outlet
        (deactivate)="onDeactivate()"
      ></router-outlet>
    </div>
    <app-down></app-down>

    <div class="text-center mb-2 2010091400 bg-white">
      <img src="/literal/assets/trademark/202010.png">
    </div>

    <app-foot></app-foot>

  </div>

</div>


<ng-template #BrandingTemplate>
  <a class="navbar-brand"
     href="{{imgUrl}}"
  >
    <img *ngIf="img && !branding"
         class="w-auto 2101040000"
         [src]="img"
    />
    <img *ngIf="img && branding"
         class="w-auto"
         [src]="img"
         height="40px"
    />
  </a>
</ng-template>
