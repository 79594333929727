/**
 * @version R69.10 - Ticket 2301 - ...the size of the text increases.
 */
export abstract class CsConfigureClass {

    abstract configPrefix;
    abstract configSave;
    PREFIX_DEFAULT = 'APP';
    //
    // restGet getPropertyNameList(): any[] {
    //   const propertyNameList = Object.getOwnPropertyNames(this).filter(
    //     propertyFilter =>
    //       CoString.startsWith(propertyFilter, (this.configPrefix) ? this.configPrefix : this.PREFIX_DEFAULT)
    //   );
    //   return (propertyNameList)
    //     ? propertyNameList
    //     : []
    //     ;
    // }
    //
    // init(): void {
    //   CoConfigBus40.addConfigure(this);
    // }
    //
    // configure(ConfigBus: any): void {
    // }
}
