export enum ExpirationEnum {
  __INIT,

  NUMBER_OF_ATTEMPS = 40,

  XXXXS = 100 * 1 * 1 * 1 * 1,  // 0.1 s
  XXXXS2 = 500 * 1 * 1 * 1 * 1,  // 0.5 s
  XXXS = 1000 * 1 * 1 * 1 * 1,  // 1 s
  XXS = 1000 * 5 * 1 * 1 * 1,  // 5 s
  XS = 1000 * 10 * 1 * 1 * 1,  // 10 s
  S = 1000 * 30 * 1 * 1 * 1,  // 30 s
  /**
   * 1 Minute
   */
  M = 1000 * 60 * 1 * 1 * 1,  // 1 m
  L = 1000 * 60 * 10 * 1 * 1,  // 10 m
  XL = 1000 * 60 * 20 * 1 * 1,  // 20 m
  XXL = 1000 * 60 * 30 * 1 * 1, // 30 m //5 s
  XXXL = 1000 * 60 * 60 * 1 * 1, // 1 h
  NOT_REPEAT = -1,

  BACKGROUND = 1000 * 60 * 10 * 1 * 1,  // 10 m
  FOREGROUND_DEFAULT = 1000 * 5 * 1 * 1 * 1, // 5 s

  // R40.5 - Users - Add
  d01 = 1000 * 60 * 60 * 24 * 1,
  d02 = 1000 * 60 * 60 * 24 * 2,
  d10 = 1000 * 60 * 60 * 24 * 10,
  h01 = 1000 * 60 * 60 * 1 * 1,
  h02 = 1000 * 60 * 60 * 2 * 1,
  h05 = 1000 * 60 * 60 * 5 * 1,
  h12 = 1000 * 60 * 60 * 12 * 1,
  m01 = 1000 * 60 * 1 * 1 * 1,
  m05 = 1000 * 60 * 5 * 1 * 1,
  m10 = 1000 * 60 * 10 * 1 * 1,
  m30 = 1000 * 60 * 30 * 1 * 1,
  ms001 = 1 * 1 * 1 * 1 * 1,
  ms010 = 10 * 1 * 1 * 1 * 1,
  ms100 = 100 * 1 * 1 * 1 * 1,
  ms200 = 200 * 1 * 1 * 1 * 1,
  ms500 = 500 * 1 * 1 * 1 * 1,
  ms700 = 700 * 1 * 1 * 1 * 1,
  s01 = 1000 * 1 * 1 * 1 * 1,
  s02 = 1000 * 2 * 1 * 1 * 1,
  s05 = 1000 * 5 * 1 * 1 * 1,
  s10 = 1000 * 10 * 1 * 1 * 1,
  s30 = 1000 * 30 * 1 * 1 * 1,

  __END,
}
