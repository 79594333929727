import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwRestRequestMinParametersI} from '../../../tool/rest/model';
import {WebinarBus} from '@rest/webinar/webinar-bus';


class WebinarFormClass extends WebinarBus {
  defineUrl: CwDefineUrl = (() => this.path + CwWebinarFormBus.Setup.token + '/form');

  DefineProperties: CwFunction[] = [
    (Properties: any) => {
    }
  ];

  do: CwFunction = ((Param: CwRestRequestMinParametersI) => {
    this.restGet({
      ...Param,
      Bus: this
    });
  });

}


export const CwWebinarFormBus = new WebinarFormClass();
