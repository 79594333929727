import {Table} from 'primeng/table';

export class CoComponentsGridBus {

    CoGrid: Table;

    ColumnsList: CoComponentsGridColumn[] = [];

    ValuesList: any[] = [];

    Selection: any;

    onRowSelectedToDo: any;

    constructor(_ColumnsList: CoComponentsGridColumn[], _ValueList?: any[]) {
        this.ColumnsList = _ColumnsList;
        if (_ValueList) {
            this.ValuesList = _ValueList;
        }
    }

    filter(_value: string): void {
        if (this.CoGrid) {
            this.CoGrid.filterGlobal(_value, 'contains');
        }
    }

    defineCoGrid(_CoGrid: Table): CoComponentsGridBus {
        this.CoGrid = _CoGrid;
        if (
            this &&
            this.CoGrid &&
            this.CoGrid.onRowSelect &&
            true
        ) {
            this.CoGrid.onRowSelect.subscribe(
                (event) => {
                    if (this.onRowSelectedToDo) {
                        this.onRowSelectedToDo(event.data);
                    }
                });
        }
        return this;
    }

}


// extends Column
export class CoComponentsGridColumn {

    coHeader = '';
    coField = '';
    coOrderBy: string;
    coType: CoComponentsGridColumnType = CoComponentsGridColumnsType.NORMAL;

    get field(): string {
        return this.coField;
    }

    constructor(coHeader: string, coField: string, coOrderBy?: string, coType?: CoComponentsGridColumnType) {
        this.coHeader = coHeader;
        this.coField = coField;
        this.coOrderBy = coOrderBy;
        this.coType = coType;
    }
}

export interface CoComponentsGridColumnInterface {
    coHeader: string;
    coField: string;
    coOrderBy?: string;
    coType?: CoComponentsGridColumnType;

    field(): string;

}

export type CoComponentsGridColumnType =
    CoComponentsGridColumnsType.NORMAL |
    2
    ;


export enum CoComponentsGridColumnsType {
    DATE,
    NORMAL,
}
