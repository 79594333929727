import {Component, OnInit} from '@angular/core';
import {PlansComponentClass} from '../plans.class';
import {CwVal} from '@tool/validation/tool-validation';
import {AppPlansTool} from '../plans.tool';
import {CwBillingPlans} from '@rest/billing/plans/plans-bus';
import {CwSpacePlanTool} from '@space/plan/space-plan.tool';
import {CW_SPACE_PLAN_TOOL, CW_SPACE_PLAN_TOOL_BUY_TYPE, CwSpacePlanToolDefinePlanModel} from '@space/plan/space-plan.model';
import {CwRestRequestParametersI} from '@tool/rest/model';
import {CW_SPACE_SUBSCRIPTION_TOOL, CW_SPACE_SUBSCRIPTION_TYPE_MODEL} from '@space/subscription/tool-space-subscription-model';
import {CwWeb} from '@tool/web/tool-web';
import {AppFeedbackTool} from '../../feedback/feedback.tool';
import {AppWebTool} from '../../app-web.tool';
import {CwSpaceBuySetupModel, CwSpaceBuyStepModel, CwSpaceBuyTemplateModel} from '@cw/components/space/buy/cw-space-buy.model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwLogicSpacePlan} from '@cw/logic/space/plan/cw-logic-space-plan';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';
import {CwToolSca} from '@cw/6.0/tools/sca/tool';

@Component({
  selector: 'app-plans-buy',
  templateUrl: './plans-buy.component.html',
  styleUrls: ['./plans-buy.component.css']
})
/**
 * #version 2007130000.1
 */
export class PlansBuyComponent
  extends PlansComponentClass
  implements OnInit {
  // 2007210000

  CwToolCurrency = CwToolCurrency;
  StepSelect: CwSpaceBuyStepModel = {
    action: (
      () => {
      }
    ),
    continueLabel: 'Continue',
    disabled: (
      (): boolean => {
        return !CwUtil.exist({
          Array: AppPlansTool.Buy.Select.MainNewList,
          condition: (
            (Item) => {
              return Item.quantity > 0;
            }
          )
        });
      }
    ),
    error: undefined,
    label: 'Select a plan...',
    template: CwSpaceBuyTemplateModel.select
  };
  StepRegistration: CwSpaceBuyStepModel = {
    action: (
      () => {
        this.AppPlansTool.Buy.do({
          successActionCustom: (
            (Data: any) => {
              AppPlansTool.Buy.Done.success = Data.error;
              CwWeb.loaderVisible = false;
            }
          ),
          errorActionCustom: (
            (Data) => {
              this.plansBuyType = CW_SPACE_PLAN_TOOL_BUY_TYPE.REGISTRATION;
              AppFeedbackTool.error(Data.error)
              CwWeb.loaderVisible = false;
            }
          ),
          errorRestActionCustom: (
            (Data) => {
              this.plansBuyType = CW_SPACE_PLAN_TOOL_BUY_TYPE.REGISTRATION;
              AppFeedbackTool.error('Try the operation again ...')
              CwWeb.loaderVisible = false;
            }
          ),
        });
// } else if (this.getPlansBuyReview) {
//   this.AppPlansTool.Buy.Agree.privacy = false;
//   this.AppPlansTool.Buy.Agree.terms = false;
      }
    ),
    continueLabel: 'Pay',
    disabled: (
      () => {
        let disabled = true;
        if (
          this.getPlansBuyRegistration &&
          AppPlansTool &&
          AppPlansTool.Buy &&
          AppPlansTool.Buy.Registration &&
          CwVal.validateInputsSet(AppPlansTool.Buy.Registration.InputsSet) &&
          CwVal.validateInput(AppPlansTool.Buy.Registration.InputCountry) &&
          CwVal.validateInput(AppPlansTool.Buy.Registration.InputState) &&
          AppPlansTool.Buy.Registration.Vat.enableButton() &&
          AppPlansTool &&
          AppPlansTool.Buy &&
          AppPlansTool.Buy.Payment &&
          AppPlansTool.Buy.Payment.token &&
          CwVal.validateInputsSet(AppPlansTool.Buy.Payment.InputElements)
        ) {
          disabled = false;
        }
        return disabled;
      }
    ),
    error: (
      () => {
      }
    ),
    label: 'Buy...',
    template: CwSpaceBuyTemplateModel.registration,
  };
  StepDone: CwSpaceBuyStepModel = {
    action: (
      () => {
      }
    ),
    continueLabel: '',
    disabled: undefined,
    error: undefined,
    label: 'Successful purchase',
    template: CwSpaceBuyTemplateModel.done,
  };
  SpaceBuySetup: CwSpaceBuySetupModel = {

    Progress: {
      stepIndex: 0,
      StepList: [
        this.StepSelect,
        this.StepRegistration,
        this.StepDone,
      ]
    },

    Registration: {
      InputSet: this.AppPlansTool.Buy.Registration.InputsSet,
      InputCountry: AppPlansTool.Buy.Registration.InputCountry,
      InputState: AppPlansTool.Buy.Registration.InputState,
    },

    SetupExtra: {
      Buy: {
        Select: {
          ExtraNews: AppPlansTool.Buy.Select.ExtraNews,
        }
      },
      Description: {
        collapsable: true,
      },
      Expansion: {
        // 2007000000
        anotherVisibleFunction: CwLogicSpacePlan.Buy.Performance.Expansion.addAnotherVisible,
        selectDisabledFunction: CwLogicSpacePlan.Buy.Performance.Expansion.selectDisabled,
        On: {
          Another: {
            clicked: (
              (Plan) => {
                CwLogicSpacePlan.Buy.On.Expansion.AddAnother.clicked(Plan);
              }
            )
          }
        }

      }
    },
    SetupMain: {
      Description: {
        collapsable: true,
      }
    },
    Summary: {
      ItemList: AppPlansTool.Buy.Select.MainNewList,
    }
  }

  CONTINUE_TEMPLATE_CONTEXT = CW_SPACE_PLAN_TOOL.CONTINUE_TEMPLATE_CONTEXT;
  PLAN_TYPE: CW_SPACE_SUBSCRIPTION_TYPE_MODEL;
  _visible = false;

  doing = false;

  plansBuyType = CW_SPACE_PLAN_TOOL_BUY_TYPE.SELECT;


  defineSelect = (
    (IO) => {
      CwLogicSpacePlan.Buy.Basic.define(IO);
    }
  );

  goToAdmin = () => {
    // 2007000000
    setTimeout(
      () => {
        AppPlansTool.Buy.SignIn = {
          email: AppPlansTool.Buy.Registration.email,
          password: AppPlansTool.Buy.Registration.password,
        }
        // noinspection SpellCheckingInspection
        CwWeb.goTo({path: '/datasignin'});
      },
      5000
    )
  };

  plansBuyContinueClick = (type: any) => {
    this.plansBuyType = type;
    AppWebTool.resetTitle();

    if (this.getPlansBuyDone) {
      this.AppPlansTool.Buy.do({
        successActionCustom: (
          (Data: any) => {
            AppPlansTool.Buy.Done.success = Data.error;
            CwWeb.loaderVisible = false;
       }
        ),
        errorActionCustom: (
          (Data) => {
            this.plansBuyType = CW_SPACE_PLAN_TOOL_BUY_TYPE.REGISTRATION;
            AppFeedbackTool.error(Data.error)
            CwWeb.loaderVisible = false;
          }
        ),
        errorRestActionCustom: (
          (Data) => {
            this.plansBuyType = CW_SPACE_PLAN_TOOL_BUY_TYPE.REGISTRATION;
            AppFeedbackTool.error('Try the operation again ...')
            CwWeb.loaderVisible = false;
          }
        ),
      });
      // } else if (this.getPlansBuyReview) {
      //   this.AppPlansTool.Buy.Agree.privacy = false;
      //   this.AppPlansTool.Buy.Agree.terms = false;
    } else if (this.getPlansBuyRegistration) {
      CwSpacePlanTool.definePlan(
        <CwSpacePlanToolDefinePlanModel>{
          ...this.AppPlansTool.Buy.Select.PlanNew,
          PlanList: CwBillingPlans.ItemsList,
          Target: this.AppPlansTool.Buy.Select.PlanNew,
        }
      )
      // Go back to plan selection
    } else {
      CwLogicSpacePlan.Buy.Data.MainList.forEach((item) => item.keep = true);
      CwLogicSpacePlan.Buy.Data.ExtraList.forEach((item) => item.keep = true);
      AppPlansTool.Buy.Payment.Input._reset();
      AppPlansTool.Buy.Payment.InputElements.forEach((Item) => Item._reset());
    }
  }


  // GETs //////////////////////////////////////////////////////////////////////

  get getContinueDisabled(): boolean {
    let _disabled = true;
    if (
      this.getPlansBuySelect &&
      // 200722000000
      CwUtil.exist({
        Array: CwLogicSpacePlan.IO.NewList,
        condition: (
          (Filter: any): boolean => {
            return Filter.quantity > 0 &&
              Filter.type === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.MAIN
          }
        )
      })
    ) {
      //
      _disabled = false;
    } else if (
      // // 2007170000
      // AppPlansTool.Buy.Agree.terms &&
      // AppPlansTool.Buy.Agree.privacy &&
      // AppPlansTool.Buy.Agree &&
      this.getPlansBuyRegistration &&
      AppPlansTool &&
      AppPlansTool.Buy &&
      AppPlansTool.Buy.Registration &&
      CwVal.validateInputsSet(AppPlansTool.Buy.Registration.InputsSet) &&
      CwVal.validateInput(AppPlansTool.Buy.Registration.InputCountry) &&
      CwVal.validateInput(AppPlansTool.Buy.Registration.InputState) &&
      AppPlansTool.Buy.Registration.Vat.enableButton() &&
      AppPlansTool &&
      AppPlansTool.Buy &&
      AppPlansTool.Buy.Payment &&
      AppPlansTool.Buy.Payment.token &&
      CwVal.validateInputsSet(AppPlansTool.Buy.Payment.InputElements)
    ) {
      _disabled = false;
    }
    // // 2007170000
    // else if (
    //   this.getPlansBuyPayment &&
    //   AppPlansTool &&
    //   AppPlansTool.Buy &&
    //   AppPlansTool.Buy.Payment &&
    //   ((AppPlansTool.Buy.Payment.Input &&
    //     AppPlansTool.Buy.Payment.Input.valid &&
    //     AppPlansTool.Buy.Payment.Input.changed) || (
    //     AppPlansTool.Buy.Payment.token
    //   ))
    // ) {
    //   _disabled = false;
    // }
    return _disabled;
  }

  get getContinueLabel(): string {
    return (this.getPlansBuyRegistration)
      ? 'Buy'
      : 'Continue'
      ;
  }

  get getAvailable(): boolean {
    return true;
  }

  get getPlansBuyDone(): boolean {
    return this.plansBuyType === CW_SPACE_PLAN_TOOL_BUY_TYPE.DONE;
  }


  get getPlansBuyRegistration(): boolean {
    return this.plansBuyType === CW_SPACE_PLAN_TOOL_BUY_TYPE.REGISTRATION;
  }

  get getPlansBuySelect(): boolean {
    return this.plansBuyType === CW_SPACE_PLAN_TOOL_BUY_TYPE.SELECT;
  }

  get getPlansBuySummary(): boolean {
    return this.plansBuyType !== CW_SPACE_PLAN_TOOL_BUY_TYPE.DONE;
  }

  get getVisible(): string {
    return (this._visible) ? 'visible' : 'invisible';
  }

  // GETs //////////////////////////////////////////////////////////////////////


  // ISs ///////////////////////////////////////////////////////////////////////

  get isElementCurrency(): boolean {
    return (
      CwToolCurrency.isEnabled &&
      (
        this.getPlansBuyRegistration ||
        this.getPlansBuySelect
      )
    );
  }

  // ISs ///////////////////////////////////////////////////////////////////////

  // NGs ///////////////////////////////////////////////////////////////////////
  ngOnInit() {
    CwBillingPlans.Load.do(<CwRestRequestParametersI>{
      successActionCustom: () => {
        const IO = {
          ApiList: CwBillingPlans.ItemsList,
          ExtraList: CwBillingPlans.Purchase.Available.ExtraList,
          MainList: CwBillingPlans.Purchase.Available.MainList,
          NewList: AppPlansTool.Buy.Select.MainNewList,
        };
        CwLogicSpacePlan.define(IO);
        this.defineSelect(IO);
        this._visible = true;
      }
    })
    // 2010290000.B2
    CwToolSca.Error.subscribe(this.goToAdmin);
    CwToolSca.NotNecessary.subscribe(this.goToAdmin);
    CwToolSca.Success.subscribe(this.goToAdmin);
  }

  // NGs ///////////////////////////////////////////////////////////////////////


}
