import {Component} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-text-middle',
  templateUrl: './cw-text-middle.component.html',
  styles: []
})
export class CwTextMiddleComponent {

}
