import {EntityBus} from '../entity-bus';
import {CwDefineUrl, CwFunction, CwMap} from '../../../model/model';
import {GetUserConfig} from '../../../user/get-user/get-user.config';
import {CwRestRequestMinParametersI} from '../../../tool/rest/model';
import {CwTrust} from '../../../trust/trust';
import {CwSocket} from '../../../socket/socket-management';
import {CwSocketActionModel, CwSocketActionPriorityModel, SOCKET_ACTION} from '../../../socket/socket-model';
import {CwBusClass} from '../../../model/bus';

class EntityGetAllRoles extends EntityBus {

  totalLicenses = 0;

  ItemsKey = 'name';

  /**
   * @override 1911220849
   * @param Data
   */
  defineData(Data: any) {
    this.totalLicenses = 0;
    super.defineData(Data);
  }

  DefineProperties: CwFunction[] = [
    (Properties) => {
      this.totalLicenses +=
        (Properties && Properties.is_host && Properties.total)
          ? Properties.total
          : 0
      ;
    },
    (Properties: any) => {
      (Properties.total > 0 || Properties.available === undefined) ? Properties.disabled = false : Properties.disabled = true;
      (Properties.available > 0 || Properties.available === undefined) ? Properties.disabled = false : Properties.disabled = true;
      Properties.nameNoPostfix = Properties.name.replace(GetUserConfig.ROLES_HOSTS_POSTFIX, '');
    }
  ];

  defineUrl: CwDefineUrl = (() => this.path + 'getAllRoles');

  do: CwFunction = ((Params: CwRestRequestMinParametersI) => {
    if (!Params) {
      Params = {};
    }
    const successFn = Params.successActionCustom;
    if (successFn) {
      Params.successActionCustom = (() => {
        this.Children.defineData();
        successFn(this.ItemsList);
      });
    } else {
      Params.successActionCustom = (() => {
        this.Children.defineData();
      });
    }

    this.restGet({
      ...Params,
      Bus: this,
      token: CwTrust.token
    });
  });

  constructor(Params) {
    super(Params);
  }

  Children = {
    BusList: [],
    defineData: (() => {
      this.Children.BusList.forEach((BusItem: CwBusClass) => {
        BusItem.defineData([...this.ItemsList]);
        if (BusItem.SuccessActionList && BusItem.SuccessActionList.length > 0) {
          BusItem.SuccessActionList.forEach(SuccessActionItem => {
            SuccessActionItem();
          })
        }
      })
    })
  };


  SocketActionMap: CwMap<CwSocketActionModel[]> = new Map()
    .set(
      SOCKET_ACTION.ROLES.UPDATE,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.ROLES.ADD,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.ROLES.DELETE,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.REGISTRATION,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.ADD.FROM_CSV,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.ADD.FROM_LDAP,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.DELETE,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.USER.REGISTRATION,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.DEVICE.CREATE,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.DEVICE.DELETE,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.GUEST.DELETE,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.GUEST.INVITE,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )
    .set(
      SOCKET_ACTION.GUEST.FROM_CSV,
      [<CwSocketActionModel>{
        fn: this.do,
        priority: CwSocketActionPriorityModel.medium,
      }]
    )


}

export const CwRolesBus = new EntityGetAllRoles({
  Socket: CwSocket,
});
