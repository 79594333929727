import {CwSpaceSubscriptionTool} from '@space/subscription/tool-space-subscription';
import {CwInput, CwInputType} from '@cw/components/input/cw-input-model';
import {CwWeb} from '@tool/web/tool-web';
import {CwEntityAccountBus} from '@rest/entity/account/entity-account.bus';
import {CwLiteral} from '@cw/literal/literal';
import {CwEntityCheckEmailFromPortalBus} from '@rest/entity/check-email-from-portal/entity-check-email-from-portal.bus';
import {CwVal} from '@tool/validation/tool-validation';
import {FREE, PlansToolModel} from './plans.model';
import {CwSpacePlanModel} from '@cw/components/space/plan/cw-space-plan.model';
import {CW_SPACE_SUBSCRIPTION_TOOL, CW_SPACE_SUBSCRIPTION_TYPE_MODEL} from '@space/subscription/tool-space-subscription-model';
import {CwSpacePlanTool} from '@space/plan/space-plan.tool';
import {CwBillingPlans} from '@rest/billing/plans/plans-bus';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwApiTool} from '@tool/api/tool-api';
import {CwValidateVatIdBus} from '@rest/billing/validate-vat-id/validate-vat-id-bus';
import {CwFunction} from '@cw/model/model';
import {APP_PLAN_STANDARD} from '@cw/logic/space/plan/cw-logic-space-plan.model';
import {CwLogicSpacePlan} from '@cw/logic/space/plan/cw-logic-space-plan';
import {CwTaxBus} from '@rest/billing/tax/tax-bus';
import {CwTaxDoI} from '@rest/billing/tax/tax-model';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';
import {CwToolSca} from '@cw/6.0/tools/sca/tool';


export const AppPlansBuyRegistrationEmailInput = new CwInput({
  api: 'email',
  label: 'Email',
  labelRequired: true,
  type: CwInputType.TEXT,
  onChange: (Item: CwInput) => {
    AppPlansTool.Buy.Registration.email = Item.value;
    Item.valid = false;
    if (Item && Item.value) {
      Item.changed = true;
      CwEntityCheckEmailFromPortalBus.do({
        Bus: CwEntityCheckEmailFromPortalBus,
        email: Item.value,
        errorActionCustom: (Data) => {
          Item.ValidationList.push(Data.error);
        },
        errorRestActionCustom: (Data) => {
          Item.ValidationList.push(Data.error);
        },
        successActionCustom: () => {
          Item.valid = true;
        },
      })
    }
  },
  reviewable: true,
  Validate: {
    cannotBeEmpty: true,
    email: true,
  },
  ValidationRelationList: [
    () => {
      CwVal.check(AppPlansBuyRegistrationEmailConfirmInput);
    }
  ]
})

export const AppPlansBuyRegistrationEmailConfirmInput = new CwInput({
  api: 'emailRepeat',
  label: 'Confirm Email',
  type: CwInputType.TEXT,
  Validate: {
    cannotBeEmpty: true,
    ConfirmInput: AppPlansBuyRegistrationEmailInput,
  }
});

export const AppPlansBuyRegistrationPasswordInput = new CwInput({
  api: 'password',
  label: 'Password',
  labelRequired: true,
  onChange: (Item: CwInput) => {
    AppPlansTool.Buy.Registration.password = Item.value;
  },
  type: CwInputType.PASSWORD_MINIMAL,
  Validate: {
    cannotBeEmpty: true,
    passwordKetan: true,
  },
  ValidationRelationList: [
    () => {
      CwVal.check(AppPlansBuyRegistrationPasswordConfirmInput);
    }
  ]
})

export const AppPlansBuyRegistrationPasswordConfirmInput = new CwInput({
  api: 'confirmPassword',
  label: 'Confirm Password',
  type: CwInputType.PASSWORD_MINIMAL,
  Validate: {
    cannotBeEmpty: true,
    ConfirmInput: AppPlansBuyRegistrationPasswordInput,
  }
});

export const AppPlansBuyRegistrationVATInput = new CwInput({
  api: 'business_vat_id',
  label: 'VAT ID',
  type: CwInputType.TEXT,
  class: 'col-6',
});


class AppPlansToolClass implements PlansToolModel {

  Buy = {
    // order by screen
    Select: {
      ExtraNews: [],
      MainNewList: [],
      Plans: [],
      PlanNew: undefined,
      keep: false,

      clear: ((): void => {
        this.Buy.Select.PlanNew = undefined;
        CwUtil.clear(this.Buy.Select.ExtraNews);
        CwUtil.clear(this.Buy.Select.MainNewList);
      }),


      definePlanNewByType: ((planType: CW_SPACE_SUBSCRIPTION_TYPE_MODEL) => {
        const PlanList = CwSpacePlanTool.getBy({
          id_collaborate_package: planType,
          PlanList: CwBillingPlans.ItemsList,
          returnAll: true,
          type: CwSpacePlanTool.TYPE.MAIN,
        });
        if (PlanList && PlanList[0]) {
          this.Buy.Select.PlanNew = PlanList[0];
          this.Buy.Select.PlanNew.quantity = 1;
          this.Buy.Select.PlanNew.Date = CwSpaceSubscriptionTool.defineEnd(APP_PLAN_STANDARD);
          this.Buy.Select.PlanNew.collapse = false;
        }
      }),


      On: {
        changed: ((PlanChanged: CwSpacePlanModel): void => {
          if (
            PlanChanged
          ) {
            const SelectedList = (
                PlanChanged.type === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.EXTRA
              ) ? AppPlansTool.Buy.Select.ExtraNews
              : (
                PlanChanged.type === CW_SPACE_SUBSCRIPTION_TOOL.TYPE.MAIN
              ) ? AppPlansTool.Buy.Select.MainNewList
                : undefined
            ;
            if (SelectedList) {
              if (
                PlanChanged.quantity === 0 &&
                PlanChanged.ExpansionList &&
                PlanChanged.ExpansionList.length > 0
              ) {
                PlanChanged.ExpansionList.forEach(
                  (ItemExpansion: any) => {
                    if (ItemExpansion.quantity > 0) {
                      ItemExpansion.quantity = 0;
                      CwSpacePlanTool.selectChanged({
                        PlanChanged: ItemExpansion,
                        PlanList: PlanChanged.ExpansionList,
                        SelectedList,
                      });
                    }
                  }
                )
              }
              // 2004150000-12
              CwSpacePlanTool.selectChanged({
                PlanChanged,
                PlanList: CwBillingPlans.ItemsList,
                SelectedList,
              });

            }
          }
        }),
      }, // On


    },

    Registration: {
      // 2007170000

      email: undefined,

      firstName: undefined,
      lastName: undefined,
      password: undefined,
      languageCountryCode: undefined,

      InputCountry: new CwInput(
        {
          api: 'country',
          label: 'Country',
          notLabel: true,
          type: CwInputType.VALIDATION,
          Validate: {
            // // 2007170000
            // passive: true,
            cannotBeEmpty: true,
          }
        },
      ),
      InputState: new CwInput(
        {
          api: 'state',
          label: 'State',
          notLabel: true,
          type: CwInputType.VALIDATION,
          Validate: {
            cannotBeEmpty: true,
          }
        },
      ),

      InputsSet: [
        new CwInput({
          api: 'firstName',
          label: 'First Name',
          labelRequired: true,
          onChange: (Item: CwInput) => {
            AppPlansTool.Buy.Registration.firstName = Item.value;
          },
          reviewable: true,
          type: CwInputType.TEXT,
          Validate: {
            cannotBeEmpty: true,
          },
        }),
        new CwInput({
          api: 'lastName',
          label: 'Last Name',
          labelRequired: true,
          reviewable: true,
          type: CwInputType.TEXT,
          onChange: (Item: CwInput) => {
            AppPlansTool.Buy.Registration.lastName = Item.value;
          },
          Validate: {
            cannotBeEmpty: true,
          }
        }),
        AppPlansBuyRegistrationEmailInput,
        AppPlansBuyRegistrationEmailConfirmInput,
        AppPlansBuyRegistrationPasswordInput,
        AppPlansBuyRegistrationPasswordConfirmInput,
        // 2007170000
        new CwInput({
          api: 'companyName',
          class: 'col-12',
          label: 'Company Name',
          reviewable: true,
          type: CwInputType.TEXT,
          onChange: (Item: CwInput) => {
          },
          Validate: {}
        }),
        new CwInput({
          api: 'address',
          class: 'col-12',
          label: 'Address',
          reviewable: true,
          type: CwInputType.TEXT,
          onChange: (Item: CwInput) => {
          },
          Validate: {}
        }),
        new CwInput({
          api: 'city',
          label: 'City',
          reviewable: true,
          type: CwInputType.TEXT,
          onChange: (Item: CwInput) => {
          },
          Validate: {}
        }),
        new CwInput({
          api: 'postal_code',
          label: 'Zip/Postal',
          reviewable: true,
          type: CwInputType.TEXT,
          onChange: (Item: CwInput) => {
          },
          Validate: {}
        }),

      ],

      // 2007170000
      Vat: {
        business_type_vat_id: undefined,
        business_vat_country_code: undefined,
        Input: new CwInput({
          api: 'business_vat_id',
          label: 'VAT ID',
          type: CwInputType.TEXT,
          class: 'col-6',
          onChange: (Item: CwInput) => {
            if (this.Buy.Registration.Vat.business_type_vat_id &&
              this.Buy.Registration.Vat.business_type_vat_id !== 'none') {
              this.Buy.Registration.Vat.validVat();
            }
            this.Buy.Registration.Vat.calculateTaxes();
          },
        }),
        enableButton: () => {
          return (this.Buy.Registration.Vat.Input.valid && this.Buy.Registration.Vat.Input.changed) ||
            this.Buy.Registration.Vat.business_type_vat_id === 'none' ||
            !this.Buy.Registration.Vat.business_type_vat_id;
        },
        validVat: () => {
          this.Buy.Registration.Vat.Input.valid = false;
          CwValidateVatIdBus.do({
            Bus: CwValidateVatIdBus,
            Parameters: {
              type: this.Buy.Registration.Vat.business_type_vat_id,
              value: this.Buy.Registration.Vat.Input.value,
              countryCode: this.Buy.Registration.Vat.business_vat_country_code,
            },
            errorActionCustom: ((Data) => {
              this.Buy.Registration.Vat.Input.valid = false;
              this.Buy.Registration.Vat.Input.ValidationList.push(Data.error);
            }),
            errorRestActionCustom: (() => {
            }),
            successActionCustom: (() => {
              this.Buy.Registration.Vat.Input.valid = true;
            }),
          });
        },
        calculateTaxes: () => {
          CwTaxBus.do(<CwTaxDoI>{
            Bus: CwTaxBus,
            country: this.Buy.Registration.InputCountry.value,
            countryCode: this.Buy.Registration.Vat.business_vat_country_code,
            vatType: this.Buy.Registration.Vat.business_type_vat_id,
            vatId: this.Buy.Registration.Vat.Input.value,
            errorActionCustom: (() => {
            }),
            errorRestActionCustom: (() => {
            }),
            successActionCustom: ((Data) => {
              if (Data && Data.result !== undefined) {
                CwTaxBus.tax = Data.result;
              }
            }),
          });
        },
      },


    },

    Payment: {
      // 2011240953
      defineInputsElements: (): CwInput[] => {
        return [
          new CwInput({
            api: '',
            class: 'col-6',
            editable: true,
            label: 'Card number',
            labelRequired: true,
            type: CwInputType.CARD_NUMBER,
            Validate: {cannotBeEmpty: true},
          }),
          new CwInput({
            api: '',
            class: 'col-6',
            label: 'Card expiry',
            labelRequired: true,
            type: CwInputType.CARD_EXPIRY,
            editable: true,
            Validate: {cannotBeEmpty: true},
          }),
          new CwInput({
            api: '',
            class: 'col-6',
            label: 'Card CVC',
            labelRequired: true,
            type: CwInputType.CARD_CVC,
            editable: true,
            Validate: {cannotBeEmpty: true},
          }),
        ];
      },
      Input: new CwInput({
        api: '',
        label: 'Credit Card',
        type: CwInputType.CARD,
        Validate: {
          cannotBeEmpty: true,
        }
      }),
      InputElements: undefined,
      token: undefined
    },

    Agree: {
      privacy: false,
      terms: false,
    },

    do: (IO: {
      successActionCustom: CwFunction,
      errorActionCustom: CwFunction,
      errorRestActionCustom: CwFunction
    }) => {
      // 2009230000.D11
      CwToolSca.successActionCustomCheck(IO);
      CwWeb.loaderVisible = true;
      const PayLoadCustom = CwApiTool.extractFromInputSet(
        [
          ...AppPlansTool.Buy.Registration.InputsSet,
          this.Buy.Registration.InputCountry,
          this.Buy.Registration.InputState,
          this.Buy.Registration.Vat.Input
        ]
      );

      CwEntityAccountBus.do({
        Bus: CwEntityAccountBus,
        PayLoadCustom: {
          ...PayLoadCustom,
          business_type_vat_id: this.Buy.Registration.Vat.business_type_vat_id,
          business_vat_country_code: this.Buy.Registration.Vat.business_vat_country_code,
          contact:
            AppPlansTool.Buy.Registration.firstName +
            ' ' +
            AppPlansTool.Buy.Registration.lastName +
            ''
          ,
          email: AppPlansTool.Buy.Registration.email,
          language: CwLiteral.Language.language,
          invoiceLanguage: AppPlansTool.Buy.Registration.languageCountryCode,
          partnerId: '1',
          password: AppPlansTool.Buy.Registration.password,
          stripeToken: (
            AppPlansTool.Buy.Payment.Input &&
            AppPlansTool.Buy.Payment.Input.CardToken &&
            AppPlansTool.Buy.Payment.Input.CardToken.id &&
            true
          )
            ? AppPlansTool.Buy.Payment.Input.CardToken.id
            : AppPlansTool.Buy.Payment.token.id
          ,
          subscriptions: CwLogicSpacePlan.Buy.toApi(AppPlansTool.Buy.Select.MainNewList),
        },
        ...IO,
      })
    },

    Done: {
      error: undefined,
      success: undefined,
    },

    SignIn: undefined,

  };

  View = {
    definePlans: () => {

      this.View.definePlanFree(
        CwSpacePlanTool.getBy({
          id_collaborate_package: CwSpacePlanTool.TYPE.FREE,
          PlanList: CwBillingPlans.ItemsList,
        })
      );
      this.View.definePlanBasic();
      this.View.definePlan(
        CwSpacePlanTool.getBy({
          id_collaborate_package: CwSpacePlanTool.TYPE.BASIC_1,
          PlanList: CwBillingPlans.ItemsList,
          returnAll: true,
          type: CwSpacePlanTool.TYPE.MAIN,
        }),
        'Basic 1'
      );
      this.View.definePlan(
        CwSpacePlanTool.getBy({
          id_collaborate_package: CwSpacePlanTool.TYPE.BASIC_5,
          PlanList: CwBillingPlans.ItemsList,
          returnAll: true,
          type: CwSpacePlanTool.TYPE.MAIN,
        }),
        'Basic 5'
      );
      this.View.definePlan(
        CwSpacePlanTool.getBy({
          id_collaborate_package: CwSpacePlanTool.TYPE.BASIC_10,
          PlanList: CwBillingPlans.ItemsList,
          returnAll: true,
          type: CwSpacePlanTool.TYPE.MAIN,
        }),
        'Basic 10'
      );
      this.View.definePlanPro();
      // 200722000000
      this.View.definePlan(
        CwSpacePlanTool.getBy({
          id_collaborate_package: CwSpacePlanTool.TYPE.PRO_5,
          PlanList: CwBillingPlans.ItemsList,
          returnAll: true,
          type: CwSpacePlanTool.TYPE.MAIN,
        }),
        'Pro 5'
      );
      // 200722000000
      this.View.definePlan(
        CwSpacePlanTool.getBy({
          id_collaborate_package: CwSpacePlanTool.TYPE.PRO_10,
          PlanList: CwBillingPlans.ItemsList,
          returnAll: true,
          type: CwSpacePlanTool.TYPE.MAIN,
        }),
        'Pro 10'
      );
      // 200722000000
      this.View.definePlan(
        CwSpacePlanTool.getBy({
          id_collaborate_package: CwSpacePlanTool.TYPE.PRO_50,
          PlanList: CwBillingPlans.ItemsList,
          returnAll: true,
          type: CwSpacePlanTool.TYPE.MAIN,
        }),
        'Pro 50'
      );
      // 200722000000
      this.View.definePlan(
        CwSpacePlanTool.getBy({
          id_collaborate_package: CwSpacePlanTool.TYPE.PRO_100,
          PlanList: CwBillingPlans.ItemsList,
          returnAll: true,
          type: CwSpacePlanTool.TYPE.MAIN,
        }),
        'Pro 100'
      );
      // 2004150000-23
      this.View.definePlan(
        [{
          complete_description: [
            'All Pro features +',
            'Private cloud with unlimited storage',
            'Premium support',
            'H.323/SIP gateway available',
            'Classroom available',
            'Webinar available',
          ]
        }],
        'Enterprise'
      )
    },

    defineExtras: (PlanTargetList: any[]) => {
      PlanTargetList.push(...CwBillingPlans.Purchase.Available.ExtraList);
      PlanTargetList.forEach(Item => {
        if (!Item.ApiPlanList) {
          Item.ApiPlanList = [];
        }
        Item.ApiPlanList.push(...CwSpacePlanTool.getBy({
          PlanList: CwBillingPlans.ItemsList,
          id_collaborate_package: Item.id_collaborate_package,
          returnAll: true,
          type: Item.type
        }));
      });
    },

    definePlan: (PlanSourceList, searched) => {
      const PlanTargetList = this.View.Plans.filter(
        Filter => Filter.name === searched
      );
      if (PlanSourceList[0] && PlanTargetList[0]) {
        this.View.defineProperties(PlanSourceList[0], PlanTargetList[0]);
        PlanTargetList[0].ApiPlanList.push(...PlanSourceList)
        this.View.defineExtras(PlanTargetList[0].ExtraList);
      }
    },
    // 200722000000
    definePlanBasic: (
      () => {
        const PlanTargetList = this.View.Plans.filter(
          Filter => Filter.name === 'Basic'
        );
        this.View.defineProperties(CwBillingPlans.ItemsList.filter(
          (Filter: any) => {
            return (
              CwLogicSpacePlan.isBasic1(Filter) &&
              Filter.interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API &&
              // 2008130000
              CwToolCurrency.isSelected(Filter)
            )
          }
        )[0], PlanTargetList[0]);
        if (
          PlanTargetList[0] &&
          PlanTargetList[0].interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API
        ) {
          PlanTargetList[0].amount = PlanTargetList[0].amount / 12;
        }
      }
    ),
    definePlanPro: (
      () => {
        const PlanTargetList = this.View.Plans.filter(
          Filter => Filter.name === 'Pro'
        );
        this.View.defineProperties(CwBillingPlans.ItemsList.filter(
          (Filter: any) => {
            return (
              CwLogicSpacePlan.isPro5(Filter) &&
              Filter.interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API
            )
          }
        )[0], PlanTargetList[0]);
        if (
          PlanTargetList[0] &&
          PlanTargetList[0].interval === CW_SPACE_SUBSCRIPTION_TOOL.INTERVAL.YEARLY.API
        ) {
          PlanTargetList[0].amount = PlanTargetList[0].amount / 12;
        }

      }
    ),
    definePlanFree: (PlanSource) => {
      if (typeof PlanSource === 'object') {
        const PlanTargetList = this.View.Plans.filter(
          Filter => Filter.name === 'Free'
        );
        if (PlanSource && PlanTargetList[0]) {
          this.View.defineProperties(PlanSource, PlanTargetList[0]);
        }
      }
    },

    defineProperties: (PlanSource, PlanTarget) => {
      const _PropertiesList = Object.keys(PlanSource);
      CwUtil.setProperties(
        PlanSource,
        PlanTarget,
        // 2004150000-30
        _PropertiesList.filter(filter =>
          filter !== 'interval' &&
          filter !== 'name' &&
          true
        ));
    },
    Plans: [
      // 2007291000
      FREE,
      {
        amount: 666,
        // 200722000000
        // amount: 825,
        ApiPlanList: [],
        name: 'Basic',
        ExtraList: [],
        hosts: 'Starting at 1 host',
        interval: 'year',
        quantity: 1,
        Routers: [
          // DEV-PURCHASE DEV-2005270000-1, DEV-2006091221
          {
            router: '/plans/basic/buy',
            name: 'Buy now',
          }
        ],
        top: 'For small teams',
        Visible: {
          description: true,
          router: true,
        },
      },
      {
        // 2004150000-30
        amount: 1667,
        ApiPlanList: [],
        ExtraList: [],
        hosts: 'Starting at 5 hosts',
        interval: 'year',
        name: 'Pro',
        perHost: 5,
        quantity: 1,
        Routers: [
          // // 2007291000
          // {
          //   router: '/plans/pro/trial',
          //   name: 'Free Trial',
          // },
          {
            name: 'Buy now',
            router: '/plans/pro/buy',
          },
        ],
        top: 'For small and medium teams',
        Visible: {
          description: true,
          router: true,
        },
      },
      {
        // 2004150000-30
        amount: 0,
        amountReplace: 'Pricing varies based on hosts and custom features.',
        ApiPlanList: [],
        ExtraList: [],
        hosts: 'Starting at 25 hosts',
        interval: 'month',
        name: 'Enterprise',
        quantity: 1,
        Routers: [
          {
            name: 'Contact sales',
            router: '/plans/enterprise',
          }
        ],
        top: 'For larger organizations',
        Visible: {
          description: true,
          router: true,
        },
      },
    ]
  } // View

  constructor() {
    this.Buy.Payment.InputElements = this.Buy.Payment.defineInputsElements();
  }

}

/**
 * @version 2004150000-10
 */
export const AppPlansTool: AppPlansToolClass = new AppPlansToolClass();
