import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CwProtocolTool} from '@tool/protocol/tool-protocol';
import {CW_PROTOCOL_TOOL} from '@tool/protocol/model';
import {CwWeb} from '@tool/web/tool-web';
import {CwIs} from '@tool/tool-is';
import {AppWebTool} from '../app-web.tool';
import {App} from '../app';
import {CwTokenRequestInviteTokenBusModel} from '@cw/token/request-invite-token/model';

@Component({
  selector: 'app-invite-logic',
  templateUrl: './invite.component.html',
  styles: []
})
/**
 * @version 2009210000
 * @description The name of the guest is retrieved (rest/token/requestInviteToken)
 */
export class InviteComponent {

  ValidationList = [];

  error = ((Data?) => {
    AppWebTool.Title = {
      main: (Data && Data.error) ? Data.error : this.ValidationList[0],
      secondary: 'Try the operation again ...',
    }
  });

  constructor(private _ActivatedRoute: ActivatedRoute) {
    CwProtocolTool.source = CW_PROTOCOL_TOOL.SOURCE.INVITE;
    App.referred = CwWeb.getParam({
      ActivatedRoute: this._ActivatedRoute,
      paramName: 'email',
    });
    if (
      App.referred &&
      CwIs.email({
        value: App.referred,
        ValidationList: this.ValidationList
      })
    ) {
      // 2009210000
      App.Cw.TokenRequestInviteTokenBus.do(<CwTokenRequestInviteTokenBusModel>{
        email: App.referred,
        successActionCustom: (() => {
          App.token = App.Cw.TokenRequestInviteTokenBus.token;
          // 2009210000.D3
          App.referredName = App.Cw.TokenRequestInviteTokenBus.userName;
          CwWeb.goTo({path: '/download/check/' + App.token})
        }),
        errorActionCustom: this.error,
        errorRestActionCustom: this.error,
      })
    } else {
      this.error();
    }

  }
}
