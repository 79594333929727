import {CwWeb} from '../web/tool-web';

export const CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT_DEFAULT = (() => {

});

export const CW_PROTOCOL_FREE_WINDOWS_FIREFOX_CHECK = (() => {
  CwWeb.goTo({path: '/download/app' + CwWeb.Route.is});
});

export const CW_PROTOCOL_FREE_WINDOWS_FIREFOX_DOWNLOAD = (() => {
});
