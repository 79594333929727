/**
 * @version R2.1 - Password checking
 */
import {CsIs} from '../tool/is';
import {CsValidationConfig} from './config';
import {CsString} from '../tool/string';


/**
 * @version R71.0 - Resources - Settings - Required
 */
export class CsValidationClass {

  validation(_in: any,
             validationList: any[] = [],
             callback: (
               (_in: any,
                validationList: any[]) => boolean
               ),
             _message?: string,
             _literalParams?: any): boolean {
    let _valid = false;
    try {
      _valid = (
        CsIs.defined(_in, callback) &&
        callback(_in, validationList)
      );
    } catch (error) {
      _valid = false;
    } finally {
      if (!_valid) {
        if (CsIs.defined(validationList, _message, _literalParams)) {
          validationList.push({
            literal: _message,
            literalParams: _literalParams
          });
        } else if (CsIs.defined(validationList, _message)) {
          validationList.push(_message);
        }
      }
    }
    return _valid;
  }

  required(_in: any, validationList: any[] = []): boolean {
    return this.validation(
      _in,
      validationList,
      function (_in, validationList) {
        if (
          CsIs.defined(_in) ||
          CsIs.stringEmpty(_in) ||
          CsIs.arrayEmpty(_in)
        ) {
          return false;
        } else {
          return true;
        }
      },
      'Required'
    );
  }

  valid(_ValidationList: any[]): boolean {
    return (_ValidationList && _ValidationList.length === 0);
  }

  notSpaceBeginEnd(value: string, validationList: string[]): boolean {
    const _validation =
      CsIs.stringEmpty(value) ||
      (!(value[0] === ' ') && !(value[value.length - 1] === ' '));
    if (
      !_validation &&
      CsIs.array(validationList)
    ) {
      validationList.push(CsValidationConfig.MSG_SPACE_BEGIN_END_NOT_VALID);
    }
    return _validation;
  }

  justValidCharacters(value: string, validationList: string[]): boolean {
    let validCharacters: string[] = [];
    validCharacters =
      CsValidationConfig.PASSWORD_SPECIAL_CHARACTERS.concat(
        CsValidationConfig.PASSWORD_LETTERS.concat(CsValidationConfig.PASSWORD_NUMBERS)
      );

    const _validation =
      CsIs.stringEmpty(value) ||
      CsString.contains(value.toLowerCase(), validCharacters, true);
    if (
      !_validation &&
      CsIs.array(validationList)
    ) {
      validationList.push(CsValidationConfig.MSG_INVALID_CHARACTER);
    }
    return _validation;
  }


  numbers(value: string): boolean {
    return CsString.contains(value, CsValidationConfig.PASSWORD_NUMBERS);
  }

  letters(value: string, _onlyPossibleValues = false): boolean {
    return CsString.contains(value.toLowerCase(), CsValidationConfig.PASSWORD_LETTERS, _onlyPossibleValues);
  }


  lettersAndNumbers(value: string, validationList: string[]): boolean {
    const _validation =
      CsIs.stringEmpty(value) ||
      (
        CsValidation.letters(value) &&
        CsValidation.numbers(value)
      );
    if (
      !_validation &&
      CsIs.array(validationList)
    ) {
      validationList.push(CsValidationConfig.MSG_LETTERS_NUMBERS);
    }
    return _validation;
  }

  maxLength(value: string, max: number, validationList: string[] = null): boolean {
    if (validationList) {
      const _validation = CsValidation.maxLength(value, max);
      if (
        !_validation &&
        CsIs.array(validationList)
      ) {
        validationList.push(CsValidationConfig.MSG_MAX_LENGTH);
      }
      return _validation;
    } else {
      return (
        CsIs.defined(value) &&
        CsIs.defined(max) &&
        // max >= Array.from(value).length &&
        // max >= value.split('').length &&
        max >= value.length &&
        true
      );
    }
  }

  minLength(value: string, min: number, validationList: string[] = null): boolean {
    if (validationList) {
      const _validation = CsValidation.minLength(value, min);
      if (
        !_validation &&
        CsIs.array(validationList) &&
        CsIs.stringNotEmpty(value)
      ) {
        validationList.push(CsValidationConfig.MSG_MIN_LENGTH);
      }
      return _validation;
    } else {
      return (
        CsIs.defined(value) &&
        CsIs.defined(min) &&
        min <= value.length &&
        true
      );
    }
  }

  confirm(_value: string, _confirm: string, validationList: string[], _resetValidationList = false): boolean {
    if (_resetValidationList) {
      validationList.splice(0, validationList.length);
    }
    const validation = (_value === _confirm)
    if (!validation) {
      validationList.push('The values are not the same.');
    }
    return validation;
  }
  /**
   * All error messages at the time.
   * @param value
   * @param validationList
   * @param _resetValidationList
   */
  passwordOk(value: string, validationList: string[], _resetValidationList = false): boolean {
    if (_resetValidationList) {
      validationList.splice(0, validationList.length);
    }
    const validationListToDoNothing = [];
    const validation = (
      value &&
      validationList &&
      CsIs.true([
        CsValidation.specialCharacter(value, validationList),
        CsValidation.minLength(
          value,
          CsValidationConfig.PASSWORD_MIN_LENGTH,
          validationListToDoNothing
        ),
        CsValidation.maxLength(
          value,
          CsValidationConfig.PASSWORD_MAX_LENGTH,
          validationListToDoNothing
        ),
        CsValidation.lettersAndNumbers(value, validationListToDoNothing),
        CsValidation.justValidCharacters(value, validationListToDoNothing),
        CsValidation.notSpaceBeginEnd(value, validationListToDoNothing),
        true
      ]) &&
      true
    );

    if (!validation) {
      validationList.push('Password must contain 8-100 characters.');
      validationList.push('No empty space at the beginning or at the end.');
      // 1905201233
      validationList.push('Password must contain...');
    }
    return validation;
  }

  // R2.1 - Password checking
  specialCharacter(_value: string, validationList = null): boolean {
    // 1
    if (validationList) {
      const _validation =
        CsIs.stringEmpty(_value) ||
        CsValidation.specialCharacter(_value);

      if (
        !_validation &&
        CsIs.array(validationList)
      ) {
        validationList.push(CsValidationConfig.MSG_SPECIAL_CHARACTER);
      }
      return _validation;


    } else {
      return CsString.contains(_value, CsValidationConfig.PASSWORD_SPECIAL_CHARACTERS);
    }
    // 0
    // const _validation =
    //   CsIs.stringEmpty(value) ||
    //   CoValidations.specialCharacterBasic(value);
    // if (
    //   !_validation &&
    //   CsIs.array(validationList)
    // ) {
    //   validationList.push(CoConfigBus40.UI.VALIDATIONS.MESSAGES.SPECIAL_CHARACTER);
    // }
    // return _validation;
  }


}

export const CsValidation = new CsValidationClass();


