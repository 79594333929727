import {Component, Input, OnInit} from '@angular/core';
import {CwGrid2ComponentModel, CwGrid2SetupModel} from '@cw/6.0/components/grid2/items/model';

@Component({
  selector: 'app-cw-grid2-item-text',
  templateUrl: './component.html',
  styleUrls: ['./component.css']
})
export class CwGrid2ItemTextComponent implements OnInit, CwGrid2ComponentModel {

  private _Setup: CwGrid2SetupModel;

  value: string;
  @Input() set Setup(Setup: CwGrid2SetupModel) {
    this.value = Setup.Item.Data[Setup.Column.property];
  }

  constructor() {
  }

  ngOnInit() {
  }

}
