import {CwBusClass} from '../model/bus';
import {CwFunction} from '../model/model';
import {CwToolKeepC} from '../tool/keep/tool-keep';

/**
 * @version 1904101050
 */
export abstract class UserBus extends CwBusClass {
    DefineProperties: CwFunction[];
    Keep: CwToolKeepC<any>;
    Setup: any;
    path = 'user/';
}
