import {Component} from '@angular/core';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-currency-select',
  templateUrl: './cw-currency-select.component.html',
  styles: []
})
export class CwCurrencySelectComponent {

  CwToolCurrency = CwToolCurrency;

}
