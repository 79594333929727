import {TokenBusClass} from '@cw/token/token-bus';
import {CwFunction} from '@cw/model/model';
import {CwRestRequestParametersI} from '@tool/rest/model';
import {CwTokenRequestInviteTokenBusModel} from '@cw/token/request-invite-token/model';

/**
 * @version 2009210000
 */
export class TokenRequestInviteTokenBus extends TokenBusClass {
  // 2010191252
  tokenSuffixDisabled = true;
  token: string;
  userName: string;

  DefineProperties: CwFunction[] = [
    ((Properties) => {
      if (Properties && Properties.token) {
        this.token = Properties.token;
      }
    }),
    ((Properties) => {
      if (Properties && Properties.userName) {
        this.userName = Properties.userName;
      } else if (Properties && Properties.username) {
        this.userName = Properties.username;
      }
    }),
  ];

  do: CwFunction = ((Params: CwTokenRequestInviteTokenBusModel) => {
    if (Params && Params.email) {
      this.userName = Params.email;
      this.restPost({
        ...<CwRestRequestParametersI>Params,
        PayLoadCustom: {
          email: Params.email
        },
        Bus: this
      })
    }
  });

  defineUrl = (): string => this.path + 'requestInviteToken';

}
