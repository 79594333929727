
export class CwGetUserConfigClass {
  configPrefix = 'API';

  LICENSE_PLUS = 'Plus';
  LICENSE_PREMIUM = 'Premium';
  LICENSE_STANDARD = 'STANDARD';
  LICENSE_PRO = 'PRO';
  LICENSE_MANAGEMENT = 'RESOURCE_MANAGEMENT';
  LICENSE_FREE = 'FREE';
  LICENSE_EMPTY = 'None';
  LICENSE_SEVERAL_MESSAGE = 'Selection includes several licenses';
  LICENSE_LIMITLESS = 100000;
  ROLES_HOSTS_POSTFIX = '_HOST';
  ROL_DEFAULT = 'User';

  API_ROLES_OWNER = 'OWNER';
  API_ROLES_ADMINISTRATOR = 'ADMINISTRATOR';
  API_ROLES_MONITOR = 'MONITOR';
  API_ROLES_IM_USER = 'IM USER';
}

export const GetUserConfig = new CwGetUserConfigClass();
