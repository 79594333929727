import {CwBusClass} from '../../model/bus';
import {CwToolKeepC} from '../../tool/keep/tool-keep';
import {CwDefineUrl, CwFunction} from '../../model/model';

export abstract class WebinarBus extends CwBusClass {
  DefineProperties: CwFunction[];
  Keep: CwToolKeepC<any>;
  Setup: any;
  defineUrl: CwDefineUrl;
  do: CwFunction;
  path = 'webinar/';
}
