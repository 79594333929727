import {CW_PROTOCOL_TOOL} from './model';
import {
  CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT,
  CW_PROTOCOL_DEFAULT_DEFAULT_FIREFOX,
  CW_PROTOCOL_FREE_WINDOWS_DEFAULT, CW_PROTOCOL_FREE_WINDOWS_FIREFOX
} from './tool-protocol-browser';


export const CW_PROTOCOL_DEFAULT_DEFAULT = new Map()
  .set(CW_PROTOCOL_TOOL.BROWSER.DEFAULT, CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT)
;

export const CW_PROTOCOL_FREE_DEFAULT = new Map()
  .set(CW_PROTOCOL_TOOL.BROWSER.DEFAULT, CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT)
  .set(CW_PROTOCOL_TOOL.BROWSER.FIREFOX, CW_PROTOCOL_DEFAULT_DEFAULT_FIREFOX)
;

export const CW_PROTOCOL_FREE_WINDOWS = new Map()
  // .set(CW_PROTOCOL_TOOL.BROWSER.DEFAULT, CW_PROTOCOL_FREE_WINDOWS_DEFAULT)
  .set(CW_PROTOCOL_TOOL.BROWSER.FIREFOX, CW_PROTOCOL_FREE_WINDOWS_FIREFOX)
;

