export interface CwTrustKeepI {
  token: string;
  trust: boolean;
  updated: number;
  iframeBehaviour: boolean;
}

export const CwTrustKeepDefault: CwTrustKeepI = {
  token: undefined,
  trust: false,
  updated: Date.now(),
  iframeBehaviour: false,
};
