<ng-container *ngIf="Plan">

  <div class="row">
<!--    <div-->
<!--      class="col-12 h5 text-clearone font-weight-bolder my-0"-->
<!--    >{{Plan?.name}}&nbsp;{{'features'}}</div>-->
<!--    <hr>-->
    <div class="col-12 text-left">
      <ng-container
        *ngFor="let description of Plan.complete_description"
      >
        <small
          class="delta-indicator"
          [translate]="description"
        ></small>
      </ng-container>
    </div>
  </div><!--    row-->

</ng-container>
