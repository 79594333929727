import {CwBusClass} from '../../model/bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwToolKeepC} from '../keep/tool-keep';
import {CwInstallerDoI, CwInstallerSetupI} from './model';
import {saveAs as SaveAs} from 'file-saver';
import {CwWeb} from '../web/tool-web';
import {CwVersionCheckPlatform} from '../../version/check/check-model';

export const CW_INSTALLER = {
  URL: 'version/downloadInstaller',
  FILE: 'COLLABORATESpace.exe'
};

/**
 * @version 1910031537
 */
export class InstallerClass extends CwBusClass {

  // 1910070847
  platform: number;

  DefineProperties: CwFunction[];
  Keep: CwToolKeepC<any>;
  Setup: CwInstallerSetupI;
  defineUrl: CwDefineUrl = (() =>
      this.Setup.restUrl +
      CW_INSTALLER.URL +
      '?token=' +
      this.token +
      // 1910070847
      '&platform=' +
      this.platform +
      ''
  );

  download: CwFunction = ((Params: CwInstallerDoI): boolean => {
    // 1911200916
    return false;
    let _downloaded = false;

    if (!this.Setup) {
      console.error('CwInstaller not setup', '1910041012');
      return false;
    }

    if (
      Params &&
      Params.token &&
      this.Setup &&
      this.Setup.restUrl &&
      CwWeb.getOperativeSystem().isWindows &&
      true
    ) {
      this.platform = CwVersionCheckPlatform.windows;
      this.token = Params.token;
      try {
        console.log('1910070847', 'this.defineUrl()', this.defineUrl());
        SaveAs(this.defineUrl(), CW_INSTALLER.FILE);
        _downloaded = true;
      } catch (e) {
        console.error('1910031537', 'Not download', e);
      }
    }
    return _downloaded;
  });
  do: CwFunction;

}

export const CwInstaller = new InstallerClass();
