import {CwLogicSpacePlanDefineModel} from '@cw/logic/space/plan/cw-logic-space-plan.model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwLogicSpacePlanBuyBasicClass} from '@cw/logic/space/plan/cw-logic-space-plan-buy-basic';

export class CwLogicSpacePlanBuyProClass extends CwLogicSpacePlanBuyBasicClass {
  constructor() {
    super();
    this.Buy.Pro = {
      clear: (
        () => {
          CwUtil.clear(this.Buy.Pro.Plan);
        }
      ),
      define: (
        (IO: CwLogicSpacePlanDefineModel) => {
          this.Buy.clear();
          this.Buy.Pro.clear();
          CwUtil.clear(IO.NewList);
          CwUtil.exec(this.Buy.Pro.Define, IO);
        }
      ),
      Define: {

        _100_Pro5: ((IO: CwLogicSpacePlanDefineModel): void => {
          this.Buy.Data.Pro5 = {
            ...CwUtil.clone(this.Plan.Pro5),
            _order: 101,
            minimized: false,
            quantity: 1
          };
        }),

        _300_basic1: ((IO: CwLogicSpacePlanDefineModel): void => {
          this.Buy.Data.Basic1 = {
            ...CwUtil.clone(this.Plan.Basic1),
            _order: 301,
            minimized: true,
            quantity: 0
          };
        }),

        _500_mainList: ((IO: CwLogicSpacePlanDefineModel): void => {
          this.addMain(
            this.Buy.Data.Pro5,
            this.Buy.Data.Basic1
          );
          this.addBasicAdd();
          this.addProAdd();

        }),

        _600_extraList: ((IO: CwLogicSpacePlanDefineModel): void => {
          this.add(
            this.Buy.Data.ExtraList,
            this.Plan.Cloud100,
            this.Plan.Gateway,
            CwUtil.clone(this.Plan.ClassroomWeb),
          );
          this.addCloudAdd();
        }),

        new: ((IO: CwLogicSpacePlanDefineModel): void => {
            this.add(
              IO.NewList,

              this.Plan.Pro5,
              this.Plan.Pro10,
              this.Plan.Pro50,
              this.Plan.Pro100,

              this.Plan.Basic1,
              this.Plan.Basic5,
              this.Plan.Basic10,

              this.Plan.Cloud100,
              // 2009151206
              this.Plan.Cloud500,

              this.Plan.Gateway,
              this.Plan.Classroom,
              this.Plan.ClassroomExpansion,
              this.Plan.Webinar,
              this.Plan.WebinarExpansion,
            )
          }),

      },

    };
  }
}
