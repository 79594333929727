import {Component} from '@angular/core';
import {AppConfig} from '../app.config';
import {CoWeb} from '../co/tools/web';
import {CwComponent} from '../CollaborateSpaceWebTools/model/component';
import {AppFootBus} from './foot-bus';
import {App} from '../app';

@Component({
  selector: 'app-foot',
  templateUrl: 'foot.component.html',
})
export class FootComponent extends CwComponent {

  App = App;

  AppConfig = AppConfig;
  today: Date = new Date;
  innerWidth: number;
  CoWeb = CoWeb;
  // 1904101050
  DownloadUrl = AppFootBus.DownloadUrl;

  constructor() {
    super();
    this.innerWidth = innerWidth;

  }

}
