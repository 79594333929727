/**
 * @version R69.10 - Ticket 2301 - ...the size of the text increases.
 * @template CoConfig - 1807030956
 * TODO: To link with DB add CsValidationConfig to CoManagementAdminService.configList
 */
import {CsConfigureClass} from '../configure';

class CsValidationConfigClass extends CsConfigureClass {
  configPrefix = 'validation'; // To link with DB
  configSave;

    MSG_INVALID_CHARACTER = 'There is a invalid character';
    MSG_LETTERS_NUMBERS = 'The value must contain letters and numbers.';
    MSG_MAX_LENGTH = 'The number of characters allowed has been exceeded.';
    MSG_MIN_LENGTH = 'The minimum number of characters has not been reached.';
    MSG_SPECIAL_CHARACTER = '';
    MSG_SPACE_BEGIN_END_NOT_VALID = 'whitespace are not valid at begin or end';

    PASSWORD_LETTERS = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
    'j', 'k', 'l', 'm', 'n', 'ñ', 'o', 'p', 'q',
    'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
    ];
  PASSWORD_MAX_LENGTH = 100;
  PASSWORD_MIN_LENGTH = 8;
  PASSWORD_NUMBERS = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
  ];
  PASSWORD_SPECIAL_CHARACTERS = [
    ' ',
    '!',
    '"',
    '#',
    '$',
    '%',
    '&',
    '\'',
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '@',
    '[',
    '\\',
    ']',
    '^',
    '_',
    '`',
    '{',
    '|',
    '}',
    '~',
  ];

}

/**
 * @version R2.1 - Password checking
 * @type {CsValidationConfigClass}
 */
export const CsValidationConfig = new CsValidationConfigClass();
