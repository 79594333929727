import {Component, OnInit} from '@angular/core';
import {PlansSalesComponent} from './plans-sales.component';
import {CwVal} from '@tool/validation/tool-validation';
import {CwLiteral} from '@cw/literal/literal';
import {CwInfoContactSalesBus} from '@rest/info/contact-sales/info-contact-sales.bus';
import {CwRestRequestParametersI} from '@rest/model';

@Component({
  selector: 'app-plans-sales-passive',
  templateUrl: './plans-sales.component.html',
  styleUrls: ['./plans-sales.component.scss']
})
/**
 * @version 2006290000.3
 */
export class PlansSalesPassiveComponent extends PlansSalesComponent implements OnInit {

  valid = true;

  check = (
    (): boolean => {
      this.valid = true;
      for (const Item of this.AppPlansSalesTool.AllInputSet) {
        if (!CwVal.check(Item)) {
          this.valid = false;
          break;
        }
      }
      return this.valid && this.recaptcha;
    }
  );

  definePassive = (() => {
    this.AppPlansSalesTool.AllInputSet.forEach(
      Item => Item.Validate.passive = true
    );
    this.AppPlansSalesTool.Comments.value = CwLiteral.translate('I have questions. Have a sales person contact me.');
  })


  get getSendDisabled(): boolean {
    return false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.definePassive();
    this.On.sendClick = (() => {
      if (this.check()) {
        const PayLoadCustom: any = {
          comments: this.AppPlansSalesTool.Comments.value,
          country: this.Country.api,
          state: this.State.name,
        };
        this.AppPlansSalesTool.AllInputSet.forEach(InputItem => {
          if (InputItem && InputItem.api) {
            PayLoadCustom[InputItem.api] = InputItem.value;
          }
        });
        if (PayLoadCustom.firstName && PayLoadCustom.lastName) {
          PayLoadCustom.contact = PayLoadCustom.firstName + ' ' + PayLoadCustom.lastName;
        }
        CwInfoContactSalesBus.do(<CwRestRequestParametersI>{
          PayLoadCustom,
          errorRestActionCustom: this.On.error,
          errorActionCustom: this.On.error,
          successActionCustom: this.On.success,
        });
      }
    });
  }

}
