import {CwBusAppI, CwFunction} from '../../model/model';

export class Grid {
}

enum GridTypeEnum {
  SELECTION_MODAL,
  // 2002201042
  SUBSCRIPTION,
}

/**
 * @version 2002111112
 */
export const CW_GRID = {
  TYPE: GridTypeEnum,
};

export class SetupGrid {
  boldIfCallback?: any; // 2009290000
  callback?: any; // 2009290000
  GridColumns: any;
  GridData: any;
  firmwareAction?: CwFunction;
  viewOnly?: boolean;
  viewOnlyWithScroll?: boolean;
  App?: CwBusAppI;
  StatusFilteringOptions?: any[];
  id?: string;
  Tool?: {
    Search?: {
      class?: string,
      value?: string,
      isModal?: boolean
    }
  };
  newButtonBar?: boolean;
  Hidden?: {
    searchTool?: boolean;
    datepickerTool?: boolean;
  };
  type?: GridTypeEnum;
}

export class ModalParams {
  Data: any;
  ModalComponent: any;
}

export class GridRow {
  id: number;
  values: any;
  stringValues: string;

  constructor(id: number, values: any) {
    this.id = id;
    this.values = values;
    this.stringValues = this.toStringValues();
  }

  public toStringValues(): string {
    let toStringValues = '';
    for (const value of this.values) {
      toStringValues = toStringValues.concat(value.toLowerCase());
    }
    return toStringValues;
  }
}
