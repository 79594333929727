<div class="bg-white">
  <cw-ready
    [ready]="ready"
  >
    <div
      style="width: 80%; padding-bottom: 30px; padding-left: 0; padding-right: 0; margin-right: auto; margin-left: auto; max-width: 1000px"
    >
      <ul class="nav nav-tabs">
        <li [ngClass]="App.Navigation.planSubscriptions? 'active' : ''">
          <!--            <li [ngClass]="activePlans">-->
          <a
            xxx-2005280951
            data-toggle="tab"
            routerLink="/plans-pricing"
          >
            <!--           (click)="showPlans()"-->
            <span translate class="text-uppercase px-3">Plans</span>
          </a>
        </li>
        <!--R2.7 - Not ended sections remove for the Beta version. - 1810041656-->
        <li [ngClass]="App.Navigation.planCallRates? 'active' : ''">
          <!--            <li [ngClass]="activeRates">-->
          <a
            xxx-2005280951
            routerLink="/plans-pricing-call-rates"
            data-toggle="tab"
          >
            <!--           (click)="showRates()"-->
            <span translate class="text-uppercase px-3">Calling Rates</span>
          </a>
        </li>
      </ul>
      <form #formCtrl="ngForm" [hidden]="!step1">
        <div class="mt-4">
          <app-plans-view-standard></app-plans-view-standard>
        </div>

      </form>

      <div [hidden]="!rates">
        <app-rates-standard></app-rates-standard>
      </div>
    </div>
  </cw-ready>
</div>
<div [hidden]="billing">
  <app-question></app-question>
  <app-home-host-join></app-home-host-join>
</div>
<div [hidden]="!rates">
  <app-summary-rates></app-summary-rates>
</div>
