/**
 * @version R3.1 - Update links...
 * @template CoConfig - 1807030956
 * TODO: To link with DB add CoConfig to CoManagementAdminService.configList
 */
import {CoConfigureClass} from './co/configure';
import {ROUTE_DOWNLOAD} from './route.config';

export class AppConfigClass extends CoConfigureClass {
  configPrefix; // To link with DB
  configSave;

  COLLABORATE_PROTOCOL = 'collaboratespace:';

  token: string;

  GO_TO_DOWNLOAD = '/' + ROUTE_DOWNLOAD;
  GO_TO_DOWNLOAD_ANDROID = this.GO_TO_DOWNLOAD + 'Android';
  GO_TO_DOWNLOAD_IOS = this.GO_TO_DOWNLOAD + 'Ios';
  GO_TO_DOWNLOAD_MAC = this.GO_TO_DOWNLOAD + 'Mac';
  GO_TO_DOWNLOAD_WIN = this.GO_TO_DOWNLOAD + 'Win';
  GO_TO_PLANS = '/plans';

}

/**
 * @version R1.0 - Download activated
 * @type {AppConfigClass}
 */
export const AppConfig = new AppConfigClass();
