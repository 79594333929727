import {Component, Inject, OnInit} from '@angular/core';
import {AppPlansSalesTool} from './plans-sales.tool';
import {CwOnPremiseBus} from '@rest/billing/on-premise/billing-on-premise-bus';
import {CwInfoContactSalesBus} from '@rest/info/contact-sales/info-contact-sales.bus';
import {CwRestRequestParametersI} from '@tool/rest/model';
import {CwVal} from '@tool/validation/tool-validation';
import {CwStateBus} from '@rest/literal/state/state-bus';
import {CwCountryStateBus} from '@rest/literal/country-min/country-min-bus';
import {AppFeedbackTool} from '../../feedback/feedback.tool';
import {AppWebTool} from '../../app-web.tool';
import {DOCUMENT} from '@angular/common';
import {CwScrollTool} from '@tool/scroll/tool';
import {PageScrollService} from 'ngx-page-scroll';
import {CwWeb} from '@tool/web/tool-web';

@Component({
  selector: 'app-plans-sales',
  templateUrl: './plans-sales.component.html',
  styleUrls: ['./plans-sales.component.scss']
})
export class PlansSalesComponent implements OnInit {

  AppPlansSalesTool = AppPlansSalesTool;
  Country: any;


  On = {
    Country: {
      blur: (() => {
        AppPlansSalesTool.Country.changed = true;
        CwVal.check(AppPlansSalesTool.Country);
        CwVal.check(AppPlansSalesTool.State);
      }),
      change: (ValueNew): void => {
        this.StateSetup = {
          OptionList: []
        };

        if (this.Country && this.Country.api) {
          this.State = undefined;
          if (this.Country.StateList) {
            this.StateSetup.OptionList.push(
                ...this.Country.StateList
            );
          }
          // 2006101639
          if (this.StateSetup.OptionList.length === 0) {
            this.StateSetup.OptionList.push({
              coGridSelected: false,
              coId: undefined,
              country_id: -1,
              cwKey: -1,
              id: -1,
              name: this.Country.api,
              web: this.Country.web,
            });
          }
          this.State = this.StateSetup.OptionList[0];
          AppPlansSalesTool.Country.set(this.Country.api);
          AppPlansSalesTool.Country.changed = true;
          AppPlansSalesTool.State.set(this.State.name);
          AppPlansSalesTool.State.changed = true;
          this.State.selected = true;
        }
        CwVal.check(AppPlansSalesTool.Country);
        CwVal.check(AppPlansSalesTool.State);

      },
    },

    // 2006111524
    error: (Data) => {
      CwWeb.loaderHide();
      AppFeedbackTool.error(
        (Data && Data.error)
          ? Data.error
          : (Data)
          ? Data
          : ''
      )
      CwScrollTool.goTo({
        id: '#ScrollFeedback',
      })

    },

    googleRecaptchaSuccess: (() => {
      this.recaptcha = true;
    }),

    sendClick: (() => {
      const PayLoadCustom: any = {
        comments: AppPlansSalesTool.Comments.value,
        country: this.Country.api,
        state: this.State.name,
      };
      AppPlansSalesTool.InputsSet.forEach(InputItem => {
        if (InputItem && InputItem.api) {
          PayLoadCustom[InputItem.api] = InputItem.value;
        }
      });
      if (PayLoadCustom.firstName && PayLoadCustom.lastName) {
        PayLoadCustom.contact = PayLoadCustom.firstName + ' ' + PayLoadCustom.lastName;
      }
      CwInfoContactSalesBus.do(<CwRestRequestParametersI>{
        PayLoadCustom,
        errorRestActionCustom: this.On.error,
        errorActionCustom: this.On.error,
        successActionCustom: this.On.success,
      });
    }),

    stateChange: ((Event) => {
      if (this.State && this.State.name) {
        AppPlansSalesTool.State.set(this.State.name);
        AppPlansSalesTool.State.changed = true;
      }
      CwVal.check(AppPlansSalesTool.State);
    }),

    // 2006111524
    success: () => {
      AppPlansSalesTool._reset();
      AppFeedbackTool.success({
        App: {
          main: 'The message has been sent successfully.',
          secondary: 'The sales department will contact you shortly.',
        }
      })
      CwScrollTool.goTo({
        id: '#ScrollFeedback',
      });
    }
  }

  recaptcha = false;
  State: any;
  StateSetup: any;
  Visible = {
    onPremise: undefined
  }

  get getReady(): boolean {
    return (
      this.Visible.onPremise !== undefined &&
      // CwStateBus.ready &&
      true
    )
  }

  get getSendDisabled(): boolean {
    if (AppPlansSalesTool.Country.changed) {
      CwVal.check(AppPlansSalesTool.Country);
      CwVal.check(AppPlansSalesTool.State);
    }
    return (
      // 2006111524
      !(AppPlansSalesTool.Country && AppPlansSalesTool.Country.value) ||
      // 2006111524
      !(AppPlansSalesTool.State && AppPlansSalesTool.State.value) ||
      !CwVal.validateInputsSet(AppPlansSalesTool.InputsSet) ||
      !(
        AppPlansSalesTool.Comments.changed &&
        AppPlansSalesTool.Comments.valid &&
        true) ||
      !this.recaptcha ||
      false
    );
  }

  get getVisibleOnCloud(): boolean {
    return (this.getReady)
      ? (!this.Visible.onPremise)
        ? true
        : false
      : false
  }

  get getVisibleOnPremise(): boolean {
    return (this.getReady)
      ? (this.Visible.onPremise)
        ? true
        : false
      : false
  }

  get getVisible(): string {
    return (this.getReady)
      ? ''
      : 'd-none'
      ;
  }

  constructor(
    // 2006111524
    // tslint:disable-next-line:no-shadowed-variable
    private PageScrollService: PageScrollService,
    @Inject(DOCUMENT) private Document: any
  ) {
    CwScrollTool.setup({PageScrollService, Document});

    CwOnPremiseBus.Load.do({
      successActionCustom: () => this.Visible.onPremise = CwOnPremiseBus.isOnPremise,
    })
    CwStateBus.Load.do();
    CwCountryStateBus.Load.do();
    // 2005280855
    AppPlansSalesTool._reset();
  }

  ngOnInit() {
    AppWebTool.Title = {
      main: 'Contact Sales',
      secondary: '',
    }
  }
}
