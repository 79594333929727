export enum ColumnEnum {
  __INIT,

  FULL = 18,

  LABEL = 3,
  INPUT = 9,
  VALIDATION = 6,

  XXS = 1,
  XS = 2,
  S = 3,
  XM = 4,
  M = 6,
  L = 9,
  XL = 12,
  XXL = 15,

  // New Design - Users - 201707031302

  ALIGN_CENTER,
  ALIGN_LEFT,
  ALIGN_RIGHT,

  TYPE_ACTION,
  TYPE_AVATAR,
  TYPE_AVATAR_CHECKABLE,
  TYPE_CHECKABLE,
  TYPE_CHECKABLE_DISABLED,
  TYPE_DATE,
  TYPE_DATE_FORMAT_LANGUAGE, // 2009290000.8
  TYPE_DATE_TIME,
  TYPE_DATE_ZONE,
  TYPE_DATE_WITH_SECONDS,
  TYPE_DOT,
  TYPE_DURATION,
  TYPE_ICON,
  TYPE_IMAGE,
  TYPE_INVOICE_ACTIONS,
  TYPE_STATUS_DOT,
  TYPE_STATUS_CONVERGENCE,
  TYPE_STATUS_CPRO,
  TYPE_STATUS_PERIODICITY,
  TYPE_STATUS_SORT,
  TYPE_FIRMWARE_CPRO,
  TYPE_GUESTS_ACTIONS,
  TYPE_LAST_UPDATE,
  TYPE_LDAP,
  TYPE_LINK_MODAL,
  TYPE_LINK_ICON,
  TYPE_LINK_PAGE,
  TYPE_LINK_PAGE_HISTORY,
  TYPE_LINK_POPOVER,
  TYPE_LINK_WITH_TEXT,
  TYPE_LOCATION,
  TYPE_LITERAL,
  TYPE_NORMAL,
  TYPE_RESOURCE_UPDATE_AVAILABLE,
  TYPE_OS,
  TYPE_SCOPE,
  TYPE_STATUS,
  TYPE_SUBSCRIPTION,
  TYPE_TITLE_CASE,
  TYPE_TRANSFORM_ID,
  TYPE_TRANSLATE,
  TYPE_TRANSLATE_LIST,
  TYPE_TRANSLATE_AND_TRUNCATE_TEXT,
  TYPE_TRUNCATE_TEXT,
  TYPE_SUBSCRIPTIONS_ACTIONS,
  TYPE_UPPERCASE,
  TYPE_USER_REGISTRATION,
  TYPE_WARNING,
  TYPE_WEB,

  __END,

}
