import {DataSignUpCouponModel, DataSignUpCouponVisibleModel} from './data-sign-up.model';
import {CwInfoCouponDoModel} from '@rest/info/coupon/info-coupon.model';
import {CwInfoCouponBus} from '@rest/info/coupon/info-coupon.bus';
import {CwOnPremiseBus} from '@rest/billing/on-premise/billing-on-premise-bus';
import {CwUtil} from '@tool/util/cw-tool-util';

export const AppDataSignUpCouponTool: DataSignUpCouponModel = {
  api: undefined,
  display: false,
  enabled: false,
  ready: false,
  value: undefined,
  Visible: {
    input: true,
    invalidMessage: false,
    removeButton: false, // 2005060000-4
    validateButton: true, // 2005060000-4
    validMessage: false,
  },

  check: (Params: CwInfoCouponDoModel) => {
    if (this.Coupon.enabled && Params) {
      CwInfoCouponBus.do(Params)
    }
  },

  checkCouponExternal: () => {
    this.Coupon.display = false;
    if (this.couponExternal) {
      this.Coupon.check(<CwInfoCouponDoModel>{
        coupon: this.couponExternal,
        errorActionCustom: () => {
          // 2005060000-2
          this.Coupon.api = undefined;
          this.Coupon.visibility({
            input: true,
            invalidMessage: false,
            removeButton: false,
            validateButton: true,
            validMessage: false,
          });
        },
        errorRestActionCustom: () => {
          // 2005060000-2
          this.Coupon.api = undefined;
          this.Coupon.visibility({
            input: true,
            invalidMessage: false,
            removeButton: false,
            validateButton: true,
            validMessage: false,
          });
        },
        successActionCustom: () => {
          // 2005060000-1
          this.Coupon.api = this.couponExternal;
          this.Coupon.visibility({
            input: false,
            invalidMessage: false,
            removeButton: false,
            validateButton: false,
            validMessage: false,
          });
        }
      });
    } else {
      // 2005060000-3
      this.Coupon.visibility({
        input: true,
        invalidMessage: false,
        removeButton: false,
        validateButton: true,
        validMessage: false,
      });
    }
  }, // checkCouponExternal

  removeButtonClicked: () => {
    this.Coupon.value = undefined;
    this.Coupon.api = undefined;
    this.Coupon.visibility({
      input: true,
      invalidMessage: false,
      removeButton: false,
      validateButton: true,
      validMessage: false,
    })
  }, // removeButtonClicked

  setup: () => {
    if (CwOnPremiseBus.ready) {
      this.Coupon.setupReady();
    } else {
      CwOnPremiseBus.Bus$.subscribe(this.Coupon.setupReady)
    }
  },

  setupReady: () => {
    if (this.CwOnP.is) {
      this.Coupon.enabled = false;
      this.Coupon.visibility({
        input: false,
        invalidMessage: false,
        removeButton: false,
        validateButton: false,
        validMessage: false
      });
      this.Coupon.display = true;
    } else {
      this.Coupon.enabled = true;
      this.Coupon.checkCouponExternal();
    }
  },

  validateButtonClicked: () => {
    this.Coupon.check(<CwInfoCouponDoModel>{
      coupon: this.Coupon.value,
      successActionCustom: () => {
        // 2005060000-5
        this.Coupon.api = this.Coupon.value;
        this.Coupon.visibility({
          input: true,
          invalidMessage: false,
          removeButton: true,
          validateButton: false,
          validMessage: true,
        });
      },
      errorRestActionCustom: () => {
        // 2005060000-6
        this.Coupon.api = undefined;
        this.Coupon.visibility({
          input: true,
          invalidMessage: true,
          removeButton: false,
          validateButton: true,
          validMessage: false,
        });
      },
      errorActionCustom: () => {
        // 2005060000-6
        this.Coupon.api = undefined;
        this.Coupon.visibility({
          input: true,
          invalidMessage: true,
          removeButton: false,
          validateButton: true,
          validMessage: false,
        });
      },

    })
  }, // validateButtonClicked

  visibility: (State: DataSignUpCouponVisibleModel) => {
    if (State) {
      CwUtil.setProperties(State, this.Coupon.Visible);
      this.Coupon.display = true;
    }
  }
}
