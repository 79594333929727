<ng-container [ngTemplateOutlet]="getTemplate"></ng-container>
<!--2009210000.D2-->
<ng-template #TemplateInvite>

  <div class="qContainer">
    <div style="width: 80%; padding-left: 0; padding-right: 0; margin-right: auto; margin-left: auto">
      <h2 class="text-center">
        <span
          translate>You have been invited to connect to COLLABORATE Space, by</span>&nbsp;<strong>{{getFrom}}</strong>
      </h2>
    </div>
  </div>

  <app-data-sign-up-passive-trial
    [couponExternal]="couponExternal"
  ></app-data-sign-up-passive-trial>


</ng-template>


<!--2009210000-->
<ng-template #TemplateInviteD1>

  <div class="qContainer">
    <div style="width: 80%; padding-left: 0; padding-right: 0; margin-right: auto; margin-left: auto">
      <h2 class="text-center">
        <span
          translate>You have been invited to connect to COLLABORATE Space, by</span>&nbsp;<strong>{{getFrom}}</strong>
      </h2>
    </div>
  </div>


  <div class="d-flex flex-row justify-content-center py-5">
    <div class="d-flex flex-row align-items-center">
      <div class="d-flex flex-column">
        <div class="h5"
             translate="New to Space?"
        >
        </div>
        <button appCw appCwForm
                [routerLink]="getCreateLink"
                type="button"
        >
          <!--          2007291000-->
          {{'Personal' | translate | uppercase}}
          <!--                    {{'Free Trial' | translate | uppercase}}-->
          <!--          {{'Free' | translate | uppercase}}-->
        </button>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="cw-w-50 border-dark border-right"></div>
      <div class="cw-w-50 border-0"></div>
    </div>
    <div class="d-flex flex-row align-items-center">
      <div class="d-flex flex-column">

        <button appCw appCwForm
                (click)="On.Start.clicked()"
                type="button"
        >
          {{'Start' | translate | uppercase}}
        </button>
      </div>
    </div>
  </div>


</ng-template>
