import {CwBusClass} from '../../model/bus';
import {CwFunction} from '../../model/model';
import {CwToolKeepC} from '../../tool/keep/tool-keep';

/**
 * @version 1905281612
 */
export abstract class LiteralBusClass extends CwBusClass {
  apiBus = false;
  DefineProperties: CwFunction[];
  Keep: CwToolKeepC<any>;
  Setup: any;

  path = '/literal/assets/';
}
