/**
 * @since 4.0.0.0.R15.0 - Reload & Session
 */
import {CwIs} from './tool-is';


class SafeClass {

  PREFIX = '##';

  get(_in: string): string {
    return (_in && _in.startsWith(CwSafe.PREFIX))
      ? CwSafe.decode(_in.slice(CwSafe.PREFIX.length))
      : _in
      ;
  }

  set(_in: string): string {
    return this.PREFIX + this.encode(_in);
  }

  /**
   * @param _in
   * @returns {string}
   * @since 4.0.0.0.R15.0 - Reload & Session
   */
  private encode(_in: string): string {
    let _encode: string;
    try {
      if (CwIs.stringNotEmpty(_in)) {

        if (CwIs.defined(_in, typeof (window), typeof (window.btoa))) {
          _encode = window.btoa(_in);
        } else {
          // TODO: not defined window.btoa
          _encode = _in;
        }
      }
    } catch (error) {
      throw new TypeError('CoSafe.encode');
    }
    return _encode;
  }

  /**
   * @param {string} _in
   * @returns {string}
   * @since 4.0.0.0.R15.0 - Reload & Session
   */
  private decode(_in: string): string {
    let _decode: string = _in;
    try {
      if (CwIs.defined(_in, typeof (window), typeof (window.atob))) {
        _decode = window.atob(_in);
        // TODO: checkUpdate if object or string
      }
    } catch (error) {
      throw new TypeError('CoSafe.decode');
    }
    return _decode;
  }

  // 1906250845

}

export const CwSafe = new SafeClass();
