import {CwFunction} from '../../CollaborateSpaceWebTools/model/model';
import {CwDateTool} from '../../CollaborateSpaceWebTools/tool/date/date.tool';
import {TimeFormatHelper} from '../helpers/time-format.helper';
import {CwIs} from '@tool/tool-is';

class UiConfigClass {

  AVATAR = {
    COLOR_DEFAULT: '#000000',
    INITIAL_DEFAULT: ':)',
  };

  MAX_NUM_ITEMS_TO_SHOW = 5;

  RESOURCES = {
    LAST_BACKUP: {
      _TEST_VALUES: true,
      API_PROPERTY: 'lastBackup',
      DEFAULT: 'Never',
    },
    MODEL: {
      API_PROPERTY: 'model',
      TYPES: [
        {
          API: '500',
          LITERAL: 'COLLABORATE Live 300',
          CONVERGENCE: '300'
        },
        {
          API: '600',
          LITERAL: 'COLLABORATE Live 600',
          CONVERGENCE: '600'
        },
      ]
    },
    STATUS: {
      COLOR_DEFAULT: '#C0C0C0',
      LITERAL_DEFAULT: 'Disconnected',
      LITERAL_OFFLINE: 'offline',
      API_PROPERTY: 'Data',
      ISSUES: 'Issues',
      DOWN: 'Down',
      COMMUNICATION_FAILED: 'Communication Service Failed',
      HEALTHY: 'Healthy',
      TYPES: [
        {
          API: 'Available to Chat',
          COLOR: 'green',
          LITERAL: 'Available',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'available',
          COLOR: 'green',
          LITERAL: 'Available',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'Online',
          COLOR: 'green',
          LITERAL: 'Available',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'In Call',
          COLOR: 'green',
          LITERAL: 'In Call',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'sptCall',
          COLOR: 'greenyellow',
          LITERAL: 'In Call',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'sptCollaborate',
          COLOR: 'limegreen',
          LITERAL: 'In Meeting',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'Recording',
          COLOR: 'darkred',
          LITERAL: 'Recording',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'Streaming',
          COLOR: 'indianred',
          LITERAL: 'Streaming',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'Whiteboard',
          COLOR: 'mediumvioletred',
          LITERAL: 'Whiteboard',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'SharingData',
          COLOR: 'orange',
          LITERAL: 'Sharing Data',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'Do Not Disturb',
          COLOR: 'red',
          LITERAL: 'Do Not Disturb',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'dnd',
          COLOR: 'red',
          LITERAL: 'Do Not Disturb',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'Away',
          COLOR: 'yellow',
          LITERAL: 'Away',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'Extended Away',
          COLOR: 'yellow',
          LITERAL: 'Away',
          CONVERGENCE: 'Healthy',
          connected: true
        },
        {
          API: 'Unknown/Not Recognized',
          COLOR: '#C0C0C0',
          LITERAL: 'Disconnected',
        },
        {
          API: 'offline',
          COLOR: '#C0C0C0',
          LITERAL: 'Disconnected',
          CONVERGENCE: 'Down'
        },
        {
          API: 'Disconnected',
          COLOR: '#C0C0C0',
          LITERAL: 'Disconnected',
          CONVERGENCE: 'Down'
        },
        {
          API: 'sptInvisible',
          COLOR: '#C0C0C0',
          LITERAL: 'Disconnected',
          CONVERGENCE: 'Down',
          connected: true
        },
        {
          API: 'xa',
          COLOR: '#C0C0C0',
          LITERAL: 'Disconnected',
          CONVERGENCE: 'Down',
          connected: true
        },
      ]
    },
    UPDATE_AVAILABLE: {
      _TEST_VALUES: true,
      API_PROPERTY: 'update',
    }
  };

  USERS = {
    FILTER: {
      REGISTERED: '1',
      PENDING: '2',
    },
    STATUS: {
      COLOR_DEFAULT: '#C0C0C0',
      LITERAL_DEFAULT: 'Disconnected',
      API_PROPERTY: 'Data',
      TYPES: [
        {
          API: 'Available to Chat',
          COLOR: 'green',
          HEX: '#008000',
          LITERAL: 'Available to Chat',
          CONVERGENCE: 'Available',
          connected: true
        },
        {
          API: 'available',
          COLOR: 'green',
          HEX: '#008000',
          LITERAL: 'Available',
          CONVERGENCE: 'Available',
          connected: true
        },
        {
          API: 'Online',
          COLOR: 'green',
          HEX: '#008000',
          LITERAL: 'Online',
          CONVERGENCE: 'Available',
          connected: true
        },
        {
          API: 'sptCollaborate',
          COLOR: 'limegreen',
          HEX: '#32cd32',
          LITERAL: 'In Meeting',
          CONVERGENCE: 'In Meeting',
          connected: true
        },
        {
          API: 'Do Not Disturb',
          COLOR: 'red',
          HEX: '#ff0000',
          LITERAL: 'Do Not Disturb',
          CONVERGENCE: 'Do Not Disturb',
          connected: true
        },
        {
          API: 'dnd',
          COLOR: 'red',
          HEX: '#ff0000',
          LITERAL: 'Do Not Disturb',
          CONVERGENCE: 'Do Not Disturb',
          connected: true
        },
        {
          API: 'away',
          COLOR: 'yellow',
          HEX: '#ffff00',
          LITERAL: 'Away',
          CONVERGENCE: 'Away',
          connected: true
        },
        {
          API: 'Extended Away',
          COLOR: 'yellow',
          HEX: '#ffff00',
          LITERAL: 'Away',
          CONVERGENCE: 'Away',
          connected: true
        },
        {
          API: 'offline',
          COLOR: '#C0C0C0',
          HEX: '#C0C0C0',
          LITERAL: 'Disconnected',
          CONVERGENCE: 'Disconnected'
        },
        {
          API: 'Disconnected',
          COLOR: '#C0C0C0',
          HEX: '#C0C0C0',
          LITERAL: 'Offline',
          CONVERGENCE: 'Disconnected'
        },
        {
          API: 'sptInvisible',
          COLOR: '#C0C0C0',
          HEX: '#C0C0C0',
          LITERAL: 'Invisible',
          CONVERGENCE: 'Disconnected',
          connected: true
        },
        {
          API: 'xa',
          COLOR: '#C0C0C0',
          HEX: '#C0C0C0',
          LITERAL: 'Invisible',
          CONVERGENCE: 'Disconnected',
          connected: true
        },
      ]
    },
  };

  INVOICES = {
    STATUS: {
      COLOR_DEFAULT: '#C0C0C0',
      LITERAL_DEFAULT: 'Disconnected',
      TYPES: [
        {
          API: 'paid',
          COLOR: 'green',
          LITERAL: 'Paid Invoice',
          CONVERGENCE: 'Paid Invoice',
        },
        {
          API: 'unpaid',
          COLOR: 'red',
          LITERAL: 'Unpaid Invoice',
          CONVERGENCE: 'Unpaid Invoice',
        }
      ]
    }
  };

  MEETINGS = {
    STATUS: {
      COLOR_DEFAULT: '#C0C0C0',
      LITERAL_DEFAULT: '',
      TYPES: [
        {
          API: 'available',
          COLOR: 'green',
          LITERAL: 'Available',
          CONVERGENCE: 'Available',
        },
        {
          API: 'created',
          COLOR: 'yellow',
          LITERAL: 'Created',
          CONVERGENCE: 'Created',
        },
        {
          API: 'finished',
          COLOR: 'red',
          LITERAL: 'Finished',
          CONVERGENCE: 'Finished',
        }
      ]
    }
  };


  STORAGE = {
    PRECISION: 2,
    SI: 1024,
    UNITS: [
      'bytes',
      'KB',
      'MB',
      'GB',
      'TB',
      'PB'
    ]
  };

  hasIssues: CwFunction = ((Device) => {
    if (Device &&
      Device.listProtocolRegistration) {
      for (const itemProtocol of Device.listProtocolRegistration) {
        if (itemProtocol.status === 'Not Connected') {
          return true;
        }
      }
    }
    return false;
  });

  isDown: CwFunction = ((Device) => {
    if (Device &&
      Device.status) {
      return (Device.status === this.RESOURCES.STATUS.LITERAL_DEFAULT ||
        Device.status === this.RESOURCES.STATUS.LITERAL_OFFLINE);
    }
    return true;
  });

  isHealthy: CwFunction = ((Device) => {
    if (Device &&
      Device.status) {
      return (this.RESOURCES.STATUS.HEALTHY === this.getLabelResourceConvergenceStatusFromDevice(Device));
    }
    return true;
  });


  constructor() {
  }

  getStatusListResourcesConvergence(): any {
    const statusList = [this.RESOURCES.STATUS.HEALTHY, this.RESOURCES.STATUS.ISSUES, this.RESOURCES.STATUS.DOWN];
    return statusList;
  }

  getStatusListUsersConvergence(): any {
    const statusList = [];
    for (const status of this.USERS.STATUS.TYPES) {
      if (statusList.indexOf(status.CONVERGENCE) === -1) {
        statusList.push(status.CONVERGENCE);
      }
    }
    return statusList;
  }

  getStatusListResources(): any {
    const statusList = [];
    for (const status of this.RESOURCES.STATUS.TYPES) {
      if (statusList.indexOf(status.LITERAL) === -1) {
        statusList.push(status.LITERAL);
      }
    }
    return statusList.sort();
  }

  getResourceConvergenceStatusFromApi (statusApi: string): string {
    const result = UiConfig.RESOURCES.STATUS.TYPES.find(statusType => statusType.API.toUpperCase() === statusApi.toUpperCase());
    return result ? result.CONVERGENCE : '';
  }

  getLabelResourceConvergenceStatusFromDevice (Device: any): string {
    if (this.hasIssues(Device) && !this.isDown(Device)) {
      return  UiConfig.RESOURCES.STATUS.COMMUNICATION_FAILED;
    }
    const result = UiConfig.RESOURCES.STATUS.TYPES.find(statusType => statusType.API.toUpperCase() === Device.status.toUpperCase());
    return result ? result.CONVERGENCE : '';
  }

  getResourceConvergenceStatusFromDevice (Device: any): string {
    if (this.hasIssues(Device) && !this.isDown(Device)) {
      return  UiConfig.RESOURCES.STATUS.ISSUES;
    }
    const result = UiConfig.RESOURCES.STATUS.TYPES.find(statusType => statusType.API.toUpperCase() === Device.status.toUpperCase());
    return result ? result.CONVERGENCE : '';
  }

  getResourceLiteralStatusFromApi (statusApi: string): string {
    const result = UiConfig.RESOURCES.STATUS.TYPES.find(statusType => statusType.API.toUpperCase() === statusApi.toUpperCase());
    return result ? result.LITERAL : '';
  }

  getResourceStatusTypeFromApi (statusApi: string): any {
    return UiConfig.RESOURCES.STATUS.TYPES.find(statusType => statusType.API.toUpperCase() === statusApi.toUpperCase());
  }

  getUserConvergenceStatusFromApi (statusApi: string): string {
    const result = UiConfig.USERS.STATUS.TYPES.find(statusType => statusType.API.toUpperCase() === statusApi.toUpperCase());
    return result ? result.CONVERGENCE : '';
  }

  getUserColorFromStatus (status: string): string {
    const result = UiConfig.USERS.STATUS.TYPES.find(statusType => statusType.CONVERGENCE.toUpperCase() === status.toUpperCase());
    return result ? result.HEX : '#C0C0C0';
  }

  getUserStatusTypeFromApi (statusApi: string): any {
    return UiConfig.USERS.STATUS.TYPES.find(statusType => statusType.API.toUpperCase() === statusApi.toUpperCase());
  }

  getElapsedStatusTime(device: any) {
    if (UiConfig.hasIssues(device) && !UiConfig.isDown(device)) {
      // find first (earliest) date of issues of type 'Not Connected'
      const listOfNotConnectedIssues = device.listProtocolRegistration.filter((item) => item.status === 'Not Connected');
      if (listOfNotConnectedIssues.length > 0) {
        let firstIssueDate = CwDateTool.utcToDateTimeZone(listOfNotConnectedIssues[0].lastChange);
        for (const issue of listOfNotConnectedIssues) {
          if (CwDateTool.utcToDateTimeZone(issue.lastChange) < firstIssueDate) {
            firstIssueDate = CwDateTool.utcToDateTimeZone(issue.lastChange);
          }
        }
        return TimeFormatHelper.getStatusElapsedTimeFormat(Date.now(), firstIssueDate);
      }

    } else if (UiConfig.isHealthy(device) && device.listProtocolRegistration) {
      // find more up to date (latest) date of issues and type 'Not Enabled' or 'Connected', including our latest status
      const listOfProtocols =
        device.listProtocolRegistration.filter((item) => item.status === 'Not Enabled' ||  item.status === 'Connected');
      if (listOfProtocols.length > 0) {
        let latestIssueDate = CwDateTool.utcToDateTimeZone(listOfProtocols[0].lastChange);
        for (const issue of listOfProtocols) {
          if (CwDateTool.utcToDateTimeZone(issue.lastChange) > latestIssueDate) {
            latestIssueDate = CwDateTool.utcToDateTimeZone(issue.lastChange);
          }
        }
        if (CwDateTool.utcToDateTimeZone(device.statusLastUpdate) > latestIssueDate) {
          latestIssueDate = CwDateTool.utcToDateTimeZone(device.statusLastUpdate);
        }
        return TimeFormatHelper.getStatusElapsedTimeFormat(Date.now(), latestIssueDate);
      }

    } else if (device.statusLastUpdate) {
      return TimeFormatHelper.getStatusElapsedTimeFormat(Date.now(), CwDateTool.utcToDateTimeZone(device.statusLastUpdate));
    }

    return '';
  }

  getLastUpdateTime(user: any) {
    if (CwIs.stringNotEmpty(user.statusLastUpdate) &&
      !user.resource
    ) {
      if (user.status === 'offline') {
        return CwDateTool.utcToDateTimeZone(user.statusLastUpdate);
      } else {
        return CwDateTool.utcToDateTimeZone(new Date().toISOString());
      }
    }
  }

}

export const UiConfig = new UiConfigClass();
