import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PlansComponent} from './plans.component';
import {FreeSingUpComponent} from '../data-sign-up/data-sign-up.component';
import {PlansBuyComponent} from './buy/plans-buy.component';
import {PlansViewComponent} from './view/plans-view.component';
import {PlansSalesPassiveComponent} from './sales/plans-sales-passive.component';
import {PlansBuyProComponent} from './buy/plans-buy-pro.component';
import {DataSignUpPassiveTrialComponent} from '../data-sign-up/passive/data-sign-up-passive-trial.component';

const routes: Routes = [
  {
    path: 'plans',
    children: [
      {
        path: '',
        component: PlansComponent
      },
      {
        path: 'basic',
        children: [
          {
            path: 'buy',
            component: PlansBuyComponent,
          },

        ]
      },
      {
        path: 'enterprise',
        component: PlansSalesPassiveComponent,
      },
      {
        path: 'free',
        component: DataSignUpPassiveTrialComponent,
        // component: DataSignUpPassiveComponent,
      },
      {
        path: 'free-old',
        component: FreeSingUpComponent,
      },
      {
        path: 'mobile',
        component: PlansViewComponent,
      },
      {
        path: 'pro',
        children: [
          {
            path: 'buy',
            component: PlansBuyProComponent,
          },
          {
            path: 'trial',
            component: DataSignUpPassiveTrialComponent,
          },
        ]
      },
      {
        path: ':rates',
        component: PlansSalesPassiveComponent
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlansRoutingModule {
}
