<div
     [dir]="App?.Literal?.getOrientation"
     [ngClass]="{'ui-rtl': App?.Literal?.getIsRtl}"
     [lang]="_TranslateService.currentLang"
>
  <ng-container *ngIf="!webinarView">
    <app-navigation></app-navigation>
  </ng-container>
  <ng-container *ngIf="webinarView">
    <app-webinar></app-webinar>
  </ng-container>
</div>
<div id="FeedbackDiv">
  <app-feedback></app-feedback>
  <app-co-feedback></app-co-feedback>
</div>
<app-cw-loader></app-cw-loader>
