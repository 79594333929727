import { Component, OnInit } from '@angular/core';
import {App} from '../../app';

@Component({
  selector: 'app-down',
  templateUrl: './down.component.html',
  styles: []
})
export class DownComponent  {

  App = App;

}
