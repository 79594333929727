import {Component} from '@angular/core';
import {CwLiteral} from '@cw/literal/literal';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-space-agree-passive',
  templateUrl: './cw-space-agree-passive.component.html',
  styleUrls: ['./cw-space-agree-passive.component.css']
})
/**
 * @version 2007170000
 */
export class CwSpaceAgreePassiveComponent {


  get getPrivacyPolicyHref(): string {
    // return '#/privacy-policy/' + CwLiteral.Language.language;
    return 'https://www.clearone.com/privacy-policy'
  }


  get getTermsHref(): string {
    return '#/terms/' + CwLiteral.Language.language;
  }


}
