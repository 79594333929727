import {CwBusClass} from '../../model/bus';
import {CwToolKeepC} from '../../tool/keep/tool-keep';

/**
 * @version 1908120928
 */
export abstract class VersionBusClass extends CwBusClass {
  Keep: CwToolKeepC<any>;
  path = 'version/';
  Setup: any;
}
