import {VersionBus} from '../version-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwToolKeepC} from '../../tool/keep/tool-keep';
import {CwVersionCheckD} from './check-define';
import {CwVersionCheckDoI, CwVersionCheckDoParametersI, CwVersionCheckPlatform} from './check-model';
import {CwWeb} from '../../tool/web/tool-web';

/**
 * @version 1904101050
 */
export class CheckBus extends VersionBus {
  DefineProperties: CwFunction[] = CwVersionCheckD.Properties;
  Keep: CwToolKeepC<any>;

  // 2001171318
  definePlatform = ((): number => {
    let out = 0;
    if (CwWeb.isTablet && CwWeb.isMac) {
      out = CwVersionCheckPlatform.ios;
    } else {
      out = (
        (
          CwVersionCheck.Parameters &&
          (
            CwVersionCheck.Parameters.platform ||
            0 === CwVersionCheck.Parameters.platform ||
            false

          ) &&
          true
        )
          ? CwVersionCheck.Parameters.platform
          : CwWeb.getPlatform()
      )
    }
    return out;
  });

  defineUrl: CwDefineUrl = (() => {
      return CwVersionCheck.path +
        'check' +
        '?platform=' +
        this.definePlatform() +
        '&entityId=' +
        (
          CwVersionCheck &&
          CwVersionCheck.Parameters &&
          CwVersionCheck.Parameters.entityId &&
          true
            ? CwVersionCheck.Parameters.entityId
            : '0'
        )
        ;
    }
  );

  // 1904251312
  do2: CwFunction = ((CheckParameters: CwVersionCheckDoI) =>
      CwVersionCheck.restGet(CheckParameters)
  );

  // 1904101050
  do: CwFunction = ((CheckParameters: CwVersionCheckDoI) =>
      CwVersionCheck.restGet(CheckParameters)
  );


  Parameters: CwVersionCheckDoParametersI;
  Setup: any;

}

/**
 * @version 1904101050
 */
export const CwVersionCheck = new CheckBus();
