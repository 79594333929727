import {InfoBus} from '../info.bus';
import {CwDefineUrl, CwFunction} from '@cw/model/model';
import {CwRestRequestParametersI} from '../../model';

export class CwInfoInstallationBusClass extends InfoBus {

  // 2008200000
  private _isAutoDownload;

  domain: string;
  webRtcAvailable = true;
  customerName: string;

  DefineProperties: CwFunction[] = [

    (Properties) => { // isAutoDownload
      if (Properties && Properties.autoDownload !== undefined) {
        this._isAutoDownload = Properties.autoDownload;
      }
    }, // isAutoDownload

    (Properties) => {
      if (
        Properties &&
        Properties.domain
      ) {
        this.domain = Properties.domain;
      }
    }, // domain

    (Properties) => {
      if (
        Properties &&
        Properties.webRTC === undefined
      ) {
        this.webRtcAvailable = true;
      } else if (
        Properties &&
        Properties.webRTC === false
      ) {
        this.webRtcAvailable = false;
      } else if (
        Properties &&
        Properties.webRTC === true
      ) {
        this.webRtcAvailable = true;
      }
    }, // webRtcAvailable

    (Properties) => { // customerName
      if (
        Properties &&
        Properties.customerName
      ) {
        this.customerName = Properties.customerName;
      }
    }, // customerName

  ];

  defineUrl: CwDefineUrl = (() =>
      this.path + 'installation'
  );

  do: CwFunction = (
    (Param: CwRestRequestParametersI) =>
      this.restGet(Param)
  );

  // ISs ///////////////////////////////////////////////////////////////////////
  get isAutoDownload(): boolean {
    return this._isAutoDownload;
  }

  // ISs ///////////////////////////////////////////////////////////////////////

}

export const CwInfoInstallationBus = new CwInfoInstallationBusClass();
