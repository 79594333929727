/**
 * @version 1.8.0
 */
import {CwFunction} from '../model/model';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CwSafe} from '../tool/tool-safe';
import {CwWeb} from '../tool/web/web.tool';
import {environment} from '../../../environments/environment';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

/**
 * @version 1911181024
 */
export const CwLiteralConfig = {
  Direction: {
    LTR: 'ltr',
    RTL: 'rtl',
    DEFAULT: 'ltr'
  },
  key: 'language',
  Language: {
    DEFAULT: {
      language: 'en',
      country: 'US',
      rtl: false,
      email: null,
      name: 'English'
    }
  },
  url: 'info/languages',
  keepKey: 'CS-Literal',
  Rtl: {
    urlCss: '/assets/bootstrap/bootstrap-rtl.min.css'
  }
};

export interface CwLiteralKeepModel {
  email: string;
  Language: CwLiteralLanguageI;
}

export const CwLiteralModuleProviders = [
  TranslateService
];


export function CwLiteralHttpLoaderFactory(http: any): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    CwSafe.get(environment.Literal.path),
    // '/../literal/assets/i18n/',
    CwWeb.notCache('.json')
  );
}

export const CwLiteralModuleImports = [
  HttpClientModule,
  TranslateModule.forRoot(
    {
      loader: {
        provide: TranslateLoader,
        useFactory: (CwLiteralHttpLoaderFactory),
        deps: [HttpClient]
      }
    }
  ),
];

export const CwLiteralModuleExports = [
  TranslateModule
];

// 1901181612 - 1904240944
export interface CwLiteralLanguageI {
  language?: string;
  country?: string;
  name?: string;
  rtl?: boolean;
  email?: string;
}


export interface CwLiteralSetupI {
  Service: any;
  LanguageSelectedToDoList: CwFunction[];
  LanguageAvailableList?: any;
}


export interface CwLiteralI {
  CwLitValue: string;
  CwLitParam?: any;
  CwLitTarget?: string;
}

export abstract class CwLiteralC implements CwLiteralI {
  CwLitParam: any;
  CwLitValue: string;
  CwLitTarget: string;
}

export type CwLiteralT = string | CwLiteralI | CwLiteralC;

// 2006150949
export enum CwLiteralLanguageModel {
  ENGLISH = 'en',
  FRANCOIS = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it',
  KOREAN = 'ko',
  POLISH = 'pl',
  PORTUGUESE = 'pt',
  RUSSIAN = 'ru',
  SPANISH = 'es',
  TURKISH = 'tr',
}

export const CW_LITERAL_MODEL = {
  LANGUAGE: CwLiteralLanguageModel,
};
