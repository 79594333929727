import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CwRadioSetupI} from '../../radio/radio-model';
import {CwComponent} from '../../component';
import {CwSubscriptionIntervalBus} from '../../bus/subscription/interval/bus';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-subscription-interval',
  templateUrl: './subscription-interval.component.html',
})
export class SubscriptionIntervalComponent
  extends CwComponent
  implements OnInit, OnChanges {

  @Input() default: any;
  _property = CwSubscriptionIntervalBus.ItemsRadio.id;

  Setup: CwRadioSetupI = {
    Bus: CwSubscriptionIntervalBus,
    Value: {},

  };

  changed = (Item) => {
    console.log('2002111109', 'Item', Item);
    this.change.emit(Item[this._property]);
  };

  defineValue = () => {
    console.log('2002111109', 'defineValue', this.default);

    this.Setup.Value[this._property] = this.default;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'default': {
            this.defineValue()
          }
        }
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.defineValue();
  }


}
