<p-dialog
  [visible]="display"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [minWidth]="0"
  [minHeight]="0"
  width="auto"
  height="auto"
  [closeOnEscape]="false"
  [closable]="false"
  [responsive]="false"
  [showHeader]="false"
  styleClass="bg-transparent border-0 shadow-none p-0"
>

  <div class="ui-rtl sh overflow-hidden" dir="rtl"
  >
    <i class="pi pi-spin pi-spinner cw-loader"
    ></i>
  </div>

</p-dialog>
