/**
 * @version 1904101050
 */
import {OnDestroy, OnInit} from '@angular/core';
import {App} from './app';
import {isBoolean} from 'util';
import {CwWeb} from './CollaborateSpaceWebTools/tool/web/tool-web';
import {CwOnP} from './CollaborateSpaceWebTools/tool/on-premise/tool-on-premise';
import {AppWebTool} from './app-web.tool';

export interface AppNavigationI {
  download: boolean;
  plan: boolean;
  planSubscriptions?: boolean;
  planCallRates?: boolean;
  home: boolean;
  host: boolean;
  join: boolean;
}


// 1904251312
export interface AppDownloadI {
  android: string;
  ios: string;
  mac: string;
  windows: string;
}


export interface AppFeedbackI {
  success?: string;
  error?: string;
  errorCode?: number;
  contactAdminVisible?: boolean;
  status?: number;
}

export interface AppPreloaderI {
  visible: boolean;
  textMain?: string;
  textSecondary?: string;
}

export interface AppBodyI {
  visible: boolean;
}

export interface AppUserI {
  name: string;
}

export interface AppVerifyI {
  email: string;
}

export interface AppHeadI {
  selectLanguageVisible: boolean;
}

// 2005280951
export enum APP_THEME {
  STANDARD,
}
