import {CwFunction} from '../../model/model';
import {CwUtil} from '../util/cw-tool-util';
import {CwIs} from '../tool-is';
import {FormControl} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import {CwInput, CwInputI} from '../../components/input/cw-input-model';
import {CwValI} from '@tool/validation/tool-validation.model';

class ToolValidationClass {

  VALUES = {
    LETTERS: [
      'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
      'j', 'k', 'l', 'm', 'n', 'ñ', 'o', 'p', 'q',
      'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
    ],
    LETTERS_UPPER_CASE: [
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
      'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q',
      'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ],
    NUMBERS: [
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ],
    SPECIAL_CHARACTERS: [
      ' ',
      '!',
      '"',
      '#',
      '$',
      '%',
      '&',
      '\'',
      '(',
      ')',
      '*',
      '+',
      ',',
      '-',
      '.',
      '/',
      ':',
      ';',
      '<',
      '=',
      '>',
      '?',
      '@',
      '[',
      '\\',
      ']',
      '^',
      '_',
      '`',
      '{',
      '|',
      '}',
      '~',
    ]
  };

  // 1908011133
  validate: CwFunction = ((Param: CwValI): boolean => {
    Param.Item.valid = Param.Item.valid && Param.valid;
    if (!Param.valid) {
      Param.Item.ValidationList.push(Param.messageInvalid);
    }
    return Param.valid;
  });


  confirm: CwFunction = ((Item: CwInputI): boolean => {
    return this.validate({
      Item,
      messageInvalid: (
        Item &&
        Item.Validate &&
        Item.Validate.ConfirmInput &&
        Item.Validate.ConfirmInput.Validate.email
      )
        ? 'Emails must match.'
        : (
          Item &&
          Item.Validate &&
          Item.Validate.ConfirmInput &&
          Item.Validate.ConfirmInput.Validate.password
        )
          ? 'Passwords must match.'
          : 'The values are not the same.'
      ,
      valid: (
        Item &&
        // Item.changed &&
        Item.value &&
        Item.Validate &&
        Item.Validate.ConfirmInput &&
        // Item.Validate.Confirm.changed &&
        Item.Validate.ConfirmInput.value &&
        Item.Validate.ConfirmInput.value === Item.value &&
        true
      ),
    })
  });

  // 1908011133
  notSpaceAtBeginningOrEnd: CwFunction = ((Item): boolean => {
    return this.validate({
      Item,
      messageInvalid: 'It must be no blank space at the beginning or end.',
      valid: (
        ' ' !== Item.value[0] &&
        ' ' !== Item.value[Item.value.length - 1] &&
        true
      ),
    });
  });

  // 1908011133
  numbersOnly: CwFunction = ((Item): boolean => {
    return this.validate(<CwValI>{
      Item,
      valid: CwUtil.contains({
        target: Item.value,
        PossibleValuesList: this.VALUES.NUMBERS,
        onlyPossibleValues: true
      }),
      messageInvalid: 'It must be just numbers.'
    });
  });

  // 1908011133
  numberSome: CwFunction = ((Item): boolean => {
    return this.validate({
      messageInvalid: 'The value must contain a number.',
      valid: CwUtil.contains({
        target: Item.value,
        PossibleValuesList: this.VALUES.NUMBERS,
        onlyPossibleValues: false
      }),
      Item,
    });
  });

  upperAndDigitsOnly: CwFunction = ((Item) => {
    return this.validate({
      Item,
      valid: CwUtil.contains({
        target: Item.value,
        PossibleValuesList: ['-']
          .concat(this.VALUES.LETTERS_UPPER_CASE)
          .concat(this.VALUES.NUMBERS)
        ,
        onlyPossibleValues: true,
      }),
      messageInvalid: 'It must be only letters in upper case, numbers and hyphen.'
    });
  });


  password: any = ((Item, messageInvalid): boolean => {
    return this.validate({
      Item,
      messageInvalid,
      valid: (
        CwUtil.contains({
          target: (Item.value) ? Item.value.toLowerCase() : Item.value,
          // target: Item.value.toLowerCase(),
          PossibleValuesList:
            this.VALUES.SPECIAL_CHARACTERS
              .concat(this.VALUES.LETTERS)
              .concat(this.VALUES.NUMBERS)
          ,
          onlyPossibleValues: true,
        }) &&
        // At least one Number
        CwUtil.contains({
          target: Item.value,
          PossibleValuesList: this.VALUES.NUMBERS,
          onlyPossibleValues: false,
        }) &&
        // At least one Letter
        CwUtil.contains({
          target: Item.value.toLowerCase(),
          PossibleValuesList: this.VALUES.LETTERS,
          onlyPossibleValues: false,
        }) &&
        // At least one Special Character
        CwUtil.contains({
          target: Item.value,
          PossibleValuesList: this.VALUES.SPECIAL_CHARACTERS,
          onlyPossibleValues: false,
        }) &&
        // there is no blank space at the beginning or end
        this.notSpaceAtBeginningOrEnd(Item) &&
        8 <= Item.value.length &&
        50 >= Item.value.length &&
        true
      ),
    })
      ;
  });

  lettersOnly: CwFunction = ((Item): boolean => {
    return this.validate(<CwValI>{
      Item,
      messageInvalid: 'It must be only letters.',
      valid: CwUtil.contains({
        onlyPossibleValues: true,
        PossibleValuesList: this.VALUES.LETTERS,
        target: <string>Item.value.toLowerCase(),
      })
    });
  });

  textOnly: CwFunction = ((Item): boolean => {
    return this.validate(<CwValI>{
      Item,
      messageInvalid: 'Value must contain valid text (letters, numbers or characters)',
      valid: CwUtil.contains({
        onlyPossibleValues: true,
        PossibleValuesList: this.VALUES.SPECIAL_CHARACTERS
          .concat(this.VALUES.LETTERS)
          .concat(this.VALUES.NUMBERS),
        target: <string>Item.value.toLowerCase(),
      })
    });
  });

  lettersUpperOnly: CwFunction = ((Item): boolean => {
    return this.validate(<CwValI>{
      Item,
      messageInvalid: 'It must be only letters.',
      valid: CwUtil.contains({
        onlyPossibleValues: true,
        PossibleValuesList: this.VALUES.LETTERS_UPPER_CASE,
        target: Item.value,
      })
    });
  });

  // 2007010000
  cannotBeEmpty: CwFunction = ((Item): boolean => {
    return this.validate(<CwValI>{
      Item,
      messageInvalid: 'Cannot be empty.',
      valid: (
        CwIs.defined(Item.value) &&
        (
          CwIs.stringNotEmpty(Item.value) ||
          CwIs.number(Item.value)
        ) &&
        true
      )
    });
  });

  required: CwFunction = ((Item): boolean => {
    return this.validate(<CwValI>{
      Item,
      messageInvalid: 'Required',
      valid: (
        CwIs.defined(Item.value) &&
        (
          CwIs.stringNotEmpty(Item.value) ||
          CwIs.number(Item.value)
        ) &&
        true
      )
    });
  });

  // 1908230842
  ip: CwFunction = ((Item): boolean => {
    Item.valid = false;
    try {
      const _ipPotential = Item.value.split('//')[1].split(':')[0];
      const _ipPattern = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
      Item.valid = _ipPattern.test(_ipPotential);
    } catch (e) {

    }

    if (!Item.valid && Item.ValidationList) {
      Item.ValidationList.push('The ip is not formatted correctly.');
    }
    return Item.valid;
  });

  // 1908191308
  branding: CwFunction = ((Item): boolean => {
    Item.valid = false;
    let value = (Item.prepend) ? Item.prepend : '';
    value += Item.value;
    value += (Item.append) ? Item.append : '';
    if (Item && CwIs.stringNotEmpty(Item.value)) {
      const Control = new FormControl();
      Control.setValue(value);
      Control.setValidators([CustomValidators.url]);
      Control.updateValueAndValidity();
      Item.valid = (Control.valid);
      if (!Item.valid && Item.ValidationList) {
        Item.ValidationList.push('The url is not formatted correctly.');
      }
    }
    return Item.valid;
  });

  urlLdap: CwFunction = ((Item): boolean => {
    Item.valid = false;
    try {
      const Control = new FormControl();
      Control.setValue(Item.value.replace('ldap://', 'http://'));
      Control.setValidators([CustomValidators.url]);
      Control.updateValueAndValidity();
      Item.valid = (Control.valid);
    } catch (e) {
    }

    if (!Item.valid && Item.ValidationList) {
      Item.ValidationList.push('The url is not formatted correctly.');
    }

    return Item.valid;
  });

  url: CwFunction = ((Item): boolean => {
    Item.valid = false;
    const Control = new FormControl();
    Control.setValue(Item.value);
    Control.setValidators([CustomValidators.url]);
    Control.updateValueAndValidity();
    Item.valid = (Control.valid);
    if (!Item.valid && Item.ValidationList) {
      Item.ValidationList.push('The url is not formatted correctly.');
    }
    return Item.valid;
  });

  email = ((Item): boolean => {
    Item.valid = CwIs.email({value: Item.value, ValidationList: Item.ValidationList});
    return Item.valid;
  });

  min = ((Item): boolean => {
    Item.valid = CwIs.min({
      min: Item.min,
      value: Item.value.toString(),
      ValidationList: Item.ValidationList,
    });
    return Item.valid;
  });

  macAddress: CwFunction = ((Item): boolean => {
    Item.valid = false;
    try {
      const _macAddressPotential = Item.value;
      const _macAddressPattern = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
      Item.valid = _macAddressPattern.test(_macAddressPotential) || !CwIs.defined(Item.value);
    } catch (e) {

      }
    if (!Item.valid && Item.ValidationList) {
      Item.ValidationList.push('The MAC Address is not formatted correctly.');
    }
    return Item.valid;
  });


  check: CwFunction = ((Param: CwInputI) => {
    if (
      Param &&
      true
    ) {
      Param.valid = true;
      if (Param.ValidationList) {
        CwUtil.clear(Param.ValidationList);
      } else {
        Param.ValidationList = [];
      }

      // 2002111109-5
      if (Param.min !== undefined) {
        this.min(Param);
      }

      if (Param.upperanddigitsonly) {
        this.upperAndDigitsOnly(Param);
      }

      if (!Param.valid && Param.Validate && Param.Validate.passive) {
        return Param.valid;
      }

      if (Param.digitsfirst) {
        this.numberSome(Param);
      }

      if (!Param.valid && Param.Validate && Param.Validate.passive) {
        return Param.valid;
      }

      if (Param.digitsonly) {
        this.numbersOnly(Param);
      }

      if (!Param.valid && Param.Validate && Param.Validate.passive) {
        return Param.valid;
      }

      if (Param.spontaniapassword) {
        this.password(Param);
      }

      if (!Param.valid && Param.Validate && Param.Validate.passive) {
        return Param.valid;
      }


      if (Param.upperandlowercase) {
        this.lettersOnly(Param);
      }

      if (!Param.valid && Param.Validate && Param.Validate.passive) {
        return Param.valid;
      }


      if (Param.uppercase) {
        this.lettersUpperOnly(Param);
      }

      if (!Param.valid && Param.Validate && Param.Validate.passive) {
        return Param.valid;
      }


      if (Param.validateEmail) {
        this.email(Param);
        // Param.valid = CwIs.email({value: Param.value, ValidationList: Param.ValidationList})
      }

      if (!Param.valid && Param.Validate && Param.Validate.passive) {
        return Param.valid;
      }


      if (
        (
          Param.Validate &&
          Param.Validate.required &&
          true
        ) ||
        Param.valdidateRequired ||
        false
      ) {
        this.required(Param);
      }

      if (!Param.valid && Param.Validate && Param.Validate.passive) {
        return Param.valid;
      }


      if (Param.Validate) {


        if (Param.Validate.branding) {
          this.branding(Param);
        }

        if (!Param.valid && Param.Validate && Param.Validate.passive) {
          return Param.valid;
        }

        if (Param.Validate.cannotBeEmpty) {
          this.cannotBeEmpty(Param);
        }

        if (!Param.valid && Param.Validate && Param.Validate.passive) {
          return Param.valid;
        }


        if (Param.Validate.email) {
          this.email(Param);
        }

        if (!Param.valid && Param.Validate && Param.Validate.passive) {
          return Param.valid;
        }


        if (Param.Validate.ldapServer) {
          const ItemTemp = CwUtil.clone(Param);
          if (this.ip(ItemTemp) || (this.urlLdap(ItemTemp))) {
            Param.valid = true;
          } else {
            Param.valid = false;
            Param.ValidationList.push('The Server is not formatted correctly (e.g. ldap://1.1.1.1:389 or ldap://server.domain:389).');
          }
        } // ldapServer

        if (!Param.valid && Param.Validate && Param.Validate.passive) {
          return Param.valid;
        }


        if (Param.Validate.password) {
          this.password(
            Param,
            'Password must contain at least one letter, one number or one ascii character. Minimum length: 8',
          );
        }


        if (!Param.valid && Param.Validate && Param.Validate.passive) {
          return Param.valid;
        }


        if (Param.Validate.passwordKetan) {
          this.password(
            Param,
            'Password must be 8-32 characters long and must contain at least capital letter, number and special character.'
          );
        }


        if (!Param.valid && Param.Validate && Param.Validate.passive) {
          return Param.valid;
        }


        if (Param.Validate.ConfirmInput) {
          this.confirm(Param);
        }

        if (!Param.valid && Param.Validate && Param.Validate.passive) {
          return Param.valid;
        }

        if (Param.Validate.macAddress) {
          this.macAddress(Param);
        }


      }

      return Param.valid;
    }
  });

  validateInputsSet: CwFunction = ((InputsSet: CwInput[]): boolean => {
    let validate = false;
    if (InputsSet) {
      for (const InputItem of InputsSet) {
        if (InputItem && InputItem.Validate && (InputItem.Validate.required || InputItem.Validate.cannotBeEmpty)) {
          validate = InputItem.valid && InputItem.changed;
        } else {
          validate = InputItem.valid;
        }
        if (!validate) {
          break;
        }
      }
    }
    return validate;
  });

  validateInput: CwFunction = ((InputItem: CwInput): boolean => {
    let validate = false;
        if (InputItem && InputItem.Validate && (InputItem.Validate.required || InputItem.Validate.cannotBeEmpty)) {
          validate = InputItem.value && InputItem.valid && InputItem.changed;
        } else {
          validate = InputItem.valid;
        }
    return validate;
  });

}

// 1908011133
export const CwVal = new ToolValidationClass();

export interface CwValI {
  Item: any;
  valid: boolean;
  messageInvalid: string;
}

export interface CwValidateI {
  branding?: boolean;
  email?: boolean;
  ldapServer?: boolean;
  password?: boolean;
  required?: boolean;
}
