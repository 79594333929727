
enum CwErrorToolTypeEnum {
  _INIT,
  ERROR,
  WARNING,
}

enum CwErrorToolCodeEnum {
  _API_ERRORS________________________________ ,
  AUTH_INVALID_CLIENT = -1500,
  AUTH_ERROR_URI = -1503,
  AUTH_ACCESS_DENIED = -1504,

  SIGN_IN_USER_NOT_EXISTS = -31,
  SIGN_IN_PROBLEMS_AUTHENTICATING = -9002,

  TOKEN_INVALID = -48,
  ENTITY_INACTIVED = -980,

  _WEB_AUTH_ERRORS___________________________ ,
  INSUFFICIENT_PARAMETERS= -10001,
  _WARNINGS__________________________________ = 100000,
  CONTACT_DEALER ,
}

export type CwErrorToolTypeModel = CwErrorToolTypeEnum;


export interface CwErrorToolSetModel {
  code: number;
  message: string;
  literal: string;
  type: CwErrorToolTypeModel;
  signInShow?: boolean;
}

/**
 * @version 1911121011
 */
export const CW_ERROR_TOOL = {
  CODE: CwErrorToolCodeEnum
  ,
  CLASS: new Map<CwErrorToolTypeModel, string>()
    .set(CwErrorToolTypeEnum.ERROR, 'alert-danger')
    .set(CwErrorToolTypeEnum.WARNING, 'alert-warning')
  ,
  LITERAL: new Map<CwErrorToolTypeModel, string>()
    .set(CwErrorToolTypeEnum.ERROR, 'Error')
    .set(CwErrorToolTypeEnum.WARNING, 'Warning')
  ,
  SIGN_IN_SHOW_DEFAULT: true,
  TITLE: new Map<CwErrorToolTypeModel, string>()
    .set(CwErrorToolTypeEnum.ERROR, 'Error')
    .set(CwErrorToolTypeEnum.WARNING, 'Warning')
  ,
  TYPE: CwErrorToolTypeEnum
  ,
  TYPE_DEFAULT: CwErrorToolTypeEnum.ERROR
  ,
  GOTO: new Map<CwErrorToolTypeModel, string>()
    .set(CwErrorToolTypeEnum.ERROR, 'error')
    .set(CwErrorToolTypeEnum.WARNING, 'error')
  ,


};
