import {CwFunction} from '../../model/model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwValidateI} from '@tool/validation/tool-validation.model';

export class CwInput implements CwInputI {

  _reset = (() => {
    this.valid = false;
    this.changed = false;
    this.value = undefined;
    this.valueOriginal = undefined;
    CwUtil.clear(this.ValidationList);
  });

  api: string;
  CardToken: any;
  changed = false;
  class: string;
  enabled = true;
  enterEnabled = false;

  id: string = CwUtil.uuid();

  focus = false;

  get payload(): boolean {
    return !this.notPayload;
  }

  get name(): string {
    return this.label;
  }

  label: string;
  labelRequired: boolean;
  notPayload = false;
  reviewable: boolean;
  type: CwInputType = CwInputType.TEXT;

  placeholder: string;

  valid = true;
  value: any;
  valueOriginal: any;
  ValidationList: any[] = [];
  Validate: CwValidateI = {};

  set name(value: string) {
    this.label = value;
  }

  set(value: any) {
    this.valueOriginal = value;
    this.value = value;
  }


  constructor(Params: {
    api: string,
    class?: string,
    editable?: boolean,
    enabled?: boolean,
    enterEnabled?: boolean,
    focus?: boolean,
    label?: string,
    labelRequired?: boolean;
    notPayload?: boolean,
    notLabel?: boolean,
    onChange?: CwFunction,
    reviewable?: boolean,
    type?: CwInputType,
    value?: any,
    Validate?: CwValidateI,
    ValidationRelationList?: CwFunction[],
  }) {
    if (Params) {
      Object.getOwnPropertyNames(Params).forEach(
        property => {
          this[property] = Params[property];
        }
      );
      this.valueOriginal = this.value;
    }

  }
}

export enum CwInputType {
  BOOL = 'Bool',
  CARD = 'Card',
  CARD_CVC = 'cardCvc',
  CARD_EXPIRY = 'cardExpiry',
  CARD_NUMBER = 'cardNumber',
  CARD_NUMBER_VIEW = 'cardNumberView',
  CHECK = 'Check',
  COMMENTS = 'Comments',
  DATE = 'Date',
  DOMAIN = 'Domain',
  FREE = 'Free',
  LIST = 'Generic',
  LIST_BY_KEY = 'Combo',
  NUMBER = 'Number',
  NUMBER_POSITIVE = 'NumberPositive',
  PASSWORD = 'Password',
  PASSWORD_MINIMAL = 'PasswordMin',
  RANGE = 'Slider',
  READ_ONLY = 'ReadOnly',
  SAVE = 'Save',
  TEXT = 'Text',
  TIME = 'Time',
  VALIDATION = 'Validation',
}

export interface CwInputDomainI extends CwInputI {
  prepend: string;
  append: string;
}

export interface CwInputI {
  enabled: boolean;
  id: string;
  name: string;
  type: CwInputType;
  valid: boolean;
  value: any;

  api?: string;
  CardToken?: any;
  changed?: boolean;
  class?: string;
  digitsfirst?: any; // Collaborate Live
  digitsonly?: any; // Collaborate Live
  editable?: boolean; // 2004150000-24
  Element?: any; // 2005270000-1
  FeedbackList?: any[];
  focus?: boolean; // 2006091221
  InputSet?: any[];
  labelRequired?: boolean;
  // 2002111109-5
  maxlength?: number;
  maxLength?: number;
  maycauserestart?: boolean;
  // 2002111109-5
  min?: number;
  minlength?: number;
  minLength?: number;
  notLabel?: boolean;
  payload?: boolean;
  placeholder?: string;
  onChange?: CwFunction;
  reset?: CwFunction;
  reviewable?: boolean; // revisable
  spontaniapassword?: any; // Collaborate Live
  uppercase?: any; // Collaborate Live
  upperanddigitsonly?: any; // Collaborate Live
  upperandlowercase?: any; // Collaborate Live
  Validate?: CwValidateI;
  validateEmail?: any; // Collaborate Live
  valdidateRequired?: any; // Collaborate Live
  ValidationList?: any[];
  ValidationRelationList?: CwFunction[];
  valueOriginal?: any;
  visible?: boolean;

  _reset?: CwFunction;
}

export interface CwInputDomainI extends CwInputI {
  prepend: string;
  append: string;
}


/**
 * @version 1912131221
 */
export const CW_INPUT_COMPONENT = {
  LOGIN: [
    new CwInput({
      api: 'email',
      label: 'Email Address',
      Validate: {
        required: true,
        email: true,
      },
      // 1912101046
      focus: true,
    }),
    new CwInput({
      api: 'password',
      label: 'Password',
      type: CwInputType.PASSWORD,
      Validate: {
        required: true,
        // password: true,
      },
      enterEnabled: true,
    })
  ]
};
