import {Component, Input} from '@angular/core';
import {CwComponent} from '../component';

@Component({
  selector: 'app-cw-form',
  templateUrl: './form.component.html',
  styles: []
})
/**
 * @version 1906101652
 */
export class FormComponent extends CwComponent {
  @Input() ValidationList: string[] = [];

}
