import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

/**
 * @version 181812031052 - Focus
 */
export const CS_DECLARATIONS = [
  // CsFocusDirective,
];

@NgModule({
  imports: [
    CommonModule,
    // DialogModule
  ],
  declarations: [
    ...CS_DECLARATIONS,
    // PhoneCreditWidgetComponent,
  ],
  exports: [
    ...CS_DECLARATIONS,
    // PhoneCreditWidgetComponent,
  ],

})
export class CsModule {

}
