<ng-container *ngIf="Visible.webRtcAvailable">

  <div
    class="text-center py-5"
  >
    <div class="h-auto my-5 py-5">
      <div
        class="font-additional text-center h4"
      >{{'Join using Web Browser' | translate}}
      </div>
      <div>
        <button
          appCw
          appCwForm
          (click)="Actions.join()"
          type="button"
          class="mx-3"
        >{{'Join' | translate}}
        </button>
      </div>
    </div>

  </div>

  <app-connection-click-here-or-download></app-connection-click-here-or-download>

</ng-container>

<ng-container *ngIf="Visible.webRtcUnavailable">
  <div class="text-center py-5">
    <div class="h-auto my-5 py-5">
      <app-connection-click-here-or-download></app-connection-click-here-or-download>
    </div>
  </div>
</ng-container>
