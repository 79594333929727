export class CoToolsClass {

  // uuid(): string {
  //     return _.uniqueId();
  // }

  uuid(): string {
    let d = new Date().getTime();
    const uuid =
      'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
          const r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    return uuid;
  }

  // uuid(_prefix: string = ''): string {
  //   let _uuid = '';
  //
  //   try {
  //     _uuid = UUID.UUID();
  //   } catch (error) {
  //
  //   }
  //
  //   return _prefix + _uuid;
  // }

}

export const CoTools = new CoToolsClass();
