import {CwRestRequestMinParametersI, CwRestSetup} from '../tool/rest/model';
import {CwLiteralLanguageI} from '../literal/model';
import {CwBusClass} from './bus';
import {FeedBusC} from '../feed/feed-bus';
import {CwToolWebClass} from '../tool/web/web.tool';
import {CwInput, CwInputI} from '../components/input/cw-input-model';
import {ModalComponent} from '../components/modal/modal.component';
import {SocketManagementClass} from '../socket/socket-management';

// 1907041644
export interface EnvironmentPageI {
  title?: string;
  icon?: string;
}

/**
 * @version 1910141546
 */
export interface CwBusLoadI {
  state: CwBusStateType;
  ActionsToDoList: CwRestRequestMinParametersI[];
  ParamsLast: CwRestRequestMinParametersI;
  forced: any;
  do: any;
}

export interface CwSetup {
  fieldId?: string;
}

// 1910081139
// export enum CwBusStateType {
//   __INIT,
//   error,
//   new,
//   loading,
//   loaded,
//   empty,
//   disabled,
//   __END
// }


export interface CwRestGoToI {
  callbackErrorGoTo: string;
  callbackSuccessGoTo: string;
}

export interface CwTokenBusI {
  token: string;
}

export type CwConditionModel = ((Item: any) => boolean);

export type CwDefineUrl = (() => string);

export type CwFunction = (
  ((param?: any) => void | any) |
  ((...args: any[]) => void) |
  null
  );


export type CwCompareFn = ((a: any, b: any) => number);


export type CwActionFunctionT = (
  (Data: any, That: CwBusClass) => void
  );

// 1904240944
export interface CwMapItemI {
  cwKey: any;
}

export type CwMap<T> = Map<string, T>;


export interface CwSetupI {
  fieldId?: string;
  ErrorActionList?: CwActionFunctionT[];
  Feed?: CwSetupFeedI;
  SuccessActionList?: CwActionFunctionT[];
  SystemActionList?: CwActionFunctionT[];
  TokenBus?: CwBusClass | any;
  Grid?: any;
  // 1904101050
  DefineProperties?: CwFunction[];
  // 1904101050
  parameterRuleValidation?: CwFunction;
}

export interface CwSetup {
  fieldId?: string;
}

export interface CwSetupFeedI {
  success?: string;
  error?: string;
  // 1904101050
  paramError?: string;
  Bus?: FeedBusC;
}

// 1901081349
export interface CwRestGoToSetup extends CwRestSetup {
  url: string;
  callbackErrorGoTo: string;
  callbackSuccessGoTo: string;
}

// 1906180824
export interface EnvironmentModuleI {
  ACTIONS: EnvironmentActionsI;
  // path: string;
  // outlet: string;
  errorPath: string;
}

export interface CwEnvironmentI {

  PAGE_KEY?: any;
  ACTIONS?: any;
  token?: string;
  //// 1907110924
  // // 1907041644
  // Page: EnvironmentPageI;
  // // 1907050739
  // AngelsLanding: AngelsLandingI;
  // // 1906180824
  // isIframe: boolean;
  // // 1906180824
  // Iframe: EnvironmentModuleI;
  //
  production: boolean;
  // onPremise?: boolean;
  //
  App: {
    name: string,
    version: string,
    configuration: string,
    keepKey: string,
    unsafe?: boolean,
    StylesList?: string[],
    adminPath?: string;
  };

  Feed: {
    hiddenTime: number,
    intervalTime?: number,
    keepKey: string,
  };

  Literal: {
    flagPath?: string,
    keepKey: string,
    LanguageDefault: CwLiteralLanguageI,
    path: string,
  };

  // 1907110924
  Login?: {
    keepLoggedKeepKey: string,
    logOutPath: string;
    tokenKeepKey: string,
    tokenWrongPath: string,
  };

  Rest: {
    Code: any,
    path: string,
  };

  // 1907110924
  Session?: {
    keepKey: string
  };

  // // // 1907110924
  Stripe?: {
    key: string,
    class: string,
  };

}


export const CwSuccessAction = {
  GoTo: (
    (Data, That) => {

    }
  ),
  ToDo: (
    (Data, That) => {

    }
  )

};


export interface CwBusCallbackI {
  accept?: CwFunction;
  cancel?: CwFunction;
}

export interface CwBusParametersI {
  optional?: any;
}

export interface CwAppI {
  // Literal: CwToolLiteralClass;
  WebTool: CwToolWebClass;
}

export interface CwLanguageI {
  change: CwFunction;
  Language: CwLiteralLanguageI;
  ItemsList: any[];
}

// 1906180824 - 1907041644 - 1908190840 - 1908161444
export interface EnvironmentActionI extends EnvironmentPageI {
  path?: string;
  state?: boolean;
  iframe?: boolean;
  name?: string;
  viewOnly?: boolean;
  userOptions?: boolean;
  Translate?: CwTranslateI;
  routerLink?: any;
  routerIframeLink?: any,
}


// 1906180824
export interface EnvironmentActionsI {
  [key: string]: EnvironmentActionI;
}

// 1906180824
export interface EnvironmentActionApiI {
  name: string;
  state: boolean;
}

// 1906180824
export type EnvironmentActionsApiT = EnvironmentActionApiI[];

// 1907050739
export interface AngelsLandingI {
  themeEnable: boolean;
}

/**
 * @version 1908280930
 */
export interface CwBusInputsI {
  actionDisabled: CwFunction;
  actionEnabled: CwFunction;
  changed: CwFunction;
  defined: CwFunction;
  get: CwFunction;
  getValue: CwFunction;
  List: CwInputI[];
  MapByApi: CwMap<CwInputI>;
  ModalComponent: ModalComponent;
  modalComponentEnabled: CwFunction;
  PayLoad: CwFunction;
  reset: CwFunction;
  clear?: CwFunction;
  clean?: CwFunction;
  set: any;
  setValues: CwFunction;
  valid: CwFunction;
}

// 1907041644
export interface EnvironmentPageI {
  title?: string;
  icon?: string;
}


// 1906180824 - 1907041644 - 1908190840 - 1908161444
export interface EnvironmentActionI extends EnvironmentPageI {
  path?: string;
  state?: boolean;
  iframe?: boolean;
  name?: string;
  viewOnly?: boolean;
  userOptions?: boolean;
  Translate?: CwTranslateI;
  routerLink?: any;
  routerIframeLink?: any,
}

/**
 * @version 1909050837
 */
export interface CwTranslateI {
  literal: string;
  Params?: any;
}

// // 1906180824
// export interface EnvironmentActionsI {
//   [key: string]: EnvironmentActionI;
// }

// 1910071306
export interface CwBusAppI {
  inBackground: boolean;
  loaded: boolean;
  loading: boolean;
}


// 1910081139
export enum CwBusStateType {
  __INIT,
  error,
  new,
  loading,
  loaded,
  empty,
  disabled,
  __END
}

/**
 * @version 1910141546
 */
export interface CwBusLoadI {
  state: CwBusStateType;
  ActionsToDoList: CwRestRequestMinParametersI[];
  ParamsLast: CwRestRequestMinParametersI;
  forced: any;
  do: any;
}


export interface CwBusModel {
  Socket?: SocketManagementClass;
  repeatTimeInSeconds?: number;
  InputsSet?: CwInput[];
  resource?: boolean;
}
