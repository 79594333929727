import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioComponent } from './radio.component';
import {TranslateModule} from '@ngx-translate/core';
import {RadioBooleanComponent} from './radio-boolean';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    RadioComponent,
    RadioBooleanComponent,
  ],
  exports: [
    RadioComponent,
    RadioBooleanComponent,
  ]
})
export class CwRadioModule { }
