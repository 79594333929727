import {NgbActiveModal, NgbModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CwModalModule} from './cw-modal.module';
import {ModalService} from '../grid/grid-services/modal.service';
import {CwBusInputsI, CwFunction} from '../../model/model';
import {GridComponent} from '../grid/grid.component';
import {OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from './modal.component';
import {SetupGrid} from '../grid/grid';

export interface CwModalSetupI {
  // NgbActiveModal: NgbActiveModal;
  NgbModal: NgbModal;
}


export const CW_MODAL_APP_MODULE_IMPORTS = [
  NgbModule,
  CwModalModule,

];
export const CW_MODAL_APP_MODULE_PROVIDERS = [
  ModalService,
  NgbActiveModal,
];

export interface CwModalOpenExtraParams {
  actionName?: string;
  actionText?: string;
  isAllowedOnlyForRegistered?: boolean;
  isResource?: boolean;
  totalAvailable?: any;
  warningWhenMoreThanOneSelected?: string;
}

export interface CwModalOpenI {
  ModalComponent: any;


  Data?: any;
  GridComponent?: GridComponent;
  ExtraParams?: CwModalOpenExtraParams|any;
  Event?: any;
  closeAction?: CwFunction;
  callback?: CwFunction;
  large?: boolean;
  xlarge?: boolean;

  // 1908191308
  messageSuccess?: string;
  messageError?: string;
  title?: string;

  // 1912131221
  SetupGrid?: SetupGrid;
}

export interface CwLiteralI {
  text: string;
  Parameters: any;
}

export interface CwModalMessageI {
  globalMessage?: string;
  message?: string;
  MessageList?: string[];
  Literal?: CwLiteralI;
  LiteralList?: CwLiteralI[];
}


/**
 * @version 1908271309
 */
export abstract class CwModalComponent
  implements OnDestroy, OnInit {
  @ViewChild('ModalComponent') public ModalComponent: ModalComponent;

  abstract Inputs: CwBusInputsI;

  // 2008131227
  public showCount = 0;


  done = ((Data?) => {
    this.ModalComponent.isLoading = false;
    this.ModalComponent.close();
  });
  DoneAction = {
    errorActionCustom: this.done,
    errorRestActionCustom: this.done,
    successActionCustom: this.done,
  };

  error = ((Data) => {
    this.ModalComponent.isLoading = false;
    this.ModalComponent.Error.message = (Data && Data.error)
      ? Data.error
      : Data
    ;
  });
  ErrorAction = {
    errorActionCustom: this.error,
    errorRestActionCustom: this.error,
  };

  /**
   * @deprecated 1908271309
   */
  InputsList: any[] = [];

  /**
   * @deprecated 1908271309
   */
  get getInputsListValidated(): boolean {
    return (
      this.InputsList.length > 0 &&
      !(this.InputsList.filter(Item => !Item.valid).length > 0) &&
      !this.ModalComponent.isLoading &&
      true
    );
  }

  /**
   * @deprecated 1908271309
   */
  get getInputsListChanged(): boolean {
    return (
      this.InputsList.length > 0 &&
      this.InputsList.filter(Item => Item.changed).length > 0 &&
      true
    );
  }

  success: CwFunction = ((Data) => {
    this.ModalComponent.isLoading = false;
    if (this.ModalComponent.callback) {
      this.ModalComponent.callback();
    }
    this.ModalComponent.close();
  });


  ActionCustom = {
    successActionCustom: this.success,
    errorActionCustom: this.error,
    errorRestActionCustom: this.error,
  };

  close(): void {
    this.ModalComponent.close();
  }


  ngOnDestroy(): void {
    if (
      this.Inputs &&
      this.Inputs.reset &&
      true
    ) {
      this.Inputs.reset();
    }
  }

  ngOnInit() {
    if (this.Inputs && this.ModalComponent) {
      this.Inputs.ModalComponent = this.ModalComponent;
    }
  }

}
