import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CwRadioSetupI} from './radio-model';
import {CwUtil} from '@tool/util/cw-tool-util';

@Component({
  selector: 'cw-radio',
  templateUrl: './radio.component.html',
  styles: []
})
export class RadioComponent {

  id = CwUtil.uuid();
  someAlreadySelected = false;

  @Input() Setup: CwRadioSetupI | any;
  @Output() changed = new EventEmitter();

  select(Item: any): void {
    if (this.Setup) {
      if (
        Item &&
        !Item.disabled &&
        Item !== this.Setup.Value &&
        true
      ) {
        this.changed.emit(Item);
        this.Setup.Value = Item;
      }
    }
  }

  isChecked(Param: { Item: any, index: number }) {
    if (
      Param.Item.disabled &&
      // this.Setup.Value === undefined &&
      true
    ) {
      return false;
    } else if (!this.someAlreadySelected) {
      this.someAlreadySelected = true;
      this.Setup.Value = Param.Item;
    }
    return (
      Param.Item[this.Setup.Bus.ItemsRadio.id] === this.Setup.Value[this.Setup.Bus.ItemsRadio.id]
    );
  }
}
