import {COLLABORATE_PROTOCOL, CW_PROTOCOL_TOOL, CwDownloadI, CwProtocolCheck6I, CwProtocolCheckI, PROTOCOL_ACTION} from './model';
import {CwVersionCheckDoI, CwVersionCheckPlatform} from '../../version/check/check-model';
import {CwVersionCheck} from '../../version/check/check-bus';
import {CwWeb} from '../web/tool-web';
import {CwFix} from '../tool-fix';
import {CwInstaller} from '../installer/installer';
// import * as customProtocolCheck from 'custom-protocol-check';
import * as customProtocolCheck from '../../../../assets/js/custom-protocol-check/custom-protocol-check';
import {CwUtil} from '../util/cw-tool-util';
import {CwWebKeepI} from '../web/web.model';
import {CwProtocolToolModel} from './tool-protocol-model';
import {CW_PROTOCOL_FREE} from './tool-protocol-source';
import {CwInfoInstallationBus} from '@rest/info/installation/cw-info-installation-bus.class';

/**
 * @version 2003030920
 */
export const CW_PROTOCOL_MAP = new Map()
  // .set(CW_PROTOCOL_TOOL.SOURCE.DEFAULT, CW_PROTOCOL_DEFAULT)
  .set(CW_PROTOCOL_TOOL.SOURCE.FREE, CW_PROTOCOL_FREE)
  .set(CW_PROTOCOL_TOOL.SOURCE.INVITE, CW_PROTOCOL_FREE)
;


/**
 * @version 2003030920
 */
export const CwProtocolTool: CwProtocolToolModel = {
  source: CW_PROTOCOL_TOOL.SOURCE.DEFAULT,
  action: CW_PROTOCOL_TOOL.ACTION.DEFAULT,

  do: (): boolean => {
    let _do = false;
    if (!CW_PROTOCOL_MAP.has(CwProtocolTool.source)) {
      CwProtocolTool.source = CW_PROTOCOL_TOOL.SOURCE.DEFAULT;
    }
    if (CW_PROTOCOL_MAP.has(CwProtocolTool.source)) {
      // device?
      let _device = CwWeb.Setup.DeviceDetectorService.os.toLowerCase();
      if (!CW_PROTOCOL_MAP.get(CwProtocolTool.source).has(_device)) {
        _device = CW_PROTOCOL_TOOL.DEVICE.DEFAULT
      }
      if (CW_PROTOCOL_MAP.get(CwProtocolTool.source).has(_device)) {
        // browser
        let _browser = CwWeb.Setup.DeviceDetectorService.browser.toLowerCase();
        if (!CW_PROTOCOL_MAP.get(CwProtocolTool.source).get(_device).has(_browser)) {
          _browser = CW_PROTOCOL_TOOL.BROWSER.DEFAULT;
        }
        if (CW_PROTOCOL_MAP.get(CwProtocolTool.source).get(_device).has(_browser)) {
          // action
          if (
            !CW_PROTOCOL_MAP.get(CwProtocolTool.source)
              .get(_device)
              .get(_browser)
              .has(CwProtocolTool.action)
          ) {
            CwProtocolTool.action = CW_PROTOCOL_TOOL.ACTION.DEFAULT
          }
          if (
            CW_PROTOCOL_MAP.get(CwProtocolTool.source)
              .get(_device)
              .get(_browser)
              .has(CwProtocolTool.action)
          ) {
            _do = true;
            CW_PROTOCOL_MAP.get(CwProtocolTool.source)
              .get(_device)
              .get(_browser)
              .get(CwProtocolTool.action)();
          }
        }

      }
    }
    return _do;
  },

  isSourceInvite: ((): boolean => {
    return CwProtocolTool.source === CW_PROTOCOL_TOOL.SOURCE.INVITE
  })
};


/**
 * @version 2002210957
 * @description COLLABORATE© Space did not install and You try Join a Meeting, "Select how to connect" page show.
 * If you select "Web App", "Personal ID" is sent automatically - 2002121212
 */
export class ToolProtocol {
  // 2008200000
  get isAutoDownload(): boolean {
    return (CwInfoInstallationBus)
      ? CwInfoInstallationBus.isAutoDownload
      : this._isAutoDownload;
    ;
  }

  // 2008200000
  set isAutoDownload(value: boolean) {
    this._isAutoDownload = value;
  }

  App = {
    _downloadUri: null,
    download: () => {
      this.App._downloadUri = this.getDownload(CwWeb.OS.api);
      if (this.App._downloadUri) {
        this.Browser.Firefox.install();
        window.location.href = this.App._downloadUri;
      } else {
        setTimeout(this.App.download, 2000);
      }
    },
    exec: () => {
      this.setupDownload();
      this.Browser.Firefox.exec();
      this.Browser.Mobile.Chrome.exec();
    },
    // 2010200942
    start: () => {
      this.setupDownload();
      document.location.href = CwProtocol.uri;
    }
  };

  // 2001200856
  exec = false;

  // 2001200856
  Browser = {
    Firefox: {
      collaborateSpaceisInstalled: false,
      _Keep: <CwWebKeepI<boolean>>{
        key: CW_PROTOCOL_TOOL.FIREFOX.KEEP,
      },
      get: () => {
        CwWeb.keepGet(this.Browser.Firefox._Keep);
        this.Browser.Firefox.collaborateSpaceisInstalled = this.Browser.Firefox._Keep.Data;
      },
      install: () => {
        if (this.Browser.Firefox.selected()) {
          this.Browser.Firefox._Keep.Data = true;
          CwWeb.keepSet(this.Browser.Firefox._Keep);
        }
      },
      check: (Params): boolean => {
        if (this.Browser.Firefox.selected()) {
          CwProtocol.exec = false;
          if (this.Browser.Firefox.collaborateSpaceisInstalled) {
            CwProtocol.exec = true;
            this.found();
          } else {
            this.notFound();
          }
        }
        return CwWeb.Browser.Firefox.is;
      },
      exec: () => {
        if (this.Browser.Firefox.selected() && this.exec) {
          document.location.href = CwProtocol.uri;
        }
      },
      selected: (): boolean => {
        this.Browser.Firefox.get();
        return (
          CwWeb.Browser.Firefox.is &&
          CwWeb.DEVICE.Desktop.is &&
          true
        )
      }

    },
    // 2002240902
    InternetExplorer: {
      check: () => {
        if (CwWeb.Browser.InternetExplorer.is) {
          const Param: CwProtocolCheckI = {
            notFound: CwProtocol.App.download,
            found: function () {
              if (CwProtocol.token) {
                const _uri = (CwProtocol.SelectConnection.available)
                  ? '/download/select'
                  : '/download/app' + CwWeb.Route.is
                ;
                CwWeb.goTo({path: _uri});
              } else {
                CwProtocol._isAutoDownload = false;
              }

            },
            token: CwProtocol.token,
          };
          CwProtocol.check(Param);
        }
      }
    },
    Mobile: {
      Chrome: {
        selected: (): boolean => (
          (
            CwWeb.DEVICE.Mobile.is ||
            CwWeb.DEVICE.Tablet.is ||
            false
          ) &&
          CwWeb.Browser.Chrome.is &&
          true
        ),
        check: (): boolean => {
          let _checked = false;
          if (this.Browser.Mobile.Chrome.selected()) {
            _checked = true;
            CwProtocol._isAutoDownload = false;
            CwProtocol.exec = true;
            CwWeb.goTo({path: '/download/app' + CwWeb.Route.is});
          }
          return _checked;
        },
        exec: (): boolean => {
          let _execed = false;
          if (this.Browser.Mobile.Chrome.selected()) {
            _execed = true;

            const Param: CwProtocolCheckI = {
              notFound: CwProtocol.App.download,
              found: function () {
                document.location.href = CwProtocol.uri;
              },
              token: CwProtocol.token,
            };
            CwProtocol.check(Param);


          }
          return _execed;
        }
      },
    }
  };

  Status = {
    sessionId: CwUtil.uuid(),
    _Keep: <CwWebKeepI<string>>{
      key: CW_PROTOCOL_TOOL.STATUS.KEEP
    },
    get: () => {
      CwWeb.keepGet(this.Status._Keep);
    },
    open: () => {
      this.Status.get();
      if (this.Status._Keep.Data === undefined) {
        this.Status._Keep.Data = this.Status.sessionId;
        CwWeb.keepSet(this.Status._Keep);
      }
    },
    close: () => {
      CwWeb.keepRemove(this.Status._Keep);
    },
    isStillOpen: (): boolean => {
      let _isStillOpen = false;
      if (CwWeb.Browser.Chrome.is) {
        const memory = (
            performance &&
            performance['memory'] &&
            performance['memory'].jsHeapSizeLimit &&
            true
          ) ? performance['memory'].jsHeapSizeLimit
          : 0
        ;
        if (memory > CW_PROTOCOL_TOOL.STATUS.MEMORY_MIN) {
          this.Status.get();
          if (
            this.Status._Keep.Data &&
            this.Status._Keep.Data !== this.Status.sessionId &&
            true
          ) {
            _isStillOpen = true;
          }
        }
      }
      return _isStillOpen;
    }
  };


  token: string;
// Equal to protocol - 22001201051
  uri: string;
  private _isAutoDownload = true;


// 2001101133
  SelectConnection = {
    available: false,
    title: '',
    flag: true,
    init: (() => {
      // RtcWeb: Only Desktop and not Internet Explorer 2001171244
      this.SelectConnection.available = (CwWeb.isDesktop && !CwWeb.isInternetExplorer);
      this.SelectConnection.flag = this.SelectConnection.available;
    }),
    show: (() => {
      if (this.SelectConnection.available) {
        this.SelectConnection.flag = true;
      } else {
        this.SelectConnection.app();
      }
    }),
    app: (() => {
      this.SelectConnection.flag = false;
    }),
    web: (() => {
      if (
        CwProtocol.CheckParam &&
        CwProtocol.CheckParam.token &&
        true
      ) {
        // 2001170837
        CwWeb.keepSet({
          key: CW_PROTOCOL_TOOL.RTC,
          DataSafe: CwProtocol.CheckParam.token,
        });
      } else if (
        CwProtocol.token &&
        true
      ) {
        // 2001170837
        CwWeb.keepSet({
          key: CW_PROTOCOL_TOOL.RTC,
          DataSafe: CwProtocol.token,
        });
      }
      window.location.href = (window.location.hostname === '192.168.0.43')
        ? 'https://collaboratespace.us/rtc'
        : '/rtc'
      ;
    }),
  };

  getDownload = ((platform: CwVersionCheckPlatform): string => {
    return (
      platform &&
      CwProtocol &&
      CwProtocol.DownloadMap &&
      CwProtocol.DownloadMap.get(platform) &&
      true
    )
      ? CwProtocol.DownloadMap.get(platform)
      : ''
      ;
  });

  DownloadMap: Map<CwVersionCheckPlatform, string> = new Map();
  server: string;

  platform: CwVersionCheckPlatform;
  download: string;

  CheckParam: CwProtocolCheckI;

  downloadLinked()
    :
    void {
    if (CwProtocol.download
    ) {
      window.location.href = CwProtocol.download;
    }
  }


// 1907030844
  check6(Param: CwProtocolCheck6I): void {
    if (
      Param &&
      Param.action &&
      Param.App &&
      Param.App.Web &&
      true
    ) {
      const Browser = Param.App.Web.getBrowser();
      const OS = Param.App.Web.getOperativeSystem();

      if (PROTOCOL_ACTION.ACTIVATION === Param.action) {

        if (OS.isAndroid && Browser.isChrome) {
          // 1907030844
          // https://www.collaboratespace.us/#/free/8MUNDYAGDL
          // https://www.collaboratespace.us/#/free/BACY6RRQBQ
          Param.App['downVisible'] = true;
        }
        if (OS.isAndroid && Browser.isFirefox) {
          // https://www.collaboratespace.us/#/free/PNDZ9OWYAV
          Param.App['downVisible'] = true;

        }

        if (OS.isIOS && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isIOS && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isIOS && Browser.isSafari) {
          Param.App['downVisible'] = true;
        }

        if (OS.isMac && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isMac && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isMac && Browser.isSafari) {
          Param.App['downVisible'] = true;
        }

        if (OS.isWindows && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isEdge) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isInternetExplorer) {
          Param.App['downVisible'] = true;
        }

      } else if (PROTOCOL_ACTION.SIGN_IN === Param.action) {

        if (OS.isAndroid && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isAndroid && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }

        if (OS.isIOS && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isIOS && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isIOS && Browser.isSafari) {
          Param.App['downVisible'] = true;
        }

        if (OS.isMac && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isMac && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isMac && Browser.isSafari) {
          Param.App['downVisible'] = true;
        }

        if (OS.isWindows && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isEdge) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isInternetExplorer) {
          Param.App['downVisible'] = true;
        }
      } else if (PROTOCOL_ACTION.RESET === Param.action) {

        if (OS.isAndroid && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isAndroid && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }

        if (OS.isIOS && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isIOS && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isIOS && Browser.isSafari) {
          Param.App['downVisible'] = true;
        }

        if (OS.isMac && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isMac && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isMac && Browser.isSafari) {
          Param.App['downVisible'] = true;
        }

        if (OS.isWindows && Browser.isChrome) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isEdge) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isFirefox) {
          Param.App['downVisible'] = true;
        }
        if (OS.isWindows && Browser.isInternetExplorer) {
          Param.App['downVisible'] = true;
        }
      } else if (PROTOCOL_ACTION.NOTHING === Param.action) {

        if (OS.isAndroid && Browser.isChrome) {

        }
        if (OS.isAndroid && Browser.isFirefox) {

        }

        if (OS.isIOS && Browser.isChrome) {

        }
        if (OS.isIOS && Browser.isFirefox) {

        }
        if (OS.isIOS && Browser.isSafari) {

        }

        if (OS.isMac && Browser.isChrome) {

        }
        if (OS.isMac && Browser.isFirefox) {

        }
        if (OS.isMac && Browser.isSafari) {

        }

        if (OS.isWindows && Browser.isChrome) {

        }
        if (OS.isWindows && Browser.isEdge) {

        }
        if (OS.isWindows && Browser.isFirefox) {

        }
        if (OS.isWindows && Browser.isInternetExplorer) {

        }


      }
    }
  }


  failCb(): void {
    const token = (
        CwProtocol &&
        CwProtocol.CheckParam &&
        CwProtocol.CheckParam.token &&
        true
      ) ? CwProtocol.CheckParam.token
      : null
    ;
    const download = CwInstaller.download({token});
// 1910031537
    if (!download) {
      CwProtocol.downloadClient();
      if (
        CwProtocol &&
        CwProtocol.CheckParam &&
        CwProtocol.CheckParam.failCallback &&
        true
      ) {
        CwProtocol.CheckParam.failCallback();
      }
      // CoProtocolBus.clean();
    }
  }

  successCb(): void {
    // CoProtocolBus.clean();
    if (
      CwProtocol &&
      CwProtocol.CheckParam &&
      CwProtocol.CheckParam.successCallback &&
      true
    ) {
      CwProtocol.CheckParam.successCallback();
    }
  }

  unsupportedCb(): void {
    // 1910031537
    if (!
      CwInstaller.download({token: CwProtocol.CheckParam.token})
    ) {
      CwProtocol.downloadClient();
      if (
        CwProtocol &&
        CwProtocol.CheckParam &&
        CwProtocol.CheckParam.unsupportedCallback &&
        true
      ) {
        CwProtocol.CheckParam.unsupportedCallback();
      }
      // CoProtocolBus.clean();
    }
  }

  downloadClient(): void {
    // 1906251012
    if (!
      CwFix.protocolIOsSafari({platform: CwWeb.getPlatform()})
    ) {
      if (CwProtocol.download) {
        window.location.href = CwProtocol.download;
      }
    }
  }


  setup(): void {
    CwProtocol.SelectConnection.init();
    CwVersionCheck.do2(<CwVersionCheckDoI>{
      Bus: CwVersionCheck,
      Parameters: {platform: 0, entityId: 0},
      successActionCustom: ((Data) => {
        if (
          Data &&
          Data.result &&
          Data.result.length > 0 &&
          true
        ) {
          Data.result.forEach(Item => {
            CwProtocol.add({
              platform: Number(Item.platform),
              url: Item.download_url,
            });
          });
        } else if (
          Data &&
          Data.result &&
          this.platform &&
          true
        ) {
          CwProtocol.add({
            platform: Number(this.platform),
            url: Data.result.download_url,
          });
        }
      })
    });

// 2001311009
    if (
      document &&
      document.location &&
      document.location.host &&
      true
    ) {
      CwProtocol.server = document.location.host;
      if (CwProtocol.server === '192.168.0.43:4200') {
        CwProtocol.server = 'collaboratespace.us';
      }
    }

    CwProtocol.platform = CwWeb.getPlatform();

    if (
      CwProtocol.platform &&
      CwProtocol.DownloadMap.get(CwProtocol.platform)
    ) {
      CwProtocol.download = CwProtocol.DownloadMap.get(CwProtocol.platform);
    }
  }

  getProtocolParam(Param?: CwProtocolCheckI): string {
    const _protocolOrUri = (
        Param &&
        Param.token &&
        CwProtocol.server &&
        true
      )
      ? COLLABORATE_PROTOCOL + Param.token + '@' + CwProtocol.server
      : (
        CwProtocol &&
        CwProtocol.token &&
        CwProtocol.server &&
        true)
        ? COLLABORATE_PROTOCOL + CwProtocol.token + '@' + CwProtocol.server
        : COLLABORATE_PROTOCOL + CwProtocol.server
    ;
    console.log('Protocol:', _protocolOrUri);
    return _protocolOrUri;
  }

  add(Param: CwDownloadI): void {
    if (
      Param &&
      Param.platform &&
      Param.url &&
      true
    ) {
      CwProtocol.DownloadMap.set(Param.platform, Param.url);
    }
  }

  setupDownload(Param?: CwProtocolCheckI) {
    let _download = CwProtocol.DownloadMap.get(CwWeb.OS.api);
    if (!_download && Param) {
      _download = <string>Param;
    }
    CwProtocol.download = _download;

    if (Param) {
      CwProtocol.CheckParam = Param;
    } else {
      CwProtocol.CheckParam = null;
    }

    CwProtocol.uri = CwProtocol.getProtocolParam(Param);

  }


// 2001201051
  checkNotFirefox(Param: CwProtocolCheckI) {
    console.log('2002101131', 'ToolProtocol', 'check', 'Param', Param);
    if (!CwWeb.Browser.Firefox.is) {
      if (
        Param &&
        Param.token &&
        true
      ) {
        CwProtocol.join(Param);
      } else {
        // Firefox mandatory - 2001200856
        const isFirefox = CwWeb.Browser.Firefox.is;
        const isChrome = CwWeb.Browser.Chrome.is;
        const isIos = CwWeb.OS.Ios.is;
        const hasSpace = CwWeb.keepGet({key: CW_PROTOCOL_TOOL.FIREFOX.KEEP});
        if (isFirefox && !hasSpace) {
          // set and download
          CwWeb.keepSet({
            key: CW_PROTOCOL_TOOL.FIREFOX.KEEP,
            Data: true,
          });
        } else if (isIos && isChrome) {
          this.joinIos(Param);
        } else {
          if (isIos) {
            if (!CwProtocol.token) {
              CwProtocol._isAutoDownload = true;
            }
            CwProtocol.notFound();
          } else {
            customProtocolCheck(
              CwProtocol.uri,
              CwProtocol.notFound,
              CwProtocol.found,
            );
          }
        }
      }
    }
  }

// 2001201051
  join(Param: CwProtocolCheckI) {
    CwProtocol.setupDownload(Param);
    // 2001200856
    // Firefox mandatory - 2001200856
    // const isFirefox = CwWeb.Browser.Firefox.is;
    // const hasSpace = CwWeb.keepGet({key: CW_PROTOCOL_TOOL.FIREFOX.KEEP});
    // if (isFirefox && !hasSpace) {
    //   // set and download
    //   CwWeb.keepSet({
    //     key: CW_PROTOCOL_TOOL.FIREFOX.KEEP,
    //     Data: true,
    //   });
    // } else {
    if (CwWeb.OS.Ios.is) {
      CwProtocol.joinIos(Param);
    } else if (CwWeb.OS.Android.is && CwWeb.Browser.Chrome.is) {
      CwProtocol.joinAndroidChrome(Param);
    } else {
      customProtocolCheck(
        CwProtocol.uri,
        CwProtocol.notFound,
        CwProtocol.found,
      );
      // }
    }
  }

  joinAndroidChrome(Param) {
    CwWeb.goTo({path: CwProtocol.uri, external: true});
  }

// 22001201051 - Only Join
  joinIos(Param) {
    if (CwWeb.OS.Ios.is) {
      window['protocolCheckIos'](
        CwProtocol.uri,
        CwProtocol.notFound,
        CwProtocol.found,
        CwProtocol.notFound,
      )
    }
  }

  found() {
    console.log('22001201051', 'found', CwProtocol.CheckParam.found);
    if (
      CwProtocol &&
      CwProtocol.CheckParam &&
      CwProtocol.CheckParam.found &&
      true
    ) {
      CwProtocol.CheckParam.found();
    }
  }

  notFound() {
    console.log('2002101131', 'ToolProtocol', 'notFound', 'CwProtocol', CwProtocol);
    if (
      CwProtocol &&
      CwProtocol.CheckParam &&
      CwProtocol.CheckParam.notFound &&
      true
    ) {
      CwProtocol.CheckParam.notFound();
    }
  }


  // 2001200856
  check = (Params: CwProtocolCheckI) => {
    CwProtocol.setupDownload(Params);
    CwProtocol.Browser.Firefox.check(Params);
    CwProtocol.checkNotFirefox(Params)

  }

}

export const CwProtocol = new ToolProtocol();
