import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {CwLiteral} from '../../../literal/literal';
import {CwStringTool} from '@tool/string/string-tool';
import * as $ from 'jquery';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-space-agree',
  templateUrl: './cw-space-agree.component.html',
  styleUrls: ['./cw-space-agree.component.css']
})
export class CwSpaceAgreeComponent implements AfterViewInit {

  @Input() literal: string;
  @Output() privacy = new EventEmitter();
  @Output() terms = new EventEmitter();

  Agree = {
    Checked: {
      term: false,
      privacy: false,
    },
    Original: {
      all: 'By signing up, I agree to the __PrivacyPolicy__ and __TermsAndConditionsOfUse__',
      privacy: 'Privacy Policy',
      privacyKey: '{{PrivacyPolicy}}',
      privacyKeyAlternative: '__PrivacyPolicy__',
      terms: 'Terms and Conditions of Use',
      termsKey: '{{TermsAndConditionsOfUse}}',
      termsKeyAlternative: '__TermsAndConditionsOfUse__',
    },
    Html: {
      source: null,
      target$: null,
      Privacy: {},
      Terms: {},
    },
    onChangePrivacy: (Event) => {
      this.Agree.Checked.privacy = Event.target.checked;
      this.privacy.emit(Event.target.checked);
    },

    definePrivacy: () => {

      const DivInput = document.createElement('div');
      DivInput.className = 'd-flex align-items-center';

      const PrivacyInput = document.createElement('input');
      PrivacyInput.name = 'agreePrivacyPolicy';
      PrivacyInput.required = true;
      PrivacyInput.type = 'checkbox';
      PrivacyInput.id = 'PrivacyInput';
      PrivacyInput.onchange = this.Agree.onChangePrivacy;

      DivInput.appendChild(PrivacyInput);

      const x = CwLiteral.translate(this.Agree.Original.privacy);
      const A$ = $('<a/>', {
          'html': x,
          'href': '#/privacy-policy/' + CwLiteral.Language.language,
          'class': 'ml-2 font-weight-bold',
          'target': '_blank'
        }
      );
      $('#PrivacyDiv')
        .append(DivInput)
        .append(A$)
      ;
    },

    onChangeTerm: (Event) => {
      this.Agree.Checked.term = Event.target.checked;
      this.terms.emit((Event.target.checked));
    },

    defineTerm: () => {
      const DivInput = document.createElement('div');
      DivInput.className = 'd-flex align-items-center';

      const TermInput = document.createElement('input');
      TermInput.name = 'agree';
      TermInput.required = true;
      TermInput.type = 'checkbox';
      TermInput.id = 'AgreeInput';
      TermInput.onchange = this.Agree.onChangeTerm;

      DivInput.appendChild(TermInput);

      const A$ = $('<a/>', {
          'html': CwLiteral.translate(this.Agree.Original.terms),
          'href': '#/terms/' + CwLiteral.Language.language,
          'class': 'ml-2 font-weight-bold',
          'target': '_blank'
        }
      );
      $('#TermsDiv')
        .append(DivInput)
        .append(A$)
      ;
    },

    define: () => {
      this.Agree.Html.source =
        CwStringTool.replaceCaseInsensitive(
          this.Agree.Html.source,
          (<string>this.Agree.Html.source).includes(this.Agree.Original.privacyKey)
            ? this.Agree.Original.privacyKey
            : this.Agree.Original.privacyKeyAlternative
          ,
          '<div id="PrivacyDiv" class="mx-2 d-flex"></div>',
        );
      const terms = CwLiteral.translate(this.Agree.Original.terms);
      this.Agree.Html.source =
        CwStringTool.replaceCaseInsensitive(
          this.Agree.Html.source,
          (<string>this.Agree.Html.source).includes(this.Agree.Original.termsKey)
            ? this.Agree.Original.termsKey
            : this.Agree.Original.termsKeyAlternative
          ,
          '<div id="TermsDiv" class="mx-2 d-flex"></div>'
        )
      ;
      this.Agree.Html.target$.html(this.Agree.Html.source);
      this.Agree.definePrivacy();
      this.Agree.defineTerm();
    }
    ,
    subscribe: () => {
      this.Agree.translate();
      CwLiteral.onLangChange$.subscribe(this.Agree.translate);
    }
    ,
    translate: () => {
      this.Agree.Html.target$ = $('#AgreeDiv');
      this.Agree.Html.source = CwLiteral.translate(this.Agree.Original.all);
      this.Agree.define();
    }
  };

  ngAfterViewInit(): void {
    if (this.literal) {
      this.Agree.Original.all = this.literal;
    }
    this.Agree.subscribe();
  }

}
