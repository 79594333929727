import {CwBusClass} from '../CollaborateSpaceWebTools/model/bus';
import {CwDefineUrl, CwFunction} from '../CollaborateSpaceWebTools/model/model';
import {CwToolKeepC} from '../CollaborateSpaceWebTools/tool/keep/tool-keep';

class FootBus extends CwBusClass {
    DefineProperties: CwFunction[];
    Keep: CwToolKeepC<any>;
    Setup: any;
    defineUrl: CwDefineUrl;
    do: CwFunction;

    DownloadUrl = {
        windows: '',
        mac: '',
        ios: '',
        android: ''
    }
}

/**
 * @version 1904101050
 */
 export const AppFootBus = new FootBus();
