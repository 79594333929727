import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {CwSpaceSubscriptionTool} from '../../../tool/space/subscription/tool-space-subscription';
import {CW_SPACE_PLAN, CwSpacePlanModel, CwSpacePlanStyleModel} from './cw-space-plan.model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwInputI, CwInputType} from '../../input/cw-input-model';
import {CwFunction} from '@cw/model/model';
import {CwLiteral} from '@cw/literal/literal';
import {CW_LITERAL_MODEL} from '@cw/literal/model';
import {CwSpacePlanMinimalSetupModel} from '@cw/components/space/plan/minimal/cw-space-plan-minimal.model';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-space-plan',
  templateUrl: './cw-space-plan.component.html',
  styleUrls: ['./cw-space-plan.component.scss']
})
/**
 * @version 2004150000-2
 */
export class CwSpacePlanComponent implements OnInit {

  @Input() amountFunction: CwFunction;
  @Input() perHost: number;
  @Input() Plan: CwSpacePlanModel;
  @Input() PlanNew: CwSpacePlanModel;
  @Input() Setup: CwSpacePlanMinimalSetupModel;
  @Input() style: CwSpacePlanStyleModel;
  @Output() changed = new EventEmitter();
  @ViewChild('TemplateMainDefault') TemplateMainDefault: TemplateRef<any>;
  @ViewChild('TemplateMainMinimal') TemplateMainMinimal: TemplateRef<any>;
  @ViewChild('TemplateMainStandard') TemplateMainStandard: TemplateRef<any>;
  @ViewChild('TemplateMainSummary') TemplateMainSummary: TemplateRef<any>;
  @ViewChild('TemplateZoom') TemplateZoom: TemplateRef<any>;

  CwToolCurrency = CwToolCurrency;

  QuantityInput: CwInputI = {
    class: 'text-right pr-3',
    enabled: true,
    id: CwUtil.uuid(),
    name: 'QuantityInput',
    min: 0,
    notLabel: true,
    type: CwInputType.NUMBER,
    valid: true,
    ValidationList: [],
    value: 0,
    Validate: {
      required: true,
    },
  };

  // ARROW FUNCTIONs ///////////////////////////////////////////////////////////

  intervalChange = ((value: any): void => {
    if (value) {
      this.PlanNew.interval = value;
      // Update Date
      if (
        this.Plan &&
        this.PlanNew &&
        this.Plan.interval === this.PlanNew.interval &&
        true
      ) {
        this.PlanNew.Date = this.Plan.Date;
      } else {
        this.PlanNew.Date = CwSpaceSubscriptionTool.defineEnd(this.PlanNew);
      }
      this.changed.emit(this.PlanNew);
    }
  });

  quantityChange = (() => {
    if (this.QuantityInput.valid) {
      this.PlanNew.quantity = this.QuantityInput.value;
      this.changed.emit(this.PlanNew);
    }
  });

  // ARROW FUNCTIONs ///////////////////////////////////////////////////////////


  // EVENTs ////////////////////////////////////////////////////////////////////
  On = {
    PlanMinimal: {
      changed: ((Plan) => {
        this.PlanNew.quantity = Plan.quantity;
        this.changed.emit(Plan)
      }),
    },
    TemplateMainStandard: {
      clicked: (
        (Plan: any): void => {
          if (Plan && Plan.Visible) {
            Plan.Visible.description = !Plan.Visible.description;
          }
        }
      ),
    }
  }
  // EVENTs ////////////////////////////////////////////////////////////////////

  // constructor ///////////////////////////////////////////////////////////////
  constructor() {
    CwToolCurrency.subscribe((
      () => {
        if (this.PlanNew) {
          this.PlanNew.currency = CwToolCurrency.getSelectedCode;
        }
      }
    ))
  }

  // constructor ///////////////////////////////////////////////////////////////


  // GETs //////////////////////////////////////////////////////////////////////

  get getAmount(): number {
    return (this.PlanNew)
      ? (this.amountFunction)
        ? this.amountFunction(this.PlanNew) / 100
        : this.PlanNew.amount / 100
      : 0
  }

  get getLanguage(): string {
    return CwLiteral.Language.language;
  }

  get getRouterButtonFontSize(): number {
    if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.GERMAN) {
      return 10;
    } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.FRANCOIS) {
      return 12;
    } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.ITALIAN) {
      return 11;
    } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.KOREAN) {
      return 11;
    } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.POLISH) {
      return 10; // 2102091735
    } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.TURKISH) {
      return 10; // 2102091735
    } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.RUSSIAN) {
      return 11;
    } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.SPANISH) {
      return 11;
    } else {
      return 15;
    }
  }

  // 2102091735
  get getRouterCol(): string {
    if (this.PlanNew.Routers.length === 1) {
      if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.FRANCOIS) {
        return 'col-9';
      } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.ITALIAN) {
        return 'col-9';
      } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.POLISH) {
        return 'col-9';
      } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.PORTUGUESE) {
        return 'col-9';
      } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.RUSSIAN) {
        return 'col-10';
      } else if (this.getLanguage === CW_LITERAL_MODEL.LANGUAGE.TURKISH) {
        return 'col-9';
      } else {
        return 'col-7';
      }
    } else {
      return 'col-5';
    }
  }

  get getTemplateOutlet(): TemplateRef<any> {
    return (!this.PlanNew)
      ? null
      : (this.style === CW_SPACE_PLAN.STYLE.MINIMAL)
        ? this.TemplateMainMinimal
        : (this.style === CW_SPACE_PLAN.STYLE.STANDARD)
          ? this.TemplateMainStandard
          : (this.style === CW_SPACE_PLAN.STYLE.SUMMARY)
            ? this.TemplateMainSummary
            : (this.style === CW_SPACE_PLAN.STYLE.ZOOM)
              ? this.TemplateZoom
              : this.TemplateMainDefault
      ;
  }

  get getTotal(): number {
    if (this.perHost) {
      return ((this.PlanNew.quantity * this.getAmount) / this.perHost);
    } else {
      return (this.PlanNew.quantity * this.getAmount);
    }
  }

  // GETs //////////////////////////////////////////////////////////////////////


// NGs ///////////////////////////////////////////////////////////////////////
  ngOnInit() {
    if (this.PlanNew) {
      this.QuantityInput.value = this.PlanNew.quantity;
    }
  }

  // NGs ///////////////////////////////////////////////////////////////////////

}
