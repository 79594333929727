import {Component, Input, OnInit} from '@angular/core';
import {AppWebTool} from '../../app-web.tool';
import {CwLiteral} from '@cw/literal/literal';
import {AppCw} from '../../app.class';
import {AppTool} from '../../app-tool';
import {CwStateBus} from '@rest/literal/state/state-bus';
import {CwCountryStateBus} from '@rest/literal/country-min/country-min-bus';
import {CwWeb} from '@tool/web/tool-web';
import {App} from '../../app';
import {AppDataSignBus} from '../data-sign-up-bus';
import {AppFeedbackTool} from '../../feedback/feedback.tool';
import {CwScrollTool} from '@tool/scroll/tool';
import {AppDataSignUpToolClass} from '../data-sign-up.tool';
import {CwInfoCouponBus} from '@rest/info/coupon/info-coupon.bus';
import {CwInfoCouponDoModel} from '@rest/info/coupon/info-coupon.model';
import {CwProtocol, CwProtocolTool} from '@tool/protocol/tool-protocol';
import {CW_PROTOCOL_TOOL} from '@tool/protocol/model';

@Component({
  selector: 'app-data-sign-up-passive',
  templateUrl: './data-sign-up-passive.component.html',
  styleUrls: ['./data-sign-up-passive.component.scss']
})
/**
 * @version 2006290000.1
 */
export class DataSignUpPassiveComponent extends AppCw implements OnInit {

  // DECORATORs ////////////////////////////////////////////////////////////////
  @Input() set couponExternal(value: string) {
    if (value) {
      CwInfoCouponBus.do(<CwInfoCouponDoModel>{
        coupon: value,
        successActionCustom: () => {
          this.AppDataSignUpTool.Coupon.api = value;
        }
      })
    }
  }

  //////////////////////////////////////////////////////////////////////////////


  AppDataSignUpTool = new AppDataSignUpToolClass;


  error = ((Data) => {
    CwWeb.loaderHide();
    AppFeedbackTool.error(
      (Data && Data.error)
        ? Data.error
        : (Data)
        ? Data
        : ''
    )
    // 2006110844
    CwScrollTool.goTo({
      id: '#ScrollFeedback',
    })

  });


  On = {
    SignUp: {
      clicked: (() => {
        this.AppDataSignUpTool.Registration.On.SignUp.clicked()
      })
    },
    // 2009210000.D2
    Start: {
      clicked: () => {
        CwProtocol.App.start();
      }
    }
  }

  successActionCustom = ((Data, That) => {
    CwWeb.loaderHide();
    App.Body.visible = false;
    App.Feedback = Data;
    if (
      AppDataSignBus &&
      AppDataSignBus.jid === '' &&
      AppDataSignBus.email &&
      true
    ) {
      CwWeb.goTo({path: '/verify/' + AppDataSignBus.email});
    } else if (
      AppDataSignBus &&
      AppDataSignBus.jid &&
      true
    ) {
      CwWeb.goTo({path: '/datasignin'})
    } else {
      AppWebTool.Title = {
        main: 'Check Your Inbox',
      }
    }
  });


  // PROTECTEDs ////////////////////////////////////////////////////////////////
  protected _countryAndStateVisible = false;
  protected _emailOffsetVisible = false;
  protected _invitedByVisible = false;
  protected _invitedBy = '';

  //////////////////////////////////////////////////////////////////////////////

  constructor() {
    super();
    AppWebTool.Title = {
      // 2007291000
      main: 'COLLABORATE Space Free Account',
      // main: 'COLLABORATE Space Personal',
      // main: 'COLLABORATE Space Trial Free',
      // main: 'COLLABORATE Space Free',
      secondary: 'Registration',
    };
    CwStateBus.Load.do();
    CwCountryStateBus.Load.do();

    this.AppDataSignUpTool.Registration.On.successActionCustom = this.successActionCustom;
    this.AppDataSignUpTool.Registration.On.errorActionCustom = this.error;
  }

  // GETs //////////////////////////////////////////////////////////////////////

  get getCountryAndStateVisible(): boolean {
    return true;
  }

  get getInviteBy(): string {
    return this._invitedBy;
  }

  get getPrivacyPolicyHref(): string {
    return '#/privacy-policy/' + CwLiteral.Language.language;
  }


  get getTermsHref(): string {
    return '#/terms/' + CwLiteral.Language.language;
  }

  //////////////////////////////////////////////////////////////////////////////


  // ISs ///////////////////////////////////////////////////////////////////////

  get isSignInVisible(): boolean {
    return !this.isStartVisible;
  }

  // 2009210000.D2
  get isStartVisible(): boolean {
    return (CwProtocolTool.source === CW_PROTOCOL_TOOL.SOURCE.INVITE);
  }

  get isEmailOffsetVisible(): boolean {
    return this._emailOffsetVisible;
  }

  get isInvitedByVisible(): boolean {
    return this._invitedByVisible;
  }

  //////////////////////////////////////////////////////////////////////////////


  ngOnInit() {
    AppTool.googleLog();
  }

}
