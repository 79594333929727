import {Component} from '@angular/core';
import {PlansViewComponent} from '../plans-view.component';

@Component({
  selector: 'app-plans-view-standard',
  templateUrl: './plans-view-standard.component.html',
  styleUrls: ['./plans-view-standard.component.css']
})
export class PlansViewStandardComponent extends PlansViewComponent {

}
