import {CwFunction} from '../../model/model';

/**
 * @version 1904101050
 */
class VersionCheckDefine {
    Properties: CwFunction[] = [
        // // descriptionWeb
        // (Properties: any) => {
        //     if (
        //         Properties &&
        //         Properties.description &&
        //         true
        //     ) {
        //         Properties.descriptionWeb = CoLiteralBus.translate(Properties.description);
        //     }
        // },
    ]
}

/**
 * @version 1904101050
 */
export const CwVersionCheckD = new VersionCheckDefine();

