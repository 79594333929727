import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-rates-call',
  templateUrl: './rates-call.component.html',
  styleUrls: ['./rates-call.component.css']
})
export class RatesCallComponent {
  @Input() Call: any;
}
