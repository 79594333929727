import {Component, OnInit} from '@angular/core';
import {AppWebTool} from '../../../app-web.tool';

@Component({
  selector: 'app-connection-app-is-still-open',
  templateUrl: './connection-app-is-still-open.component.html',
  styleUrls: ['./connection-app-is-still-open.component.css']
})
/**
 * @version 2002141110
 */
export class ConnectionAppIsStillOpenComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    AppWebTool.Title = {
      main: 'Close all COLLABORATE Space windows and try again.',
      secondary: '',
      startHereVisible: false,
    };
  }

}
