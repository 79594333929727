import {Component, Input} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-ready',
  templateUrl: './cw-ready.component.html',
  styleUrls: ['./cw-ready.component.css']
})
export class CwReadyComponent {
  @Input() ready = false;


}
