import {CwWeb} from '../../web/tool-web';
import {CwWebLoadJsI} from '../../web/web.model';
import {CwGoogleLogRecordModel} from './google-log-model';
import * as $ from 'jquery';

/**
 * @version 2003251014
 *
 */
export const CW_GOOGLE_LOG_TOOL = {

  /**
   * @version 2003251014
   * @param Params
   */
  record: (Params: CwGoogleLogRecordModel) => {
    // src/assets/js/google/log/google-log.js
    if (
      Params &&
      !Params.isOnPremise &&
      true
    ) {
      if (
        Params.isProd === undefined &&
        Params.domain &&
        true
      ) {
        Params.isProd = (Params.domain === 'collaboratespace.net');
      }
      if (Params.isProd) {
        CwWeb.loadJs(<CwWebLoadJsI>{
          condition: true,
          UrlList: [
            '/assets/js/google/log/google-log.js'
          ],
        });

        const IframeElement = document.createElement('iframe');
        IframeElement.src = 'https://www.googletagmanager.com/ns.html?id=GTM-KXHVXQT';
        IframeElement.height = '0';
        IframeElement.width = '0';
        IframeElement.style.display = 'none';
        IframeElement.style.visibility = 'hidden';

        const NoScriptElement = document.createElement('noscript');
        NoScriptElement.appendChild(IframeElement);

        $(NoScriptElement).prepend('body');
        // document.getElementsByTagName('body')[0].appendChild(NoScriptElement);

      }
    }
  }
}
