import * as _ from 'lodash';
import {CoIs} from './is';
import {CoUtils} from './CoUtils';
import {CoLog} from './log';
import {CoError} from '../app/error';
import {CoMapClass} from '../map';

/**
 * @since R23.0 - Users - Add/Edit Teams (Choose Teams)
 * @version R59.21 - Resources - Recording, Streaming, Whiteboard, SharingData ...
 */
export class CoArray {

  /**
   * @version R40.0 - Pessimistic
   * @param {any[]} _sourceList
   * @param {any[]} _exceptList
   * @returns {any[]}
   */
  public static returnDifference(_sourceList: any[], _exceptList: any[]): any[] {
    return _.difference(_sourceList, _exceptList);
  }

  /**
   * @param propertiesArray
   * @param _propertiesUOI
   * @returns {boolean}
   * @version R34.1 - Resources - When they are updated, go back to General Settings
   */
  // public static propertiesUpdateOrInsert(propertiesArray: any,
  //                                        _propertiesUOI: any): boolean {
  //   return CoBasicDefine.define(propertiesArray, _propertiesUOI, function (propertiesArray, _propertiesUOI) {
  //     for (const _propertiesUOIKeyItem of Object.keys(_propertiesUOI)) {
  //       if (CoIs.arrayNotEmpty(_propertiesUOI[_propertiesUOIKeyItem])) {
  //         CoArray.propertiesUpdateOrInsert(propertiesArray[_propertiesUOIKeyItem], _propertiesUOI[_propertiesUOIKeyItem]);
  //       } else {
  //         propertiesArray[_propertiesUOIKeyItem] = _propertiesUOI[_propertiesUOIKeyItem];
  //       }
  //     }
  //     return true;
  //   });
  // }

  /**
   * @param {any[]} itemsArray
   * @param _itemUOI
   * @param {string} _itemKey
   * @returns {boolean}
   * @version R34.1 - Resources - When they are updated, go back to General Settings
   */
  // public static updateOrInsert(itemsArray: any[], _itemUOI: any, _itemKey?: string): boolean {
  //   return CoBasicDefine.define(itemsArray, _itemUOI, function (itemsArray, _itemROP) {
  //     let _insert = true;
  //
  //     for (const _itemsArrayKeyItem of Object.keys(itemsArray)) {
  //       if (itemsArray[_itemsArrayKeyItem][_itemKey] === _itemROP[_itemKey]) {
  //         _insert = false;
  //         // update properties
  //         CoArray.propertiesUpdateOrInsert(itemsArray[_itemsArrayKeyItem], _itemUOI);
  //         break;
  //       }
  //     }
  //
  //     if (_insert) {
  //       itemsArray.push(_itemROP);
  //     }
  //     return true;
  //
  //   });
  // }


  /**
   * @returns {(itemsArray: any[], _item: any) => boolean}
   * @version R34.1 - Resources - When they are updated, go back to General Settings
   */
  // public static replaceOrPush(itemsArray: any[], _itemROP: any, _itemKey?: string): boolean {
  //   return CoBasicDefine.define(itemsArray, _itemROP, function (itemsArray, _itemROP) {
  //     let _push = true;
  //
  //     for (const _itemsArrayKeyItem of Object.keys(itemsArray)) {
  //       if (itemsArray[_itemsArrayKeyItem][_itemKey] === _itemROP[_itemKey]) {
  //         _push = false;
  //         itemsArray[_itemsArrayKeyItem] = _itemROP;
  //         break;
  //       }
  //     }
  //
  //     if (_push) {
  //       itemsArray.push(_itemROP);
  //     }
  //     return true;
  //   });
  // }

  /**
   *
   * @param {any[] | any} itemsArray
   * @param {string} itemKeyName
   * @param itemKeyValue
   * @param {string} itemPropertyName
   * @returns {any}
   */
  // public static extractPropertyValueByKey(itemsArray: any[] | any,
  //                                         itemKeyName: string,
  //                                         itemKeyValue: any,
  //                                         itemPropertyName?: string): any {
  //   let _value = null;
  //   let _itemFound = null;
  //   const _itemHasFound = CoBasicDefine.define(itemsArray, {}, function (itemsArray, {}) {
  //     let _found = false;
  //     for (const _indexItem of Object.keys(itemsArray)) {
  //       if (itemsArray[_indexItem][itemKeyName] === itemKeyValue) {
  //         _found = true;
  //         _itemFound = itemsArray[_indexItem];
  //         break;
  //       }
  //     }
  //     return _found;
  //   });
  //   if (_itemHasFound) {
  //     if (
  //       CoIs.stringNotEmpty(itemPropertyName) &&
  //       CoIs.defined(_itemFound[itemPropertyName])
  //     ) {
  //       _value = _itemFound[itemPropertyName];
  //     }
  //   }
  //   return _value;
  //
  // }


  /**
   *
   * @param _dataIn
   * @param dataOut
   * @param {string} _propertyName
   * @param {boolean} _allowRepeated
   * @returns {boolean}
   * @version R29.0 - Billing - Migration to work in production mode.
   */
  public static extractProperty(_dataIn: any,
                                dataOut: any,
                                _propertyName: string,
                                _allowRepeated = true): boolean {
    let _success = false;
    try {
      if (CoIs.undefined(dataOut)) {
        dataOut = [];
      }
      if (CoIs.defined(_dataIn, dataOut) && CoIs.stringNotEmpty(_propertyName)) {
        _success = true;
        if (CoIs.array(_dataIn)) {
          _dataIn.forEach(item => {
            // R29.0 - Billing - Migration to work in production mode.

            if (_allowRepeated) {
              dataOut.push(item[_propertyName]);
            } else {
              CoArray.pushOnly(dataOut, item[_propertyName]);
            }

            // _success = _success && CoIs.defined(item[_propertyName]);


            // _success = _success &&
            //   CoIs.defined(item[_propertyName]) &&
            //   (
            //     (_allowRepeated)
            //       ? 0 < dataOut.push(item[_propertyName])
            //       : 0 < CoArray.pushOnly(dataOut, item[_propertyName])
            //   ) &&
            //   true
            // ;

          });
        } else {
          dataOut[_propertyName] = _dataIn[_propertyName];
        }
      }
    } catch (error) {
      _success = false;
    }
    return _success;
  }


  public static returnExtractProperty(data: any[], propertyName: string): any[] {
    const only: any[] = [];
    if (data) {
      data.forEach(item => {
        if (item[propertyName]) {
          only.push(item[propertyName]);
        }
      });
    }
    return only;
  }

  /**
   * @version 4.0.0.0.R9.0 - Admin Project
   * @param data
   * @returns {any}
   * TODO: remove lodash to other method 15/09/2017
   * [CoPlansBus].getFilter(item => true)[0]
   */
  public static cloneDeep(data: any): any {
    return CoUtils.cloneDeep(data);
  }

  /**
   * Gets the last element of array.
   * @param data
   * @returns {any}
   * @since 4.0.0.0.R9.0 - Admin Project
   */
  public static last(data: any): any {
    return _.last(data);
  }

  /**
   * Splits string by separator.
   * @param data
   * @param {string} separator
   * @returns {any}
   * @since 4.0.0.0.R9.0 - Admin Project
   */
  public static split(data: any, separator: string): any {
    return _.split(data, separator);
  }

  /**
   * Give back true if the arrays are the same
   * @param {any[]} _arrayA
   * @param {any[]} _arrayB
   * @returns {boolean}
   * @version R25.0 - Teams - Migration to work in production mode.
   */
  public static equal(_arrayA: any[], _arrayB: any[], _propertyKey?: string): boolean {
    let _equal = false;
    if (CoIs.equal(_arrayA.length, _arrayB.length)) {
      try {
        const _intersection: any[] = CoArray.intersection(
          _arrayA,
          _arrayB,
          _propertyKey
        );
        // const _intersection: any[] = CoArray.intersection(_arrayA, _arrayB);
        _equal = CoIs.equal(_intersection.length, _arrayA.length);
      } catch (error) {

      }
    }
    return _equal;
  }

  /**
   * @doc https://lodash.com/docs/4.17.4#intersection
   * @param dataA
   * @param dataB
   * @returns {any}
   * @since 4.0.0.0.R9.0 - Admin Project
   */

  /**
   * Creates an array of unique values that are included in all given arrays
   * @param {any[]} dataA
   * @param {any[]} dataB
   * @param {string} _propertyKey
   * @returns {any}
   * @version R25.0 - Teams - Migration to work in production mode.
   */
  public static intersection(dataA: any[], dataB: any[], _propertyKey?: string): any {
    return CoIs.stringNotEmpty(_propertyKey)
      ? _.intersectionBy(dataA, dataB, _propertyKey)
      : _.intersection(dataA, dataB)
      ;
  }

  /**
   * Converts all elements in array into a string separated by separator.
   * @doc https://lodash.com/docs/4.17.4#join
   * @param dataArray
   * @param {string} joiner
   * @returns {any}
   * @since 4.0.0.0.R9.0 - Admin Project
   */
  public static join(dataArray: any, joiner: string): any {
    _.join(dataArray, joiner);
  }

  /**
   *
   * @param {any[]} outArray
   * @param _item
   * @returns {number}
   * @since 4.0.0.0.R13.11 - Users - Add - Invite users with mailing lists separated by semicolons
   * @since R35.5 - New design - Users - Add.
   */
  public static pushOnly(outArray: any[], _item: any, _objectKey?: string): number {
    let _pushOnly = -1;
    try {
      if (
        !CoIs.array(outArray) ||
        CoIs.undefined(_item)
      ) {
        throw new CoError('201802151539', 'pushOnly', 'Params undefined');
      }
      let _ItemNews = _item;
      if (!CoIs.array(_ItemNews)) {
        _ItemNews = [_item];
      }
      _ItemNews.forEach(_itemNew => {
        let _equal = false;
        outArray.forEach(_listItem => {
          _equal = _equal || CoIs.equal(_listItem, _itemNew, _objectKey);
        });
        if (!_equal) {
          outArray.push(_itemNew);
        }
      });
      _pushOnly = outArray.length;
    } catch (error) {
      _pushOnly = -1;
      const x = new CoError('201802151544', 'pushOnly', error);
    }
    return _pushOnly;
  }

  /**
   * Creates an array of elements split into groups the length of size
   * @doc https://lodash.com/docs/4.17.4#chunk
   * @param {any[]} _array The array to process.
   * @param {number} _size The length of each chunk
   * @returns {any[]}
   * @version R21.0 - Users - Load the vcard of all users.
   */
  public static group(_array: any[], _size: number): any[] {
    return _.chunk(_array, _size);
  }

  public static removeByCoId(arrayIO: any[], coId: any) {
    return CoArray.remove(arrayIO, {coId}, 'coId');
  }

  public static removeByProperty(arrayIO: any[], propertyName: string, propertyValue: any) {
    const removeItem = {};
    removeItem[propertyName] = propertyValue;
    return CoArray.remove(arrayIO, removeItem, 'coId');
  }

  public static clear(arrayIO: any[]): void {
    if (arrayIO) {
      arrayIO.splice(0, arrayIO.length);
    }
  }

  public static remove40(arrayIO: any[], _removeItem: any, _removeKey?: string): number {
    try {
      if (CoIs.arrayNotEmpty(arrayIO)) {
        for (const _index in arrayIO) {
          const _itemIndex = Number.parseInt(_index);
          const _item = arrayIO[_itemIndex];
          if (CoIs.defined(_removeKey)) {
            if (_item[_removeKey] === _removeItem[_removeKey]) {
              arrayIO.splice(_itemIndex, 1);
              break;
            }
          } else if (_item === _removeItem) {
            arrayIO.splice(_itemIndex, 1);
            break;
          }
        }
      }
    } catch (error) {
      CoLog.error('CoArray.remove40', arrayIO, _removeItem, _removeKey);
    }
    return (CoIs.array(arrayIO))
      ? arrayIO.length
      : undefined
      ;
  }


  /**
   * @version R40.5 - Users - Add
   */
  public static remove(arrayIO: any[], _removeItem: any, _removeKey?: string): number {
    try {
      arrayIO.forEach((_item, _index) => {
        if (CoIs.defined(_removeKey)) {
          if (_item[_removeKey] === _removeItem[_removeKey]) {
            arrayIO.splice(_index, 1);
          }
        } else if (_item === _removeItem) {
          arrayIO.splice(_index, 1);
        }
      });
    } catch (error) {
      CoLog.error('CoArray.remove', arrayIO, _removeItem, _removeKey);
    }

    return (CoIs.array(arrayIO))
      ? arrayIO.length
      : undefined
      ;
    // try {
    //   arrayIO = arrayIO.getFilter(
    //     _filter =>
    //       _filter[_removeKey] !== _removeItem[_removeKey]
    //   );
    //
    // } catch (error) {
    //
    // }
    // return arrayIO.length;
  }

  /**
   * @param {any[]} _collection
   * @param _value
   * @returns {boolean}
   * @version R24.5 - ERROR - Users - Erase does not work in IE
   */
  public static includes(_collection: any[], _value: any): boolean {
    return _.includes(_collection, _value);
  }

  /**
   * @param {any[]} arrayIO
   * @param {any[]} _excludeList
   * @param {CoMapClass} _map
   * @version R37.2 - Teams - Checked
   */
  public static difference(arrayIO: any[], _excludeList: any[], _map: CoMapClass): void {
    _excludeList.forEach(_excludeItem => {
      CoArray.remove(arrayIO, _excludeItem, _map.propertyId);
    });
  }
}
