import {isArray, isDate} from 'util';
import * as _ from 'lodash';
import {FormControl, Validators} from '@angular/forms';

class ToolIsClass {

  boolean(data: any): boolean {
    return _.isBoolean(data);
  }

  array(data: any): boolean {
    return (
      data &&
      isArray(data) &&
      // data.isArray() &&
      true
    );
  }


  arrayNotEmpty(data: any): boolean {
    return (
      CwIs.array(data) &&
      0 < data.length &&
      true
    );
  }


  arrayEmpty(data: any): boolean {
    return (
      CwIs.array(data) &&
      0 === data.length &&
      true
    );
  }

  arrayWithLength(data: any, length: number): boolean {
    return (
      CwIs.array(data) &&
      length === data.length &&
      true
    );
  }

  object(_in: any): boolean {
    return (
      CwIs.defined(_in) &&
      typeof _in === 'object'
    );
  }

  string(data: any): boolean {
    return (
      CwIs.defined(data) &&
      typeof data === 'string' &&
      true
    );
  }

  stringNotEqual(data: any, than: string): boolean {
    let _isStringNotEqual = false;
    if (
      (
        CwIs.stringNotEmpty(data) ||
        CwIs.number(data)
      ) &&
      String(data) !== than
    ) {
      _isStringNotEqual = true;
    }
    return _isStringNotEqual;
  }

  defined(...dataList: any[]): boolean {
    let _isDefined = false;
    try {
      _isDefined = (
        undefined !== dataList &&
        null !== dataList &&
        // isNaN(data) &&
        // NaN !== data &&
        true
      );
      if (
        _isDefined &&
        isArray(dataList) &&
        true
      ) {
        for (const item of dataList) {
          _isDefined = (
            _isDefined &&
            undefined !== item &&
            // isNaN(item) &&
            // NaN !== item &&
            null !== item
          );
        }
      }
    } catch (error) {
      _isDefined = false;
    }

    return _isDefined;

  }

  stringEmpty(data: any): boolean {
    return (
      CwIs.string(data) &&
      String('') === String(data) &&
      true
    );
  }

  number(data: any): boolean {
    let _valid = false;
    if (CwIs.stringNotEmpty(String(data))) {
      try {
        _valid = (typeof data === 'number');
      } catch (error) {
        _valid = false;
      }
    }
    return _valid;
  }

  stringEqual(data: any, than: string): boolean {
    let _isStringEqual = false;
    if (
      (
        CwIs.stringNotEmpty(data) ||
        CwIs.number(data)
      ) &&
      String(data) === than
    ) {
      _isStringEqual = true;
    }
    return _isStringEqual;
  }

  stringNotEmpty(data: any): boolean {
    return (
      CwIs.string(data) &&
      String('') !== String(data) &&
      true
    );
  }

  date(data: any): boolean {
    let _valid = false;
    try {
      _valid = (
        CwIs.defined(data) &&
        isDate(data) &&
        true
      );
    } catch (error) {
      _valid = false;
    }
    return _valid;
  }

  email(Param: {
    value: string,
    ValidationList?: string[],
  }): boolean {
    let valid = false;
    if (Param && CwIs.stringNotEmpty(Param.value)) {
      const Control = new FormControl();
      Control.setValue(Param.value);
      Control.setValidators([Validators.email]);
      Control.updateValueAndValidity();
      valid = (Control.valid);
      if (!valid && Param.ValidationList) {
        Param.ValidationList.push('Invalid email address.');
      }
    }
    return valid;
  }

  /**
   * @version 2002111109-5
   * @param Param
   */
  min(Param: {
    min: number,
    value: string,
    ValidationList?: string[],
  }): boolean {
    let valid = false;
    if (Param && CwIs.stringNotEmpty(Param.value)) {
      const Control = new FormControl();
      Control.setValue(Param.value);
      Control.setValidators([Validators.min(Param.min)]);
      Control.updateValueAndValidity();
      valid = (Control.valid);
      if (!valid && Param.ValidationList) {
        Param.ValidationList.push(
          'The minimum value is:' + ' ' + Param.min
        );
      }
    }
    return valid;
  }
}

export const CwIs = new ToolIsClass();
