<!--1906211133-->
<form #formCtrl="ngForm" appCw appCwForm
      *ngIf="App.Body.visible"
>

  <app-cw-row>
    <div R>
      <app-cw-form literal="Password" [ValidationList]="ValidationList">
        <input
            name="newpassword"
            type="password"
            ngModel
            #passwordInput="ngModel"
            [(ngModel)]="newpassword"
            [pattern]="passwordPattern"
            required
            (change)="CsValidation.passwordOk(newpassword, ValidationList, true)"
            appCw
            appCwFocus
            appCwForm
        >
      </app-cw-form>
    </div>
  </app-cw-row>

  <app-cw-row>
    <div R>
      <app-cw-form literal="Confirm Password"
                   [ValidationList]="ConfirmValidationList"
      >
        <input name="repeatnewpassword"
               type="password"
               (keyup.enter)="reset()"
               ngModel
               #rPasswordInput="ngModel"
               [equalTo]="passwordInput"
               [(ngModel)]="newPasswordConfirm"
               (change)="CsValidation.confirm(newpassword, newPasswordConfirm, ConfirmValidationList, true)"
               appCw
               appCwForm
        >
      </app-cw-form>
    </div>
  </app-cw-row>

  <app-cw-row>
    <div R class="text-center">
      <app-cw-form>
        <button
            (click)="reset()"
            type="button"
            appCw
            appCwForm
            [disabled]="!formCtrl.form.valid || !CsValidation.valid(ValidationList)"
        >
          {{ 'Save Password' | translate}}
        </button>
      </app-cw-form>
    </div>
  </app-cw-row>

</form>
