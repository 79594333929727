<form #formCtrl="ngForm" class="py-3">

  <app-cw-row>

    <div R class="text-center">
      <img src="assets/images/icon-checkEmail.png">
    </div>

  </app-cw-row>

  <div class="py-2"></div>

  <app-cw-row>
    <h6 R class="text-center font-bold">{{App?.Verify?.email}}</h6>
  </app-cw-row>

  <div class="py-2"></div>

  <ng-container #MessageContainer>

    <app-cw-row class="d-none d-md-block">
      <div R2
           class="text-justify"
           translate="If you have an account associated with the above email you will have received an email with the instructions for reset the password.">

      </div>
    </app-cw-row>

    <app-cw-row class="d-block d-md-none">
      <div R
           class="text-justify"
           translate="If you have an account associated with the above email you will have received an email with the instructions for reset the password.">

      </div>
    </app-cw-row>

  </ng-container>

  <div class="py-2"></div>

  <ng-container #SeparatorContainer>

    <app-cw-row class="d-block d-md-none">
      <hr R class="line-full">
    </app-cw-row>

    <app-cw-row class="d-none d-md-block">
      <hr R2 class="line-full">
    </app-cw-row>

  </ng-container>

  <div class="py-2"></div>

  <ng-container #EmailContainer>

    <app-cw-row class="d-none d-md-block">

      <div R2>
      <span class="font-bold">
              {{ 'Wrong email address?' | translate}}
            </span>

        <a
            routerLink="/forgot"
            class="font-bold"
        >&#160;{{'Verify Your Email' | translate}}</a>
      </div>

    </app-cw-row>

    <app-cw-row class="d-block d-md-none">

      <div R class="text-center font-bold" translate="Wrong email address?">
      </div>

      <div R class="text-center">
        <a
            routerLink="/forgot"
            class="font-bold"
        >&#160;{{'Verify Your Email' | translate}}</a>
      </div>

    </app-cw-row>

  </ng-container>


</form>
