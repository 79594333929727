<table class="h-100 w-100">
  <tbody>
  <tr>
    <td
      class="align-middle"
    >
      <ng-content></ng-content>
    </td>
  </tr>
  </tbody>
</table>
