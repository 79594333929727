import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwRestRequestParametersI} from '../../../tool/rest/model';
import {InfoBus} from '../info.bus';
import {CwInfoCouponDoModel} from './info-coupon.model';

class Bus extends InfoBus {
  coupon: string;
  DefineProperties: CwFunction[];
  defineUrl: CwDefineUrl = (() => this.path + 'coupon/' + this.coupon);

  do: CwFunction = ((Params: CwInfoCouponDoModel) => {
    if (
      Params &&
      Params.coupon &&
      true
    ) {
      this.coupon = Params.coupon;
      this.restGet(<CwRestRequestParametersI>{
        ...Params,
        Bus: this,
      });
    }
  });


}

/**
 * @version 2005060000
 */
export const CwInfoCouponBus: Bus = new Bus();
