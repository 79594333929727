class DevelopToolClass {

  Enabled = {
    // DEV-PURCHASE DEV-2006031328
    purchase: true,
    // 2008030000
    purchaseSupport: false,
    upcoming: true,
  };
}

export const AppDevelopTool: DevelopToolClass = new DevelopToolClass();
