<a name="page-top"></a>
<!--<ng-container *ngIf="!CoProtocolBus.downloadLink">-->
<!--2001101133-->
<ng-container *ngIf="!App.Protocol.SelectConnection.flag || false">
  <ng-container *ngIf="App.Protocol.download">
    <!--<ng-container *ngIf="ProtocolB.getShowVersionAvailable">-->
    <div class="text-center pb-4">
      <!--        <div style="height: 70px;">-->
      <!--            <h1 class="text-center">{{ 'Thank you for downloading' | translate}}</h1>-->
      <!--        </div>-->
      <div class="h-auto">
        <h3
          class="font-additional text-dark text-center">{{ 'The download process should start automatically.' | translate}}</h3>
      </div>
      <div class="h-auto">
        <h3
          class="font-additional text-dark text-center"
        >{{ 'If the download process doesn’t start,' | translate}}&nbsp;
          <a (click)="App.Protocol.downloadLinked()"
             class="text-dark font-weight-bold"
          >{{ 'click here' | translate}}
          </a>
        </h3>
      </div>
    </div>
    <!--    1904251312-->
    <div class="d-flex justify-content-center flex-row align-items-center bg-light">
      <figure class="figure p-3">
        <img src="assets/images/step-1.png">
        <figcaption
          class="figure-caption "
        >
          <h6>
            <br/>
            1. <span translate>Run the Installer</span>
          </h6>
        </figcaption>
      </figure>
      <figure class="figure p-3">
        <img src="assets/images/step-2.png">
        <figcaption
          class="figure-caption"
        >
          <h6>
            <br/>
            2. <span translate>Click Yes</span>
          </h6>
        </figcaption>
      </figure>
      <figure class="figure p-3">
        <img src="assets/images/step-3.png">
        <figcaption
          class="figure-caption"
        >
          <h6>
            <br/>
            3. <span translate>Follow the Instructions</span>
          </h6>
        </figcaption>
      </figure>
    </div>

    <div class="d-flex justify-content-center flex-row align-items-center bg-light pb-5">
      <a routerLink="/home">
        <span translate>Back Home</span>
      </a>
    </div>

    <!--    &lt;!&ndash;    1904251312&ndash;&gt;-->
    <!--    <div class="div-download">-->
    <!--        <div class="div-middle-4">-->
    <!--            <div class="col-sm-4"><img src="assets/images/step-1.png"></div>-->
    <!--            <div class="col-sm-4"><img src="assets/images/step-2.png"></div>-->
    <!--            <div class="col-sm-4"><img src="assets/images/step-3.png"></div>-->
    <!--        </div>-->
    <!--        <div class="div-middle-4">-->
    <!--            <div class="col-sm-12" style="height: 60px;"></div>-->
    <!--        </div>-->
    <!--        <div class="div-middle-4">-->
    <!--            <div class="col-sm-4 text-left">-->
    <!--                <h3>1. {{ 'Run the Installer' | translate}}</h3>-->
    <!--            </div>-->
    <!--            <div class="col-sm-4 text-left">-->
    <!--                <h3>2. {{ 'Click Yes' | translate}}</h3>-->
    <!--            </div>-->
    <!--            <div class="col-sm-4 text-left">-->
    <!--                <h3>3. {{ 'Follow the Instructions' | translate}}</h3>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="div-middle-4">-->
    <!--            <div class="col-sm-12" style="height: 25px;"></div>-->
    <!--        </div>-->
    <!--        <div class="div-middle-4">-->
    <!--            <div class="col-sm-4 text-left">-->
    <!--            </div>-->
    <!--            <div class="col-sm-4 text-left">-->
    <!--            </div>-->
    <!--            <div class="col-sm-4 text-left">-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="div-middle-4">-->
    <!--            <div class="col-sm-12" style="height: 25px;"></div>-->
    <!--        </div>-->
    <!--        <div class="div-middle-4">-->
    <!--            <div class="col-sm-12"><a routerLink="/home">{{ 'Back Home' | translate}}</a></div>-->
    <!--        </div>-->
    <!--    </div>-->
  </ng-container>
</ng-container>


<ng-container *ngIf="!App.Protocol.download && !App.Protocol.SelectConnection.flag">
  <!--<ng-container *ngIf="!ProtocolB.downloadLink && ProtocolB.getShowVersionNotAvailable">-->
  <div class="div-middle-3">
    <div class="text-white">
      <br>
      <br>
      <h4 translate>For obtain COLLABORATE Space Mobile Version contact with videotechsupport@clearone.com</h4>
      <!--          {{'For obtain COLLABORATE Space Mobile Version contact with space.support@clearone.com' | translate}}-->
    </div>
  </div>
</ng-container>
<!--</ng-container>-->
<!--<app-foot></app-foot>-->

