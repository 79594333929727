<form #formCtrl="ngForm" *ngIf="App.Body.visible" class="mt-3 mt-sm-2 mt-md-2 mt-lg-2 mt-xl-2">

  <app-cw-row>
    <div R2>
      <app-cw-form
          literal="Enter the email address..."
      >
      </app-cw-form>
    </div>
  </app-cw-row>

  <app-cw-row>
    <div R>
      <app-cw-form
      >
        <input appCw
               appCwFocus
               appCwForm
               name="email"
               type="mail"
               ngModel
               #emailInput="ngModel"
               [(ngModel)]="email"
               email
               required
        >
      </app-cw-form>
    </div>
  </app-cw-row>

  <app-cw-row>

    <div R class="text-center text-uppercase">
      <button appCw
              appCwForm
              (click)="askreset()"
              type="button"
              [disabled]="!formCtrl.form.valid"
              translate="Send"
      ></button>
    </div>

  </app-cw-row>

  <app-cw-row>
    <hr R2>
  </app-cw-row>

  <app-cw-row>
    <div R class="text-center">
      <a
          routerLink="/datasignin"
          class="font-bold"
      >&#160;{{'Back to Login' | translate}}</a>
    </div>
  </app-cw-row>

</form>
