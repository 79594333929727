import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DownloadComponent} from './download/download.component';
import {MeetingIdComponent} from './meeting-id/meeting-id.component';
import {HomeComponent} from './home/home.component';
import {VerifyComponent} from './verify/verify.component';
import {DataSignInComponent} from './data-sign-in/data-sign-in.component';
import {RatesComponent} from './rates/rates.component';
import {DialinComponent} from './dialin/dialin.component';
import {ActivationComponent} from './activation/activation.component';
import {HostComponent} from './host/host.component';
import {ResetComponent} from './reset/reset.component';
import {ForgotComponent} from './forgot/forgot.component';
import {RegistrationComponent} from './registration/registration.component';
import {TermsComponent} from './marketing/terms/terms.component';
import {DownloadOnlyComponent} from './download-only/download-only.component';
import {MarketingPrivacyPolicyComponent} from './marketing/privacy-policy/marketing-privacy-policy.component';
import {CouponComponent} from './coupon/coupon.component';
import {FeedbackComponent} from './feedback/feedback.component';
import {PricingComponent} from './pricing/pricing.component';
import {PricingCallRatesComponent} from './pricing/call-rates/pricing-call-rates.component';
import {DataSignUpPassiveComponent} from './data-sign-up/passive/data-sign-up-passive.component';
import {DataSignUpPassiveTokenComponent} from './data-sign-up/passive/data-sign-up-passive-token.component';
import {InviteComponent} from './invite/invite.component';
import {WebinarComponent} from './webinar/webinar.component';
import {ValidationEmailComponent} from './validation-email/validation-email.component';

const appRoutes: Routes = [
  {
    path: 'plans-pricing',
    component: PricingComponent,
  },
  {
    path: 'plans-pricing-call-rates',
    component: PricingCallRatesComponent,
  },
  {
    path: 'coupon/:token',
    component: CouponComponent,
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'download-only',
    component: DownloadOnlyComponent,
  },
  {
    path: 'downloadAndroid',
    component: DownloadComponent
  },
  {
    path: 'downloadIos',
    component: DownloadComponent
  },
  {
    path: 'downloadMac',
    component: DownloadComponent
  },
  {
    path: 'downloadWin',
    component: DownloadComponent
  },
  {
    path: 'rates',
    component: RatesComponent
  },
  {
    path: 'dialin',
    component: DialinComponent
  },
  {
    path: 'meeting',
    component: MeetingIdComponent
  },
  {
    path: 'join/:token',
    component: MeetingIdComponent
  },
  {
    path: 'dmeeting/:token',
    component: MeetingIdComponent
  },
  {
    path: 'hostmeeting',
    component: HostComponent
  },
  {
    path: 'host/:token',
    component: MeetingIdComponent
    // component: HostComponent
  },
  {
    path: 'datasignin',
    component: DataSignInComponent
  },
  {
    // 2006031328
    path: 'datasignup',
    component: DataSignUpPassiveComponent,
  },
  {
    // 2006031328 - 2007020000.1
    path: 'datasignup/:tokenp',
    component: DataSignUpPassiveTokenComponent,
    // component: FreeSingUpComponent
  },
  {
    path: 'forgot',
    component: ForgotComponent
  },
  {
    path: 'verify',
    component: VerifyComponent
  },
  {
    // 2009210000
    path: 'invite/:email',
    component: InviteComponent,
  },
  {
    // 2006031328 - 2007020000.1, 2011160000.D1
    path: 'invitation/:token',
    component: DataSignUpPassiveTokenComponent,
    // component: DataSignUpInvitationComponent,
  },
  {
    path: 'registration/:token',
    component: RegistrationComponent
  },
  {
    path: 'validation-email/:token',
    component: ValidationEmailComponent
  },
  {
    path: 'free/:token',
    component: ActivationComponent,
  },
  {
    path: 'reset/:token',
    component: ResetComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  // 1905211103
  {
    path: 'terms/:language',
    component: TermsComponent
  },
  {
    path: 'privacy-policy',
    component: MarketingPrivacyPolicyComponent,
  },
  {
    path: 'privacy-policy/:language',
    component: MarketingPrivacyPolicyComponent,
  },
  {
    path: 'webinar/:token',
    component: WebinarComponent,
  },
  {
    path: '',
    redirectTo: 'plans',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'plans',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        // to access to virtual context of Angular
        useHash: true,
        // Angular 6
        // scrollPositionRestoration: 'enabled'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}
