<form #formCtrl="ngForm" appCw appCwForm
      *ngIf="!App.Protocol.SelectConnection.flag"
>
  <app-cw-row>
    <app-cw-form R
                 literal="Personal ID or Meeting ID"
    >
      <input appCw
             appCwFocus
             appCwForm
             name="personalId"
             type="text"
             (keyup.enter)="accept()"
             ngModel
             #personalIdInput="ngModel"
             [(ngModel)]="personalId"
             required
      >
    </app-cw-form>

  </app-cw-row>

  <app-cw-row>
    <app-cw-form R>
      <div class="text-center">

        <button appCw
                appCwForm
                (click)="accept()"
                type="button"
                [disabled]="!formCtrl.form.valid"
        >
          {{'Join' | translate}}
        </button>
      </div>

    </app-cw-form>

  </app-cw-row>


</form>
