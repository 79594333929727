/**
 * @version 1.8.0
 */
import {CwDefineUrl, CwFunction, CwLanguageI, CwMapItemI, CwTokenBusI} from '../model/model';
import {CwLiteralC, CwLiteralConfig, CwLiteralI, CwLiteralLanguageI, CwLiteralSetupI, CwLiteralT} from './model';
import {CwToolKeepC} from '../tool/keep/tool-keep';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwWeb} from '../tool/web/web.tool';
import {CwLiteralD} from './literal-define';
import {CwBusClass} from '../model/bus';
import {CwRestRequestParametersI} from '../tool/rest/model';
import {CwSessionTool} from '../tool/session/session.tool';
import {SafeResourceUrl} from '@angular/platform-browser';
import {CwThemeConfig} from '../tool/theme/model';
import {CwThemeTool} from '../tool/theme/theme';
import {Observable} from 'rxjs';
import {EventEmitter} from '@angular/core';


/**
 * @version 1911181024
 * @description
 * https://github.com/ngx-translate/core
 */
export class CwToolLiteralClass extends CwBusClass implements CwLanguageI {

  RtlCssUrl: SafeResourceUrl;
  direction = CwLiteralConfig.Direction.DEFAULT;

  get isRtl(): boolean {
    return (this.direction === CwLiteralConfig.Direction.RTL);
  }

  loading = true;
  LangChangePending: any[] = [];

  onLangChange: any;
  onLangChange$: Observable<any>;
  onTranslateServiceChange$: Observable<any>;
  LangSelected: EventEmitter<any> = new EventEmitter();

  // 1905141531
  get getIsRtl(): boolean {
    return (
      CwLiteral &&
      CwLiteral.Language &&
      CwLiteral.Language.rtl &&
      true
    );
  }

  get getOrientation(): string {
    if (this.getIsRtl) {
      return 'rtl';
    }
    return 'ltr';
  }

  _enable = true;
  disabled = (() => this._enable = false);
  enabled = (() => this._enable = true);

  Language: CwLiteralLanguageI = CwLiteralConfig.Language.DEFAULT;
  /**
   * @override 1904240944
   */
  ItemsKey = CwLiteralConfig.key;

  apiBus = true;

  set LanguageSelected(Value: CwLiteralLanguageI) {
    if (
      Value &&
      true
    ) {
      if (CwSessionTool) {
        Value.email = CwSessionTool.email;
      }
      this.languageSelectedDoList();
      this._use(Value);
      this.Language = Value;
      this.LangSelected.emit(Value);
      if (
        CwLiteral &&
        CwLiteral.Keep &&
        true
      ) {
        CwLiteral.Keep.Keep = Value;
      }
    }
  }


  DefineProperties: CwFunction[] = CwLiteralD.Properties;
  Setup: CwLiteralSetupI;
  SuccessActionList: any[];
  SuccessActionOnceList: any[];
  TokenBus: CwTokenBusI;

  defineUrl: CwDefineUrl = (() =>
      CwLiteralConfig.url +
      CwWeb.notCache()
  );

  do: CwFunction = ((ParametersRest: CwRestRequestParametersI) => {
    this.loading = true;
    if (!ParametersRest) {
      ParametersRest = {
        Bus: this
      };
    } else if (!ParametersRest.Bus) {
      ParametersRest.Bus = this;
    }
    CwLiteral.restGet(ParametersRest);
  });

  // 1901181612
  Keep: CwToolKeepC<any> = new CwToolKeepC({
    key: CwLiteralConfig.keepKey,
    defineData: (
      (Data) => {
        return <CwLiteralLanguageI>{
          language: Data.language,
          rtl: Data.rtl,
          country: Data.country,
          email: Data.email,
          name: Data.name,
        };
      }
    )
  });

  /**
   * @version 1905281217
   */
  languageIsEnabled: CwFunction = ((Parameters: CwMapItemI): boolean => {
      if (!this._enable) {
        return false;
      }
      let _languageIsEnabled = false;
      if (
        CwLiteral &&
        CwLiteral.loading &&
        Parameters &&
        Parameters.cwKey &&
        true
      ) {
        CwLiteral.LangChangePending.push(Parameters.cwKey);
      } else {

        if (Parameters && Parameters.cwKey) {
          const _Item = CwLiteral.Items.get(Parameters.cwKey);
          if (
            _Item &&
            CwLiteral &&
            true
          ) {
            this.defineRtlUrl(_Item);
            CwLiteral.LanguageSelected = _Item;
            _languageIsEnabled = true;
          }
        }
      }
      return _languageIsEnabled;
    }
  );

  change: CwFunction = this.languageIsEnabled;

  /**
   * @version 1911181024
   */
  logic = ((): void => {
    CwLiteral.loading = false;
    if (
      CwLiteral &&
      CwLiteral.LangChangePending &&
      CwLiteral.LangChangePending.length > 0 &&
      true
    ) {
      while (CwLiteral.LangChangePending.length > 0) {
        const cwKey = CwLiteral.LangChangePending.shift();
        if (cwKey) {
          CwLiteral.languageIsEnabled({cwKey});
        }
      }
    } else {
      if (
        CwLiteral &&
        CwLiteral.Keep &&
        CwLiteral.Keep.Keep &&
        CwLiteral.Keep.Keep[this.ItemsKey] &&
        CwSessionTool &&
        CwSessionTool.email &&
        CwSessionTool.email === CwLiteral.Keep.Keep.email &&
        true
      ) {
        const Keep = CwLiteral.Keep.Keep;
        Keep.cwKey = CwLiteral.Keep.Keep[this.ItemsKey];
        CwLiteral.languageIsEnabled(Keep);
      } else if (
        CwLiteral &&
        CwLiteral.Keep &&
        CwLiteral.Keep.Keep &&
        CwLiteral.Keep.Keep.cwKey &&
        CwLiteral.languageIsEnabled(CwLiteral.Keep.Keep) &&
        true
      ) {
      } else if (
        CwLiteral &&
        CwLiteral.Setup &&
        CwLiteral.Setup.Service &&
        CwLiteral.Setup.Service.getBrowserLang &&
        CwLiteral.Setup.Service.getBrowserLang() &&
        CwLiteral.languageIsEnabled({
          cwKey: CwLiteral.Setup.Service.getBrowserLang()
        }) &&
        true
      ) {

      } else if (
        // TODO-1907110924
        // environment &&
        // environment.Literal &&
        // environment.Literal.LanguageDefault &&
        // environment.Literal.LanguageDefault.language &&
        // CwLiteral.languageIsEnabled({
        //     cwKey: environment.Literal.LanguageDefault.language
        // }) &&
        true
      ) {

      } else if (
        CwLiteral &&
        CwLiteral.ItemsList &&
        CwLiteral.ItemsList[0] &&
        CwLiteral.languageIsEnabled(CwLiteral.ItemsList[0]) &&
        true
      ) {

      }

    }
  });

  public translate = ((...Literals: CwLiteralT[]): any => {
    const TranslateList = [];
    if (Literals) {
      Literals.forEach(LiteralItem => {
        TranslateList.push(this.translateOne(LiteralItem));
      });
    }
    return (1 === TranslateList.length)
      ? TranslateList[0]
      : TranslateList;
  });

  public translateOne = ((Literal: CwLiteralT): string => {
    let out = '';
    const _Lit: CwLiteralI = {
      CwLitValue: undefined,
      CwLitParam: {}
    };
    if (
      Literal &&
      CwLiteral.Setup &&
      CwLiteral.Setup.Service &&
      true
    ) {
      if (typeof Literal === 'string') {
        _Lit.CwLitValue = Literal;
      } else if (Literal instanceof CwLiteralC) {
        _Lit.CwLitValue = Literal.CwLitValue;
        _Lit.CwLitParam = Literal.CwLitParam;
      } else if (Literal.CwLitValue) {
        _Lit.CwLitValue = Literal.CwLitValue;
        if (Literal.CwLitParam) {
          _Lit.CwLitParam = Literal.CwLitParam;
        }
      }

      if (_Lit.CwLitValue) {
        CwLiteral.Setup.Service.get(
          _Lit.CwLitValue,
          _Lit.CwLitParam
        )
          .subscribe((res) => {
            out = res;
            if (
              typeof Literal !== 'string' &&
              Literal.CwLitTarget &&
              true
            ) {
              Literal[Literal.CwLitTarget] = res;
            }
          });
      }
    }
    return out;
  });

  private _use(Value: CwLiteralLanguageI): void {
    if (
      this.Setup.Service &&
      Value &&
      this.ItemsKey &&
      Value[this.ItemsKey] &&
      true
    ) {
      this.Setup.Service.use(Value[this.ItemsKey]);
    }
  }

  public languageSelectedDoList() {
    if (
      CwLiteral.Setup &&
      CwLiteral.Setup.LanguageSelectedToDoList &&
      true
    ) {
      CwUtil.doList(CwLiteral.Setup.LanguageSelectedToDoList);
    }
  }

  setup(_Setup: any) {
    super.setup(_Setup);
    CwLiteral.defineOnLangChange();
  }

  defineRtlUrl = (Parameters) => {
    this.RtlCssUrl = (
      Parameters &&
      Parameters.rtl &&
      CwWeb &&
      true
    )
      ? CwWeb.url(CwLiteralConfig.Rtl.urlCss)
      : undefined
    ;
    this.direction = (this.RtlCssUrl)
      ? CwLiteralConfig.Direction.RTL
      : CwLiteralConfig.Direction.LTR
    ;
    if (this.RtlCssUrl) {
      CwThemeTool.CssUrl = CwWeb.url(CwThemeConfig.cssUrl);
    }
  }


  // 1905221727
  defineOnLangChange(): void {
    if (
      CwLiteral &&
      CwLiteral.Setup &&
      CwLiteral.Setup.Service &&
      true
    ) {
      CwLiteral.onLangChange$ = CwLiteral.LangSelected;
      CwLiteral.onTranslateServiceChange$ = CwLiteral.Setup.Service.onLangChange;
      CwLiteral.onLangChange = CwLiteral.Setup.Service.onLangChange;
    }

  }

}

export const CwLiteral = new CwToolLiteralClass();
