<style>
    .cw-language {
      width: auto!important;
    }

    .language-flag {
        width: 20px;
        height: 20px;
    }

    .language-text {
        font-size: small;
    }
    /*1905221301*/
    .ui-dropdown {
      width: auto!important;
    }

</style>

<ng-container *ngIf="Bus.Language">

    <p-dropdown
            class="cw-language"
            [options]="Bus.ItemsList"
            (onChange)="onChange($event)"
            [(ngModel)]="Bus.Language"
    >
        <ng-template let-language pTemplate="selectedItem">

            <ng-container
                    *ngTemplateOutlet="LanguageTemplate;context:{language:language}">
            </ng-container>

        </ng-template>

        <ng-template let-language pTemplate="item">

            <ng-container
                    *ngTemplateOutlet="LanguageTemplate;context:{language:language}">
            </ng-container>

        </ng-template>

    </p-dropdown>
</ng-container>

<ng-template #LanguageTemplate let-language="language">
<!--    1905211224-->
    <div class="d-flex flex-row align-items-center justify-content-start">
        <div class="d-flex">
            <img
                    class="rounded-circle  pr-1  language-flag"
                    [src]="language?._flag"
            />
        </div>
        <div class="language-text d-block xxx-1910231643">
            <div class="d-flex">{{language?.name}}</div>
        </div>
    </div>
</ng-template>

