import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Grid2Component} from '@cw/6.0/components/grid2/component';
import {CwGrid2ItemTextComponent} from '@cw/6.0/components/grid2/items/text/component';
import {CwGrid2TemplateFieldDirective} from '@cw/6.0/components/grid2/directive';
import {TranslateModule} from '@ngx-translate/core';
import {CwGrid2ItemComponent} from '@cw/6.0/components/grid2/items/component';
import {CwGrid2FilterPipe} from '@cw/6.0/components/grid2/pipes/filter.pipe';
import {CwGrid2Pipe} from '@cw/6.0/components/grid2/pipes/pipe';
import {CwGrid2OrderByPipe} from '@cw/6.0/components/grid2/pipes/orderBy.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [
    Grid2Component,
    CwGrid2ItemTextComponent,
    CwGrid2TemplateFieldDirective,
    CwGrid2ItemComponent,
    CwGrid2FilterPipe,
    CwGrid2OrderByPipe,
    CwGrid2Pipe,
  ],
  entryComponents: [
    CwGrid2ItemTextComponent,
  ],
  exports: [
    Grid2Component,
    CwGrid2ItemTextComponent,
    CwGrid2ItemComponent,
    CwGrid2FilterPipe,
    CwGrid2OrderByPipe,
    CwGrid2Pipe,
  ]
})
export class CwComponentGrid2Module {
}
