<div class="mt-4">

  <div class="row">
    <div class="app-view-rates">
      <app-rates-call-group
        [CallGroup]="CwInfoPhoneRatesBus.getItemListAmericaTop"
        nameGroup="NA"
        showAllVisible="true"
      ></app-rates-call-group>
    </div>
    <div class="app-view-rates">
      <app-rates-call-group
        [CallGroup]="CwInfoPhoneRatesBus.getItemListEuropeTop"
        nameGroup="EU"
        showAllVisible="true"
      ></app-rates-call-group>
    </div>
    <div class="app-view-rates">
      <app-rates-call-group
        [CallGroup]="CwInfoPhoneRatesBus.getItemListAfricaTop"
        nameGroup="AF"
        showAllVisible="true"
      ></app-rates-call-group>
    </div>
    <div class="app-view-rates">
      <app-rates-call-group
        [CallGroup]="CwInfoPhoneRatesBus.getItemListAsiaTop"
        nameGroup="AS"
        showAllVisible="true"
      ></app-rates-call-group>
    </div>
  </div>
</div>

<div class="d-flex pt-4 h5" translate>Rate may vary...</div>
