import {Component} from '@angular/core';
import {TimeFormatHelper} from '../../helpers/time-format.helper';
import {CwLiteral} from '../../../CollaborateSpaceWebTools/literal/literal';
import {CwFileTool} from '../../../CollaborateSpaceWebTools/tool/file/file.tool';
import {CwModalComponent} from '../../../CollaborateSpaceWebTools/components/modal/model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-export-csv-modal',
  templateUrl: './export-csv-modal.component.html',
  styleUrls: ['./export-csv-modal.component.scss']
})
/**
 * @version 1910311256
 */
export class ExportCsvModalComponent
  extends CwModalComponent {

  Inputs: any;

  exportCsv() {
    const fileName = CwLiteral.translate(this.ModalComponent.ExtraParams.fileNamePrefix) +
      TimeFormatHelper.dateWithFormat(new Date(), CwFileTool.Csv.DATE_FORMAT) + '.csv';
    this.ModalComponent.GridComponent.exportToFile({fileName: fileName, extraData: this.ModalComponent.ExtraParams.extraData});
    this.ModalComponent.close();
  }

}
