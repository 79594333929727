import {CW_PROTOCOL_TOOL} from './model';
import {
  CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT_DEFAULT,
  CW_PROTOCOL_FREE_WINDOWS_FIREFOX_CHECK,
  CW_PROTOCOL_FREE_WINDOWS_FIREFOX_DOWNLOAD
} from './tool-protocol-action';

export const CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT = new Map()
  .set(CW_PROTOCOL_TOOL.ACTION.DEFAULT, CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT_DEFAULT)
  .set(CW_PROTOCOL_TOOL.ACTION.CHECK, {})
  .set(CW_PROTOCOL_TOOL.ACTION.DOWNLOAD, {})
;

export const CW_PROTOCOL_DEFAULT_DEFAULT_FIREFOX = new Map()
  .set(CW_PROTOCOL_TOOL.ACTION.DEFAULT, CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT_DEFAULT)
  .set(CW_PROTOCOL_TOOL.ACTION.CHECK, {})
  .set(CW_PROTOCOL_TOOL.ACTION.DOWNLOAD, {})
;

export const CW_PROTOCOL_FREE_WINDOWS_DEFAULT = new Map()
  .set(CW_PROTOCOL_TOOL.ACTION.DEFAULT, CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT_DEFAULT)
  .set(CW_PROTOCOL_TOOL.ACTION.CHECK, {})
  .set(CW_PROTOCOL_TOOL.ACTION.DOWNLOAD, {})
;

export const CW_PROTOCOL_FREE_WINDOWS_FIREFOX = new Map()
  .set(CW_PROTOCOL_TOOL.ACTION.DEFAULT, CW_PROTOCOL_DEFAULT_DEFAULT_DEFAULT_DEFAULT)
  .set(CW_PROTOCOL_TOOL.ACTION.CHECK, CW_PROTOCOL_FREE_WINDOWS_FIREFOX_CHECK)
  .set(CW_PROTOCOL_TOOL.ACTION.DOWNLOAD, CW_PROTOCOL_FREE_WINDOWS_FIREFOX_DOWNLOAD)
;
