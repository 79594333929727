import {CwFunction} from '../model/model';
import {CwDefine} from '../tool/define/tool-define';

/**
 * @version 1904240944
 */

class LiteralDefine {
    Properties: CwFunction[] = [
        // flag
        CwDefine.flag,
        // Spanish service
        (Properties: any) => {
            if (
                Properties &&
                Properties.lenguaje &&
                true
            ) {
                Properties.language = Properties.lenguaje;
                Properties.cwKey = Properties.lenguaje;
            }
        },
        // dropdown - label
        (Properties: any) => {
            if (
                Properties &&
                Properties.name &&
                true
            ) {
                Properties.label = Properties.name;
            } else if (
                Properties &&
                Properties.nombre &&
                true
            ) {
                Properties.label = Properties.nombre;
                Properties.name = Properties.nombre;
            }
        },
        // dropdown - value
        (Properties: any) => {
            if (
                Properties &&
                true
            ) {
                Properties.value = Properties;
            }
        },
    ];
}

/**
 * @version 1904101050
 */
export const CwLiteralD = new LiteralDefine();
