import {VersionBusClass} from '../version-bus';
import {CwDefineUrl, CwFunction} from '../../../model/model';
import {CwRestRequestMinParametersI} from '../../../tool/rest/model';

class CproversionBus extends VersionBusClass {

  Version = {
    major: 0,
    minor: 0,
    build: 0,
    revision: 0,
  };

  DefineProperties: CwFunction[] = [
    ((Properties) => {
      if (Properties.version) {
        const VersionList = Properties.version.split('.');
        if (VersionList && 4 === VersionList.length) {
          this.Version.major = VersionList[0];
          this.Version.minor = VersionList[1];
          this.Version.build = VersionList[2];
          this.Version.revision = VersionList[3];
        }
      }
    })
  ];

  defineUrl: CwDefineUrl = (() =>
      this.path + 'CProVersion'
  );

  do: CwFunction = ((Param: CwRestRequestMinParametersI) => {
    this.restGet({
      ...Param,
      Bus: this,
      // 2005210000
      // token: CwTrust.token,
    });
  })

}

/**
 * @version
 */
export const CwFirmwareBus = new CproversionBus({
  repeatTimeInSeconds: 60,
});
