<div class="d-flex flex-wrap justify-content-center">

  <ng-content select="[R]"></ng-content>

</div>


<div class="d-flex flex-wrap justify-content-center">

  <ng-content select="[RF]"></ng-content>

</div>


<div class="d-flex flex-wrap justify-content-center">

  <ng-content select="[R4]"></ng-content>

</div>

<div class="d-flex flex-wrap justify-content-center">

  <ng-content select="[R2]"></ng-content>

</div>

<!--<div class="d-block d-md-none d-lg-none d-xl-block">-->
<!--  <div class="d-flex flex-wrap justify-content-center">-->

<!--    <ng-container *ngTemplateOutlet="Rx1Template"></ng-container>-->
<!--    <ng-container *ngTemplateOutlet="Rx2Template"></ng-container>-->
<!--    <ng-container *ngTemplateOutlet="Rx3Template"></ng-container>-->
<!--    <ng-container *ngTemplateOutlet="Rx4Template"></ng-container>-->

<!--  </div>-->
<!--</div>-->

<!--<div class="d-none d-md-block d-lg-block d-xl-none">-->
<!--  <div class="d-flex flex-wrap justify-content-center">-->
<!--    <ng-container *ngTemplateOutlet="Rx1Template"></ng-container>-->
<!--    <ng-container *ngTemplateOutlet="Rx2Template"></ng-container>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="d-none d-md-block d-lg-block d-xl-none">-->
<!--  <div class="d-flex flex-wrap justify-content-center">-->
<!--    <ng-container *ngTemplateOutlet="Rx3Template"></ng-container>-->
<!--    <ng-container *ngTemplateOutlet="Rx4Template"></ng-container>-->
<!--  </div>-->
<!--</div>-->

<!--<ng-template #Rx1Template>-->
<!--    <ng-content select="[RX1]"></ng-content>-->
<!--</ng-template>-->

<!--<ng-template #Rx2Template>-->
<!--    <ng-content select="[RX2]"></ng-content>-->
<!--</ng-template>-->

<!--<ng-template #Rx3Template>-->
<!--    <ng-content select="[RX3]"></ng-content>-->
<!--</ng-template>-->

<!--<ng-template #Rx4Template>-->
<!--    <ng-content select="[RX4]"></ng-content>-->
<!--</ng-template>-->
