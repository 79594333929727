import {CwIs} from '../tool-is';
import {CW_WEB_TOOL, CwWebKeepI, CwWebLoadJsI, CwWebOSI, CwWebSetupI} from './web.model';
import {CwSafe} from '../tool-safe';
import {CwToolKeepC} from '../keep/tool-keep';
import {CwFunction} from '../../model/model';
import {CwUtil} from '../util/cw-tool-util';
import {CwVersionCheckPlatform} from '../../version/check/check-model';
import {ToolProtocol} from '../protocol/tool-protocol';
import {formatCurrency} from '@angular/common';
import {SafeResourceUrl} from '@angular/platform-browser';
import {Observable, Subject} from 'rxjs';

/**
 * @version 1911121011
 */
export class CwToolWebClass {


  private _OrientationSubject$ = new Subject();

  get Orientation$(): Observable<any> {
    return this._OrientationSubject$.asObservable();
  };

  private _SizeSubject$ = new Subject();

  get Size$(): Observable<any> {
    return this._SizeSubject$.asObservable();
  };

  get Size(): any {
    return {
      Screen: {
        width: screen.width,
        height: screen.height,
      },
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }


  Setup: CwWebSetupI;

  macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  loaderVisible = false;

  // 1911071046
  // https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
  // https://www.npmjs.com/package/ngx-device-detector
  isIE = ((): boolean => {
    return /*@cc_on!@*/false || !!document['documentMode'];
  });

  isEdge = ((): boolean => {
    return !this.isIE() && !!window['StyleMedia'];
  });

  isMobile = false;
  isIpad = false;
  isFirefox = false;
  isPortrait = false;
  isDesktop = false;
  isSafari = false;
  isTablet: boolean = false;
  isInternetExplorer = false;

  checkIsInternetExplorer = (() => {
    this.isInternetExplorer = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      CW_WEB_TOOL.BROWSER.IE === this.Setup.DeviceDetectorService.browser.toLowerCase() &&
      true
    );
  });


  /**
   * @override 1911200945
   * @param _Setup
   */
  setup(_Setup: CwWebSetupI) {
    this.Setup = _Setup;
    this.checkIsMobile();
    this.checkIsIpad();
    this.checkIsFirefox();
    this.checkIsDesktop();
    this.checkIsTablet();
    this.checkIsSafari(); // 2009290000.B5
    this.checkOrientation();
    this.checkSize();
    this.checkIsInternetExplorer();
  }

  sizeEventListener: boolean = false;
  checkSize = (() => {
    if (!this.sizeEventListener) {
      window.addEventListener(
        'resize',
        (() => {
          this._SizeSubject$.next();
        }),
        false
      );
      this.sizeEventListener = true;
    } else {
      this._SizeSubject$.next();
    }
  });

  checkIsTablet = (() => {
    this.isTablet = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      this.Setup.DeviceDetectorService.isTablet() &&
      true
    );
  });

  checkIsDesktop = (() => {
    this.isDesktop = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      this.Setup.DeviceDetectorService.isDesktop() &&
      true
    );
    if (this.Setup.DeviceDetectorService['browserVersion'] === '13.0.1' &&
      this.Setup.DeviceDetectorService.os_version === 'mac-os-x-15') {
      this.isDesktop = false;
    }
  });

  isPortraitEventListener: boolean = false;
  // 1912021548
  checkOrientation = (() => {
    const angle = (screen['orientation'])
      ? screen['orientation'].angle
      : window.orientation
    ;
    this.isPortrait = !(angle === 90 || angle === -90);
    if (
      this.isPortrait &&
      this.Size &&
      this.Size.Screen &&
      this.Size.Screen.width > this.Size.Screen.height &&
      true
    ) {
      this.isPortrait = false;
    }
    if (!this.isPortraitEventListener) {
      window.addEventListener(
        'orientationchange',
        (() => {
          this.checkOrientation();
          this.checkSize();
          this._OrientationSubject$.next();
        }),
        false
      );
      this.isPortraitEventListener = true;
    }

  });

  checkIsFirefox = (() => {
    this.isFirefox = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      CW_WEB_TOOL.BROWSER.FIREFOX === this.Setup.DeviceDetectorService.browser.toLowerCase() &&
      true
    );
  });

  checkIsMobile = (() => {
    this.isMobile = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      this.Setup.DeviceDetectorService.isMobile() &&
      true
    );
  });

  // 2009290000.B5
  checkIsSafari = (() => {
    this.isSafari = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      CW_WEB_TOOL.BROWSER.SAFARI === this.Setup.DeviceDetectorService.browser.toLowerCase() &&
      true
    );
  });

  checkIsIpad = (() => {
    this.isIpad = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      CW_WEB_TOOL.DEVICE.IPAD === this.Setup.DeviceDetectorService.device.toLowerCase() &&
      true
    );
  });


  getToken(Param: CwWebSetupI): any {
    return CwWeb.getParam({
      ...Param,
      paramName: 'token',
    });
  }

  getParam(Param: CwWebSetupI): any {
    let value: any;
    if (
      Param &&
      Param.paramName &&
      Param.ActivatedRoute &&
      Param.ActivatedRoute.snapshot &&
      Param.ActivatedRoute.snapshot.paramMap &&
      true
    ) {
      value = Param.ActivatedRoute.snapshot.paramMap.get(Param.paramName);
      if (!value) {
        value = Param.ActivatedRoute.snapshot.queryParamMap.get(Param.paramName);
      }
    }
    return value;
  }

  cleanQueryParams(queryParamsArray: any[]) {
    if (
      queryParamsArray &&
      queryParamsArray.length > 0 &&
      CwWeb.Setup &&
      CwWeb.Setup.Router &&
      CwWeb.Setup.Router.navigate &&
      true
    ) {
      const queryParams = [];
      for (const param of queryParamsArray) {
        queryParams[param] = null;
      }
      CwWeb.Setup.Router.navigate([], {
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
    }

  }

  /**
   * @version 1909051500
   * @param Params
   */
  goTo(Params: {
    path: string,
    external?: boolean,
    queryParams?: any,
    byUrl?: boolean,
  }): void {
    if (Params && Params.path) {
      if (
        Params.external &&
        window &&
        window.location &&
        true
      ) {
        window.location.href = CwSafe.get(Params.path);
      } else if (
        CwWeb &&
        CwWeb.Setup &&
        CwWeb.Setup.Router &&
        CwWeb.Setup.Router.navigate &&
        Params &&
        Params.path &&
        !Params.byUrl &&
        true
      ) {
        if (!Params.queryParams) {
          Params.queryParams = {};
        }
        CwWeb.Setup.Router.navigate(
          [CwSafe.get(Params.path)],
          {queryParams: Params.queryParams}
        )
          .then((res) => {
          })
          .catch((error) => {
            console.log(error);
            throw new Error('1902041245');
          })
        ;
      } else if (
        CwWeb &&
        CwWeb.Setup &&
        CwWeb.Setup.Router &&
        CwWeb.Setup.Router.navigateByUrl &&
        Params &&
        Params.path &&
        Params.byUrl &&
        true
      ) {
        if (!Params.queryParams) {
          Params.queryParams = {};
        }
        CwWeb.Setup.Router.navigateByUrl(
          CwSafe.get(Params.path),
          {queryParams: Params.queryParams}
        );
      }
    }
  }

  goToAdmin(token: string): void {
    if (token) {
      CwWeb.goTo({
        path: '/admin/#/' +
          CwUtil.uuid() +
          '/' +
          token +
          '',
        external: true,
      });
    }
  }

  /**
   * @version 1908270820
   * @param Param
   */
  public cacheBusting(Param?: {
    value?: string,
    QueryParams?: any,
  }): string {
    const _notCache = '?cacheBusting=' + new Date().getTime();
    if (Param && Param.QueryParams) {
      Object.defineProperty(
        Param.QueryParams,
        'cacheBusting',
        {
          value: new Date().getTime(),
          configurable: true,
          enumerable: true,
          // writable: true,
        }
      );
    }
    return (Param && Param.value)
      ? Param.value + _notCache
      : _notCache;
  }

  /**
   * @version 0.3.2 - The literal project json files unify - 1810151342
   * @description
   * https://github.com/ngx-translate/http-loader/issues/25
   */
  public notCache(value: string = null): string {
    const _notCache = '?cacheBusting=' + new Date().getTime();
    return (value)
      ? value + _notCache
      : _notCache;
  }

  public keepGet(Param: CwWebKeepI<any>): boolean {
    let _get = false;
    try {
      if (Param &&
        Param.key &&
        CwIs.defined(typeof (Storage)) &&
        true
      ) {
        // R24.1 - Users - Set Admins - It does not opened the permissions.
        const _data = localStorage.getItem(CwSafe.get(Param.key));
        if (CwIs.defined(_data)) {
          Param.Data = JSON.parse(CwSafe.get(_data));
          _get = true;
        }
      }
    } catch (error) {
      _get = false;
    }
    return _get;
  }

  // 1901181612
  public keepSet(Param: CwWebKeepI<any>): boolean {
    let _set = false;
    try {
      if (
        Param &&
        Param.key &&
        CwIs.defined(typeof (Storage)) &&
        true
      ) {
        if (CwIs.defined(Param.Data)) {
          Param.DataSafe = CwSafe.set(JSON.stringify(Param.Data));
        }
      }
      localStorage.setItem(CwSafe.get(Param.key), Param.DataSafe);
    } catch (error) {
      _set = false;
      // throw new TypeError();
    }
    return _set;
  }

  public keepRemove(Param: CwWebKeepI<any>): void {
    try {

      if (Param &&
        Param.key &&
        CwIs.defined(typeof (Storage)) &&
        true
      ) {
        localStorage.removeItem(
          CwSafe.get(Param.key)
        );
      }
    } catch (error) {
      // throw new TypeError();
    }
  }

  /**
   * @version 1.12.0 - RPM - Js and Css - 1902051520
   */
  public loadJs(Param: CwWebLoadJsI): void {
    if (
      Param &&
      Param.UrlList &&
      Param.UrlList[0] &&
      Param.condition &&
      true
    ) {
      Param.UrlList.forEach(urlItem => {
        const node = document.createElement('script');
        node.src = urlItem;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        node['crossOrigin'.toLowerCase()] = 'anonymous';
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }
  }

  public loadCss(callback: CwFunction, ...urls) {
    if (urls) {
      let count = 0;
      urls.forEach((urlItem) => {
        const element = document.createElement('link');
        element.rel = 'stylesheet';
        element.href = urlItem;
        if (callback) {
          element.addEventListener('load', function () {
            count++;
            if (count === urls.length) {
              callback(urls);
            }
          });
        }
        document.getElementsByTagName('head')[0].appendChild(element);
      });
    }
  }

  getPlatform(): CwVersionCheckPlatform {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    let _platform: CwVersionCheckPlatform = CwVersionCheckPlatform.other;

    if (CwWeb.macosPlatforms.indexOf(platform) !== -1) {
      _platform = CwVersionCheckPlatform.mac;
    } else if (CwWeb.iosPlatforms.indexOf(platform) !== -1) {
      _platform = CwVersionCheckPlatform.ios;
    } else if (CwWeb.windowsPlatforms.indexOf(platform) !== -1) {
      _platform = CwVersionCheckPlatform.windows;
    } else if (/Android/.test(userAgent)) {
      _platform = CwVersionCheckPlatform.android;
    }

    return _platform;
  }

  getOS(): string {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    let os = null;

    if (CwWeb.macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (CwWeb.iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (CwWeb.windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;

  }

  loaderShow(): void {
    this.loaderVisible = true;
  }

  loaderHide(): void {
    this.loaderVisible = false;
  }

  /**
   * @version 1906051731
   */
  htmlClassAdd(Param: CwWebHtmlClassAddI) {
    if (
      Param &&
      Param.ClassList &&
      Param.HtmlElement &&
      true
    ) {
      if (!Param.HtmlElement.classList) {
        // DOMTokenList
        Param.HtmlElement.classList = new DOMTokenList();
        // Param.HtmlElement.classList = [];
      }
      // IE not support ...
      Param.ClassList.forEach(
        classItem => {
          if (
            classItem &&
            // Not exists
            !Param.HtmlElement.classList.contains(classItem) &&
            true
          ) {
            Param.HtmlElement.classList.add(classItem);
          }
        }
      );
    }
  }


  htmlHas(Param: CwWebHasI): boolean {
    return (
      // Edge
      (
        Param &&
        Param.name &&
        Param.HtmlElement &&
        Param.HtmlElement.hasAttribute &&
        Param.HtmlElement.hasAttribute(Param.name.toLowerCase()) &&
        true
      ) ||
      false
    );
  }

  /**
   * @version 1906261144
   * @param Param
   * TODO: sleep depends of OS
   */
  checkAndSignIn(Param: CwWebCheckSignIn): void {
    if (Param && Param.Protocol) {
      if (Param.token) {
        Param.Protocol.check({
          token: Param.token
        });
        const timeout = setTimeout(() => {
          if (Param.admin) {
            CwWeb.goToAdmin(Param.token);
          } else if (Param.userDo) {
            Param.userDo();
          }
          if (Param.KeepToken) {
            Param.KeepToken.Keep = Param.token;
          }

        }, 2000);
      }
    }

  }

  public currency(
    _amount: number,
    _currency: string,
    _locale = 'en',
    _currencySymbol = '$',
  ): string {
    return formatCurrency(
      _amount / 100,
      _locale,
      _currencySymbol,
      (_currency && _currency.toUpperCase)
        ? _currency.toUpperCase()
        : ''
    );
  }

  public currencyWithValue(value: number): string {
    const formatter = new Intl.NumberFormat(
      'USD',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        // the default value for minimumFractionDigits depends on the currency
        // and is usually already 2
      });
    return formatter.format(value);
    /* $2,500.00 */
  }


  // 1907030844
  getOperativeSystem(): CwWebOSI {
    const isAndroid = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'ANDROID' === this.Setup.DeviceDetectorService.os.toUpperCase() &&
      true
    );
    const isIOS = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'IOS' === this.Setup.DeviceDetectorService.os.toUpperCase() &&
      true
    );
    const isMac = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'MAC' === this.Setup.DeviceDetectorService.os.toUpperCase() &&
      true
    );
    const isWindows = (
      this.Setup &&
      this.Setup.DeviceDetectorService &&
      'WINDOWS' === this.Setup.DeviceDetectorService.os.toUpperCase() &&
      true
    );
    return {
      isAndroid,
      isIOS,
      isMac,
      isWindows,
    };
  }

  // 1910041310
  tabShow(index = 1) {
    const Tabs: any = $('#TabUsers li:nth-child(' + index + ') a');
    if (Tabs && Tabs.tab) {
      Tabs.tab('show');
    }
  }

  isTextOverflow = ((Html: HTMLElement): boolean => {
    let overflow = false;
    if (Html) {
      const _offsetWidth =
        (this.isIE() || this.isEdge())
          ? Html.offsetWidth + 1
          : Html.offsetWidth
      ;
      overflow = _offsetWidth < Html.scrollWidth;
    }
    return overflow;
  });

  url = ((urlString: string): SafeResourceUrl => {
    return (
      urlString &&
      this.Setup &&
      // this.Setup.DomSanitizer &&
      true
    )
      ? this.Setup.DomSanitizer.bypassSecurityTrustResourceUrl(urlString)
      : undefined;
  });


  Keep = {
    clear: (() => {
      this.Keep.remove({key: CW_WEB_TOOL.KEEP.KEY.SPACE});
      this.Keep.remove({key: CW_WEB_TOOL.KEEP.KEY.SPACE_KEEP_ME_LOGGED_IN});
      this.Keep.remove({key: CW_WEB_TOOL.KEEP.KEY.SPACE_TOKEN});
    }) // clear
    ,
    get: ((Param: CwWebKeepI<any>): boolean => {
      let _get = false;
      try {
        if (Param &&
          Param.key &&
          CwIs.defined(typeof (Storage)) &&
          true
        ) {
          // R24.1 - Users - Set Admins - It does not opened the permissions.
          const _data = localStorage.getItem(CwSafe.get(Param.key));
          if (CwIs.defined(_data)) {
            Param.Data = JSON.parse(CwSafe.get(_data));
          }
          _get = true;
        }
      } catch (error) {
        _get = false;
      }
      return _get;
    }) // get
    ,
    getData: ((key: string): any => {
      let _Keep: CwWebKeepI<any> = {key};
      this.Keep.get(_Keep);
      return _Keep.Data;
    }) // getData
    ,
    // 1901181612
    set: ((Param: CwWebKeepI<any>): boolean => {
      let _set = false;
      try {
        if (
          Param &&
          Param.key &&
          CwIs.defined(typeof (Storage)) &&
          true
        ) {
          if (CwIs.defined(Param.Data)) {
            Param.DataSafe = CwSafe.set(JSON.stringify(Param.Data));
          }
        }
        localStorage.setItem(CwSafe.get(Param.key), Param.DataSafe);
      } catch (error) {
        _set = false;
        // throw new TypeError();
      }
      return _set;
    }) // set
    ,
    remove: ((Param: CwWebKeepI<any>): void => {
      try {
        if (Param &&
          Param.key &&
          CwIs.defined(typeof (Storage)) &&
          true
        ) {
          localStorage.removeItem(
            CwSafe.get(Param.key)
          );
        }
      } catch (error) {
        // throw new TypeError();
      }
    }) // remove
    ,
  };

  Event = {
    stop: ((Event) => {
      if (Event && Event.preventDefault) {
        Event.preventDefault();
      }
      if (Event && Event.stopImmediatePropagation) {
        Event.stopImmediatePropagation();
      }
      if (Event && Event.stopPropagation) {
        Event.stopPropagation();
      }
    })
  };

}

export const CwWeb = new CwToolWebClass();

export interface CwWebCheckSignIn {
  token: string;
  admin: boolean;
  KeepToken?: CwToolKeepC<string>;
  Protocol: ToolProtocol;
  userDo?: CwFunction;
}

export interface CwWebHtmlI {
  // HtmlElement: HTMLElement;
  HtmlElement: any;
}

export interface CwWebHtmlClassAddI extends CwWebHtmlI {
  ClassList: string[];
}

export interface CwWebHasI extends CwWebHtmlI {
  name: string;
}
