<div class="text-left"
     style="font-size: 13px!important;">
  <ng-container
    *ngFor="let description of DescriptionList"
  >
    <ng-container
      *ngTemplateOutlet="templateByDescription(description);context:{description: description}"
    ></ng-container>
  </ng-container>
</div>


<ng-container #TEMPLATES>


  <ng-template #TemplateItem let-description="description">
    <div class="d-flex">

      <div class="icon icon-check pr-1"
           style="color: #f06623;"
      ></div>
      <div
        xxx-2005280951
        class="text-truncate text-wrap mb-3"
        [translate]="description"
      ></div>

    </div>
  </ng-template>


  <ng-template #TemplateSubitem let-description="description">
    <div
      xxx-2005280951
      class="pl-4  text-truncate text-wrap mb-3"
    >{{description|slice:2 | translate}}</div>
  </ng-template>

</ng-container>
