import {CwVersionCheckPlatform} from '../../version/check/check-model';
import {CwFunction} from '../../model/model';

// 2007150000 - CGIL
// export const COLLABORATE_PROTOCOL = 'futuralab://';
export const COLLABORATE_PROTOCOL = 'collaboratespace://';

export interface CwDownloadI {
  platform: CwVersionCheckPlatform;
  url: string
}

export interface CwProtocolCheck6I {
  action: PROTOCOL_ACTION;
  App: any;
}

export interface CwProtocolCheckI {
  token?: string;
  failCallback?: CwFunction;
  successCallback?: CwFunction;
  unsupportedCallback?: CwFunction;
  found?: CwFunction;
  notFound?: CwFunction;
}

// 1907030844
export enum PROTOCOL_ACTION {
  NOTHING,
  ACTIVATION,
  RESET,
  SIGN_IN,
}

enum CW_PROTOCOL_ACTION {
  DEFAULT = 'default',
  CHECK = 'check',
  DOWNLOAD = 'download',
}

export type CW_PROTOCOL_ACTION_MODEL = CW_PROTOCOL_ACTION;

enum CW_PROTOCOL_SOURCE {
  DEFAULT = 'default',
  FREE = 'free',
  // 2009210000
  INVITE = 'invite',
  INVITE_BY_APP = 'invite_BY_APP',
}

export type CW_PROTOCOL_SOURCE_MODEL = CW_PROTOCOL_SOURCE;

enum CW_PROTOCOL_DEVICE {
  DEFAULT = 'default',
  WINDOWS = 'windows',
}

enum CW_PROTOCOL_BROWSER {
  DEFAULT = 'default',
  FIREFOX = 'firefox',
}

/**
 * @version 2002141110
 */
export const CW_PROTOCOL_TOOL = {
  // 2003030920
  SOURCE: CW_PROTOCOL_SOURCE,
  DEVICE: CW_PROTOCOL_DEVICE,
  BROWSER: CW_PROTOCOL_BROWSER,
  ACTION: CW_PROTOCOL_ACTION,

  STATUS: {
    KEEP: 'CsProtocolCheckOpened',
    MEMORY_MIN: 2123104256,
  },
  RTC: 'CS_WEB_RTC',
  FIREFOX: {
    // KEEP : '##Q3NGaXJlZm94UHJvdG9jb2xJbnN0YWxsZWQ=',
    KEEP: 'CsFirefoxProtocolInstalled',
  }
};


