<div class="container app-purchase py-3 px-5">
  <div class="row justify-content-center h1">
    {{'Webinar registration' | translate}}
  </div>
  <div class="col-12 row my-2 h5 justify-content-center" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-12 row my-2 h5 justify-content-center" *ngIf="errorMessage">
            <span class="">
              {{errorMessage | translate}}
            </span>
    </div>
    <ng-container *ngIf="!errorMessage">
      <div class="col-md-9 row my-2 h5">
            <span>
              <span class="font-weight-bold" translate="Topic:"></span>
              {{WebinarInfo.name}}
            </span>
      </div>
      <div class="col-md-9 row my-2 h5" *ngIf="WebinarInfo.description">
            <span>
              <span class="font-weight-bold" translate="Description:"></span>
              {{WebinarInfo.description | translate}}
            </span>
      </div>
      <div class="col-md-9 row my-2 h5" *ngIf="WebinarInfo.nextSession">
            <span>
              <span class="font-weight-bold" translate="When:"></span>
              {{getNextSession | translate}}
            </span>
      </div>
      <img *ngIf="WebinarInfo.imageUrl" [src]="WebinarInfo.imageUrl" class="img-fluid">
      <hr>
      <ng-container *ngFor="let Item of InputSet">
        <div class="col-12 col-sm-12 col-md-6 px-2">
          <cw-input *ngIf="Item.label !== 'Country' && Item.label !== 'State'"
              [Item]="Item"
          ></cw-input>
          <ng-container *ngIf="Item.label === 'Country'">
              <div class="form-group">
                <label class="font-weight-bold text-dark"
                       for="country-field"
                    translate="Country"
                ></label>
                <span class="ml-1 h5 align-middle"
                      *ngIf="Item.labelRequired"
                >*</span>
                <app-cw-select-country
                    id="country-field"
                    name="country-field"
                    [(ngModel)]="Country"
                    (change)="onCountryChanged()"
                    ngDefaultControl
                ></app-cw-select-country>
              </div>
          </ng-container>
          <ng-container *ngIf="Item.label === 'State' && isCountrySelectedDefaultQuestions" >
              <div class="form-group">
                <label class="font-weight-bold text-dark"
                    translate="State"
                ></label>
                <span class="ml-1 h5 align-middle"
                      *ngIf="Item.labelRequired"
                >*</span>
                <app-cw-select2
                    [Setup]="StateSetup"
                    [(ngModel)]="State"
                    (change)="onStateChanged()"
                    ngDefaultControl
                ></app-cw-select2>
              </div>
          </ng-container>
          <ng-container *ngIf="Item.label === 'State' && !isCountrySelectedDefaultQuestions" >
            <cw-input
                [Item]="Item"
            ></cw-input>
          </ng-container>

        </div>
      </ng-container>
      <ng-container *ngFor="let Item of CustomQuestions">
        <ng-container *ngIf="Item.question.type === 0">
          <div class="col-12 col-sm-12 col-md-6 px-2">
            <cw-input
                [Item]="Item.Input"
            ></cw-input>
          </div>
        </ng-container>
        <ng-container *ngIf="Item.question.type === 1">
          <div class="form-group col-12 col-sm-12 col-md-6 px-2">
            <label class="font-weight-bold text-dark"
            >{{Item.question.text}}</label>
            <span class="ml-1 h5 align-middle" *ngIf="Item.required"
            >*</span>
            <app-cw-select2
                [Setup]="Item.Input.SelectSetup"
                [(ngModel)]="Item.Input.Select"
                (change)="Item.Input.changed()"
                ngDefaultControl
            ></app-cw-select2>
          </div>
        </ng-container>
        <ng-container *ngIf="Item.question.type === 2">
          <div class="form-group col-12 col-sm-12 col-md-12 px-2">
            <label class="font-weight-bold text-dark"
            >{{Item.question.text}}</label>
            <span class="ml-1 h5 align-middle" *ngIf="Item.required"
            >*</span>

            <div *ngFor="let answer of Item.question.answers">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-1 justify-content-end">
                    <input type="checkbox"
                           [(ngModel)]="answer.selected">
                  </div>
                  <span class="col-5 pb-1">
                    {{answer.text}}
                  </span>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="mb-3 row justify-content-center px-2" *ngIf="!loading && !errorMessage">
    <div class="col text-center">
      <button
          appCw
          appCwForm
          type="button"
          class="btn-secondary"
          role="button"
          [disabled]="getRegisterDisabled"
          (click)="saveWebinar()"
      >{{ 'Register' | translate | uppercase}}
      </button>
    </div>
  </div>
</div>
