import {CwBusClass} from '../model/bus';
import {CwToolKeepC} from '../tool/keep/tool-keep';
import {CwDefineUrl, CwFunction, CwSetupI, CwTokenBusI} from '../model/model';
import {CwOnP} from '../tool/on-premise/tool-on-premise';
import {CoComponentsGridBus, CoComponentsGridColumn} from '../../co/components/grid/model/grid.bus';
import {CwLicenseD} from './license-define';
import {CwPartnerEntities} from '../partner/entities/entities-bus';
import {CwRestRequestParametersI} from '../tool/rest/model';

export class LicenseBus extends CwBusClass {

  CoGridBus: CoComponentsGridBus;

  Plans: any[] = [];
  // 1904010955
  LicenseList: any[] = [];
  serverKey: string;
  installed: boolean;
  valid: boolean;
  DefineProperties: CwFunction[] = CwLicenseD.properties(this);
  Keep: CwToolKeepC<any>;
  Setup: CwSetupI;
  SuccessActionList: any[];
  SuccessActionOnceList: any[];

  TokenBus: CwTokenBusI;

  defineUrl: CwDefineUrl = (
    (): string => {
      return 'partner/getLicenseStatus/';
    }
  );

  refresh: CwFunction = (
    () => {
      if (
        CwLicense &&
        CwLicense.TokenBus &&
        CwLicense.TokenBus.token &&
        !CwLicense.serverKey &&
        true
      ) {
        CwLicense.do();
      }
    }
  );

  do: CwFunction = (
    () => {
      // 1905141531
      CwLicense.restGet(<CwRestRequestParametersI>{
        Bus: CwLicense,
        Parameters: {}
      });
      // CwLicense.restGet(CwLicense);
    }
  );

  get getAddable(): boolean {
    return (CwOnP.is)
      ? CwLicense &&
      CwLicense.Item &&
      CwLicense.Item.maxEntities &&
      CwPartnerEntities &&
      CwPartnerEntities.Items &&
      CwPartnerEntities.Items.size < CwLicense.Item.maxEntities &&
      // CwLicense.Setup &&
      // CwLicense.Setup.AccountsBus &&
      // CwLicense.Setup.AccountsBus.CoItemsMap &&
      // CwLicense.Setup.AccountsBus.CoItemsMap.size &&
      // CwLicense.Setup.AccountsBus.CoItemsMap.size < CwLicense.Item.maxEntities &&
      true
      : true;
  }

  get getEditable(): boolean {
    return (CwOnP.is)
      ? CwLicense &&
      CwLicense.installed &&
      CwLicense.valid &&
      true
      : true
      ;
  }


  constructor() {
    super();
    this.CoGridBus = new CoComponentsGridBus([
        new CoComponentsGridColumn(
          'Name',
          'name',
          'name'
        ),
        new CoComponentsGridColumn(
          'Licenses',
          'licenses',
          'licenses',
        ),
        new CoComponentsGridColumn(
          'Creation Date',
          'StartDateWeb',
          'StartDate',
        ),
        new CoComponentsGridColumn(
          'End Date',
          'EndDateWeb',
          'EndDate'
        ),
      ],
      // 1904010955
      this.LicenseList
      // this.Plans
    );

  }
}

export const CwLicense = new LicenseBus();
