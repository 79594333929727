import {CwRestRequestParametersI} from '../../../tool/rest/model';

export interface CwTokenGetWebI extends CwRestRequestParametersI {
  token: string;
}

/**
 * @version 1912101603
 */
export const CW_WEB_BUS = {
  MENU: {
    // 1912131221
    ACCOUNTS: {
      API: 'ACCOUNTS',
      LITERAL: 'Accounts',
      DEVELOP: {
        name: 'ACCOUNTS',
        order: 100,
        type: 'OPTION'
      },
    }
    ,
    DASHBOARD: {
      API: 'DASHBOARD',
      LITERAL: 'Dashboard',
    }
    ,
    // 2009290000
    CDRS: {
      API: 'CDRS',
      LITERAL: 'Call Details'
    },
    OAUTH2: {
      API: 'AUTHORIZATIONS',
      LITERAL: 'Authorizations',
      DEVELOP: {
        name: 'OAUTH2',
        order: 100,
        type: 'OPTION'
      },
    },
    PHONE_EXTENSIONS: {
      API: 'PHONE_EXTENSIONS',
      LITERAL: 'Phone Extensions',
      DEVELOP: {
        name: 'PHONE_EXTENSIONS',
        order: 200,
        type: 'OPTION'
      },
    },
    MEETINGS: {
      API: 'MEETINGS',
      LITERAL: 'Meetings',
      DEVELOP: {
        name: 'MEETINGS',
        order: 300,
        type: 'OPTION'
      },
    },
  }
};
