import {Component, OnInit} from '@angular/core';
import {CountryRates} from '../common/country-rates';
import {CwInfoPhoneRatesBus} from '../CollaborateSpaceWebTools/info/phone-rates/phone-rates-bus';
import {App} from '../app';
import {CwWeb} from '../CollaborateSpaceWebTools/tool/web/tool-web';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
})
/**
 * @version 1906111045
 */
export class RatesComponent implements OnInit {

  App = App;

  ModalContext: any;

  CwInfoPhoneRatesBus = CwInfoPhoneRatesBus;

  errorMessage: string;

  selectedContinent: string;
  allCountriesSelectedContinent: CountryRates[] = [];


  constructor() {
    CwInfoPhoneRatesBus.do();
  }



  ngOnInit(): void {
    // App.Navigation.planSubscriptions = false;
    // App.Navigation.planCallRates = true;
    // // App.Title = {
    //   main: 'Call Rates',
    // }
  }


}
