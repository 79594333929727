import {InfoBus} from '../info-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {CwRestRequestParametersI} from '../../tool/rest/model';
import {CwDefine} from '../../tool/define/tool-define';

/**
 * @version 1904101050
 */
class DialInBus extends InfoBus {
    DefineProperties: CwFunction[] = [
        CwDefine.flag
    ];

    defineUrl: CwDefineUrl = (() => {
        return CwDialIn.path + 'dialin';
    });

    do: CwFunction = ((DoParameters: CwRestRequestParametersI) => {
        CwDialIn.restGet(DoParameters);
    })

};

/**
 * @version 1904101050
 */
export const CwDialIn = new DialInBus();
