<ng-container *ngIf="getAvailable">
  <div class="row">
    <div class="col-6 text-center pt-3">
      <cw-accepted-cards></cw-accepted-cards>
    </div>
    <ng-container *ngFor="let InputItem of AppPlansTool.Buy.Payment.InputElements">

      <div [ngClass]="InputItem.class">
<!--        2011240953-->
<!--          (change)="On.changed(InputItem)"-->
        <cw-input
          xxx-2005270000-1
          [Item]="InputItem"
          [Set]="AppPlansTool.Buy.Payment.InputElements"
          (success)="On.success($event)"
        ></cw-input>
      </div>

    </ng-container>
  </div>
</ng-container>
