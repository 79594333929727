import { Component, OnInit } from '@angular/core';
import {CoFeedbackBus} from './bus';

@Component({
  selector: 'app-co-feedback',
  templateUrl: './component.html',
  styleUrls: ['./component.css']
})
export class CoFeedbackComponent implements OnInit {

  Bus = CoFeedbackBus;

  constructor() { }

  ngOnInit() {
  }

}
