<div
  class="text-center py-4"
>
  <div class="h-auto h4 font-additional">
    <div
      translate
    >If nothing prompts from browser,</div>
    <div>
      <a (click)="Actions.clickHere()"
         class="text-primary font-weight-bold mx-2"
         translate
      >click here</a>
      <span
        translate
      >to launch the meeting, or</span>
      <a (click)="Actions.download()"
         class="text-primary font-weight-bold mx-2"
         [translate]="getDownload"
      ></a>
    </div>
  </div>

</div>
