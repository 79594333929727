/**
 * @version 2004150000-12
 */
export enum CwBillingPlanType {
  main = 'Main',
  extra = 'Extra',
  order = 'Order',
  gift = 'Gift'
}

export interface CwBillingPlansAmountModel {
  id_collaborate_package?: string;
  interval?: string;
  currency?: string;
}
