import {SocketClass} from './socket';
import {CwMap} from '../model/model';
import {CwSocketActionModel, CwSocketCreateI, CwSocketStartI} from './socket-model';
import {CwRestRequestMinParametersI} from '../tool/rest/model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwConfigBus} from '@rest/entity/get-config/bus';

/**
 * @version 1910291517
 */
export class SocketManagementClass {

  SocketsMap: CwMap<SocketClass> = new Map();
  ActionsMap: CwMap<CwSocketActionModel[]> = new Map();

  getSocketKey = ((Params): string => Params.url + Params.protocols);

  /**
   * @version 1909051500
   */
  close = (() => {
    // Sockets closed
    this.SocketsMap.forEach(SocketItem => {
      SocketItem.Socket.close();
    });
    this.SocketsMap.clear();

    // Actions unregistered
    CwSocket.ActionsMap.clear();

  });

  do = ((Params?: CwSocketStartI) => {
    if (!Params) {
      Params = {};
    }
    if (Params && Params.Admin) {
      this.create(Params.Admin);
    } else {
      CwConfigBus.Load.do(<CwRestRequestMinParametersI>{
        successActionCustom: ((Data) => {
          if (
            CwConfigBus &&
            CwConfigBus.ParametersMap &&
            true
          ) {
            const Protocols = CwConfigBus.ParametersMap.get('ADM_APP_WEB_ADMIN_SOCKETS_PROTOCOLS');
            const Url = CwConfigBus.ParametersMap.get('ADM_APP_WEB_ADMIN_SOCKETS_URL');
            // // 2005210000
            // Params.Admin = <CwSocketCreateI>{
            //   token: CwTrust.token,
            //   protocols: Protocols.valor,
            //   url: Url.valor,
            // };
            this.do(Params);
          }
        })
      })

    }

    if (Params && Params.Im) {
      this.create(Params.Im);
    } else {
      CwConfigBus.Load.do(<CwRestRequestMinParametersI>{
        successActionCustom: ((Data) => {
          if (
            CwConfigBus &&
            CwConfigBus.ParametersMap &&
            true
          ) {
            const ImProtocols = CwConfigBus.ParametersMap.get('ADM_APP_IM_SOCKETS_PROTOCOLS');
            const ImUrl = CwConfigBus.ParametersMap.get('ADM_APP_IM_SOCKETS_URL');
            // // 2005210000
            // Params.Im = <CwSocketCreateI>{
            //   protocols: ImProtocols.valor,
            //   url: ImUrl.valor,
            //   token: CwTrust.token,
            //   onOpen: Params.onOpenIm
            // };
            this.do(Params);
          }
        })
      })

    }
  });

  create(Params: CwSocketCreateI): void {

    if (this.SocketsMap.has(this.getSocketKey(Params))) {
      const Socket = this.SocketsMap.get(this.getSocketKey(Params));
      if (Socket && Socket.isClosed) {
        Socket.Socket = undefined;
        this.SocketsMap.delete(this.getSocketKey(Params));
      }
    }

    // Do sockets open?
    if (!this.SocketsMap.has(this.getSocketKey(Params))) {
      const NewSocket = new SocketClass();
      NewSocket.setup({
        ...Params,
        onMessage: this.onMessage,
        onOpen: Params.onOpen,
      });
      this.SocketsMap.set(this.getSocketKey(Params), NewSocket);
    } else {
      if (Params && Params.onOpen) {
        Params.onOpen();
      }
    }
  }

  onMessage(Event: MessageEvent): void {
    if (
      Event &&
      Event.data &&
      // Event.AT_TARGET === Event.eventPhase &&
      Event.data[0] &&
      Event.data[0].toUpperCase &&
      'E' !== <string>Event.data[0].toUpperCase() &&
      true
    ) {
      try {
        const Message = JSON.parse(Event.data);

        if (Message) {
          const action = (Message.webserviceName)
            ? Message.webserviceName
            : (Message.action)
              ? Message.action
              : null
          ;
          if (!action) {
            console.error('1907261257');
            return;
          } else {
          }

          if (
            Message.result < 0 &&
            Message.error &&
            Message.error.length > 0 &&
            true
          ) {
            const ActionsList = CwSocket.ActionsMap.get(action);
            if (!ActionsList) {
              // console.error('1907261259');
              return;
            } else {
            }
            // 1910291517
            ActionsList.sort((ItemA, ItemB) => {
              return CwUtil.sortDesc(ItemA.priority, ItemB.priority);
            });
            ActionsList.forEach(Item => {
              Item.fn(Message);
            })
          }
        } else {
          console.error('1907261256');
        }
      } catch (e) {
      }
    } else {
      if (
        Event &&
        Event.data &&
        // Event.AT_TARGET === Event.eventPhase &&
        Event.data[0] &&
        Event.data[0].toUpperCase &&
        'E' === <string>Event.data[0].toUpperCase() &&
        true
      ) {
        // Error forced
      } else {
        console.error('1907261254', 'Message error', Event)
      }
    }
  }

  register(ActionsMap: CwMap<CwSocketActionModel[]>) {
    if (!ActionsMap) {
      console.error('1910291619');
      return
    }
    (Array.from(ActionsMap.keys())).forEach(
      (key) => {
        if (!CwSocket.ActionsMap.has(key)) {
          CwSocket.ActionsMap.set(key, []);
        }
        // Check if socket function was not registered already
        for (const SocketAction of ActionsMap.get(key)) {
          if (CwSocket.ActionsMap.get(key).filter(
            SocketActionFilter =>
              SocketActionFilter.fn === SocketAction.fn).length === 0
          ) {
            CwSocket.ActionsMap.get(key).push(...ActionsMap.get(key));
          }
        }
      }
    );
  }


  // 1908011133
  send(Param: {
    Message: any
  }) {
    Array.from(this.SocketsMap.values()).forEach((Item: SocketClass) => {
      if (
        Item &&
        Item.Socket &&
        Item.Socket.OPEN === Item.Socket.readyState &&
        true
      ) {
        Item.Socket.send(JSON.stringify(Param.Message))
      }
    })
  }

}

export const CwSocket = new SocketManagementClass();
