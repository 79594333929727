import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-rates-show-all-modal',
  templateUrl: './rates-show-all-modal.component.html',
  styleUrls: ['./rates-show-all-modal.component.css']
})
export class RatesShowAllModalComponent implements OnInit {
  @Input() CallGroup: any;
  @Input() nameGroup: string;
  @Input() showAllVisible: boolean;

  constructor() { }

  ngOnInit() {
  }

}
