import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';

/**
 * @version 2007211129
 * @description https://v6.angular.io/api/common/CurrencyPipe
 */
@Pipe({
  name: 'cwCurrency'
})
export class CwCurrencyPipe extends CurrencyPipe
  implements PipeTransform {

  transform(value: any, code?: any): any {
    return super.transform(
      value,
        code ? code : CwToolCurrency.getSelectedCode ,
      CwToolCurrency.DISPLAY,
        CwToolCurrency.getSelectedCode === 'JPY' ?
            CwToolCurrency.DIGITS_INFO_JPY : CwToolCurrency.DIGITS_INFO,
      // // 2007211129
      // CwLiteral.Language.language
    );
  }
}

