import {CwFunction, CwMap} from '../model/model';


/**
 * @version 1909051500
 */
export type CwSocketActionsT = CwMap<CwFunction[]>;

/**
 * @version 1909051500
 */
export interface CwSocketStartI {
  ActionsMap?: CwSocketActionsT;
  ActionsMapList?: CwSocketActionsT[];
  onOpenIm?: CwFunction;
  onOpenAdmin?: CwFunction;
  Admin?: CwSocketCreateI;
  Im?: CwSocketCreateI;
}


export interface CwSocketCreateI {
  token: string;
  url: string;
  protocols: string;
  onOpen?: CwFunction;
}

export interface CwSocketSetupI extends CwSocketCreateI {
  onMessage: CwFunction;
  onOpen?: CwFunction;
}


// 1907251253
export const SOCKET_ACTION = {
  DEVICE: {
    ADMIN: {
      CONNECTED: 'admin_connected',
      DISCONNECTED: 'admin_disconnected',
    },
    CREATE: 'user/create',
    DELETE: 'user/deleteUsers',
    FIRMWARE: {
      UPDATE: 'update_firmware'
    },
    LOGS: {
      GET: 'get_all_log_files',
      READY: 'log_zip_file_uploaded'
    },
    RESTART: 'restart',
    REGISTRATION: 'registration_status',
    SETTING: {
      SET: 'request_settings_change',
      CHANGED: 'settings_changed',
      UPDATED: 'settings'
    },
    STATUS: 'status_updated',
    STATUS_DEVICE: 'status',
    SCREEN_MESSAGE: 'screen_message',
    SHUTDOWN: 'shutdown',
    UPDATE: 'user/update',
  },
  ENTITY: {
    BRANDING: 'entity/setBranding',
    LDAP: 'authentication/set',
    PHONE_CREDIT: 'sAdmin/addPhoneCredit',
    SUBSCRIPTION_UPDATE: 'sAdmin/changeSubscription',
    DELETED: 'sAdmin/deleteAccount',
    DISABLED: 'sAdmin/disableAccount',
    SMTP: 'entity/setMailConfigurationOn',
    SEARCHABLE: 'entity/modifySearchable',
    RECORDING: 'entity/modifyMeetingScreenRecording'
  },
  GUEST: {
    DELETE: 'entity/deleteInvitedUsers',
    INVITE: 'entity/inviteUsers',
    FROM_CSV: 'entity/addUsersFromCSV',
    RE_INVITE: 'entity/reInviteUsers',
  },
  ROLES: {
    ADD: 'user/addRolesUsers',
    DELETE: 'user/delRolesUsers',
    UPDATE: 'sAdmin/changeSubscription',
  },
  USER: {
    ADD: {
      FROM_CSV: 'entity/addUsersFromCSV',
      FROM_LDAP: 'entity/importLDAPUsers'
    },
    DELETE: 'user/deleteUsers',
    REGISTRATION: 'user/registration',
    STATUS: 'status_updated',
    UPDATE: 'user/update',
    VCARD: 'vcard_updated',
  },
  BILLING: {
    UPDATE_CUSTOMER: 'billing/updateCustomer',
  },
  PHONE_EXTENSIONS: {
    ADD: 'phone_extension/create',
    DELETE: 'phone_extension/delete',
    UPDATE: 'phone_extension/modify',
    STATE: 'phone_extension/state',
  }
};


export enum CwSocketActionPriorityModel {
  low = 1,
  medium = 50,
  high = 100
}

export interface CwSocketActionModel {
  priority: CwSocketActionPriorityModel;
  fn: CwFunction;
}
