<div class="text-center">
  <div translate="By submitting..."></div>
  <div>
    <a
      class="mx-2 font-weight-bold"
      target="_blank"
      translate="Privacy Policy"
      [href]="getPrivacyPolicyHref"
    ></a>
    <span translate="and"></span>
    <a
      class="mx-2 font-weight-bold"
      target="_blank"
      translate="Terms"
      [href]="getTermsHref"
    ></a>
  </div>
</div>
