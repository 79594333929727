import * as moment from 'moment';
import {CwLiteral} from '../../CollaborateSpaceWebTools/literal/literal';
import {CwDateTool} from '../../CollaborateSpaceWebTools/tool/date/date.tool';

export class TimeFormatHelper {

  public static DATE_FORMAT_MOMENT = 'MM/DD/YYYY';
  public static DATE_FORMAT_MOMENT_FULL = 'MM/DD/YYYY HH:mm:ss';
  public static DATE_FORMAT_STANDARD = 'MM/dd/yyyy';
  public static DATE_FORMAT_FULL = 'MM/dd/yyyy HH:mm:ss';

  public static MILLISECONDS_PER_SECOND = 1000;
  public static SECONDS_PER_MIN = 60;
  public static SECONDS_PER_HOUR = 60 * TimeFormatHelper.SECONDS_PER_MIN;
  public static SECONDS_PER_DAY = 24 * TimeFormatHelper.SECONDS_PER_HOUR;

  public static getCurrentDate() {
    return moment().format('MM/DD/YYYY');
  }

  public static getPreviousDateBaseOnRange(number: number, range: string = 'month', date = '') {
    if (range === 'week' || range === 'month' || range === 'day') {
      if (date) {
        return moment(date).subtract(number, range).format('MM/DD/YYYY');
      }
      return moment().subtract(number, range).format('MM/DD/YYYY');
    }
    return '';
  }

  public static getFutureDateBaseOnRange(number: number, range: string = 'month', date = '') {
    if (range === 'week' || range === 'month' || range === 'day') {
      if (date) {
        return moment(date).add(number, range).format('MM/DD/YYYY');
      }
      return moment().add(number, range).format('MM/DD/YYYY');

    }
    return '';
  }

  public static formatDate(dateDefault) {
    return moment(dateDefault).format('MM/DD/YYYY');
  }

  public static dateWithFormat(dateDefault, dateFormat) {
    return moment(dateDefault).format(dateFormat);
  }

  public static convertTimeStampToDateFormat(timestamp) {
    return moment.unix(timestamp).format('MM/DD/YYYY');
  }

  public static getTimeStampStartDate(date) {
    return moment(date).set({hour: 0, minute: 0, second: 0, millisecond: 0}).valueOf();
  }

  public static getTimeStampEndDate(date) {
    return moment(date).set({hour: 23, minute: 59, second: 59, millisecond: 999}).valueOf();
  }

  public static convertTimeStampToFullTime(timestamp) {
    return moment.unix(timestamp / 1000).format('MM/DD/YYYY HH:mm:ss');
  }

  public static toUtc (date) {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  }


  public static getStatusElapsedTimeFormat (now, input): string {
    let msg: string;

    let sign = '';
    let unit = '';
    let time = 0;

    const duration = now - input;
    const seconds = duration / TimeFormatHelper.MILLISECONDS_PER_SECOND;
    if (duration === 0 || input === 0 || input === undefined || input === null) {
      return '';
    } else if (seconds < TimeFormatHelper.SECONDS_PER_MIN) {
      sign = '<';
      unit = 'minute';
      time = 1;
    } else if (seconds < TimeFormatHelper.SECONDS_PER_HOUR) {
      sign = '';
      time = Math.round(seconds / TimeFormatHelper.SECONDS_PER_MIN);
      if (time === 1) {
        unit = 'minute';
      } else {
        unit = 'minutes';
      }
    } else if (seconds < TimeFormatHelper.SECONDS_PER_DAY) {
      sign = '';
      time = Math.round(seconds / TimeFormatHelper.SECONDS_PER_HOUR);
      if (time === 1) {
        unit = 'hour';
      } else {
        unit = 'hours';
      }
    } else {
      sign = '';
      time = Math.round( seconds / TimeFormatHelper.SECONDS_PER_DAY);
      if (time === 1) {
        unit = 'day';
      } else {
        unit = 'days';
      }
    }
    msg = sign + ' ' + time + ' ' + CwLiteral.translate(unit);
    return msg;
 }

  public static getTimeExpiredDate(date: number): any {
    const expTime = {
      time: 0,
      type: ''
    }
    const time = Math.floor(moment.duration(date).asDays());
    if (time === 1) {
      expTime.time = time;
      expTime.type = 'day';
      return expTime;
    } else if (time < 1 && time >= 0 ) {
      const hours = Math.floor(moment.duration(date).asHours())
      expTime.time = hours;
      if (hours === 0 || hours === 1) {
        expTime.type = 'hour';
      } else {
        expTime.type = 'hours';
      }
      return expTime;
    } else if (time < 0) {
      expTime.time = time;
      expTime.type = 'Expired';
      return expTime;
    }
    expTime.time = time;
    expTime.type = 'days';
    return expTime;
  }

}
