import {Component, ViewChild} from '@angular/core';
import {Result} from '../common/result';
import {NgForm} from '@angular/forms';
import {CwUserPwd} from '@cw/user/pwd/pwd-bus';
import {CwUserPwdDoI} from '@cw/user/pwd/pwd-model';
import {errorActionCustomD} from '../app-default';
import {CwWeb} from '@tool/web/tool-web';
import {AppWebTool} from '../app-web.tool';
import {AppCw} from '../app.class';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
})
export class ForgotComponent extends AppCw {

  email: string;
  result: Result;
  errorMessage: string;

  @ViewChild('formCtrl') public formCtrl: NgForm;

  askreset(): void {
    if (this.formCtrl.form.valid) {
      this.App.Verify.email = this.email;
      CwUserPwd.do(<CwUserPwdDoI>{
          Bus: CwUserPwd,
          Parameters: {
            email: this.email,
          },
          errorActionCustom: errorActionCustomD,
          errorRestActionCustom: errorActionCustomD,
          successActionCustom: ((Data) => {
            CwWeb.goTo({path: '/verify'});
          })
        }
      );
    }
  }

  OnInit(): void {
    super.OnInit();
    AppWebTool.Title.main = 'Forgot Password';
  }
}
