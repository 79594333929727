import {CwUtil} from '@tool/util/cw-tool-util';
import {CwLogicSpacePlanTool} from '@cw/logic/space/plan/cw-logic-space-plan-tool';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';

export class CwLogicSpacePlanBuyClass extends CwLogicSpacePlanTool {


  Buy = {
    // 2007290000
    Admin: undefined,

    Basic: undefined,

    Data: {
      Basic: {},
      Basic1: <any>{},
      Basic5: <any>{},
      Basic10: <any>{},
      BasicAdd: <any>{},
      BasicWeb: <any>{},

      // 200722000000
      Pro: <any>{},
      Pro5: <any>{},
      Pro10: <any>{},
      Pro50: <any>{},
      Pro100: <any>{},
      ProAdd: <any>{},
      ProWeb: <any>{},

      Classroom: {},
      ClassroomAdd: {},
      ClassroomExpansion: {},
      ClassroomWeb: {},

      ExtraList: [],
      MainList: [],
    },

    Pro: undefined,

    // EVENTs //////////////////////////////////////////////////////////////////
    On: {
      Expansion: {
        AddAnother: {
          clicked: (
            (Plan) => {
              this.Buy.On.Expansion.AddAnother.clickedBasicAdd(Plan);
              this.Buy.On.Expansion.AddAnother.clickedClassroomAdd(Plan);
              this.Buy.On.Expansion.AddAnother.clickedProAdd(Plan);
              // 2009081500
              this.Buy.On.Expansion.AddAnother.clickedCloudAdd(Plan);
              this.Buy.On.Expansion.AddAnother.clickedWebinarAdd(Plan);

              if (this.isClassroomWeb(Plan)) {
                this.add(
                  this.Buy.Data.ExtraList,
                  CwUtil.clone(this.Plan.ClassroomExpansion),
                )
              } else if (this.isWebinarWeb(Plan)) {
                const WebinarAtSummary: any = this.IO.NewList.filter(this.isWebinar)[0];
                const WebinarExpansionAtSummary: any = this.IO.NewList.filter(this.isWebinarExpansion)[0];
                if (WebinarAtSummary && WebinarAtSummary.quantity > 0) {
                  this.add(
                    this.Buy.Data.ExtraList,
                    this.Plan.WebinarExpansion,
                  )
                } else if (WebinarExpansionAtSummary && WebinarExpansionAtSummary.quantity > 0) {
                  this.add(
                    this.Buy.Data.ExtraList,
                    this.Plan.Webinar,
                  )
                }
              }
            }
          ),
          // 2007241150.1
          clickedBasicAdd: (
            (Plan): void => {
              if (Plan && this.isBasicType(Plan)) {
                this.addMain(
                  {
                    ...CwUtil.clone(Plan),
                    _order: this.orderMainNext(this.isBasicAdd),
                    quantity: 0, // 2007311046
                    minimized: false,
                  }
                )
              }
              this.addBasicAdd();
            }
          ),
          // 2009081500
          clickedCloudAdd: ((Plan): void => {
            if (Plan && this.isCloudType(Plan)) {
              this.addExtra(
                {
                  ...CwUtil.clone(Plan),
                  _order: this.orderExtraNext(this.isCloudAdd),
                  quantity: 0, // 2007311046
                  minimized: false,
                }
              )
            }
            this.addCloudAdd();
          }),
          // 2007242311
          clickedWebinarAdd: (
            (Plan): void => {
              if (Plan && this.isWebinarType(Plan)) {
                this.addExtra(
                  {
                    ...CwUtil.clone(Plan),
                    _order: this.orderExtraNext(this.isWebinarAdd),
                    quantity: 0, // 2007311046
                    minimized: false,
                  }
                )
              }
              this.addWebinarAdd();
            }
          ),
          // 2007242311
          clickedClassroomAdd: (
            (Plan: any): void => {
              if (Plan && this.isClassroomAdd(Plan)) {
                this.add(
                  this.Buy.Data.ExtraList,
                  CwUtil.clone(this.Plan.ClassroomExpansion)
                );
                CwUtil.rm(this.Buy.Data.ExtraList, this.isClassroomAdd);
              }
            }
          ),
          clickedProAdd: (
            (Plan): void => {
              if (Plan && this.isProType(Plan)) {
                this.addMain(
                  {
                    ...CwUtil.clone(Plan),
                    _order: this.orderMainNext(this.isProAdd),
                    quantity: 0, // 2007311046
                    minimized: false,
                  }
                );
                this.addProAdd();
              }
            }
          ),

        }
      },


      Extra: {

        changed: (
          (Plan: any, NewList: any[]) => {
            try {
              // 2007242311
              this.Buy.On.Extra.changedClassroomShowExpansionAdd(Plan);

              if ( // 2008211016
                this.isClassroom(Plan) || this.isClassroomWeb(Plan)
              ) {
                this.Buy.update(this.isClassroom, Plan);
                // Classroom expansion quantity = 0 at "Summary"
                // Classroom expansion quantity = 0 and removed at "Select Plan"
                if (Plan.quantity < 1) {
                  const ClassroomExpansionAtSelect = this.Buy.Data.ExtraList.filter(this.isClassroomExpansion)[0];
                  if (ClassroomExpansionAtSelect) {
                    ClassroomExpansionAtSelect.quantity = 0
                    ClassroomExpansionAtSelect.quantityPrevious = 0
                    this.Buy.update(this.isClassroomExpansion, ClassroomExpansionAtSelect);
                    // 2009222232
                    CwUtil.remove({
                      List: this.Buy.Data.ExtraList,
                      condition: this.isClassroomExpansion,
                    })
                  }
                  this.rmExtra(this.isClassroomAdd);
                }
              } else if (this.isClassroomExpansion(Plan)) {
                this.Buy.update(this.isClassroomExpansion, Plan)
              } else if (this.isWebinar(Plan)) {
                this.Buy.update(this.isWebinar, Plan);
              } else if (this.isWebinarExpansion(Plan)) {
                this.Buy.update(this.isWebinarExpansion, Plan);
              } else if (this.isCloud500(Plan)) {
                this.Buy.update(this.isCloud500, Plan);
              }
            } catch (e) {
              console.error('2007201605', e);
            }
          }
        ),

        // 2007242311
        changedClassroomShowExpansionAdd: (
          (Plan: any) => {
            if (
              ( // 2008211016
                this.isClassroom(Plan) || this.isClassroomWeb(Plan)
              ) &&
              Plan.quantity > 0 &&
              !CwUtil.has(this.Buy.Data.ExtraList, this.isClassroomAdd) &&
              !CwUtil.has(this.Buy.Data.ExtraList, this.isClassroomExpansion)
            ) {
              this.add(this.Buy.Data.ExtraList, this.Plan.ClassroomAdd);
            }

            if (
              Plan.quantity === 0 &&
              this.isClassroom(Plan)
            ) {
              CwUtil.rm(this.Buy.Data.ExtraList, this.isClassroomAdd)
              this.Buy.remove(this.isClassroom);
              this.Buy.remove(this.isClassroomExpansion);
            }

          }
        ),

      }, // Extra //////////////////////////////////////////////////////////////


      Main: {

        changed: (
          (Plan: any, NewList: any[]) => {
            // this.Buy.On.Main.changedFirstBasicWeb(Plan);
            // this.Buy.On.Main.changedFirstProWeb(Plan);
            this.Buy.On.Main.changedPro(Plan);
            this.Buy.On.Main.changedAllMainToZero();
            this.Buy.On.Main.changedAllIntervalWithZeroQuantity();
            if (Plan) {
              if (
                Plan.quantity > 0 &&
                NewList &&
                !CwUtil.exist({
                  Array: NewList,
                  condition: (
                    (Item): boolean => {
                      return this.is(Item, Plan);
                    }
                  )
                })
              ) {
                this.add(
                  NewList,
                  Plan,
                )
              }

              if (
                NewList &&
                CwUtil.exist({
                  Array: NewList,
                  condition: (
                    (Item): boolean => {
                      return this.is(Item, Plan);
                    }
                  )
                })
              ) {
                // 200722000000
                if (this.isBasic1(Plan)) {
                  this.Buy.update(this.isBasic1, Plan);
                } else if (this.isBasic5(Plan)) {
                  this.Buy.update(this.isBasic5, Plan);
                } else if (this.isBasic10(Plan)) {
                  this.Buy.update(this.isBasic10, Plan);
                } else if (this.isPro5(Plan)) {
                  this.Buy.update(this.isPro5, Plan);
                } else if (this.isPro10(Plan)) {
                  this.Buy.update(this.isPro10, Plan);
                } else if (this.isPro50(Plan)) {
                  this.Buy.update(this.isPro50, Plan);
                } else if (this.isPro100(Plan)) {
                  this.Buy.update(this.isPro100, Plan);
                }

              }

            }
          }
        ),  // changed
        changedAllIntervalWithZeroQuantity: (
          (): void => {
            const NotZeroList = this.Buy.Data.MainList.filter(
              (Filter): boolean => {
                return Filter.quantity > 0
              }
            );
            if (NotZeroList && NotZeroList[0]) {
              [
                ...this.Buy.Data.MainList,
                ...this.Buy.Data.ExtraList,
              ]
                .forEach(
                  (Item) => {
                    if (Item && Item.quantity === 0) {
                      Item.interval = NotZeroList[0].interval;
                    }
                  }
                )
            }
          }
        ),
        // 2007242311
        changedAllMainToZero: (
          (): void => {
            if (
              !CwUtil.has(
                this.Buy.Data.MainList,
                (
                  (Filter: any): boolean => {
                    return Filter.quantity > 0;

                  }
                )
              )
            ) {
              // 2007242311
              // All Extra to Zero
              this.Buy.Data.ExtraList.forEach(
                (Item: any) => {
                  Item.quantity = 0;
                }
              );
              CwUtil.rm(this.Buy.Data.ExtraList, this.isClassroom);
              CwUtil.rm(this.Buy.Data.ExtraList, this.isClassroomAdd);
              CwUtil.rm(this.Buy.Data.ExtraList, this.isClassroomExpansion);
              CwUtil.rm(this.Buy.Data.ExtraList, this.isWebinar);
              CwUtil.rm(this.Buy.Data.ExtraList, this.isWebinarAdd);
              CwUtil.rm(this.Buy.Data.ExtraList, this.isWebinarExpansion);
              // All Summary to Zero
              this.IO.NewList.forEach(
                (Item: any) => {
                  Item.quantity = 0;
                }
              )
            }

          }
        ),

        // 2007241150.1
        changedPro: (
          (Plan: any) => {
            if (this.isProType(Plan)) {
              if (
                this.existInMainList(
                  (PlanInMain): boolean => {
                    return (
                      this.isProType(PlanInMain) &&
                      PlanInMain.quantity > 0
                    )
                  }
                )
              ) {
                this.addWebinarAdd();
              } else {
                this.Buy.remove(this.isWebinarAdd);
                this.Buy.remove(this.isWebinar);
                this.Buy.remove(this.isWebinarExpansion);
              }
            }
          }
        )

      } // Main
    },
    // EVENTs //////////////////////////////////////////////////////////////////

    Performance: {
      Extra: {
        is: (
          (): boolean => {
            return CwUtil.has(
              this.Buy.Data.MainList,
              (
                (Filter: any): boolean => {
                  return (
                    (
                      this.isBasicType(Filter) ||
                      this.isProType(Filter)
                    ) &&
                    Filter.quantity > 0
                  )
                }
              )
            );
          }
        )
      },
      Expansion: {
        addAnotherVisible: (
          (Plan: any): boolean => {
            let visible = false;

            if (this.isWebinarWeb(Plan)) {
              if (
                CwUtil.exist({
                  Array: this.Buy.Data.ExtraList,
                  condition: this.isWebinar,
                }) ||
                CwUtil.exist({
                  Array: this.Buy.Data.ExtraList,
                  condition: this.isWebinarExpansion,
                })
              ) {
                visible = false;
              } else {
                visible = true;
              }
            } // isWebinarWeb


            return visible;
          }
        ),
        selectDisabled: (
          (Plan: any): boolean => {
            let disabled = false;
            if (Plan.quantity > 0) {
              // 2007270944
              if (
                this.isBasicWeb(Plan)
              ) {
                const countBasicWebInMainList = this.Buy.Data.MainList.filter(this.isBasicWeb).length;
                disabled = (countBasicWebInMainList > 1);
              } else if (this.isProWeb(Plan)) {
                const countProWebInMainList = this.Buy.Data.MainList.filter(this.isProWeb).length;
                disabled = (countProWebInMainList > 1);
              } else {
                disabled = true;
              }
            } else if (this.isWebinarWeb(Plan)) {
              if (
                CwUtil.exist({
                  Array: this.Buy.Data.ExtraList,
                  condition: this.isWebinar,
                }) ||
                CwUtil.exist({
                  Array: this.Buy.Data.ExtraList,
                  condition: this.isWebinarExpansion,
                })
              ) {
                disabled = true;
              }
            }
            return disabled;
          }
        )
      }
    },

    ////////////////////////////////////////////////////////////////////////////

    clear: (
      () => {
        CwUtil.clearData(this.Buy.Data);
        this.Buy.Basic.clear();
        this.Buy.Pro.clear();
      }
    ),

    remove: (
      (condition) => {
        if (condition) {
          // Plan quantity = 0 at "Summary"
          // Plan quantity = 0 and removed at "Select Plan"
          try {
            const PlanAtSummary: any = this.IO.NewList.filter(condition)[0];
            if (PlanAtSummary) {
              PlanAtSummary.quantity = 0;
            }
            const PlanAtSelect: any = this.Buy.Data.ExtraList.filter(condition)[0];
            if (PlanAtSelect) {
              PlanAtSelect.quantity = 0;
            }
            CwUtil.remove({
              List: this.Buy.Data.ExtraList,
              condition,
            })
          } catch (e) {
            console.error('2007201310', e);
          }
        }
      }
    ), // remove


    // 2007000000.1
    toApi: (
      (PlanList: any[]): any[] => {
        const ToApiList = PlanList.filter(
          (Filter: any) => {
            return Filter.quantity > 0;
          }
        );
        if (ToApiList) {
          ToApiList.forEach(
            (Item: any) => {
              try {
                if (Item) {
                  const PlanFromApi = this.IO.ApiList.filter(
                    (Filter: any) => {
                      return (
                        this.is(Item, Filter) &&
                        Item.interval === Filter.interval &&
                        // 2008130000
                        CwToolCurrency.isSelected(Filter)
                      );
                    }
                  )[0];
                  Item.id_Plan = PlanFromApi.id_Plan;
                  Item.planId = PlanFromApi.id_Plan;
                }
              } catch (e) {
                console.error('2007202329', e);
              }
            }
          )
        }
        return ToApiList;
      }
    ),

    update: (
      (condition, Plan) => {
        if (condition && Plan) {
          try {
            const PlanAtSummary: any = this.IO.NewList.filter(condition)[0];
            PlanAtSummary.interval = Plan.interval;
            PlanAtSummary.quantity = Plan.quantity;
            // 200722000000
            PlanAtSummary.amount = Plan.amount;
            PlanAtSummary.Date = Plan.Date;

            if (
              Plan.quantity < 1 &&
              // 2007240008
              // Plan.quantityPrevious !== undefined
              Plan.quantityPrevious
            ) {
              this.Buy.remove(condition);
            }
            Plan.quantityPrevious = Plan.quantity;
          } catch (e) {
            console.error('2007201313', e)

          }
        }
      }
    ), // update


  } // Buy


}

