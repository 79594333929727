// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.


import {CwEnvironmentI} from '../app/CollaborateSpaceWebTools/model/model';
import {CwRestCode} from '../app/CollaborateSpaceWebTools/tool/rest/model';
import {CwLiteralLanguageI} from '../app/CollaborateSpaceWebTools/literal/model';
declare var require: any;
export const EnvironmentCommon: CwEnvironmentI = {

  production: false,
  // 1902051520
  // onPremise: false,


  App: {
    // 2004150000-10
    adminPath: require('../../package.json').admin,
    name: require('../../package.json').name,
    version: require('../../package.json').version,
    configuration: require('../../package.json').configuration,
    keepKey: '##Q29sbGFib3JhdGVTcGFjZS1BcHA=',
    unsafe: true,
    StylesList: [
      '/literal/assets/css/space.css',
      '/literal/assets/css/bootstrap-space.css',
      '/literal/assets/css/prime-space-portal.css',
    ],
  },

  Feed: {
    hiddenTime: 10 * 1000,
    intervalTime: 5 * 1000,
    keepKey: 'COLLABORATE_SPACE_SYSTEM',
  },

  Literal: {
    flagPath: '##L2xpdGVyYWwvYXNzZXRzL2ZsYWdzLw==',
    path: '##Ly4uL2xpdGVyYWwvYXNzZXRzL2kxOG4v',
    keepKey: '##Q29sbGFib3JhdGVTcGFjZS1MaXRlcmFsLUtlZXA=',
    LanguageDefault: <CwLiteralLanguageI>{
      language: 'en',
      country: 'US',
    },
  },

  Login: {
    keepLoggedKeepKey: 'COLLABORATESpaceKeepLogged',
    logOutPath: '/#/datasignin',
    tokenKeepKey: 'COLLABORATESpaceToken',
    tokenWrongPath: '/#/datasignin',
  },

  Rest: {
    Code: CwRestCode,
    path: 'L2NvbS5jbGVhcm9uZS5jb2xsYWJvcmF0ZS5zZXJ2aWNlcy9yZXN0Lw=='
  },

  Session: {
    keepKey: '##Q09MTEFCT1JBVEUgU3BhY2UgLSBQYXJ0ZW5lcnMgLSBTZXNzaW9u',
  },

  Stripe: {
    key: '##cGtfdGVzdF9HTzNqTXRoWGpjYlR6Rm1KYzZSWU11bGc=',
    class: '##U3RyaXBlRWxlbWVudC0tY29tcGxldGU=',
  },

};
