import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-call-rates',
  templateUrl: './pricing-call-rates.component.html',
  styleUrls: ['./pricing-call-rates.component.css']
})
export class PricingCallRatesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
