/**
 * @version 1910311256
 */
export interface CwFileToolCsvExportListModel {
  filename: string,
  Items?: {
    DataList: any[],
    HeaderList: string[],
    FieldList: string[],
  }
}

/**
 * @version 1910311256
 */
export interface CwFileToolCsvExportModel extends CwFileToolCsvExportListModel {
  selection?: boolean;
}

export const CW_FILE_TOOL = {
  SEPARATOR: ',',
  SEPARATOR_LIST: '|',
};
