import {AppComponent} from './app.component';
import {CwToolWebClass, CwWeb} from './CollaborateSpaceWebTools/tool/web/tool-web';
import {CwWebSetupI} from './CollaborateSpaceWebTools/tool/web/web.model';
import {CwRest} from './CollaborateSpaceWebTools/tool/rest/tool-rest';
import {CwRestRequestParametersI, CwRestSetup} from './CollaborateSpaceWebTools/tool/rest/model';
import {CwLiteral} from './CollaborateSpaceWebTools/literal/literal';
import {CwLiteralSetupI} from './CollaborateSpaceWebTools/literal/model';
import {CwUserPwd} from './CollaborateSpaceWebTools/user/pwd/pwd-bus';
import {CwAppI, CwSetupFeedI, CwSetupI} from './CollaborateSpaceWebTools/model/model';
import {CwFeed} from './CollaborateSpaceWebTools/feed/feed-bus';
import {CwTokenGet} from './CollaborateSpaceWebTools/token/get/get-bus';
import {CwEntityInvitation} from './CollaborateSpaceWebTools/entity/invitation/invitation-bus';
import {CwBillingProductsBus} from './CollaborateSpaceWebTools/billing/products/products-bus';
import {CwUserResetBus} from './CollaborateSpaceWebTools/user/reset/reset-bus';
import {CwUserRegistrationBus} from './CollaborateSpaceWebTools/user/registration/registration-bus';
import {CwInfoPhoneRatesBus} from './CollaborateSpaceWebTools/info/phone-rates/phone-rates-bus';
import {APP_THEME, AppBodyI, AppDownloadI, AppFeedbackI, AppHeadI, AppNavigationI, AppPreloaderI, AppUserI, AppVerifyI} from './app-model';
import {CwToolKeepC} from './CollaborateSpaceWebTools/tool/keep/tool-keep';
import {environment} from '../environments/environment';
import {CwVersionCheckPlatform} from './CollaborateSpaceWebTools/version/check/check-model';
import {CwProtocol} from './CollaborateSpaceWebTools/tool/protocol/tool-protocol';
import {CwDialIn} from './CollaborateSpaceWebTools/info/dialin/dialin-bus';
import {CwInstaller} from './CollaborateSpaceWebTools/tool/installer/installer';
import {CwInstallerSetupI} from '@tool/installer/model';
import {CwBillingPlans} from '@rest/billing/plans/plans-bus';
import {CwOnPremiseBus} from '@rest/billing/on-premise/billing-on-premise-bus';
import {AppPlansTool} from './plans/plans.tool';
import {CwInfoInstallationBus} from '@rest/info/installation/cw-info-installation-bus.class';
import {CwCountryStateBus} from '@rest/literal/country-min/country-min-bus';
import {CwStateBus} from '@rest/literal/state/state-bus';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';
import {TokenRequestInviteTokenBus} from '@cw/token/request-invite-token/bus';
import {CwUserValidationEmailBus} from './CollaborateSpaceWebTools/user/validation-email/validation-email-bus';


export const APP_REST_URL = '/com.clearone.collaborate.services/rest/';

/**
 * @version 1904101050
 */
export class AppClass implements CwAppI {

  // 2101040000
  Xpress = {
    src: 'assets/images/ClearOneXpress_white.png',
    url: 'http://www.clearone.com'
  }

  Logo = {
    src: this.Xpress.src,
    url: this.Xpress.url,
  }

  WebTool;
  // 2009210000
  Cw = {
    TokenRequestInviteTokenBus: new TokenRequestInviteTokenBus(),
  }


  downVisible = false;
  referred: string; // 2009210000
  referredName: string; // 2009210000
  theme = APP_THEME.STANDARD
  token: string; // 2009210000

  Web: CwToolWebClass = CwWeb; // setup
  // 1904251312
  Head: AppHeadI = {
    selectLanguageVisible: true,
  }

  DialIn: any[];

  Verify: AppVerifyI = {
    email: '',
  };

  User: AppUserI = {
    name: '',
  };

  Body: AppBodyI = {
    visible: true
  };

  Preloader: AppPreloaderI = {
    visible: false,
    textMain: 'Saving data ...',
    textSecondary: 'This operation can take several minutes',
  };


  get Feedback(): AppFeedbackI {
    return this._Feedback;
  }

  set Feedback(Value: AppFeedbackI) {
    this.resetFeedBack();
    if (Value) {
      if (0 === Value.errorCode) {
        this._Feedback.success = Value.error;
      } else if (0 > Value.errorCode) {
        this._Feedback.error = Value.error;
        this._Feedback.contactAdminVisible = true;
      } else if (400 <= Value.status) {
        this._Feedback.contactAdminVisible = true;
        console.log('Feedback - error', Value.status);
      } else {
        this._Feedback.contactAdminVisible = (Value.contactAdminVisible)
          ? Value.contactAdminVisible
          : false
        ;

        this._Feedback.error = (Value.error)
          ? Value.error
          : ''
        ;

        this._Feedback.success = (Value.success)
          ? Value.success
          : ''
        ;
      }
    }
  }

  resetFeedBack = (() => {
    this._Feedback = {
      contactAdminVisible: false,
      error: '',
      errorCode: null,
      success: '',
    }
  });

  private _Feedback: AppFeedbackI = {};


  // 1904251312
  Protocol = CwProtocol;

  // 1904251312
  // check = CwProtocol.check;
  // 1904251312
  Platform = CwVersionCheckPlatform;

  // 1904251312
  Literal = CwLiteral;

  Navigation: AppNavigationI = {
    download: false,
    plan: false,
    home: false,
    host: false,
    join: false
  };

  client: any;

  innerWidth: number;

  KeepLoggedIn: CwToolKeepC<boolean>;
  KeepToken: CwToolKeepC<string>;

  // 1904251312
  Download: AppDownloadI = {
    android: '',
    ios: '',
    mac: '',
    windows: ''
  };

  constructor() {

    this.KeepLoggedIn = new CwToolKeepC(
      {
        key: environment.Login.keepLoggedKeepKey,
        DataDefault: false
      }
    );

    this.KeepToken = new CwToolKeepC(
      {
        key: environment.Login.tokenKeepKey,
        DataDefault: ''
      }
    )
  }

  setup(Component: AppComponent) {

    // 1904101050
    CwRest.setup(<CwRestSetup>{
      HttpClient: Component._HttpClient,
      url: APP_REST_URL,
    });


    CwWeb.setup(<CwWebSetupI>{
      ActivatedRoute: Component._ActivatedRoute,
      Router: Component._Router,
      DeviceDetectorService: Component._DeviceDetectorService,
    });

    // 2003041211
    CwOnPremiseBus.Load.do({
      successActionCustom: (
        () => {
          CwToolCurrency.setup();
        }
      ), // succesActionCustom
    }); // CwOnPremiseBus.Load.do

    CwLiteral.setup(<CwLiteralSetupI>{
      DomSanitizer: Component._DomSanitizer,
      Service: Component._TranslateService,
      LanguageSelectedToDoList: [
        (() => {
          CwWeb.fitty();
        })
      ],
    });

    CwLiteral.do(<CwRestRequestParametersI>{
      Bus: CwLiteral,
      Parameters: {},
      successActionCustom: ((Data) => {
        CwLiteral.logic();
      })
    });

    CwUserPwd.setup(<CwSetupI>{
      Feed: <CwSetupFeedI>{
        Bus: CwFeed,
        error: '',
        paramError: 'The email is not formatted correctly.',
        success: 'If you have an account associated with' +
          ' the above email you will have received an email with the instructions for reset the password.',
      },
      ErrorActionList: [],
      SuccessActionList: [],
    });

    CwEntityInvitation.setup(<CwSetupI>{
      SuccessActionList: [
        ((Data, That) => {
          CwWeb.goTo({path: '/datasignin'});
        })
      ],
    });

    CwTokenGet.setup(<CwSetupI>{
      SuccessActionList: [
        // ((Data, That) => {
        //     CwWeb.goTo('/datasignin');
        // })
      ],
    });

    CwBillingProductsBus.setup(<CwSetupI>{
      DefineProperties: [
        (Properties: any) => {
          if (
            Properties &&
            Properties.price &&
            true
          ) {
            Properties.price /= 100;
          }
        },
      ]
    })

    CwUserResetBus.setup(<CwSetupI>{
      SuccessActionList: [
        // // 1906250845
        // ((Data, That) => {
        //     CwWeb.goTo('/datasignin');
        // })
      ],
      parameterRuleValidation: (() =>
          CwUserResetBus &&
          CwUserResetBus.Parameters &&
          CwUserResetBus.Parameters.password &&
          CwUserResetBus.Parameters.token &&
          true
      )
    });

    CwUserRegistrationBus.setup(<CwSetupI>{

      parameterRuleValidation: (() =>
          CwUserResetBus &&
          CwUserResetBus.Parameters &&
          CwUserResetBus.Parameters.password &&
          CwUserResetBus.Parameters.token &&
          true
      ),

      SuccessActionList: [
        ((Data, That) => {
          // TODO-1904101050
          // this.message = 'Congratulations, You are registered in the system';
          // CoProtocolBus.check(this.informationService.client);
        })
      ],
    });

    CwUserValidationEmailBus.setup(<CwSetupI>{

      parameterRuleValidation: (() =>
          CwUserResetBus &&
          CwUserResetBus.Parameters &&
          CwUserResetBus.Parameters.password &&
          CwUserResetBus.Parameters.token &&
          true
      ),

      SuccessActionList: [
        ((Data, That) => {
          // TODO-1904101050
          // this.message = 'Congratulations, You are registered in the system';
          // CoProtocolBus.check(this.informationService.client);
        })
      ],
    });

    CwInfoPhoneRatesBus.setup(<CwSetupI>{});


    CwInfoInstallationBus.setup(<CwSetupI>{});

    // 1904251312
    CwProtocol.setup();

    CwDialIn.do(<CwRestRequestParametersI>{
      Bus: CwDialIn,
      successActionCustom: ((Data) => {
        App.DialIn = CwDialIn.ItemsList;
      }),
      Parameters: {}
    });

    // // 1904251312
    // CwVersionCheck.do2(<CwVersionCheckDoI>{
    //     Bus: CwVersionCheck,
    //     successActionCustom: ((Data, That) => {
    //         if (
    //             Data &&
    //             Data.download_url &&
    //             true
    //         ) {
    //             App.Download.android = Data.download_url;
    //             // 1904251312
    //             CwToolProtocol.add({
    //                 platform: CwVersionCheckPlatform.android,
    //                 url: Data.download_url
    //             })
    //         }
    //     }),
    //     Parameters: <CwVersionCheckDoParametersI>{
    //         entityId: 0,
    //         platform: CwVersionCheckPlatform.android
    //     },
    // });
    //
    // // 1904251312
    // CwVersionCheck.do2(<CwVersionCheckDoI>{
    //     Bus: CwVersionCheck,
    //     successActionCustom: ((Data, That) => {
    //         if (
    //             Data &&
    //             Data.download_url &&
    //             true
    //         ) {
    //             App.Download.ios = Data.download_url;
    //             // 1904251312
    //             CwToolProtocol.add({
    //                 platform: CwVersionCheckPlatform.ios,
    //                 url: Data.download_url
    //             })
    //         }
    //     }),
    //     Parameters: <CwVersionCheckDoParametersI>{
    //         entityId: 0,
    //         platform: CwVersionCheckPlatform.ios
    //     },
    // });
    //
    // // 1904251312
    // CwVersionCheck.do2(<CwVersionCheckDoI>{
    //     Bus: CwVersionCheck,
    //     successActionCustom: ((Data, That) => {
    //         if (
    //             Data &&
    //             Data.download_url &&
    //             true
    //         ) {
    //             App.Download.mac = Data.download_url;
    //             // 1904251312
    //             CwToolProtocol.add({
    //                 platform: CwVersionCheckPlatform.mac,
    //                 url: Data.download_url
    //             })
    //         }
    //     }),
    //     Parameters: <CwVersionCheckDoParametersI>{
    //         entityId: 0,
    //         platform: CwVersionCheckPlatform.mac
    //     },
    // });
    //
    // // 1904251312
    // CwVersionCheck.do2(<CwVersionCheckDoI>{
    //     Bus: CwVersionCheck,
    //     successActionCustom: ((Data, That) => {
    //         if (
    //             Data &&
    //             Data.download_url &&
    //             true
    //         ) {
    //             App.Download.windows = Data.download_url;
    //             // 1904251312
    //             CwToolProtocol.add({
    //                 platform: CwVersionCheckPlatform.windows,
    //                 url: Data.download_url
    //             })
    //         }
    //     }),
    //     Parameters: <CwVersionCheckDoParametersI>{
    //         entityId: 0,
    //         platform: CwVersionCheckPlatform.windows
    //     },
    // });

    CwInstaller.setup(<CwInstallerSetupI>{
      restUrl: APP_REST_URL
    });

    // 2004150000-3
    CwBillingPlans.Load.do(<CwRestRequestParametersI>{
      successActionCustom: () => {
        AppPlansTool.View.definePlans();
      }
    });

    CwStateBus.Load.do();
    CwCountryStateBus.Load.do();
  }


} // App

/**
 * @version 1904101050
 */
export const App = new AppClass();
