import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {CwComponent} from '../component';
import {CwWeb} from '../../tool/web/tool-web';

@Component({
  selector: 'app-cw-row',
  templateUrl: './row.component.html',
  styles: []
})
export class RowComponent extends CwComponent implements AfterViewInit {

  ClassCommon = [
    // 'm-2',
    // 'bg-info',
  ];
  ClassItemR = [
    ...this.ClassCommon,
    'cw-row-item',
  ];
  ClassItemR2 = [
    ...this.ClassCommon,
    'cw-row-item-2',
    'd-flex',
    'flex-wrap',
    'justify-content-center',
  ];
  ClassItemR4 = [
    ...this.ClassCommon,
    'cw-row-item-4',
  ];

  ClassRow = [
    'd-flex',
    'flex-wrap',
  ];

  get getClassRow(): string {
    const _ClassRow = [...this.ClassRow];
    if (this.has('end')) {
      _ClassRow.push('justify-content-end');
    } else if (this.has('start')) {
      _ClassRow.push('justify-content-start');
    } else {
      _ClassRow.push('justify-content-center');
    }

    return _ClassRow.join(' ');
  }

  constructor(Element: ElementRef<any>) {
    super()
    this.Element = Element;
  }


  ngAfterViewInit(): void {
    if (
        // this.getVersion2 &&
        this.Element &&
        this.Element.nativeElement &&
        this.Element.nativeElement.children &&
        true
    ) {
      for (const Container of this.Element.nativeElement.children) {
        // if (
        //     Container &&
        //     this.has('rx1', Container) ||
        //     this.has('rx2', Container) ||
        //     this.has('rx3', Container) ||
        //     this.has('rx4', Container) ||
        //     false
        // ) {
        //   CwWeb.htmlClassAdd({
        //     ClassList: this.ClassItemR,
        //     HtmlElement: Container
        //   });
        // }
        if (
            Container &&
            Container.children &&
            true
        ) {

          for (const Item of Container.children) {
            if (Item) {
              if (
                  this.has('r', Item) ||
                  // this.has('rx1', Item) ||
                  // this.has('rx2', Item) ||
                  // this.has('rx3', Item) ||
                  // this.has('rx4', Item) ||
                  false
              ) {
                CwWeb.htmlClassAdd({
                  ClassList: this.ClassItemR,
                  HtmlElement: Item
                });
              } else if (
                  this.has('r2', Item) ||
                  false
              ) {
                CwWeb.htmlClassAdd({
                  ClassList: this.ClassItemR2,
                  HtmlElement: Item
                });
              } else if (
                  this.has('r4', Item) ||
                  false
              ) {
                CwWeb.htmlClassAdd({
                  ClassList: this.ClassItemR4,
                  HtmlElement: Item
                });
                // Item.classList.add(...this.classItem);
              } else {
                for (const Child of Item.children) {
                  if (Child) {
                    if (
                        this.has('r4', Child)
                    ) {
                      // this.classAdd(this.classItemR4, Child);
                    } else if (
                        // this.has('r2l', Child) ||
                    // this.has('r2r', Child) ||
                        this.has('r21', Child) ||
                        this.has('r22', Child) ||
                        this.has('r23', Child) ||
                        this.has('r24', Child) ||
                        false
                    ) {
                      CwWeb.htmlClassAdd({
                        ClassList: this.ClassItemR,
                        HtmlElement: Item
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }

}
