import {CwTraceI} from './model';

class CwToolTraceClass {
  _traceList: CwTraceI[] = [];

  constructor() {
    window['Cw'] = Cw;
  }

  trace(text: string) {
    this._traceList.push({
      Date: new Date(),
      text
    })
  }

  get getTrace(): string {
    let _trace = '';
    this._traceList.forEach(TraceItem => {
      _trace += '<br>' +
        TraceItem.Date.getTime() +
        ' - ' +
        TraceItem.text +
        '';
    });

    // if (
    //   window &&
    //   window['CwTrace'] &&
    // true
    // ) {
    //   for (const TraceItem  in window['CwTrace']) {
    //     _trace += '<br>' +
    //       TraceItem['Date'].getTime() +
    //       ' - ' +
    //       TraceItem['text'] +
    //       '';
    //   }
    // }
    return _trace;
  }

}

export const Cw = new CwToolTraceClass();
