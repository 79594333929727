import {Result} from './result';

export class User extends Result {
  email: string;
  password: string;
  jid: string;
  name: string;
  applicant: string;
  roles: string[];
  domain: string;

  constructor() {
    super();
    this.email = '';
    this.password = '';
    this.jid = '';
    this.name = '';
    this.applicant = '';
    this.domain = '';
  }
}
