import {AppFeedbackSuccessModel} from './feedback.model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwWeb} from '@tool/web/tool-web';
import {AppWebTool} from '../app-web.tool';

class AppFeedbackToolClass {
  Data: AppFeedbackSuccessModel = {};
  error = ((message = 'Error') => {
    this.success({
      App: {
        main: message,
        secondary: 'Error',
      }
    })
  });
  tryAgain = ((message = 'Error') => {
    this.success({
      App: {
        main: 'Try the operation again ...',
        secondary: message,
      }
    })
  });
  success = ((Params: AppFeedbackSuccessModel) => {
    if (Params) {
      CwUtil.clear(this.Data);
      CwUtil.setProperties(Params, this.Data)
      if (Params.App) {
        CwUtil.clear(AppWebTool.Title);
        CwUtil.setProperties(
          Params.App,
          AppWebTool.Title,
        )
      }

      if (Params.GoTo) {
        CwWeb.goTo(Params.GoTo)
      } else {
        // CwWeb.goTo({path: '../../feedback'})
        // // 2006110844
        // CwWeb.goTo({
        //   path: 'FeedbackSection',
        //   section: true,
        // })
      }


    }

  })
}

export const AppFeedbackTool: AppFeedbackToolClass = new AppFeedbackToolClass();
