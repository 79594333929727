import {ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CwLiteral} from '../literal/literal';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwRestSetup} from '../tool/rest/model';

/**
 * @version 1906051110
 */
export class CwComponent implements OnInit {
  cwId = CwUtil.uuid();
  @Input() class: string;
  @Input() literal: string;
  @Input() required: boolean;

  @Input() ngModel: any;
  @Input() Setup: any;
  @Output() ngModelChange = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() change = new EventEmitter();

  protected Element: ElementRef;

  // 2005210000
  On = {
    blur: ((Event) => {
      this.blur.emit(Event);
    })
  };


  WebPrevious: any;

  get getVersion2(): boolean {
    return this.has('v2');
  }

  has(
    name: string,
    Element = (
      this.Element &&
      this.Element.nativeElement &&
      true
    )
      ? this.Element.nativeElement
      : undefined
  ): boolean {
    return (
      (
        Element &&
        Element.hasAttribute &&
        Element.hasAttribute(name) &&
        true
      ) ||
      (
        Element &&
        Element.getAttributeNames &&
        -1 < Element.getAttributeNames().indexOf(name) &&
        true) ||
      // Edge
      false
    );
  }

  onNgModelChangeBoolean(): boolean {
    if (typeof this.ngModel === 'boolean') {
      this.WebPrevious = !this.WebPrevious;
      this.ngModel = this.WebPrevious;
      this.ngModelChange.emit(this.ngModel);
    }
    return (typeof this.ngModel === 'boolean');
  }

  onNgModelChangeObject(event): boolean {
    const isObject = (
      this.ngModel &&
      this.ngModel.web !== undefined &&
      true
    );
    if (isObject) {
      this.ngModelChange.emit(event);
    }
    return isObject;
  }

  onNgModelChange(event): boolean {
    return (
      this.onNgModelChangeBoolean() ||
      this.onNgModelChangeObject(event) ||
      false
    );

  }

  onNgModelChange0(ValueNew: any): void {
    if (
      ValueNew &&
      (
        ValueNew.web ||
        ValueNew.web === false ||
        false
      ) &&
      true) {
      if (
        ValueNew &&
        this.WebPrevious !== ValueNew.web
      ) {
        this.WebPrevious = ValueNew.web;
        this.ngModel = ValueNew;
        this.ngModelChange.emit(ValueNew);
        this.change.emit(ValueNew);
      }
    } else {
      if (
        this.WebPrevious !== ValueNew
      ) {
        this.WebPrevious = ValueNew;
        this.ngModel = ValueNew;
        this.ngModelChange.emit(ValueNew);
        this.change.emit(ValueNew);
      }

    }
  }

  onLangChange(): void {

  }

  onInit(): void {
    this.WebPrevious = this.ngModel;
  }

  ngOnInit(): void {
    if (CwLiteral) {
      CwLiteral.onLangChange$.subscribe((Data) => {
        this.onLangChange();
      });
    }
    this.onInit();
  }


}

/**
 * @version 1907230828
 */
export abstract class CwClass {
  abstract Setup: any;

  // abstract APP_MODULE_IMPORTS: any[];
  // abstract APP_MODULE_PROVIDERS: any[];

  setup(_Setup: any) {
    this.Setup = _Setup;
  }

}
