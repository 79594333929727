import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlansRoutingModule } from './plans-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import { PlansViewStandardComponent } from './view/standard/plans-view-standard.component';
import {CwComponentsModule} from '@cw/components/component.module';
import { PlansSalesPassiveComponent } from './sales/plans-sales-passive.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    PlansRoutingModule,
    CwComponentsModule,
  ],
  exports: [
    PlansViewStandardComponent
  ],
  declarations: [PlansViewStandardComponent]
})
export class PlansModule { }
