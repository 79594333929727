<ng-container *ngIf="getAvailable">

  <ng-container
    *ngTemplateOutlet="Template2005210946"
  ></ng-container>

</ng-container>


<ng-template
  #Template2005210946
  xxx-2005210946
>
  <div class="d-flex justify-content-center flex-wrap flex-xl-nowrap">
    <ng-container *ngFor="let Plan of AppPlansTool.View.Plans">
      <div class="flex-fill border cw2005210946 p-3 mb-3">
        <cw-space-plan
          [PlanNew]="Plan"
        ></cw-space-plan>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #Template2004150000_23>
  <div class="d-flex justify-content-center flex-wrap flex-xl-nowrap">
    <ng-container *ngFor="let Plan of AppPlansTool.View.Plans">
      <div class="shadow p-3 m-3 flex-fill">
        <cw-space-plan
          [PlanNew]="Plan"
        ></cw-space-plan>
      </div>
    </ng-container>
  </div>
</ng-template>
