/**
 * @version R4.0 - Mobile - 1810040937
 */
import {CoAppLiteralClass} from '../literal/model';

export class CoAppFeedbackItemClass {

  key: string;
  literal: CoAppLiteralClass;

  constructor(_key: string, _literalMessage: string, _literalParameters: any = null ) {
    this.key = _key;
    this.literal = new CoAppLiteralClass(_literalMessage, _literalParameters);
  }

  getMessage(): string {
    return this.literal.get();
  }
}
