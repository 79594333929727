import {CurrencyPipe} from '@angular/common';
import {CwToolClass} from '../tool.model';
import {CwMap} from '../../model/model';
import {CwToolCurrenciesModel} from './tool-currencies.model';
import {CwSelectComponentI} from '../../components/select/select-model';
import {CwUtil} from '../util/cw-tool-util';
import {CwBillingCurrenciesBus} from '../../rest/billing/currencies/currencies-bus';
import {CwCountry} from '../../rest/literal/country/country-bus';
import {CwLocationBus} from '../../rest/info/location/location-bus';
import {CwLocationDoI} from '../../rest/info/location/location-model';
import {CwOnPremiseBus} from '../../rest/billing/on-premise/billing-on-premise-bus';

class CwToolCurrenciesClass extends CwToolClass<any> {

  private _isOnPremise = false;
  private _forceDisabled = false;

  CurrencyList = [];
  CurrencyMap: CwMap<any> = new Map<string, any>();
  DEFAULT = CwToolCurrenciesModel.DOLLAR;
  DISPLAY = 'symbol-narrow';
  DIGITS_INFO = '1.2-2';
  DIGITS_INFO_JPY = '1.0-0';
  Pipe = {
    Currency: <CurrencyPipe>undefined,
  };

  selected: string;
  Select = {
    Setup: <CwSelectComponentI>{
      OptionList: [],
    },
    Value: undefined,
    On: {
      changed: (
        (Data): void => {
          try {
            this.selected = this.Select.Value.code;
            this.changed();

          } catch (e) {
            console.error('CW2008171743', e);
          }
        }
      )
    }
  };
  Setup: any;


  Define = {
    _000_clear: (
      () => {
        this.selected = undefined;
        CwUtil.clear(this.CurrencyList);
        CwUtil.clear(this.Select.Setup.OptionList);
      }
    ), // _000_clear

    _100_currencyList: (
      () => {
        try {
          CwBillingCurrenciesBus.ItemsList.forEach(
            (item: string): void => {
              const CurrencyItem = CwCountry.CurrencyMap.get(item.toUpperCase());
              CurrencyItem.web = CurrencyItem.symbol + ' ' + CurrencyItem.name;
              this.CurrencyList.push(CurrencyItem);
              this.Select.Setup.OptionList.push(CurrencyItem);
              this.CurrencyMap.set(
                item.toUpperCase(),
                CurrencyItem
              );
            }
          );
        } catch (e) {
          console.error('CW2008141244', e);
        }
      }
    ), // currencyList

    _200_navigatorSelectedCurrencySelected: (
      (): void => {
        try {
          if (
            this.isEnabled &&
            window &&
            window.navigator &&
            window.navigator.language
          ) {
            const LanguageSplitList = window.navigator.language.split('-');
            const key = (LanguageSplitList && LanguageSplitList.length > 1)
              ? LanguageSplitList[1].toUpperCase()
              : window.navigator.language.toUpperCase()
            ;
            // /rest/billing/currencies
            const CountryCurrency = CwCountry.Items.get(key);
            if (CountryCurrency) {
              const CountryCurrencyList = CwCountry.Items.get(key).currencies;
              if (CountryCurrencyList) {
                CountryCurrencyList.forEach(
                  (Item) => {
                    if (
                      !this.selected &&
                      CwBillingCurrenciesBus.ItemsList.find(
                        (finder: string): boolean => {
                          return this.is(finder, Item.code);
                        }
                      )
                    ) {
                      this.selected = Item.code.toUpperCase();
                      this.changed();
                    }
                  }
                ); // forEach
              }
            }
            if (!this.selected) {
              this.selected = this.DEFAULT;
              this.changed();
            }
          }
        } catch (e) {
          console.error('CW2008140904', e);
        }
      }
    ), // navigatorSelectedCurrencySelected

    // _300_customerCurrency: ((): void => {
    //   try {
    //
    //     if (this.isDisabled && CwCustomerBus) {
    //       CwCustomerBus.Load.do({
    //         successActionCustom: (() => {
    //           if (CwCustomerBus.currency) {
    //             this.selected = CwCustomerBus.currency.toUpperCase();
    //             this.changed();
    //           }
    //         })
    //       });
    //     }
    //
    //   } catch (e) {
    //
    //   }
    // }), // _300_customerCurrency

    _400_dropdownSelected: (
      (): void => {
        try {
          if (this.isEnabled && this.selected) {
            this.getSelected.selected = true;
          }
        } catch (e) {
          console.error('CW2008201121', e);
        }
      }
    )
  }; // Define

  is = (
    (
      PlanOrCurrencyA: any | string,
      PlanOrCurrencyB: any | string
    ): boolean => {
      try {
        const currencyA: string = (typeof PlanOrCurrencyA === 'string')
          ? PlanOrCurrencyA
          : (PlanOrCurrencyA.currency)
            ? PlanOrCurrencyA.currency
            : this.getSelected.code
        ;
        const currencyB: string = (typeof PlanOrCurrencyB === 'string')
          ? PlanOrCurrencyB
          : PlanOrCurrencyB.currency
        ;
        return currencyA.toUpperCase() === currencyB.toUpperCase();
      } catch (e) {
        return false;
      }
    });

  isDefault = (
    (PlanOrCurrency: any | string): boolean => {
      return this.is(PlanOrCurrency, this.DEFAULT);
    });

  isSelected = (
    (PlanOrCurrency: any | string): boolean => {
      return this.is(
        PlanOrCurrency,
        (this.getSelected && this.getSelected.code)
          ? this.getSelected.code
          : this.DEFAULT
      );
    }
  );

  locationSelectedCurrencySelected = (
    (): void => {
      try {

        if (!navigator.geolocation) {
          console.log('Geolocation is not supported by your browser');
          return;
        }
        navigator.geolocation.getCurrentPosition(
          function showLocation(position) {
            CwLocationBus.do(<CwLocationDoI>{
              Bus: CwLocationBus,
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
              errorActionCustom: ((Error) => {
                console.log(Error);
              }),
              errorRestActionCustom: ((Error) => {
                console.log(Error);
              }),
              successActionCustom: ((Data) => {
                if (Data && Data.result !== undefined) {
                  CwLocationBus.countryCode = Data.result.countryISO2;
                  if (CwLocationBus.countryCode) {
                    CwCountry.Load.do({
                      successActionCustom: (
                        () => {
                          CwBillingCurrenciesBus.Load.do({
                            successActionCustom: (
                              () => {
                                CwToolCurrency.selected = CwToolCurrency.getCountryCurrencyCode(CwLocationBus.countryCode);
                                CwToolCurrency.changed();
                              }
                            )
                          });
                        }
                      )
                    });
                  }
                }
              }),
            });
          },
          function error(err) {
            console.log('Unable to retrieve your location:', err);
          });

      } catch (e) {
        console.error('CW2008140904', e);
      }
    });

  getCountryCurrencyCode = (
    (countryCode: string): string => {
      const CountryCurrency = CwCountry.Items.get(countryCode);
      let found = false;
      let countryCurrencyCode = this.DEFAULT;
      if (CountryCurrency) {
        const CountryCurrencyList = CountryCurrency.currencies;
        if (CountryCurrencyList) {
          CountryCurrencyList.forEach(
            (Item) => {
              if (
                CwBillingCurrenciesBus.ItemsList.find(
                  (finder: string): boolean => {
                    return !found &&
                      this.is(finder, Item.code);
                  }
                )
              ) {
                found = true;
                countryCurrencyCode = Item.code.toUpperCase();
              }
            }
          ); // forEach
        }
      }
      return countryCurrencyCode;
    }
  );

  format = ((Params: {
    value: number,
    currencyCode: string,
  }): string => {
    try {
      return this.Pipe.Currency.transform(
        Params.value,
        Params.currencyCode,
        'symbol-narrow',
        '1.2-2'
      );
    } catch (e) {
      console.error('CW2008201035', e);
      return undefined;
    }
  });



  // constructor ///////////////////////////////////////////////////////////////
  constructor() {
    super();
    this.subscribe((): void => {
      try {
        this.Select.Setup.OptionList.forEach((Item: any) => {
          Item.selected = this.isSelected(Item.code);
          if (Item.selected) {
            this.Select.Value = Item;
          }
        });

      } catch (e) {
        console.error('CW2008181724', e);
      }
    }); // this.subscribe

  }

  // constructor ///////////////////////////////////////////////////////////////


  // GETs //////////////////////////////////////////////////////////////////////
  get getSelected(): any {
    return (this.CurrencyMap.get(this.selected))
      ? this.CurrencyMap.get(this.selected)
      : this.CurrencyMap.get(this.DEFAULT)
      ;
  }

  get getSelectedCode(): string {
    return (this.getSelected)
      ? this.getSelected.code
      : this.DEFAULT
      ;
  }

  // GETs //////////////////////////////////////////////////////////////////////


  // ISs ///////////////////////////////////////////////////////////////////////

  get isDisabled(): boolean {
    return (
      CwOnPremiseBus.isOnPremise ||
      this._isOnPremise ||
      this._forceDisabled ||
      false
    );
  }

  get isEnabled(): boolean {
    return !this.isDisabled;
  }

  // ISs ///////////////////////////////////////////////////////////////////////


  // FUNCTIONs /////////////////////////////////////////////////////////////////

  setup() {
    if (this.isEnabled) {
      try {
        CwCountry.Load.do({
          successActionCustom: (
            (Data) => {
              CwBillingCurrenciesBus.Load.do({
                successActionCustom: this.define,
              });
            }
          )
        });

      } catch (e) {
        console.warn('2008140039', e);
      }
    }
  }

  getCountryCodeLocation(addrComponents: any): string {
    for (const Item of addrComponents) {
      if (Item.types[0] === 'country') {
        return Item.short_name;
      }
      if (Item.types.length === 2) {
        if (Item.types[0] === 'political') {
          return Item.short_name;
        }
      }
    }
  }

  // setupAdmin(Params: { _CurrencyPipe: CurrencyPipe }) {
  //   if (Params && Params._CurrencyPipe) {
  //     this.Pipe.Currency = Params._CurrencyPipe;
  //   }
  //   if (this.isEnabled) {
  //     try {
  //       CwWebBus.Load.do({
  //         successActionCustom: (() => {
  //           this._isOnPremise = CwWebBus.Is.onPremise;
  //           if (this.isEnabled) {
  //             // Free ?
  //             CwCurrentPlansBus.Load.do({
  //               successActionCustom: (() => {
  //
  //                 if (CwCurrentPlansBus.ItemsList.length === 0) {
  //                   // Example: 2007290000-free@yopmail.com
  //                   this._forceDisabled = false;
  //                 } else {
  //                   this._forceDisabled = true;
  //                 }
  //
  //                 CwBillingCurrenciesBus.Load.do({
  //                   successActionCustom: (() => {
  //                     CwCountry.Load.do({
  //                       successActionCustom: this.define
  //                     });
  //                   }),
  //                 });
  //
  //               }),
  //               token: CwTrust.token
  //             });
  //           }
  //         }),
  //         token: CwTrust.token,
  //       });
  //
  //     } catch (e) {
  //       console.error('CW2008191257', e);
  //     }
  //   } else {
  //     if (CwCustomerBus) {
  //       CwCustomerBus.Load.do();
  //     }
  //   }
  // }

  // FUNCTIONs /////////////////////////////////////////////////////////////////


}

// 2008130000
export const CwToolCurrency: CwToolCurrenciesClass = new CwToolCurrenciesClass();
