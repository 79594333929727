<div class="d-flex flex-fill  px-2">

  <div
    class="form-group input-group-sm w-100"
    [ngClass]="class"
  >
    <label *ngIf="literal"
           [translate]="literal"
           class="font-weight-bold text-dark text-justify"
    ></label>
    <ng-content></ng-content>
    <small class="form-text text-muted text-danger"
           *ngFor="let validationItem of ValidationList"
           [translate]="validationItem"
    ></small>
  </div>

</div>
