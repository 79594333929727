<div [ngClass]="{'d-none': (!getDisplay)}">


  <style>
    label {
      font-family: proxima_nova_ltsemibold;
    }
  </style>

  <form *ngIf="App.Body.visible"
        #formCtrl="ngForm"
        appCw
        appCwForm
  >

    <app-cw-row *ngIf="AppDataSignBus.action === 'PASSWORD'">
      <div R2>
        <app-cw-form
          literal="Please fill in the information to complete the subscription process"
        >
        </app-cw-form>
      </div>
    </app-cw-row>

    <app-cw-row class="bg-white" *ngIf="null === token || undefined === token">

      <app-cw-form R
                   literal="Email"
                   [ValidationList]="ConfirmEmailValidationList"
      >
        <input appCw
               appCwForm
               appCwFocus
               name="email"
               type="mail"
               ngModel #emailInput="ngModel"
               maxlength="80"
               [(ngModel)]="AppDataSignBus.email"
               email
               required
        />
      </app-cw-form>

      <app-cw-form R
                   literal="Confirm Email"
                   [ValidationList]="ConfirmEmailValidationList"
      >
        <input appCw
               appCwForm
               appCwBlockPaste
               appCwBlockCopy
               type="mail"
               name="confirmEmail"
               maxlength="80"
               ngModel
               #confirmEmailInput="ngModel"
               email
               required
               [(ngModel)]="confirmEmail"
               xxx-1905281217
               (change)="CsValidation.confirm(AppDataSignBus.email, confirmEmail, ConfirmEmailValidationList, true)"
               (blur)="CsValidation.confirm(AppDataSignBus.email, confirmEmail, ConfirmEmailValidationList, true)"
        />
      </app-cw-form>
    </app-cw-row>


    <app-cw-row
      *ngIf="null !== token && undefined !== token"
    >
      <app-cw-form R
                   literal="Email"
      >
        <input type="hidden"
               name="email"
               value="{{AppDataSignBus.email}}"
        />
        {{AppDataSignBus.email}}
      </app-cw-form>

    </app-cw-row>

    <app-cw-row>
      <app-cw-form R literal="Password"
                   [ValidationList]="ValidationList"
      >
        <input appCw
               appCwForm
               type="password"
               name="password"
               [(ngModel)]="AppDataSignBus.password"
               #passwordInput="ngModel"
               [pattern]="passwordPattern"
               required
               (change)="CsValidation.passwordOk(AppDataSignBus.password, ValidationList, true)"
        />
      </app-cw-form>

      <app-cw-form R literal="Confirm Password"
                   [ValidationList]="ConfirmValidationsList"
      >
        <input appCw
               appCwForm
               appCwBlockPaste
               type="password"
               name="confirmPassword"
               ngModel
               #confirmPasswordInput="ngModel"
               required
               [(ngModel)]="confirmPassword"
               (change)="CsValidation.confirm(AppDataSignBus.password, confirmPassword, ValidationList, true)"
               validateEqualTo="passwordInput"
        />
      </app-cw-form>

    </app-cw-row>


    <app-cw-row *ngIf="'PASSWORD' !== AppDataSignBus.action">

      <app-cw-form R literal="First Name">
        <input appCw
               appCwForm
               type="text"
               name="firstName"
               maxlength="35"
               [(ngModel)]="AppDataSignBus.firstName"
               #firstNameInput="ngModel"
               required
               ngDefaultControl
        />
      </app-cw-form>

      <app-cw-form R literal="Last Name">
        <input appCw
               appCwForm
               type="text"
               name="lastName"
               maxlength="40" [(ngModel)]="AppDataSignBus.lastName"
               #lastNameInput="ngModel"
               required
               ngDefaultControl
        />
      </app-cw-form>

    </app-cw-row>

    <app-cw-row *ngIf="'PASSWORD' !== AppDataSignBus.action">

      <app-cw-form R literal="Company Name">
        <input appCw
               appCwForm
               type="text"
               name="companyName"
               [(ngModel)]="companyName"
               #companyNameInput="ngModel"
               required
               maxlength="100"
        />
      </app-cw-form>

      <app-cw-form R literal="Country">
        <app-cw-select-country
          [(ngModel)]="Country"
          (change)="On.Country.change($event)"
          required
          [ngModelOptions]="{standalone: true}"
          ngDefaultControl
        ></app-cw-select-country>
      </app-cw-form>

    </app-cw-row>

    <app-cw-row *ngIf="'PASSWORD' !== AppDataSignBus.action">

      <app-cw-form R literal="Phone">
        <input appCw
               appCwForm
               type="text"
               name="phoneNumber"
               maxlength="40"
               [(ngModel)]="phoneNumber"
               #phoneInput="ngModel"
               required
        />
      </app-cw-form>

      <app-cw-form R literal="State">
        <app-cw-select2
          xxx-2005261100
          [Setup]="StateSetup"
          [(ngModel)]="State"
          (change)="On.State.change($event)"
          required
          ngDefaultControl
          [ngModelOptions]="{standalone: true}"
        ></app-cw-select2>
      </app-cw-form>

    </app-cw-row>


    <app-cw-row *ngIf="'PASSWORD' === AppDataSignBus.action">

      <app-cw-form R literal="First Name">
        <input appCw
               appCwForm
               type="text"
               name="firstName"
               maxlength="35"
               [(ngModel)]="AppDataSignBus.firstName"
               #firstNameInput="ngModel"
               required
               ngDefaultControl
               [ngModelOptions]="{standalone: true}"
        />
      </app-cw-form>

      <app-cw-form R literal="Last Name">
        <input appCw
               appCwForm
               type="text"
               name="lastName"
               maxlength="40" [(ngModel)]="AppDataSignBus.lastName"
               #lastNameInput="ngModel"
               required
               ngDefaultControl
               [ngModelOptions]="{standalone: true}"
        />
      </app-cw-form>

    </app-cw-row>

    <app-cw-row *ngIf="'PASSWORD' === AppDataSignBus.action">

      <app-cw-form R literal="Company Name"
      >
        <span>{{AppDataSignBus.companyName}}</span>
        <input type="hidden" name="companyName" value="{{AppDataSignBus.companyName}}"/>
      </app-cw-form>

    </app-cw-row>


    <!-- 2005080000 -->
    <!--    <app-cw-row-->
    <!--      xxx-2005060000-->
    <!--      *ngIf="Coupon.Visible.input"-->
    <!--    >-->
    <!--      <app-cw-form R2 literal="Coupon">-->
    <!--        <div class="d-flex">-->
    <!--          <input appCw-->
    <!--                 appCwForm-->
    <!--                 class="mr-2"-->
    <!--                 type="text"-->
    <!--                 name="coupon"-->
    <!--                 [(ngModel)]="Coupon.value"-->
    <!--                 [disabled]="getCouponInputDisabled"-->
    <!--          />-->
    <!--          <ng-container *ngIf="Coupon.Visible.validateButton">-->
    <!--            <button-->
    <!--              appCw-->
    <!--              appCwForm-->
    <!--              class="text-uppercase"-->
    <!--              translate="Validate"-->
    <!--              type="button"-->
    <!--              (click)="Coupon.validateButtonClicked()"-->
    <!--            ></button>-->
    <!--          </ng-container>-->
    <!--          <ng-container *ngIf="Coupon.Visible.removeButton">-->
    <!--            <button-->
    <!--              appCw-->
    <!--              appCwForm-->
    <!--              class="text-uppercase"-->
    <!--              translate="Remove"-->
    <!--              type="button"-->
    <!--              (click)="Coupon.removeButtonClicked()"-->
    <!--            ></button>-->
    <!--          </ng-container>-->
    <!--        </div>-->
    <!--        <ng-container *ngIf="Coupon.Visible.validMessage">-->
    <!--          <div-->
    <!--            class="form-text text-muted text-success"-->
    <!--            translate="Coupon valid."-->
    <!--          ></div>-->
    <!--        </ng-container>-->
    <!--        <ng-container *ngIf="Coupon.Visible.invalidMessage">-->
    <!--          <div-->
    <!--            class="form-text text-muted text-danger"-->
    <!--            translate="Coupon invalid."-->
    <!--          ></div>-->
    <!--        </ng-container>-->
    <!--      </app-cw-form>-->
    <!--    </app-cw-row>-->


    <app-cw-row xxx-2003301457>
      <div R2 xxx-2004150000-6>
        <cw-space-agree
          (privacy)=privacyChanged($event)
          (terms)="termsChanged($event)"
        ></cw-space-agree>
      </div>
    </app-cw-row>

    <div class="mt-2"></div>

    <app-cw-row>
      <button R
              appCw
              appCwForm
              (click)="signup()"
              type="button"
              [disabled]="getDisabled"
              xxx-2005291520
              class="btn-secondary"
      >{{ 'Sign Up' | translate | uppercase}}
      </button>
    </app-cw-row>

    <app-cw-row>
      <hr R2>
    </app-cw-row>

    <app-cw-row>

      <div R class="form-group input-group-sm w-100 d-flex justify-content-center justify-content-sm-end">
        <label translate="Already a member?"></label>
      </div>

      <div R class="form-group input-group-sm w-100 d-flex justify-content-center justify-content-sm-start">
        <a routerLink="/datasignin" class="font-bold">&#160;{{'Sign In' | translate}}</a>
      </div>

    </app-cw-row>

  </form>

</div>
