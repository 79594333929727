import {Component, ViewChild} from '@angular/core';
import {Result} from '../common/result';
import {NgForm} from '@angular/forms';
import {CwUserLogin} from '@cw/user/login/login-bus';
import {CwUserLoginDoI} from '@cw/user/login/login-model';
import {App} from '../app';
import {CwWeb} from '@tool/web/tool-web';
import {CwProtocol} from '@tool/protocol/tool-protocol';
import {AppPlansTool} from '../plans/plans.tool';
import {AppWebTool} from '../app-web.tool';
import {AppCw} from '../app.class';
import { OnExecuteData, ReCaptchaV3Service } from 'ng-recaptcha';
import {Subscription} from 'rxjs';

declare var protocolObject: any;

@Component({
  selector: 'app-data-sign-in',
  templateUrl: './data-sign-in.component.html',
})
export class DataSignInComponent extends AppCw {


  result = new Result();
  errorMessage: String;

  email: string;
  external: boolean;
  password: string;

  // reCaptchatv3 Token
  reCaptchav3Token: string;
  private subscription: Subscription;

  @ViewChild('formCtrl') public formCtrl: NgForm;

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super();

    // 1.11.1 - HotFix - Portal - Keep me logged in - 1902011306
    if (
      App &&
      App.KeepLoggedIn &&
      App.KeepLoggedIn.Keep &&
      App.KeepToken &&
      App.KeepToken.Keep &&
      true
    ) {
      CwWeb.goToAdmin(App.KeepToken.Keep);
      // window.location.href = '/admin/#/' + App.KeepToken.Keep;
    }

  }


  OnInit(): void {
    AppWebTool.Title.main = 'Sign In';

    this.subscription = this.recaptchaV3Service.onExecute.subscribe(
      (data: OnExecuteData) => {

      }
    );

    // 2004150000-7
    if (
      AppPlansTool &&
      AppPlansTool.Buy &&
      AppPlansTool.Buy.SignIn &&
      AppPlansTool.Buy.SignIn.password &&
      AppPlansTool.Buy.SignIn.email &&
      true
    ) {
      this.email = AppPlansTool.Buy.Registration.email;
      this.password = AppPlansTool.Buy.Registration.password;
      this.external = true;
      this.executeImportantAction();

    }
  }

  OnDestroy() {
    super.OnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  signin(reCaptchav3Token: string): void {
    if (
      this.external ||
      (
        this.formCtrl &&
        this.formCtrl.form &&
        this.formCtrl.form.valid &&
        true
      ) ||
      false
    ) {
      // 1904101050
      CwUserLogin.do(<CwUserLoginDoI>{
        Bus: CwUserLogin,
        Parameters: {
          email: this.email,
          password: this.password,
          reCaptchaV3Token: reCaptchav3Token
        },
        errorActionCustom: ((Data) => {
          // 2010141200.2
          this.errorMessage = Data.error;
          App.Feedback = {
            ...Data,
            contactAdminVisible: false,
          };
        }),
        errorRestActionCustom: ((Data) => {
          App.Feedback = Data;
        }),
        successActionCustom: ((Data, That) => {
          let admin = false;

          if (Data && Data.roles) {
            Data.roles.forEach(eachObj => {
              if (
                eachObj.componentName === 'OWNER' ||
                eachObj.componentName === 'ADMINISTRATOR' ||
                eachObj.componentName === 'ADMINISTRATOR_BILLING' ||
                eachObj.componentName === 'MONITOR'
              ) {
                admin = true;
              }
            });
          }
          if (
            !admin &&
            Data &&
            Data.token &&
            true
          ) {
            CwProtocol.check({
              token: Data.token
            });
          }

          if (
            Data &&
            Data.token &&
            true
          ) {
            if (App.KeepToken) {
              App.KeepToken.Keep = Data.token;
            }

            CwWeb.goToAdmin(Data.token);
          } else {
            App.KeepToken.remove();
          }

          // }
        })
      })

    }
  }

  public executeImportantAction(): void {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => this.signin( token ));
  }
}
