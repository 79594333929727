import {CwOnPremiseBus} from '../../rest/billing/on-premise/billing-on-premise-bus';
import {loadStripe, Stripe, StripeCardElementOptions, StripeElements} from '@stripe/stripe-js';
import {CwSafe} from '../tool-safe';
import {CwStripeToolCardCreateModel, CwStripeToolCardModel, CwStripeToolLocaleModel} from '@tool/stripe/stripe.model';
import {CwInputI, CwInputType} from '../../components/input/cw-input-model';
import {CwLiteral} from '../../literal/literal';
import {CwVal} from '@tool/validation/tool-validation';
import {CwFunction} from '@cw/model/model';
import {CwUtil} from '@tool/util/cw-tool-util';

class ToolStripeClass {

  PROD = {
    // tslint:disable-next-line:max-line-length
    key: '##cGtfbGl2ZV81MUg3ZEFTS2RkMkw2eXVVN0FsSmxDWWE3ME9Lb0RMZUxaa1VTVFJUbnJTMEg0QzRQaG9uMzR6d0Y3blBGWHhwTGpPcEp0eGtjaGVJb1d2TFAxSVltUHppcDAwd2ZsZGpNTXI=',
    class: '##U3RyaXBlRWxlbWVudC0tY29tcGxldGU=',
  };

  TEST = {
    // tslint:disable-next-line:max-line-length
    key: '##cGtfdGVzdF81MUg3ZEFTS2RkMkw2eXVVN2FDcUhNUFh4NU5ZMjJ5SDdsNWpsbmJaZmg5ZllDcUYyM1oyY0Jvb1hsRUxJQmtCdUwwV0RMcnRQMkluRTRvY0I2V09lbk1kSDAwUllCRnpjZDE=',
    class: '##U3RyaXBlRWxlbWVudC0tY29tcGxldGU=',
  };

  Api: Stripe;
  class = '';
  Elements: StripeElements;
  key = '';


  setup: (Params: { environmentProd?: boolean }) => void = ((Params: {
    environmentProd?: boolean
  }) => {
    if (
      Params && Params.environmentProd
    ) {
      this.key = this.PROD.key;
      this.class = this.PROD.class;
    } else {
      this.key = this.TEST.key;
      this.class = this.TEST.class;
    }
  });


  Card: CwStripeToolCardModel = {
    Element: undefined,
    create: (Params: CwStripeToolCardCreateModel): void => {
      const Options: StripeCardElementOptions = {
        hidePostalCode: true,
      };
      this.Card.Element = this.Elements.create('card', Options);
      this.Card.Element.on('change', (Event) => {
        if (Event.complete && Params && Params.successCallback) {
          this.createToken({
            Element: this.Card.Element,
            Input: Params.Input,
            successCallback: Params.successCallback,
            errorCallback: Params.errorCallback,
          });
        } else if (Event.error && Params && Params.errorCallback) {
          // show validation to customer
          Params.errorCallback(Event.error.message);
        }
      });
      this.Card.Element.mount(Params.DomElement);
    },
    destroy: () => {
      if (this.Card && this.Card.Element) {
        this.Card.Element.unmount();
        this.Card.Element.clear();
        this.Card.Element.destroy();
        this.Card.Element = undefined;
      }
    }
  };

  Element = {

    create: (Params: CwStripeToolCardCreateModel): void => {
      if (Params && Params.Input) {
        if (CwInputType.CARD_NUMBER === Params.Input.type) {
          this.Element.CardNumber.create(Params);
        } else if (CwInputType.CARD_CVC === Params.Input.type) {
          this.Element.CardCvc.create(Params);
        } else if (CwInputType.CARD_EXPIRY === Params.Input.type) {
          this.Element.CardExpiry.create(Params);
        }
      }
    },

    // https://stripe.com/docs/js/elements_object/create_element?type=cardNumber#elements_create-options
    CardCvc: {
      Element: undefined,
      create: (Params: CwStripeToolCardCreateModel): void => {
        this.Element.createToken({
          ...Params,
          CardElement: this.Element.CardCvc,
        });
      }
    },

    // https://stripe.com/docs/js/elements_object/create_element?type=cardNumber#elements_create-options
    CardExpiry: {
      Element: undefined,
      create: (Params: CwStripeToolCardCreateModel): void => {
        this.Element.createToken({
          ...Params,
          CardElement: this.Element.CardExpiry,
        });
      }
    },

    // https://stripe.com/docs/js/elements_object/create_element?type=cardNumber#elements_create-options
    CardNumber: {
      Element: undefined,
      create: (Params: CwStripeToolCardCreateModel): void => {
        this.Element.createToken({
          ...Params,
          CardElement: this.Element.CardNumber,
        });
      },
      InputItem: undefined,
    },

    createToken: (Params: CwStripeToolCardCreateModel): void => {
      try {

        const InputItem: CwInputI = (Params && Params.Input)
          ? Params.Input
          : undefined
        ;
        const InputSet: CwInputI[] = (Params && Params.InputSet)
          ? Params.InputSet
          : undefined
        ;
        if (Params && Params.DomElement && Params.CardElement) {

          const Options = {
            disabled: false,
            showIcon: true,
            // 2006091221
            style: {
              base: {
                textAlign: 'center'
              }
            }
          };

          if (Params.CardElement && Params.CardElement.Element && Params.CardElement.Element.destroy) {
            Params.CardElement.Element.destroy();
          }

          Params.CardElement.Element = this.Elements.create(<any>Params.Input.type, Options);
          Params.CardElement.Element.on('change', (Event) => {
            CwUtil.clear(InputItem.ValidationList);
            InputItem.changed = true;
            InputItem.Element = Params.CardElement.Element;
            if (Event && Event.complete && !Event.error && !Event.empty) {
              InputItem.valid = true;
              if (InputSet && CwVal.validateInputsSet(InputSet)) {
                const CardNumberElementList = InputSet.filter(
                  (Filter: CwInputI) => {
                    return (
                      Filter &&
                      Filter.Element &&
                      CwInputType &&
                      Filter.Element._componentName === CwInputType.CARD_NUMBER
                    );
                  });
                if (CardNumberElementList && CardNumberElementList[0]) {
                  this.createToken({
                    Element: CardNumberElementList[0].Element,
                    Input: CardNumberElementList[0],
                    successCallback: Params.successCallback,
                    errorCallback: Params.errorCallback,
                  });
                }
              }
            } else if (Event && Event.error) {
              InputItem.valid = false;
              InputItem.ValidationList.push(Event.error.message);
            } else if (Event && Event.empty) {
              // 2008042238
              InputItem.valid = false;
              InputItem.changed = true;
              InputItem.ValidationList.push('Cannot be empty.');
            }
          });
          Params.CardElement.Element.mount(Params.DomElement);
        }

      } catch (Exception) {
        console.log('2005270000-1', 'Exception', Exception);
      }

    },
  };

  // 2010290000.D11.3
  Sca = {
    // pi_1HpUdmKdd2L6yuU7NW6GfXhj_secret_bB6PBGXfLEniwMsoH0aDASwQ4
    // https://stripe.com/docs/js/payment_intents/confirm_card_payment
    // stripe.confirmCardPayment(clientSecret,data?,options?)
    // stripe.confirmCardPayment will return a Promise which resolves with a result object. This object has either:
    // result.paymentIntent: the successful PaymentIntent.
    // result.error: an error. Refer to the API reference for all possible errors.
    // Error
    // code: "payment_intent_authentication_failure"
    // doc_url: "https://stripe.com/docs/error-codes/payment-intent-authentication-failure"
    // message: "No podemos autenticar tu método de pago. Elige otro método y vuelve a intentarlo."
    // payment_intent: {id: "pi_1HpUpzKdd2L6yuU7U7jfO9sk", object: "payment_intent",
    // amount: 103, canceled_at: null, cancellation_reason: null, …}
    // source: {id: "card_1HpDSSKdd2L6yuU7UQ0eiGev", object: "card", address_city: null, address_country: null, address_line1: null, …}
    // type: "invalid_request_error"
    confirm: (Param: {
      clientSecret: string
      errorCallback: CwFunction,
      successCallback: CwFunction
    }) => {
      try {
        if (Param && Param.clientSecret && this.Api) {
          this.Api.confirmCardPayment(Param.clientSecret).then((Result) => {
            if (Result) {
              if (Result.error) {
                if (Param.errorCallback) {
                  Param.errorCallback(Result.error.message);
                } else {
                  console.error('2011201009');
                }
              } else if (Result.paymentIntent) {
                if (Result.paymentIntent.status === 'succeeded') {
                  if (Param.successCallback) {
                    Param.successCallback();
                  } else {
                    console.error('2011201011');
                  }
                } else {
                  if (Param.errorCallback) {
                    Param.errorCallback(Result.paymentIntent.cancellation_reason);
                  } else {
                    console.error('2011201014');
                  }
                }
              } else {
                console.error('2011201010');
              }
            } else {
              console.error('2011201006');
            }
          });
        } else if (Param && Param.clientSecret && !this.Api) {
          this.api();
          setTimeout(() => {
            this.Sca.confirm(Param);
          }, 500);

        } else {
          console.error('2011200959', 'SCA Error');
        }
      } catch (e) {
        console.error('2011201016');
      }
    },

  };

  Subscribe = {
    onPremiseBus: () => {
      if (CwOnPremiseBus) {
        CwOnPremiseBus.Bus$.subscribe(
          () => {
            this.setup({environmentProd: CwOnPremiseBus.isProduction});
            this.api();
          }
        );
      } else {
        setTimeout(this.Subscribe.onPremiseBus, 500);
      }
    },
    onLangChange: () => {
      if (CwLiteral && CwLiteral.onLangChange$) {
        CwLiteral.onLangChange$.subscribe(
          () => {
            this.api();
          }
        );
      } else {
        setTimeout(this.Subscribe.onLangChange, 500);
      }
    },
  };


  constructor() {
    Object.getOwnPropertyNames(this.Subscribe).forEach(property => {
      this.Subscribe[property]();
    });
  }

  async api() {
    let locale: CwStripeToolLocaleModel;
    const localeDefault = 'en';
    if (
      CwLiteral &&
      CwLiteral.LanguageSelected &&
      CwLiteral.LanguageSelected.language &&
      true
    ) {
      try {
        locale = <CwStripeToolLocaleModel>CwLiteral.LanguageSelected.language;
      } finally {
      }
    } else {
      locale = <CwStripeToolLocaleModel>localeDefault;
    }
    if (this.key) {
      CwStripe.Api = await loadStripe(
        CwSafe.get(this.key),
        (locale)
          ? {locale}
          : undefined
      );
    }
    if (CwStripe.Api) {
      CwStripe.Elements = CwStripe.Api.elements();
    } else {
      setTimeout(CwStripe.api, 1000);
    }
  }

  async createToken(Params: {
    Element: any,
    Input: CwInputI,
    errorCallback?: CwFunction,
    successCallback?: CwFunction,
  }) {
    await CwStripe.Api.createToken(Params.Element).then(
      (Result: { error?, token? }) => {
        if (Result && Result.token) {
          Params.Input.CardToken = Result.token;
          if (Params.successCallback) {
            Params.successCallback(Result.token);
          }
        } else {
          if (Params.errorCallback) {
            Params.errorCallback(Result.error);
          }
        }
      }
    );
  }
}

/**
 * @version 1910231156
 * @description https://github.com/stripe/stripe-js
 */
export const CwStripe = new ToolStripeClass();
