<!--d-flex flex-wrap justify-content-center-->
<div [ngClass]="getVisible">

  <div class="container py-3">

    <div class="row justify-content-center">

      <div class="col-9 col-lg-6">

        <ng-container *ngTemplateOutlet="TemplateForm"></ng-container>

        <ng-container *ngIf="getVisibleOnPremise">
          <ng-container *ngTemplateOutlet="TemplateActionOnPremise"></ng-container>
        </ng-container>
        <ng-container *ngIf="getVisibleOnCloud">
          <ng-container *ngTemplateOutlet="TemplateActionOnCloud"></ng-container>
        </ng-container>

      </div>

    </div>

  </div>

</div>

<ng-template #TemplateForm>
  <div class="row">
    <ng-container *ngFor="let Input of AppPlansSalesTool.InputsSet">
      <div
        class="col-12 col-sm-12 col-md-6 px-2"
        [ngClass]="Input.class"
      >
        <cw-input
          [Item]="Input"
        ></cw-input>
      </div>
    </ng-container>

    <div class="col-12 col-sm-12 col-md-6 px-0">
      <app-cw-form literal="Country" class="mb-0">
        <app-cw-select-country
          [(ngModel)]="Country"
          (change)="On.Country.change($event)"
          (blur)="On.Country.blur()"
          ngDefaultControl
          required
        ></app-cw-select-country>
      </app-cw-form>
      <div class="px-2">
        <cw-input [Item]="AppPlansSalesTool.Country"></cw-input>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-6 px-0">
      <app-cw-form literal="State" class="mb-0">
        <app-cw-select2
          [Setup]="StateSetup"
          [(ngModel)]="State"
          (change)="On.stateChange($event)"
          required
          ngDefaultControl
        ></app-cw-select2>
      </app-cw-form>
      <div class="px-2">
        <cw-input [Item]="AppPlansSalesTool.State"></cw-input>
      </div>
    </div>

    <div class="col-12 px-2">
      <cw-input
        [Item]="AppPlansSalesTool.Comments"
      ></cw-input>
    </div>
  </div>
</ng-template>

<ng-template #TemplateActionOnPremise>
  <div class="mb-3 row justify-content-center px-2">
    <div class="col-5">
      <ng-container *ngTemplateOutlet="TemplateSend"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #TemplateActionOnCloud>
  <div class="mb-3 row justify-content-center px-2">
    <cw-google-recaptcha
      (success)="On.googleRecaptchaSuccess()"
    ></cw-google-recaptcha>
    <div class="col pr-0">
      <ng-container *ngTemplateOutlet="TemplateSend"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #TemplateSend>
  <button
    type="button"
    class="btn btn-sm btn-primary my-3 text-truncate btn-gradient-primary btn-block"
    translate="Submit"
    [disabled]="getSendDisabled"
    (click)="On.sendClick()"
  ></button>
</ng-template>
