import {Component, OnInit} from '@angular/core';
import {CwWebinarFormBus} from '@rest/webinar/form/webinar-form';
import {ActivatedRoute} from '@angular/router';
import {CwWeb} from '@tool/web/tool-web';
import {CwInput, CwInputType} from '@cw/components/input/cw-input-model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwDateTool} from '@tool/date/date.tool';
import {CwVal} from '@tool/validation/tool-validation';
import {CwAnswerQuestionsByUserBus} from '@rest/webinar/answer-questions-by-user/answer-questions-by-user-bus';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.css']
})
export class WebinarComponent implements OnInit {

  DefaultQuestions = [];
  CustomQuestions = [];
  CwWeb = CwWeb;
  errorMessage = '';
  loading = false;
  isCountrySelectedDefaultQuestions = false;

  Country: any;
  State: any;
  StateSetup: any;

  InputFirstName = new CwInput({
    api: 'firstName',
    label: 'First Name',
    labelRequired: true,
    type: CwInputType.TEXT,
    Validate: {
      cannotBeEmpty: true,
    },
  });
  InputLastName = new CwInput({
    api: 'lastName',
    label: 'Last Name',
    labelRequired: true,
    type: CwInputType.TEXT,
    Validate: {
      cannotBeEmpty: true,
    },
  });
  InputEmail = new CwInput({
    api: 'email',
    label: 'Email',
    labelRequired: true,
    type: CwInputType.TEXT,
    Validate: {
      email: true,
      cannotBeEmpty: true,
    },
  });

  InputCountry = new CwInput(
      {
        api: 'country',
        label: 'Country',
        notLabel: true,
        type: CwInputType.VALIDATION,
        Validate: {
          passive: true,
          cannotBeEmpty: true,
        }
      },
  );

  InputState = new CwInput(
      {
        api: 'state',
        label: 'State',
        notLabel: true,
        type: CwInputType.VALIDATION,
        Validate: {
          cannotBeEmpty: true,
        }
      },
  );

  InputSet = [
    this.InputFirstName,
    this.InputLastName,
    this.InputEmail,
  ];

  WebinarInfo: any;

  constructor(  private route: ActivatedRoute) {
    if (this.route.snapshot.params &&
        this.route.snapshot.params.token) {
      CwWebinarFormBus.Setup = {};
      CwWebinarFormBus.Setup.token = this.route.snapshot.params.token;
    }
  }

  ngOnInit() {
    this.loading = true;
    this.WebinarInfo = {};
    CwWebinarFormBus.do({
      errorActionCustom: ((Data): void  => {
        this.errorMessage = Data.error;
        this.loading = false;
        console.log(Data, 'error');
      }),
      errorRestActionCustom: ((Data): void => {
        this.errorMessage = Data.error;
        this.loading = false;
        console.log(Data, 'error');
      }),
      successActionCustom: ((Data): void => {
        this.loading = false;
        if (Data.errorCode === 0 && Data.result) {
          this.WebinarInfo = Data.result;
          this.DefaultQuestions = CwUtil.clone(this.WebinarInfo.questions.filter(item => item.defaultQuestion));
          this.CustomQuestions = CwUtil.clone(this.WebinarInfo.questions.filter(item => !item.defaultQuestion));

          this.isCountrySelectedDefaultQuestions =
              this.DefaultQuestions.find(item => item.question.field === 'Country') !== undefined;

          this.DefaultQuestions.forEach((item) => {
            switch (item.question.field) {
              case 'Country': {
                this.InputCountry.labelRequired = item.required;
                this.InputCountry.Validate.cannotBeEmpty = item.required;
                item.Input = this.InputCountry;
                this.InputSet.push(this.InputCountry);
                break;
              }
              case 'State': {
                if (this.isCountrySelectedDefaultQuestions) {
                  this.InputState.labelRequired = item.required;
                  this.InputState.Validate.cannotBeEmpty = item.required;
                  item.Input = this.InputState;
                  this.InputSet.push(this.InputState);
                } else {
                  const input = new CwInput({
                    api: item.question.field,
                    label: item.question.field,
                    labelRequired: item.required,
                    type: CwInputType.TEXT,
                    Validate: {
                      cannotBeEmpty: item.required,
                    },
                  });
                  item.Input = input;
                  this.InputSet.push(input);
                }
                break;
              }
              default: {
                const input = new CwInput({
                  api: item.question.field,
                  label: item.question.field,
                  labelRequired: item.required,
                  type: CwInputType.TEXT,
                  Validate: {
                    cannotBeEmpty: item.required,
                  },
                });
                item.Input = input;
                this.InputSet.push(input);
                break;
              }
            }
          });

          this.CustomQuestions.forEach((item) => {
            switch (item.question.type) {
              case 0: {
                const input = new CwInput({
                  api: item.question.text,
                  label: item.question.text,
                  labelRequired: item.required,
                  type: CwInputType.TEXT,
                  Validate: {
                    cannotBeEmpty: item.required,
                  },
                });
                item.Input = input;
                break;
              }
              case 1: {
                const input = {
                  SelectSetup: {OptionList: [{id: '', web: ''}]},
                  Select: undefined,
                  changed: ((): void => {
                  }),
                };
                item.question.answers.forEach((answer) => {
                  input.SelectSetup.OptionList.push({id: answer.id, web: answer.text});
                });
                input.Select = input.SelectSetup.OptionList[0];
                item.Input = input;
                break;
              }
              case 2: {
                break;
              }
              default: return;
            }
          });
        }
      }),
    });
  }

  saveWebinar(): void {
    this.errorMessage = '';
    this.loading = false;
    const answers = {list: []};
    if (!this.getRegisterDisabled) {
      this.loading = true;
      this.DefaultQuestions.forEach((item) => {
        const answer = {
          id: item.id,
          required: item.required,
          question: {
            text: item.question.field,
            type: 0
          },
          answer: item.Input.value,
        };
        answers.list.push(answer);
      });
      this.CustomQuestions.forEach((item) => {
        switch (item.question.type) {
          case 0: {
            const answer = {
              id: item.id,
              required: item.required,
              question: item.question,
              answer: item.Input.value,
            };
            answers.list.push(answer);
            break;
          }
          case 1: {
            const answer = {
              id: item.id,
              required: item.required,
              question: item.question,
              answer: item.Input.Select,
            };
            answers.list.push(answer);
            break;
          }
          case 2: {
            const answer = {
              id: item.id,
              required: item.required,
              question: item.question,
              answer: item.question.answers.filter((answerItem) => answerItem.selected),
            };
            answers.list.push(answer);
            break;
          }
          default: return;
        }
      });

      CwAnswerQuestionsByUserBus.do({
        email: this.InputEmail.value,
        firstName: this.InputFirstName.value,
        lastName: this.InputLastName.value,
        sessionId: this.WebinarInfo.sessionId,
        token: CwWebinarFormBus.Setup.token,
        answers: answers,
        errorActionCustom: ((Data): void  => {
          this.loading = false;
          this.errorMessage = Data.error;
          console.log(Data, 'error');
        }),
        errorRestActionCustom: ((Data): void => {
          this.loading = false;
          this.errorMessage = Data.error;
          console.log(Data, 'error');
        }),
        successActionCustom: ((Data): void => {
          this.errorMessage = 'Operation successfully performed. Thanks for registering';
          this.loading = false;
        }),
      });
    }
  }

  get getRegisterDisabled(): boolean {

    const customQuestionInputs = [];
    let customQuestionInputsValid = true;
    let customQuestionMultipleInputValid = true;
    this.CustomQuestions.forEach((item) => {
      if (item.question.type === 0) {
        customQuestionInputs.push(item.Input);
      } else if (item.question.type === 1 && item.required) {
        customQuestionMultipleInputValid = item.Input.Select.id !== '';
      } else if (item.question.type === 2 && item.required) {
        customQuestionMultipleInputValid = item.question.answers.some(answer => answer.selected);
      }
      if (!customQuestionMultipleInputValid) {
        customQuestionInputsValid = false;
      }
     });
    const defaultValid = customQuestionInputs.length > 0 ?
        CwVal.validateInputsSet(this.InputSet) &&  CwVal.validateInputsSet(customQuestionInputs) :
        CwVal.validateInputsSet(this.InputSet);
    return !defaultValid || !customQuestionInputsValid;
  }

  get getNextSession(): any {
    return CwDateTool.localeWithTimeZone(this.WebinarInfo.nextSession, false);
  }

  onCountryChanged() {
    this.StateSetup = {
      OptionList: []
    };

    if (this.Country && this.Country.api) {
      this.State = undefined;

      if (this.Country.StateList) {
        this.StateSetup.OptionList.push(
            ...this.Country.StateList
        );
      }

      if (this.StateSetup.OptionList.length === 0) {
        this.StateSetup.OptionList.push({
          coGridSelected: false,
          coId: undefined,
          country_id: -1,
          cwKey: -1,
          id: -1,
          name: this.Country.api,
          web: this.Country.web,
        });
      }
      this.State = this.StateSetup.OptionList[0];

      this.onStateChanged();

      this.InputCountry.set(this.Country.api);
      this.InputCountry.changed = true;
    }
  }

  onStateChanged() {
    if (this.State && this.State.name) {
      this.InputState.set(this.State.name);
      this.InputState.changed = true;
    }
  }
}
