import {CwFunction} from './CollaborateSpaceWebTools/model/model';
import {App} from './app';
import {CwTokenGet} from './CollaborateSpaceWebTools/token/get/get-bus';
import {CwRestRequestParametersI} from './CollaborateSpaceWebTools/tool/rest/model';
import {AppWebTool} from './app-web.tool';

export const errorActionCustomD: CwFunction = ((Data) => {
  App.Feedback = Data;
});

/**
 * @version 1906270835
 */
export const tokenIsValid = ((
    action: string,
    token?: string,
) => {
  CwTokenGet.do(<CwRestRequestParametersI>{
    Bus: CwTokenGet,
    Parameters: {
      action,
      token,
    },
    successActionCustom: ((Data) => {

    }),
    errorRestActionCustom: ((Data) => {
      App.Feedback.contactAdminVisible = true;
      App.Feedback = Data;
      App.Body.visible = false;
    }),
    errorActionCustom: ((Data) => {
      App.Feedback = Data;
      App.Body.visible = false;
      // 2007291445
      AppWebTool.Title = {
        main: Data.error,
        secondary: 'Try the operation again ...',
      }
    }),
  });
});
