import {Subject} from 'rxjs/internal/Subject';
import {CwFunction} from '@cw/model/model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {OnDestroy} from '@angular/core';

export abstract class CwToolClass<T> implements OnDestroy {

  private Subject$ = new Subject();

  abstract Define: any;
  abstract Setup: T;

  changed = (() => {
    this.Subject$.next()
  });

  define = (
    (): void => {
      CwUtil.exec(this.Define);
    }
  )


  subscribe = (
    (nextFn: CwFunction) => {
      try {

        this.Subject$.subscribe(nextFn);

      } catch (e) {
        console.error('CW2008171716', e);
      }
    }
  );

  setup(_Setup: T) {
    this.Setup = _Setup;
  }


  // NGs ////////////////////////////////////////////////////////////////////////
  ngOnDestroy(): void {
    this.Subject$.unsubscribe();
  }

  // NGs ////////////////////////////////////////////////////////////////////////


}
