import {NgModule} from '@angular/core';
import {CwSelectModule} from './language/cw-select.module';
import {LoaderComponent} from './loader/loader.component';
import {DialogModule} from 'primeng/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {InputComponent} from './input/input.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CountrySelectComponent, SelectComponent} from './select/select.component';
import {OffsetLargeComponent} from './offset-large/offset-large.component';
import {TooltipComponent} from './tooltip/tooltip.component';
import {TooltipModule} from 'primeng/primeng';
import {RowComponent} from './row/row.component';
import {FormComponent} from './form/form.component';
import {CwModule} from '../cw.module';
import {ConfirmComponent} from './confirm/confirm.component';
import {CwDatePickerComponent} from './date-picker/cw-date-picker.component';
import {NgbDatepickerModule, NgbProgressbarModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { CwSpacePlanComponent } from './space/plan/cw-space-plan.component';
import {CwInputModule} from './input/cw-input.module';
import {SubscriptionIntervalComponent} from './subscription/subscription-interval/subscription-interval.component';
import {CwRadioModule} from './radio/cw-radio.module';
import { CwSpaceSummaryComponent } from './space/summary/cw-space-summary.component';
import { CwSpaceAgreeComponent } from './space/agree/cw-space-agree.component';
import {RouterModule} from '@angular/router';
import {CwPipesModule} from '@cw/pipes/cw-pipes.module';
import { CwSpacePlanMinimalComponent } from './space/plan/minimal/cw-space-plan-minimal.component';
import { CwSpaceAgreePassiveComponent } from './space/agree/passive/cw-space-agree-passive.component';
import { CwSpacePlanZoomComponent } from './space/plan/zoom/cw-space-plan-zoom.component';
import { CwSpacePlanDescriptionComponent } from './space/plan/description/cw-space-plan-description.component';
import { CwSpacePlanButtonComponent } from './space/plan/button/cw-space-plan-button.component';
import { CwAcceptedCardsComponent } from './accepted-cards/cw-accepted-cards.component';
import { CwSpaceBuyComponent } from './space/buy/cw-space-buy.component';
import { CwSpacePlanFeaturesComponent } from './space/plan/features/cw-space-plan-features.component';
import { CwReadyComponent } from './ready/cw-ready.component';
import { CwTextMiddleComponent } from './text/middle/cw-text-middle.component';
import { CwCurrencySelectComponent } from './currency/select/cw-currency-select.component';
import {AvatarComponent} from '@cw/components/avatar/avatar.component';

@NgModule({
  imports: [
    NgbDatepickerModule.forRoot(),
    CwSelectModule,
    DialogModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    CwModule,
    CwInputModule,
    CwRadioModule,
    RouterModule,
    CwPipesModule,
    NgbTooltipModule,
    NgbProgressbarModule,
  ],
  declarations: [
    AvatarComponent,
    LoaderComponent,
    InputComponent,
    SelectComponent,
    CountrySelectComponent,
    OffsetLargeComponent,
    TooltipComponent,
    RowComponent,
    FormComponent,
    ConfirmComponent,
    CwDatePickerComponent,
    CwSpacePlanComponent,
    SubscriptionIntervalComponent,
    CwSpaceSummaryComponent,
    CwSpaceAgreeComponent,
    CwSpacePlanMinimalComponent,
    CwSpaceAgreePassiveComponent,
    CwSpacePlanZoomComponent,
    CwSpacePlanDescriptionComponent,
    CwSpacePlanButtonComponent,
    CwAcceptedCardsComponent,
    CwSpaceBuyComponent,
    CwSpacePlanFeaturesComponent,
    CwReadyComponent,
    CwTextMiddleComponent,
    CwCurrencySelectComponent,
  ],
  exports: [
    AvatarComponent,
    CwSelectModule,
    LoaderComponent,
    InputComponent,
    SelectComponent,
    CountrySelectComponent,
    OffsetLargeComponent,
    TooltipComponent,
    RowComponent,
    FormComponent,
    ConfirmComponent,
    CwDatePickerComponent,
    CwSpacePlanComponent,
    SubscriptionIntervalComponent,
    CwSpaceSummaryComponent,
    CwSpaceAgreeComponent,
    CwSpaceAgreePassiveComponent,
    CwSpacePlanZoomComponent,
    CwAcceptedCardsComponent,
    CwSpaceBuyComponent,
    CwSpacePlanFeaturesComponent,
    CwSpacePlanMinimalComponent,
    CwReadyComponent,
    CwTextMiddleComponent,
    CwCurrencySelectComponent,
  ],
  providers: [
  ]
})
export class CwComponentsModule {
}
