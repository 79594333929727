export const CwStringTool = {
  /**
   * @version 2003301457
   * @description Replace the search case insensitive by the replace
   * @param source
   * @param search
   * @param replace
   */
  replaceCaseInsensitive: (
    source: string,
    search: string,
    replace: string
  ): string => {
    const pattern = new RegExp(search, 'gi');
    return source.replace(pattern, replace);
  }
}
