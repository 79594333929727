import {CwSetup} from '../../model/model';
import {CwToolClass} from '../tool.model';
import {CwCallback} from '../rest/model';
import {CwSafe} from '../tool-safe';
import {CwStripe} from '../stripe/tool';
import {CwLiteral} from '../../literal/literal';

declare var StripeCheckout: any;

export interface CwToolPaySetupI extends CwSetup {
  email: string;
}

/**
 * https://stripe.com/docs/checkout#integration-custom
 */
class CwToolPayClass extends CwToolClass<CwSetup> {

  private _CardCallback: CwCallback;

  get CardCallback(): CwCallback {
    return this._CardCallback;
  }

  set CardCallback(value: CwCallback) {
    if (value) {
      this._CardCallback = value;
    }
  }

  CardHandler: any;
  CwStripe = CwStripe;
  Setup: CwToolPaySetupI = {
    email: 'videotechsupport@clearone.com'
  };


  create(Setup: CwToolPaySetupI = CwPayTool.Setup): void {
    // 1905221934
    CwPayTool.CardHandler = undefined;
    CwPayTool.CardHandler = StripeCheckout.configure({
        // 1901181612 - 1910241309
        key: CwSafe.get(CwStripe.key),
        color: 'orange',
        // image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
        // 1905221918
        locale: CwLiteral.Language.language,
        email: CwPayTool.Setup.email,
        token: function (token) {
          if (
            CwPayTool.CardCallback &&
            CwPayTool.CardCallback.success &&
            CwPayTool.CardCallback.success.action &&
            true
          ) {
            CwPayTool.CardCallback.success.action(token.id);
          }

        },
        opened: function (Event) {
        },
        closed: function (Event) {
          if (
            CwPayTool.CardCallback &&
            CwPayTool.CardCallback.closed &&
            CwPayTool.CardCallback.closed.action &&
            true
          ) {
            CwPayTool.CardCallback.closed.action();
          }
        },

      },
    );
  }

  card(literal: string, callback?: CwCallback): void {
    CwPayTool.create();
    this.CardCallback = callback;
    this.CardHandler.open({
      name: CwLiteral.translate('Credit or debit card'),
      // description: '2 widgets',
      // zipCode: true,
      // amount: 2000
      panelLabel: literal,
      allowRememberMe: false,
    });
  }

  Define: any;

}

export const CwPayTool = new CwToolPayClass();
