import {CW_DATE_TOOL, DateAddModel, DateDurationFormatEnum, DateDurationI, DateDurationUnitEnum} from './date.model';
import * as Moment from 'moment';
import {DatePipe, formatDate} from '@angular/common';
import {CwIs} from '../tool-is';
import {CwLiteral} from '@cw/literal/literal';

/**
 * @version 1911281603
 */
class CwDateToolClass {

  formatToMoment = ((formatAngular: string): string => {
    Object.keys(CW_DATE_TOOL.FORMAT_MOMENT).forEach(keyItem => {
      formatAngular = formatAngular.replace(keyItem, CW_DATE_TOOL.FORMAT_MOMENT[keyItem]);
    });
    return formatAngular;
  });

  toString = ((Parameters: {
    Date: Date,
    format: string
  }): string => {
    let out = '';
    if (
      Parameters &&
      Parameters.Date &&
      Parameters.format &&
      Moment &&
      true
    ) {
      out = Moment(Parameters.Date).format(this.formatToMoment(Parameters.format));
    }
    return out;
  });


  /**
   * @version 1910310846
   * @param _Utc
   * @param defaultReturn
   */
  utcToDateTimeZone = ((_Utc: any, defaultReturn = new Date()): Date => {
    const _DateMoment = (
        _Utc &&
        _Utc instanceof Date &&
        true
      ) ? Moment(_Utc)
      : (
        _Utc &&
        typeof _Utc === 'string' &&
        true
      ) ? Moment(this.utcToDate(_Utc))
        : null
    ;
    return (_DateMoment)
      ? _DateMoment
        .add((-1) * (new Date()).getTimezoneOffset(), 'minute')
        .toDate()
      : defaultReturn
      ;
  });


  utcToDate = ((_utc: string, defaultReturn = new Date()): Date => {
    let _Date = defaultReturn;
    if (_utc) {
      const _DateArray = _utc.toLowerCase().split('z');
      if (_DateArray) {
        const _DateString = _DateArray[0];
        if (_DateString) {
          _Date = Moment(_DateString.replace('t', ' ')).toDate();
        }
      }
    }
    return _Date;
  });


  isLess = ((_Less: Date, _Greater: Date): boolean => {
    return (
      ((_Less)
          ? _Less.getTime()
          : 0
      ) < (
        (_Greater)
          ? _Greater.getTime()
          : 0
      )
    );
  });


  toEndDay = ((DateTo: Date, _newDate = true, _utc = true): Date => {
    const _date = (_newDate)
      ? (_utc)
        ? this.toUTC(DateTo)
        : new Date(DateTo)
      : DateTo
    ;
    _date.setHours(23, 59, 59, 999);
    return _date;
  });


  toBeginningDay = ((DateTo: Date, _newDate = true, _utc = true): Date => {
    const _date = (_newDate)
      ? (_utc)
        ? this.toUTC(DateTo)
        : new Date(DateTo)
      : DateTo
    ;
    _date.setHours(0, 0, 0, 1);
    return _date;
  });

  toFullUTC = ((DateToUTC: Date): Date => {
    DateToUTC = new Date(
      Date.UTC(
        DateToUTC.getFullYear(),
        DateToUTC.getMonth(),
        DateToUTC.getDate(),
        DateToUTC.getMinutes(),
        DateToUTC.getSeconds(),
        DateToUTC.getMilliseconds()
      )
    );
    return DateToUTC;
  });

  todayUTC = ((): Date => {
    return this.toUTC(new Date());
  });

  toUTC = ((DateToUTC: Date): Date => {
    DateToUTC = new Date(
      Date.UTC(
        DateToUTC.getFullYear(),
        DateToUTC.getMonth(),
        DateToUTC.getDate(),
        12, 0, 0, 0
      )
    );
    return DateToUTC;
  });


  monthAgoUTC = ((): Date => {
    const _today = new Date();
    return this.toUTC(
      new Date(
        _today.getFullYear(),
        _today.getMonth() - 1,
        _today.getDate(),
      )
    );
  });


  yearAgoUTC = ((): Date => {
    const _today = new Date();
    return this.toUTC(
      new Date(
        _today.getFullYear() - 1,
        _today.getMonth(),
        _today.getDate(),
      )
    );
  });


  nowUTC = ((): Date => {
    const _today = new Date();
    return new Date(
      Date.UTC(
        _today.getFullYear(),
        _today.getMonth(),
        _today.getDate(),
        _today.getHours(),
        _today.getMinutes(),
        _today.getSeconds(),
        _today.getMilliseconds()
      )
    );
  });


  /**
   * @description
   * 2017-11-21T00:00:01.000Z
   */
  defineDateToUtc = ((_DateToString: Date, _toEnd = true): string => {
    let _date = '';
    if (_DateToString) {
      // 1811260953
      _date = formatDate(_DateToString, 'yyyy-LL-dd', 'EN');
      if (_toEnd) {
        _date += 'T23:59:59.999Z';
      } else {
        _date += 'T00:00:00.001Z';

      }
    }
    return _date;
  });


  // 1901101818
  duration = ((param: DateDurationI): string => {
    const locale = 'en-us';
    let _duration = '';
    // unit default value
    if (!param.unit) {
      param.unit = DateDurationUnitEnum.second;
    }
    if (!param.format) {
      param.format = DateDurationFormatEnum.time;
    }

    if (param.format === DateDurationFormatEnum.humanize) {
      _duration = Moment.duration(
        param.duration,
        ((param.unit)
            ? (
              (param.unit === DateDurationUnitEnum.second)
                ? DateDurationUnitEnum.second
                : null
            )
            : DateDurationUnitEnum.second
        )
      )
        .locale(locale)
        .humanize();
    } else if (param.format === DateDurationFormatEnum.web) {
      let _format = 's \'s\'';
      if (param.unit === DateDurationUnitEnum.second) {
        if (param.duration >= 60 * 60) {
          _format = 'h:mm:ss';
        } else if (param.duration >= 60) {
          _format = 'm:ss \'m\'';
        }
      }
      _duration = formatDate(
        new Date(1970, 0, 1, 0, 0, param.duration),
        _format,
        locale
      );
    } else if (param.format === DateDurationFormatEnum.time) {
      _duration = formatDate(
        new Date(1970, 0, 1, 24, 0, param.duration),
        (param.duration >= 60 * 60)
          ? 'hh:mm:ss'
          : '00:mm:ss'
        ,
        locale
      );
    }
    return _duration;
  });


  // 1907251253
  dateToString = ((Param?: {
                     date?: Date,
                     format?: string,
                     language?: string
                   }
  ): string => {
    if (!Param) {
      Param = {
        date: undefined,
        format: undefined,
        language: undefined
      };
    }
    if (!Param.date) {
      Param.date = new Date();
    }
    if (!Param.format) {
      Param.format = 'yyyy-MM-dd';
    }
    if (!Param.language) {
      Param.language = 'en';
    }
    let _dateToString = null;
    try {
      if (CwIs.date(Param.date)) {
        // https://angular.io/api/common/DatePipe
        const datePipe = new DatePipe(Param.language);
        _dateToString = datePipe.transform(Param.date, Param.format);
      }
    } catch (error) {

      try {
        const datePipe = new DatePipe('en');
        _dateToString = datePipe.transform(Param.date, Param.format);

      } catch (errorEn) {

        console.error('1907290851', error);
      }

    }
    return _dateToString;
  });

  // 1912131221
  yyyymmddToDate = ((dateString: string, separator = '-'): Date => {
    if (dateString) {
      const DateArray = dateString.split(separator);
      return new Date(
        // tslint:disable-next-line:radix
        Number.parseInt(DateArray[0]),
        // tslint:disable-next-line:radix
        Number.parseInt(DateArray[1]) - 1,
        // tslint:disable-next-line:radix
        Number.parseInt(DateArray[2])
      );
    } else {
      return null;
    }
  });

  dateToNgbDateStruct = ((DateTo?: Date): any => {
    if (
      !DateTo ||
      DateTo === null ||
      DateTo === undefined ||
      false
    ) {
      DateTo = new Date();
    }
    return {
      day: DateTo.getDate(),
      month: DateTo.getMonth() + 1,
      year: DateTo.getFullYear(),
    };
  });

  // 2002281031-4
  add = ((Params: DateAddModel): Date => {
    let _add: Date = null;
    if (Params && Params.Source !== undefined) {
      if (Params.days) {
        Params.Source.setDate(Params.Source.getDate() + Params.days);
      }
      if (Params.months) {
        Params.Source.setMonth(Params.Source.getMonth() + Params.months);
      }
      if (Params.years) {
        Params.Source.setFullYear(Params.Source.getFullYear() + Params.years);
      }
      _add = Params.Source;
    }
    return _add;
  });

  localeWithTimeZone = ((date: any, onlyDateNoTime: boolean): any => {
    if (date) {
      const localLocale = Moment(this.utcToDateTimeZone(date));
      localLocale.locale(CwLiteral.Language.language);
      if (localLocale && onlyDateNoTime) {
        return localLocale.format('L');
      } else {
        return localLocale.format('L LT');
      }
    } else {
      return null;
    }
  });

  localeDefaultWithTimeZone = ((date: any): any => {
    if (date) {
      Moment.locale(CwLiteral.Language.language);
      const localLocale = Moment(date, 'L');
      Moment.locale('en');
      const defaultLocale = Moment(localLocale.toDate());
      if (defaultLocale) {
        return Moment(this.utcToDateTimeZone(defaultLocale.toDate(), null)).format('L');
      }
    } else {
      return null;
    }
  });

  // 2003300855
  tomorrow = (): Date => this.add({Source: new Date(), days: 1});
}


/**
 * @version 1911281603
 */
export const CwDateTool = new CwDateToolClass();
