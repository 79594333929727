import {Component} from '@angular/core';
import {CoAppLoaderWidgetBus40} from './bus';

@Component({
  selector: 'co-app-loader-widget40',
  templateUrl: './component.html',
  styleUrls: ['./component.css']
})
export class CoAppLoaderWidget40Component {

  // @ViewChild('CoModalDiv') CoModalDiv: any;

  CoAppLoaderWidgetBus40 = CoAppLoaderWidgetBus40;
}
