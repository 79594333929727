import {InfoBus} from '../info-bus';
import {CwDefineUrl, CwFunction} from '@cw/model/model';
import {CwLiteral} from '@cw/literal/literal';
import {RATES_TOP_AFRICA, RATES_TOP_AMERICA, RATES_TOP_ASIA, RATES_TOP_EUROPE} from './phone-rates-model';
import {CwDefine} from '@tool/define/tool-define';

/**
 * @version 1904251312
 */
class InfoPhoneRatesBus extends InfoBus {

  DefineProperties: CwFunction[] = [
    CwDefine.flag
  ];

  // 1904251312
  get getItemListAmerica(): any[] {
    return this.ItemsList.filter(RateItem =>
      RateItem.continentCode === 'NA' ||
      RateItem.continentCode === 'SA' ||
      false
    );
  }

  get getItemListAmericaTop(): any[] {
    return this.getItemListAmerica.filter(RateItem =>
      RATES_TOP_AMERICA.find(
        rateTarget =>
          rateTarget === RateItem.countryCode
      )
    );
  }

  get getItemListEurope(): any[] {
    return this.ItemsList.filter(RateItem =>
      RateItem.continentCode === 'EU' ||
      false
    );
  }

  get getItemListEuropeTop(): any[] {
    return this.getItemListEurope.filter(RateItem =>
      RATES_TOP_EUROPE.find(
        rateTarget =>
          rateTarget === RateItem.countryCode
      )
    );
  }

  get getItemListAfrica(): any[] {
    return this.ItemsList.filter(RateItem =>
      RateItem.continentCode === 'AF' ||
      false
    );
  }

  get getItemListAfricaTop(): any[] {
    return this.getItemListAfrica.filter(RateItem =>
      RATES_TOP_AFRICA.find(
        rateTarget =>
          rateTarget === RateItem.countryCode
      )
    );
  }

  get getItemListAsia(): any[] {
    return this.ItemsList.filter(RateItem =>
      RateItem.continentCode === 'AS' ||
      false
    );
  }

  get getItemListAsiaTop(): any[] {
    return this.ItemsList.filter(RateItem =>
      RATES_TOP_ASIA.find(
        rateTarget =>
          rateTarget === RateItem.countryCode
      )
    );
  }

  defineUrl: CwDefineUrl = (() =>
      CwInfoPhoneRatesBus.path +
      (
        (
          // 1904251312
          CwLiteral &&
          // CwLiteral.Setup &&
          CwLiteral.LanguageSelected &&
          CwLiteral.LanguageSelected.language &&
          // // 1904251312
          // CwLiteral &&
          // CwLiteral.Setup &&
          // CwLiteral.Setup.Service &&
          // CwLiteral.Setup.Service.getBrowserLang &&
          true
        )
          ? CwLiteral.LanguageSelected.language
          : 'en'
      ) +
      '/phonerates' +
      ''
  );

  do: CwFunction = (() => CwInfoPhoneRatesBus.restGet({
    Parameters: {},
    Bus: CwInfoPhoneRatesBus,
  }));

}

/**
 * @version 1904101050
 */
export const CwInfoPhoneRatesBus = new InfoPhoneRatesBus();


// @Injectable()
// export class RatesService extends BasicService{
//
//     url: string;
//
//     countriesRates: CountryRates[] = [];
//     extendedCountriesRates: CountryRates[];
//     globalCountriesRates: CountryRates[] = [];
//
//     constructor(private http:Http, private translate: TranslateService) {
//         super();
//         this.url = AppSettings.API_ENDPOINT + "info/" + translate.getBrowserLang() + "/phonerates";
//     }
//
//     getPhoneRates(): Observable<CountryRates[]> {
//         let headers = new Headers({ 'Content-type': 'application/json'});
//         let options = new RequestOptions({headers: headers});
//
//         return this.http.get(this.url,options)
//             .map(this.extractData.bind(this))
//             .catch(this.handleError);
//     }
//
//     extractData(res: Response) {
//         let body = res.json();
//
//         this.extendedCountriesRates= body.countriesRates;
//
//         let i:number = 0;
//         let j:number = 0;
//         let countryRates;
//         for(countryRates of this.extendedCountriesRates) {
//
//             if(AppSettings.RATES_AM.find(x => x === countryRates.countryCode)) {
//                 this.countriesRates[i] = countryRates;
//                 i++;
//             }
//             else if(AppSettings.RATES_EU.find(x => x === countryRates.countryCode)) {
//                 this.countriesRates[i] = countryRates;
//                 i++;
//             }
//             else if(AppSettings.RATES_AS.find(x => x === countryRates.countryCode)) {
//                 this.countriesRates[i] = countryRates;
//                 i++;
//             }
//             else if(AppSettings.RATES_AF.find(x => x === countryRates.countryCode)) {
//                 this.countriesRates[i] = countryRates;
//                 i++;
//             }
//
//             if(AppSettings.RATES_GLOBAL.find(x => x === countryRates.countryCode)) {
//                 this.globalCountriesRates[j] = countryRates;
//                 j++;
//             }
//
//         }
//
//         return body;
//     }
//
// }








