import {CoLog} from '../utils/log';

export class CoError implements Error {

  name = 'CO ERROR';
  message: string;
  stack: string;
  coId: string;


  constructor(coId: string, ...params: any[]) {
    this.coId = coId;
    CoLog.error(coId, ...params, this.message, this.stack);
  }
}
