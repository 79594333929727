import {Injectable, OnDestroy} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {InformModalComponent} from '../../modal/inform-modal/inform-modal.component';

@Injectable()
export class ModalService implements OnDestroy {

  private modalData = {
    size: 'lg',
    backdrop: 'static',
    centered: false,
    title: '',
    body: '',
    result: false,
    content: '',
    isPrompt: false,
    emitConfirm: false,
    isUseHtmlTitle: false,
    isUseHtmlBody: false
  };


  public get ModalData() {
    return this.modalData;
  }

  constructor(private modal: NgbModal,
              private translate: TranslateService) { }

  public showInformModal() {
    const modalRef = this.modal.open(InformModalComponent, this.getOptionsDisplayModal());

    this.setHtmlTitleIfPassingString();

    this.setHtmlBodyIfPassingString();

    modalRef.componentInstance.data = {
      title: this.modalData.title,
      body: this.modalData.body,
      isPrompt: this.modalData.isPrompt,
      emitConfirm: this.modalData.emitConfirm
    };
    if (this.modalData.emitConfirm) {
      modalRef.componentInstance.emitConfirmAction.subscribe((confirm) => {
        return confirm;
      });
    }
  }

  public showError(errorTitle, errorMsg) {

    const modalRef = this.modal.open(InformModalComponent, {centered: false});
    modalRef.componentInstance.data = {
      title: errorTitle,
      body: `<div class="text-left alert ${'alert-danger'}">${errorMsg}</div>`,
      isPrompt: false,
      emitConfirm: false
    };

  }

  private getOptionsDisplayModal() {
    const options: any = {};
    options.backdrop = this.modalData.backdrop === 'static' ? 'static' : true;
    if (this.modalData.size === 'lg' || this.modalData.size === 'sm') {
      options.size = this.modalData.size;
    }
    options.centered = this.modalData.centered;
    return options;
  }

  /**
   * set html for title if passing string from i18n
   * Ex: Passing 'SETTINGS.TITLE' => parse to 'Setting Title'
   */
  private setHtmlTitleIfPassingString() {
    if (!this.modalData.isUseHtmlTitle) {
      this.translate.get(this.modalData.title).subscribe(title => {
        this.modalData.title = title;
      });
    }
  }

  /**
   * set html for title if passing string from i18n
   * Ex: Passing 'SETTINGS.BODY' and result = false => <div class="text-left alert alert-danger">'Setting body'</div>
   */

  private setHtmlBodyIfPassingString() {
    if (!this.modalData.isUseHtmlBody) {
      this.translate.get(this.modalData.body).subscribe(body => {
        this.modalData.body = `<div class="text-left alert ${this.modalData.result ? 'alert-success' : 'alert-danger'}">${body}</div>`;
      });
    }
  }

  ngOnDestroy(): void {
    this.modalData = {
      size: 'lg',
      backdrop: 'static',
      centered: false,
      title: '',
      body: '',
      result: false,
      content: '',
      isPrompt: false,
      emitConfirm: false,
      isUseHtmlTitle: false,
      isUseHtmlBody: false
    };
  }
}
