import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ConnectionRoutingModule} from './connection-routing.module';
import {ConnectionCheckComponent} from './check/check.component';
import {ConnectionSelectionComponent} from './selection/selection.component';
import {ConnectionAppComponent} from './app/connection-app.component';
import {CwModule} from '../CollaborateSpaceWebTools/cw.module';
import {ConnectionAppWindowsComponent} from './app/windows/connection-app-windows.component';
import {ConnectionAppIosComponent} from './app/ios/connection-app-ios.component';
import {ConnectionAppAndroidComponent} from './app/android/connection-app-android.component';
import {FormsModule} from '@angular/forms';
import {CwComponentsModule} from '../CollaborateSpaceWebTools/components/component.module';
import {ConnectionAppIosChromeComponent} from './app/ios/chrome/connection-app-ios-chrome.component';
import {ConnectionAppIsStillOpenComponent} from './app/is-still-open/connection-app-is-still-open.component';
import {ConnectionClickHereOrDownloadComponent} from './click-here-download/connection-click-here-or-download.component';
import {DataSignUpPassiveTrialComponent} from '../data-sign-up/passive/data-sign-up-passive-trial.component';
import {DataSingUpModuleModule} from '../data-sign-up/data-sing-up-module.module';

@NgModule({
  imports: [
    CommonModule,
    ConnectionRoutingModule,
    CwModule,
    FormsModule,
    CwComponentsModule,
    DataSingUpModuleModule,
  ],
  declarations: [
    ConnectionCheckComponent,
    ConnectionSelectionComponent,
    ConnectionAppComponent,
    ConnectionAppWindowsComponent,
    ConnectionAppIosComponent,
    ConnectionAppAndroidComponent,
    ConnectionAppIosChromeComponent,
    ConnectionAppIsStillOpenComponent,
    ConnectionClickHereOrDownloadComponent,
  ],
  exports: [
    ConnectionCheckComponent,
    ConnectionSelectionComponent,
    ConnectionAppComponent,
    ConnectionAppWindowsComponent,
    ConnectionAppIosComponent,
    ConnectionAppAndroidComponent,
    ConnectionAppIosChromeComponent,
    ConnectionAppIsStillOpenComponent,
    ConnectionClickHereOrDownloadComponent,
  ]
})
export class ConnectionModule {
}
