<form #formCtrl="ngForm"
      *ngIf="App.Body.visible"
      appCw
      appCwForm
>

  <ng-container #PassContainer>

    <app-cw-row class="d-block d-md-none">
      <ng-container R *ngTemplateOutlet="PassTemplate"></ng-container>
    </app-cw-row>

    <app-cw-row class="d-none d-md-block">
      <ng-container R2 *ngTemplateOutlet="PassTemplate"></ng-container>
    </app-cw-row>

  </ng-container>

  <app-cw-row>

    <button R
            appCw
            appCwForm
            (click)="accept()"
            type="button"
            [disabled]="!formCtrl.form.valid || !CsValidation.valid(ValidationList)"
    >{{ 'Accept' | translate}}
    </button>

  </app-cw-row>

</form>








<ng-template #PassTemplate>
  <app-cw-form literal="Enter your new password"
               [ValidationList]="ValidationList"
  >
    <input
        appCw
        appCwFocus
        appCwForm
        appCwBlockCopy
        appCwBlockPaste
        name="password"
        type="password"
        class="form-control"
        (keyup.enter)="accept()"
        ngModel
        #passwordInput="ngModel"
        [(ngModel)]="password"
        required
        (change)="CsValidation.passwordOk(password, ValidationList, true)"
        (keyup)="CsValidation.passwordOk(password, ValidationList, true)"
        style="margin-top: 5px !important;"
    >
  </app-cw-form>
</ng-template>

