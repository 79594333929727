import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConnectionCheckComponent} from './check/check.component';
import {ConnectionSelectionComponent} from './selection/selection.component';
import {ConnectionAppWindowsComponent} from './app/windows/connection-app-windows.component';
import {ConnectionAppIsStillOpenComponent} from './app/is-still-open/connection-app-is-still-open.component';
import {ConnectionClickHereOrDownloadComponent} from './click-here-download/connection-click-here-or-download.component';

const routes: Routes = [
  {
    path: 'download',
    children: [
      {
        path: '',
        component: ConnectionCheckComponent,
      },
      {
        path: 'check/:token',
        component: ConnectionCheckComponent,
      },
      {
        path: 'select',
        component: ConnectionSelectionComponent,
      },
      {
        path: 'app',
        children: [
          {
            path: 'is-still-open',
            component: ConnectionAppIsStillOpenComponent,
          },

          {
            path: 'windows',
            children: [
              {
                path: '',
                component: ConnectionAppWindowsComponent,
              },
              {
                path: '**',
                component: ConnectionAppWindowsComponent,
              },
            ]
          },

          {
            path: 'android',
            children: [
              {
                path: '',
                component: ConnectionClickHereOrDownloadComponent,
              },
              {
                path: '**',
                component: ConnectionClickHereOrDownloadComponent,
              },
            ],
          },

          {
            path: 'ios',
            children: [
              {
                path: '',
                component: ConnectionClickHereOrDownloadComponent,
              },
              {
                path: 'chrome',
                // Tcket 3860
                component: ConnectionClickHereOrDownloadComponent,
                // component: ConnectionAppIosChromeComponent,
              },
              {
                path: '**',
                component: ConnectionClickHereOrDownloadComponent,
              },
            ],
          },

          {
            path: '',
            component: ConnectionAppWindowsComponent,
          },
          {
            path: '**',
            component: ConnectionAppWindowsComponent,
          },

        ],

      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConnectionRoutingModule {
}
