<ng-container *ngIf="CwToolCurrency.isEnabled">
  <div
    class="h5"
    translate="Currency"
  ></div>
  <app-cw-select2
    required
    ngDefaultControl

    [Setup]="CwToolCurrency.Select.Setup"
    (change)="CwToolCurrency.Select.On.changed($event)"
    [(ngModel)]="CwToolCurrency.Select.Value"

  ></app-cw-select2>

</ng-container>
