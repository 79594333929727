import {EntityBus} from '../entity-bus';
import {CwDefineUrl, CwFunction} from '../../model/model';
import {EntityInvitationDoI} from './invitation-model';

/**
 * @version 1904101050
 */
class EntityInvitationBus extends EntityBus {
  DefineProperties: CwFunction[];


  defineUrl: CwDefineUrl = (() =>
      CwEntityInvitation.path + 'invitation' +
      '?email=' + CwEntityInvitation.Parameters.email +
      '&password=' + encodeURIComponent(CwEntityInvitation.Parameters.password) +
      '&jid=' + CwEntityInvitation.Parameters.jid +
      '&name=' + CwEntityInvitation.Parameters.name +
      '&applicant=' + CwEntityInvitation.Parameters.applicant +
      ''
  );


  do: CwFunction = ((Parameters: EntityInvitationDoI) =>
      CwEntityInvitation.restPost({
        Bus: CwEntityInvitation,
        Parameters: Parameters,
      })
  );

  parameterRuleValidation = (() =>
      CwEntityInvitation &&
      CwEntityInvitation.Parameters &&
      CwEntityInvitation.Parameters.applicant &&
      CwEntityInvitation.Parameters.email &&
      CwEntityInvitation.Parameters.jid &&
      CwEntityInvitation.Parameters.name &&
      CwEntityInvitation.Parameters.password &&
      true
  );

  Parameters: EntityInvitationDoI;
}

export const CwEntityInvitation = new EntityInvitationBus();

// // 1904101050
// url = AppSettings.API_ENDPOINT + 'entity/invitation';
// createFreeFromInvitation(user: User): any {
//     let headers = new Headers({'Content-type': 'application/json'});
//     let options = new RequestOptions({headers: headers});
//     return this.http.post(this.url +
//     '?email=' +     user.email +
//     '&password=' + encodeURIComponent(user.password) +
//     '&jid=' + user.jid +
//     '&name=' + user.name +
//     '&applicant=' + user.applicant
//     , options)
//         .map(this.extractData2.bind(this))
//         .catch(this.handleError);
// }
