<form appCw appCwForm>

  <ng-container #DialHeaderContainer>

    <app-cw-row class="d-block d-md-none" #Mobile>
      <div R class="d-flex">
        <ng-container *ngTemplateOutlet="DialHeaderTemplate"></ng-container>
      </div>
    </app-cw-row>

    <app-cw-row class="d-none d-md-block" #Mobile>
      <div R2 class="d-flex">
        <ng-container *ngTemplateOutlet="DialHeaderTemplate"></ng-container>
      </div>
    </app-cw-row>

  </ng-container>


  <ng-container #DialBodyContainer>

    <app-cw-row *ngFor="let DialInItem of App.DialIn" class="d-block d-md-none" #Mobile>
      <small R class="d-flex align-items-center my-2">
        <ng-container *ngTemplateOutlet="DialBodyTemplate;context{DialInItem:DialInItem}"></ng-container>
      </small>
    </app-cw-row>

    <app-cw-row *ngFor="let DialInItem of App.DialIn" class="d-none d-md-block" #Mobile>
      <div R2 class="d-flex align-items-center my-2">
        <ng-container *ngTemplateOutlet="DialBodyTemplate;context{DialInItem:DialInItem}"></ng-container>
      </div>
    </app-cw-row>

  </ng-container>

</form>






<ng-template #DialBodyTemplate let-DialInItem="DialInItem">
  <div class="text-left  w-25">
    <a [href]="sanitize(DialInItem.phoneNumber)">
      <img
          class="rounded-circle w-25"
          [src]="DialInItem?._flag"
      />
    </a>

  </div>
  <div class="text-left w-25 text-truncate">
    <div>{{DialInItem.country|translate}}</div>
  </div>
  <div class="text-right w-50 text-truncate">
    <div>{{DialInItem.phoneNumber}}</div>
  </div>
</ng-template>


<ng-template #DialHeaderTemplate>
  <div class="text-left  w-25 bg-light"></div>
  <div class="text-left w-25 bg-light" translate>Country</div>
  <div class="text-center w-50 bg-light" translate>Phone</div>
</ng-template>
