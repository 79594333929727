import {PartnerBus} from '../partner-bus';
import {CwDefineUrl, CwFunction, CwSetupI, CwTokenBusI} from '../../model/model';
import {CwToolKeepC} from '../../tool/keep/tool-keep';
import {formatDate} from '@angular/common';
import {CwBillingTypeValue} from '../../billing/billing-model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwPartnerEntitiesBus} from '../../rest/partner/entities/partner-entities.bus';
import {CwSession} from '../../session/session-bus';
import {CwWeb} from '../../tool/web/tool-web';

class EntitiesBus extends PartnerBus {

  // 2011231615
  gridId = 'entities';
  GridParentComponent: any;
  partnerId: string;

  DefineProperties: CwFunction[] = [
    // sCreationDate
    (Properties: any) => {
      if (
        Properties &&
        Properties.creationDate &&
        true
      ) {
        Properties.sCreationDate =
          formatDate(Properties.creationDate, 'mediumDate', 'EN');
      }
    },
    // sBillingType
    (Properties: any) => {
      if (
        Properties &&
        Properties.billing_type &&
        true
      ) {
        Properties.sCreationDate =
          (Properties.billing_type === CwBillingTypeValue.creditCard)
            ? 'Portal'
            : ((Properties.billing_type === CwBillingTypeValue.partner)
              ? Properties.partner.name
              : ''
            );
      }
    },
  ]
  ;
  Keep: CwToolKeepC<any>;
  Setup: CwSetupI;
  SuccessActionList: any[];
  SuccessActionOnceList: any[];
  TokenBus: CwTokenBusI;
  defineUrl: CwDefineUrl = ((): string => {
    // /partner/2/entities/8aca515d-d325-4cfb-a936-64edccbeb4a3
    return CwPartnerEntities.path +
      CwPartnerEntities.partnerId +
      '/entities/'
      ;
  });

  do = ((partnerId: string = this.partnerId) => {
    if (
      partnerId &&
      true
    ) {
      if (
        CwPartnerEntities &&
        CwPartnerEntities.GridParentComponent &&
        CwPartnerEntities.GridParentComponent.refresh &&
        true
      ) {
        CwPartnerEntities.GridParentComponent.refresh(
          CwPartnerEntities.GridParentComponent
        );
      }
      CwPartnerEntities.partnerId = CwSession.partnerId;
    }

    // 2011231615
    CwPartnerEntitiesBus.do({
      Bus: CwPartnerEntitiesBus,
      token: CwSession.token,
      partnerId: CwSession.partnerId,
      successActionCustom: (Data) => {
        this.defineData(Data.result);
        CwWeb.loaderHide();
      }
    });
  });

  // 1904011208
  clean() {
    CwUtil.clear(CwPartnerEntities.Items);
    CwUtil.clear(CwPartnerEntities.ItemsList);
    CwUtil.clear(CwPartnerEntities.Grid.ValuesList);
  }


  defineList(Data: any[]) {
    this.clear();
    this.clean();
    // active: true
    // banner: false
    // billingId: "cus_Hmtc4la0QMxiHk"
    // brandingOn: false
    // bussinesName: "0102RISHABH@GMAIL.COM"
    // cloudSpaceTotal: 0
    // cloudSpaceUsed: 0
    // connectionId: "572709BC-5CC0-45BC-A31B-6889230816EA"
    // contact: "0102rishabh@gmail"
    // creationDate: "2020-06-09"
    // customEmailSettingsOn: false
    // email: "0102rishabh@gmail.com"
    // gatewayLicenses: 0
    // id: 6178
    // language: "en_US"
    // login: "0102rishabh@gmail.com"
    // partner: {billing_type: "STRIPE_CREDIT_CARD", id: 1, inboundCallId: 0, name: "CLEARONE"}
    // partnerId: 1
    // phoneCallsCredit: 0
    // purchaseOrder: ""
    // redirection: ""
    // usersNumber: 0
    // vatId: ""
    // 0: CoComponentsGridColumn {coHeader: "Name", coField: "bussinesName", coType: undefined, coOrderBy: "bussinesName"}
    // 1: CoComponentsGridColumn {coHeader: "Email", coField: "email", coType: undefined, coOrderBy: "email"}
    // 2: CoComponentsGridColumn {coHeader: "Created", coField: "creationDate", coType: undefined, coOrderBy: "creationDate"}
    // 3: CoComponentsGridColumn {coHeader: "Purchase Order", coField: "purchaseOrder", coType: undefined, coOrderBy: "purchaseOrder"}
    // length: 4
    // const Grid = CwToolGrid2.get(this.gridId);
    // if (Grid) {
    //   Grid.Column.add(
    //     new CwGrid2Head({
    //       literal: 'Name',
    //       property: 'businessName',
    //       Component: CwGrid2ItemTextComponent,
    //     }),
    //     new CwGrid2Head({
    //       literal: 'Email',
    //       property: 'email',
    //       Component: CwGrid2ItemTextComponent,
    //     }),
    //     new CwGrid2Head({
    //       literal: 'Created',
    //       property: 'creationDate',
    //       Component: CwGrid2ItemTextComponent,
    //     }),
    //     new CwGrid2Head({
    //       literal: 'Purchase Order',
    //       property: 'purchaseOrder',
    //       Component: CwGrid2ItemTextComponent,
    //     })
    //   );
    //   for (const _Item of Data) {
    //
    //     Grid.Item.add(
    //       new CwGrid2Item({
    //         Data: {
    //           businessName: _Item.bussinesName,
    //           email: _Item.email,
    //           creationDate: _Item.creationDate,
    //           purchaseOrder: _Item.purchaseOrder,
    //           entityId: _Item.id,
    //         },
    //         id: _Item.id,
    //       })
    //     );
    //
    //
    //   }
    // }

    CwUtil.clear(Data);
  }


}

export const CwPartnerEntities = new EntitiesBus();
