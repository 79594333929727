import {EnvironmentActionI, EnvironmentActionsI} from '../app/CollaborateSpaceWebTools/model/model';
import {CW_WEB_BUS} from '../app/CollaborateSpaceWebTools/rest/token/get-web/get-web-model';

export const ACTIONS_API = {
  profile: 'PROFILE',
  webinar: 'WEBINAR',
  users: 'USERS',
  userDetails: 'USER_DETAILS',
  usersAdd: 'ADD_USERS_OPTIONS',
  deviceDetails: 'DEVICE_DETAILS',
  devices: 'VIDEO_DEVICES',
  settings: 'ADVANCED',
  phonecalls: 'PHONE_CALLS',
  phoneExtensions : 'PHONE_EXTENSIONS',
  onCloud: 'ONCLOUD_BILLING',
  billingInformation: 'BILLING_INFORMATION',
  // 1909061153
  branding: 'BRANDING',
  ldap: 'LDAP',
  searchable: 'SEARCHABLE',
  recording: 'MEETING_SCREEN_RECORDING',
  history: 'ACTION_HISTORY',
  invoices: 'INVOICES',
  meetingDetails: 'MEETING_DETAILS',
};


export const ACTIONS_MAP = new Map()
  .set(CW_WEB_BUS.MENU.ACCOUNTS.API, <EnvironmentActionI>{
    Translate: {literal: CW_WEB_BUS.MENU.ACCOUNTS.LITERAL, },
    routerLink: ['/partner/accounts'],
  })
  .set(CW_WEB_BUS.MENU.OAUTH2.API, <EnvironmentActionI>{
    Translate: {literal: CW_WEB_BUS.MENU.OAUTH2.LITERAL, },
    routerLink: ['/advanced/oauth2'],
  })
  .set(CW_WEB_BUS.MENU.PHONE_EXTENSIONS.API, <EnvironmentActionI>{
    Translate: {literal: CW_WEB_BUS.MENU.PHONE_EXTENSIONS.LITERAL, },
    routerLink: ['/advanced/phone-extensions'],
  })
  .set(ACTIONS_API.history, <EnvironmentActionI>{
    Translate: {literal: 'History', },
    routerLink: ['/advanced/history'],
  })
  .set(CW_WEB_BUS.MENU.DASHBOARD.API, <EnvironmentActionI>{
    Translate: {literal: CW_WEB_BUS.MENU.DASHBOARD.LITERAL, },
    routerLink: ['/advanced/dashboard'],
  })
  .set(ACTIONS_API.onCloud, <EnvironmentActionI>{
    Translate: {literal: 'On Cloud Billing', },
    routerLink: ['/advanced/on-cloud'],
    iframeLink : ['collaborate-space/oncloud_billing']
  })
  .set(ACTIONS_API.phonecalls, <EnvironmentActionI>{
    Translate: {literal: 'Phone Credit', },
    routerLink: ['/advanced/calls'],
  })
  .set(ACTIONS_API.settings, <EnvironmentActionI>{
    Translate: {literal: 'Settings', },
    routerLink: ['/advanced/settings'],
    iframeLink : ['settings/collaborate-space']
  })
  .set(ACTIONS_API.devices, <EnvironmentActionI>{
    Translate: {literal: 'Devices', },
    routerLink: ['/advanced/devices'],
    iframeLink : ['video-collaboration']
  })
  .set(ACTIONS_API.deviceDetails, <EnvironmentActionI>{
    Translate: {literal: 'Devices', },
    routerLink: ['/advanced/device-details'],
    iframeLink: ['device-details'],
  })
  .set(ACTIONS_API.users, <EnvironmentActionI>{
    Translate: {literal: 'Users', },
    routerLink: ['/advanced/users'],
    iframeLink : ['collaborate-space/users']
  })
  .set(ACTIONS_API.userDetails, <EnvironmentActionI>{
    Translate: {literal: 'Users', },
    routerLink: ['/advanced/user-details'],
    iframeLink : ['user-details']
  })
  .set(ACTIONS_API.profile, <EnvironmentActionI>{
    Translate: {literal: 'Profile', },
    routerLink: ['/basic/profile'],
  })
  .set(ACTIONS_API.webinar, <EnvironmentActionI>{
    Translate: {literal: 'Webinar'},
    routerLink: ['/basic/webinar'],
  })
  // 2009290000
  .set(CW_WEB_BUS.MENU.CDRS.API, <EnvironmentActionI>{
    Translate: {literal: CW_WEB_BUS.MENU.CDRS.LITERAL, },
    routerLink: ['/advanced/meetings-calls'],
  })
  .set(CW_WEB_BUS.MENU.MEETINGS.API, <EnvironmentActionI>{
    Translate: {literal: CW_WEB_BUS.MENU.MEETINGS.LITERAL, },
    routerLink: ['/advanced/meetings'],
  })
  .set(ACTIONS_API.meetingDetails, <EnvironmentActionI>{
    Translate: {literal: 'Meetings', },
    routerLink: ['/advanced/meeting-details'],
    iframeLink : ['meeting-details']
  })

;

// GET /rest/entity/listAvailableActionTypes
export const HISTORY_MAP = new Map()
  .set('Update Settings', {action: ACTIONS_MAP.get(ACTIONS_API.settings), activeTab: 1})
  .set('Update On Cloud Billing', {action: ACTIONS_MAP.get(ACTIONS_API.onCloud), activeTab: 2, ownerOnly: true})
  .set('Update Payment Method', {action: ACTIONS_MAP.get(ACTIONS_API.onCloud), activeTab: 2, ownerOnly: true})
  .set('Pay Order/Invoice', {action: ACTIONS_MAP.get(ACTIONS_API.onCloud), activeTab: 3, ownerOnly: true})
  .set('Update Subscription', {action: ACTIONS_MAP.get(ACTIONS_API.onCloud), activeTab: 1})
  .set('Add/Invite Users', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Remove Users Invitation', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Resend Users Invitation', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Request Password Reset', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Change Role of Users', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Update User', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Update Users', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Create User', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Delete Users', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('User Registered', {action: ACTIONS_MAP.get(ACTIONS_API.users), activeTab: 1})
  .set('Change Role of Devices', {action: ACTIONS_MAP.get(ACTIONS_API.devices), activeTab: 1})
  .set('Update Devices', {action: ACTIONS_MAP.get(ACTIONS_API.devices), activeTab: 1})
  .set('Delete Devices', {action: ACTIONS_MAP.get(ACTIONS_API.devices), activeTab: 1})
  .set('Create Device', {action: ACTIONS_MAP.get(ACTIONS_API.devices), activeTab: 1})
  .set('Create Phone Extension', {action: ACTIONS_MAP.get(ACTIONS_API.phoneExtensions), activeTab: 1})
  .set('Delete Phone Extension', {action: ACTIONS_MAP.get(ACTIONS_API.phoneExtensions), activeTab: 1})
  .set('Update Phone Extension', {action: ACTIONS_MAP.get(ACTIONS_API.phoneExtensions), activeTab: 1})
;


export const ACTIONS = {
  IFRAME: <EnvironmentActionsI>{
    HISTORY: <EnvironmentActionI>{
      iframe: true,
      name: 'HISTORY',
      state: true,
      path: '/history',
    },
    USERS: <EnvironmentActionI>{
      iframe: true,
      name: 'USERS',
      state: true,
      path: '/users',
    },
    PHONE_CALLS: <EnvironmentActionI>{
      iframe: true,
      name: 'PHONE_CALLS',
      state: true,
      path: '/phonecalls',
    },
    RESOURCES: <EnvironmentActionI>{
      iframe: true,
      name: 'RESOURCES',
      state: true,
      path: '/resources',
    },
    // 1908190840
    RESOURCES_VIEW: <EnvironmentActionI>{
      iframe: true,
      name: 'RESOURCES',
      state: true,
      path: '/resources',
      viewOnly: true,
      userOptions: true,
    },
    CURRENT_PLANS: <EnvironmentActionI>{
      iframe: true,
      name: 'CURRENT_PLANS',
      state: true,
      path: '/currentplans',
    },
    INVOICES: <EnvironmentActionI>{
      iframe: true,
      name: 'PHONE_INVOICES',
      state: true,
      path: '/invoices',
    },
    BILLING_INFORMATION: <EnvironmentActionI>{
      iframe: true,
      name: 'BILLING_INFORMATION',
      state: true,
      path: '/billinginformation',
    },
    ADVANCED: <EnvironmentActionI>{
      iframe: true,
      name: 'ADVANCED',
      state: true,
      title: 'Advanced',
      icon: 'icon icon-sound-mix',
      path: '/settings',
      // path: '/iframe/settings',
    },
    PHONE_EXTENSIONS: <EnvironmentActionI>{
      iframe: true,
      name: 'PHONE_EXTENSIONS',
      state: true,
      path: '/phoneextensions',
    },

  }
};
