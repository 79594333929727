import {HttpHeaders} from '@angular/common/http';
import {CwBusClass} from '../../model/bus';
import {CwRestRequestParametersI, CwRestRequestParametersT, CwRestSetup} from './model';
import {environment} from '../../../../environments/environment';
import {CwBusStateType} from '../../model/model';


class ToolRestClass {
  private _HEADERS =
    {
      // 'Content-Type': 'application/json',
      // 'Content-Type': 'text/plain',
      // To avoid Internet Explorer caching
      'Cache-Control': 'no-cache,no-store,must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    };

  private _headers = new HttpHeaders(this._HEADERS);

  /**
   * @version 1912101603
   */
  delete = ((Parameters: CwRestRequestParametersT): void => {
    this._method = 'DELETE';
    this.doing(Parameters);
  });

  headersDefine = ((Bus: CwBusClass): void => {
    if (Bus && Bus.bearer) {
      this._headers = new HttpHeaders({
        ...this._HEADERS,
        'Authorization': 'Bearer ' + Bus.bearer,
      });
    }
  });

  manager = ((Params: {
    RequestParameters: CwRestRequestParametersI,
    action: 'successActionCustom' | 'errorActionCustom' | 'errorRestActionCustom'
  }) => {
    if (
      Params &&
      Params.action &&
      Params.RequestParameters &&
      Params.RequestParameters.Bus &&
      Params.RequestParameters.Bus.Load.ActionsToDoList &&
      true
    ) {
      while (Params.RequestParameters.Bus.Load.ActionsToDoList.length !== 0) {
        // 1910280940
        const _Actions = Params.RequestParameters.Bus.Load.ActionsToDoList.shift();
        // const _Actions = Params.RequestParameters.Bus.Load.ActionsToDoList.pop();
        if (
          _Actions &&
          _Actions[Params.action] &&
          true
        ) {
          _Actions[Params.action]();
        }
      }
    }
  });

  Setup: CwRestSetup;

  private _method = 'GET';

  setup(_Setup: CwRestSetup) {
    this.Setup = _Setup;
  }

  getUrl(Bus: CwBusClass): string {
    let url = '';

    // 1904240944
    if (
      (
        Bus &&
        Bus.apiBus &&
        this.Setup &&
        this.Setup.url &&
        true
      ) ||
      (
        !Bus &&
        this.Setup &&
        this.Setup.url &&
        true
      ) ||
      false
    ) {
      url += this.Setup.url;
    }

    // // 1904240944
    // if (this.Setup.url) {
    //     url += this.Setup.url;
    // }
    if (
      Bus &&
      Bus.Setup &&
      Bus.Setup.url &&
      true
    ) {
      url += Bus.Setup.url;
    }
    if (Bus.Param.url) {
      url += Bus.Param.url;
    }
    if (Bus.defineUrl) {
      url += Bus.defineUrl();
    }

    if (!Bus.bearer && Bus && Bus.TokenBus) {
      url += (Bus.TokenBus.token)
        ? (url.lastIndexOf('/'))
          ? Bus.TokenBus.token
          : '/' + Bus.TokenBus.token
        : ''
      ;
    }
    if (!Bus.bearer && this.getToken(Bus)) {
      url += this.getToken(Bus);
    }
    return url;
    // return this.Setup.url + Param.url + this.getToken(Param);
  }

  getToken(Bus: CwBusClass): string {
    // 2010191252
    return (Bus.token && !Bus.tokenSuffixDisabled)
      ? '/' + Bus.token
      : ''
      ;
  }

  // 1904101050
  request(RequestParameters: CwRestRequestParametersI): void {
    const That = CwRest;
    CwRest.headersDefine(RequestParameters.Bus);
    That.Setup.HttpClient
      .request(
        That._method,
        That.getUrl(RequestParameters.Bus),
        That.getOptions((RequestParameters.PayLoadCustom)
          ? RequestParameters.PayLoadCustom
          : RequestParameters.Bus.Param),
      )
      .subscribe(
        dataJson => {
          if (That.requestIsOk(dataJson)) {
            // 1910081139
            if (RequestParameters.Bus) {
              RequestParameters.Bus.Load.state = CwBusStateType.loaded;
            }
            // 1910081139
            if (
              RequestParameters &&
              RequestParameters.Bus &&
              RequestParameters.Bus.socketActivation &&
              true
            ) {
              RequestParameters.Bus.socketActivation();
            }

            if (
              RequestParameters &&
              RequestParameters.Bus &&
              RequestParameters.Bus.Param &&
              RequestParameters.Bus.Param.Callback &&
              RequestParameters.Bus.Param.Callback.success &&
              RequestParameters.Bus.Param.Callback.success.action &&
              true
            ) {
              RequestParameters.Bus.Param.Callback.success.action(dataJson['result']);
            }
            if (
              RequestParameters &&
              RequestParameters.successActionCustom &&
              true
            ) {
              RequestParameters.successActionCustom(dataJson);
            }
            this.manager({RequestParameters, action: 'successActionCustom'});
          } else {
            // 1910081139
            if (RequestParameters.Bus) {
              RequestParameters.Bus.Load.state = CwBusStateType.error;
            }

            if (
              RequestParameters &&
              RequestParameters.Bus &&
              RequestParameters.Bus.Param &&
              RequestParameters.Bus.Param.Callback &&
              RequestParameters.Bus.Param.Callback.error &&
              RequestParameters.Bus.Param.Callback.error.action &&
              true
            ) {
              RequestParameters.Bus.Param.Callback.error.action(dataJson);
            }
            // 1904101050
            if (
              RequestParameters &&
              RequestParameters.errorActionCustom &&
              true
            ) {
              RequestParameters.errorActionCustom(dataJson);
            }
            this.manager({RequestParameters, action: 'errorActionCustom'});
          }
        },
        (Data: any) => {
          // 1910081139
          if (RequestParameters.Bus) {
            RequestParameters.Bus.Load.state = CwBusStateType.error;
          }
          if (
            RequestParameters.Bus &&
            RequestParameters.Bus.Param &&
            RequestParameters.Bus.Param.Callback &&
            RequestParameters.Bus.Param.Callback.system &&
            RequestParameters.Bus.Param.Callback.system.action &&
            true
          ) {
            RequestParameters.Bus.Param.Callback.system.action(Data);
            // 1904101050
          }
          if (
            RequestParameters &&
            RequestParameters.errorRestActionCustom &&
            true
          ) {
            RequestParameters.errorRestActionCustom(Data);
          }

          this.manager({RequestParameters, action: 'errorRestActionCustom'});
        }
      )
    ;
  }

// // 1904101050
//   request(Bus: CwBusClass): void {
//     const That = CwRest;
//     That.Setup.HttpClient
//       .request(
//         That._method,
//         That.getUrl(Bus),
//         That.getOptions(Bus.Param),
//       )
//       .subscribe(
//         dataJson => {
//           if (That.requestIsOk(dataJson)) {
//             Bus.Param.Callback.success.action(dataJson['result']);
//           } else {
//             Bus.Param.Callback.error.action(dataJson);
//           }
//         },
//         (Data: any) => {
//           if (
//             Bus &&
//             Bus.Param &&
//             Bus.Param.Callback &&
//             Bus.Param.Callback.system &&
//             Bus.Param.Callback.system.action &&
//             true
//           ) {
//             Bus.Param.Callback.system.action(Data);
//           }
//         }
//       )
//     ;
//   }

  getOptions(Param: any): any {
    // getOptions(Param: CwToolRestParam | PayLoadCustomI): any {
    const that = CwRest;
    let Options = {
      body: {},
      headers: that._headers,
    };

    if (Param && Param.Data) {
      Options = {
        body: Param.Data,
        headers: that._headers
      };
    } else if (Param && Param.TransactionId) {
      Options = {
        body: Param,
        headers: that._headers
      };
    } else if (Param && !Param.Callback) {
      Options = {
        body: Param,
        headers: that._headers
      };
    }
    return Options;
  }

  requestIsOk(_DataJson: any): boolean {
    const that = CwRest;
    let _isOk = false;
    if (_DataJson) {
      if (!environment.production) {
        // console.trace('requestIsOk', _DataJson);
      }
      if (
        // 1901181612
        _DataJson['errorCode'] === environment.Rest.Code.errorTokenNotExpired ||
        _DataJson['errorCode'] === environment.Rest.Code.errorTokenNotValid
        // _DataJson['errorCode'] === that.Setup.codeErrorTokenExpired ||
        // _DataJson['errorCode'] === that.Setup.codeErrorTokenNotValid
      ) {
        _isOk = false;
      } else {
        // 1901181612
        _isOk = (_DataJson['errorCode'] === environment.Rest.Code.ok);
        // _isOk = (_DataJson['errorCode'] === that.Setup.codeOk);
      }
    }
    return _isOk;
  }

  // 1904101050
  doing(Parameters: CwRestRequestParametersT): void {
    if (
      Parameters &&
      Parameters.Simulation &&
      true
    ) {
      if (
        Parameters.Simulation.success &&
        Parameters.successActionCustom &&
        true
      ) {
        Parameters.successActionCustom();
      } else if (
        Parameters.Simulation.error &&
        Parameters.errorActionCustom &&
        true
      ) {
        Parameters.errorActionCustom({
          error: 'SIMULATION ERROR',
          errorCode: -666
        });
      } else if (
        Parameters.Simulation.errorRest &&
        Parameters.errorRestActionCustom &&
        true
      ) {
        Parameters.errorRestActionCustom({
          error: 'SIMULATION REST ERROR',
          errorCode: -666
        });
      }
      return null;
    }
    // 1907110924
    if (
      Parameters &&
      Parameters.Bus &&
      Parameters.token &&
      true
    ) {
      Parameters.Bus.token = Parameters.token;
    }

    if (Parameters && Parameters.Bus) {
      Parameters.Bus.addObservable(Parameters);
      Parameters.Bus.addRepeater(Parameters);
    }

    // 1910081139
    try {
      if (Parameters.Bus) {
        Parameters.Bus.Load.state = CwBusStateType.loading;
      }
      this.request(Parameters);
    } catch (e) {
      if (Parameters.Bus) {
        Parameters.Bus.Load.state = CwBusStateType.error;
      }
    }
  }

  // // 1904101050
  // doing(Bus: any): void {
  //   this.request(Bus);
  // }

  setGet(): void {
    this._method = 'GET';
  }

  setPost(): void {
    this._method = 'POST';

  }

  setPut(): void {
    this._method = 'PUT';
  }


}

export const CwRest = new ToolRestClass();
