import {CwUtil} from '@tool/util/cw-tool-util';
import {CwOnP} from '../tool/on-premise/tool-on-premise';
import {LicenseBus} from './license-bus';
import {formatDate} from '@angular/common';

/**
 * @version 1.13.0 - RPM - Partners - 1902071227
 */
class LicenseDefine {

  properties(That: LicenseBus): any[] {

    return [
      (Properties: any) => {
        if (
          Properties &&
          Properties.license &&
          true
        ) {
          That.Item = Properties.license;
          CwLicenseD.plans(That);
        }
      },
      (Properties: any) => {
        if (
          Properties &&
          Properties.serverKey &&
          true
        ) {
          That.serverKey = Properties.serverKey;
        }
      },
      (Properties: any) => {
        if (
          Properties &&
          Properties.installedLicense &&
          true
        ) {
          That.installed = Properties.installedLicense;
        }
      },
      (Properties: any) => {
        if (
          Properties &&
          Properties.validLicense &&
          true
        ) {
          That.valid = Properties.validLicense;
        }
      },
    ];
  }

  plans(That: LicenseBus): void {
    if (
      That &&
      That.Item &&
      That.Item.packets &&
      true
    ) {
      That.Item.packets.forEach(PacketItem => {
        CwLicenseD.propertyPacketLicenses(PacketItem);
        CwLicenseD.propertyPacketAvailable(PacketItem);
        CwLicenseD.propertyPacketMin(PacketItem);
        CwLicenseD.propertyEndDate(PacketItem);
        CwLicenseD.propertyStartDate(PacketItem);
      });
    }

    CwUtil.clear(That.Plans);
    // 1904010955
    CwUtil.clear(That.LicenseList);

    if (
      CwOnP.is &&
      That &&
      That.Item &&
      That.Item.packets &&
      true
    ) {
      // 1904010955
      That.LicenseList.push(...That.Item.packets);
      That.Plans.push(...That.Item.packets.filter(
        PacketFilter => (
          PacketFilter &&
          PacketFilter.available &&
          true
        )
      ));
    } else {
      // That.Plans.push(...CsBillingPlanBus.ItemsList);
    }
  }

  propertyPacketLicenses(PacketItem: any): void {
    if (
      PacketItem &&
      PacketItem.total_license_plans &&
      // 1904010955
      // PacketItem.available_license_plans &&
      true
    ) {
      PacketItem.licenses =
        (PacketItem.total_license_plans - PacketItem.available_license_plans) +
        '/' +
        PacketItem.total_license_plans
      ;
    }
  }

  propertyPacketAvailable(PacketItem: any): void {
    if (
      PacketItem &&
      PacketItem.available_license_plans &&
      true
    ) {
      PacketItem.available = PacketItem.available_license_plans;
    }
  }

  propertyPacketMin(PacketItem: any): void {
    if (
      PacketItem &&
      PacketItem.min_Subscription === 0 &&
      true
    ) {
      PacketItem.min_Subscription = 1;
    }
  }

  propertyEndDate(PacketItem: any): void {
    if (
      PacketItem &&
      PacketItem.end_date &&
      true
    ) {
      PacketItem.EndDate = CwUtil.utcToDate(PacketItem.end_date);
      PacketItem.EndDateWeb = formatDate(
        <Date>PacketItem.EndDate,
        'mediumDate',
        'en'
      );
    }
  }

  propertyStartDate(PacketItem: any): void {
    if (
      PacketItem &&
      PacketItem.start_date &&
      true
    ) {
      PacketItem.StartDate = CwUtil.utcToDate(PacketItem.start_date);
      PacketItem.StartDateWeb = formatDate(
        <Date>PacketItem.StartDate,
        'mediumDate',
        'en'
      );
    }
  }

}

export const CwLicenseD = new LicenseDefine();
