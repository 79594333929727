import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {CwInputI, CwInputType} from '@cw/components/input/cw-input-model';
import {CwUtil} from '@tool/util/cw-tool-util';
import {CwFunction} from '@cw/model/model';
import {CwSpacePlanModel, CwSpacePlanStyleModel} from '@cw/components/space/plan/cw-space-plan.model';
import {CwSpaceSubscriptionTool} from '@space/subscription/tool-space-subscription';
import {CW_SPACE_SUBSCRIPTION_TYPE_MODEL} from '@space/subscription/tool-space-subscription-model';
import {CwOptionI} from '@cw/components/select/select-model';
import {CwSpacePlanMinimalSetupModel} from '@cw/components/space/plan/minimal/cw-space-plan-minimal.model';
import {CwLiteral} from '@cw/literal/literal';
import {CwLogicSpacePlan} from '@cw/logic/space/plan/cw-logic-space-plan';
import {CwToolCurrency} from '@tool/currencies/tool-currencies';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cw-space-plan-minimal',
  templateUrl: './cw-space-plan-minimal.component.html',
  styles: []
})
export class CwSpacePlanMinimalComponent
  implements OnChanges {

  @Input() amountFunction: CwFunction;
  @Input() PlanNew: CwSpacePlanModel;
  @Input() Setup: CwSpacePlanMinimalSetupModel;
  @Input() style: CwSpacePlanStyleModel;
  @Output() changed = new EventEmitter();
  @ViewChild('ElementPlanDescription') ElementPlanDescription: TemplateRef<any>;
  @ViewChild('ElementPlanDescriptionCollapsable') ElementPlanDescriptionCollapsable: TemplateRef<any>;
  @ViewChild('ElementPlanDescriptionInTooltip') ElementPlanDescriptionInTooltip: TemplateRef<any>;
  @ViewChild('ElementPlanDescriptionFirstLine') ElementPlanDescriptionFirstLine: TemplateRef<any>;
  @ViewChild('TemplateExpansionAdd') TemplateExpansionAdd: TemplateRef<any>;
  @ViewChild('TemplateExpansionAddSelect') TemplateExpansionAddSelect: TemplateRef<any>;
  @ViewChild('TemplateMainDefaultMaximized') TemplateMainDefaultMaximized: TemplateRef<any>;
  @ViewChild('TemplateMainDefaultMinimized') TemplateMainDefaultMinimized: TemplateRef<any>;
  @ViewChild('TemplateMainExpansionMaximized') TemplateMainExpansionMaximized: TemplateRef<any>;
  @ViewChild('TemplateMainExpansionMinimized') TemplateMainExpansionMinimized: TemplateRef<any>;

  CwToolCurrency = CwToolCurrency;

  Define = {
    all: () => {
      this.Define.Config.annual();
      this.Define.Config.monthly();
      this.Define.quantity();
    },

    quantity: (Plan = this.getPlan) => {
      if (Plan &&
        !this.PlanNew.keep) {
        this.QuantityInput.value = Plan.quantity;
        this.On.Quantity.changed();
      } else if (Plan && this.PlanNew.keep) {
        this.QuantityInput.value = this.PlanNew.quantity;
        this.On.Quantity.changed();
      }
    },

    Config: {
      annual: (Plan = this.getPlan) => {
        if (Plan) {
          // selected?
          this.Config.Annual.selected = (Plan.interval === this.Config.Annual.interval);
          // price?
          this.Config.Annual.price = this.amountFunction({
            ...Plan,
            interval: this.Config.Annual.interval
          });
        }
      },
      monthly: (Plan = this.getPlan) => {
        if (Plan) {
          // selected?
          this.Config.Monthly.selected = (Plan.interval === this.Config.Monthly.interval);
          // price?
          this.Config.Monthly.price = this.amountFunction({
            ...Plan,
            interval: this.Config.Monthly.interval
          });
        }
      }
    }

  }


  elementIntervalSelected = (
    (Interval): boolean => {
      return this.PlanNew.interval === Interval.interval;
    }
  )

  Expansion = {
    _clear: (
      (): void => {
        CwUtil.clear(this.Expansion.Type.Setup.OptionList);
        CwUtil.clear(this.Expansion.Type.Value);
        this.Expansion.AddAnother.hasClicked = false;
      }
    ),
    AddAnother: {
      hasClicked: false,
      On: {
        clicked: (
          (PlanSelected = this.PlanNew) => {
            this.Expansion.AddAnother.hasClicked = true;
            try {
              this.Setup.Expansion.On.Another.clicked(PlanSelected)
            } catch (e) {
              console.error('2007201110', e)
            }
          }
        )
      }
    },
    Define: {
      _: (
        (): void => {
          if (
            this.PlanNew &&
            this.PlanNew.ExpansionList &&
            this.PlanNew.ExpansionList.length > 0
          ) {
            this.Expansion._clear();
            this.Expansion.Define.select();
            this.Expansion.Define.itemSelected();
            this.Define.Config.annual();
            this.Define.Config.monthly();
            this.Define.quantity();
          }
        }
      ),


      itemSelected: (() => {
        this.Expansion.Type.Setup.OptionList.filter(
          (Filter: any) => {
            return Filter.selected;
          }
        ).forEach(
          (Item: any) => {
            this.Expansion.Type.Value = Item;
          }
        );

        if (
          !this.Expansion.Type.Value &&
          this.Expansion.Type.Setup.OptionList[0]
        ) {
          this.Expansion.Type.Setup.OptionList[0].selected = true;
          this.Expansion.Type.Value = this.Expansion.Type.Setup.OptionList[0];
        }
      }),

      select: (
        (): void => {
          const ExpansionWithoutDependenciesList = this.PlanNew.ExpansionList.filter(
            (Filter: any) => {
              return !Filter.dependencies;
            }
          );
          if (ExpansionWithoutDependenciesList) {
            let selected = true;
            ExpansionWithoutDependenciesList.forEach(
              (Item: any): void => {
                this.Expansion.Type.Setup.OptionList.push(
                  {
                    ...Item,
                    selected,
                    web: Item.name,
                  }
                );
                selected = false;
              }
            )
          }

          const ExpansionWithDependenciesList = this.PlanNew.ExpansionList.filter(
            (Filter: any) => {
              return Filter.dependencies && Filter.dependencies.length > 0;
            }
          );
          if (ExpansionWithDependenciesList) {
            ExpansionWithDependenciesList.forEach(
              (Item: any) => {
                this.Expansion.Type.Setup.OptionList.push(
                  {
                    ...Item,
                    disabled: Item.disabled,
                    web: Item.name,
                  }
                );
              }
            )
          }


        }
      ),
    },
    Type: {
      Setup: {
        OptionList: <CwOptionI[]>[]
      },
      Value: undefined,

      On: {
        changed: (
          (): void => {
            this.Config.Annual.price = this.amountFunction({
              ...this.getPlan,
              interval: this.Config.Annual.interval
            });
            this.Config.Monthly.price = this.amountFunction({
              ...this.getPlan,
              interval: this.Config.Monthly.interval
            });

            if (this.QuantityInput.value === 0) {
              this.Config.Annual.amount = ((this.Config.Annual.price / 100) / 12);
              this.Config.Annual.total = (this.Config.Annual.price / 100);
              this.Config.Monthly.amount = (this.Config.Monthly.price / 100);
            } else {
              this.Config.Annual.amount = this.QuantityInput.value * ((this.Config.Annual.price / 100) / 12);
              this.Config.Annual.total = this.QuantityInput.value * (this.Config.Annual.price / 100);
              this.Config.Monthly.amount = this.QuantityInput.value * (this.Config.Monthly.price / 100);
            }

            // 2007270944
            if (this.Config.Annual.selected) {
              this.getPlan.interval = this.Config.Annual.interval;
            } else if (this.Config.Monthly.selected) {
              this.getPlan.interval = this.Config.Monthly.interval;
            }
            // 2007270944
            this.getPlan.quantity = this.QuantityInput.value;

            this.changed.emit(this.getPlan);
          }
        ), // changed
      }, // On

    }
  }


  On = {

    Minimized: {
      clicked: (
        () => {
          try {
            this.PlanNew.minimized = !this.PlanNew.minimized
          } finally {
          }
        }
      )
    },

    Plan: {
      clicked: (PlanClicked) => {

        // 2008030933 - 2008031216
        if (
          CwLogicSpacePlan.isMain(this.PlanNew) &&
          this.PlanNew.quantity === 0
        ) {
          this.PlanNew.interval = PlanClicked.interval;
          this.Config.Annual.selected = CwLogicSpacePlan.isYearly(PlanClicked);
          this.Config.Monthly.selected = CwLogicSpacePlan.isMonthly(PlanClicked);
        } else if (CwLogicSpacePlan.isClassroomWeb(this.PlanNew)) {
          this.PlanNew.interval = PlanClicked.interval;
          this.changed.emit(this.getPlan);
        } else {
          if (!PlanClicked.selected) {
            this.Config.Annual.selected = false;
            this.Config.Monthly.selected = false;
            PlanClicked.selected = true;
          }

          this.getPlan.interval = PlanClicked.interval;
          this.getPlan.Date = CwSpaceSubscriptionTool.defineEnd(this.getPlan);
          this.getPlan.amount = PlanClicked.price;
          this.changed.emit(this.getPlan);
        }


      }
    },

    Quantity: {
      changed: () => {
        if (this.QuantityInput.value === 0) {
          this.Config.Annual.amount = ((this.Config.Annual.price / 100) / 12);
          this.Config.Annual.total = (this.Config.Annual.price / 100);
          this.Config.Monthly.amount = (this.Config.Monthly.price / 100);
        } else {
          this.Config.Annual.amount = this.QuantityInput.value * ((this.Config.Annual.price / 100) / 12);
          this.Config.Annual.total = this.QuantityInput.value * (this.Config.Annual.price / 100);
          this.Config.Monthly.amount = this.QuantityInput.value * (this.Config.Monthly.price / 100);
        }
        if (this.getPlan) {
          this.getPlan.quantity = this.QuantityInput.value;
        }
        // 2007240008
        this.PlanNew.quantityPrevious = this.PlanNew.quantity;
        this.PlanNew.quantity = this.QuantityInput.value;

        this.changed.emit(this.getPlan);
      }
    },

  }

  QuantityInput: CwInputI = {
    class: 'text-right pr-3',
    enabled: true,
    id: CwUtil.uuid(),
    name: 'QuantityInput',
    min: 1,
    notLabel: true,
    type: CwInputType.NUMBER_POSITIVE,
    valid: true,
    ValidationList: [],
    value: 0,
    Validate: {
      required: true,
    },
  }

  Config = {
    Annual: {
      amount: 0,
      annual: true,
      description: 'Billed annually',
      interval: 'year',
      label: 'annual',
      price: 0,
      quantity: 0,
      selected: false,
      total: null,
    },
    Monthly: {
      amount: 0,
      annual: false,
      description: 'Billed monthly',
      interval: 'month',
      label: 'monthly',
      price: 0,
      quantity: 0,
      selected: true,
      total: null,
    },
    Plan: {
      Description: {
        collapsed: true
      }
    }


  }


  constructor() {
    CwToolCurrency.subscribe((
      () => {
        this.Expansion.Type.On.changed();
      }
    ))
  }

  // GETS //////////////////////////////////////////////////////////////////////

  get getAnotherText(): string {
    return (
      CwLogicSpacePlan.isClassroomAdd(this.PlanNew)
        ? CwLiteral.translate('Add') + ' ' + this.PlanNew.name
        : CwLiteral.translate('Add another') + ' ' + this.PlanNew.name
    )
  }

  // 2006101345
  get getCollapse(): boolean {
    return (
      this.PlanNew &&
      this.PlanNew.collapse
    );
  }

  // 2006101345
  get getCollapseHidden(): boolean {
    return this.PlanNew && this.PlanNew.collapse;
  }

  get getDescriptionCollapsed(): boolean {
    return (
      this.Config &&
      this.Config.Plan &&
      this.Config.Plan.Description &&
      this.Config.Plan.Description.collapsed
    )
  }

  get getDescriptionElement(): TemplateRef<any> {
    return (
      this.Setup &&
      this.Setup.Description &&
      this.Setup.Description.inTooltip
    )
      ? this.ElementPlanDescriptionInTooltip
      : (
        this.Setup &&
        this.Setup.Description &&
        this.Setup.Description.firstLine
      )
        ? this.ElementPlanDescriptionFirstLine
        : (
          this.Setup &&
          this.Setup.Description &&
          this.Setup.Description.collapsable
        )
          ? this.ElementPlanDescriptionCollapsable
          : this.ElementPlanDescription
      ;
  }

  get getElementExpansionSelectDisabled(): boolean {
    let disabled = false;
    if (
      this.Setup &&
      this.Setup.Expansion &&
      this.Setup.Expansion.selectDisabledFunction
    ) {
      disabled = this.Setup.Expansion.selectDisabledFunction({
        ...this.PlanNew,
        quantity: this.QuantityInput.value
      });
    }
    return disabled;
  }

  get getElementPlanNameTooltipPlacement(): string {
    return (CwLiteral.getIsRtl)
      ? 'right'
      : 'left'
      ;
  }

  get getExpansionAvailable(): number {
    return this.Expansion.Type.Setup.OptionList.length;
  }


  get getMinimized(): boolean {
    return (
      this.PlanNew && this.PlanNew.minimized
    );
  }

  get getPlan(): any {
    return (this.getTypeExpansive)
      ? this.Expansion.Type.Value
      : this.PlanNew
      ;
  }


  get getPlanIntervalVisible(): boolean {
    return this.PlanNew && this.PlanNew.quantity > 0
  }


  get getTemplateOutlet(): TemplateRef<any> {
    try {
      if (this.PlanNew) {
        // 207242311 - 2007241150 Basic - 2007241150 Pro
        if (CwLogicSpacePlan.isPlanAdd(this.PlanNew)) {

          if (
            CwLogicSpacePlan.isBasicAdd(this.PlanNew) ||
            CwLogicSpacePlan.isCloudAdd(this.PlanNew) ||
            CwLogicSpacePlan.isProAdd(this.PlanNew) ||
            CwLogicSpacePlan.isWebinarAdd(this.PlanNew)
          ) {
            return this.TemplateExpansionAddSelect;
          } else {
            return this.TemplateExpansionAdd;
          }
        // 2009231537
        } else  {

          if (this.getMinimized) {
            return this.TemplateMainDefaultMinimized;
          } else {
            return this.TemplateMainDefaultMaximized;
          }
        }
        // // 2009231537
        // } else if (this.getTypeExpansive) {
        //   if (this.getMinimized) {
        //     return this.TemplateMainExpansionMinimized;
        //   } else {
        //     return this.TemplateMainExpansionMaximized;
        //   }
        //
        // } else if (!this.getTypeExpansive) {
        //
        //   if (this.getMinimized) {
        //     return this.TemplateMainDefaultMinimized;
        //   } else {
        //     return this.TemplateMainDefaultMaximized;
        //   }
        // }

      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  }

  get getTypeExpansive(): boolean {
    return this.PlanNew.type === CW_SPACE_SUBSCRIPTION_TYPE_MODEL.EXPANSION_WEB;
  }

  get getVisible(): boolean {
    return !!this.PlanNew;
  }

  get isPlanDetailsMinimizedVisible(): boolean {
    return (
      this.getMinimized &&
      this.QuantityInput &&
      this.QuantityInput.value > 0
    );
  }

  // NGs ///////////////////////////////////////////////////////////////////////
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'PlanNew': {
            if (this.PlanNew) {
              this.Define.all();
              this.Expansion.Define._();
            }
          }
        }
      }
    }
  }

  // NGs ///////////////////////////////////////////////////////////////////////

}
