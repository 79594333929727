import {CwPartnerBus} from '../partner.bus';
import {CwFunction} from '../../../model/model';
import {CwPartnerEntitiesDoModel} from './partner-entities.model';

export class PartnerEntitiesBus extends CwPartnerBus {


  DefineProperties: CwFunction[];

  defineUrl = (() => this.path + this.partnerId + '/entities');

  do = ((Params: CwPartnerEntitiesDoModel) => {
    this.bearer = Params.token;
    this.partnerId = Params.partnerId;
    this.restGet(Params);
  });

  partnerId: string;

  defineData(Data) {
  }

}

export const CwPartnerEntitiesBus: PartnerEntitiesBus = new PartnerEntitiesBus();
