/**
 * @version 1.11.0
 */
import {CwEnvironmentI} from '../app/CollaborateSpaceWebTools/model/model';
import {EnvironmentCommon} from './environment.common';

declare var require: any;

// 11901281618
export const environment: CwEnvironmentI = {
    ...EnvironmentCommon,
    // onPremise: false,

};
