<ng-container *ngIf="getAvailable">
  <div class="row">
    <div
      class="col-12 h5 font-weight-bolder my-0"
      translate="Order Summary"
    ></div>
    <hr>
    <ng-container *ngFor="let Select of SelectedList">
      <div class="col-12"
           [ngClass]="{'d-none': Select?.quantity === 0}"
      >
        <cw-space-plan
          [amountFunction]="amountFunction"
          [PlanNew]="Select"
          [style]="CW_SPACE_PLAN.STYLE.SUMMARY"
        ></cw-space-plan>
      </div>


    </ng-container>
  </div>
  <ng-container
    *ngTemplateOutlet="DataTemplate; context:{amount:getAmountToday, label:'Today\'s Total:', Date: getDateToday}"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="DataTemplate; context:{amount:getAmountMonthly, label:'Total per month:', Date: getDateMonthly}"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="DataTemplate; context:{amount:getAmountYearly, label:'Total per year:', Date: getDateYearly}"
  ></ng-container>


</ng-container>

<!-- DataTemplate -------------------------------------------------------------->
<ng-template
  #DataTemplate
  let-amount="amount"
  let-label="label"
  let-Date="Date"
>
  <ng-container *ngIf="amount>0">
    <hr>
    <div class="d-flex">
      <div
        class="flex-fill text-left"
        [translate]="label"
      ></div>
      <div
        class="flex-fill text-right font-bold"
      >{{amount|cwCurrency:CwToolCurrency.getSelectedCode}}</div>
    </div>
    <div class="d-flex">
      <span class="icon icon-calendar small text-primary"></span>
      <div
        class="flex-fill text-left small mx-1"
      >{{CwDateTool.localeWithTimeZone(Date, true)}}</div>
      <div
        class="flex-fill text-right small"
      >{{getTaxesText | translate}}</div>
      <span *ngIf="taxesApplied()"
            class="flex-fill text-right small ml-1"
      >{{getTaxes}}</span>
    </div>
  </ng-container>
</ng-template>
