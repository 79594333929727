import {Component, OnInit} from '@angular/core';
import {CwProtocol} from '@tool/protocol/tool-protocol';
import {CwWeb} from '@tool/web/tool-web';

@Component({
  selector: 'app-connection-click-here-or-download',
  templateUrl: './connection-click-here-or-download.component.html',
  styleUrls: ['./connection-click-here-or-download.component.css']
})
/**
 * @version 2002241005
 */
export class ConnectionClickHereOrDownloadComponent implements OnInit {


  Actions = {
    clickHere: () => {
      let _uri = '/download/check';
      if (CwProtocol.token) {
        _uri += '/' + CwProtocol.token;
      }
      CwWeb.goTo({path: _uri});
    },
    download: CwProtocol.App.download,
  };


  // GETs //////////////////////////////////////////////////////////////////////
  // 2008200000
  get getDownload(): string {
    if (CwWeb.OS.Ios.is) {
      return 'Download from App Store'
    } else {
      return 'Download';
    }
  }

  //////////////////////////////////////////////////////////////////////////////


  // ISs ///////////////////////////////////////////////////////////////////////

  get isAutoDownload(): boolean {
    return CwProtocol.isAutoDownload;
  }

  // ISs ///////////////////////////////////////////////////////////////////////


  // NGs ///////////////////////////////////////////////////////////////////////

  // 2008200000
  ngOnInit() {
    if (CwProtocol.isAutoDownload === undefined) {
      setTimeout(this.ngOnInit, 500);
    } else {
      if (CwProtocol.isAutoDownload) {
        CwProtocol.App.download();
      }
      if (CwProtocol.exec && !CwWeb.OS.Ios.is) {
        CwProtocol.App.exec();
      }
    }
  }

  // NGs ///////////////////////////////////////////////////////////////////////

}
