import {Component} from '@angular/core';
import {DataSignUpPassiveComponent} from './data-sign-up-passive.component';
import {AppWebTool} from '../../app-web.tool';

@Component({
  selector: 'app-data-sign-up-passive-trial',
  templateUrl: './data-sign-up-passive.component.html',
  styleUrls: ['./data-sign-up-passive.component.scss']
})
/**
 * @version 2007291000
 */
export class DataSignUpPassiveTrialComponent
  extends DataSignUpPassiveComponent {
  constructor() {
    super();
    // 2007290008
    AppWebTool.Title = {
      // 2007291000
      main: 'COLLABORATE Space Free Account',
      // main: 'COLLABORATE Space Personal',
      // main: 'COLLABORATE Space Free Trial',
      // main: 'COLLABORATE Space Free',
      secondary: 'Registration',
    };

  }
}
