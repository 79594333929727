import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CwModalMessageI} from './model';
import {CwFunction} from '../../model/model';
import {GridComponent} from '../grid/grid.component';
import {CwUtil} from '@tool/util/cw-tool-util';
import {SetupGrid} from '../grid/grid';

@Component({
  selector: 'cw-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
/**
 * @version 1907230828
 */
export class ModalComponent implements OnInit {

  @Input() title: string;
  @Input() Data: any;
  @Input() callback: CwFunction;
  @Input() closeAction: CwFunction;
  @Input() GridComponent: GridComponent;
  // 1912131221
  @Input() SetupGrid: SetupGrid;
  @Input() ExtraParams: any;
  @Input() Error: CwModalMessageI = {
    message: '',
    MessageList: [],
  };
  @Input() Success: CwModalMessageI = {
    message: '',
  };
  @Input() Warning: CwModalMessageI = {
    message: '',
  };

  isLoading: boolean;


  constructor(private _NgbActiveModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.isLoading = false;
  }

  close(): void {
    if (!this.isLoading) {
      this._NgbActiveModal.close();
    }
  }

  closeCross(): void {
    if (this.closeAction) {
      this.closeAction();
    }
    if (!this.isLoading) {
      this._NgbActiveModal.close();
    }
  }

  errorReset() {
    this.Error.message = undefined;
    CwUtil.clear(this.Error.MessageList);
  }

  clear(): void {
    this.Error.message = '';
    CwUtil.clear(this.Error.MessageList);

    this.Success.message = '';
    CwUtil.clear(this.Success.MessageList);

    this.Warning.message = '';
    CwUtil.clear(this.Warning.MessageList);

  }
}
