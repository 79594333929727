export enum DataSignUpTypeModel {
  _,
  INVITATION = 'INVITATION',
}

export interface DataSignUpCouponVisibleModel {
  input: boolean;
  invalidMessage: boolean;
  removeButton: boolean;
  validateButton: boolean;
  validMessage: boolean;
}

export interface DataSignUpCouponModel {
  api: string;
  display: boolean;
  enabled: boolean;
  ready: boolean;
  Visible: DataSignUpCouponVisibleModel;
  value: string;

  check: any;
  checkCouponExternal: any;
  removeButtonClicked: any;
  setup: any;
  setupReady: any;
  validateButtonClicked: any;
  visibility: any;
}

// 2006031328
export const APP_DATA_SIGN_UP = {
  TYPE: DataSignUpTypeModel,
}
