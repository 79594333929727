import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {PlansComponentClass} from '../../plans.class';
import {CwInput} from '@cw/components/input/cw-input-model';
import {CwDoParametersI} from '@rest/model';
import {CwFiscalIdsBus} from '@cw/rest/billing/fiscalIds/ficalids-bus';
import {CwCountryStateBus} from '@rest/literal/country-min/country-min-bus';
import {CwStateBus} from '@rest/literal/state/state-bus';
import {CwCountry} from '@rest/literal/country/country-bus';

/**
 * @version 2004150000-23
 */
@Component({
  selector: 'app-plans-buy-registration',
  templateUrl: './plans-buy-registration.component.html',
  styleUrls: ['./plans-buy-registration.component.css']
})
export class PlansBuyRegistrationComponent
  extends PlansComponentClass implements OnInit, AfterViewInit {

  @Input() review: boolean;
  @ViewChild('countrySelect') countrySelect: any;

  Country: any;
  CwFiscalIdsBus = CwFiscalIdsBus;

  State: any;
  StateSetup: any;

  vatTypeSelected: any;


  On = {
    Country: {
      changed: (
        () => {
          this.StateSetup = {
            OptionList: []
          };
          if (this.Country && this.Country.api) {
            this.State = undefined;
            if (this.Country.StateList) {
              this.StateSetup.OptionList.push(
                ...this.Country.StateList
              );
            }
            // 2006101639
            if (this.StateSetup.OptionList.length === 0) {
              this.StateSetup.OptionList.push({
                coGridSelected: false,
                coId: undefined,
                country_id: -1,
                cwKey: -1,
                id: -1,
                name: this.Country.api,
                web: this.Country.web,
              });
            }
            this.State = this.StateSetup.OptionList[0];
            this.On.State.changed();

            this.AppPlansTool.Buy.Registration.InputCountry.set(this.Country.api);
            // 2011240953
            if (!this.Country.mainLanguageCode) {
              if (this.Country.languages && this.Country.languages[0]) {
                this.Country.mainLanguageCode = this.Country.languages[0].iso639_1;
              }
            }
            this.AppPlansTool.Buy.Registration.languageCountryCode = this.Country.mainLanguageCode + '_' + this.Country.countryCode;
            this.AppPlansTool.Buy.Registration.InputCountry.changed = true;
            this.Country.selected = true;

            // recalculate taxes
            this.AppPlansTool.Buy.Registration.Vat.calculateTaxes();
          }
        }
      )
    },
    State: {
      changed: (
        () => {
          if (this.State && this.State.name) {
            this.AppPlansTool.Buy.Registration.InputState.set(this.State.name);
            this.AppPlansTool.Buy.Registration.InputState.changed = true;
            this.State.selected = true;
          }
        }
      )
    },
    VatType: {
      changed: (
        () => {
          if (this.vatTypeSelected) {
            this.AppPlansTool.Buy.Registration.Vat.business_type_vat_id = this.vatTypeSelected.type;
            this.AppPlansTool.Buy.Registration.Vat.business_vat_country_code = this.vatTypeSelected.countryCode;
          }


          this.AppPlansTool.Buy.Registration.Vat.Input._reset();
          if (this.vatTypeSelected.type === 'none') {
            this.AppPlansTool.Buy.Registration.Vat.Input.valid = true;
          } else {
            this.AppPlansTool.Buy.Registration.Vat.validVat();
          }

          const vatTypeItem = CwFiscalIdsBus.ItemsList.find((item) =>
            item.type === this.vatTypeSelected.type &&
            item.countryCode === this.vatTypeSelected.countryCode);
          if (vatTypeItem) {
            this.AppPlansTool.Buy.Registration.Vat.Input.placeholder = vatTypeItem.pattern;
            this.AppPlansTool.Buy.Registration.Vat.Input.valueOriginal = vatTypeItem.pattern;
          }

          // Calculate taxes
          this.AppPlansTool.Buy.Registration.Vat.calculateTaxes();
        }
      )
    }
  }


  constructor() {
    super();
    CwStateBus.Load.do();
    CwCountryStateBus.Load.do();
  }

  get getAvailable(): boolean {
    return true;
  }

  get getInputs(): CwInput[] {
    return this.review
      ? this.AppPlansTool.Buy.Registration.InputsSet.filter(Filter => Filter.reviewable)
      : this.AppPlansTool.Buy.Registration.InputsSet
      ;
  }

  getSelected(Option: any): boolean {
    return (Option && this.vatTypeSelected) ? Option.vatTypeWeb === this.vatTypeSelected.vatTypeWeb : false;
  }

  ngOnInit() {
    CwFiscalIdsBus.Load.do(<CwDoParametersI>{
      successActionCustom: (
        () => {
          const defaultVat = {'type': 'none', 'vatTypeWeb': ' '};
          const defaultItem = CwFiscalIdsBus.ItemsList.find((item) => item.type === 'none');
          if (!defaultItem) {
            CwFiscalIdsBus.ItemsList.unshift(defaultVat);
          }

          // Select previous vat type selected when going back and return to registration
          const vatType = this.AppPlansTool.Buy.Registration.Vat.business_type_vat_id;
          const countryCode = this.AppPlansTool.Buy.Registration.Vat.business_vat_country_code;
          const vatTypeItem = CwFiscalIdsBus.ItemsList.find((item) =>
            item.type === vatType &&
            item.countryCode === countryCode);
          vatTypeItem ? this.vatTypeSelected = vatTypeItem : this.vatTypeSelected = defaultVat;
        }
      )
    });

  }

  ngAfterViewInit(): void {
    if (this.AppPlansTool.Buy.Registration.InputCountry.value ||
      this.AppPlansTool.Buy.Registration.InputState.value) {
      CwCountry.do(<CwDoParametersI>{
        successActionCustom: (() => {
          this.Country = this.countrySelect.CountryB.ItemsList.find((item) =>
            item.api === this.AppPlansTool.Buy.Registration.InputCountry.value);
          if (this.Country) {
            this.On.Country.changed();
          }

          this.State = CwStateBus.ItemsList.find((item) =>
            item.web === this.AppPlansTool.Buy.Registration.InputState.value);
          if (this.State) {
            this.On.State.changed();
          }
        })
      });
    }
  }
}
